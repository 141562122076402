import {
  styled,
  getColor,
  RadioStandAlone,
} from '@gk-devteam/apmc-core-web'

export const StatusRadioStyle = styled(RadioStandAlone)`
  label {
    &:first-child {
      color: ${props => getColor(props, 'danger')};
      border-color: ${props => props.value === 1 ? getColor(props, 'danger') : getColor(props, 'cardBorder')};
    }
    &:nth-child(2) {
      color: ${props => getColor(props, 'info')};
      border-color: ${props => props.value === 2 ? getColor(props, 'info') : getColor(props, 'cardBorder')};
    }
    &:nth-child(3) {
      color: ${props => getColor(props, 'success')};
      border-color: ${props => props.value === 3 ? getColor(props, 'success') : getColor(props, 'cardBorder')};
    }
    &:last-child {
      color: ${props => getColor(props, 'warning')};
      border-color: ${props => props.value === 4 ? getColor(props, 'warning') : getColor(props, 'cardBorder')};
    }
  }
`
