import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pusher from 'pusher-js'
import { useTranslation } from '@gk-devteam/apmc-core-web'

import { selectOwnerID, selectUserID } from '../selectors/auth/authSelector'
import {
  mainHogeHandler,
  ownerHogeHandler,
  updateContractsHandler
} from './pusher'
import { useNavigate } from '@reach/router'

const {
  REACT_APP_PUSHER_KEY,
  REACT_APP_PUSHER_CLUSTER
} = process.env

export const usePusher = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const i18n = useRef(t) // Prevent useffect rerenders
  const dispatch = useDispatch()
  const ownerID = useSelector(selectOwnerID)
  const userID = useSelector(selectUserID)
  const [pusher, setPusher] = useState()
  const ownerChannel = useRef()
  const memberChannel = useRef()

  // Main block
  useEffect(() => {
    // Add custom log function on debug mode only
    if (process.env.REACT_APP_DEBUG_MODE === 'true') {
      Pusher.log = function (message) {
        console.debug(message)
      }
    }
    // Initialize Pusher
    const pusher = new Pusher(REACT_APP_PUSHER_KEY, {
      cluster: REACT_APP_PUSHER_CLUSTER,
      encrypted: true
    })
    console.info('Pusher initialized')

    // Handle errors coming from Pusher error channel
    pusher.connection.bind('error', function (err) {
      if (err?.error?.data?.code === 4004) {
        console.warning('Over limit!')
      }
    })

    // Set Pusher to this hook state to be reused in other useEffect blocks
    setPusher(pusher)

    // Disconnect Pusher on useEffect "dismount"
    return () => {
      console.info('Disconnect Pusher')
      pusher.disconnect()
    }
  }, [])

  // Subscribe to main channel
  useEffect(() => {
    if (pusher) {
      // Subscribe to main channel
      const channel = pusher.subscribe('main_channel')
      // Handle hoge event (debug)
      channel.bind('hoge_event', mainHogeHandler)
    }
  }, [pusher])

  // Subscribe to owner channel
  useEffect(() => {
    if (ownerID && pusher) {
      const channelName = `owner_${ownerID}`
      var channels = pusher.allChannels()
      // Access or subscribe to owner channel
      if (channels && channels.some(channel => channel.name === channelName)) {
        console.info('Owner channel already subscribed')
        ownerChannel.current = pusher.channel(channelName)
      } else {
        console.info('Subscribing to owner channel')
        ownerChannel.current = pusher.subscribe(channelName)
      }

      // Handle hoge event (debug)
      ownerChannel.current.bind('hoge_event', ownerHogeHandler)

      return () => {
        console.info('unsubscribing from owner channel')
        pusher.unsubscribe(channelName)
      }
    }
  }, [ownerID, pusher])

  // Subscribe to member channel
  useEffect(() => {
    if (userID && pusher) {
      // console.debug('user ID', userID)
      const channelName = `member_${userID}`
      var channels = pusher.allChannels()

      // Access or subscribe to owner channel
      if (
        (channels && channels.some(channel => channel.name === channelName)) ||
        memberChannel?.current?.name === channelName
      ) {
        console.info('Member channel already subscribed')
        memberChannel.current = pusher.channel(channelName)
      } else {
        console.info('Subscribing to member channel')
        memberChannel.current = pusher.subscribe(channelName)
      }

      // Handle contract updated event
      if (memberChannel.current) {
        memberChannel.current.bind('update_contracts', data => updateContractsHandler({
          userID,
          data,
          dispatch,
          navigate,
          contractUpdatedMessage: i18n.current('contracts:contract_updated'),
          contractUpdateQuestion: i18n.current('contracts:contract_update_question')
        }))
      }

      return () => {
        console.info('unsubscribing from member channel')
        pusher.unsubscribe(channelName)
      }
    }
  }, [userID, pusher, dispatch, i18n, navigate])
}
