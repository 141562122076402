import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_FORM,
  LeftSearchMenu,
  TextInput,
  selectQueryParams,
  RangePicker
} from '@gk-devteam/apmc-core-web'

import { inspectionsSearchSchema } from '../../../validation'

const initialValues = {
  contract_id: '',
  contractor_id: '',
  contractor_name: '',
  property_id: '',
  next_inspection_date_from: '',
  next_inspection_date_to: ''
}

const InspectionsSearchBar = () => {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        property_id: queryParams?.property_id,
        contract_id: queryParams?.contract_id,
        contractor_id: queryParams?.contractor_id,
        contractor_name: queryParams?.contractor_name,
        next_inspection_date_from: queryParams?.next_inspection_date_from,
        next_inspection_date_to: queryParams?.next_inspection_date_to
      }
    })
  }, [queryParams, dispatch])

  return (
    <>
      <LeftSearchMenu
        resetValues={initialValues}
        validationSchema={inspectionsSearchSchema}
      >
        <TextInput
          name="property_name"
          label='properties:property_name'
          placeholder=""
          mr="0"
        />
        <TextInput
          name="contract_manage_id"
          label='contracts:contract_manage_id'
          placeholder=""
          mr="0"
        />
        <TextInput
          type="text"
          name="contractor_name"
          label='contracts:contractor_name'
          placeholder="placeholders.partial_user_name"
          mr="0"
        />
        <RangePicker
          name_start="next_inspection_date_from"
          name_end="next_inspection_date_to"
          label='inspections:inspection_date'
          placeholder=""
          mr="0"
        />
      </LeftSearchMenu>
    </>
  )
}

export default memo(InspectionsSearchBar)

// PropertiesSearchBar.propTypes = {}
