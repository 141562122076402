import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import { TOGGLE_ADD_PROPERTY_DOCUMENT_MODAL, POST_PROPERTY_DOCUMENT } from '../../../types'
import { selectPropertyDocumentAddModalState, selectPropertyDocumentsPosting } from '../../../selectors'

import PropertyDocumentForm from '../../../forms/PropertyDocumentForm'

const AddDocumentModal = ({ propertyID }) => {
  const dispatch = useDispatch()
  const visible = useSelector(selectPropertyDocumentAddModalState)
  const posting = useSelector(selectPropertyDocumentsPosting)

  const initialData = {
    property_id: propertyID,
    file_name: '',
    chairman: false,
    inspector: false,
    contractors: false,
    residents: false,
    file: null,
    delete_file: false
  }

  const _closeAddModal = () => {
    dispatch({ type: TOGGLE_ADD_PROPERTY_DOCUMENT_MODAL })
  }
  const _saveDocument = (data) => {
    const successRedirect = () => {
      _closeAddModal()
    }
    dispatch({ type: POST_PROPERTY_DOCUMENT, data, successRedirect })
  }
  return (
    <Modal
      visible={visible}
      onCancel={_closeAddModal}
      onOk={_saveDocument}
      okText="save"
      title="properties:documents.add"
      fullWidth
      formID="propertyDocumentForm"
      posting={posting}
    >
      <PropertyDocumentForm saveDocument={_saveDocument} data={initialData}/>
    </Modal>
  )
}

export default memo(AddDocumentModal)

AddDocumentModal.propTypes = {
  propertyID: PropTypes.string.isRequired
}
