import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const memberUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    name: yup.string().max(255).nullable().required().label(i18n.t('user_management:member.name')),
    furigana: yup.string().max(255).nullable().required().label(i18n.t('common:furigana')),
    mail_address: yup.string().nullable().required().email().label(i18n.t('common:email')),
    phone: yup.string().max(255).nullable().label(i18n.t('common:phone')),
    office: yup.string().max(255).nullable().label(i18n.t('user_management:member.office')),
    department: yup.string().max(255).nullable().label(i18n.t('user_management:member.department')),
    position: yup.string().max(255).nullable().label(i18n.t('user_management:member.position')),
    role: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .min(2).max(7)
      .label(i18n.t('user_management:member.role')),
    all_properties: yup
      .boolean()
      .nullable()
      .label(i18n.t('user_management:all_properties')),
    groups: yup
      .array()
      .nullable()
      .of(
        yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .required()
      )
      .label(i18n.t('user_management:group')),
    properties: yup
      .array()
      .nullable()
      .of(
        yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .required()
      )
      .label(i18n.t('common:property')),
    file: yup.string().nullable().isBlob().label(i18n.t('common:file'))
  })
}
