import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getFileFromURL } from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { customCsvParser } from '../../../utils'

import { ConfirmTableContainerStyle, TableStyle } from './DatasyncConfirmPage.style'

function ConfirmPage ({ file, type }) {
  const [data, setData] = useState(null)
  const [rowKey, setRowKey] = useState(null)
  const tableRef = useRef()
  const [width, setWidth] = useState(0)
  const [pageSize, setPageSize] = useState(100)

  useEffect(() => {
    let key
    if (data) {
      const firstItem = data[0]
      const keys = Object.keys(firstItem)
      if (type === 'properties') {
        if (keys.includes('物件管理ID')) {
          key = '物件管理ID'
        }
        if (keys.includes('property_manage_id')) {
          key = 'property_manage_id'
        }
        if (keys.includes('物件No')) {
          key = '物件No'
        }
      }
      if (type === 'contracts') {
        if (keys.includes('契約管理ID')) {
          key = '契約管理ID'
        }
        if (keys.includes('contract_manage_id')) {
          key = 'contract_manage_id'
        }
        if (keys.includes('契約番号')) {
          key = '契約番号'
        }
      }
    }
    setRowKey(key)
  }, [data, type])

  const parseFile = useCallback(
    async (file) => {
      try {
        const blob = await getFileFromURL(file)
        // console.log('blob', blob)
        const csv = await customCsvParser({ file: blob })
        // console.log('csv', csv)
        setData(csv)
      } catch (error) {
        console.warn('file parsing error', error)
      }
    },
    []
  )

  useEffect(() => {
    if (file) {
      parseFile(file)
    }
  }, [file, parseFile])

  const _handleResizing = () => {
    const main = document.getElementById('main')
    const table = tableRef && tableRef.current
    if (table) {
      const pageWidth = document.documentElement.clientWidth
      // console.log('pageWidth', pageWidth)
      // const pageHeight = document.documentElement.clientHeight
      const parentEl = main.parentElement
      const parentStyles = window.getComputedStyle(parentEl)
      const parentPaddingLeft = parseFloat(parentStyles.getPropertyValue('padding-left'))
      // console.log('parentPaddingLeft', parentPaddingLeft)
      const parentPaddingRight = parseFloat(parentStyles.getPropertyValue('padding-right'))
      // console.log('parentPaddingRight', parentPaddingRight)
      // const parentPaddingBottom = parseFloat(parentStyles.getPropertyValue('padding-bottom'))

      // const { top } = table.getBoundingClientRect()

      setWidth(pageWidth - parentPaddingLeft - parentPaddingRight)
      // console.log('set width', pageWidth - parentPaddingLeft - parentPaddingRight)
      // setHeight(pageHeight - top - parentPaddingBottom)
    }
  }

  useEffect(() => {
    _handleResizing()
    window.addEventListener('resize', _handleResizing, false)
    return () => {
      window.removeEventListener('resize', _handleResizing, false)
    }
  }, [rowKey])

  const _handlePageSizeChange = (current, size) => {
    setPageSize(size)
  }
  const _handlePageChange = (page, pageSize) => {
    window.scroll(0, 0)
  }

  if (!type || !file || !data || data.length === 0 || !rowKey) return null

  const columns = Object.keys(data[0]).map(key => {
    return {
      title: key,
      dataIndex: key
    }
  })

  return (
    <ConfirmTableContainerStyle ref={tableRef} width={width}>
      <TableStyle
        rowKey={rowKey}
        dataSource={data}
        columns={columns}
        pagination={{ pageSize, onShowSizeChange: _handlePageSizeChange, onChange: _handlePageChange, pageSizeOptions: [20, 50, 100, 250, 500] }}
        size="small"
        scroll={{ x: 'max-content' }}
      />
    </ConfirmTableContainerStyle>
  )
}

ConfirmPage.propTypes = {
  file: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['properties', 'contracts']).isRequired
}

export default ConfirmPage
