import { api, MULTIPART_HEADERS, getFileFromURL } from '@gk-devteam/apmc-core-web'

export const fetchRefuseCalendar = (id, cancelSource) => {
  return api.get(`properties/${id}/garbage_calendar`, {
    cancelToken: cancelSource.token
  })
}
export const fetchCalendars = (id, cancelSource) => {
  return api.get(`properties/${id}/garbages`, {
    cancelToken: cancelSource.token
  })
}

export const selectCalendar = (data, id, cancelSource) => {
  return api.post(`properties/${id}/garbage_calendar`, data, {
    cancelToken: cancelSource.token
  })
}

export const postCalendar = async (data, id, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.pdf) {
      try {
        const pdf = await getFileFromURL(data.pdf)
        formData.append('pdf', pdf, data.pdf_filename)
      } catch (error) {
        console.warning('error while processing document')
      }
    }

    return api.post(`properties/${id}/garbages/original`, formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const postReport = (data, id, cancelSource) => {
  return api.post(`properties/${id}/garbages/issue`, data, {
    cancelToken: cancelSource.token
  })
}

export const deleteCalendar = (id, cancelSource) => {
  return api.delete(`properties/${id}/garbages`, {
    cancelToken: cancelSource.token
  })
}
