import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  LeftNavigationMenu,
  ItemNotFound
} from '@gk-devteam/apmc-core-web'

import {
  selectPages,
  selectLoadingProperty,
  selectPropertyError
} from '../../selectors'
import { FETCH_PROPERTY, RESET_PROPERTY } from '../../types'

import NotFoundPage from '../../pages/NotFoundPage'

const PropertyBlock = ({ enabled, children, propertyID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const pages = useSelector(selectPages)
  const error = useSelector(selectPropertyError)
  const loading = useSelector(selectLoadingProperty)

  useEffect(() => {
    if (propertyID) {
      dispatch({ type: FETCH_PROPERTY, id: propertyID })
    }
    return () => {
      dispatch({ type: RESET_PROPERTY })
    }
  }, [dispatch, propertyID])

  if (!propertyID) return null
  if (!enabled || !children || !pages) return <NotFoundPage />

  const getMenu = () => {
    const { properties, rooms } = pages
    const {
      details,
      adminTasksSettings,
      faq,
      manuals,
      vicinities,
      public_facilities,
      guides,
      refuse,
      contacts,
      services,
      custom
    } = properties.functions
    const menu = []
    if (details) {
      menu.push({
        label: t('navigation:properties_details'),
        link: './'
      })
    }
    if (rooms.enabled) {
      menu.push({
        label: t('navigation:rooms_list'),
        link: './rooms'
      })
    }
    if (adminTasksSettings) {
      menu.push({
        label: t('navigation:properties_admin_tasks_settings'),
        link: './admin_tasks'
      })
    }
    if (faq) {
      menu.push({
        label: t('navigation:properties_faq'),
        link: './faq'
      })
    }
    if (manuals) {
      menu.push({
        label: t('navigation:properties_manuals'),
        link: './manuals'
      })
    }
    if (vicinities) {
      menu.push({
        label: t('navigation:properties_vicinities'),
        link: './vicinities'
      })
    }
    if (public_facilities) {
      menu.push({
        label: t('navigation:properties_public_facilities'),
        link: './public_facilities'
      })
    }
    if (guides) {
      menu.push({
        label: t('navigation:properties_guide_categories'),
        link: './guides'
      })
    }
    if (refuse) {
      menu.push({
        label: t('navigation:properties_refuse'),
        link: './refuse'
      })
    }
    if (contacts) {
      menu.push({
        label: t('navigation:properties_contacts'),
        link: './contacts'
      })
    }
    if (services) {
      menu.push({
        label: t('navigation:services'),
        link: './services?type=1'
      })
    }
    if (custom?.yahagi) {
      menu.push({
        label: t('navigation:properties_documents'),
        link: './documents'
      })
      menu.push({
        label: t('navigation:properties_events'),
        link: './events'
      })
      menu.push({
        label: t('navigation:properties_spaces'),
        link: './spaces'
      })
    }

    return menu
  }

  return (
    <PageLayout
      sidebar={loading || (error && error.code === 404) ? null : () => <LeftNavigationMenu menu={getMenu()}/>}
      noMain
    >
      {
        loading
          ? null
          : error && error.code === 404
            ? <ItemNotFound item={t('navigation:properties')} link="/properties" />
            : children
      }
    </PageLayout>
  )
}

export default PropertyBlock

PropertyBlock.propTypes = {
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  propertyID: PropTypes.string
}
