import React from 'react'
import {
  useTranslation,
  Row,
  TextInput,
  Select,
  RoleControlled,
  selectPages
} from '@gk-devteam/apmc-core-web'

import { SUPPORT_STATUS } from '../../../constants'
import { useSelector } from 'react-redux'

const ChatSearchForm = () => {
  const { t } = useTranslation()
  const pages = useSelector(selectPages)
  return (
    <>
      <Row>
        <TextInput
          flex={1}
          name="userName"
          label={pages?.custom?.yasue ? 'yasue:contracts.contractor_name' : 'app_users:app_user_name'}
          placeholder={pages?.custom?.yasue ? '' : 'placeholders.partial_user_name'}
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          name="propertyName"
          label={pages?.custom?.yasue ? 'yasue:contracts.contract_manage_id' : 'properties:property_name'}
          placeholder={pages?.custom?.yasue ? '' : 'placeholders.partial_property_name'}
        />
      </Row>
      <RoleControlled authorized="!custom.yasue">
        <Row justify="between">
          <Select
            mr="S"
            name="managementStatus"
            label='status'
            placeholder=""
            options={[
              {
                label: t('admin_tasks:status.undefined'),
                value: SUPPORT_STATUS.UNDEFINED
              },
              {
                label: t('admin_tasks:status.is_waiting'),
                value: SUPPORT_STATUS.WAITING
              },
              {
                label: t('admin_tasks:status.is_confirmed'),
                value: SUPPORT_STATUS.CONFIRMED
              },
              {
                label: t('admin_tasks:status.is_completed'),
                value: SUPPORT_STATUS.COMPLETED
              },
              {
                label: t('admin_tasks:status.is_unused'),
                value: SUPPORT_STATUS.UNUSED
              },
              {
                label: t('admin_tasks:status.archive'),
                value: SUPPORT_STATUS.ARCHIVE
              }
            ]}
          />
          <TextInput
            flex={1}
            name="userID"
            label='app_users:app_user_id'
            placeholder=""
          />
        </Row>
      </RoleControlled>
    </>
  )
}

export default ChatSearchForm

ChatSearchForm.propTypes = {}
