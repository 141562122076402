import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import { POST_VICINITY } from '../../../../types'
import { selectVicinitiesPosting } from '../../../../selectors'

import VicinityForm from '../../../../forms/VicinityForm/VicinityForm'

const VicinityModal = ({ visible, onCancel, id, editMode }) => {
  const dispatch = useDispatch()
  const posting = useSelector(selectVicinitiesPosting)

  const _handleSubmit = (data) => {
    const onSuccess = () => {
      onCancel()
    }
    dispatch({ type: POST_VICINITY, data, id, onSuccess })
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={_handleSubmit}
      okText="save"
      title={editMode ? 'vicinities:edit_vicinity' : 'vicinities:add_vicinity'}
      formID="vicinity_form"
      posting={posting}
      fullWidth
      // fullHeight
      large
    >
      <VicinityForm handleSubmit={_handleSubmit} />
    </Modal>
  )
}

export default VicinityModal

VicinityModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  id: PropTypes.string
}
