export const FETCH_PROPERTY_EVENTS = 'fetch_property_events'
export const FETCH_PROPERTY_EVENTS_SUCCESS = 'fetch_property_events_success'
export const FETCH_PROPERTY_EVENTS_FAIL = 'fetch_property_events_fail'
export const RESET_PROPERTY_EVENTS = 'reset_property_events'

export const DELETE_PROPERTY_EVENT = 'delete_property_event'
export const DELETE_PROPERTY_EVENT_SUCCESS = 'delete_property_event_success'
export const DELETE_PROPERTY_EVENT_FAIL = 'delete_property_event_fail'

export const POST_PROPERTY_EVENT = 'post_property_event'
export const POST_PROPERTY_EVENT_SUCCESS = 'post_property_event_success'
export const POST_PROPERTY_EVENT_FAIL = 'post_property_event_fail'

export const TOGGLE_ADD_PROPERTY_EVENT_MODAL = 'toggle_add_property_event_modal'
export const TOGGLE_VIEW_PROPERTY_EVENTS_MODAL = 'toggle_view_property_events_modal'
export const SET_ACTIVE_PROPERTY_EVENTS = 'set_active_property_events'
