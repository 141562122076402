import { createSelector } from 'reselect'

const selectAppUsers = state => state.appUsers

export const selectAppUsersLoading = createSelector(
  selectAppUsers,
  appUsers => appUsers.loading
)
export const selectAppUsersCount = createSelector(
  selectAppUsers,
  appUsers => appUsers.results_count
)
export const selectAppUsersData = createSelector(
  selectAppUsers,
  appUsers => appUsers.results
)

export const selectAppUsersAvailableRecipientsLoading = createSelector(
  selectAppUsers,
  appUsers => appUsers.loading_available_recipients
)
export const selectAppUsersAvailableRecipients = createSelector(
  selectAppUsers,
  appUsers => appUsers.available_recipients
)
