import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_PROPERTIES,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTIES_FAIL,
  RESET_PROPERTIES,
  DELETE_PROPERTY
  // DELETE_PROPERTY_SUCCESS,
  // DELETE_PROPERTY_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  max_pages: null,
  results_count: null,
  results: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_PROPERTIES]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_PROPERTIES_SUCCESS]: (state, action) => {
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_PROPERTIES_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_PROPERTIES]: (state, action) => {
    state.loading = null
    state.error = null
    state.max_pages = null
    state.results_count = null
    state.results = null
  },
  [DELETE_PROPERTY]: (state, action) => {
    state.loading = true
  }
  // [DELETE_PROPERTY_SUCCESS]: (state, action) => {
  //   state.loading = false
  // },
  // [DELETE_PROPERTY_FAIL]: (state, action) => {
  //   state.loading = false
  // }
})
