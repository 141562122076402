import React from 'react'
import {
  Card,
  RepeaterAddButton,
  RepeaterFields,
  Row
} from '@gk-devteam/apmc-core-web'

import RenewalNoticeRow from './RenewalNoticeRow'
import CancelNoticeRow from './CancelNoticeRow'

export default function NoticeCard () {
  return (
    <Card mb="L" title="contracts:notices_info">
      <Row wrap="wrap" align="baseline">
        <RepeaterFields
          propertyKey="renewal_notice"
          uniqField="destination_email"
          field={(index) => <RenewalNoticeRow key={index} index={index}/>}
        />
      </Row>
      <RepeaterAddButton
        label="contracts:add_renewal_notice"
        propertyKey="renewal_notice"
        uniqField="destination_email"
        fields={['enabled', 'destination_name', 'destination_email']}
        // help="contracts:help.custom_fields"
      />
      <Row wrap="wrap" align="baseline">
        <RepeaterFields
          propertyKey="cancel_notice"
          uniqField="destination_email"
          field={(index) => <CancelNoticeRow key={index} index={index}/>}
        />
      </Row>
      <RepeaterAddButton
        label="contracts:add_cancel_notice"
        propertyKey="cancel_notice"
        uniqField="destination_email"
        fields={['enabled', 'destination_name', 'destination_email']}
        // help="contracts:help.custom_fields"
      />
    </Card>
  )
}
