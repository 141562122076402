import {
  styled,
  getColor,
  SPACING,
  Row,
  Column
} from '@gk-devteam/apmc-core-web'

export const GuideCategoryStyle = styled(Column)`
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  padding: ${SPACING.REGULAR}rem ${SPACING.MEDIUM_LARGE}rem;
  cursor: pointer;
  margin-top: ${SPACING.REGULAR}rem;

  /* &:not(:last-of-type){
    border-bottom: none;
  } */
`

export const GuideCategoryContentStyle = styled(Row)`
  border-top: solid 1px ${props => getColor(props, 'cardBorder')};
  padding-top: ${SPACING.SMALL}rem;
`
export const GuideCategoryImageStyle = styled.div`
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
  margin-right: ${SPACING.REGULAR}rem;
  box-sizing: border-box;
`

export const DescriptionStyle = styled.p`
  margin: 0;
`
