import React, { useEffect } from 'react'
import {
  Card,
  RoleControlled,
  TextInput,
  Select,
  Row,
  Confirm,
  DangerButton,
  UPDATE_FORM
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

// import CasaInfo from './CasaInfo'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

function CasaCard ({ casaApprovalNumbers, readonly, onNumberDelete }) {
  const dispatch = useDispatch()
  const approvalNumber = useSelector(state => state.form.casa_approval_number)
  const approvalNumberOptions = casaApprovalNumbers?.map(row => ({
    label: row?.approval_number,
    value: row?.approval_number
  }))

  // Update casa contract info on select approval number
  useEffect(() => {
    if (!readonly) {
      const contract = casaApprovalNumbers?.find(row => row.approval_number === approvalNumber)
      if (contract) {
        dispatch({
          type: UPDATE_FORM,
          payload: {
            casa_contractor_name: contract.contractor_name,
            casa_property_name: contract.property_name,
            casa_room_number: contract.room_number
          }
        })
      }
    }
  }, [readonly, approvalNumber, casaApprovalNumbers, dispatch])

  return (
    <RoleControlled authorized="custom.casa">
      <Card mb="L" title="contracts:casa.contract">
        {/* <CasaInfo /> */}
        <Row mb="L" align="center">
          <Select
            flex={1}
            size="XL"
            name="casa_approval_number"
            label="contracts:casa.approval_number"
            placeholder=""
            showSearch
            disabled={readonly}
            autocomplete="on"
            loading={!casaApprovalNumbers}
            options={approvalNumberOptions}
          />
          {
            readonly && (
              <Confirm
                title="contracts:casa.delete_approval_number_question"
                placement="topRight"
                onConfirm={onNumberDelete}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                <DangerButton
                  label="contracts:casa.delete_approval_number"
                  small
                  withMargin
                  style={{ marginTop: 24 }}
                />
              </Confirm>

            )
          }
        </Row>
        <Row>
          <TextInput
            flex={1}
            size="XL"
            type="text"
            name="casa_property_name"
            label='properties:property_name'
            readonly={true}
            placeholder=""
          />
          <TextInput
            flex={1}
            size="M"
            type="text"
            name="casa_room_number"
            label='properties:room_number'
            readonly={true}
            placeholder=""
          />
          <TextInput
            flex={1}
            size="XL"
            type="text"
            name="casa_contractor_name"
            label='contracts:contractor_name'
            readonly={true}
            placeholder=""
          />
        </Row>
      </Card>
    </RoleControlled>
  )
}

CasaCard.propTypes = {
  casaApprovalNumbers: PropTypes.arrayOf(PropTypes.shape({
    approval_number: PropTypes.string.isRequired,
    property_name: PropTypes.string.isRequired,
    room_number: PropTypes.string.isRequired,
    contractor_name: PropTypes.string.isRequired
  }).isRequired),
  readonly: PropTypes.bool.isRequired,
  onNumberDelete: PropTypes.func.isRequired
}

export default CasaCard
