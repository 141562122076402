import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import {
  useTranslation,
  api,
  UPDATE_FORM,
  Spinner,
  Modal,
  PrimaryButton,
  DangerButton
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import SearchAddressItem from './SearchAddressItem'
import {
  SearchAddressButtonStyle,
  AddressesListStyle
} from './SearchAddressButton.style'

const SearchAddressButton = ({
  postcodeKey = 'postcode',
  prefectureKey = 'prefecture',
  cityKey = 'city',
  addressKey = 'address',
  disabled,
  withReset
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loadingPostCode, setLoadingPostCode] = useState(false)
  const [adddressModalVisible, setAdddressModalVisible] = useState(false)
  const [addresses, setAdddresses] = useState(false)
  const postcode = useSelector(state => state.form[postcodeKey])

  const _handleSearchAddress = async () => {
    const regex = /^\d{7}$/
    if (postcode && regex.test(postcode)) {
      setLoadingPostCode(true)
      try {
        const res = await api.get('postcode', {
          params: {
            postcode
          }
        })
        if (!res.data || (res.data && res.data.length === 0)) {
          message.warning(t('properties:no_postcode_address'))
        } else if (res.data && res.data.length === 1) {
          const { address, city, prefecture } = res.data[0]
          dispatch({
            type: UPDATE_FORM,
            payload: {
              [prefectureKey]: prefecture,
              [cityKey]: city,
              [addressKey]: address
            }
          })
        } else if (res.data && res.data.length > 1) {
          setAdddressModalVisible(true)
          setAdddresses(res.data)
        }

        setLoadingPostCode(false)
      } catch (error) {
        console.warning('error', error)
        setLoadingPostCode(false)
      }
    } else {
      // message.config({ maxCount: 1 })
      message.error(t('properties:postcode_not_valid'))
    }
  }

  const _resetAddress = () => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        [postcodeKey]: null,
        [prefectureKey]: null,
        [cityKey]: null,
        [addressKey]: null
      }
    })
  }

  const closeModal = useCallback(
    () => {
      setAdddressModalVisible(false)
    },
    []
  )

  const selectAddress = ({ prefecture, city, address }) => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        [prefectureKey]: prefecture,
        [cityKey]: city,
        [addressKey]: address
      }
    })
    closeModal()
  }

  const _renderAddresses = () => {
    if (!addresses) return null
    return addresses.map((item, i) => {
      const { prefecture, city, address } = item
      return (
        <SearchAddressItem
          handleClick={selectAddress}
          prefecture={prefecture}
          city={city}
          address={address}
          key={i}
        />
      )
    })
  }

  return (
    <>
      <Modal
        visible={adddressModalVisible}
        title={'properties:select_address'}
        onCancel={closeModal}
        cancelText='close'
      >
        <AddressesListStyle>
          {_renderAddresses()}
        </AddressesListStyle>
      </Modal>
      <SearchAddressButtonStyle>
        <PrimaryButton
          label='properties:search_address'
          type="button"
          handleClick={_handleSearchAddress}
          icon={loadingPostCode ? <Spinner size="small" color="text" /> : null}
          disabled={loadingPostCode || disabled}
        />
        {
          withReset && (
            <DangerButton
              label='properties:reset_address'
              type="button"
              handleClick={_resetAddress}
            />
          )
        }
      </SearchAddressButtonStyle>
    </>
  )
}

export default SearchAddressButton

SearchAddressButton.propTypes = {
  postcodeKey: PropTypes.string,
  prefectureKey: PropTypes.string,
  cityKey: PropTypes.string,
  addressKey: PropTypes.string,
  disabled: PropTypes.bool,
  withReset: PropTypes.bool
}
