import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import { TOGGLE_ADD_CONSTRUCTION_WORK_FILE_MODAL, POST_CONSTRUCTION_WORK_FILE } from '../../../types'
import { selectFileAddModalState, selectFilePosting } from '../../../selectors'

import ConstructionWorkFileForm from '../../../forms/yasue/ConstructionWorkFileForm'

const initialData = {
  file: null,
  filename: ''
}

const AddConstructionWorkFileModal = ({ constructionWorkID }) => {
  const dispatch = useDispatch()
  const visible = useSelector(selectFileAddModalState)
  const posting = useSelector(selectFilePosting)

  const _closeAddModal = () => {
    dispatch({ type: TOGGLE_ADD_CONSTRUCTION_WORK_FILE_MODAL })
  }
  const _saveDocument = (data) => {
    const successRedirect = () => {
      _closeAddModal()
    }
    dispatch({ type: POST_CONSTRUCTION_WORK_FILE, constructionWorkID, data, successRedirect })
  }
  return (
    <Modal
      visible={visible}
      onCancel={_closeAddModal}
      onOk={_saveDocument}
      okText="save"
      title="yasue:construction.add_construction_work_file"
      fullWidth
      formID="constructionWorkFileForm"
      posting={posting}
    >
      <ConstructionWorkFileForm saveDocument={_saveDocument} data={initialData}/>
    </Modal>
  )
}

export default memo(AddConstructionWorkFileModal)

AddConstructionWorkFileModal.propTypes = {
  constructionWorkID: PropTypes.string.isRequired
}
