import {
  styled,
  SPACING,
  Form,
  Card,
  TextInput,
  Select
} from '@gk-devteam/apmc-core-web'

export const FormStyle = styled(Form)`
  /* height: 500px;
  overflow-y: scroll; */
`

export const FileContainerStyle = styled(Card)`
  align-self: stretch;
  max-width: 240px;
  margin-right: ${SPACING.MEDIUM_LARGE}rem;
  box-sizing: border-box;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const InputContainerStyle = styled.div`
  flex: 1;
`

export const TextInputStyle = styled(TextInput)`
  &:not(:first-child) {
    margin-top: ${SPACING.SMALL}rem;
  }
`
export const SelectStyle = styled(Select)`
  &:not(:first-child) {
    margin-top: ${SPACING.SMALL}rem;
  }
`
