import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchAdminUser,
  postAdminUser
} from '../../services'

import {
  FETCH_ADMIN_USER,
  FETCH_ADMIN_USER_SUCCESS,
  FETCH_ADMIN_USER_FAIL,
  POST_ADMIN_USER,
  POST_ADMIN_USER_SUCCESS,
  POST_ADMIN_USER_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchAdminUserSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchAdminUser, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_ADMIN_USER_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_ADMIN_USER_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_ADMIN_USER_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postAdminUserSaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postAdminUser, data, cancelSource)
    if (res && res.data && res.data.success) {
      if (successRedirect) yield call(successRedirect, res.data.id)
      yield put({ type: POST_ADMIN_USER_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_ADMIN_USER_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_ADMIN_USER_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchAdminUser () {
  yield takeLatest(FETCH_ADMIN_USER, fetchAdminUserSaga)
  yield takeLatest(POST_ADMIN_USER, postAdminUserSaga)
}
