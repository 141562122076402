import {
  api,
  MULTIPART_HEADERS,
  getFileFromURL,
  DEFAULT_QUERY_LIMIT
} from '@gk-devteam/apmc-core-web'

export const fetchNotices = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || '',
    sort_order: sort_order || 'asc'
  }

  return api.get('notices', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token

  })
}

export const fetchNotice = (id, cancelSource) => {
  return api.get(`notices/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const fetchNoticeTags = (cancelSource) => {
  return api.get('notice_tags', {
    cancelToken: cancelSource.token
  })
}
export const postNotice = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }
    delete data.file
    delete data.file_url
    delete data.file_filename
    delete data.recipients_list

    formData.append('data', JSON.stringify(data))
    return api.post('notices/', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}
export const deleteNotice = (id, cancelSource) => {
  return api.delete(`notices/${id}`, {
    cancelToken: cancelSource.token
  })
}
export const sendNotice = (notice_id, cancelSource) => {
  return api.post('send_notice', {
    notice_id
  }, {
    cancelToken: cancelSource.token
  })
}
export const stopNotice = (id, cancelSource) => {
  return api.post(`/notices/${id}/stop`, null, {
    cancelToken: cancelSource.token
  })
}
