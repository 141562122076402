import { createReducer } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import {
  FETCH_AREAS,
  FETCH_AREAS_SUCCESS,
  FETCH_AREAS_FAIL,
  RESET_AREAS
} from '../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  data: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_AREAS]: (state, action) => {
    state.loading = true
    state.data = null
  },
  [FETCH_AREAS_SUCCESS]: (state, action) => {
    const areas = keyBy(action.payload, 'prefecture')
    for (const key in areas) {
      const prefecture = areas[key]
      areas[key] = {
        ...prefecture,
        cities: keyBy(prefecture.cities, 'city')
      }

      for (const cityKey in prefecture.cities) {
        const city = prefecture.cities[cityKey]
        areas[key].cities[city.city] = {
          ...city,
          areas: keyBy(city.areas, 'area_id')
        }
      }
    }
    state.loading = false
    state.data = areas
  },
  [FETCH_AREAS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_AREAS]: (state, action) => {
    state.loading = true
    state.error = null
    state.data = []
  }
})
