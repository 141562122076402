import { createSelector } from 'reselect'

const selectConstructionWorks = state => state.yasueConstructionWorks

export const selectLoadingConstructionWorks = createSelector(
  selectConstructionWorks,
  constructionWorks => constructionWorks.loading
)

export const selectConstructionWorksCount = createSelector(
  selectConstructionWorks,
  constructionWorks => constructionWorks.results_count
)
export const selectConstructionWorksData = createSelector(
  selectConstructionWorks,
  constructionWorks => constructionWorks.results
)
