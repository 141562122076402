import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  INIT_FORM,
  Row
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyName,
  selectFaqLoading,
  selectFaqCategoriesOptionsWithReset,
  selectFaqHiddendBy,
  selectFaqHiddendById
} from '../../../../selectors'
import { FETCH_FAQ, RESET_FAQ } from '../../../../types'
import initialData from '../common/initialData'

import AdminFaqList from './AdminFaqList'
import PropertyFaqList from './PropertyFaqList'
import FaqModal from '../common/FaqModal'
import PanelLayout from '../../../../components/Display/Panels/PanelLayout'

import { FilterStyle } from '../faq.style'

const PropertyFaqsPage = ({ propertyID }) => {
  const dispatch = useDispatch()
  const propertyName = useSelector(selectPropertyName)
  const loading = useSelector(selectFaqLoading)
  const categoriesOptions = useSelector(selectFaqCategoriesOptionsWithReset)
  const [modalVisible, setModalVisible] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [categoryFilter, setCategoryFilter] = useState('')

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_faq',
      link: `/properties/edit/${propertyID}/faq`
    }
  ]

  useEffect(() => {
    if (propertyID) {
      dispatch({ type: FETCH_FAQ, config: { property: propertyID } })
    }
    return () => {
      dispatch({ type: RESET_FAQ })
    }
  }, [propertyID, dispatch])

  const _handleFilterChange = (value) => {
    setCategoryFilter(value)
  }

  const _openAddModal = () => {
    dispatch({ type: INIT_FORM, payload: initialData })
    setModalVisible(true)
  }
  const _openCopyModal = () => {
    setModalVisible(true)
  }
  const _openEditModal = () => {
    setModalVisible(true)
    setEditMode(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
    setEditMode(false)
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectFaqHiddendBy}
      hiddenByIdSelector={selectFaqHiddendById}
      title="navigation:properties_faq"
      authorized="faq.functions.add"
      info="services:level_message.property"
      infoTitle="services:level_message.propertyTitle"
      handleAddClick={_openAddModal}
    >
      <FaqModal
        visible={modalVisible}
        onCancel={_closeModal}
        type="property"
        editMode={editMode}
        id={propertyID}
      />
      <Row mt="L">
        <FilterStyle
          mb="0"
          noLabel
          handleChange={_handleFilterChange}
          value={categoryFilter}
          name="category_filter"
          options={categoriesOptions}
          loading={loading}
        />
      </Row>
      <AdminFaqList filter={categoryFilter} copyModal={_openCopyModal} editModal={_openEditModal} />
      <PropertyFaqList filter={categoryFilter} copyModal={_openCopyModal} editModal={_openEditModal} />
    </PanelLayout>
  )
}
export default PropertyFaqsPage

PropertyFaqsPage.propTypes = {
  propertyID: PropTypes.string.isRequired
}
PropertyFaqsPage.defaultProps = {}
