import Cookies from 'js-cookie'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/messaging'
import { api, USER_IDENTITY_COOKIE } from '@gk-devteam/apmc-core-web'

const firestoreConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
}

firebase.initializeApp(firestoreConfig)
export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()

const fetchFirestoreToken = () => {
  if (Cookies.get(USER_IDENTITY_COOKIE)) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.get('generate_fire_token')
        if (res && res.data && res.data.firebase_token) {
          resolve(res.data.firebase_token)
        } else {
          reject(new Error('No token'))
        }
      } catch (error) {
        reject(error)
      }
    })
  }
}

const keepUserLoggedIn = (callback) => {
  const unregisterAuthObserver = auth.onAuthStateChanged(async user => {
    if (user) {
      // console.success('User is already signed in.')
      if (callback) callback(user)
    } else {
      // No user is signed in.
      try {
        const token = await fetchFirestoreToken()
        if (token) {
          const loggedInUser = await auth.signInWithCustomToken(token)
          // console.success('Signed in user successfully.')
          if (callback) callback(loggedInUser)
        }
      } catch (error) {
        // Handle Errors here.
        // var errorCode = error.code
        // var errorMessage = error.message
        console.warn(error)
      }
    }
    unregisterAuthObserver()
  })
}

export const checkIfLoggedIn = (callback) => {
  // console.debug('auth', auth)
  // console.debug('currentUser', auth.currentUser)
  if (auth.currentUser) {
    if (callback) callback(auth.currentUser)
  } else {
    keepUserLoggedIn(callback)
  }
}

export const signOutUser = () => {
  // console.debug('Signing out from firebase...', auth)
  return auth.signOut()
}

export const initializeFCM = async (registerToken) => {
  if (firebase.messaging && firebase.messaging.isSupported() && registerToken) {
    const messaging = firebase.messaging()
    if (!messaging.vapidKey) {
      messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_VAPID_KEY)
    }

    try {
      const currentToken = await messaging.getToken()
      if (currentToken) {
        console.success('FCM Token retrieved')
        registerToken(currentToken)
      } else {
        console.log('No Instance ID token available.')
      }
    } catch (error) {
      console.warn('An error occurred while retrieving FCM token.', error)
    }

    messaging.onTokenRefresh(async () => {
      try {
        const refreshedToken = await messaging.getToken()
        if (refreshedToken) {
          console.success('Token refreshed.')
          registerToken(refreshedToken)
        } else {
          console.log('No Instance ID token available.')
        }
      } catch (error) {
        console.warn('An error occurred while retrieving refreshed token. ', error)
      }
    })

    messaging.onMessage((payload) => {
      // console.success('payload', payload)
      if (payload.notification && payload.notification.body) {
        console.success('notification', payload.notification)
      }
    })
  }
}
