import React, { memo } from 'react'
import { Router, LocationProvider, createHistory } from '@reach/router'
import { listenHistory } from '@gk-devteam/apmc-core-web'

import ForgotPassword from '../pages/auth/ForgotPassword'
import ChangePassword from '../pages/auth/ChangePassword'
import CreatePassword from '../pages/auth/CreatePassword'
import PrivateRouter from './PrivateRouter'
import Initializer from './Initializer'

const history = createHistory(window)
listenHistory({ history })

const Routes = () => {
  return (
    <LocationProvider history={history}>
      <Initializer />
      <Router>
        <ForgotPassword path="/forgot_password"/>
        <ChangePassword path="/change_password"/>
        <CreatePassword path="/create_password"/>
        <PrivateRouter path="/*"/>
      </Router>
    </LocationProvider>
  )
}

export default memo(Routes)
