import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_INSPECTIONS,
  FETCH_INSPECTIONS_SUCCESS,
  FETCH_INSPECTIONS_FAIL,
  RESET_INSPECTIONS
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  max_pages: null,
  results_count: null,
  results: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_INSPECTIONS]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_INSPECTIONS_SUCCESS]: (state, action) => {
    // console.debug('here')
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_INSPECTIONS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_INSPECTIONS]: (state, action) => {
    state.loading = true
    state.error = null
    state.max_pages = null
    state.results_count = null
    state.results = []
  }
})
