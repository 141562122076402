export const FETCH_CONTRACTS_PROCESSING_COUNT = 'fetch_contracts_processing_count'
export const FETCH_CONTRACTS_PROCESSING_COUNT_SUCCESS = 'fetch_contracts_processing_count_success'
export const FETCH_CONTRACTS_PROCESSING_COUNT_FAIL = 'fetch_contracts_processing_count_fail'
export const RESET_CONTRACTS_PROCESSING_COUNT = 'reset_contracts_processing_count'

export const FETCH_CONTRACTS_PROCESSING = 'fetch_contracts_processing'
export const FETCH_CONTRACTS_PROCESSING_SUCCESS = 'fetch_contracts_processing_success'
export const FETCH_CONTRACTS_PROCESSING_FAIL = 'fetch_contracts_processing_fail'
export const RESET_CONTRACTS_PROCESSING = 'reset_contracts_processing'

export const FETCH_CONTRACTS_RENEWING = 'fetch_contracts_renewing'
export const FETCH_CONTRACTS_RENEWING_SUCCESS = 'fetch_contracts_renewing_success'
export const FETCH_CONTRACTS_RENEWING_FAIL = 'fetch_contracts_renewing_fail'
export const RESET_CONTRACTS_RENEWING = 'reset_contracts_renewing'

export const FETCH_CONTRACTS_CANCELING = 'fetch_contracts_canceling'
export const FETCH_CONTRACTS_CANCELING_SUCCESS = 'fetch_contracts_canceling_success'
export const FETCH_CONTRACTS_CANCELING_FAIL = 'fetch_contracts_canceling_fail'
export const RESET_CONTRACTS_CANCELING = 'reset_contracts_canceling'
