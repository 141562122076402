import {
  getColor,
  SPACING,
  styled
} from '@gk-devteam/apmc-core-web'
import { Table } from 'antd'

export const ConfirmTableContainerStyle = styled.div`
  margin-top: ${SPACING.LARGE}rem;
  width: ${props => `${props.width}px` || 'auto'};
`

export const TableStyle = styled(Table)`
  .ant-pagination {
    .ant-pagination-item-active {
      border-color: ${props => getColor(props, 'accent')};
      a {
        color: ${props => getColor(props, 'accent')};
      }
    }

    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a,
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: ${props => getColor(props, 'accent')};
    }

    .ant-pagination-prev, .ant-pagination-next {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:focus .ant-pagination-item-link,
      &:hover .ant-pagination-item-link {
        color: ${props => getColor(props, 'accent')};
      }
      & > button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
      }
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: ${props => getColor(props, 'accent')};
    }

    .ant-pagination-item-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
    }
  }
`

export const ProgressContainerStyle = styled.div`
  width: 75%;
  margin: auto;
  padding: 75px 0;
  box-sizing: border-box;
`
