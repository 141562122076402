import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  MainLayout,
  ItemNotFound
} from '@gk-devteam/apmc-core-web'

import {
  selectMemberName,
  selectMemberData,
  selectMemberError,
  selectLoadingMember
} from '../../../selectors'
import { FETCH_MEMBER, RESET_MEMBER } from '../../../types'

import MemberForm from '../../../forms/userManagement/MemberForm'

const MemberDetailsPage = ({ memberID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const data = useSelector(selectMemberData)
  const memberName = useSelector(selectMemberName)
  const error = useSelector(selectMemberError)
  const loading = useSelector(selectLoadingMember)

  useEffect(() => {
    if (memberID) {
      dispatch({ type: FETCH_MEMBER, id: memberID })
    }
    return () => {
      dispatch({ type: RESET_MEMBER })
    }
  }, [dispatch, memberID])

  const breadcrumbs = [
    {
      label: 'navigation:management',
      link: '/user_management'
    },
    {
      text: memberName || '',
      link: `/user_management/members/edit/${memberID}`
    }
  ]

  if (error && error.code === 404) return <ItemNotFound withSidebar item={t('user_management:members')} link="/user_management" />

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
      lastEdited={data && data.modified}
      lastEditedBy={data && data.modifiedBy}
    >
      {
        !loading && !error ? <MemberForm data={data} memberID={memberID}/> : null
      }
    </MainLayout>
  )
}
export default MemberDetailsPage

MemberDetailsPage.propTypes = {
  memberID: PropTypes.string.isRequired
}
MemberDetailsPage.defaultProps = {

}
