import { createReducer } from '@reduxjs/toolkit'
import {
  DELETE_SPACE,
  DELETE_SPACE_FAIL,
  FETCH_SPACES,
  FETCH_SPACES_FAIL,
  FETCH_SPACES_RESERVATIONS,
  FETCH_SPACES_RESERVATIONS_FAIL,
  FETCH_SPACES_RESERVATIONS_SUCCESS,
  FETCH_SPACES_SUCCESS,
  FETCH_SPACES_WAITING_RESERVATIONS,
  FETCH_SPACES_WAITING_RESERVATIONS_FAIL,
  FETCH_SPACES_WAITING_RESERVATIONS_SUCCESS,
  RESET_SPACES,
  RESET_SPACES_RESERVATIONS
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  max_pages: null,
  results_count: null,
  results: [],
  reservations_loading: null,
  reservations_max_pages: null,
  reservations_count: null,
  reservations: [],
  reservations_error: null,
  waiting_reservations_loading: null,
  waiting_reservations_count: null,
  waiting_reservations: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_SPACES]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_SPACES_SUCCESS]: (state, action) => {
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_SPACES_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_SPACES]: () => INITIAL_STATE,
  [DELETE_SPACE]: (state) => {
    state.loading = true
  },
  [DELETE_SPACE_FAIL]: (state) => {
    state.loading = false
  },
  [FETCH_SPACES_RESERVATIONS]: (state, action) => {
    state.reservations_loading = true
  },
  [FETCH_SPACES_RESERVATIONS_SUCCESS]: (state, action) => {
    const { max_pages, results_count, results } = action.payload
    state.reservations_loading = false
    state.reservations_max_pages = max_pages
    state.reservations_count = results_count
    state.reservations = results
  },
  [FETCH_SPACES_RESERVATIONS_FAIL]: (state, action) => {
    state.reservations_loading = false
    state.reservations_error = action.payload
  },
  [RESET_SPACES_RESERVATIONS]: (state) => {
    state.reservations_loading = null
    state.reservations = null
    state.reservations_error = null
  },
  [FETCH_SPACES_WAITING_RESERVATIONS]: (state, action) => {
    state.waiting_reservations_loading = true
  },
  [FETCH_SPACES_WAITING_RESERVATIONS_SUCCESS]: (state, action) => {
    const { results_count, results } = action.payload
    state.waiting_reservations_count = results_count
    state.waiting_reservations = results
    state.waiting_reservations_loading = false
  },
  [FETCH_SPACES_WAITING_RESERVATIONS_FAIL]: (state, action) => {
    state.waiting_reservations_loading = false
  }
})
