import Cookies from 'js-cookie'
import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'
import {
  encrypt,
  USER_FCM_COOKIE
} from '@gk-devteam/apmc-core-web'

import {
  fetchFCMStatus,
  postFCMToken,
  deleteFCMTokens,
  deleteFCMToken,
  fetchTheme,
  postTheme
} from '../../services'

import {
  FETCH_FCM_STATUS,
  FETCH_FCM_STATUS_SUCCESS,
  FETCH_FCM_STATUS_FAIL,
  POST_FCM_TOKEN,
  POST_FCM_TOKEN_SUCCESS,
  POST_FCM_TOKEN_FAIL,
  DELETE_FCM_TOKEN,
  DELETE_FCM_TOKEN_SUCCESS,
  DELETE_FCM_TOKEN_FAIL,
  FETCH_THEME,
  FETCH_THEME_SUCCESS,
  FETCH_THEME_FAIL,
  POST_THEME,
  POST_THEME_SUCCESS,
  POST_THEME_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchFCMStatusSaga () {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchFCMStatus, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_FCM_STATUS_SUCCESS, payload: res.data.authorized })
    } else {
      yield put({ type: FETCH_FCM_STATUS_FAIL, error: 'No data' })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_FCM_STATUS_FAIL, error })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postFCMTokenSaga ({ data, onSuccess, onFail }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postFCMToken, data, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: POST_FCM_TOKEN_SUCCESS })
      yield put({ type: FETCH_FCM_STATUS })
      yield call(Cookies.set, USER_FCM_COOKIE, encrypt(JSON.stringify(data)), { expires: 365, secure: process.env.REACT_APP_STAGE === 'production' })
      if (onSuccess) yield call(onSuccess)
    } else {
      yield put({ type: POST_FCM_TOKEN_FAIL })
      if (onFail) yield call(onFail)
    }
  } catch (error) {
    yield put({ type: POST_FCM_TOKEN_FAIL, payload: error.data || error.message })
    if (onFail) yield call(onFail)
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteFCMTokenSaga ({ token, onSuccess, onFail }) {
  const cancelSource = CancelToken.source()
  try {
    let res
    if (token) {
      res = yield call(deleteFCMToken, token, cancelSource)
    } else {
      res = yield call(deleteFCMTokens, cancelSource)
    }
    if (res) {
      yield put({ type: DELETE_FCM_TOKEN_SUCCESS })
      yield call(Cookies.remove, USER_FCM_COOKIE)
      yield put({ type: FETCH_FCM_STATUS_SUCCESS, payload: false })
      if (onSuccess) yield call(onSuccess)
    }
  } catch (error) {
    yield put({ type: DELETE_FCM_TOKEN_FAIL, payload: error.data || error.message })
    if (onFail) yield call(onFail)
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * fetchThemeSaga () {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchTheme, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_THEME_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_THEME_FAIL, error: 'No data' })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_THEME_FAIL, error })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postThemeSaga ({ data }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postTheme, data, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: POST_THEME_SUCCESS })
      yield put({ type: FETCH_THEME })
    } else {
      yield put({ type: POST_THEME_FAIL })
    }
  } catch (error) {
    yield put({ type: POST_THEME_FAIL, payload: error.data || error.message })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchUserPreferences () {
  yield takeLatest(FETCH_FCM_STATUS, fetchFCMStatusSaga)
  yield takeLatest(POST_FCM_TOKEN, postFCMTokenSaga)
  yield takeLatest(DELETE_FCM_TOKEN, deleteFCMTokenSaga)
  yield takeLatest(FETCH_THEME, fetchThemeSaga)
  yield takeLatest(POST_THEME, postThemeSaga)
}
