import {
  styled,
  CustomText,
  FONT_SIZE,
  SPACING,
  Column
} from '@gk-devteam/apmc-core-web'


export const LoaderContainerStyle = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0%;
  right: 0;
  background-color: rgba(255,255,255,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CustomTextStyle = styled(CustomText)`
    ${FONT_SIZE.LARGE}
    font-weight: bold;
`

export const ChartWrapper = styled.div`
    width: 100%;
    height: 400px;
`

export const ColumnStyle = styled(Column)`
  width: 264px;
  margin-bottom: ${SPACING.LARGE}rem;
`
