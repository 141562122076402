import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  Form,
  Card,
  Row,
  Title,
  FilePicker,
  FormSubmitButton,
  TextInput,
  Select,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  selectLoadingAdminUser,
  selectPostingAdminUser,
  selectUserRole
} from '../../../selectors'
import { userProfileUpdateValidation } from '../../../validation'
import { POST_ADMIN_USER, FETCH_ADMIN_USER } from '../../../types'
import { ADMIN_USER_ROLE_OPTIONS, ROLES } from '../../../constants'

import SelectItems from '../../userManagement/common/SelectItems'

const AdminUserForm = ({ data }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(selectUserRole)
  const userID = data && data.id

  const initialValues = {
    ...data,
    file: (data && data.file) || null,
    delete_file: (data && data.delete_file) || false,
    groups: data.groups_list && data.groups_list.length > 0 ? data.groups_list.map(item => item.id) : null,
    properties: data.properties_list && data.properties_list.length > 0 ? data.properties_list.map(item => item.id) : null,
    groups_list: data.groups_list && data.groups_list.length > 0 ? data.groups_list : null,
    properties_list: data.properties_list && data.properties_list.length > 0 ? data.properties_list : null,
    password: '',
    new_password: ''
  }

  const handleFormSubmit = (data) => {
    const successRedirect = (id) => {
      if (userID) {
        dispatch({ type: FETCH_ADMIN_USER, id: userID })
      } else {
        navigate('/settings')
      }
    }
    dispatch({ type: POST_ADMIN_USER, data, userID, successRedirect })
  }

  return (
    <Form
      id="updateSettingUserProfileForm"
      handleSubmit={handleFormSubmit}
      validationSchema={userProfileUpdateValidation}
      loadingSelector={selectLoadingAdminUser}
      postingSelector={selectPostingAdminUser}
      initialData={initialValues}
      authorized="settings.functions.edit_profile"
      // debug
    >
      <Row justify="between" mb="ML">
        <Title label='navigation:settings_user_profile' />
        <RoleControlled authorized="settings.functions.edit_profile">
          <FormSubmitButton editMode postingSelector={selectPostingAdminUser} />
        </RoleControlled>
      </Row>
      <>
        <Card mb="L">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              type="text"
              name="name"
              label='name'
              placeholder=""
              required
            />
            <TextInput
              flex={1}
              type="text"
              name="furigana"
              label='furigana'
              placeholder=""
              required
            />
            <TextInput
              flex={1}
              type="text"
              name="mail_address"
              label='email'
              placeholder="placeholders.email"
              required
            />
          </Row>
          <Row wrap="wrap" align="start">
            <TextInput
              flex={1}
              type="text"
              name="phone"
              label='phone'
              placeholder="placeholders.phone"
            />
            <RoleControlled authorized={userRole !== ROLES.GUEST}>
              <TextInput
                flex={1}
                type="text"
                name="office"
                label='user_management:member.office'
              />
              <TextInput
                flex={1}
                type="text"
                name="department"
                label='user_management:member.department'
              />
            </RoleControlled>
          </Row>
          <RoleControlled authorized={userRole !== ROLES.GUEST}>
            <Row wrap="wrap">
              <TextInput
                flex={1}
                type="text"
                name="position"
                label='user_management:member.position'
              />
              <Select
                flex={1}
                type="text"
                name="role"
                label='user_management:member.role'
                options={ADMIN_USER_ROLE_OPTIONS}
                disabled
              />
            </Row>
          </RoleControlled>
        </Card>
        <RoleControlled authorized={userRole !== ROLES.SUPPORT}>
          <Card mb="L" title="user_management:change_password">
            <TextInput
              flex={1}
              type="password"
              name="password"
              label='login:password'
              placeholder=""
              mr="0"
              data-private
            />
            <TextInput
              flex={1}
              type="password"
              name="new_password"
              label='login:new_password'
              placeholder="common:placeholders.password"
              data-private
            />
          </Card>
        </RoleControlled>
        <Row wrap="wrap" align="baseline" justify={'between'} mb="ML">
          <Card title={'image'}>
            <FilePicker
              name="file"
              delete_name="delete_file"
              types={['image']}
            />
          </Card>
        </Row>
      </>
      <RoleControlled authorized={userRole !== ROLES.SUPPORT}>
        {
          data &&
          ((data.groups_list && data.groups_list.length > 0) ||
          (data.properties_list && data.properties_list.length > 0))
            ? (
              <Card title='user_management:groups-properties' mt="L">
                <SelectItems itemsKey="groups" readOnly/>
                <SelectItems itemsKey="properties" readOnly/>
              </Card>
            )
            : null
        }
      </RoleControlled>
    </Form>
  )
}
export default AdminUserForm

AdminUserForm.propTypes = {
  data: PropTypes.object
}
AdminUserForm.defaultProps = {

}
