import {
  api,
  DEFAULT_QUERY_LIMIT,
  getFileFromURL,
  MULTIPART_HEADERS
} from '@gk-devteam/apmc-core-web'

export const fetchCoupons = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }

  return api.get('/coupons', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token
  })
}

export const fetchCoupon = (id, params = {}, cancelSource) => {
  const {
    limit,
    page
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1
  }

  return api.get(`/coupons/${id}`, {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token
  })
}

export const postCoupon = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.image) {
      try {
        const image = await getFileFromURL(data.image)
        formData.append('image', image, data.image_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }
    delete data.image
    delete data.image_url
    delete data.image_filename
    delete data.recipients_list

    formData.append('data', JSON.stringify(data))
    return api.post('/coupons/', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const deleteCoupon = (id, cancelSource) => {
  return api.delete(`/coupons/${id}`, {
    cancelToken: cancelSource.token
  })
}
