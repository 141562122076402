import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Row,
  Title,
  Card,
  InquiryID,
  FilePicker,
  FormSubmitButton,
  RoleControlled,
  Form,
  TextInput,
  TextArea,
  Select,
  Radio
} from '@gk-devteam/apmc-core-web'

import {
  selectLoadingNotice,
  selectPostingNotice,
  selectNoticeEditable
} from '../../selectors'
import { noticeUpdateValidation } from '../../validation'
import { POST_NOTICE } from '../../types'
import { fetchNoticeTags } from '../../services'
import { INQUIRY_PREFIX, NOTICE_PUSH_NOTIFICATION_OPTIONS } from '../../constants'

import NoticeType from './subForms/NoticeType'
import NoticeStopTime from './subForms/NoticeStopTime'
import NoticeRecipientsSettings from './subForms/NoticeRecipients/NoticeRecipientsSettings'
import NoticeStatus from './NoticeStatus'

import { NoticeFormMainBlockStyle } from './NoticeForm.style.js'

const NoticeForm = ({ data, noticeID }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [tags, setTags] = useState(null)
  const [loadingTags, setLoadingTags] = useState(true)
  const editable = useSelector(selectNoticeEditable)

  const initialValues = {
    ...data,
    file: data?.file || null,
    delete_file: data?.delete_file || false
  }

  if (data && data.recipient_type === 0) {
    initialValues.recipients_list = null
  }

  const fetchTags = useCallback(
    async () => {
      try {
        setLoadingTags(true)
        const cancelSource = CancelToken.source()
        const res = await fetchNoticeTags(cancelSource)
        if (res.data) {
          setLoadingTags(false)
          setTags(res.data.map(item => {
            const { name, id } = item
            return { label: name, value: id }
          }))
        } else {
          message.error(t('fetch_error'))
          setLoadingTags(false)
        }
      } catch (error) {
        message.error(t('fetch_error'))
        setLoadingTags(false)
      }
    },
    [t]
  )

  useEffect(() => {
    fetchTags()
  }, [noticeID, fetchTags])

  const handleFormSubmit = (data) => {
    const successRedirect = (id) => {
      // if (noticeID) {
      //   dispatch({ type: FETCH_NOTICE, id: noticeID })
      // } else {
      //   navigate(`/notices/edit/${id}`)
      // }
      navigate('/notices')
    }
    dispatch({ type: POST_NOTICE, data, successRedirect })
  }

  return (
    <Form
      id="updateAppUserForm"
      handleSubmit={handleFormSubmit}
      validationSchema={noticeUpdateValidation}
      loadingSelector={selectLoadingNotice}
      postingSelector={selectPostingNotice}
      initialData={initialValues}
      authorized="notices.functions.update"
      // debug
    >
      <Row justify="between" >
        {
          noticeID
            ? <Title text={data && data.title} />
            : <Title label='notices:add_notice' />
        }
        <RoleControlled authorized='notices.functions.update'>
          <FormSubmitButton editMode={!!noticeID} postingSelector={selectPostingNotice} />
        </RoleControlled>
      </Row>
      <Row mb="ML">
        <InquiryID id={noticeID} prefix={INQUIRY_PREFIX.NOTICE} />
      </Row>
      <Row wrap="wrap">
        <NoticeFormMainBlockStyle>
          <NoticeStatus />
          <Card mb="L">
            <Row>
              <Radio
                name="push_notification"
                label='notices:push_notification'
                options={NOTICE_PUSH_NOTIFICATION_OPTIONS}
                readonly={!editable}
              />
            </Row>
            <Row wrap="wrap" align="baseline">
              <TextInput
                flex={1}
                type="text"
                name="title"
                label='title'
                placeholder=""
                required
              />
              <Select
                flex={1}
                type="text"
                name="tag"
                label='tag'
                placeholder=""
                options={tags}
                loading={loadingTags}
                required
                noClear
              />
            </Row>
            <Row wrap="wrap" align="baseline">
              <TextInput
                flex={1}
                type="text"
                name="url"
                label='external_url'
                placeholder=""
              />
              <TextInput
                flex={1}
                type="text"
                name="video_url"
                label='video_url'
                placeholder=""
              />
            </Row>
            <Row wrap="wrap" align="baseline">
              <TextArea
                flex={1}
                name="content"
                label='content'
                placeholder=""
                maxLength={10000}
                required
              />
            </Row>
          </Card>
          <Card title='notices:attachment_file' mb="L">
            <FilePicker
              name="file"
              delete_name="delete_file"
              types={['image', 'pdf']}
            />
          </Card>
          <Card title='notices:recipient_type_settings' mb="L">
            <NoticeType />
            <NoticeStopTime />
          </Card>
          <NoticeRecipientsSettings />
        </NoticeFormMainBlockStyle>
        {/* <NoticeFile /> */}
      </Row>
    </Form>
  )
}
export default NoticeForm

NoticeForm.propTypes = {
  data: PropTypes.object,
  noticeID: PropTypes.string
}
NoticeForm.defaultProps = {

}
