import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import {
  Calendar,
  MainLayout,
  PrimaryButton,
  RESET_PARAMS,
  Row,
  selectQueryParams,
  Title,
  updateQueryParameters
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { selectPropertyEventsLoading, selectPropertyName } from '../../../selectors'
import { propertyEventsSearchSchema } from '../../../validation'
import { FETCH_PROPERTY_EVENTS, RESET_PROPERTIES, TOGGLE_ADD_PROPERTY_EVENT_MODAL } from '../../../types'

import AddEventModal from './AddEventModal'
import DayEventsModal from './DayEventsModal'
import PropertyEventCell from './PropertyEventCell'
import PropertyEventRow from './PropertyEventRow'

export default function PropertyEventsPage ({ propertyID, location }) {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const prevQuery = useRef(null)
  const propertyName = useSelector(selectPropertyName)
  const loading = useSelector(selectPropertyEventsLoading)

  const fetchData = useCallback(
    async (params) => {
      if (propertyID) {
        const schema = propertyEventsSearchSchema()
        const currentQuery = {
          property_id: propertyID,
          ...query,
          ...params
        }
        schema.isValid(currentQuery)
          .then(valid => {
            if (valid) {
              dispatch({ type: FETCH_PROPERTY_EVENTS, query: currentQuery })
            } else {
              console.warning('params invalid, query using default params')
              dispatch({ type: FETCH_PROPERTY_EVENTS, query: { property_id: propertyID } })
            }
          })
      }
    },
    [dispatch, query, propertyID]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_PROPERTIES })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'properties:events_list',
      link: `/properties/edit/${propertyID}/events`
    }
  ]

  const _handleMonthChange = (date) => {
    dispatch(updateQueryParameters({ date }))
  }

  const _handleAddClick = () => {
    dispatch({ type: TOGGLE_ADD_PROPERTY_EVENT_MODAL })
  }

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      <AddEventModal />
      <DayEventsModal />
      <Row justify="between" align="center" my="R" wrap={'wrap'}>
        <Title label="properties:events_list"/>
        <PrimaryButton
          label="properties:events.add"
          handleClick={_handleAddClick}
          reverse
          withMargin
        />
      </Row>
      <Calendar
        initialMonth={query?.date}
        onMonthChange={_handleMonthChange}
        customDayCell={PropertyEventCell}
        customDayRow={PropertyEventRow}
      />
    </MainLayout>
  )
}

PropertyEventsPage.propTypes = {
  location: PropTypes.object.isRequired,
  propertyID: PropTypes.string.isRequired
}
PropertyEventsPage.defaultProps = {

}
