import { api, DEFAULT_QUERY_LIMIT } from '@gk-devteam/apmc-core-web'

export const fetchChecklistHome = (cancelSource) => {
  return api.get('admin_tasks', {
    cancelToken: cancelSource.token
  })
}
export const fetchChecklist = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'status',
    sort_order: sort_order || 'desc'
  }

  return api.get('admin_tasks/checklist', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token
  })
}

export const fetchChecklistIssue = (checklistID, cancelSource) => {
  return api.get(`admin_tasks/checklist/${checklistID}`, {
    cancelToken: cancelSource.token
  })
}

export const postChecklistIssueComment = (checklistID, data, cancelSource) => {
  return api.post(`admin_tasks/checklist/${checklistID}`, data, {
    cancelToken: cancelSource.token
  })
}

export const updateChecklistIssuesStatus = (data, cancelSource) => {
  return api.post('admin_tasks/checklist/status', data, {
    cancelToken: cancelSource.token
  })
}
export const updateChecklistIssueStatus = (checklistID, data, cancelSource) => {
  return api.post(`admin_tasks/checklist/${checklistID}/status`, data, {
    cancelToken: cancelSource.token
  })
}

export const fetchChecklistSettings = (cancelSource) => {
  return api.get('settings/checklist', {
    cancelToken: cancelSource.token
  })
}
export const postChecklistSettings = (data, cancelSource) => {
  return api.post('settings/checklist', data, {
    cancelToken: cancelSource.token
  })
}
