import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  Form,
  Card,
  Row,
  Title,
  FilePicker,
  FormSubmitButton,
  RoleControlled,
  TextInput
} from '@gk-devteam/apmc-core-web'

import {
  selectLoadingSettingAdminProfile,
  selectPostingSettingAdminProfile,
  selectUserRole
} from '../../../selectors'
import { adminProfileUpdateValidation } from '../../../validation'
import { POST_ADMIN_PROFILE, FETCH_ADMIN_PROFILE } from '../../../types'
import { ROLES } from '../../../constants'

import { CheckboxCustomStyle } from './AdminProfileForm.style.js'

const AdminProfileForm = ({ data }) => {
//   const enabled = useSelector(state => state.form.property_owner_enabled)
  const enabled = false
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(selectUserRole)
  const ownerID = data && data.id

  const initialValues = {
    ...data,
    file: (data && data.file) || null,
    delete_file: (data && data.delete_file) || false
  }

  const handleFormSubmit = (data) => {
    delete data.id
    delete data.name
    delete data.furigana
    const successRedirect = (id) => {
      if (ownerID) {
        dispatch({ type: FETCH_ADMIN_PROFILE, id: ownerID })
      } else {
        navigate('/settings')
      }
    }
    dispatch({ type: POST_ADMIN_PROFILE, data, id: ownerID, successRedirect })
  }
  return (
    <Form
      id="updateSettingAdminProfileForm"
      handleSubmit={handleFormSubmit}
      validationSchema={adminProfileUpdateValidation}
      loadingSelector={selectLoadingSettingAdminProfile}
      postingSelector={selectPostingSettingAdminProfile}
      initialData={initialValues}
      authorized="settings.functions.edit_adminProfile"
      // debug
    >
      <Row justify="between" mb="ML">
        <Title label={userRole && userRole === ROLES.OWNER ? 'navigation:settings_owner_profile' : 'navigation:settings_admin_profile'} />
        <RoleControlled authorized="settings.functions.edit_adminProfile">
          <FormSubmitButton editMode postingSelector={selectPostingSettingAdminProfile} />
        </RoleControlled>
      </Row>
      <>
        <Card mb="L">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              type="text"
              name="name"
              label='name'
              placeholder=""
              readonly
            />
            <TextInput
              flex={1}
              type="text"
              name="furigana"
              label='furigana'
              placeholder=""
              readonly
            />
          </Row>
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              type="text"
              name="address"
              label='address'
              placeholder=""
              required
            />
          </Row>
          <Row wrap="wrap" align="start">
            <TextInput
              flex={1}
              type="text"
              name="phone"
              label='phone'
              placeholder="placeholders.phone"
            />
            <CheckboxCustomStyle
              name="display_phone"
              inputLabel='properties:display_phone'
              readonly={!enabled}
              valueLabel='permission'
            />
            <TextInput
              flex={1}
              type="text"
              name="contact_mail_address"
              label='properties:contact_mail_address'
              placeholder="placeholders.email"
            />
          </Row>
          <Row wrap="wrap">
            <TextInput
              flex={1}
              type="text"
              name="url"
              label='homepage'
            />
          </Row>
          <Row wrap="wrap">
            <TextInput
              flex={1}
              type="text"
              name="business_hour"
              label='properties:contact_hours'
            />
          </Row>
        </Card>
        <Row wrap="wrap" align="baseline" justify={'between'} mb="ML">
          <Card title={'image'}>
            <FilePicker
              name="file"
              delete_name="delete_file"
              types={['image']}
            />
          </Card>
        </Row>
      </>
    </Form>
  )
}
export default AdminProfileForm

AdminProfileForm.propTypes = {
  data: PropTypes.object
}
AdminProfileForm.defaultProps = {

}
