import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS
} from '@gk-devteam/apmc-core-web'
import { Collapse } from 'antd'
const { Panel } = Collapse

export const BlockStyle = styled.div`
  margin-top: ${SPACING.LARGE}rem;
`

export const CollapseStyle = styled(Collapse)`
  &&& {
    ${BORDER_RADIUS};
    border: solid 1px ${props => getColor(props, 'cardBorder')};
    margin-top: ${SPACING.REGULAR}rem;

    .ant-collapse-header {
      background-color: ${props => getColor(props, 'white')};
    }

    .ant-collapse-content-box {
      padding: ${SPACING.SMALL}rem ${SPACING.REGULAR}rem;
    }

    .ant-collapse-content {
      overflow: inherit;

      & > .ant-collapse-content-box {
        padding-top: ${SPACING.SMALL}rem;
      }
    }


  }
`
export const PanelStyle = styled(Panel)`
  &&& {
    border: solid 1px ${props => getColor(props, 'cardBorder')};
    &:last-of-type {
      border-bottom: none;
    }
  }
`
