import {
  styled,
  Card,
  TextInput,
  SPACING
} from '@gk-devteam/apmc-core-web'

export const FileContainerStyle = styled(Card)`
  align-self: stretch;
  max-width: 240px;
  margin-right: ${SPACING.MEDIUM_LARGE}rem;
  box-sizing: border-box;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const InputContainerStyle = styled.div`
  flex: 1;
`

export const TextInputStyle = styled(TextInput)`
  &:not(:first-child) {
    margin-top: ${SPACING.SMALL}rem;
  }
`
