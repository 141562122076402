import React from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import {
  useTranslation,
  SERVER_TIMESTAMP_FORMAT
} from '@gk-devteam/apmc-core-web'

import NotificationMessage from './NotificationMessage'

const WaitingRooms = ({ data, type, closeDrawer }) => {
  const { t } = useTranslation()
  if (!data) return null

  const _parseMessage = (item) => {
    switch (type) {
      case 'chat': {
        const {
          id,
          userID,
          userName,
          userLastMessage,
          userLastMessageTime
        } = item
        return {
          userID: Number(userID),
          userName,
          content: userLastMessage,
          lastUpdated: userLastMessageTime && userLastMessageTime.seconds ? dayjs.unix(userLastMessageTime.seconds).format(SERVER_TIMESTAMP_FORMAT) : '',
          link: `/admin_tasks/chat/${id}`
        }
      }
      case 'checklist': {
        const {
          app_user_id,
          app_user_name,
          id,
          last_user_message,
          last_user_message_time
        } = item
        return {
          userID: app_user_id,
          userName: app_user_name,
          content: last_user_message || t('drawer.checklist_added'),
          lastUpdated: last_user_message_time,
          link: `/admin_tasks/checklist/${id}`
        }
      }
      default: return null
    }
  }

  return data.map((item, i) => <NotificationMessage message={_parseMessage(item)} key={i} closeDrawer={closeDrawer} />)
}

export default WaitingRooms

WaitingRooms.propTypes = {
  data: PropTypes.array,
  type: PropTypes.oneOf(['chat', 'checklist']),
  closeDrawer: PropTypes.func.isRequired
}
