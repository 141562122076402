import React, { useContext, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  UPDATE_FORM_ARRAY,
  Radio,
  ReadOnlyContext
} from '@gk-devteam/apmc-core-web'

import {
  SURVEY_RECIPIENT_TYPE_OPTIONS
} from '../../../constants'

const RecipientType = () => {
  const recipientType = useSelector(state => state.form.recipient_type)
  const isReadOnly = useContext(ReadOnlyContext)
  const dispatch = useDispatch()
  const prevTypeValue = useRef(null)

  useEffect(() => {
    if (prevTypeValue.current == null) {
      prevTypeValue.current = recipientType
    } else {
      if (prevTypeValue.current !== recipientType) {
        prevTypeValue.current = recipientType
        dispatch({
          type: UPDATE_FORM_ARRAY,
          payload: [
            {
              name: 'recipients_id',
              values: null
            },
            {
              name: 'recipients_list',
              values: null
            }
          ]
        })
      }
    }
  }, [recipientType, dispatch, prevTypeValue])

  return (
    <Radio
      name="recipient_type"
      options={SURVEY_RECIPIENT_TYPE_OPTIONS}
      readonly={isReadOnly}
    />
  )
}

export default RecipientType

RecipientType.propTypes = {}
