import { put, call, takeLatest, select, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchRoom,
  postRoom,
  deleteRoom
} from '../../services'
import {
  FETCH_ROOM,
  FETCH_ROOM_SUCCESS,
  FETCH_ROOM_FAIL,
  POST_ROOM,
  POST_ROOM_SUCCESS,
  POST_ROOM_FAIL,
  DELETE_ROOM,
  DELETE_ROOM_SUCCESS,
  DELETE_ROOM_FAIL,
  FETCH_ROOMS,
  FETCH_PROPERTY
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchRoomSaga ({ propertyID, roomID }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchRoom, propertyID, roomID, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_ROOM_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_ROOM_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_ROOM_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postRoomSaga ({ data, propertyID, success }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postRoom, data, propertyID, cancelSource)
    if (res && res.data && res.data.success) {
      if (success) yield call(success, propertyID, res.data.id)
      yield put({ type: POST_ROOM_SUCCESS })
      yield put({ type: FETCH_PROPERTY, id: propertyID })
      yield call(message.success, i18n.t('post_success'))
    } else {
      console.warning('error here', res)
      yield put({ type: POST_ROOM_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    console.warning('error', error)
    yield put({ type: POST_ROOM_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteRoomSaga ({ propertyID, roomID }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteRoom, propertyID, roomID, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_ROOM_SUCCESS })
      yield put({ type: FETCH_ROOMS, query, propertyID })
      yield put({ type: FETCH_PROPERTY, id: propertyID })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_ROOM_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchRoom () {
  yield takeLatest(FETCH_ROOM, fetchRoomSaga)
  yield takeLatest(POST_ROOM, postRoomSaga)
  yield takeLatest(DELETE_ROOM, deleteRoomSaga)
}
