import React, { memo } from 'react'
import { AppmeWrapper } from '@gk-devteam/apmc-core-web'

import i18nSettings from './locales/commonSettings'
import reducers from './reducers'
import sagas from './sagas'

import Router from './Routes/Router'

const App = () => {
  return (
    <AppmeWrapper
      config={{
        i18n: {
          settings: i18nSettings
        },
        helmet: {
          title: 'app-me! Cloud | 管理画面',
          url: 'https://appme-cloud.com'
        },
        store: {
          reducers,
          sagas
        }
      }}
    >
      <Router />
    </AppmeWrapper>
  )
}

export default memo(App)

App.propTypes = {}
