import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  RepeaterRemoveButton,
  TextInput,
  DatePicker
} from '@gk-devteam/apmc-core-web'

const ParkingRow = ({ index }) => {
  return (
    <Row wrap="wrap" align="baseline">
      <DatePicker
        name={`parking[${index}].parking_date`}
        label="refunds:parking_date"
        repeaterField
      />
      <TextInput
        flex={1}
        type="text"
        name={`parking[${index}].parking_name`}
        label='refunds:parking_name'
        repeaterField
      />
      <TextInput
        type="number"
        name={`parking[${index}].amount`}
        label='refunds:amount'
        suffix="currency_symbol"
        repeaterField
      />
      <RepeaterRemoveButton
        index={index}
        propertyKey="parking"
        label="delete"
      />
    </Row>
  )
}

export default memo(ParkingRow)

ParkingRow.propTypes = {
  index: PropTypes.number.isRequired
}
