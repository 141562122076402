import React from 'react'
import { useSelector } from 'react-redux'
// import PropTypes from 'prop-types'
import {
  COLORS,
  Row,
  Card,
  RoleControlled,
  selectUserRole
} from '@gk-devteam/apmc-core-web'

import { selectDashboardData } from '../../selectors'
import { ROLES } from '../../constants'

import {
  CustomTextStyle,
  ColumnStyle
} from './DashboardPage.style.js'

const DashboardWeeklyData = () => {
  const role = useSelector(selectUserRole)
  const data = useSelector(selectDashboardData)
  if (!data) return null
  const {
    contract_count,
    property_count,
    room_count,
    app_download_count
  } = data
  return (
    <Row mb='ML' wrap='wrap'>
      <ColumnStyle mx={'S'}>
        <Card title={'dashboard:contractCount'}>
          <CustomTextStyle label={`${contract_count}`} textColor={COLORS.text} />
        </Card>
      </ColumnStyle>
      <RoleControlled authorized='!custom.yasue'>
        <ColumnStyle mx={'S'}>
          <Card title={'dashboard:propertyCount'}>
            <CustomTextStyle label={`${property_count}`} textColor={COLORS.text} />
          </Card>
        </ColumnStyle>
      </RoleControlled>
      <RoleControlled authorized={role !== ROLES.SUPPORT}>
        <RoleControlled authorized='!custom.house_maker'>
          <RoleControlled authorized='!custom.yasue'>
            <ColumnStyle mx={'S'}>
              <Card title={'dashboard:roomCount'}>
                <CustomTextStyle label={`${room_count}`} textColor={COLORS.text} />
              </Card>
            </ColumnStyle>
          </RoleControlled>
        </RoleControlled>
        <ColumnStyle mx={'S'}>
          <Card title={'dashboard:appDownloadCount'}>
            <CustomTextStyle label={`${app_download_count}`} textColor={COLORS.text} />
          </Card>
        </ColumnStyle>
      </RoleControlled>
    </Row>
  )
}

export default DashboardWeeklyData

// DashboardWeeklyData.propTypes = {}
