import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  FilePicker,
  Form,
  Modal,
  RangePicker,
  Row,
  TextArea,
  TextInput
} from '@gk-devteam/apmc-core-web'

import { selectPropertyEventsAddModalState, selectPropertyID } from '../../../selectors'
import { POST_PROPERTY_EVENT, TOGGLE_ADD_PROPERTY_EVENT_MODAL } from '../../../types'
import { propertyEventSchema } from '../../../validation'

export default function AddEventModal () {
  const dispatch = useDispatch()
  const isVisible = useSelector(selectPropertyEventsAddModalState)
  const propertyID = useSelector(selectPropertyID)

  const _closeModal = () => {
    dispatch({ type: TOGGLE_ADD_PROPERTY_EVENT_MODAL })
  }
  const _saveEvent = (data) => {
    dispatch({ type: POST_PROPERTY_EVENT, data, successRedirect: _closeModal })
  }
  return (
    <Modal
      visible={isVisible}
      onCancel={_closeModal}
      onOk={_saveEvent}
      okText="save"
      title="properties:events.add"
      formID="add_event"
      large
      fullWidth
    >
      <Form
        id="add_event"
        handleSubmit={_saveEvent}
        validationSchema={propertyEventSchema}
        authorized="faq.functions.update"
        initialData={{
          property_id: propertyID,
          title: '',
          content: '',
          start_time: '',
          end_time: '',
          file: null,
          delete_file: false
        }}
      >
        <Row>
          <TextInput
            flex={1}
            type="text"
            name="title"
            label='properties:events.title'
            placeholder=""
            required
          />
        </Row>
        <Row>
          <TextArea
            flex={1}
            name="content"
            label='properties:events.content'
            placeholder=""
          />
        </Row>
        <Row>
          <RangePicker
            name_start="start_time"
            name_end="end_time"
            label='properties:events.event_date'
            placeholder=""
            showTime
          />
        </Row>
        <Card>
          <FilePicker
            flex={1}
            name="file"
            delete_name="delete_file"
            types={['pdf', 'image']}
            placeholder=""
          />
        </Card>
      </Form>
    </Modal>
  )
}
