import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from '@reach/router'
import {
  selectFormDirtyState,
  Centered,
  Card,
  Row,
  CustomButton,
  Form,
  TextInput,
  COLORS,
  SPACING,
  UPDATE_FORM,
  Link,
  useTranslation,
  LightText
} from '@gk-devteam/apmc-core-web'

import { loginSchema } from '../../../validation'
import { LOGIN_USER } from '../../../types'
import { selectUser } from '../../../selectors'

import { EmailInputStyle, CustomTextStyle } from './LoginPage.style.js'

// TODO: use these variables to customize login page layout
const {
  // REACT_APP_LOGIN_TITLE,
  // REACT_APP_LOGIN_LOGO,
  // REACT_APP_LOGIN_FONT_COLOR,
  REACT_APP_LOGIN_ACCENT_FONT_COLOR,
  REACT_APP_LOGIN_BORDER_COLOR,
  REACT_APP_LOGIN_BG,
  REACT_APP_LOGIN_INNER_BG
} = process.env

const initialValues = {
  mail_address: '',
  password: ''
}

const MIN_CLICK_COUNT = 20

const LoginPage = () => {
  const { t } = useTranslation()
  const [count, setCount] = useState(0)
  const [debugMode, setDebugMode] = useState(false)
  const dispatch = useDispatch()
  const loading = useSelector(state => state.auth && state.auth.loading)
  const user = useSelector(selectUser)
  const isDirty = useSelector(selectFormDirtyState)

  useEffect(() => {
    if (count === MIN_CLICK_COUNT && !debugMode) {
      setDebugMode(true)
      dispatch({
        type: UPDATE_FORM,
        payload: {
          mail_address: ''
        }
      })
    }

    if (count === 0) {
      setDebugMode(false)
      dispatch({
        type: UPDATE_FORM,
        payload: {
          login_id: ''
        }
      })
    }
  }, [count, dispatch, debugMode])

  if (user) return <Redirect to="/" noThrow/>

  const _handleCountClick = () => {
    setCount(prevCount => {
      if (prevCount <= (MIN_CLICK_COUNT - 1)) {
        return prevCount + 1
      } else {
        return prevCount
      }
    })
  }
  const _handleCountRightClick = () => {
    setCount(0)
  }

  const submitForm = (data) => {
    // console.log(data)
    dispatch({ type: LOGIN_USER, payload: data })
  }

  return (
    <Centered fullScreen backgroundColor={REACT_APP_LOGIN_BG}>
      <Card w={500} h={500} borderColor={REACT_APP_LOGIN_BORDER_COLOR}>
        <Centered backgroundColor={REACT_APP_LOGIN_INNER_BG}>
          <Form
            id="loginForm"
            handleSubmit={submitForm}
            initialData={initialValues}
            validationSchema={loginSchema}
            w={380}
            // debug
          >
            <Row mb="L" justify="center">
              <CustomTextStyle
                text="ログイン"
                textColor={COLORS.pageTitle}
                textSize={`${SPACING.MEDIUM_LARGE}rem`}
                onClick={_handleCountClick}
                onContextMenu={_handleCountRightClick}
              />
            </Row>
            {
              debugMode
                ? (
                  <EmailInputStyle
                    label='login_id'
                    name="login_id"
                    borderColor={REACT_APP_LOGIN_BORDER_COLOR}
                    mr="0"
                    placeholder="placeholders.letters_and_numbers_only"
                    required
                  />
                )
                : (
                  <EmailInputStyle
                    label='email'
                    placeholder='placeholders.email'
                    name="mail_address"
                    borderColor={REACT_APP_LOGIN_BORDER_COLOR}
                    mr="0"
                    required
                  />
                )
            }
            <TextInput
              label='login:password'
              placeholder='placeholders.letters_and_numbers_only'
              name="password"
              type="password"
              mr="0"
              required
              data-private
            />
            <Row justify="end">
              <LightText label="login:forgot.link" />
              <Link to="/forgot_password">{t('login:forgot.click_here')}</Link>
            </Row>
            <Row mt='L'>
              <CustomButton
                type="submit"
                btnStyle="custom"
                label='login:login'
                color={REACT_APP_LOGIN_ACCENT_FONT_COLOR}
                reverse
                loading={loading}
                disabled={!isDirty}
              />
            </Row>
          </Form>
        </Centered>
      </Card>
    </Centered>
  )
}
export default LoginPage

LoginPage.whyDidYouRender = true
