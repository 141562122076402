import { api, MULTIPART_HEADERS, getFileFromURL } from '@gk-devteam/apmc-core-web'

export const fetchGuides = (config, cancelSource) => {
  const { guideCategoryID, property, room, type } = config
  let path = `admin/${type}`
  if (property) path = `properties/${property}/${type}`
  if (room) path = `rooms/${room}/${type}`

  return api.get(`${path}/${guideCategoryID}`, {
    cancelToken: cancelSource.token
  })
}

export const postGuide = async (data, config, cancelSource) => {
  const { guideCategoryID, property, room, type } = config
  let path = `admin/${type}`
  if (property) path = `properties/${property}/${type}`
  if (room) path = `rooms/${room}/${type}`

  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing document')
      }
    }

    delete data.file
    delete data.file_url
    delete data.file_filename

    formData.append('data', JSON.stringify(data))
    return api.post(`${path}/${guideCategoryID}`, formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const deleteGuide = (id, config, cancelSource) => {
  const { guideCategoryID, property, room, type } = config
  let path = `admin/${type}`
  if (property) path = `properties/${property}/${type}`
  if (room) path = `rooms/${room}/${type}`

  return api.delete(`${path}/${guideCategoryID}/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const toggleGuide = (data, config, cancelSource) => {
  const { guideCategoryID, property, room, type } = config
  let path = `admin/${type}`
  if (property) path = `properties/${property}/${type}`
  if (room) path = `rooms/${room}/${type}`

  // console.log('path', path)
  // console.log('data', data)

  return api.post(`${path}/${guideCategoryID}/display`, data, {
    cancelToken: cancelSource.token
  })
}
export const bulkToggleGuides = (data, config, cancelSource) => {
  const { guideCategoryID, property, room, type } = config
  let path = `admin/${type}`
  if (property) path = `properties/${property}/${type}`
  if (room) path = `rooms/${room}/${type}`

  // console.log('path', path)
  // console.log('data', data)

  return api.post(`${path}/${guideCategoryID}/bulk_display`, data, {
    cancelToken: cancelSource.token
  })
}
