import { DEFAULT_QUERY_LIMIT, api } from '@gk-devteam/apmc-core-web'

export const fetchYasueContacts = (params = {}, cancelSource) => {
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }

  return api.get('/yasue/contacts', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token
  })
}
