import { i18n } from '../../locales'
import { createSelector } from 'reselect'

const selectFaq = state => state.faq

export const selectFaqLoading = createSelector(
  selectFaq,
  faq => faq.loading
)
export const selectFaqPosting = createSelector(
  selectFaq,
  faq => faq.posting
)
export const selectFaqCategoriesOptions = createSelector(
  selectFaq,
  faq => faq.categories
)
export const selectFaqCategoriesOptionsWithReset = createSelector(
  selectFaq,
  faq => faq.categories && [
    {
      label: i18n.t('all'),
      value: ''
    },
    ...faq.categories
  ]
)

export const selectAdminFaqData = createSelector(
  selectFaq,
  faq => faq.data && faq.data.admin_results
)
export const selectPropertyFaqData = createSelector(
  selectFaq,
  faq => faq.data && faq.data.property_results
)
export const selectAdminFaqAllDisplayed = createSelector(
  selectFaq,
  faq => {
    const items = []
    if (faq.data && faq.data.admin_results) {
      for (const category of faq.data.admin_results) {
        if (category.items) {
          for (const item of category.items) {
            items.push(item.display)
          }
        }
      }
    }
    if (items.every(item => item === 0 || item === false)) return 0 // all hidden
    if (items.every(item => item === 2 || item === true)) return 2 // all displayed
    return 1
  }
)
export const selectPropertyFaqAllDisplayed = createSelector(
  selectFaq,
  faq => {
    const items = []
    if (faq.data && faq.data.property_results) {
      for (const category of faq.data.property_results) {
        if (category.items) {
          for (const item of category.items) {
            items.push(item.display)
          }
        }
      }
    }
    if (items.every(item => item === 0 || item === false)) return 0 // all hidden
    if (items.every(item => item === 2 || item === true)) return 2 // all displayed
    return 1
  }
)

export const selectFaqHiddendBy = createSelector(
  selectFaq,
  faq => faq.data && faq.data.hiddenBy
)
export const selectFaqHiddendById = createSelector(
  selectFaq,
  faq => faq.data && faq.data.hiddenById
)
