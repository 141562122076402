import {
  FONT_SIZE,
  getColor,
  SPACING,
  Row,
  Column,
  styled,
  Text,
  BORDER_RADIUS
} from "@gk-devteam/apmc-core-web";
import { transparentize } from "polished";

export const RowStyle = styled(Row)`
  cursor: pointer;
`
export const DayRowStyle = styled.div`
  color: ${props => {
    let color = 'text'
    if (props.day === 0) color = 'danger'
    if (props.day === 6) color = 'info'
    return getColor(props, color)
  }};
  margin-right: ${SPACING.REGULAR}rem;
  white-space: nowrap;
  ${FONT_SIZE.SMALL};
`

export const DayCellStyle = styled(Column)`
  padding: ${SPACING.SMALL}rem ${SPACING.SMALLEST}rem;
  font-weight: bold;
  ${FONT_SIZE.MEDIUM};
  color: ${props => getColor(props, props.outOfMonth ? 'lightText' : 'pageTitle')};
  background-color: ${props => props.withEvent && transparentize(0.9, getColor(props, 'accent'))};
  cursor: pointer;
  height: 100%;
  pointer-events: ${props => props.outOfMonth ? 'none' : 'auto'};

  & span {
    display: ${props => props.outOfMonth ? 'none' : 'block'};
    margin-top: ${SPACING.SMALLEST}rem;
    color: ${props => props.withEvent ? getColor(props, 'accent') : 'inherit'};
  }
`

export const ListStyle = styled.ul`
  ${BORDER_RADIUS};
  border: 1px solid ${props => getColor(props, 'cardBorder')};
  padding: ${SPACING.SMALL}rem;
  flex: 1;
  list-style: none;
  margin: 0;
  height: 100%;
  overflow-y: scroll;
`
export const ContentStyle = styled(Column)`
  flex: 1;
  margin-left: ${SPACING.LARGE}rem;
  align-self: stretch;
  overflow-y: scroll;
`
export const ContentInnerStyle = styled.div`
  width: 100%;

  & .react-pdf__Page__canvas {
    margin: auto;
  }
`

export const DayEventListItemStyle = styled.li`
  ${BORDER_RADIUS};
  border: 1px solid ${props => {
    let color = 'cardBorder'
    if (props.isCurrent) color = 'accent'
    return getColor(props, color)
  }};
  background-color: ${props => props.isCurrent? transparentize(0.9, getColor(props, 'accent')) : 'inherit'};
  padding: ${SPACING.SMALL}rem;
  margin-bottom: ${SPACING.SMALL}rem;
  cursor: pointer;
`
export const DatetimeStyle = styled(Text)`
  font-weight: bold;
`
export const EventModalBodyStyle = styled(Row)`
  height: 100%;
  overflow-y: hidden;
`
