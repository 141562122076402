import React from 'react'
import PropTypes from 'prop-types'

import { DayEventListItemStyle } from './PropertyEventsPage.style'
import { Row, Text, ToastTitle } from '@gk-devteam/apmc-core-web'

function DayEventListItem ({ event, isCurrent, setCurrentevent, index }) {
  const { title, start_time, end_time } = event

  const _handleEventClick = () => {
    setCurrentevent(index)
  }
  return (
    <DayEventListItemStyle isCurrent={isCurrent} onClick={_handleEventClick}>
      <ToastTitle text={title} />
      <Row mt="XS"><Text text={`${start_time} - ${end_time}`}/></Row>
    </DayEventListItemStyle>
  )
}

DayEventListItem.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired
  }).isRequired,
  isCurrent: PropTypes.bool.isRequired,
  setCurrentevent: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
}

export default DayEventListItem
