import {
  styled,
  SPACING
} from '@gk-devteam/apmc-core-web'

export const StatusStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${SPACING.LARGER}rem;
`

export const StatusValueStyle = styled.div`
  margin-left: ${SPACING.SMALL}rem;
  margin-top: ${SPACING.SMALLEST}rem;
`
