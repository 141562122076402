import { createSelector } from 'reselect'

const selectCoupons = state => state.coupons

export const selectCouponsLoading = createSelector(
  selectCoupons,
  coupons => coupons.loading
)

export const selectCouponsCount = createSelector(
  selectCoupons,
  coupons => coupons.results_count
)

export const selectCouponsData = createSelector(
  selectCoupons,
  coupons => coupons.results
)

export const selectCouponStatusModal = createSelector(
  selectCoupons,
  coupons => coupons.statusModal
)
export const selectActiveCouponStatus = createSelector(
  selectCoupons,
  coupons => coupons.activeStatus
)
