import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  Row,
  TextInput,
  RepeaterAddButton,
  RepeaterFields,
  selectFormRequiredFields
} from '@gk-devteam/apmc-core-web'

import TotalFees from './TotalFees'
import ContractFeesRow from './ContractFeesRow'

const ContractFees = () => {
  const requiredFields = useSelector(selectFormRequiredFields)
  return (
    <>
      <Row wrap="wrap" align="baseline">
        <TextInput
          flex={1}
          size="M"
          type="number"
          name="rent_amount"
          label='contracts:rent_amount'
          placeholder=""
          suffix="currency_symbol"
          required={requiredFields?.rent_amount}
        />
        <TextInput
          flex={1}
          size="M"
          type="number"
          name="rent_common_fees"
          label='contracts:rent_fees.common_fees'
          placeholder=""
          suffix="currency_symbol"
          required={requiredFields?.rent_common_fees}
        />
        <TextInput
          flex={1}
          size="S"
          type="number"
          name="administrative_fees"
          label='contracts:administrative_fees'
          suffix="currency_symbol"
          required={requiredFields?.administrative_fees}
        />
        <TotalFees />
      </Row>
      <Row wrap="wrap" align="baseline">
        <RepeaterFields
          propertyKey="rent_fees"
          uniqField="label"
          field={(index) => <ContractFeesRow key={index} index={index}/>}
        />
      </Row>
      <RepeaterAddButton
        label="contracts:rent_fees.add_rent_fees"
        propertyKey="rent_fees"
        uniqField="label"
        fields={['label', 'amount']}
        help="contracts:help.rent_fees"
        maxItems={6}
      />
    </>
  )
}

export default memo(ContractFees)

// ContractFees.propTypes = {}
