import { createSelector } from 'reselect'

const selectContracts = state => state.contractsProcessing

export const selectContractsProcessingLoading = createSelector(
  selectContracts,
  contracts => contracts.loading
)
export const selectContractsProcessing = createSelector(
  selectContracts,
  contracts => contracts?.processingContracts?.results
)
export const selectContractsProcessingCount = createSelector(
  selectContracts,
  contracts => contracts?.processingContracts?.results_count || 0
)
export const selectContractsRenewing = createSelector(
  selectContracts,
  contracts => contracts?.renewing?.results
)
// Status 5, 6 & 7
export const selectContractsRenewingCount = createSelector(
  selectContracts,
  contracts => contracts?.renewing?.results_count || 0
)
// Status 8 & 9
export const selectContractsCanceling = createSelector(
  selectContracts,
  contracts => contracts?.canceling?.results
)
export const selectContractsCancelingCount = createSelector(
  selectContracts,
  contracts => contracts?.canceling?.results_count || 0
)
// Status 5 & 6
export const selectAskingForRenewalContractsCount = createSelector(
  selectContracts,
  contracts => contracts?.askingForRenewalCount || 0
)
// Status 7
export const selectRenewingContractsCount = createSelector(
  selectContracts,
  contracts => contracts?.renewingCount || 0
)
// Status 8
export const selectRequestingCancelContractsCount = createSelector(
  selectContracts,
  contracts => contracts?.requestingCancelCount || 0
)
// Status 9
export const selectCancelingContractsCount = createSelector(
  selectContracts,
  contracts => contracts?.cancelingCount || 0
)
// Status 10
export const selectRenewingPeriodContractsCount = createSelector(
  selectContracts,
  contracts => contracts?.renewingPeriodCount || 0
)
