import React, { useCallback, useState, useEffect } from 'react'
import {
  Centered,
  Card,
  Row,
  InfoButton,
  Form,
  SectionTitle,
  TextInput,
  Text,
  useTranslation,
  PageLoader
} from '@gk-devteam/apmc-core-web'
import { message } from 'antd'
import { CancelToken } from 'axios'
import queryString from 'query-string'
import PropTypes from 'prop-types'

import { changePasswordSchema } from '../../../validation'
import { changeUserPassword, verifyForgotPasswordToken } from '../../../services'

const {
  REACT_APP_LOGIN_BORDER_COLOR,
  REACT_APP_LOGIN_BG,
  REACT_APP_LOGIN_INNER_BG
} = process.env

function ChangePassword ({ navigate, location }) {
  const { t } = useTranslation()
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)

  const verifyToken = useCallback(
    async (token) => {
      try {
        const cancelSource = CancelToken.source()
        const res = await verifyForgotPasswordToken({ token }, cancelSource)
        if (res.status !== 204) {
          navigate('/')
          message.error(t('login:change.verify_error'))
        } else {
          setValidated(true)
        }
      } catch (error) {
        console.warn('error', error)
        navigate('/')
        message.error(error?.message || error.data)
      }
    },
    // eslint-disable-next-line
    [navigate] // ignore t dependency
  )

  useEffect(() => {
    const qs = queryString.parse(location.search)
    if (!qs?.token) {
      navigate('/')
    } else {
      verifyToken(qs.token)
    }
  }, [navigate, location, verifyToken])

  const _submitForm = async (data) => {
    setLoading(true)
    try {
      const cancelSource = CancelToken.source()
      const qs = queryString.parse(location.search)
      const { password } = data
      const { token } = qs

      if (data && token) {
        const res = await changeUserPassword({
          token,
          password
        }, cancelSource)
        if (res.status === 204) {
          message.success(t('login:forgot.email_submit_success'))
          navigate('/')
        }
      } else {
        message.error(t('post_error'))
      }
    } catch (error) {
      console.warn('error', error)
      message.error(t('login:forgot.email_submit_fail'))
    } finally {
      setLoading(false)
    }
  }

  if (!validated) return <PageLoader />

  return (
    <Centered fullScreen backgroundColor={REACT_APP_LOGIN_BG}>
      <Card w={500} h={500} borderColor={REACT_APP_LOGIN_BORDER_COLOR}>
        <Centered backgroundColor={REACT_APP_LOGIN_INNER_BG}>
          <Form
            id="changePasswordForm"
            handleSubmit={_submitForm}
            initialData={{
              mail_address: ''
            }}
            validationSchema={changePasswordSchema}
            w={380}
          >
            <Row mb="ML">
              <SectionTitle label="login:change.title" />
            </Row>
            <Row mb="M">
              <Text label="login:change.description" />
            </Row>
            <TextInput
              label='login:password'
              placeholder='placeholders.password'
              name="password"
              type="password"
              mr="0"
              required
              data-private
            />
            <Row mt='L' justify="end">
              <InfoButton
                type="submit"
                label='submit'
                reverse
                loading={loading}
              />
            </Row>
          </Form>
        </Centered>
      </Card>
    </Centered>
  )
}

ChangePassword.propTypes = {
  navigate: PropTypes.func,
  location: PropTypes.object
}

export default ChangePassword
