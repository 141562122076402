import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_PUBLIC_FACILITIES,
  FETCH_PUBLIC_FACILITIES_SUCCESS,
  FETCH_PUBLIC_FACILITIES_FAIL,
  POST_PUBLIC_FACILITY,
  POST_PUBLIC_FACILITY_SUCCESS,
  POST_PUBLIC_FACILITY_FAIL,
  DELETE_PUBLIC_FACILITY,
  // DELETE_PUBLIC_FACILITY_SUCCESS,
  DELETE_PUBLIC_FACILITY_FAIL,
  TOGGLE_PUBLIC_FACILITY,
  TOGGLE_PUBLIC_FACILITY_FAIL,
  TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA,
  RESET_PUBLIC_FACILITIES
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  posting: null,
  data: null,
  categories: null,
  error: null,
  hiddenBy: null,
  hiddenById: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_PUBLIC_FACILITIES]: (state, action) => {
    state.loading = true
  },
  [FETCH_PUBLIC_FACILITIES_SUCCESS]: (state, action) => {
    const { data, categories, hiddenBy, hiddenById } = action.payload
    state.loading = false
    state.data = data
    state.categories = categories
    state.hiddenBy = hiddenBy
    state.hiddenById = hiddenById
  },
  [FETCH_PUBLIC_FACILITIES_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA]: (state, action) => {
    state.loading = true
  },
  [POST_PUBLIC_FACILITY]: (state, action) => {
    state.posting = true
  },
  [POST_PUBLIC_FACILITY_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_PUBLIC_FACILITY_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [TOGGLE_PUBLIC_FACILITY]: (state, action) => {
    state.loading = true
  },
  [TOGGLE_PUBLIC_FACILITY_FAIL]: (state, action) => {
    state.loading = false
  },
  [DELETE_PUBLIC_FACILITY]: (state, action) => {
    state.loading = true
  },
  [DELETE_PUBLIC_FACILITY_FAIL]: (state, action) => {
    state.loading = false
  },
  [RESET_PUBLIC_FACILITIES]: () => INITIAL_STATE

})
