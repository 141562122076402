import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  MainLayout, selectPages
} from '@gk-devteam/apmc-core-web'

import {
  selectContractFormData,
  selectContractLoading
} from '../../../selectors'

import ContractForm from '../../../forms/contract/ContractForm'
import YasueContractForm from '../../../forms/contract/ContractForm/YasueContractForm'

const ContractDetailsPage = ({ contractID }) => {
  const data = useSelector(selectContractFormData)
  const loading = useSelector(selectContractLoading)
  const pages = useSelector(selectPages)

  const breadcrumbs = [
    {
      label: 'navigation:contracts',
      link: '/contracts'
    },
    {
      label: 'navigation:contracts_details',
      link: `/contracts/edit/${contractID}`
    }
  ]

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
      lastEdited={data && data.modified}
      lastEditedBy={data && data.modifiedBy}
    >
      {
        pages?.custom?.yasue
          ? <YasueContractForm data={data} contractID={contractID} />
          : <ContractForm data={data} contractID={contractID} />
      }
    </MainLayout>
  )
}
export default ContractDetailsPage

ContractDetailsPage.propTypes = {
  contractID: PropTypes.string.isRequired
}
ContractDetailsPage.defaultProps = {

}
