import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Text } from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import WeekDayPreview from './WeekDayPreview'
import {
  TimeContainerBorder,
  TimeBorder,
  TimeLabel,
  TimeRow,
  WeekdaysStyle
} from './ChatSettingsForm.style.js'

function TimePreview ({ level, disabled }) {
  const daysOfWeek = useSelector(state => {
    if (level === 'admin') {
      return {
        Mon: state.chat.settings.admin?.daysOfWeek?.Mon,
        Tue: state.chat.settings.admin?.daysOfWeek?.Tue,
        Wed: state.chat.settings.admin?.daysOfWeek?.Wed,
        Thu: state.chat.settings.admin?.daysOfWeek?.Thu,
        Fri: state.chat.settings.admin?.daysOfWeek?.Fri,
        Sat: state.chat.settings.admin?.daysOfWeek?.Sat,
        Sun: state.chat.settings.admin?.daysOfWeek?.Sun
      }
    }
    if (level === 'property') {
      return {
        Mon: state.chat.settings.property?.daysOfWeek?.Mon,
        Tue: state.chat.settings.property?.daysOfWeek?.Tue,
        Wed: state.chat.settings.property?.daysOfWeek?.Wed,
        Thu: state.chat.settings.property?.daysOfWeek?.Thu,
        Fri: state.chat.settings.property?.daysOfWeek?.Fri,
        Sat: state.chat.settings.property?.daysOfWeek?.Sat,
        Sun: state.chat.settings.property?.daysOfWeek?.Sun
      }
    }
    return {
      Mon: state.form['daysOfWeek.Mon'],
      Tue: state.form['daysOfWeek.Tue'],
      Wed: state.form['daysOfWeek.Wed'],
      Thu: state.form['daysOfWeek.Thu'],
      Fri: state.form['daysOfWeek.Fri'],
      Sat: state.form['daysOfWeek.Sat'],
      Sun: state.form['daysOfWeek.Sun']
    }
  })

  function renderBorders () {
    return (
      [...Array(24)].map((_, i) => (
        <TimeRow key={i} align='center'>
          {
            i % 6 === 0 || i === 23
              ? <TimeLabel text={i + ':00'} />
              : null
          }
          <TimeBorder />
        </TimeRow>
      ))
    )
  }

  if (!daysOfWeek) return null

  return (
    <div>
      <Row>
        <Text label="chat.time_preview"/>
      </Row>
      <Row>
        <TimeContainerBorder>
          {renderBorders()}
          <WeekdaysStyle>
            <WeekDayPreview label='daysOfWeek_short.Mon' allDay={daysOfWeek.Mon} level={level} disabled={disabled} />
            <WeekDayPreview label='daysOfWeek_short.Tue' allDay={daysOfWeek.Tue} level={level} disabled={disabled} />
            <WeekDayPreview label='daysOfWeek_short.Wed' allDay={daysOfWeek.Wed} level={level} disabled={disabled} />
            <WeekDayPreview label='daysOfWeek_short.Thu' allDay={daysOfWeek.Thu} level={level} disabled={disabled} />
            <WeekDayPreview label='daysOfWeek_short.Fri' allDay={daysOfWeek.Fri} level={level} disabled={disabled} />
            <WeekDayPreview label='daysOfWeek_short.Sat' allDay={daysOfWeek.Sat} level={level} disabled={disabled} />
            <WeekDayPreview label='daysOfWeek_short.Sun' allDay={daysOfWeek.Sun} level={level} disabled={disabled} />
          </WeekdaysStyle>
        </TimeContainerBorder>
      </Row>
    </div>
  )
}

TimePreview.propTypes = {
  level: PropTypes.oneOf(['admin', 'property']),
  disabled: PropTypes.bool
}

export default TimePreview
