import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  LeftNavigationMenu
} from '@gk-devteam/apmc-core-web'

import { selectPages } from '../../selectors'

import NotFoundPage from '../../pages/NotFoundPage'

const UserManagementBlock = ({ enabled, children }) => {
  const { t } = useTranslation(['navigation'])
  const pages = useSelector(selectPages)

  if (!enabled || !children || !pages) return <NotFoundPage />

  const getMenu = () => {
    const { user_management } = pages
    const {
      members,
      propertiesGroup,
      guests
    } = user_management.functions
    const menu = []
    if (members) {
      menu.push({
        label: t('navigation:management_members_list'),
        link: './'
      })
    }
    if (propertiesGroup) {
      menu.push({
        label: t('navigation:management_properties_groups_list'),
        link: './properties_groups'
      })
    }
    if (guests) {
      menu.push({
        label: t('navigation:management_guests_list'),
        link: './guests'
      })
    }

    return menu
  }

  return (
    <PageLayout
      sidebar={() => <LeftNavigationMenu menu={getMenu()}/>}
      noMain
    >
      { children }
    </PageLayout>
  )
}

export default UserManagementBlock

UserManagementBlock.propTypes = {
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}
