import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_FORM,
  LeftSearchMenu,
  TextInput,
  Select,
  selectQueryParams,
  useTranslation
} from '@gk-devteam/apmc-core-web'

import { refundsSearchSchema } from '../../../../validation'
import { ANABUKI } from '../../../../constants'

const initialValues = {
  property_name: '',
  property_id: '',
  user_id: '',
  user_name: '',
  status: ''
}

const RefundsSearchBar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        property_name: queryParams.property_name,
        property_id: queryParams.property_id,
        user_id: queryParams.user_id,
        user_name: queryParams.user_name,
        status: queryParams.status
      }
    })
  }, [queryParams, dispatch])

  return (
    <>
      <LeftSearchMenu
        resetValues={initialValues}
        validationSchema={refundsSearchSchema}
      >
        <TextInput
          type="text"
          name="property_name"
          label='properties:property_name'
          placeholder="placeholders.partial_property_name"
          mr="0"
        />
        <TextInput
          type="number"
          name="property_id"
          label='properties:property_id'
          placeholder=""
          mr="0"
        />
        <TextInput
          type="text"
          name="user_name"
          label='app_users:app_user_name'
          placeholder="placeholders.partial_user_name"
          mr="0"
        />
        <TextInput
          type="number"
          name="user_id"
          label='app_users:app_user_id'
          placeholder=""
          mr="0"
        />
        <Select
          mr="0"
          name="status"
          label='status'
          placeholder=""
          options={[
            {
              label: t('refunds:statuses.waiting'),
              value: ANABUKI.SUPPORT_STATUS.WAITING
            },
            {
              label: t('refunds:statuses.in_progress'),
              value: ANABUKI.SUPPORT_STATUS.IN_PROGRESS
            },
            {
              label: t('refunds:statuses.completed'),
              value: ANABUKI.SUPPORT_STATUS.COMPLETED
            },
            {
              label: t('refunds:statuses.rejected'),
              value: ANABUKI.SUPPORT_STATUS.REJECTED
            }
          ]}
        />
      </LeftSearchMenu>
    </>
  )
}

export default memo(RefundsSearchBar)

// PropertiesSearchBar.propTypes = {}
