import { createSelector } from 'reselect'

const selectMember = state => state.member

export const selectLoadingMember = createSelector(
  selectMember,
  member => member.loading
)
export const selectPostingMember = createSelector(
  selectMember,
  member => member.posting
)
export const selectMemberError = createSelector(
  selectMember,
  member => member.error
)
export const selectMemberData = createSelector(
  selectMember,
  member => member.data || {}
)
export const selectMemberID = createSelector(
  selectMember,
  member => member.data && member.data.id
)
export const selectMemberName = createSelector(
  selectMember,
  member => member.data && member.data.name
)
