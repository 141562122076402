import { getColor, SPACING, styled } from "@gk-devteam/apmc-core-web";

export const SeikatsuCellStyle = styled.div`
  display: inline-flex;
  align-items: center;

  & > span {
    white-space: nowrap;
  }

  & > a {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }

`

export const IconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${SPACING.REGULAR}rem;
  cursor: pointer;
  color: ${props => getColor(props, 'info')};
  span {
    font-size: 20px;
  }

  &:hover {
    color: ${props => getColor(props, 'accent')};
  }
`
