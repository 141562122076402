import React, { memo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  INIT_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyName,
  selectGuideCategoriesLoading,
  selectGuideCategoriessHiddenBy,
  selectGuideCategoriessHiddenById
} from '../../../../selectors'
import { FETCH_GUIDE_CATEGORIES, RESET_GUIDE_CATEGORIES } from '../../../../types'
import initialData from '../common/initialData'

import AdminGuideCategoriesList from './AdminGuideCategoriesList'
import PropertyGuideCategoriesList from './PropertyGuideCategoriesList'
import GuideCategoryModal from '../common/GuideCategoryModal'
import PanelLayout from '../../../../components/Display/Panels/PanelLayout'

const PropertyGuidesPage = ({ propertyID }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectGuideCategoriesLoading)
  const propertyName = useSelector(selectPropertyName)
  const [modalVisible, setModalVisible] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_guides',
      link: `/properties/edit/${propertyID}/guides`
    }
  ]

  useEffect(() => {
    dispatch({ type: FETCH_GUIDE_CATEGORIES, config: { property: propertyID } })
    return () => {
      dispatch({ type: RESET_GUIDE_CATEGORIES })
    }
  }, [dispatch, propertyID])

  const _openAddModal = () => {
    dispatch({ type: INIT_FORM, payload: initialData })
    setModalVisible(true)
  }
  const _openCopyModal = () => {
    setModalVisible(true)
  }
  const _openEditModal = () => {
    setModalVisible(true)
    setEditMode(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
    setEditMode(false)
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectGuideCategoriessHiddenBy}
      hiddenByIdSelector={selectGuideCategoriessHiddenById}
      title="navigation:properties_guide_categories"
      authorized="guides.functions.add"
      info="services:level_message.property"
      infoTitle="services:level_message.propertyTitle"
      handleAddClick={_openAddModal}
    >
      <GuideCategoryModal
        visible={modalVisible}
        editMode={editMode}
        onCancel={_closeModal}
        type="property"
        id={propertyID}
      />
      <AdminGuideCategoriesList editModal={_openEditModal} propertyID={propertyID} />
      <PropertyGuideCategoriesList copyModal={_openCopyModal} editModal={_openEditModal} />
    </PanelLayout>
  )
}
export default memo(PropertyGuidesPage)

PropertyGuidesPage.propTypes = {
  propertyID: PropTypes.string.isRequired
}
PropertyGuidesPage.defaultProps = {}
