import React, { memo } from 'react'
import { useLocation } from '@reach/router'
import { useSelector } from 'react-redux'
import {
  NavBar,
  selectPages,
  NavItem,
  Dropdown,
  useTranslation
} from '@gk-devteam/apmc-core-web'

import {
  selectContractsCancelingCount,
  selectContractsRenewingCount,
  selectSpacesWaitingReservationsCount,
  selectWaitingChatRoomsCount,
  selectWaitingChecklistsCount
} from '../../selectors'

import Drawer from '../Drawer'
import { CONTRACT_STATUS } from '../../constants'

const CustomNavBar = () => {
  const pages = useSelector(selectPages)
  const { t } = useTranslation()
  const location = useLocation()
  const waitingChatRooms = useSelector(selectWaitingChatRoomsCount)
  const waitingChecklists = useSelector(selectWaitingChecklistsCount)
  const renewingContractsCount = useSelector(selectContractsRenewingCount)
  const cancelingContractsCount = useSelector(selectContractsCancelingCount)
  const spaceReservationsCount = useSelector(selectSpacesWaitingReservationsCount)

  if (!pages) return null

  const {
    dashboard,
    contracts,
    app_users,
    properties,
    notices,
    admin_tasks,
    user_management,
    manage_members,
    manage_propertiesGroup,
    manage_guests,
    settings,
    imports,
    custom
  } = pages

  const menu = [
    <NavItem path="/" label={t('navigation:dashboard')} key="dashboard" enabled={dashboard.enabled} />,

    <Dropdown path="/admin_tasks" label={t('navigation:admin_tasks')} key="admin_tasks" enabled={admin_tasks.enabled}>
      <NavItem path="/admin_tasks/chat" label={t('navigation:chat_rooms_list')} enabled={admin_tasks.enabled && admin_tasks.functions.chat} />
      <NavItem path="/admin_tasks/checklist" label={t('navigation:checklists_list')} enabled={admin_tasks.enabled && admin_tasks.functions.checklist} />
      <NavItem path={`/admin_tasks/contracts?status=${CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value},${CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value}`} label={t('navigation:admin_tasks_contracts')} enabled={custom?.cloudsign || contracts?.functions?.cancel} />
      <NavItem path={'/admin_tasks/spaces_reservations'} label={t('navigation:properties_spaces_reservations_list')} enabled={custom?.yahagi} />
    </Dropdown>,

    <Dropdown path="/notices" label={t('navigation:notices')} key="notices" enabled={notices.enabled}>
      <NavItem path="/notices" label={t('navigation:notices_list')} enabled={notices.enabled} />
      <NavItem path="/notices/add" label={t('navigation:notices_add')} enabled={notices.enabled && notices.functions.add} />
    </Dropdown>,

    <Dropdown path="/surveys" label={t('navigation:surveys')} key="surveys" enabled={custom?.yahagi}>
      <NavItem path="/surveys" label={t('navigation:surveys_list')} enabled={custom?.yahagi} />
      <NavItem path="/surveys/add" label={t('navigation:surveys_add')} enabled={custom?.yahagi} />
    </Dropdown>,

    <NavItem path="/inquiries" label={t('navigation:inquiries')} key="inquiries" enabled={custom?.yahagi} />,

    <Dropdown path="/properties" label={t('navigation:properties')} key="properties" enabled={properties.enabled}>
      <NavItem path="/properties" label={t('navigation:properties_list')} enabled={properties.enabled} />
      <NavItem path="/properties/add" label={t('navigation:properties_add')} enabled={properties.enabled && properties.functions.add} />
    </Dropdown>,

    <Dropdown path="/contracts" label={t('navigation:contracts')} key="contracts" enabled={contracts.enabled}>
      <NavItem path="/contracts" label={t('navigation:contracts_list')} enabled={contracts.enabled} />
      <NavItem path="/contracts/add" label={t('navigation:contracts_add')} enabled={contracts.enabled && contracts.functions.add} />
    </Dropdown>,

    <NavItem path="/app_users" label={t('navigation:app_users')} key="app_users" enabled={app_users.enabled} />,

    <NavItem path="/datasync" label={t('navigation:datasync')} key="datasync" enabled={custom?.manual_datasync} />,
    <NavItem path="/billing" label={t('navigation:chouei.billing')} key="billing" enabled={custom?.chouei} />,

    <Dropdown path="/yasue/construction_works?sort_by=contract_date&sort_order=desc" label={t('navigation:yasue.construction_work')} key="construction_work" enabled={custom?.yasue}>
      <NavItem path="/yasue/construction_works?sort_by=contract_date&sort_order=desc" label={t('navigation:yasue.construction_work_list')} enabled={custom?.yasue} />
      <NavItem path="/yasue/construction_works/add" label={t('navigation:yasue.construction_work_add')} enabled={custom?.yasue} />
    </Dropdown>,
    <NavItem path="/yasue/survey" label={t('navigation:yasue.survey')} enabled={custom?.yasue} key="yasue_survey" />,
    <NavItem path="/yasue/contacts" label={t('navigation:yasue.contacts')} key="yasue.contacts" enabled={custom?.yasue} />,
    <NavItem path="/yasue/maintenance" label={t('navigation:yasue.maintenance')} key="yasue.maintenance" enabled={custom?.yasue} />,

    <NavItem path="/import" label={t('navigation:import')} key="imports" enabled={imports.enabled} />,

    <Dropdown path="/user_management" label={t('navigation:management')} key="user_management" enabled={user_management.enabled && user_management.functions.members}>
      <NavItem path="/user_management" label={t('navigation:management_members_list')} enabled={user_management.enabled && user_management.functions.members} />
      <NavItem path="/user_management/members/add" label={t('navigation:management_members_add')} enabled={manage_members.enabled && manage_members.functions.add} />
      <NavItem path="/user_management/properties_groups" label={t('navigation:management_properties_groups_list')} enabled={user_management.enabled && user_management.functions.propertiesGroup} />
      <NavItem path="/user_management/properties_groups/add" label={t('navigation:management_properties_groups_add')} enabled={manage_propertiesGroup.enabled && manage_propertiesGroup.functions.add} />
      <NavItem path="/user_management/guests" label={t('navigation:management_guests_list')} enabled={user_management.enabled && user_management.functions.guests} />
      <NavItem path="/user_management/guests/add" label={t('navigation:management_guests_add')} enabled={manage_guests.enabled && manage_guests.functions.add} />
    </Dropdown>,

    <NavItem path="/inui/contacts" label={t('navigation:inui.contacts')} key="inui.contacts" enabled={custom?.inui} />,
    <Dropdown path="/coupons" label={t('navigation:inui.coupons')} key="coupons" enabled={custom?.inui}>
      <NavItem path="/coupons" label={t('navigation:inui.coupons_list')} enabled={custom?.inui} />
      <NavItem path="/coupons/add" label={t('navigation:inui.coupons_add')} enabled={custom?.inui} />
    </Dropdown>,

    <NavItem path="/anabuki/refunds" label={t('navigation:anabuki.refunds_list')} key="anabuki.refunds" enabled={custom?.anabuki} />,
    <NavItem path="/inspections" label={t('navigation:house_maker.inspections_list')} key="house_maker.inspections_list" enabled={custom?.house_maker && !custom?.yasue} />,

    <NavItem path="/settings" label={t('navigation:settings')} key="settings" enabled={settings.enabled} active={location.pathname.indexOf('/settings') === 0} />
  ]

  const count = waitingChatRooms + waitingChecklists + renewingContractsCount + cancelingContractsCount + spaceReservationsCount || 0

  return (
    <NavBar
      count={count}
      countMessage={t('avatar_badge_msg', { count })}
      menu={menu}
      drawerBody={Drawer}
    />
  )
}

export default memo(CustomNavBar)

CustomNavBar.propTypes = {}
