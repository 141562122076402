import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_MEMBER,
  FETCH_MEMBER_SUCCESS,
  FETCH_MEMBER_FAIL,
  RESET_MEMBER,
  POST_MEMBER,
  POST_MEMBER_SUCCESS,
  POST_MEMBER_FAIL
  // DELETE_MEMBER,
  // DELETE_MEMBER_SUCCESS,
  // DELETE_MEMBER_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_MEMBER]: (state, action) => {
    state.posting = true
  },
  [POST_MEMBER_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_MEMBER_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_MEMBER]: (state, action) => {
    state.loading = true
  },
  [FETCH_MEMBER_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_MEMBER_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_MEMBER]: () => INITIAL_STATE
})
