import React, { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import {
  selectContactsLoading,
  selectContactsHiddenBy,
  selectContactsHiddenById,
  selectPropertyID
} from '../../../../selectors'
import { FETCH_CONTACTS, RESET_CONTACTS } from '../../../../types'

import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import AdminContactsList from './AdminContactsList'
import PropertyContactsList from './PropertyContactsList'

const ContractContactsPage = ({ contractID }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectContactsLoading)
  const propertyID = useSelector(selectPropertyID)

  useEffect(() => {
    dispatch({ type: FETCH_CONTACTS, config: { contract: contractID } })
    return () => {
      dispatch({ type: RESET_CONTACTS })
    }
  }, [dispatch, contractID])

  const breadcrumbs = [
    {
      label: 'navigation:contracts',
      link: '/contracts'
    },
    {
      label: 'navigation:contracts_details',
      link: `/contracts/edit/${contractID}`
    },
    {
      label: 'navigation:properties_contacts',
      link: `/contracts/edit/${contractID}/contacts`
    }
  ]

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectContactsHiddenBy}
      hiddenByIdSelector={selectContactsHiddenById}
      propertyID={propertyID}
      title="navigation:properties_contacts"
      authorized="contacts.functions.add"
      info="services:level_message.contract"
      infoTitle="services:level_message.contractTitle"
    >
      <AdminContactsList />
      <PropertyContactsList />
    </PanelLayout>
  )
}
export default memo(ContractContactsPage)

ContractContactsPage.propTypes = {
  contractID: PropTypes.string.isRequired
}
ContractContactsPage.defaultProps = {}
