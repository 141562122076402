import React, { memo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslation,
  numberWithCommas,
  FormControlLabel
} from '@gk-devteam/apmc-core-web'

import { selectFormAdditionalFees } from '../../../../../selectors'

import {
  TotalFeesStyle,
  TotalValueStyle
} from '../../ContractForm.style'

const TotalFees = () => {
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const rent = useSelector(state => state.form.rent_amount)
  const commonFees = useSelector(state => state.form.rent_common_fees)
  const administrativeFees = useSelector(state => state.form.administrative_fees)
  const additionalFees = useSelector(selectFormAdditionalFees)

  useEffect(() => {
    let totalFees = Number(rent) || 0
    if (additionalFees) {
      totalFees += additionalFees
    }
    if (commonFees) {
      totalFees += Number(commonFees)
    }
    if (administrativeFees) {
      totalFees += Number(administrativeFees)
    }
    setTotal(numberWithCommas(totalFees))
  }, [rent, commonFees, administrativeFees, additionalFees])

  return (

    <TotalFeesStyle flex={1}>
      <FormControlLabel label={t('total')} />
      <TotalValueStyle text={`${total} 円`} />
    </TotalFeesStyle>

  )
}

export default memo(TotalFees)

// RentFees.propTypes = {}
