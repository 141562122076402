import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchYasueMaintenanceContacts,
  postYasueMaintenanceContacts,
  fetchYasueMaintenanceShowrooms,
  postYasueMaintenanceShowrooms
} from '../../services'
import {
  FETCH_YASUE_MAINTENANCE_CONTACTS,
  FETCH_YASUE_MAINTENANCE_CONTACTS_SUCCESS,
  FETCH_YASUE_MAINTENANCE_CONTACTS_FAIL,
  FETCH_YASUE_MAINTENANCE_SHOWROOMS,
  FETCH_YASUE_MAINTENANCE_SHOWROOMS_SUCCESS,
  FETCH_YASUE_MAINTENANCE_SHOWROOMS_FAIL,
  POST_YASUE_MAINTENANCE_CONTACTS,
  POST_YASUE_MAINTENANCE_CONTACTS_SUCCESS,
  POST_YASUE_MAINTENANCE_CONTACTS_FAIL,
  POST_YASUE_MAINTENANCE_SHOWROOMS,
  POST_YASUE_MAINTENANCE_SHOWROOMS_SUCCESS,
  POST_YASUE_MAINTENANCE_SHOWROOMS_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchYasueMaintenanceContactsSaga () {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchYasueMaintenanceContacts, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_YASUE_MAINTENANCE_CONTACTS_SUCCESS, payload: res?.data?.contacts })
    } else {
      yield put({ type: FETCH_YASUE_MAINTENANCE_CONTACTS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: FETCH_YASUE_MAINTENANCE_CONTACTS_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * postYasueMaintenanceContactsSaga ({ data }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postYasueMaintenanceContacts, data, cancelSource)
    if (res?.status === 204) {
      yield put({ type: POST_YASUE_MAINTENANCE_CONTACTS_SUCCESS })
      yield put({ type: FETCH_YASUE_MAINTENANCE_CONTACTS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_YASUE_MAINTENANCE_CONTACTS_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_YASUE_MAINTENANCE_CONTACTS_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * fetchYasueMaintenanceShowroomssSaga () {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchYasueMaintenanceShowrooms, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_YASUE_MAINTENANCE_SHOWROOMS_SUCCESS, payload: res?.data?.showrooms })
    } else {
      yield put({ type: FETCH_YASUE_MAINTENANCE_SHOWROOMS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: FETCH_YASUE_MAINTENANCE_SHOWROOMS_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * postYasueMaintenanceShowroomsSaga ({ data }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postYasueMaintenanceShowrooms, data, cancelSource)
    if (res?.status === 204) {
      yield put({ type: POST_YASUE_MAINTENANCE_SHOWROOMS_SUCCESS })
      yield put({ type: FETCH_YASUE_MAINTENANCE_SHOWROOMS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_YASUE_MAINTENANCE_SHOWROOMS_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_YASUE_MAINTENANCE_SHOWROOMS_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchYasueMaintenance () {
  yield takeLatest(FETCH_YASUE_MAINTENANCE_CONTACTS, fetchYasueMaintenanceContactsSaga)
  yield takeLatest(POST_YASUE_MAINTENANCE_CONTACTS, postYasueMaintenanceContactsSaga)
  yield takeLatest(FETCH_YASUE_MAINTENANCE_SHOWROOMS, fetchYasueMaintenanceShowroomssSaga)
  yield takeLatest(POST_YASUE_MAINTENANCE_SHOWROOMS, postYasueMaintenanceShowroomsSaga)
}
