import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchAdminProfile,
  postAdminProfile
} from '../../services'

import {
  FETCH_ADMIN_PROFILE,
  FETCH_ADMIN_PROFILE_SUCCESS,
  FETCH_ADMIN_PROFILE_FAIL,
  POST_ADMIN_PROFILE,
  POST_ADMIN_PROFILE_SUCCESS,
  POST_ADMIN_PROFILE_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchAdminProfileSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchAdminProfile, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_ADMIN_PROFILE_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_ADMIN_PROFILE_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_ADMIN_PROFILE_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postAdminProfileSaga ({ data, id, succeccRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postAdminProfile, data, id, cancelSource)
    if (res && res.data && res.data.success) {
      if (succeccRedirect) yield call(succeccRedirect, res.data.id)
      yield put({ type: POST_ADMIN_PROFILE_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_ADMIN_PROFILE_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_ADMIN_PROFILE_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchAdminProfile () {
  yield takeLatest(FETCH_ADMIN_PROFILE, fetchAdminProfileSaga)
  yield takeLatest(POST_ADMIN_PROFILE, postAdminProfileSaga)
}
