import React, { memo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  RoleControlled,
  Row,
  TextInput
} from '@gk-devteam/apmc-core-web'

import { selectContractsLoading } from '../../../../../selectors'

import { SearchButtonStyle } from '../../../../contract/ContractForm/ContractForm.style'

const ContractsForm = ({ fetchData }) => {
  const loading = useSelector(selectContractsLoading)
  const [propertyName, setPropertyName] = useState('')
  const [contractorName, setContractorName] = useState('')
  const [residentName, setResidentName] = useState('')

  const _handlePropertyNameChange = useCallback(
    (e) => {
      setPropertyName(e.target.value)
    },
    [setPropertyName]
  )
  const _handleContractorNameChange = useCallback(
    (e) => {
      setContractorName(e.target.value)
    },
    [setContractorName]
  )
  const _handleResidentNameChange = useCallback(
    (e) => {
      setResidentName(e.target.value)
    },
    [setResidentName]
  )

  const _handleSearchClick = useCallback(
    () => {
      fetchData({
        property_name: propertyName,
        contractor_name: contractorName,
        resident_name: residentName
      })
    },
    [
      fetchData,
      propertyName,
      contractorName,
      residentName
    ]
  )

  const _handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        // _handleSearchClick()
      }
    },
    // [_handleSearchClick]
    []
  )

  return (
    <div>
      <Row>
        <RoleControlled authorized="!custom.yasue">
          <TextInput
            flex={1}
            type="text"
            name="property_name"
            label='properties:property_name'
            placeholder=""
            onChange={_handlePropertyNameChange}
            value={propertyName}
            onKeyPress={_handleKeyPress}
          />
        </RoleControlled>
        <TextInput
          flex={1}
          type="text"
          name="contractor_name"
          label='contracts:contractor_name'
          placeholder=""
          onChange={_handleContractorNameChange}
          value={contractorName}
          onKeyPress={_handleKeyPress}
        />
        <RoleControlled authorized="!custom.yasue">
          <TextInput
            flex={1}
            type="text"
            name="resident_name"
            label='contracts:resident_name'
            placeholder=""
            onChange={_handleResidentNameChange}
            value={residentName}
            onKeyPress={_handleKeyPress}
          />
        </RoleControlled>
        <SearchButtonStyle
          label="search"
          type="button"
          handleClick={_handleSearchClick}
          loading={loading}
        />
      </Row>
    </div>
  )
}

export default memo(ContractsForm)

ContractsForm.propTypes = {
  fetchData: PropTypes.func.isRequired
}
