import { api } from '@gk-devteam/apmc-core-web'

export const fetchFaqCategories = (cancelSource) => {
  return api.get('/faqs/categories', {
    cancelToken: cancelSource && cancelSource.token
  })
}
export const fetchFaq = (config, cancelSource) => {
  const { property, room } = config
  let path = 'admin'
  if (property) path = `properties/${property}`
  if (room) path = `rooms/${room}`

  return api.get(`${path}/faqs`, {
    cancelToken: cancelSource.token
  })
}

export const postFaq = (data, config, cancelSource) => {
  const { property, room } = config
  let path = 'admin'
  if (property) path = `properties/${property}`
  if (room) path = `rooms/${room}`

  return api.post(`${path}/faqs`, data, {
    cancelToken: cancelSource.token
  })
}

export const deleteFaq = (id, config, cancelSource) => {
  const { property, room } = config
  let path = 'admin'
  if (property) path = `properties/${property}`
  if (room) path = `rooms/${room}`

  return api.delete(`${path}/faqs/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const toggleFaq = (data, config, cancelSource) => {
  const { property, room, type } = config
  // type: admin_faqs, property_faqs
  let path = 'admin/faqs'
  if (property && type) path = `properties/${property}/${type}`
  if (room && type) path = `rooms/${room}/${type}`

  // console.log('path', path)
  // console.log('data', data)

  return api.post(`${path}/display`, data, {
    cancelToken: cancelSource.token
  })
}
export const bulkToggleFaq = (data, config, cancelSource) => {
  const { property, room, type } = config
  // type: admin_faqs, property_faqs
  let path = 'admin/faqs'
  if (property && type) path = `properties/${property}/${type}`
  if (room && type) path = `rooms/${room}/${type}`

  // console.log('path', path)
  // console.log('data', data)

  return api.post(`${path}/bulk_display`, data, {
    cancelToken: cancelSource.token
  })
}
