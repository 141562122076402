import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const inquiriesSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    title: yup.string().label(i18n.t('inquiries:title')),
    property_name: yup.string().label(i18n.t('properties:property_name')),
    contract_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('contracts:contract_id')),
    app_user_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('app_users:app_user_id')),
    app_user_name: yup.string().label(i18n.t('app_users:app_user_name')),
    sent_date_from: yup.date().nullable().label(i18n.t('inquiries:sent_datetime')),
    sent_date_to: yup.date().nullable().label(i18n.t('inquiries:sent_datetime')),
    limit: yup.number().integer().label(i18n.t('limit')),
    page: yup.number().integer().label(i18n.t('page')),
    sort_by: yup.string().label(i18n.t('sort_by')),
    sort_order: yup.string().label(i18n.t('sort_order'))
  })
}
