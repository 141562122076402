import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_SERVICES,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAIL,
  RESET_SERVICES,

  POST_CLOUDSIGN_CLIENT_ID,
  POST_CLOUDSIGN_CLIENT_ID_SUCCESS,
  POST_CLOUDSIGN_CLIENT_ID_FAIL
} from '../../types'

import { SERVICES_TYPES } from '../../constants'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  error: null,
  internalServices: [],
  externalServices: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_SERVICES]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_SERVICES_SUCCESS]: (state, action) => {
    state.loading = false
    state.internalServices = action?.payload.filter(service => service.type === SERVICES_TYPES.INTERNAL)
    state.externalServices = action?.payload.filter(service => service.type === SERVICES_TYPES.EXTERNAL)
  },
  [FETCH_SERVICES_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },

  [POST_CLOUDSIGN_CLIENT_ID]: (state, action) => {
    state.posting = true
  },
  [POST_CLOUDSIGN_CLIENT_ID_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CLOUDSIGN_CLIENT_ID_FAIL]: (state, action) => {
    state.posting = false
  },
  [RESET_SERVICES]: () => INITIAL_STATE
})
