import { useQueryParameters } from '@gk-devteam/apmc-core-web'

import {
  useNotification,
  useFirebaseApp,
  useFirebaseUser,
  useFirebaseAppObserver,
  useFirebaseUserObserver,
  useFirebaseRoomsObserver,
  usePusher,
  useContractsProcessing,
  useSpacesWaitingReservations
} from '../hooks'

const Initializer = () => {
  useFirebaseUserObserver() // subscribe to users.user.uid document
  useFirebaseAppObserver() // subscribe to app.general document
  useFirebaseApp() // check app version, handle force logout & force refresh (app level)
  useFirebaseUser() // handle force logout & force refresh (user level)
  useFirebaseRoomsObserver() // subscribe to chat rooms & checklist rooms // TODO: use waiting_chat_rooms collection
  useQueryParameters()
  useNotification()
  usePusher()
  useContractsProcessing()
  useSpacesWaitingReservations()
  return null
}

export default Initializer
