import { createSelector } from 'reselect'
import { isEqual } from 'lodash'
import dot from 'dot-object'
import { THEME } from '../../constants'

export const selectUserPreferences = state => state.userPreferences
export const selectForm = state => state.form

export const selectLoadingUserPreferences = createSelector(
  selectUserPreferences,
  userPreferences => userPreferences.loading
)

export const selectPostingUserPreferences = createSelector(
  selectUserPreferences,
  userPreferences => userPreferences.posting
)
export const selectUserPreferencesWebPush = createSelector(
  selectUserPreferences,
  userPreferences => userPreferences.webpush
)
export const selectUserPreferencesTheme = createSelector(
  selectUserPreferences,
  userPreferences => userPreferences.theme
)
export const selectUserPreferencesCurrentColorTheme = createSelector(
  selectUserPreferences,
  userPreferences => {
    const currentColors = userPreferences?.theme?.colors
    let label
    for (const key in THEME) {
      const theme = THEME[key]
      if (isEqual(theme, currentColors)) {
        label = `settings:theme.colors.variations.${key}`
        break
      }
    }
    if (currentColors && label) return { theme: currentColors, label }
    return null
  }
)

export const selectUserPreferencesFormColorTheme = createSelector(
  selectForm,
  form => {
    if (form && Object.keys(form).length > 0) {
      const data = dot.object({ ...form })
      return data?.colors
    }
    return null
  }
)
