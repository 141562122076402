import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControlLabel,
  Row,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import { Rate } from 'antd'

export default function YasueRate ({ rate, label }) {
  const { t } = useTranslation()
  return (
    <div>
      <FormControlLabel label={t(label)}/>
      <Row mb="L">
        <Rate
          count={7}
          defaultValue={rate}
          disabled
        />
      </Row>
    </div>
  )
}

YasueRate.propTypes = {
  rate: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
}
