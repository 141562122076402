import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
  Checkmark,
  Form,
  Image
} from '@gk-devteam/apmc-core-web'

export const FormStyle = styled(Form)`
  height: 400px;
  overflow-y: auto;
`

export const CalendarLinkStyle = styled.a`
  ${BORDER_RADIUS};
  margin: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
  border: solid 1px ${props => getColor(props, 'info')};
  color: ${props => getColor(props, 'info')};
  padding: 2px 8px;

  & > span {
    color: inherit;
  }
`

export const CalendarContainerStyle = styled.div`
  ${BORDER_RADIUS};
  box-sizing: border-box;
  width: 192px;
  height: 150px;
  border: 1px solid ${props => {
    if (props.selected) return getColor(props, 'accent')
    if (props.isFormSelected) return getColor(props, 'info')
    return getColor(props, 'cardBorder')
  }};
  border-width: ${props => props.selected || props.isFormSelected ? 2 : 1}px;
  margin: ${SPACING.SMALLEST}rem;
  cursor: ${props => props.selected ? 'default' : 'pointer'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckmarkStyle = styled(Checkmark)`
  position: absolute;
  bottom: 2px;
  right: 2px;
  padding: 0;
  transform: scale(1.5);

  &&& > span > span {
    border-color: ${props => props.isFormSelected ? getColor(props, 'info') : getColor(props, 'accent')};
    background-color: ${props => props.isFormSelected ? getColor(props, 'info') : getColor(props, 'accent')};
  }
`

export const ImageStyle = styled(Image)`
  cursor: inherit;
`

export const LinkPreviewStyle = styled.div`
  &&& {
    height: 40%;
    svg {
      height: 100%;
      width: auto;
      path {
        fill: ${props => getColor(props, 'info')}!important;
      }
    }
  }
`
