import React, { memo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import {
  dynamicLink,
  Text,
  Tag,
  Column
} from '@gk-devteam/apmc-core-web'

import {
  selectContractsProcessingCount,
  selectContractsProcessingLoading,
  selectContractsProcessing
} from '../../../selectors'
import {
  FETCH_CONTRACTS_PROCESSING,
  RESET_CONTRACTS_PROCESSING
} from '../../../types'
import { CONTRACT_STATUS } from '../../../constants'

import AdminTaskCard from './AdminTaskCard'

import { ContractorStyle, StatusStyle } from './AdminTasksPage.style'

const ContractsCard = () => {
  const dispatch = useDispatch()
  const count = useSelector(selectContractsProcessingCount)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch({ type: FETCH_CONTRACTS_PROCESSING })
    return () => {
      dispatch({ type: RESET_CONTRACTS_PROCESSING })
    }
  }, [dispatch])

  const navigateToContract = (id, row, e) => {
    const { status } = row
    let path = ''
    switch (status) {
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value:
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value:
      case CONTRACT_STATUS.RENEWING.value:
        path = 'renew'
        break
      case CONTRACT_STATUS.REQUESTING_CANCEL.value:
      case CONTRACT_STATUS.CANCELING.value:
        path = 'cancel'
        break
      default:
        break
    }
    const url = `/contracts/edit/${id}/${path}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  const roomCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return (
        <Column align="start">
          <ContractorStyle text={data.contractor_name} />
          <div>
            <Text text={data.property_name} />　<Text text={data.room_number} />
          </div>
        </Column>
      )
    },
    []
  )

  const statusCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      if (data && data.status != null) {
        let status, color
        for (const key in CONTRACT_STATUS) {
          const element = CONTRACT_STATUS[key]
          if (data.status === element.value) {
            status = `contracts:status.${key}`
            color = element.color
          }
        }
        return (
          <StatusStyle>
            <Tag label={status} color={color}/>
          </StatusStyle>
        )
      }
      return null
    },
    []
  )

  const columns = [
    {
      title: '',
      dataIndex: 'propertyName',
      key: 'propertyName',
      render: roomCell
    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: statusCell
    }
  ]

  return (
    <AdminTaskCard
      title="admin_tasks:contracts"
      linkTo={`./contracts?status=${CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value},${CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value}`}
      linkLabel="admin_tasks:to_contracts"
      unresolvedCountLabel=""
      unresolvedListLabel=""
      count={count}
      columns={columns}
      loadingSelector={selectContractsProcessingLoading}
      dataSelector={selectContractsProcessing}
      handleRowClick={navigateToContract}
      rowKey="id"
    />
  )
}

export default memo(ContractsCard)

ContractsCard.propTypes = {}
