import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  MainLayout,
  ItemNotFound
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertiesGroupName,
  selectPropertiesGroupData,
  selectPropertiesGroupError,
  selectLoadingPropertiesGroup
} from '../../../selectors'
import { FETCH_PROPERTIES_GROUP, RESET_PROPERTIES_GROUP } from '../../../types'

import PropertiesGroupForm from '../../../forms/userManagement/PropertiesGroupForm'

const PropertiesGroupDetailsPage = ({ groupID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const data = useSelector(selectPropertiesGroupData)
  const propertiesGroupName = useSelector(selectPropertiesGroupName)
  const error = useSelector(selectPropertiesGroupError)
  const loading = useSelector(selectLoadingPropertiesGroup)

  useEffect(() => {
    if (groupID) {
      dispatch({ type: FETCH_PROPERTIES_GROUP, id: groupID })
    }
    return () => {
      dispatch({ type: RESET_PROPERTIES_GROUP })
    }
  }, [dispatch, groupID])

  const breadcrumbs = [
    {
      label: 'navigation:management',
      link: '/user_management'
    },
    {
      text: propertiesGroupName || '',
      link: `/user_management/properties_groups/edit/${groupID}`
    }
  ]

  if (error && error.code === 404) return <ItemNotFound withSidebar item={t('user_management:properties_groups')} link="/user_management/properties_groups" />

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
      lastEdited={data && data.modified}
      lastEditedBy={data && data.modifiedBy}
    >
      {
        !loading && !error ? <PropertiesGroupForm data={data} propertiesGroupID={groupID}/> : null
      }
    </MainLayout>
  )
}
export default PropertiesGroupDetailsPage

PropertiesGroupDetailsPage.propTypes = {
  groupID: PropTypes.string.isRequired
}
PropertiesGroupDetailsPage.defaultProps = {

}
