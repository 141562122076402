import { createSelector } from 'reselect'

const selectApp = state => state.app

export const selectAppInfo = createSelector(
  selectApp,
  app => app
)
export const selectAppLogout = createSelector(
  selectApp,
  app => app.forceLogout
)
export const selectAppRefreshUser = createSelector(
  selectApp,
  app => app.forceRefreshUser
)
export const selectAppVersion = createSelector(
  selectApp,
  app => app.version
)
export const selectMaintenance = createSelector(
  selectApp,
  app => app.maintenance
)
export const selectFirebaseLoading = createSelector(
  selectApp,
  app => app.loading
)
