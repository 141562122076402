import React, { useState, useEffect, useCallback } from 'react'
import { Empty, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  useTranslation,
  api,
  Modal,
  Spinner,
  LightText,
  RadioStandAlone,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  selectNoticeStatusModal,
  selectActiveNoticeStatus
} from '../../../selectors'
import { CLOSE_NOTICE_STATUS_MODAL } from '../../../types'

import NoticeStatusUserRow from './NoticeStatusUserRow'
import {
  UserListTable,
  UserListHeader,
  UserListHeaderText
} from './NoticesPage.style'

const filterOptions = [
  { label: 'all', value: 1 },
  { label: 'notices:read', value: 2 },
  { label: 'notices:unread', value: 3 }
]

const NoticeStatusModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visible = useSelector(selectNoticeStatusModal)
  const noticeID = useSelector(selectActiveNoticeStatus)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [filter, setFilter] = useState(1)

  const fetchData = useCallback(
    async () => {
      try {
        const res = await api.get(`/notices/${noticeID}/status`)
        if (res && res.status === 200) {
          setData(res.data)
          setLoading(false)
        } else {
          message.error(t('fetch_error'))
          setLoading(false)
        }
      } catch (error) {
        console.warning('error', error)
        message.error(t('fetch_error'))
        setLoading(false)
      }
    },
    [noticeID, t]
  )

  useEffect(() => {
    if (visible && noticeID) { fetchData() }
    return () => {
      setLoading(true)
      setData(null)
    }
  }, [fetchData, visible, noticeID])

  const _closeModal = () => {
    dispatch({ type: CLOSE_NOTICE_STATUS_MODAL })
  }

  const _onFilterChange = useCallback(
    (value) => {
      setFilter(value)
    },
    []
  )

  const _renderRow = () => {
    if (!data) return null
    return data
      .filter(user => {
        // Show only read
        if (filter === 2) {
          return user.read
        } else if (filter === 3) {
          // Show only unread
          return !user.read
        } else {
          return true
        }
      })
      .map((user, i) => {
        return <NoticeStatusUserRow user={user} key={i} />
      })
  }

  const _renderData = () => {
    if (loading) return <Spinner color="info" />
    if (!data) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <LightText label='no_data'/>
          }
        />
      )
    }
    return (
      <UserListTable>
        <UserListHeader>
          <UserListHeaderText className="cell">{t('app_users:app_user_name')}</UserListHeaderText>
          <UserListHeaderText className="cell">{t('properties:property_name')}</UserListHeaderText>
          <RoleControlled authorized='!custom.house_maker'>
            <UserListHeaderText className="cell">{t('properties:room_number')}</UserListHeaderText>
          </RoleControlled>
          <UserListHeaderText className="cell">{t('notices:read_status')}</UserListHeaderText>
          <UserListHeaderText className="cell">{t('notices:read_time')}</UserListHeaderText>
          <UserListHeaderText className="cell"></UserListHeaderText>
        </UserListHeader>
        { _renderRow() }
      </UserListTable>
    )
  }

  return (
    <Modal
      visible={visible}
      onCancel={_closeModal}
      cancelText='close'
      title="notices:notice_status"
      large
      fullWidth
    >
      <RadioStandAlone
        name="notice_status_filter"
        handleChange={_onFilterChange}
        value={filter}
        options={filterOptions}
      />
      { _renderData() }
    </Modal>
  )
}

export default NoticeStatusModal

NoticeStatusModal.propTypes = {

}
NoticeStatusModal.defaultProps = {

}
