import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
  FONT_SIZE,
  Row
} from '@gk-devteam/apmc-core-web'

export const PanelInnerContainerStyle = styled.div`
  padding-bottom: ${props => props.noLegend ? 0 : SPACING.LARGER}rem;
`

export const DisabledCardStyle = styled(Row)`
  ${BORDER_RADIUS}
  border: 2px solid ${props => getColor(props, 'warning')};
  padding: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
  background-color: #fffef0;
  margin-bottom: ${SPACING.SMALL}rem;

  .anticon {
    ${FONT_SIZE.LARGE}
    color: ${props => getColor(props, 'warning')};
    margin-right: ${SPACING.REGULAR}rem;
  }
`

export const MessageStyle = styled(Row)`
  white-space: nowrap;

`
