import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const yasueConstructionWorksUpdateSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('yasue:construction.construction_work_id')),
    title: yup.string().max(255).required().label(i18n.t('yasue:construction.title')),
    contract_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().required().label(i18n.t('yasue:contracts.contract_manage_id')),
    work_number: yup.string().max(255).required().label(i18n.t('yasue:construction.work_number')),
    contract_date: yup
      .date()
      .nullable()
      .label(i18n.t('yasue:construction.contract_date')),
    delivery_date: yup
      .date()
      .nullable()
      .label(i18n.t('yasue:construction.delivery_date')),
    completion_date: yup
      .date()
      .nullable()
      .label(i18n.t('yasue:construction.completion_date')),
    postcode: yup.string().matches(/^\d{7}$/).nullable().label(i18n.t('yasue:construction.postcode')),
    prefecture: yup.string().max(255).nullable().label(i18n.t('yasue:construction.prefecture')),
    city: yup.string().max(255).nullable().label(i18n.t('yasue:construction.city')),
    address: yup.string().max(255).nullable().label(i18n.t('yasue:construction.address')),
    content: yup.string().nullable().max(5000).label(i18n.t('yasue:construction.content')),
    memo: yup.string().nullable().max(5000).label(i18n.t('common:memo'))
  })
}
