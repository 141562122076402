import React, { memo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row,
  TextInput
} from '@gk-devteam/apmc-core-web'

import { selectContractsLoading } from '../../../../../selectors'

import { SearchButtonStyle } from '../../../../contract/ContractForm/ContractForm.style'

const UsersForm = ({ fetchData }) => {
  const loading = useSelector(selectContractsLoading)
  const [roomNumber, setRoomNumber] = useState('')
  const [roomId, setRoomId] = useState('')
  const [propertyName, setPropertyName] = useState('')
  const [propertyId, setPropertyId] = useState('')

  const _handleRoomNumberChange = useCallback(
    (e) => {
      setRoomNumber(e.target.value)
    },
    [setRoomNumber]
  )
  const _handleRoomIdChange = useCallback(
    (e) => {
      setRoomId(e.target.value)
    },
    [setRoomId]
  )
  const _handlePropertyNameChange = useCallback(
    (e) => {
      setPropertyName(e.target.value)
    },
    [setPropertyName]
  )
  const _handlePropertyIdChange = useCallback(
    (e) => {
      setPropertyId(e.target.value)
    },
    [setPropertyId]
  )

  const _handleSearchClick = useCallback(
    () => {
      fetchData({
        roomNumber,
        roomId,
        propertyName,
        propertyId
      })
    },
    [
      fetchData,
      roomNumber,
      roomId,
      propertyName,
      propertyId
    ]
  )

  const _handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        // _handleSearchClick()
      }
    },
    // [_handleSearchClick]
    []
  )

  return (
    <div>
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="properties:room_number"
          label='properties:room_number'
          onChange={_handleRoomNumberChange}
          value={roomNumber}
          onKeyPress={_handleKeyPress}
        />
        <TextInput
          flex={1}
          type="text"
          name="rooms:room_id"
          label='rooms:room_id'
          onChange={_handleRoomIdChange}
          value={roomId}
          onKeyPress={_handleKeyPress}
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="properties:property_name"
          label='properties:property_name'
          onChange={_handlePropertyNameChange}
          value={propertyName}
          onKeyPress={_handleKeyPress}
        />
        <TextInput
          flex={1}
          type="text"
          name="properties:property_id"
          label='properties:property_id'
          onChange={_handlePropertyIdChange}
          value={propertyId}
          onKeyPress={_handleKeyPress}
        />
        <SearchButtonStyle
          label="search"
          type="button"
          handleClick={_handleSearchClick}
          loading={loading}
        />
      </Row>
    </div>
  )
}

export default memo(UsersForm)

UsersForm.propTypes = {
  fetchData: PropTypes.func.isRequired
}
