import { SPACING, styled, DeleteButtonStyle } from "@gk-devteam/apmc-core-web";

export const HiddenFileInput = styled.input`
  display: none;
`

export const ButtonWrapperStyle = styled.div`
  && > button{
    &:first-child {
      margin-right: ${SPACING.SMALL}rem;
    }
  }
`

export const DeleteFileButtonStyle = styled(DeleteButtonStyle)`
  appearance: button;
`

export const MainCellStyle = styled.div`
  display: inline-flex;
  align-items: center;

  && > span {
    white-space: nowrap;
    margin-right: ${SPACING.SMALL}rem;
  }
`
