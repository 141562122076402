import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  Title,
  Card,
  MainLayout,
  RoleControlled,
  InquiryID,
  Row,
  PrimaryButton
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyName,
  selectRoomName,
  selectRoomData
} from '../../../selectors'

import CurrentContract from './CurrentContract'
import RoomHistory from './RoomHistory'
import RoomForm from '../../../forms/room/RoomForm'
import { INQUIRY_PREFIX } from '../../../constants'

const RoomDetailsPage = ({ roomID, propertyID }) => {
  const navigate = useNavigate()
  const propertyName = useSelector(selectPropertyName)
  const roomName = useSelector(selectRoomName)
  const data = useSelector(selectRoomData)
  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      text: roomName,
      link: `/properties/edit/${propertyID}/rooms/edit/${roomID}`
    }
  ]

  const _handleContractRedirect = () => {
    navigate('/contracts/add', {
      state: {
        propertyID: Number(propertyID),
        propertyName,
        roomID: Number(roomID),
        roomName
      }
    })
  }

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      // loading={loading}
      lastEdited={data && data.modified}
      lastEditedBy={data && data.modifiedBy}
    >
      <Row justify="between" >
        <Title text={data && data.room_number} />
        <RoleControlled authorized="contracts.functions.add">
          <PrimaryButton
            label="rooms:add_contract"
            handleClick={_handleContractRedirect}
            reverse
          />
        </RoleControlled>
      </Row>
      <Row mb="ML">
        <InquiryID id={data?.id} prefix={INQUIRY_PREFIX.ROOM} />
      </Row>
      <Card>
        <RoomForm propertyID={propertyID} data={data} />
      </Card>
      <CurrentContract />
      <RoleControlled authorized="rooms.functions.contractsHistory">
        <RoomHistory roomID={roomID} />
      </RoleControlled>
    </MainLayout>
  )
}
export default memo(RoomDetailsPage)

RoomDetailsPage.propTypes = {
  propertyID: PropTypes.string.isRequired,
  roomID: PropTypes.string.isRequired
}
RoomDetailsPage.defaultProps = {}
