import {
  styled,
  SPACING
} from '@gk-devteam/apmc-core-web'

export const FilePreviewStyle = styled.a``

export const DescriptionStyle = styled.p`
  flex: 1;
  margin: 0 ${SPACING.SMALL}rem;
`
