import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
  Row,
  FormRemovableLabel,
  PrimaryButton,
  Card,
  Icon,
  DeleteButtonStyle
} from '@gk-devteam/apmc-core-web'

export const NoticeFormMainBlockStyle = styled.div`
  flex: 1;
  width: 100%;
`

export const FileCardStyle = styled(Card)`
  width: auto;
  margin-left: ${SPACING.LARGE}rem;
  align-self: flex-start;
`

export const FilePreviewStyle = styled.div`
  max-width: 250px;
`

export const DateTimeStyle = styled(Row)`
  margin-right: ${SPACING.LARGE}rem;
  margin-top: ${SPACING.SMALL}rem;
  span {
    margin-right: ${SPACING.REGULAR}rem;
  }
`
export const IconStyle = styled(Icon)`
  width: 14px;
  height: 14px;
  margin-right: ${SPACING.SMALLEST}rem;
`
export const StatusBlockStyle = styled(Row)`
  margin-right: ${SPACING.REGULAR}rem;
  justify-content: flex-start;
  width: auto;
  align-items: center;
`
export const StatusLabelStyle = styled.div`
  padding: ${SPACING.SMALLEST}rem;
  background-color: ${props => getColor(props, 'cardBorder')};
  border: solid 1px ${props => getColor(props, 'inputBorder')};

  &:first-of-type {
    border-radius: 4px 0 0 4px;
  }
`
export const StatusValueStyle = styled.div`
  width: 80px;
  padding: ${SPACING.SMALLEST}rem;
  border-top: solid 1px ${props => getColor(props, 'cardBorder')};
  border-bottom: solid 1px ${props => getColor(props, 'cardBorder')};

  &:last-of-type {
    border-radius: 0 4px 4px 0;
    border-right: solid 1px ${props => getColor(props, 'cardBorder')};
  }
`

export const RangeStyle = styled.div`
  margin-right: ${SPACING.REGULAR}rem;
`
export const RangeSymbolStyle = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ResultsBlockStyle = styled(Row)`
  flex: 1;
  overflow: scroll;
`

export const TableContainerStyle = styled.div`
  flex: 5;
  height: 100%;
  overflow: scroll;
  position: relative;
`

export const ResultsContainer = styled.ul`
  ${BORDER_RADIUS};
  padding: ${SPACING.SMALL}rem;
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  margin: 0;
  list-style: none;
  flex: 2;
  height: 100%;
  overflow: scroll;
`

export const RecipientStyle = styled(FormRemovableLabel)`
  margin: ${SPACING.SMALLEST / 2}rem;
`

export const BetweenStyle = styled.div`
  margin: 0 ${SPACING.SMALLEST}rem;
  user-select: none;
  color: ${props => getColor(props, 'lightText')};
`

export const CheckmarkCellStyle = styled.div`
  padding: calc(${SPACING.SMALL}rem - 2px) 0;
  text-align: center;
`

// export const AreaSelectStyle = styled(SelectStandAlone)`
//   ${commonStyles};
//   min-width: 175px;
//   margin-right: ${SPACING.REGULAR}rem;
// `

export const AddButtonStyle = styled(PrimaryButton)`
  margin-top: 11px;
  align-self: center;
`

export const StopButtonStyle = styled(DeleteButtonStyle)`
  ${(props) => props.stoppingNotice && `
    color: ${getColor(props, 'lightText')};
    border-color: ${getColor(props, 'lightText')};
    background-color: ${getColor(props, 'cardBorder')};
    cursor: not-allowed;
    user-select: none;
    pointer-events: none;
  `
  }
`
