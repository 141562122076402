import {
  getColor,
  Icon,
  Row,
  SPACING,
  styled
} from "@gk-devteam/apmc-core-web"

export const CloudsignWebhookStyle = styled.div`
  display: inline-flex;
  margin-right: ${SPACING.LARGE}rem;
  & > * {
    white-space: nowrap;
  }
`
export const EditIconStyle = styled(Icon)`
  width: ${SPACING.REGULAR}rem;
  height: ${SPACING.REGULAR}rem;
  color: ${props => getColor(props, 'lightText')};
  margin-left: ${SPACING.SMALLEST}rem;
  cursor: pointer;
`

export const InputRowStyle = styled(Row)`
  & > div {
    width: 100%;
  }
`
