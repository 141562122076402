import React, { useCallback, useEffect } from 'react'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  useTranslation,
  MainLayout,
  Row,
  Title,
  LightText,
  Card,
  Tabs,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  DELETE_FCM_TOKEN,
  FETCH_FCM_STATUS,
  FETCH_THEME,
  RESET_THEME
} from '../../../types'
import { selectUserPreferencesTheme, selectUserPreferencesWebPush } from '../../../selectors'
import { useNotification } from '../../../hooks/notificationHook'

import ThemeForm from '../../../forms/settings/ThemeForm'
import { SwitchStyle } from './UserPreferencesPage.style'

const UserPreferencesPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const webPush = useSelector(selectUserPreferencesWebPush)
  const theme = useSelector(selectUserPreferencesTheme)
  const pages = useSelector(selectPages)
  const { register } = useNotification()

  const breadcrumbs = [
    {
      label: 'navigation:settings',
      link: '/settings'
    },
    {
      label: 'navigation:settings_preferences',
      link: '/settings/'
    }
  ]

  const _fetchStatus = useCallback(
    () => {
      dispatch({ type: FETCH_FCM_STATUS })
    },
    [dispatch]
  )

  useEffect(() => {
    if (pages?.settings?.functions?.editTheme) dispatch({ type: FETCH_THEME })
    return () => {
      dispatch({ type: RESET_THEME })
    }
  }, [dispatch, pages])

  useEffect(() => {
    _fetchStatus()
  }, [_fetchStatus])

  const onChangeFail = useCallback(
    () => {
      message.error(t('webpush.notification_decline_error'))
    },
    [t]
  )

  const handleWebpushChange = async (e) => {
    const { checked } = e.target
    try {
      if (!checked) {
        dispatch({ type: DELETE_FCM_TOKEN, onFail: onChangeFail })
      } else {
        if ('Notification' in window) {
          register({ useRedux: true })
        }
      }
    } catch (error) {
      console.warn('Error getting permission...', error)
    }
  }

  const getPanes = () => {
    const panes = []

    if (pages?.settings?.functions?.editTheme) {
      panes.push({
        key: 0,
        tab: 'settings:theme.title',
        node: <ThemeForm data={theme} />
      })
    }

    panes.push({
      key: 1,
      tab: 'settings:webpush_title',
      node: (
        <Card
          title="settings:webpush"
          mt="ML"
        >
          <Row>
            <SwitchStyle
              mb="0"
              mr="0"
              size="fluid"
              name="webpush"
              checked={!!webPush}
              handleChange={handleWebpushChange}
              disabled={!('Notification' in window)}
              help={!('Notification' in window) ? 'settings:webpush_unavailable' : null}
            />
            <Row ml="S">
              <LightText label="settings:webpush_label" />
            </Row>
          </Row>
        </Card>
      )
    })

    return panes
  }

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
    >
      <Row justify="between" mb="ML">
        <Title label='navigation:settings_preferences' />
      </Row>

      <Tabs panes={getPanes()} />

    </MainLayout>
  )
}
export default UserPreferencesPage

UserPreferencesPage.propTypes = {}
UserPreferencesPage.defaultProps = {}
