import React from 'react'
import {
  Card,
  FilePicker,
  Form,
  RoleControlled,
  Row,
  Title
} from '@gk-devteam/apmc-core-web'

import { choueiBillingUpdateValidation } from '../../../validation'
import { selectChoueiBillingPosting } from '../../../selectors'
import ConfirmButton from './ConfirmButton'

function BillingForm () {
  const handleFormSubmit = (data) => {
    console.log('data', data)
  }
  return (
    <Form
      id="uploadBillingData"
      handleSubmit={handleFormSubmit}
      validationSchema={choueiBillingUpdateValidation}
      postingSelector={selectChoueiBillingPosting}
      initialData={{
        billing_file: null,
        billing_file_url: '',
        billing_file_filename: '',
        delete_billing_file: false
      }}
      authorized="custom.chouei"
      // debug
    >
      <Row justify="between" mb="ML">
        <Title label="navigation:chouei.billing" />
        <RoleControlled authorized="custom.chouei">
          <ConfirmButton />
        </RoleControlled>
      </Row>
      <Card mb="L" title="chouei:billing_file">
        <FilePicker
          name="billing_file"
          delete_name="delete_billing_file"
          types={['csv']}
        />
      </Card>
    </Form>
  )
}

BillingForm.propTypes = {

}

export default BillingForm
