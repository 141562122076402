import React from 'react'
import { useSelector } from 'react-redux'
import {
  Row,
  Select,
  TextInput,
  selectFormRequiredFields
} from '@gk-devteam/apmc-core-web'

import { CONTRACT_CANCEL_REASONS } from '../../../constants'

export default function CancelReason () {
  const cancelReason = useSelector(state => state?.form?.cancel_reason)
  const requiredFields = useSelector(selectFormRequiredFields)
  return (
    <Row align="baseline">
      <Select
        size="M"
        // maxW={80}
        name="cancel_reason"
        label="contracts:cancel_reason"
        placeholder=""
        options={CONTRACT_CANCEL_REASONS}
        noClear
      />
      {
        cancelReason === 9 // TODO: make this more readable (CONTRACT_CANCEL_REASONS => その他の値)
          ? (
            <TextInput
              flex={1}
              type="text"
              name="cancel_message"
              label='contracts:cancel_message'
              placeholder=""
              required={requiredFields?.cancel_message}
            />
          )
          : null
      }
    </Row>
  )
}
