import React from 'react'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'

import { selectUserPreferencesFormColorTheme } from '../../../selectors'
import { THEME } from '../../../constants'

import ColorBlock from './ColorBlock'

function ColorsBlocks () {
  const formColorTheme = useSelector(selectUserPreferencesFormColorTheme)

  return Object.keys(THEME).map(key => {
    return (
      <ColorBlock
        key={key}
        theme={THEME[key]}
        label={`settings:theme.colors.variations.${key}`}
        active={isEqual(formColorTheme, THEME[key])}
      />
    )
  })
}

export default ColorsBlocks
