/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'
import { SURVEY_QUESTION_TYPE } from '../../constants'

export const surveyUpdateSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    title: yup
      .string()
      .nullable()
      .required()
      .label(i18n.t('surveys:title')),
    description: yup
      .string()
      .nullable()
      .required()
      .label(i18n.t('surveys:description')),
    status: yup
      .number()
      .oneOf([1, 2, 3])
      .nullable()
      .required()
      .label(i18n.t('status')),
    start_time: yup
      .date()
      .nullable()
      .label(i18n.t('surveys:start_date')),
    end_time: yup
      .date()
      .nullable()
      .label(i18n.t('surveys:end_time')),
    recipient_type: yup
      .number()
      .oneOf([1, 2, 3])
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .nullable()
      .required()
      .label(i18n.t('surveys:recipient_type')),
    recipients_id: yup
      .array()
      .when('recipient_type', {
        is: 1,
        then: yup
          .array()
          .nullable(),
        otherwise: yup
          .array()
          .required()
          .of(
            yup
              .number()
              .transform((cv, ov) => ov === '' ? undefined : cv)
              .integer()
              .required()
          )
      })
      .label(i18n.t('surveys:recipients_id')),
    questions: yup
      .array()
      .of(
        yup.object().shape({
          label: yup
            .string()
            .nullable()
            .required()
            .label(i18n.t('surveys:question.label')),
          type: yup
            .number()
            .oneOf([1, 2, 3, 4, 5])
            .nullable()
            .required()
            .label(i18n.t('surveys:question.type')),
          required: yup
            .boolean()
            .nullable()
            .label(i18n.t('surveys:question.required')),
          max_length: yup
            .number()
            .transform((cv, ov) => ov === '' ? undefined : cv)
            .integer()
            .nullable()
            .max(10000)
            .label(i18n.t('surveys:question.max_length')),
          options: yup
            .array()
            .of(
              yup
                .string()
                .nullable()
                .test(
                  'conditional_required',
                  i18n.t('required', { label: '${label}' }),
                  function (value) {
                    const { type, options } = this.from[0].value
                    if (type === SURVEY_QUESTION_TYPE.CHECKBOX || type === SURVEY_QUESTION_TYPE.RADIO) {
                      if (!options || options.some(option => !option)) return false
                    }
                    return true
                  })
                // .required()
                .label(i18n.t('surveys:question.options'))
            )
            .nullable()
            .label(i18n.t('surveys:question.options'))
        })
      )
      .nullable()
      .label(i18n.t('surveys:questions'))
  })
}
