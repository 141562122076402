import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyID,
  selectPropertyName,
  selectRoomName,
  selectFaqLoading,
  selectFaqCategoriesOptionsWithReset,
  selectFaqHiddendBy,
  selectFaqHiddendById
} from '../../../../selectors'
import { FETCH_FAQ, RESET_FAQ } from '../../../../types'

import AdminFaqList from './AdminFaqList'
import PropertyFaqList from './PropertyFaqList'
import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import { FilterStyle } from '../faq.style'

const RoomFaqsPage = ({ roomID }) => {
  const dispatch = useDispatch()
  const propertyID = useSelector(selectPropertyID)
  const propertyName = useSelector(selectPropertyName)
  const roomName = useSelector(selectRoomName)
  const loading = useSelector(selectFaqLoading)
  const categoriesOptions = useSelector(selectFaqCategoriesOptionsWithReset)
  const [categoryFilter, setCategoryFilter] = useState('')

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      text: roomName,
      link: `/properties/edit/${propertyID}/rooms/edit/${roomID}`
    },
    {
      label: 'navigation:rooms_faq',
      link: `/properties/edit/${propertyID}/rooms/edit/${roomID}/faq`
    }
  ]

  useEffect(() => {
    if (roomID) {
      dispatch({ type: FETCH_FAQ, config: { room: roomID } })
    }
    return () => {
      dispatch({ type: RESET_FAQ })
    }
  }, [roomID, dispatch])

  const _handleFilterChange = (value) => {
    setCategoryFilter(value)
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectFaqHiddendBy}
      hiddenByIdSelector={selectFaqHiddendById}
      propertyID={propertyID}
      title="navigation:properties_faq"
      authorized="faq.functions.add"
      info="services:level_message.room"
      infoTitle="services:level_message.roomTitle"
    >
      <Row mt="L">
        <FilterStyle
          mb="0"
          noLabel
          handleChange={_handleFilterChange}
          value={categoryFilter}
          name="category_filter"
          options={categoriesOptions}
          loading={loading}
        />
      </Row>
      <AdminFaqList filter={categoryFilter} />
      <PropertyFaqList filter={categoryFilter} />
    </PanelLayout>
  )
}
export default RoomFaqsPage

RoomFaqsPage.propTypes = {
  roomID: PropTypes.string.isRequired
}
RoomFaqsPage.defaultProps = {}
