import React, { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_APP_USERS,
  RESET_APP_USERS
} from '../../../../types'

import {
  ResultsBlockStyle,
  BetweenStyle
} from '../../SurveyForm.style'

import UsersForm from './UsersForm'
import UsersList from './UsersList'
import RecipientsResult from './RecipientsResult'

const USERS_LIMIT = 100

const UsersModal = ({ visible, close }) => {
  const dispatch = useDispatch()

  const fetchData = useCallback(
    ({
      username = '',
      phone = '',
      email = '',
      id = '',
      manageID = ''
    }) => {
      dispatch({
        type: FETCH_APP_USERS,
        query: {
          app_user_name: username,
          phone,
          mail_address: email,
          app_user_id: id,
          app_user_manage_id: manageID,
          limit: USERS_LIMIT
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    fetchData({})
    return () => {
      dispatch({ type: RESET_APP_USERS })
    }
  }, [fetchData, dispatch])

  return (
    <Modal
      visible={visible}
      title='notices:select_users'
      onCancel={close}
      cancelText='close'
      fullWidth
      fullHeight
      large
    >
      <UsersForm fetchData={fetchData}/>
      <ResultsBlockStyle>
        <UsersList />
        <BetweenStyle> {'>>'} </BetweenStyle>
        <RecipientsResult />
      </ResultsBlockStyle>
    </Modal>
  )
}

export default memo(UsersModal)

UsersModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}
