import React from 'react'
import {
  Column,
  Row,
  Text
} from '@gk-devteam/apmc-core-web'
import { InfoCircleOutlined } from '@ant-design/icons'

import {
  UserStatusLegendContainerStyle,
  BoldStyle
} from './UserStatusLegend.style'

function UserStatusLegend () {
  return (
    <UserStatusLegendContainerStyle>
      <Row align="center">
        <InfoCircleOutlined />
        <BoldStyle label="app_users:status_legend.title"/>
      </Row>
      <Column mt="XS" align="start" pl="R">
        <Row>
          <BoldStyle label="app_users:login_status.no_login"/>：
          <Text label="app_users:status_legend.no_login"/>
        </Row>
        <Row>
          <BoldStyle label="app_users:login_status.no_confirmation"/>：
          <Text label="app_users:status_legend.no_confirmation"/>
        </Row>
        <Row>
          <BoldStyle label="app_users:login_status.active"/>：
          <Text label="app_users:status_legend.active"/>
        </Row>
        <Row>
          <BoldStyle label="app_users:login_status.inactive"/>：
          <Text label="app_users:status_legend.inactive"/>
        </Row>
      </Column>
    </UserStatusLegendContainerStyle>
  )
}

export default UserStatusLegend
