export const SUBSCRIBE_CHAT_WAITING_ROOMS = 'subscribe_chat_waiting_rooms'
export const SUBSCRIBE_CHAT_WAITING_ROOMS_SUCCESS = 'subscribe_chat_waiting_rooms_success'
export const SUBSCRIBE_CHAT_WAITING_ROOMS_FAIL = 'subscribe_chat_waiting_rooms_fail'
export const UNSUBSCRIBE_CHAT_WAITING_ROOMS = 'unsubscribe_chat_waiting_rooms'

export const FETCH_CHAT_ROOMS = 'fetch_chat_rooms'
export const FETCH_CHAT_ROOMS_SUCCESS = 'fetch_chat_rooms_success'
export const FETCH_CHAT_ROOMS_FAIL = 'fetch_chat_rooms_fail'
export const RESET_CHAT_ROOMS = 'reset_chat_rooms'

export const SUBSCRIBE_CHAT_ROOM = 'subscribe_chat_room'
export const SUBSCRIBE_CHAT_ROOM_SUCCESS = 'subscribe_chat_room_success'
export const SUBSCRIBE_CHAT_MESSAGES_SUCCESS = 'subscribe_chat_messages_success'
export const SUBSCRIBE_CHAT_ROOM_FAIL = 'subscribe_chat_room_fail'
export const UNSUBSCRIBE_CHAT_ROOM = 'unsubscribe_chat_room'
// export const RESET_CHAT_ROOM = 'reset_chat_room'

export const POST_CHAT_MESSAGE = 'post_chat_message'
export const POST_CHAT_MESSAGE_SUCCESS = 'post_chat_message_success'
export const POST_CHAT_MESSAGE_FAIL = 'post_chat_message_fail'

export const DELETE_CHAT_MESSAGE = 'delete_chat_message'
export const DELETE_CHAT_MESSAGE_SUCCESS = 'delete_chat_message_success'
export const DELETE_CHAT_MESSAGE_FAIL = 'delete_chat_message_fail'

export const UPDATE_CHAT_ROOM_STATUS = 'update_chat_room_status'
export const UPDATE_CHAT_ROOM_STATUS_SUCCESS = 'update_chat_room_status_success'
export const UPDATE_CHAT_ROOM_STATUS_FAIL = 'update_chat_room_status_fail'

export const FETCH_CHAT_SETTINGS = 'fetch_chat_settings'
export const FETCH_ADMIN_CHAT_SETTINGS_SUCCESS = 'fetch_admin_chat_settings_success'
export const FETCH_PROPERTY_CHAT_SETTINGS_SUCCESS = 'fetch_property_chat_settings_success'
export const FETCH_ROOM_CHAT_SETTINGS_SUCCESS = 'fetch_room_chat_settings_success'
export const FETCH_CHAT_SETTINGS_FAIL = 'fetch_chat_settings_fail'
export const UPDATE_CHAT_SETTINGS = 'update_chat_settings'
export const UPDATE_CHAT_SETTINGS_SUCCESS = 'update_chat_settings_success'
export const UPDATE_CHAT_SETTINGS_FAIL = 'update_chat_settings_fail'
export const RESET_CHAT_SETTINGS = 'reset_chat_settings'

export const FETCH_CHAT_STATUS = 'fetch_chat_status'
export const FETCH_CHAT_STATUS_SUCCESS = 'fetch_chat_status_success'
export const FETCH_CHAT_STATUS_FAIL = 'fetch_chat_status_fail'
export const RESET_CHAT_STATUS = 'reset_chat_status'
