import { createSelector } from 'reselect'

const selectYasueContacts = state => state.yasueContacts

export const selectLoadingYasueContacts = createSelector(
  selectYasueContacts,
  contacts => contacts.loading
)

export const selectYasueContactsCount = createSelector(
  selectYasueContacts,
  contacts => contacts.results_count
)
export const selectYasueContactsData = createSelector(
  selectYasueContacts,
  contacts => contacts.results
)
