import { createSelector } from 'reselect'

const selectGuides = state => state.guides

const parseDisplay = (data) => {
  const items = []
  for (const item of data) {
    items.push(item.display)
  }

  if (items.every(item => item === 0 || item === false)) return 0 // all hidden
  if (items.every(item => item === 2 || item === true)) return 2 // all displayed
  return 1
}

export const selectGuidesLoading = createSelector(
  selectGuides,
  guides => guides.loading
)
export const selectGuidesPosting = createSelector(
  selectGuides,
  guides => guides.posting
)
export const selectCategoryName = createSelector(
  selectGuides,
  guides => guides.categoryName
)

export const selectGuidesData = createSelector(
  selectGuides,
  guides => guides.data && guides.data
)

export const selectGuidesAllDisplayed = createSelector(
  selectGuides,
  guides => guides.data ? parseDisplay(guides.data) : 0
)

// export const selectPropertyGuideCategoriesData = createSelector(
//   selectGuides,
//   guides => guides.data && guides.data.property_results
// )
// export const selectRoomGuideCategoriesData = createSelector(
//   selectGuides,
//   guides => guides.data && guides.data.room_results
// )

// export const selectAdminGuideCategoriesAllDisplayed = createSelector(
//   selectGuides,
//   guides => guides.data && guides.data.admin_results ? parseDisplay(guides.data.admin_results) : 0
// )

export const selectGuidesHiddenBy = createSelector(
  selectGuides,
  guides => guides.data && guides.data.hiddenBy
)
export const selectGuidesHiddenById = createSelector(
  selectGuides,
  guides => guides.data && guides.data.hiddenById
)
