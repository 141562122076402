import { message } from 'antd'
import firebase from 'firebase/app'
import { getFileFromURL, UUID, getFileExtension } from '@gk-devteam/apmc-core-web'
import { i18n } from '../locales'

import {
  SUBSCRIBE_CHAT_WAITING_ROOMS,
  SUBSCRIBE_CHAT_WAITING_ROOMS_SUCCESS,
  UPDATE_CHAT_SETTINGS,
  FETCH_CHAT_SETTINGS,
  FETCH_ADMIN_CHAT_SETTINGS_SUCCESS,
  FETCH_PROPERTY_CHAT_SETTINGS_SUCCESS,
  FETCH_ROOM_CHAT_SETTINGS_SUCCESS,
  FETCH_CHAT_SETTINGS_FAIL,
  // SUBSCRIBE_CHAT_WAITING_ROOMS_FAIL,
  SUBSCRIBE_CHAT_ROOM,
  SUBSCRIBE_CHAT_ROOM_SUCCESS,
  SUBSCRIBE_CHAT_MESSAGES_SUCCESS,
  POST_CHAT_MESSAGE,
  POST_CHAT_MESSAGE_SUCCESS,
  POST_CHAT_MESSAGE_FAIL,
  DELETE_CHAT_MESSAGE,
  DELETE_CHAT_MESSAGE_SUCCESS,
  DELETE_CHAT_MESSAGE_FAIL,
  UPDATE_CHAT_ROOM_STATUS,
  UPDATE_CHAT_ROOM_STATUS_SUCCESS,
  UPDATE_CHAT_ROOM_STATUS_FAIL,
  SUBSCRIBE_CHAT_ROOM_FAIL,
  // SUBSCRIBE_CHAT_ROOM_FAIL,
  UNSUBSCRIBE_CHAT_ROOM,
  FETCH_CHAT_ROOMS_SUCCESS,
  FETCH_CHAT_ROOMS
} from '../types'
import { checkIfLoggedIn, db, storage } from '../services/firebaseService'
import {
  CHAT_ROOMS_COLLECTION_NAME,
  CHAT_CONFIG_COLLECTION_NAME,
  CHAT_MESSAGES_COLLECTION_NAME,
  CHAT_STORAGE_REF,
  SUPPORT_STATUS
} from '../constants'

let unsubscribeChatWaitingRooms
let unsubscribeChatRoom
let unsubscribeChatMessages

const emptySettings = {
  autoMessage: false,
  message: '',
  startTime: '',
  endTime: ''
}

const getChatWaitingRooms = (user, dispatch) => {
  if (user && user.uid) {
    // Remove listener if already exists
    if (unsubscribeChatWaitingRooms) {
      unsubscribeChatWaitingRooms()
    }
    const roomsRef = db.collection(CHAT_ROOMS_COLLECTION_NAME)
      .where(`members.${user.uid}`, '==', true)
      .where('type', '==', 0) // support type
      .where('managementStatus', '==', SUPPORT_STATUS.WAITING)
      .where('delete', '==', null)

    unsubscribeChatWaitingRooms = roomsRef.onSnapshot(querySnapshot => {
      const rooms = []
      querySnapshot.forEach(function (room) {
        const roomData = room.data()
        roomData.id = room.id
        roomData.userID = Number(room.id.replace(/contract:\d*_/, ''))
        rooms.push(roomData)
      })

      // Sort chronologicaly first
      rooms.sort((a, b) => {
        return b.lastUpdated.seconds - a.lastUpdated.seconds
      })

      dispatch({ type: SUBSCRIBE_CHAT_WAITING_ROOMS_SUCCESS, payload: rooms })
    })
  }
}
const getChatRoomsOnce = async (user, dispatch) => {
  if (user && user.uid) {
    const roomsRef = db.collection(CHAT_ROOMS_COLLECTION_NAME)
      .where(`members.${user.uid}`, '==', true)
      .where('type', '==', 0) // support type
      .where('delete', '==', null)
      .where('userLoggedIn', '==', true)

    const querySnapshot = await roomsRef.get()

    const rooms = []
    querySnapshot.forEach(function (room) {
      const roomData = room.data()
      roomData.id = room.id
      roomData.userID = Number(room.id.replace(/contract:\d*_/, ''))
      rooms.push(roomData)
    })

    // Sort chronologicaly first
    rooms.sort((a, b) => {
      return b.lastUpdated.seconds - a.lastUpdated.seconds
    })

    // Then sort by status
    rooms.sort((a, b) => {
      return b.managementStatus - a.managementStatus
    })

    dispatch({ type: FETCH_CHAT_ROOMS_SUCCESS, payload: rooms })
  }
}
const getChatRoom = async (roomID, user, dispatch, getState) => {
  if (user && user.uid) {
    // Remove listener if already exists
    if (unsubscribeChatRoom) {
      unsubscribeChatRoom()
    }
    if (unsubscribeChatMessages) {
      unsubscribeChatMessages()
    }

    const roomRef = db.collection(CHAT_ROOMS_COLLECTION_NAME).doc(roomID)

    const docSnapshot = await roomRef.get()
    if (docSnapshot && docSnapshot.exists) {
      const data = docSnapshot.data()
      if (data && !data.delete && data?.members?.[user.uid]) {
        unsubscribeChatRoom = roomRef.onSnapshot(doc => {
          const room = {
            ...doc.data(),
            id: doc.id
          }
          dispatch({ type: SUBSCRIBE_CHAT_ROOM_SUCCESS, payload: room })

          const messagesRef = db.collection(CHAT_MESSAGES_COLLECTION_NAME)
            .where('roomID', '==', roomID)
            .orderBy('created')
          unsubscribeChatMessages = messagesRef.onSnapshot(querySnapshot => {
            const messages = []
            querySnapshot.forEach(function (message) {
              const messageData = message.data()
              // if (!messageData?.deleted) {
              messageData.id = message.id
              messages.push(messageData)
              // }
            })

            if (getState()?.chat?.room?.id === roomID) {
              dispatch({ type: SUBSCRIBE_CHAT_MESSAGES_SUCCESS, payload: messages })
            }
          })
        })
      } else {
        dispatch({ type: SUBSCRIBE_CHAT_ROOM_FAIL, payload: { code: 404, message: '部屋が見つかりません。' } })
      }
    } else {
      dispatch({ type: SUBSCRIBE_CHAT_ROOM_FAIL, payload: { code: 404, message: '部屋が見つかりません。' } })
    }
  }
}

export const subscribeToChatWaitingRooms = () => (dispatch) => {
  dispatch({ type: SUBSCRIBE_CHAT_WAITING_ROOMS })
  checkIfLoggedIn((user) => getChatWaitingRooms(user, dispatch))
}
export const unSubscribeFromChatRooms = () => {
  try {
    if (unsubscribeChatWaitingRooms) unsubscribeChatWaitingRooms()
  } catch (error) {
    // Handle Errors here.
    // var errorCode = error.code
    // var errorMessage = error.message
    console.warn(error)
  }
}
export const fetchChatRooms = () => dispatch => {
  dispatch({ type: FETCH_CHAT_ROOMS })
  checkIfLoggedIn((user) => getChatRoomsOnce(user, dispatch))
}

export const subscribeToChatRoom = (roomID) => (dispatch, getState) => {
  dispatch({ type: SUBSCRIBE_CHAT_ROOM })
  checkIfLoggedIn((user) => getChatRoom(roomID, user, dispatch, getState))
}
export const unSubscribeFromChatRoom = () => (dispatch) => {
  try {
    if (unsubscribeChatRoom) {
      unsubscribeChatRoom()
    }
    if (unsubscribeChatMessages) {
      unsubscribeChatMessages()
    }
    dispatch({ type: UNSUBSCRIBE_CHAT_ROOM })
  } catch (error) {
    // Handle Errors here.
    // var errorCode = error.code
    // var errorMessage = error.message
    console.warn(error)
  }
}
export const updateChatRoomStatus = (roomID, data) => async (dispatch) => {
  dispatch({ type: UPDATE_CHAT_ROOM_STATUS })
  const roomRef = db.collection(CHAT_ROOMS_COLLECTION_NAME).doc(roomID)
  try {
    await roomRef.set(data, {
      merge: true
    })
    // if (onSuccess) onSuccess()
    dispatch({ type: UPDATE_CHAT_ROOM_STATUS_SUCCESS })
    message.success(i18n.t('post_success'))
    // if (onSuccess) onSuccess()
  } catch (error) {
    console.error('settings updated error', error)
    dispatch({ type: UPDATE_CHAT_ROOM_STATUS_FAIL })
    message.error(i18n.t('post_error'))
    // if (onFail) onFail()
  }
}

export const postChatFile = ({ data, onSuccess, onFail }) => async dispatch => {
  if (data.file) {
    dispatch({ type: POST_CHAT_MESSAGE })
    const { file, filename, type } = data
    const fileBlob = await getFileFromURL(file)
    const timestamp = new Date().valueOf()
    const fileExtension = getFileExtension(filename)
    const fileName = `${data.roomID.replace(':', '_')}_${timestamp}_${UUID()}.${fileExtension}`
    const metadata = {
      contentType: fileBlob.type
    }

    const storageDB = storage.ref(`${CHAT_STORAGE_REF[type]}${fileName}`)
    const uploadTask = storageDB.put(fileBlob, metadata)
    uploadTask.on('state_changed', function (snapshot) {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      console.log('Upload is ' + progress + '% done')
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused')
          break
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running')
          break
        default:
          break
      }
    }, function (error) {
      // Handle unsuccessful uploads
      console.log('error: ', error)
    }, async function () {
      // Handle successful uploads on complete
      const url = await uploadTask.snapshot.ref.getDownloadURL()
      delete data.file
      delete data.filename
      data.body = url
      data.fileExtension = fileExtension
      dispatch(postChatMessage({ data, onSuccess, onFail }))
    })
  }
}

export const postChatMessage = ({
  data,
  onSuccess,
  onFail
}) => async (dispatch) => {
  if (data) {
    dispatch({ type: POST_CHAT_MESSAGE })
    const roomRef = db.collection(CHAT_MESSAGES_COLLECTION_NAME)
    data.created = firebase.firestore.Timestamp.fromDate(new Date())

    try {
      const docRef = await roomRef.add(data)
      // console.log('Document written with ID: ', docRef.id)
      dispatch({ type: POST_CHAT_MESSAGE_SUCCESS, payload: docRef })
      if (onSuccess) onSuccess()
    } catch (error) {
      console.log('error adding doc', error)
      dispatch({ type: POST_CHAT_MESSAGE_FAIL })
      if (onFail) onFail()
    }
  }
}

export const deleteChatMessage = ({
  messageID,
  onSuccess,
  onFail
}) => async (dispatch) => {
  if (messageID) {
    dispatch({ type: DELETE_CHAT_MESSAGE })

    try {
      const docRef = db.collection(CHAT_MESSAGES_COLLECTION_NAME).doc(messageID)
      await docRef.delete()

      dispatch({ type: DELETE_CHAT_MESSAGE_SUCCESS, payload: docRef })
      message.success(i18n.t('delete_success'))

      if (onSuccess) onSuccess()
    } catch (error) {
      console.log('error deleting doc', error)
      message.error(i18n.t('delete_error'))
      dispatch({ type: DELETE_CHAT_MESSAGE_FAIL })
      if (onFail) onFail()
    }
  }
}

export const updateChatMessagesRead = (messages, userID) => async dispatch => {
  if (messages && userID) {
    const toUpdateIDs = []
    const updateContent = {
      [`reads.${userID}`]: true
    }
    for (const message of messages) {
      const { reads, id, user } = message
      const usersRead = reads && Object.keys(reads)
      // Create array of message id where author is not admin,
      // and the user is not included in the reads map
      if (
        user.id.indexOf('admin_') === -1 &&
        usersRead &&
        !usersRead.includes(userID)
      ) {
        toUpdateIDs.push(id)
      }
    }
    if (toUpdateIDs.length > 0) {
      const messagesRef = db.collection(CHAT_MESSAGES_COLLECTION_NAME)
      const batch = db.batch()

      for (const id of toUpdateIDs) {
        batch.update(messagesRef.doc(id), updateContent)
      }
      try {
        await batch.commit()
      } catch (error) {
        console.warn('error during messages reads update batch', error)
      }
    }
  }
}

const initWeekDays = (data) => {
  if (!data.daysOfWeek) {
    return {
      ...data,
      daysOfWeek: {
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false
      }
    }
  }
  return data
}

export const fetchChatSettings = ({
  adminDocument,
  propertyDocument,
  roomDocument
}) => async (dispatch) => {
  dispatch({ type: FETCH_CHAT_SETTINGS })

  const settingsRef = db.collection(CHAT_CONFIG_COLLECTION_NAME)
  const adminRef = adminDocument && settingsRef.doc(adminDocument)
  const propertyRef = propertyDocument && settingsRef.doc(propertyDocument)
  const roomRef = roomDocument && settingsRef.doc(roomDocument)

  try {
    if (adminDocument) {
      // console.debug('admin document...')
      const adminDoc = await adminRef.get()
      if (adminDoc.exists) {
        dispatch({ type: FETCH_ADMIN_CHAT_SETTINGS_SUCCESS, payload: initWeekDays(adminDoc.data()) })
      } else {
        dispatch({ type: FETCH_ADMIN_CHAT_SETTINGS_SUCCESS, payload: emptySettings })
      }
    }

    if (propertyDocument) {
      // console.debug('property document...')
      const propertyDoc = await propertyRef.get()
      if (propertyDoc.exists) {
        dispatch({ type: FETCH_PROPERTY_CHAT_SETTINGS_SUCCESS, payload: initWeekDays(propertyDoc.data()) })
      } else {
        dispatch({ type: FETCH_PROPERTY_CHAT_SETTINGS_SUCCESS, payload: emptySettings })
      }
    }

    if (roomDocument) {
      // console.debug('room document...')
      const roomDoc = await roomRef.get()
      if (roomDoc.exists) {
        dispatch({ type: FETCH_ROOM_CHAT_SETTINGS_SUCCESS, payload: initWeekDays(roomDoc.data()) })
      } else {
        dispatch({ type: FETCH_ROOM_CHAT_SETTINGS_SUCCESS, payload: emptySettings })
      }
    }
  } catch (error) {
    console.error('settings updated error', error)
    dispatch({ type: FETCH_CHAT_SETTINGS_FAIL })
    message.error(i18n.t('fetch_error'))
  }
}

export const updateChatSettings = ({ documentName, data, onSuccess, onFail }) => async (dispatch) => {
  dispatch({ type: UPDATE_CHAT_SETTINGS })
  const settingsRef = db.collection(CHAT_CONFIG_COLLECTION_NAME).doc(documentName)
  try {
    await settingsRef.set(data, {
      merge: true
    })
    if (onSuccess) onSuccess()
    // dispatch(fetchChatSettings(documentName))
    message.success(i18n.t('post_success'))
    if (onSuccess) onSuccess()
  } catch (error) {
    console.error('settings updated error', error)
    message.error(i18n.t('post_error'))
    if (onFail) onFail()
  }
}
