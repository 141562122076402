import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Card, Image, Row } from '@gk-devteam/apmc-core-web'

import { selectRefundReceipts } from '../../selectors'

import { ImageContainerStyle } from './RefundForm.style'

function Receipts () {
  const receipts = useSelector(selectRefundReceipts)
  if (!receipts) return null

  const _renderReceipts = () => {
    if (receipts) {
      return receipts.map((receipt, i) => (
        <ImageContainerStyle key={i}>
          <Image src={receipt} previewable contain/>
        </ImageContainerStyle>
      ))
    }
    return null
  }

  return (
    <Card title="refunds:receipts">
      <Row wrap="wrap">
        { _renderReceipts() }
      </Row>
    </Card>
  )
}

Receipts.propTypes = {
  receipts: PropTypes.arrayOf(PropTypes.shape({
    parking_date: PropTypes.string.isRequired,
    parking_name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    file_url: PropTypes.string.isRequired
  }).isRequired)
}

export default Receipts
