import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import {
  selectPropertyFaqData,
  selectPropertyFaqAllDisplayed,
  selectRoomID
} from '../../../../selectors'
import { TOGGLE_FAQ } from '../../../../types'

import FaqList from '../common/FaqList'

const PropertyFaqList = ({ filter }) => {
  const dispatch = useDispatch()

  const _handleFaqDisplay = useCallback(
    (display, id, item) => {
      if (id) {
        const data = {
          id: item.faq_id,
          display
        }
        const config = {
          type: 'property_faqs',
          room: id
        }
        dispatch({ type: TOGGLE_FAQ, data, config })
      } else {
        console.warning('no room id')
      }
    },
    [dispatch]
  )
  return (
    <FaqList
      dataSelector={selectPropertyFaqData}
      displaySelector={selectPropertyFaqAllDisplayed}
      idSelector={selectRoomID}
      title="faq:property_faq"
      filter={filter}
      type="property_faqs"
      level="room"
      handleFaqDisplay={_handleFaqDisplay}
    />
  )
}

export default PropertyFaqList

PropertyFaqList.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}
