import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import { selectImportType } from '../../../selectors'
import { IMPORT_TYPES } from '../../../constants'

import HelpContract from './HelpContract'
import HelpFaq from './HelpFaq'
import HelpProperty from './HelpProperty'

const HelpModal = ({ visible, closeModal }) => {
  const importType = useSelector(selectImportType)
  const _renderContent = () => {
    switch (importType) {
      case IMPORT_TYPES.PROPERTIES: // Properties Help
        return <HelpProperty />
      case IMPORT_TYPES.CONTRACTS: // Contracts Help
        return <HelpContract />
      case IMPORT_TYPES.FAQ: // Faq Help
        return <HelpFaq />
      default: // General Help
        return null
    }
  }
  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      cancelText="close"
      title="help"
    >
      <div>
        { _renderContent() }
      </div>
    </Modal>
  )
}

export default HelpModal

HelpModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  helpType: PropTypes.oneOf([1, 2, 3]) // 1: properties, 2: contracts, 3: faq
}
