import {
  styled,
  getColor,
  SPACING,
  Text,
  FormReadOnlyLink,
  InfoButton,
  DangerButton,
  Modal,
  Tag,
  Select,
  BORDER_RADIUS,
  FONT_SIZE
} from '@gk-devteam/apmc-core-web'
import { lighten, transparentize } from 'polished'

export const PropertyInputStyle = styled.div`
  margin-right: ${SPACING.LARGE}rem;
`
export const ContractReadOnlyLink = styled(FormReadOnlyLink)`
  flex-grow: 0;
  flex-shrink: 1;
  margin-right: ${SPACING.LARGEST}rem;
`

export const PropertiesModalStyle = styled(Modal)`
  /* height: 100%; */
  width: 100%;
`

export const SearchButtonStyle = styled(InfoButton)`
  margin-top: ${SPACING.REGULAR}rem;
  margin-left: ${SPACING.REGULAR}rem;
`

export const PropertyLinkStyle = styled(FormReadOnlyLink)`
  && {
    margin-bottom: 0;
    margin-right: 0;
    margin-left: ${SPACING.SMALLEST}rem;
  }
  & + div {
    margin-top: 0;
  }
`
export const PropertiesListStyle = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: ${SPACING.SMALLEST}rem;
  border-top: solid 1px ${props => getColor(props, 'cardBorder')};
  overflow-y: scroll;
  flex: 1;
  position: relative;
`
export const PropertiesLoaderStyle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const PropertiesListItemStyle = styled.li`
  margin-bottom: ${SPACING.SMALL}rem;
  padding: ${SPACING.SMALLEST}rem;
  cursor: pointer;
  &:hover {
    background-color: ${props => transparentize(0.9, getColor(props, 'accent'))};
  }
`

export const SelectRoomStyle = styled(Select)`
  max-width: 200px;
`

export const TotalFeesStyle = styled.div`
  margin-left: ${SPACING.LARGE}rem;
  display: flex;
  flex-direction: column;
  max-width: 200px;

  flex: ${props => props.flex ? `${props.flex} 1 auto` : 0};
  margin-bottom: ${SPACING.REGULAR}rem;
  &:not(:last-of-type) {
    margin-right: ${SPACING.LARGE}rem;
  }
`

export const TotalValueStyle = styled(Text)`
  padding: ${SPACING.SMALLEST}rem 0;
  font-size: 1rem;
`

export const AddFeesStyle = styled.div`
  cursor: pointer;
  color: ${props => getColor(props, 'accent')};
  display: flex;
  align-items: center;

  &:hover {
    color: ${props => lighten(0.05, getColor(props, 'accent'))};
  }
`
export const AddFeesTextStyle = styled(Text)`
  font-weight: bold;
  color: inherit;
  margin-left: ${SPACING.SMALLEST}rem;
`

export const UserNameLinkStyle = styled(FormReadOnlyLink)`
  max-width: 175px;
`

export const UserModalStyle = styled(Modal)`
  width: 100%;
`

const MessageStyle = styled.p`
  font-weight: bold;
`
export const ErrorMessageStyle = styled(MessageStyle)`
  color: ${props => getColor(props, 'danger')};
`
export const WarningMessageStyle = styled(MessageStyle)`
  color: ${props => getColor(props, 'warning')};
`
export const SuccessMessageStyle = styled(MessageStyle)`
  color: ${props => getColor(props, 'success')};
`
export const UserFieldNameStyle = styled(Text)`
  font-weight: bold;
`
export const ResetUserStyle = styled.button`
  appearance: none;
  background: none;
  border: none;
  outline: none;
  color: ${props => getColor(props, 'danger')};
  cursor: pointer;
  margin-left: ${SPACING.REGULAR}rem;
`

export const ApprovalCancelStyle = styled(DangerButton)`
  margin-right: ${SPACING.REGULAR}rem;
`

export const StatusStyle = styled(Tag)`
  && {
    margin-left: ${SPACING.SMALL}rem;
  }
`

export const LegacyContainerStyle = styled.div`
  margin-bottom: ${SPACING.LARGE}rem;
`

export const LegacyDescriptionStyle = styled.div`
  ${BORDER_RADIUS};
  padding: ${SPACING.SMALL}rem ${SPACING.REGULAR}rem;
  background-color: ${props => transparentize(0.9, getColor(props, 'info'))};
  border: 1px solid ${props => getColor(props, 'info')};

  .anticon {
    ${FONT_SIZE.LARGE};
    color: ${props => getColor(props, 'info')};
    margin-right: ${SPACING.REGULAR}rem;
  }
`

export const LegacyTextStyle = styled(Text)`
  margin-right: ${SPACING.REGULAR}rem;
`

export const RoomateRowStyle = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => getColor(props, 'inputBorder')};
    margin-bottom: ${SPACING.SMALL}rem;
  }
`

export const CancelButtonStyle = styled(DangerButton)`
  margin-left: ${SPACING.REGULAR}rem;
  margin-top: ${SPACING.REGULAR}rem;
`
