import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'
import {
  dynamicLink,
  FRONT_DATE_FORMAT,
  PageLayout,
  RESET_PARAMS,
  Row,
  selectQueryParams,
  Table,
  Text,
  Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_INSPECTIONS,
  RESET_INSPECTIONS
} from '../../../types'
import {
  selectInspectionsCount,
  selectInspectionsData,
  selectInspectionsLoading
} from '../../../selectors/house_maker/inspectionsSelector'
import { inspectionsSearchSchema } from '../../../validation'

import InspectionsSearchBar from './InspectionsSearchBar'

const breadcrumbs = [
  {
    label: 'navigation:house_maker.inspections_list',
    link: '/house_maker/inspections'
  }
]

const InspectionsPages = ({ location, navigate }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const { t } = useTranslation(['common', 'inspections'])
  const prevQuery = useRef(null)

  const fetchData = useCallback(
    () => {
      const schema = inspectionsSearchSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_INSPECTIONS, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_INSPECTIONS })
          }
        })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_INSPECTIONS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const dateCell = useCallback(
    (value) => {
      return <Text text={dayjs(value).format(FRONT_DATE_FORMAT)} />
    },
    []
  )
  const countCell = useCallback(
    (value) => {
      return <Text text={value ?? '-'} />
    },
    []
  )
  const inspectionsColumns = [
    {
      title: t('contracts:contractor_name'),
      dataIndex: 'contractor_name',
      key: 'contractor_name',
      cellTitle: true,
      sortable: true
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name'
    },
    {
      title: t('contracts:contract_manage_id'),
      dataIndex: 'contract_manage_id',
      key: 'contract_manage_id'
    },
    {
      title: t('contracts:contract_start_date'),
      dataIndex: 'contract_start',
      key: 'contract_start',
      render: dateCell,
      sortable: true
    },
    {
      title: t('inspections:inspection_count'),
      dataIndex: 'inspection_count',
      key: 'inspection_count',
      render: countCell
    },
    {
      title: t('inspections:next_inspection_date'),
      dataIndex: 'next_inspection_date',
      key: 'next_inspection_date',
      sortable: true
    }
  ]

  const _navigateToDetails = (id, row, e) => {
    dynamicLink({
      url: `/inspections/${id}`,
      event: e,
      navigate
    })
  }

  return (
    <PageLayout
      sidebar={() => <InspectionsSearchBar />}
      breadcrumbs={breadcrumbs}
    >
      <Row justify="between" align="center" mt="R" wrap={'wrap'}>
        <Title label="navigation:house_maker.inspections_list"/>
      </Row>
      <Table
        fullHeight
        columns={inspectionsColumns}
        rowKey="contract_id"
        loadingSelector={selectInspectionsLoading}
        resultsCountSelector={selectInspectionsCount}
        dataSelector={selectInspectionsData}
        onRowClick={_navigateToDetails}
        leftStickyColumnCount={1}
        rightStickyColumnCount={1}
      />
    </PageLayout>
  )
}
export default InspectionsPages

InspectionsPages.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}

InspectionsPages.defaultProps = {

}
