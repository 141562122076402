import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleFile,
  Modal, Spinner
} from '@gk-devteam/apmc-core-web'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'

import { selectAddInspectionModalState, selectContractInspectionsPosting } from '../../../selectors'
import {
  POST_CONTRACT_INSPECTION,
  TOGGLE_ADD_INSPECTION_MODAL
} from '../../../types'

import InspectionForm from '../../../forms/house_maker/InspectionForm'
import { downloadInspectionDocument } from '../../../services'

const initialValues = {
  inspection_date: '',
  file: null,
  file_url: null,
  delete_file: false
}

const InspectionModal = ({ contractId, refetch, data, onClose }) => {
  const dispatch = useDispatch()
  const visible = useSelector(selectAddInspectionModalState)
  const posting = useSelector(selectContractInspectionsPosting)
  const [initialData, setInitialData] = useState(null)
  const [loadingFile, setLoadingFile] = useState(null)

  useEffect(() => {
    setInitialData({
      ...initialValues,
      ...data
    })
    if (visible && data?.id && data?.with_file) {
      setLoadingFile(true)
      const cancelSource = CancelToken.source()
      downloadInspectionDocument(contractId, data.id, cancelSource)
        .then(res => {
          if (res.data) {
            handleFile({
              file: res.data,
              types: ['pdf'],
              maxSize: 10
            })
              .then(file => {
                const blobURL = URL.createObjectURL(file.file)
                const filename = `【${contractId}】${data?.inspection_date}.pdf`

                setInitialData(current => {
                  return {
                    ...current,
                    file: blobURL,
                    file_url: file.fileURL,
                    file_filename: filename
                  }
                })
              })
          }
        })
        .catch((error) => message.error(error?.data?.message || error?.message))
        .finally(() => setLoadingFile(false))
    }
  }, [data, contractId, visible])

  const _closeModal = () => {
    if (onClose) onClose()
    dispatch({ type: TOGGLE_ADD_INSPECTION_MODAL })
  }

  const _handleAddInspection = (data) => {
    const onSuccess = () => {
      dispatch({ type: TOGGLE_ADD_INSPECTION_MODAL })
      refetch()
    }
    dispatch({
      type: POST_CONTRACT_INSPECTION,
      data: {
        contract_id: contractId,
        ...data
      },
      onSuccess
    })
  }

  if (!initialData) return null

  return (
    <Modal
      visible={visible}
      formID="addInspectionForm"
      onCancel={_closeModal}
      onOk={_handleAddInspection}
      okText="save"
      title="inspections:add_inspection_date"
      fullWidth
      posting={posting}
    >
      {
        loadingFile
          ? <Spinner color="info"/>
          : <InspectionForm handleFormSubmit={_handleAddInspection} initialData={initialData}/>
      }
    </Modal>
  )
}

export default memo(InspectionModal)

InspectionModal.propTypes = {
  contractId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired
}
