import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchContractDocuments,
  postContractDocument,
  deleteContractDocument
} from '../../services'
import {
  FETCH_CONTRACT_DOCUMENTS,
  FETCH_CONTRACT_DOCUMENTS_SUCCESS,
  FETCH_CONTRACT_DOCUMENTS_FAIL,
  POST_CONTRACT_DOCUMENT,
  POST_CONTRACT_DOCUMENT_SUCCESS,
  POST_CONTRACT_DOCUMENT_FAIL,
  DELETE_CONTRACT_DOCUMENT,
  DELETE_CONTRACT_DOCUMENT_SUCCESS,
  DELETE_CONTRACT_DOCUMENT_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchContractDocumentsSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchContractDocuments, id, cancelSource)
    if (res && res.data && res.data.items) {
      yield put({ type: FETCH_CONTRACT_DOCUMENTS_SUCCESS, payload: { items: res.data.items, hiddenBy: res.data.hiddenBy, hiddenById: res.data.hiddenById } })
    } else {
      yield put({ type: FETCH_CONTRACT_DOCUMENTS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    console.log('err', error)
    yield put({ type: FETCH_CONTRACT_DOCUMENTS_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postContractDocumentSaga ({ contractID, data, successRedirect }) {
  const cancelSource = CancelToken.source()
  let preparedData
  if (data) {
    preparedData = { ...data }
    if (preparedData.label === 'その他' && preparedData.custom_label) {
      preparedData.label = preparedData.custom_label
    }
  }
  try {
    const res = yield call(postContractDocument, preparedData, contractID, cancelSource)
    if (res && res.data && res.data.success) {
      yield put({ type: POST_CONTRACT_DOCUMENT_SUCCESS })
      yield put({ type: FETCH_CONTRACT_DOCUMENTS, id: contractID })
      if (successRedirect) yield call(successRedirect)
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CONTRACT_DOCUMENT_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CONTRACT_DOCUMENT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteContractDocumentSaga ({ documentID, contractID }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteContractDocument, documentID, contractID, cancelSource)
    if (res) {
      yield put({ type: DELETE_CONTRACT_DOCUMENT_SUCCESS })
      yield put({ type: FETCH_CONTRACT_DOCUMENTS, id: contractID })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_CONTRACT_DOCUMENT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchContractDocuments () {
  yield takeLatest(FETCH_CONTRACT_DOCUMENTS, fetchContractDocumentsSaga)
  yield takeLatest(POST_CONTRACT_DOCUMENT, postContractDocumentSaga)
  yield takeLatest(DELETE_CONTRACT_DOCUMENT, deleteContractDocumentSaga)
}
