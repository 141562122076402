import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageLayout, Row, Table, Title, useTranslation } from '@gk-devteam/apmc-core-web'

import { selectLoadingYasueContacts, selectYasueContactsCount, selectYasueContactsData } from '../../../selectors'
import { FETCH_YASUE_CONTACTS, RESET_YASUE_CONTACTS } from '../../../types'
import ContactModal from './ContactModal'

const ContactsPage = () => {
  const { t } = useTranslation(['common', 'yasue'])
  const dispatch = useDispatch()
  const [activeMessage, setActiveMessage] = useState(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    dispatch({ type: FETCH_YASUE_CONTACTS })
    return () => {
      dispatch({ type: RESET_YASUE_CONTACTS })
    }
  }, [dispatch])

  const breadcrumbs = [
    {
      label: 'navigation:yasue.contacts',
      link: '/yasue/contacts'
    }
  ]

  const _showMessage = (_, row, e) => {
    setActiveMessage(row)
    setVisible(true)
  }
  const _closeModal = () => {
    setActiveMessage(null)
    setVisible(false)
  }

  const columns = [
    {
      title: t('contracts:contractor_name'),
      dataIndex: 'contractor_name',
      key: 'contractor_name',
      cellTitle: true
    },
    {
      title: t('yasue:construction.work_number'),
      dataIndex: 'work_number',
      key: 'work_number'
    },
    // {
    //   title: t('yasue:contacts.message'),
    //   dataIndex: 'message',
    //   key: 'message'
    // },
    {
      title: t('yasue:contacts.sent'),
      dataIndex: 'sent',
      key: 'sent'
    }
  ]

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
    >
      <ContactModal
        visible={visible}
        close={_closeModal}
        contact={activeMessage}
      />
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:yasue.contacts"/>
      </Row>
      <Table
        fullHeight
        columns={columns}
        rowKey="id"
        loadingSelector={selectLoadingYasueContacts}
        resultsCountSelector={selectYasueContactsCount}
        dataSelector={selectYasueContactsData}
        onRowClick={_showMessage}
        rightStickyColumnCount={0}
      />
    </PageLayout>
  )
}

export default ContactsPage

ContactsPage.propTypes = {}
ContactsPage.defaultProps = {}
