import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Form,
  FilePicker,
  TextArea
} from '@gk-devteam/apmc-core-web'

import { guideSchema } from '../../validation'

import {
  FileContainerStyle,
  InputContainerStyle,
  TextInputStyle
} from './GuideForm.style'

const GuideForm = ({ handleSubmit }) => {
  return (
    <Form
      id="guide_form"
      handleSubmit={handleSubmit}
      validationSchema={guideSchema}
      authorized="guides.functions.update"
      // loadingSelector={selectLoadingAppUser}
      // postingSelector={selectPostingAppUser}
      // initialData={initialValues}
      // debug
    >
      <Row>
        <FileContainerStyle>
          <FilePicker
            flex={1}
            name="file"
            delete_name="delete_file"
            types={['image', 'pdf']}
            placeholder=""
          />
        </FileContainerStyle>
        <InputContainerStyle>
          <TextInputStyle
            flex={1}
            type="text"
            name="name"
            label='title'
            placeholder=""
            required
          />
          <TextInputStyle
            flex={1}
            type="text"
            name="url"
            label='external_url'
            placeholder=""
          />
          <TextInputStyle
            flex={1}
            type="text"
            name="video_url"
            label='video_url'
            placeholder=""
          />
        </InputContainerStyle>
      </Row>
      <Row mt="R">
        <TextArea
          flex={1}
          name="content"
          label='content'
          placeholder=""
        />
      </Row>
    </Form>
  )
}

export default memo(GuideForm)

GuideForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}
