import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout
} from '@gk-devteam/apmc-core-web'

import {
  RESET_SURVEY,
  FETCH_SURVEY_SUCCESS
} from '../../../types'
import { fetchSurvey } from '../../../services'

import SurveyForm from '../../../forms/SurveyForm/SurveyForm'
import { SURVEY_STATUS } from '../../../constants'

const breadcrumbs = [
  {
    label: 'navigation:surveys',
    link: '/surveys'
  },
  {
    label: 'navigation:surveys_add',
    link: '/surveys/add'
  }
]

const initialData = {
  title: null,
  description: null,
  status: SURVEY_STATUS.UNPUBLISHED,
  start_time: null,
  end_time: null,
  recipient_type: 1,
  recipient_ids: null,
  questions: null
}

const SurveysAddPage = ({ location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(null)

  const prepareData = useCallback(
    async (data) => {
      if (data && data.id) {
        const editedData = { ...data }
        delete editedData.id
        delete editedData.delete_file
        delete editedData.modified_at

        // editedData.recipients_id = data?.recipients?.map(recipient => recipient.recipient_id)
        // editedData.recipients_list = data?.recipients?.map(recipient => ({
        //   id: recipient.recipient_id,
        //   label: recipient.recipient_name
        // }))
        editedData.status = SURVEY_STATUS.UNPUBLISHED
        editedData.questions = data?.questions?.map(question => ({
          label: question?.label,
          type: question?.type,
          required: question?.required,
          max_length: question?.max_length,
          options: question?.options
        }))

        setFormData(editedData)
      }
    },
    []
  )

  const fetchData = useCallback(
    async (id) => {
      const cancelSource = CancelToken.source()
      setLoading(true)
      try {
        const res = await fetchSurvey(id, cancelSource)
        setLoading(false)
        if (res && res.data) {
          prepareData(res.data)
          dispatch({ type: FETCH_SURVEY_SUCCESS, payload: null })
        } else {
          message.error(t('fetch_error'))
        }
      } catch (error) {
        setLoading(false)
        message.error(t('fetch_error'))
      }
    },
    [dispatch, prepareData, t]
  )

  useEffect(() => {
    if (location && location.state && location.state.copy) {
      fetchData(location.state.id)
    } else {
      setFormData(initialData)
      dispatch({ type: FETCH_SURVEY_SUCCESS, payload: null })
    }
    return () => {
      dispatch({ type: RESET_SURVEY })
    }
  }, [location, dispatch, fetchData])

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      {
        formData
          ? <SurveyForm data={formData}/>
          : null
      }
    </PageLayout>
  )
}
export default SurveysAddPage

SurveysAddPage.propTypes = {
  location: PropTypes.object
}
SurveysAddPage.defaultProps = {

}
