import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { QuestionCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import {
  useTranslation,
  Card,
  Row,
  Text,
  PrimaryButton,
  Confirm,
  RoleControlled,
  SpinnerStyle
} from '@gk-devteam/apmc-core-web'

import { selectNoticeEditable, selectNoticeData, selectStoppingNotice } from '../../selectors'
import { OPEN_NOTICE_STATUS_MODAL, STOP_NOTICE } from '../../types'

import NoticeStatusModal from '../../pages/notices/NoticesPage/NoticeStatusModal'

import {
  DateTimeStyle,
  IconStyle,
  StatusBlockStyle,
  StatusLabelStyle,
  StatusValueStyle,
  StopButtonStyle
} from './NoticeForm.style'

const NoticeStatus = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const editable = useSelector(selectNoticeEditable)
  const data = useSelector(selectNoticeData)
  const stopping = useSelector(selectStoppingNotice)
  if (editable) return null

  const startDateTime = data.start_time && data.start_time.split(' ')
  const startDate = startDateTime && startDateTime[0]
  const startTime = startDateTime && startDateTime[1]
  const endDateTime = data.stop_time && data.stop_time.split(' ')
  const endDate = endDateTime && endDateTime[0]
  const endTime = endDateTime && endDateTime[1]

  const stoppable = () => {
    const end = data && data.stop_time && dayjs(data.stop_time)
    return !end || (end && end.isAfter(dayjs()))
  }

  const _handleStopClick = () => {
    dispatch({ type: STOP_NOTICE, id: data.id, mode: 'details' })
  }

  const _handleDetailsClick = () => {
    dispatch({ type: OPEN_NOTICE_STATUS_MODAL, payload: data.id })
  }
  return (
    <Card title='notices:notice_status' mb="L">
      <NoticeStatusModal />
      <Row mb="R" align="center">
        <div>
          <Text label="notices:notice_sent_time" />
          <DateTimeStyle>
            <IconStyle name="calendar" />
            <Text text={startDate} />
            <IconStyle name="clock" />
            <Text text={startTime} />
          </DateTimeStyle>
        </div>
        {
          endDateTime
            ? (
              <div>
                <Text label="notices:notice_stop_time" />
                <DateTimeStyle>
                  <IconStyle name="calendar" />
                  <Text text={endDate} />
                  <IconStyle name="clock" />
                  <Text text={endTime} />
                </DateTimeStyle>
              </div>
            )
            : null
        }
        {
          stoppable()
            ? (
              <RoleControlled authorized="notices.functions.update">
                <div>
                  <Confirm
                    title='notices:stop_notice_confirm'
                    placement="topRight"
                    onConfirm={_handleStopClick}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  >
                    <StopButtonStyle stoppingNotice={stopping}>
                      {t('notices:stop_notice')}
                      {
                        stopping
                          ? (
                            <SpinnerStyle
                              color="text"
                              size="small"
                            />
                          )
                          : null
                      }
                    </StopButtonStyle>
                  </Confirm>
                </div>
              </RoleControlled>
            )
            : null
        }

      </Row>
      <div>
        <Text label="notices:read_status" />
        <Row mt="R">
          <StatusBlockStyle>
            <StatusLabelStyle>
              <Text label="notices:read_count" />
            </StatusLabelStyle>
            <StatusValueStyle>
              <Text text={data.read_count || '0'} />
            </StatusValueStyle>
            <StatusLabelStyle>
              <Text label="notices:unread_count" />
            </StatusLabelStyle>
            <StatusValueStyle>
              <Text text={data.unread_count || '0'} />
            </StatusValueStyle>
            <StatusLabelStyle>
              <Text label="notices:read_rate" />
            </StatusLabelStyle>
            <StatusValueStyle>
              <Text text={data.sent_count ? `${Number((data.read_count * 100) / data.sent_count).toFixed(2)}%` : ' '} />
            </StatusValueStyle>
          </StatusBlockStyle>
          <PrimaryButton
            type="button"
            label="notices:status_details"
            handleClick={_handleDetailsClick}
          />
        </Row>
      </div>
    </Card>
  )
}

export default NoticeStatus

NoticeStatus.propTypes = {}
