import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_PROPERTY,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAIL,
  RESET_PROPERTY,
  POST_PROPERTY,
  POST_PROPERTY_SUCCESS,
  POST_PROPERTY_FAIL
  // DELETE_PROPERTY,
  // DELETE_PROPERTY_SUCCESS,
  // DELETE_PROPERTY_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_PROPERTY]: (state, action) => {
    state.posting = true
  },
  [POST_PROPERTY_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_PROPERTY_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_PROPERTY]: (state, action) => {
    state.loading = true
  },
  [FETCH_PROPERTY_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_PROPERTY_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_PROPERTY]: () => INITIAL_STATE

})
