import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from '@reach/router'
import PropTypes from 'prop-types'

import { Card, DatePicker, Form, FormSubmitButton, InquiryID, Row, TextArea, TextInput, Title } from '@gk-devteam/apmc-core-web'
import SearchAddressButton from '../../../components/Misc/SearchAddressButton/SearchAddressButton'
import { selectLoadingConstructionWork, selectPostingConstructionWork } from '../../../selectors'
import { POST_CONSTRUCTION_WORK } from '../../../types'
import { yasueConstructionWorksUpdateSchema } from '../../../validation'

import Contract from './Contract'
import { INQUIRY_PREFIX } from '../../../constants'
import { CustomDatePickerStyle } from './ConstructionWorkForm.style'

const ConstructionWorkForm = ({ data, constructionWorkID, lockContract }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialValues = data

  const handleSubmit = (data) => {
    const successRedirect = (id) => {
      const redirect = location?.state?.redirect ?? '/yasue/construction_works?sort_by=contract_date&sort_order=desc'
      navigate(redirect)
    }

    dispatch({ type: POST_CONSTRUCTION_WORK, data, successRedirect })
  }

  return (
    <Form
      id="addConstructionWorkForm"
      handleSubmit={handleSubmit}
      loadingSelector={selectLoadingConstructionWork}
      postingSelector={selectPostingConstructionWork}
      validationSchema={yasueConstructionWorksUpdateSchema}
      initialData={initialValues}
      authorized="custom.yasue"
    >
      <Row justify="between" wrap="wrap">
        {
          constructionWorkID
            ? <Title text={data.title} />
            : <Title label='yasue:construction.add_new_construction_work' />
        }
        <FormSubmitButton editMode={!!constructionWorkID} postingSelector={selectPostingConstructionWork}/>
      </Row>
      <Row mb="ML">
        <InquiryID id={constructionWorkID} prefix={INQUIRY_PREFIX.YASUE.CONSTRUCTION_WORK} />
      </Row>
      <Card mb="L">
        <Row wrap="wrap" align="baseline">
          <TextInput
            size="L"
            name="title"
            label="yasue:construction.title"
            flex={1}
            type="text"
            required />
          <Contract constructionWorkID={constructionWorkID} disabled={lockContract} />
          <DatePicker
            name="contract_date"
            label="yasue:construction.contract_date"
            flex={1}
          />
        </Row>
        <Row wrap="wrap" align="baseline">
          <TextInput
            size="L"
            name="work_number"
            label="yasue:construction.work_number"
            flex={1}
            type="text"
            required
          />
          <DatePicker
            name="delivery_date"
            label="yasue:construction.delivery_date"
            flex={1}
          />
          <CustomDatePickerStyle
            name="completion_date"
            label="yasue:construction.completion_date"
            flex={1}
          />
        </Row>
        <Row wrap="wrap" align="start" mb="R">
          <TextInput
            size="L"
            mr="0"
            name="postcode"
            label="yasue:construction.postcode"
            pattern="^\d{7}$"
            flex={1}
            type="text"
          />
          <SearchAddressButton withReset />
        </Row>
        <Row wrap="wrap" align="baseline">
          <TextInput
            size="L"
            name="prefecture"
            label="yasue:construction.prefecture"
            flex={1}
            type="text"
            readonly
          />
          <TextInput
            size="L"
            name="city"
            label="yasue:construction.city"
            flex={1}
            type="text"
            readonly
          />
        </Row>
        <Row wrap="wrap">
          <TextInput
            name="address"
            label="yasue:construction.address"
            flex={1}
            type="text"
          />
        </Row>
        <Row wrap="wrap" align="baseline">
          <TextArea
            name="content"
            label="yasue:construction.content"
            flex={1}
          />
        </Row>
        <Row wrap="wrap" align="baseline">
          <TextArea
            name="memo"
            label="yasue:construction.memo"
            flex={1}
          />
        </Row>
      </Card>
    </Form>
  )
}

export default ConstructionWorkForm

ConstructionWorkForm.propTypes = {
  data: PropTypes.object,
  constructionWorkID: PropTypes.string,
  lockContract: PropTypes.bool
}
ConstructionWorkForm.defaultProps = {
}
