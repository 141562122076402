import {
  styled,
  getColor,
  FONT_SIZE,
  SPACING,
  LEFT_SIDEBAR_WIDTH,
  Link,
  Centered
} from '@gk-devteam/apmc-core-web'

export const PageStyle = styled(Centered)`
   padding-left: ${props => props.withSidebar ? LEFT_SIDEBAR_WIDTH : 0}px;
`

export const LinkStyle = styled(Link)`
   ${FONT_SIZE.MEDIUM};
   position: relative;
   margin: auto ${SPACING.SMALLEST}rem;
   color: ${props => getColor(props, 'accent')};
   box-shadow: none;

   &:not(:last-of-type) {
      &:after {
         content: '・';
         position: absolute;
         right: -1rem;
         color: ${props => getColor(props, 'text')};
      }
   }
`

export const ImageContainerStyle = styled.div`
  width: 200px;
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`
