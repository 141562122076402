import React, { memo, useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Document,
  Page,
  api,
  FILE_TYPES,
  Modal,
  Spinner,
  Row,
  Card,
  Image,
  LastEdited,
  Text
} from '@gk-devteam/apmc-core-web'

import {
  selectContractDocumentData,
  selectDocumentViewModalState,
  selectActiveDocumentID,
  selectCloudSignCertificate
} from '../../../selectors'
import { TOGGLE_VIEW_DOCUMENT_MODAL } from '../../../types'
import { PDF_CMAP_URL } from '../../../constants'

import { PreviewStyle } from './ContractDocumentsPage.style'

const ViewDocumentModal = ({ contractID }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visible = useSelector(selectDocumentViewModalState)
  const id = useSelector(selectActiveDocumentID)
  const cloudSignCertificate = useSelector(selectCloudSignCertificate)
  const data = useSelector(state => selectContractDocumentData(state, id))
  const [loading, setLoading] = useState(null)
  const [fileURL, setFileURL] = useState(null)
  const [fileType, setFileType] = useState(null)
  const [extension, setExtension] = useState(null)
  const [height, setHeight] = useState(400)

  const handlePreviewHeight = useCallback(
    () => {
      const windowHeight = window.innerHeight
      setHeight(windowHeight * 0.8)
    },
    []
  )

  useEffect(() => {
    handlePreviewHeight()
    window.addEventListener('resize', handlePreviewHeight)
    return () => {
      window.removeEventListener('resize', handlePreviewHeight)
    }
  }, [handlePreviewHeight])

  const fetchDocument = useCallback(
    async () => {
      if (id && contractID) {
        setLoading(true)
        try {
          const endpoint = cloudSignCertificate ? 'certificate' : 'download'
          const res = await api.get(`/contracts/${contractID}/documents/${id}/${endpoint}`, { responseType: 'blob' })
          if (res.headers && FILE_TYPES.pdfTypes.includes(res.headers['content-type'])) {
            setFileType('pdf')
            setExtension('pdf')
          } else {
            setFileType('image')
            setExtension(res.headers['content-type'].replace('image/', ''))
          }
          const fileURL = URL.createObjectURL(res.data)
          setFileURL(fileURL)
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.warning('error', error)
          message.error(t('fetch_error'))
        }
      }
    },
    [contractID, id, cloudSignCertificate, t]
  )
  useEffect(() => {
    fetchDocument()
    return () => {
      setFileType(null)
      setFileURL(null)
    }
  }, [fetchDocument])

  const _closeViewModal = () => {
    dispatch({ type: TOGGLE_VIEW_DOCUMENT_MODAL })
  }

  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   console.log('pdf loaded successfully', numPages)
  // }

  const _saveDocument = () => {
    saveAs(fileURL, `${data.document_label.replace(' ', '_')}.${extension}`)
  }

  const _renderPreview = () => {
    if (!fileType) return <></>
    if (fileType === 'pdf') {
      return (
        <PreviewStyle>
          <Document
            file={fileURL}
            loading={t('pdf_load')}
            error={t('pdf_load_fail')}
            // onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
            options={{
              cMapUrl: PDF_CMAP_URL,
              cMapPacked: true
            }}
          >
            <Page
              pageNumber={1}
              height={height}
              renderAnnotationLayer={false}
            />
          </Document>
        </PreviewStyle>

      )
    } else if (fileType === 'image') {
      return (
        <Image src={fileURL}/>
      )
    }
  }

  return (
    <Modal
      visible={visible && !!data}
      onCancel={_closeViewModal}
      cancelText="close"
      onOk={_saveDocument}
      okText="download"
      title={data && data.document_label}
      fullWidth
      fullHeight
      large
    >
      <div>
        {
          loading
            ? <Row justify="center"><Spinner size="default" color="info"/></Row>
            : (
              <>
                <Row mb="XS">
                  <Text label="file"/>
                </Row>
                <Card>
                  { _renderPreview() }
                </Card>
              </>
            )
        }
      </div>
      <Row justify="end" mt="R">
        <LastEdited
          lastEdited={data && data.modified}
          lastEditedBy={data && data.modifiedBy}
        />
      </Row>
    </Modal>
  )
}

export default memo(ViewDocumentModal)

ViewDocumentModal.propTypes = {
  contractID: PropTypes.string.isRequired
}
