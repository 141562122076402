import { api } from '@gk-devteam/apmc-core-web'

export const fetchContractOpelo = (id, cancelSource) => {
  return api.get(`contracts/${id}/opelo`, {
    cancelToken: cancelSource.token
  })
}
export const postContractOpelo = (id, data, cancelSource) => {
  return api.post(`contracts/${id}/opelo`, data, {
    cancelToken: cancelSource.token
  })
}
