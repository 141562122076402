import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { selectServiceName, selectServiceType } from '../../../selectors'
import { SERVICES_LEVEL } from '../../../constants'
import { roomsSearchSchema } from '../../../validation'

import ServiceByLevel from '../common/ServiceByLevel'

function RoomsServicePage ({ serviceID }) {
  const { t } = useTranslation()
  const serviceName = useSelector(selectServiceName)
  const type = useSelector(selectServiceType)
  const breadcrumbs = [
    {
      label: 'navigation:services',
      link: `/settings/services?type=${type}`
    },
    {
      label: serviceName,
      link: `/settings/services/${serviceID}/properties`
    },
    {
      label: 'navigation:service_rooms',
      link: `/settings/services/${serviceID}/rooms`
    }
  ]
  const columns = [
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name',
      cellTitle: true
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number',
      cellTitle: true,
      sortable: true
    },
    {
      title: t('properties:property_id'),
      dataIndex: 'property_id',
      key: 'property_id'
    },
    {
      title: t('rooms:room_id'),
      dataIndex: 'room_id',
      key: 'room_id',
      sortable: true
    }
  ]
  return (
    <ServiceByLevel
      id={serviceID}
      breadcrumbs={breadcrumbs}
      columns={columns}
      validationSchema={roomsSearchSchema}
      level={SERVICES_LEVEL.ROOM}
    />
  )
}

RoomsServicePage.propTypes = {
  serviceID: PropTypes.string.isRequired
}

export default RoomsServicePage
