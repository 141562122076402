import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  Row,
  Title,
  Tag,
  Image,
  ItemNotFound,
  PageLoader,
  SERVER_TIMESTAMP_FORMAT,
  selectPages,
  selectUserRole
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_CHECKLIST_ISSUE,
  RESET_CHECKLIST_ISSUE,
  UPDATE_CHECKLIST_ISSUE_STATUS
} from '../../../types'
import {
  selectChecklistIssueHiddenBy,
  selectChecklistIssueHiddenById,
  selectChecklistIssue,
  selectChecklistLoading,
  selectChecklistError,
  selectChecklistIssueLastEdited,
  selectChecklistIssueLastEditedBy
} from '../../../selectors'
import { ROLES } from '../../../constants'

import IssueComments from './IssueComments'
import { StatusRadioStyle, PanelDisabledStyle } from '../adminTasks.style'
import {
  IssueDetailsStyle,
  CaptionStyle
} from './AdminTasksChecklistRoomPage.style'

const AdminTasksChecklistRoomPage = ({ checklistID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const issue = useSelector(selectChecklistIssue)
  const lastEdited = useSelector(selectChecklistIssueLastEdited)
  const lastEditedBy = useSelector(selectChecklistIssueLastEditedBy)
  const loading = useSelector(selectChecklistLoading)
  const error = useSelector(selectChecklistError)
  const pages = useSelector(selectPages)
  const role = useSelector(selectUserRole)

  useEffect(() => {
    dispatch({ type: FETCH_CHECKLIST_ISSUE, checklistID })
    return () => {
      dispatch({ type: RESET_CHECKLIST_ISSUE })
    }
  }, [dispatch, checklistID])

  const breadcrumbs = [
    {
      label: 'navigation:admin_tasks',
      link: '/admin_tasks'
    },
    {
      label: 'navigation:checklists_list',
      link: '/admin_tasks/checklist'
    },
    {
      label: issue && issue.label,
      link: `/admin_tasks/checklist/${checklistID}`
    }
  ]

  const _handleChange = (status) => {
    dispatch({
      type: UPDATE_CHECKLIST_ISSUE_STATUS,
      checklistID,
      data: {
        status
      }
    })
  }

  const _renderStatusTag = () => {
    if (!issue || (issue && !issue.status)) return null
    let label, color
    switch (issue.status) {
      case 1:
        label = 'admin_tasks:status.is_unused'
        color = 'text'
        break
      case 2:
        label = 'admin_tasks:status.is_completed'
        color = 'success'
        break
      case 3:
        label = 'admin_tasks:status.is_confirmed'
        color = 'info'
        break
      case 4:
        label = 'admin_tasks:status.is_waiting'
        color = 'danger'
        break
      default:
        break
    }
    return <Tag label={label} color={color} />
  }

  const _renderStatusToggle = () => {
    return (
      <StatusRadioStyle
        name="checklist_status"
        handleChange={_handleChange}
        value={issue && issue.status}
        options={[
          {
            label: 'admin_tasks:status.is_waiting',
            value: 4
          },
          {
            label: 'admin_tasks:status.is_confirmed',
            value: 3
          },
          {
            label: 'admin_tasks:status.is_completed',
            value: 2
          }
        ]}
        disabled={
          issue?.hiddenBy ||
          (pages?.custom?.anabuki && issue?.repair && role > ROLES.AGENCY_MEMBER)
        }
      />
    )
  }

  if (loading) return <PageLoader />
  if (error && error.code === 404) return <ItemNotFound item={t('navigation:checklist_room')} link="/admin_tasks/checklist" />

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      lastEdited={lastEdited && dayjs(lastEdited).format(SERVER_TIMESTAMP_FORMAT)}
      lastEditedBy={lastEditedBy}
      // loading={!data}
    >
      <PanelDisabledStyle
        hiddenSelector={selectChecklistIssueHiddenBy}
        hiddenByIdSelector={selectChecklistIssueHiddenById}
        propertyID={issue?.property_id}
        contractID={issue?.contract_id}
      >
        <Row justify="between">
          <Title text={(issue && issue.label) || ''} />
          { _renderStatusToggle() }
        </Row>
        <Row mt="XS">
          <Tag label={(issue && issue.checklist_category_name) || ''} color="info"/>
          { _renderStatusTag() }
          { issue?.repair && <Tag label="admin_tasks:checklist_repair.flag" color="danger"/>}
        </Row>
        <IssueDetailsStyle
          mt="R"
          title={(issue && issue.label)}
          rightText={issue && issue.photo_datetime ? dayjs(issue.photo_datetime).format('YYYY/MM/DD HH:mm') : ''}
        >
          <CaptionStyle>{issue && issue.caption}</CaptionStyle>
          <Row>
            <Image
              src={issue && issue.photo_url}
              h="120px"
              // contain
              previewable
            />
          </Row>
        </IssueDetailsStyle>
        <IssueComments checklistID={checklistID} />
      </PanelDisabledStyle>
    </PageLayout>
  )
}
export default AdminTasksChecklistRoomPage

AdminTasksChecklistRoomPage.propTypes = {
  checklistID: PropTypes.string.isRequired
}
AdminTasksChecklistRoomPage.defaultProps = {

}
