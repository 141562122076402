import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { i18n } from '../../../locales'
import { selectImportValidationErrors, selectImportData, selectImportType } from '../../../selectors'
import {
  IMPORT_TYPES,
  IMPORT_PROPERTIES_HEADERS,
  IMPORT_PROPERTIES_HEADERS_LABEL,
  IMPORT_CONTRACTS_HEADERS,
  IMPORT_CONTRACTS_HEADERS_LABEL,
  IMPORT_FAQ_HEADERS,
  IMPORT_FAQ_HEADERS_LABEL,
  IMPORT_CASA_CONTRACTS_HEADERS,
  IMPORT_CASA_CONTRACTS_HEADERS_LABEL,
  IMPORT_YASUE_CONTRACTS_HEADERS,
  IMPORT_YASUE_CONTRACTS_HEADERS_LABEL,
  IMPORT_YASUE_CONSTRUCTION_WORK_HEADERS,
  IMPORT_YASUE_CONSTRUCTION_WORK_HEADERS_LABEL
} from '../../../constants'

import ImportConfirmCell from './ImportConfirmCell'
import {
  ImportConfirmTableContainerStyle,
  TableStyle
} from './ImportsConfirmPage.style'
import { selectPages } from '@gk-devteam/apmc-core-web'

const renderCell = (value, row, index, dataIndex) => {
  return <ImportConfirmCell dataIndex={dataIndex} value={value} index={index} originalIndex={row.originalIndex} />
}

const createColumns = (importType, pages) => {
  let columns, keys, labels
  switch (importType) {
    case IMPORT_TYPES.PROPERTIES:
      keys = IMPORT_PROPERTIES_HEADERS
      labels = IMPORT_PROPERTIES_HEADERS_LABEL
      break
    case IMPORT_TYPES.CONTRACTS:
      if (pages?.custom?.casa) {
        keys = IMPORT_CASA_CONTRACTS_HEADERS
        labels = IMPORT_CASA_CONTRACTS_HEADERS_LABEL
      } else if (pages?.custom?.yasue) {
        keys = IMPORT_YASUE_CONTRACTS_HEADERS
        labels = IMPORT_YASUE_CONTRACTS_HEADERS_LABEL
      } else {
        keys = IMPORT_CONTRACTS_HEADERS
        labels = IMPORT_CONTRACTS_HEADERS_LABEL
      }
      break
    case IMPORT_TYPES.FAQ:
      keys = IMPORT_FAQ_HEADERS
      labels = IMPORT_FAQ_HEADERS_LABEL
      break
    case IMPORT_TYPES.YASUE_CONTRUCTION_WORK:
      if (pages?.custom?.yasue) {
        keys = IMPORT_YASUE_CONSTRUCTION_WORK_HEADERS
        labels = IMPORT_YASUE_CONSTRUCTION_WORK_HEADERS_LABEL
      }
      break
    default:
      break
  }
  if (keys && labels) {
    columns = keys.map((key, i) => {
      return {
        title: i18n.t(labels[i]),
        dataIndex: key,
        render: (value, row, index) => renderCell(value, row, index, key)
      }
    })
  }

  return columns
}

const ImportConfirmTable = () => {
  const tableRef = useRef()
  const pages = useSelector(selectPages)
  // const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const validationErrors = useSelector(selectImportValidationErrors)
  const data = useSelector(selectImportData)
  const importType = useSelector(selectImportType)
  const [pageSize, setPageSize] = useState(20)

  const columns = createColumns(importType, pages)

  const _handleResizing = () => {
    const main = document.getElementById('main')
    const table = tableRef && tableRef.current
    if (table) {
      const pageWidth = document.documentElement.clientWidth
      // const pageHeight = document.documentElement.clientHeight
      const parentEl = main.parentElement
      const parentStyles = window.getComputedStyle(parentEl)
      const parentPaddingLeft = parseFloat(parentStyles.getPropertyValue('padding-left'))
      const parentPaddingRight = parseFloat(parentStyles.getPropertyValue('padding-right'))
      // const parentPaddingBottom = parseFloat(parentStyles.getPropertyValue('padding-bottom'))

      // const { top } = table.getBoundingClientRect()

      setWidth(pageWidth - parentPaddingLeft - parentPaddingRight)
      // setHeight(pageHeight - top - parentPaddingBottom)
    }
  }

  useEffect(() => {
    _handleResizing()
    window.addEventListener('resize', _handleResizing, false)
    return () => {
      window.removeEventListener('resize', _handleResizing, false)
    }
  }, [])

  const _handleRowError = (record, index) => {
    if (validationErrors && validationErrors[record.originalIndex]) return 'withError'
  }

  const _handlePageSizeChange = (current, size) => {
    setPageSize(size)
  }
  const _handlePageChange = (page, pageSize) => {
    window.scroll(0, 0)
  }

  if (!data || data.length === 0) return null

  return (
    <ImportConfirmTableContainerStyle ref={tableRef} width={width}>
      <TableStyle
        rowKey="originalIndex"
        dataSource={data}
        columns={columns}
        pagination={{ pageSize, onShowSizeChange: _handlePageSizeChange, onChange: _handlePageChange }}
        size="small"
        rowClassName={_handleRowError}
        scroll={{ x: 'max-content' }}
      />
    </ImportConfirmTableContainerStyle>
  )
}

export default ImportConfirmTable

ImportConfirmTable.propTypes = {
  data: PropTypes.array
}
