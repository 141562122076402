import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal,
  Row,
  Form,
  FilePicker
} from '@gk-devteam/apmc-core-web'
import { SendOutlined } from '@ant-design/icons'

import { selectChatPosting } from '../../../selectors'
import { chatPostMessageValidation } from '../../../validation'

const FileModal = ({
  visible,
  onCancel,
  handleSubmit,
  modalTitle,
  filePickerLabel,
  filePickerTypes
}) => {
  const posting = useSelector(selectChatPosting)

  const _handlePostFile = (data) => {
    // console.log('data', data)
    if (data && data.file) {
      handleSubmit(data.file, data.file_filename)
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={_handlePostFile}
      okText="submit"
      okIcon={<SendOutlined />}
      okCustomColor="accent"
      title={modalTitle}
      fullWidth
      formID="messageFileForm"
      posting={posting}
    >
      <Form
        id="messageFileForm"
        handleSubmit={_handlePostFile}
        validationSchema={chatPostMessageValidation}
        // loadingSelector={selectLoadingAppUser}
        postingSelector={selectChatPosting}
        initialData={{
          file: null,
          delete_file: false
        }}
      // debug
      >
        <Row>
          <FilePicker
            label={filePickerLabel}
            name="file"
            delete_name="delete_file"
            types={filePickerTypes}
            maxSize={25}
          />
        </Row>
      </Form>
    </Modal>
  )
}

export default FileModal

FileModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  filePickerLabel: PropTypes.string,
  filePickerTypes: PropTypes.arrayOf(function (propValue, key, componentName, location, propFullName) {
    const acceptedTypes = ['image', 'video', 'pdf', 'csv', 'doc', 'xls', 'ppt', 'txt']
    if (!propValue.every(value => acceptedTypes.includes(value))) {
      return new Error(
        `Failed prop validation: Invalid prop '${propFullName}' supplied to '${componentName}'.
        Expected an array containing theses values: ${acceptedTypes}.
        `
      )
    }
  })
}
