
import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  ItemNotFound,
  MainLayout,
  PageLoader,
  useTranslation
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyName,
  selectSpaceData,
  selectSpaceError,
  selectSpaceLoading
} from '../../../selectors'

import PropertySpaceForm from '../../../forms/PropertySpaceForm/PropertySpaceForm'

const PropertySpaceDetailsPage = ({ propertyID, spaceID }) => {
  const { t } = useTranslation()
  const data = useSelector(selectSpaceData)
  const loading = useSelector(selectSpaceLoading)
  const error = useSelector(selectSpaceError)
  const propertyName = useSelector(selectPropertyName)

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_spaces',
      link: `/properties/edit/${propertyID}/spaces`
    },
    {
      label: data?.name || '',
      link: `/properties/edit/${propertyID}/spaces/edit/${spaceID}`
    }
  ]

  return loading
    ? <PageLoader />
    : error && error.code === 404
      ? <ItemNotFound item={t('navigation:properties_spaces')} link={`/properties/edit/${propertyID}/spaces`} />
      : (
        <MainLayout
          withSidebar={true}
          breadcrumbs={breadcrumbs}
          loading={loading}
        >
          <PropertySpaceForm propertyID={propertyID} spaceID={spaceID} data={data}/>
        </MainLayout>
      )
}
export default PropertySpaceDetailsPage

PropertySpaceDetailsPage.propTypes = {
  propertyID: PropTypes.string.isRequired,
  spaceID: PropTypes.string.isRequired
}
PropertySpaceDetailsPage.defaultProps = {

}
