import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import dot from 'dot-object'

import RecipientsItem from '../RecipientsItem'
import {
  ResultsContainer
} from '../../SurveyForm.style'

const RecipientsResult = (props) => {
  const selectedRecipients = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    return form.recipients_list
  })

  const _renderRecipients = () => {
    if (!selectedRecipients) return null
    return selectedRecipients.map(recipient => <RecipientsItem key={recipient.id} id={recipient.id} label={recipient.label} />)
  }

  return (
    <ResultsContainer>
      { _renderRecipients() }
    </ResultsContainer>
  )
}

export default memo(RecipientsResult)
