import React, { memo } from 'react'
import {
  Card,
  RoleControlled,
  Row
} from '@gk-devteam/apmc-core-web'

import NoticeRecipientType from './NoticeRecipientType'
import NoticeUserType from './NoticeUserType'
import NoticeConditions from './NoticeConditions'
import NoticeRecipientsSearch from './NoticeRecipientsSearch'
import NoticeRecipientsList from './NoticeRecipientsList'

const NoticeRecipientsSettings = () => {
  return (
    <Card title='notices:recipients_settings' mb="L">
      <NoticeRecipientType />
      <RoleControlled authorized='!custom.house_maker'>
        <RoleControlled authorized='!custom.yasue'>
          <Row wrap="wrap" align="baseline" mt="S">
            <NoticeUserType />
          </Row>
        </RoleControlled>
      </RoleControlled>
      <NoticeRecipientsSearch />
      <NoticeRecipientsList />
      <RoleControlled authorized="!custom.yasue">
        <Row wrap="wrap" align="baseline" mt="S">
          <NoticeConditions />
        </Row>
      </RoleControlled>
    </Card>
  )
}

export default memo(NoticeRecipientsSettings)

// NoticeRecipients.propTypes = {}
