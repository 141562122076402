import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Tag, Text } from '@gk-devteam/apmc-core-web'

import { UlStyle, LiStyle, LabelStyle, TextStyle, LinkStyle } from './InuiContactsPage.style'

const items = [
  {
    key: 'app_user_name',
    label: 'app_users:app_user_name',
    link: '/app_users/edit/$id',
    linkKey: 'app_user_id'
  },
  {
    key: 'property_name',
    label: 'properties:property_name',
    link: '/properties/edit/$id',
    linkKey: 'property_id'
  },
  {
    key: 'room_number',
    label: 'properties:room_number',
    link: '/properties/edit/$id1/rooms/edit/$id2',
    linkKey: ['property_id', 'room_id']
  },
  {
    key: 'app_user_phone',
    label: 'phone'
  },
  {
    key: 'contact_type',
    label: 'inui:contact_types',
    options: 'inui:contact_type.',
    colors: {
      1: 'success',
      2: 'info',
      3: 'danger',
      4: 'process',
      5: 'accent'
    }
  },
  {
    key: 'bicycle_count',
    label: 'inui:bicycle_count',
    suffix: '台'
  },
  {
    key: 'bike_type',
    label: 'inui:type',
    options: 'inui:bike_types.'
  },
  {
    key: 'car_height',
    label: 'inui:car_height',
    suffix: 'mm'
  },
  {
    key: 'car_length',
    label: 'inui:car_length',
    suffix: 'mm'
  },
  {
    key: 'car_width',
    label: 'inui:car_width',
    suffix: 'mm'
  },
  {
    key: 'car_weight',
    label: 'inui:car_weight',
    suffix: 'kg'
  },
  {
    key: 'parking_type',
    label: 'inui:type',
    options: 'inui:parking_types.'
  },
  {
    key: 'payment_method',
    label: 'inui:payment_method',
    options: 'inui:payment_methods.'
  },
  {
    key: 'trunk_type',
    label: 'inui:type',
    options: 'inui:trunk_types.'
  },
  {
    key: 'start_day',
    label: 'inui:start_day'
  },
  {
    key: 'sent_at',
    label: 'notices:sent_time'
  }
]

function ContactDetailsModal ({ data, visible, close }) {
  if (!data) return null

  const _renderItems = () => {
    return items.map(item => {
      const {
        key,
        label,
        link,
        linkKey,
        options,
        suffix,
        colors
      } = item
      const value = data[key]
      if (!value) return null
      let Value = function DefaultValue () { return <TextStyle text={value} /> }
      if (link) {
        let to = link
        if (typeof linkKey === 'string') to = link.replace('$id', data[linkKey])
        if (Array.isArray(linkKey)) {
          for (let index = 0; index < linkKey.length; index++) {
            const idKey = linkKey[index]
            const id = data[idKey]
            to = to.replace(`$id${index + 1}`, id)
          }
        }
        Value = function LinkValue () { return <LinkStyle to={to}>{value}</LinkStyle> }
      }
      if (options) {
        if (colors) {
          Value = function OptionValue () { return <Tag color={colors[value]} label={`${options}${value}`}/> }
        } else {
          Value = function OptionValue () { return <TextStyle label={`${options}${value}`} /> }
        }
      }
      return (
        <LiStyle key={key}>
          <LabelStyle><Text label={label} /></LabelStyle>
          <Value />
          { suffix ? <TextStyle text={suffix} /> : null }
        </LiStyle>
      )
    })
  }
  return (
    <Modal
      visible={visible}
      onCancel={close}
      fullWidth
      title="inui:contact_details"
      cancelText="close"
    >
      <UlStyle>
        { _renderItems() }
      </UlStyle>
    </Modal>
  )
}

ContactDetailsModal.propTypes = {
  data: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

export default ContactDetailsModal
