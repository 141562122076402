import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
  FONT_SIZE,
  LightText
} from '@gk-devteam/apmc-core-web'
import { transparentize } from 'polished'

export const ChatMessageContainerStyle = styled.article`
    justify-content: ${props => props.isOwn ? 'flex-end' : 'flex-start'};
    display: flex;
    width: 100%;
    margin-bottom: ${SPACING.SMALLEST}rem;
`
export const ChatBodyContainerStyle = styled.div`
  ${BORDER_RADIUS};
  & > div {
    border: 1px solid ${props => !props.isOwn && !props.isRead ? getColor(props, 'accent'): 'inherit'};
    transition: all 2000ms ease-in-out;
  }

`
export const ChatMessageMainStyle = styled.div`
    /* max-width: 280px; */
    max-width: 60%;
    min-width: 240px;
`
export const MessageImageContainerStyle = styled.div`
    ${BORDER_RADIUS};
    height: 200px;
    overflow: hidden;
    & > div {
        img {
            width: auto;
            height: auto;
        }
    }
`
export const MessageFileContainerStyle = styled.div`
    ${BORDER_RADIUS};
    width: 250px;
    border: solid 1px ${props => getColor(props, 'cardBorder')};
    padding: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
`
export const FileIconContainerStyle = styled.div`
    height: 32px;
    width: auto;
`
export const FileDownloadStyle = styled.a`
    margin-left: ${SPACING.REGULAR}rem;
`
export const MessageVideoContainerStyle = styled.div`
    ${BORDER_RADIUS};
    height: 200px;
    width: 400px;
`

export const ChatMessageTextStyle = styled.div`
    ${BORDER_RADIUS}
    padding: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
    border: 1px solid ${props => props.isOwn ? 'transparent' : getColor(props, 'inputBorder')};
    background-color: ${props => {
      if (!props.isOwn && !props.isRead) return transparentize(0.8, getColor(props, 'accent'))
      if (props.isOwn) return transparentize(0.9, getColor(props, 'accent'))
      return getColor(props, 'white')
    }};
`

export const ChatDateContainerStyle = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    align-items: center;
    div{
        span{
            margin-right: 4px;
        }
    }
`

export const FileSizeStyle = styled(LightText)`
  ${FONT_SIZE.SMALL};
`

export const DeleteMessageStyle = styled.span`
    margin-left: ${SPACING.SMALL}rem;
    cursor: pointer;
    color: ${props => {
      return getColor(props, 'danger')
    }};

    & > span {
        display: inline-flex;
        align-items: center;
    }
`
