import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { selectServiceName, selectServiceType } from '../../../selectors'
import { contractsSearchSchema } from '../../../validation'
import { SERVICES_LEVEL } from '../../../constants'

import ServiceByLevel from '../common/ServiceByLevel'

function ContractsServicePage ({ serviceID }) {
  const { t } = useTranslation()
  const serviceName = useSelector(selectServiceName)
  const type = useSelector(selectServiceType)
  const breadcrumbs = [
    {
      label: 'navigation:services',
      link: `/settings/services?type=${type}`
    },
    {
      label: serviceName,
      link: `/settings/services/${serviceID}/properties`
    },
    {
      label: 'navigation:service_contracts',
      link: `/settings/services/${serviceID}/contracts`
    }
  ]
  const columns = [
    {
      title: t('contracts:contractor_name'),
      dataIndex: 'contractor_name',
      key: 'contractor_name',
      cellTitle: true
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name'
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number'
    },
    {
      title: t('contracts:contract_id'),
      dataIndex: 'contract_id',
      key: 'contract_id',
      sortable: true
    }
  ]
  return (
    <ServiceByLevel
      id={serviceID}
      breadcrumbs={breadcrumbs}
      columns={columns}
      validationSchema={contractsSearchSchema}
      level={SERVICES_LEVEL.CONTRACT}
    />
  )
}

ContractsServicePage.propTypes = {
  serviceID: PropTypes.string.isRequired
}

export default ContractsServicePage
