import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Row,
  Tree,
  Spinner
} from '@gk-devteam/apmc-core-web'

import {
  selectAreasLoading,
  selectAreasTree,
  selectNoticeEditable
} from '../../../../../selectors'

import {
  FETCH_AREAS,
  RESET_AREAS
  // ADD_AREA
} from '../../../../../types'

const AreasRecipients = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectAreasLoading)
  const areasTree = useSelector(selectAreasTree)
  const editable = useSelector(selectNoticeEditable)

  useEffect(() => {
    dispatch({ type: FETCH_AREAS })
    return () => {
      dispatch({ type: RESET_AREAS })
    }
  }, [dispatch])

  const _renderTree = () => {
    if (loading) return <Spinner color="info" />
    if (areasTree && areasTree.length > 0) {
      return (
        <Tree
          treeData={areasTree}
          name="recipients_id"
          label="notices:select_areas"
          required
          idsOnly
          readonly={!editable}
        />
      )
    }
    return null
  }

  return (
    <Row wrap="wrap" align="baseline" mt="R">
      { _renderTree() }
    </Row>
  )
}

export default memo(AreasRecipients)

AreasRecipients.propTypes = {}
