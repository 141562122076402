export const FETCH_MEMBERS = 'fetch_members'
export const FETCH_MEMBERS_SUCCESS = 'fetch_members_success'
export const FETCH_MEMBERS_FAIL = 'fetch_members_fail'
export const RESET_MEMBERS = 'reset_members'

export const DELETE_MEMBER = 'delete_member'
export const DELETE_MEMBER_SUCCESS = 'delete_member_success'
export const DELETE_MEMBER_FAIL = 'delete_member_fail'

export const FETCH_MEMBER = 'fetch_member'
export const FETCH_MEMBER_SUCCESS = 'fetch_member_success'
export const FETCH_MEMBER_FAIL = 'fetch_member_fail'
export const RESET_MEMBER = 'reset_member'

export const POST_MEMBER = 'post_member'
export const POST_MEMBER_SUCCESS = 'post_member_success'
export const POST_MEMBER_FAIL = 'post_member_fail'

export const SEND_MEMBER_EMAIL = 'send_member_email'
export const SEND_MEMBER_EMAIL_SUCCESS = 'send_member_email_success'
export const SEND_MEMBER_EMAIL_FAIL = 'send_member_email_fail'
