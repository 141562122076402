import React from 'react'
import PropTypes from 'prop-types'
import { InspectorItemStyle } from '../PropertyForm.style'

function SelectedInspectorItem ({ inspector, removeInspector }) {
  const _handleClick = () => {
    const { app_user_id, app_user_name } = inspector
    removeInspector(app_user_id, app_user_name)
  }
  return (
    <InspectorItemStyle
      handleClick={_handleClick}
      key={inspector?.app_user_id}
      label={inspector?.app_user_name || ''}
    />
  )
}

SelectedInspectorItem.propTypes = {
  inspector: PropTypes.shape({
    app_user_id: PropTypes.number.isRequired,
    app_user_name: PropTypes.string.isRequired
  }).isRequired,
  removeInspector: PropTypes.func.isRequired
}

export default SelectedInspectorItem
