import React, { memo, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  selectQueryParams,
  Row,
  PrimaryButton,
  updateQueryParameters,
  TextInputStandAlone
} from '@gk-devteam/apmc-core-web'

const ContractSearch = ({ roomID }) => {
  const queryParams = useSelector(selectQueryParams)
  const dispatch = useDispatch()
  const [residentName, setResidentName] = useState('')
  const [contractorName, setContractorName] = useState('')

  useEffect(() => {
    if (queryParams) {
      setResidentName(queryParams.resident_name || '')
      setContractorName(queryParams.contractor_name || '')
    }
  }, [queryParams, dispatch])

  const _handleSearch = useCallback(
    () => {
      dispatch(updateQueryParameters({
        ...queryParams,
        resident_name: residentName,
        contractor_name: contractorName
      }))
    },
    [dispatch, queryParams, residentName, contractorName]
  )

  const updateResidentName = (e) => {
    setResidentName(e.target.value)
  }
  const updateContractorName = (e) => {
    setContractorName(e.target.value)
  }

  const _handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        _handleSearch()
      }
    },
    [_handleSearch]
  )

  return (
    <>
      <Row>
        <TextInputStandAlone
          flex={1}
          size="M"
          value={residentName}
          handleChange={updateResidentName}
          onKeyPress={_handleKeyPress}
          type="text"
          name="resident_name"
          label='contracts:resident_name'
          placeholder=""
        />
        <TextInputStandAlone
          flex={1}
          size="M"
          value={contractorName}
          handleChange={updateContractorName}
          onKeyPress={_handleKeyPress}
          type="text"
          name="contractor_name"
          label='contracts:contractor_name'
          placeholder=""
        />
        {/* <MediumTextInputFlexStyle
          flex={1}
          type="text"
          name="contract_manage_id"
          label='contracts:contract_manage_id'
          placeholder=""
        /> */}
      </Row>
      <Row mb="L">
        <PrimaryButton
          type="button"
          label="search"
          handleClick={_handleSearch}
        />
      </Row>
    </>
  )
}

export default memo(ContractSearch)

ContractSearch.propTypes = {
  roomID: PropTypes.string.isRequired
}
