export const FETCH_CONTACTS = 'fetch_contacts'
export const FETCH_CONTACTS_SUCCESS = 'fetch_contacts_success'
export const FETCH_CONTACTS_FAIL = 'fetch_contacts_fail'

export const POST_CONTACT = 'post_contact'
export const POST_CONTACT_SUCCESS = 'post_contact_success'
export const POST_CONTACT_FAIL = 'post_contact_fail'

export const DELETE_CONTACT = 'delete_contact'
export const DELETE_CONTACT_SUCCESS = 'delete_contact_success'
export const DELETE_CONTACT_FAIL = 'delete_contact_fail'

export const TOGGLE_CONTACT = 'toggle_contact'
export const TOGGLE_CONTACT_SUCCESS = 'toggle_contact_success'
export const TOGGLE_CONTACT_FAIL = 'toggle_contact_fail'

export const BULK_TOGGLE_CONTACTS = 'bulk_toggle_contacts'
export const BULK_TOGGLE_CONTACTS_SUCCESS = 'bulk_toggle_contacts_success'
export const BULK_TOGGLE_CONTACTS_FAIL = 'bulk_toggle_contacts_fail'

export const RESET_CONTACTS = 'reset_contacts'
