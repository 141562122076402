import {
  styled,
  getColor,
  SPACING
} from '@gk-devteam/apmc-core-web'
import { transparentize } from 'polished'

export const SearchAddressButtonStyle = styled.div`
  margin-left: ${SPACING.SMALL}rem;
  margin-right: ${SPACING.LARGE}rem;
  padding-top: ${SPACING.LARGE}rem;
  white-space: nowrap;
  display: flex;
  gap: ${SPACING.SMALL}rem;
`
export const AddressesListStyle = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: ${SPACING.SMALLEST}rem;
    cursor: pointer;

    &:hover {
      background-color: ${props => transparentize(0.9, getColor(props, 'accent'))}
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid ${props => getColor(props, 'cardBorder')};
    }
  }
`
