/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CancelToken } from 'axios'
import { notification, message } from 'antd'
import { NotificationOutlined } from '@ant-design/icons'
import {
  useTranslation,
  SPACING,
  InfoButton,
  DangerButton
} from '@gk-devteam/apmc-core-web'

import {
  DELETE_FCM_TOKEN,
  POST_FCM_TOKEN
} from '../types'
import {
  selectTheme, selectUser
} from '../selectors'
import { initializeFCM } from '../services/firebaseService'
import { fetchFCMStatus } from '../services/settings/userPreferencesService'

const key = 'push_modal'

export const useNotification = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const theme = useSelector(selectTheme)
  const [authorized, setAuthorized] = useState()

  const _fetchStatus = useCallback(
    async () => {
      const cancelSource = CancelToken.source()
      try {
        const res = await fetchFCMStatus(cancelSource)
        if (res && res.data) setAuthorized(res.data.authorized)
      } catch (error) {
        console.warn('error fetching fcm status', error)
      }
    },
    []
  )

  useEffect(() => {
    if (user) {
      _fetchStatus()
    }
  }, [_fetchStatus, user])

  const setupFCM = useCallback(
    async () => {
      const registerToken = (token) => {
        console.info('Init FCM')
        dispatch({ type: POST_FCM_TOKEN, data: { token } })
      }
      if (Notification.permission === 'granted') {
        initializeFCM(registerToken)
      } else if (Notification.permission !== 'denied') {
        try {
          const permission = await Notification.requestPermission()
          if (permission === 'granted') {
            initializeFCM(registerToken)
          }
          if (permission === 'denied') {
            message.warning(t('webpush.notification_permission_denied'), 2)
          }
        } catch (error) {
          console.warn('An error occurred while retrieving Notification permission', error)
        }
      } else {
        message.warning(t('webpush.notification_permission_denied'), 2)
      }
    },
    [dispatch, t]
  )

  const _handleAcceptClick = useCallback(
    async () => {
      notification.close(key)
      setupFCM()
    },
    [setupFCM]
  )
  const _declineSuccess = useCallback(
    () => {
      message.info(t('webpush.notification_decline_success'))
      notification.close(key)
    },
    [t]
  )
  const _declineFail = useCallback(
    () => {
      message.error(t('webpush.notification_decline_error'))
      notification.close(key)
    },
    [t]
  )
  const _handleDeclineClick = useCallback(
    () => {
      console.log('decline')
      dispatch({ type: DELETE_FCM_TOKEN, onSuccess: _declineSuccess, onFail: _declineFail })
    },
    [dispatch, _declineSuccess, _declineFail]
  )
  const close = () => {
    console.log(
      'Notification was closed. Either the close button was clicked or duration time elapsed.'
    )
  }
  const _renderBtns = useCallback(
    () => {
      return (
        <>
          <DangerButton
            label="webpush.notification_decline"
            handleClick={_handleDeclineClick}
            style={{ marginRight: SPACING.REGULAR * 16 }}
          />
          <InfoButton
            label="webpush.notification_accept"
            handleClick={_handleAcceptClick}
            reverse
          />
        </>
      )
    },
    [_handleDeclineClick, _handleAcceptClick]
  )

  useEffect(() => {
    if (user && 'Notification' in window) {
      if (authorized === true && Notification.permission === 'granted') {
        setupFCM()
      }

      if (authorized === null) {
        console.log('authorized', authorized)
        notification.open({
          key,
          duration: null,
          message: t('webpush.notification_title'),
          description: t('webpush.notification_text'),
          icon: <NotificationOutlined style={{ color: theme && theme.colors.accent }}/>,
          btn: _renderBtns(),
          onClose: close
        })
      }
    }
  }, [t, _renderBtns, authorized, theme, setupFCM, user])

  return { register: setupFCM }
}
