import { createSelector } from 'reselect'

const selectDatasync = state => state.datasync
const selectForm = state => state.form

export const selectDatasyncPosting = createSelector(
  selectDatasync,
  datasync => datasync.posting
)
export const selectDatasyncError = createSelector(
  selectDatasync,
  datasync => datasync.error
)
export const selectDatasyncFormPropertiesFiles = createSelector(
  selectForm,
  form => form?.properties_file || null
)
export const selectDatasyncFormContractsFiles = createSelector(
  selectForm,
  form => form?.contracts_file || null
)
