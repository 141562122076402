import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Form,
  Card,
  Row,
  TextArea,
  Title,
  FormSubmitButton,
  InquiryID,
  RoleControlled,
  TextInput,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  selectContractLoading,
  selectContractPosting
} from '../../../selectors'
import { yasueContractUpdateValidation } from '../../../validation'
import { INQUIRY_PREFIX } from '../../../constants'
import { POST_CONTRACT } from '../../../types'

import { YasueContractorCard } from './subForms/AppUserInfo/YasueContractorCard'

const YasueContractForm = ({ data, contractID }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const elemRef = useRef()
  const pages = useSelector(selectPages)
  const dispatch = useDispatch()

  // focus top of the page
  useEffect(() => {
    if (elemRef.current) {
      elemRef.current.scrollIntoView()
    }
  }, [elemRef])

  const handleFormSubmit = (data) => {
    const successRedirect = (id) => {
      navigate('/contracts')
    }
    dispatch({ type: POST_CONTRACT, data, successRedirect, yasue: true })
  }

  return (
    <Form
      id="updateAppUserForm"
      handleSubmit={handleFormSubmit}
      validationSchema={yasueContractUpdateValidation}
      loadingSelector={selectContractLoading}
      postingSelector={selectContractPosting}
      initialData={data}
      // ignoreDirty
      // debug
    >
      <span id="focus_ref" ref={elemRef}></span>
      <Row justify="between">
        <Row align="baseline" wrap={'wrap'}>
          {
            contractID
              ? (
                <Title
                  text={t('contracts:contract_details', { residentName: data?.contractor?.last_name })}
                />
              )
              : <Title label='contracts:add_contract' />
          }
        </Row>
        <RoleControlled authorized="contracts.functions.update">
          <FormSubmitButton editMode={!!contractID} postingSelector={selectContractPosting}/>
        </RoleControlled>
      </Row>
      <Row mb="ML" wrap={'wrap'}>
        <InquiryID id={contractID} prefix={INQUIRY_PREFIX.CONTRACT} />
      </Row>

      <Card mb="L" title="contracts:contract_info">
        <Row wrap="wrap" align="baseline" justify="start">
          <TextInput
            flex={1}
            size="M"
            type="text"
            name="contract_manage_id"
            label='yasue:contracts.contract_manage_id'
            readonly={pages?.custom?.datasync}
            placeholder=""
            required
          />
        </Row>
      </Card>

      <YasueContractorCard />

      <Card title='memo'>
        <TextArea
          name="memo"
          placeholder="placeholders.memo"
        />
      </Card>

    </Form>
  )
}
export default YasueContractForm

YasueContractForm.propTypes = {
  data: PropTypes.object,
  contractID: PropTypes.string
}
YasueContractForm.defaultProps = {

}
