import React, { memo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import {
  dynamicLink,
  Row,
  ToastTitle,
  Text,
  Badge
} from '@gk-devteam/apmc-core-web'

import {
  selectChecklistLoadingSubscription,
  selectWaitingChecklistsCount,
  selectChecklistLoading,
  selectChecklistsData,
  selectChecklistsStatuses,
  selectChecklistHiddenBy,
  selectChecklistHiddenById
} from '../../../selectors'
import {
  FETCH_CHECKLIST_HOME,
  RESET_CHECKLIST
} from '../../../types'

import AdminTaskCard from './AdminTaskCard'

const ChecklistCard = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectChecklistLoadingSubscription)
  const count = useSelector(selectWaitingChecklistsCount)
  const statuses = useSelector(selectChecklistsStatuses)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch({
      type: FETCH_CHECKLIST_HOME,
      params: {
        limit: 10000,
        page: 1,
        sort_by: 'status',
        sort_order: 'asc'
      }
    })
    return () => {
      dispatch({ type: RESET_CHECKLIST })
    }
  }, [dispatch, statuses])

  const navigateToChecklistRoom = (id, row, e) => {
    const url = `/admin_tasks/checklist/?contract_id=${id}&status=4`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  const roomCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return (
        <Badge count={data.unresolved_count} offset={[10, 0]}>
          <Row>
            <ToastTitle text={data.property_name} />　<Text text={data.room_number} />
          </Row>
        </Badge>
      )
    },
    []
  )

  const columns = [
    {
      title: '',
      dataIndex: 'propertyName',
      key: 'propertyName',
      render: roomCell
    }
  ]

  if (loading) return null

  return (
    <AdminTaskCard
      title="admin_tasks:checklist"
      linkTo="./checklist"
      linkLabel="admin_tasks:to_checklist"
      count={count}
      columns={columns}
      loadingSelector={selectChecklistLoading}
      dataSelector={selectChecklistsData}
      hiddenSelector={selectChecklistHiddenBy}
      hiddenByIdSelector={selectChecklistHiddenById}
      handleRowClick={navigateToChecklistRoom}
      rowKey="contract_id"
    />
  )
}

export default memo(ChecklistCard)

ChecklistCard.propTypes = {}
