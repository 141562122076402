import { createSelector } from 'reselect'

const selectYasueSurveys = state => state.yasueSurveys

export const selectLoadingYasueSurveys = createSelector(
  selectYasueSurveys,
  surveys => surveys.loading
)

export const selectYasueSurveysCount = createSelector(
  selectYasueSurveys,
  surveys => surveys.results_count
)
export const selectYasueSurveysData = createSelector(
  selectYasueSurveys,
  surveys => surveys.results
)
