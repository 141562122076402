import React, { memo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  TextInput
} from '@gk-devteam/apmc-core-web'

import { i18n } from '../../../locales'
import { MAX_ROOMS } from '../../../constants'
import { selectPropertyID } from '../../../selectors'

const message = i18n.t('properties:max_room_limit_exceeded')

const PropertyRoomCount = () => {
  const roomCount = useSelector(state => state.form.room_count)
  const propertyID = useSelector(selectPropertyID)
  const [error, setError] = useState(null)
  useEffect(() => {
    if (roomCount > MAX_ROOMS) {
      setError({
        type: 'LIMIT_REACHED',
        message
      })
    } else {
      setError(null)
    }
  }, [roomCount])
  return (
    <TextInput
      type="number"
      name="room_count"
      label='properties:room_count'
      placeholder=""
      customError={error}
      min={0}
      maxNumber={MAX_ROOMS}
      readonly={!!propertyID}
      required
      help="properties:help.room_count"
    />
  )
}

export default memo(PropertyRoomCount)

PropertyRoomCount.propTypes = {}
