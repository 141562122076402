import { styled } from "@gk-devteam/apmc-core-web"

export const PreviewStyle = styled.div`
  .react-pdf__Page__canvas, .react-pdf__Page__textContent {
    margin: auto;
    max-width: 100%;
  }
`
// export const TableFooterStyle = styled(Row)`
//     border-left: 1px solid ${props => getColor(props, 'cardBorder')};
//     border-bottom: 1px solid ${props => getColor(props, 'cardBorder')};
//     border-right: 1px solid ${props => getColor(props, 'cardBorder')};
// `
