import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row,
  PrimaryButton,
  Title,
  INIT_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectFaqLoading,
  selectFaqCategoriesOptionsWithReset,
  selectFaqHiddendBy,
  selectFaqHiddendById
} from '../../../../selectors'
import { FETCH_FAQ, RESET_FAQ } from '../../../../types'
import { IMPORT_TYPES } from '../../../../constants'
import initialData from '../common/initialData'

import AdminFaqList from './AdminFaqList'
import FaqModal from '../common/FaqModal'
import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import { FilterStyle } from '../faq.style'

const AdminFaqPage = ({ navigate }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectFaqLoading)
  const categoriesOptions = useSelector(selectFaqCategoriesOptionsWithReset)
  const [modalVisible, setModalVisible] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [categoryFilter, setCategoryFilter] = useState('')

  const breadcrumbs = [
    {
      label: 'navigation:settings',
      link: '/settings'
    },
    {
      label: 'navigation:settings_faq',
      link: '/settings/faq'
    }
  ]

  useEffect(() => {
    dispatch({ type: FETCH_FAQ, config: { } })
    return () => {
      dispatch({ type: RESET_FAQ })
    }
  }, [dispatch])

  const _handleFilterChange = (value) => {
    setCategoryFilter(value)
  }

  const _openAddModal = () => {
    dispatch({ type: INIT_FORM, payload: initialData })
    setModalVisible(true)
  }
  const _openCopyModal = () => {
    setModalVisible(true)
  }
  const _openEditModal = () => {
    setModalVisible(true)
    setEditMode(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
    setEditMode(false)
  }

  const navigateToCSV = () => {
    navigate(`/import?type=${IMPORT_TYPES.FAQ}`)
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectFaqHiddendBy}
      hiddenByIdSelector={selectFaqHiddendById}
      noHeader
      authorized="faq.functions.add"
      info="services:level_message.admin"
      infoTitle="services:level_message.adminTitle"
    >
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:properties_faq"/>
        <div>
          <PrimaryButton
            label="add_csv"
            handleClick={navigateToCSV}
            withMargin
          />
          <PrimaryButton
            label="add"
            handleClick={_openAddModal}
            reverse
            withMargin
          />
        </div>
      </Row>
      <FaqModal
        visible={modalVisible}
        editMode={editMode}
        onCancel={_closeModal}
      />
      <Row mt="L">
        <FilterStyle
          mb="0"
          noLabel
          handleChange={_handleFilterChange}
          value={categoryFilter}
          name="category_filter"
          options={categoriesOptions}
          loading={loading}
        />
      </Row>
      <AdminFaqList
        filter={categoryFilter}
        editModal={_openEditModal}
        copyModal={_openCopyModal}
      />
    </PanelLayout>
  )
}
export default AdminFaqPage

AdminFaqPage.propTypes = {
  navigate: PropTypes.func.isRequired
}
AdminFaqPage.defaultProps = {}
