import {
  styled,
  getColor,
  SPACING,
  Text,
  Row
} from '@gk-devteam/apmc-core-web'

export const ImportTypeLabelStyle = styled(Text)`
  font-weight: bold;
`

export const HelpIconContainerStyle = styled.div`
  margin-left: ${SPACING.SMALL}rem;
  color: ${props => getColor(props, 'accent')};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
`

export const GenreListContainerStyle = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

export const GenreListHeaderStyle = styled(Row)`
  font-weight: bold;
  padding: ${SPACING.REGULAR}rem ${SPACING.SMALLEST}rem;
  border-bottom: 1px solid ${props => getColor(props, 'cardBorder')};
  span:first-child {
    width: 120px;
  }
  span:last-child {
    flex: 1;
  }
`

export const GenreListItemStyle = styled.li`
  display: flex;
  padding: ${SPACING.SMALLEST}rem;
  border: solid 1px ${props => getColor(props, 'cardBorder')};

  span:first-child {
    width: 120px;
  }
  span:last-child {
    flex: 1;
  }
`

export const ExampleTitleStyle = styled(Text)`
  font-weight: bold;
`
export const ExampleRowStyle = styled(Row)`
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  span:first-child{
    width: 160px;
  }
`
