import React, { memo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Empty } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import {
  useTranslation,
  Document,
  Page,
  getFileExtension,
  Image
} from '@gk-devteam/apmc-core-web'

import { selectCurrentCalendar } from '../../../../selectors'
import { PDF_CMAP_URL } from '../../../../constants'

import {
  EmptyStyle,
  CalendarContainerStyle,
  LinkContainerStyle,
  ImageContainerStyle
} from './PropertyRefusePage.style'

const CurrentCalendar = (props) => {
  const currentCalendar = useSelector(selectCurrentCalendar)
  const [width, setWidth] = useState(0)
  const { t } = useTranslation()

  const _handleWidth = () => {
    const main = document.getElementById('main')
    if (main) {
      const { width } = main.getBoundingClientRect()
      setWidth((width * 75) / 100)
    }
  }

  useEffect(() => {
    _handleWidth()
    window.addEventListener('resize', _handleWidth)
    return () => {
      window.removeEventListener('resize', _handleWidth)
    }
  }, [])

  if (!currentCalendar) {
    return (
      <EmptyStyle
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t('refuse:no_calendar')}
      />
    )
  }

  const _renderPDFPreview = () => {
    return (
      <Document
        file={currentCalendar}
        loading={t('pdf_load')}
        error={t('pdf_load_fail')}
        // onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        options={{
          cMapUrl: PDF_CMAP_URL,
          cMapPacked: true
        }}
      >
        <Page
          pageNumber={1}
          width={width}
          renderAnnotationLayer={false}
        />
      </Document>
    )
  }
  const _renderImagePreview = () => {
    return (
      <ImageContainerStyle>
        <Image src={currentCalendar} cover full previewable/>
      </ImageContainerStyle>
    )
  }

  const _renderPreview = () => {
    const extension = getFileExtension(currentCalendar)
    switch (extension) {
      case 'pdf':
        return _renderPDFPreview()
      case 'jpeg':
      case 'jpg':
      case 'png':
        return _renderImagePreview()
      default:
        return null
    }
  }

  return (
    <CalendarContainerStyle>
      <LinkContainerStyle align="center">
        <a href={currentCalendar} target="_blank" rel="noopener noreferrer">
          {t('refuse:calendar_link')}
          <LinkOutlined />
        </a>
      </LinkContainerStyle>
      { _renderPreview() }
    </CalendarContainerStyle>
  )
}

export default memo(CurrentCalendar)

CurrentCalendar.propTypes = {}
