import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  Row,
  Text,
  RepeaterAddButton,
  RepeaterFields
} from '@gk-devteam/apmc-core-web'

import { selectNoticeEditable, selectNoticeConditions } from '../../../../selectors'

import NoticeConditionsRow from './NoticeConditionsRow'

const conditionRowFields = ['min_income', 'max_income', 'sex', 'min_age', 'max_age']

const NoticeConditions = () => {
  const recipientType = useSelector(state => state.form.recipient_type)
  const editable = useSelector(selectNoticeEditable)
  const conditions = useSelector(selectNoticeConditions)

  const _renderFields = useCallback(
    (index) => <NoticeConditionsRow key={index} index={index}/>,
    []
  )

  if (recipientType && recipientType === 2) return null

  return (
    <>
      <Text label="notices:notice_conditions" />
      <Row wrap="wrap" align="baseline" mt="XS">
        { !editable && (!conditions || conditions.length === 0) ? <Text label="none" /> : null}
        <RepeaterFields
          propertyKey="recipients_condition"
          uniqField="min_income"
          field={_renderFields}
          readOnly={!editable}
        />
      </Row>
      {
        editable
          ? (
            <RepeaterAddButton
              label="notices:add_condition"
              propertyKey="recipients_condition"
              uniqField="min_income"
              fields={conditionRowFields}
            />
          )
          : null
      }
    </>
  )
}

export default NoticeConditions

// NoticeUserType.propTypes = {}
