import { createReducer } from '@reduxjs/toolkit'
import {
  POST_COUPON,
  POST_COUPON_SUCCESS,
  POST_COUPON_FAIL,
  FETCH_COUPON_FAIL,
  FETCH_COUPON_SUCCESS,
  FETCH_COUPON
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  stopping: null,
  data: null,
  editable: true,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_COUPON]: (state, action) => {
    state.posting = true
  },
  [POST_COUPON_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_COUPON_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_COUPON]: (state, action) => {
    state.loading = true
    state.stopping = false
    state.editable = true
  },
  [FETCH_COUPON_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_COUPON_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  }
})
