export const FETCH_PROPERTIES = 'fetch_properties'
export const FETCH_PROPERTIES_SUCCESS = 'fetch_properties_success'
export const FETCH_PROPERTIES_FAIL = 'fetch_properties_fail'
export const RESET_PROPERTIES = 'reset_properties'

export const DELETE_PROPERTY = 'delete_property'
export const DELETE_PROPERTY_SUCCESS = 'delete_property_success'
export const DELETE_PROPERTY_FAIL = 'delete_property_fail'

export const FETCH_PROPERTY = 'fetch_property'
export const FETCH_PROPERTY_SUCCESS = 'fetch_property_success'
export const FETCH_PROPERTY_FAIL = 'fetch_property_fail'
export const RESET_PROPERTY = 'reset_property'

export const POST_PROPERTY = 'post_property'
export const POST_PROPERTY_SUCCESS = 'post_property_success'
export const POST_PROPERTY_FAIL = 'post_property_fail'
