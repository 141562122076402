import React from 'react'
import {
  PageLayout
} from '@gk-devteam/apmc-core-web'

import DatasyncForm from '../../forms/DatasyncForm'

const breadcrumbs = [
  {
    label: 'navigation:datasync',
    link: '/datasync'
  }
]

function DatasyncPage (props) {
  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <DatasyncForm />
    </PageLayout>
  )
}

DatasyncPage.propTypes = {

}

export default DatasyncPage
