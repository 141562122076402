import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const propertiesGroupUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    name: yup.string().max(255).nullable().required().label(i18n.t('user_management:properties_group.name')),
    manage_id: yup.string().max(255).nullable().label(i18n.t('user_management:properties_group.manage_id')),
    description: yup.string().nullable().label(i18n.t('user_management:properties_group.description')),
    properties: yup
      .array()
      .nullable()
      .of(
        yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .required()
      )
      .label(i18n.t('common:property'))
  })
}
