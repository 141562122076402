import {
  styled,
  getColor,
  SPACING,
  CardTitle,
  SelectStandAlone
} from '@gk-devteam/apmc-core-web'

export const FilterStyle = styled(SelectStandAlone)`
  width: 200px;
  color: ${props => getColor(props, 'lightText')};
`

export const QuestionStyle = styled(CardTitle)`
  margin-left: ${SPACING.REGULAR}rem;
`

export const AnswerStyle = styled.p`
  display: inline-flex;
  margin: 0 ${SPACING.SMALL}rem;
  flex: 1;
  white-space: pre-wrap;
`
