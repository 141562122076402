import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { Tooltip } from 'antd'
import {
  MainLayout,
  RadioStandAlone,
  RESET_PARAMS,
  selectQueryParams,
  SPACING,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import { useLocation } from '@reach/router'
import PropTypes from 'prop-types'

import { FETCH_SERVICE_BY_LEVEL, POST_SERVICE_DISPLAY, RESET_SERVICE_BY_LEVEL } from '../../../types'
import { selectManageCompanyName, selectServiceData } from '../../../selectors'
import {
  LAST_LEVEL_SERVICES_DISPLAY_OPTIONS,
  SERVICES_DISPLAY,
  SERVICES_DISPLAY_OPTIONS,
  SERVICES_LEVEL,
  SERVICES_PLAN
} from '../../../constants'

import ServicesLegend from '../../../components/Display/ServicesLegend'
import ServiceByLevelList from './ServiceByLevelList'
import ServiceHeader from './ServiceHeader'

import { DisplayRadioContainerStyle } from './common.style'
import { ServiceDisplayStyle } from '../ServicesPage/services.style'

// const getTitle = (level) => {
//   switch (level) {
//     case SERVICES_LEVEL.PROPERTY:
//       return 'navigation:service_properties'
//     case SERVICES_LEVEL.ROOM:
//       return 'navigation:service_rooms'
//     case SERVICES_LEVEL.CONTRACT:
//       return 'navigation:service_contracts'
//     default:
//       break
//   }
// }

function ServiceByLevel ({ id, breadcrumbs, columns, validationSchema, level }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const prevQuery = useRef(null)
  const query = useSelector(selectQueryParams)
  const service = useSelector(selectServiceData)
  const manageCompanyName = useSelector(selectManageCompanyName)
  const searchQuery = useSelector(selectQueryParams)

  const getLevel = (level) => {
    switch (level) {
      case SERVICES_LEVEL.PROPERTY:
        return t('navigation:service_properties')
      case SERVICES_LEVEL.ROOM:
        return t('navigation:service_rooms')
      case SERVICES_LEVEL.CONTRACT:
        return t('navigation:service_contracts')
      default:
        break
    }
  }

  const serviceDisplay = useCallback(
    (value, cellKey, rowID, className, data) => {
      const {
        plan,
        last_level,
        parent_level,
        parent_display,
        display,
        property_id,
        room_id,
        contract_id
      } = data
      let currentLevel
      switch (parent_level) {
        case SERVICES_LEVEL.MANAGE:
          currentLevel = manageCompanyName
          break
        case SERVICES_LEVEL.ADMIN:
          currentLevel = t('services:service_levels.admin')
          break
        case SERVICES_LEVEL.PROPERTY:
          currentLevel = t('services:service_levels.property')
          break
        case SERVICES_LEVEL.ROOM:
          currentLevel = t('services:service_levels.room')
          break
        default:
          break
      }
      const displayOptions = last_level ? LAST_LEVEL_SERVICES_DISPLAY_OPTIONS : SERVICES_DISPLAY_OPTIONS
      const currentDisplay = parent_display === SERVICES_DISPLAY.CUSTOM_DISPLAY ? display : parent_display

      if (plan === SERVICES_PLAN.MANAGED || parent_display !== SERVICES_DISPLAY.CUSTOM_DISPLAY) {
        let tooltip = ''
        if (plan === SERVICES_PLAN.MANAGED) {
          tooltip = t('services:managed_service_help', { manageCompanyName })
        } else {
          tooltip = t('services:service_levels.description', { level: currentLevel })
        }
        const label = displayOptions.find(option => option.value === currentDisplay)?.label
        return (
          <DisplayRadioContainerStyle>
            <Tooltip
              title={tooltip}
              overlayStyle={{
                fontSize: `${SPACING.SMALL}rem`
              }}
              placement="left"
            >
              <ServiceDisplayStyle>{t(label)}</ServiceDisplayStyle>
            </Tooltip>
          </DisplayRadioContainerStyle>
        )
      }

      const _handleChange = (value) => {
        if (
          plan !== SERVICES_PLAN.MANAGED &&
          parent_display === SERVICES_DISPLAY.CUSTOM_DISPLAY
        ) {
          console.log(`updating service ${service.id} to ${value}`)
          let query
          switch (level) {
            case SERVICES_LEVEL.ADMIN:
              break
            case SERVICES_LEVEL.PROPERTY:
              query = { property_id }
              break
            case SERVICES_LEVEL.ROOM:
              query = { room_id }
              break
            case SERVICES_LEVEL.CONTRACT:
              query = { contract_id }
              break
            default:
              break
          }
          const data = {
            ...query,
            service_id: service.id,
            display: value
          }
          const onSuccess = () => {
            dispatch({ type: FETCH_SERVICE_BY_LEVEL, query: searchQuery, level, id: service.id })
          }
          dispatch({ type: POST_SERVICE_DISPLAY, data, onSuccess })
        }
      }

      return (
        <DisplayRadioContainerStyle>
          <RadioStandAlone
            name="display"
            handleChange={_handleChange}
            value={currentDisplay}
            options={displayOptions}
            noLabel
            size="fluid"
            autoH
            mr="0"
            mb="0"
          />
        </DisplayRadioContainerStyle>
      )
    },
    [manageCompanyName, t, service, level, searchQuery, dispatch]
  )

  const displayColumn = [
    {
      title: '',
      dataIndex: 'display',
      key: 'display',
      render: serviceDisplay
    }
  ]

  const fetchData = useCallback(
    () => {
      const schema = validationSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_SERVICE_BY_LEVEL, query, level, id })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_SERVICE_BY_LEVEL, level, id })
          }
        })
    },
    [dispatch, query, id, level, validationSchema]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_SERVICE_BY_LEVEL })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
    >
      {/* <Row mb="R">
        <Title label={getTitle(level)} />
      </Row> */}
      <ServiceHeader level={getLevel(level)}/>
      <ServiceByLevelList
        columns={[...columns, ...displayColumn]}
      />
      <ServicesLegend />
    </MainLayout>
  )
}

ServiceByLevel.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  })).isRequired,
  columns: PropTypes.array.isRequired,
  validationSchema: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  level: PropTypes.oneOf(Object.values(SERVICES_LEVEL)).isRequired
}

export default ServiceByLevel
