import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_SUPPORT_CONTRACT,
  FETCH_SUPPORT_CONTRACT_SUCCESS,
  FETCH_SUPPORT_CONTRACT_FAIL,
  RESET_SUPPORT_CONTRACT
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  error: null,
  data: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_SUPPORT_CONTRACT]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_SUPPORT_CONTRACT_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_SUPPORT_CONTRACT_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_SUPPORT_CONTRACT]: () => INITIAL_STATE
})
