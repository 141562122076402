import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFileFromURL,
  InfoButton,
  PageLayout,
  RoleControlled,
  Row,
  Steps,
  Title
} from '@gk-devteam/apmc-core-web'
import { LoadingOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import { selectDatasyncPosting } from '../../../selectors'
import { POST_DATASYNC } from '../../../types'

import ConfirmPage from './ConfirmPage'
import DataUploading from './DataUploading'
import DataUploaded from './DataUploaded'

const breadcrumbs = [
  {
    label: 'navigation:datasync',
    link: '/datasync'
  },
  {
    label: 'datasync:confirm',
    link: '/datasync'
  }
]

function DatasyncConfirmPage ({ location, navigate }) {
  const dispatch = useDispatch()
  const posting = useSelector(selectDatasyncPosting)
  const [steps, setSteps] = useState(null)
  const [step, setStep] = useState(0)
  const [status, setStatus] = useState('process')

  const properties_file = location?.state?.properties_file
  const contracts_file = location?.state?.contracts_file

  const singleFile = (properties_file && !contracts_file) || (!properties_file && contracts_file)

  const areFilesReady = useCallback(
    async () => {
      let filesReady = true

      if (properties_file && filesReady) {
        try {
          await getFileFromURL(properties_file)
        } catch (error) {
          console.warn('properties_file error', error)
          filesReady = false
        }
      }

      if (contracts_file && filesReady) {
        try {
          await getFileFromURL(contracts_file)
        } catch (error) {
          console.warn('contracts_file error', error)
          filesReady = false
        }
      }

      // console.log('filesReady', filesReady)
      return filesReady
    },
    [contracts_file, properties_file]
  )

  useEffect(() => {
    areFilesReady()
      .then(filesReady => {
        if ((!properties_file && !contracts_file) || !filesReady) navigate('/datasync')
      })
  }, [contracts_file, properties_file, navigate, areFilesReady])

  useEffect(() => {
    const showIcon = singleFile ? step === 1 : step === 2
    const stepItems = [
      {
        title: properties_file ? 'datasync:properties_confirm' : 'datasync:contracts_confirm',
        disabled: singleFile || step > 1
      },
      {
        title: 'datasync:files_upload',
        icon: posting && showIcon ? <LoadingOutlined /> : null,
        disabled: true
      },
      {
        title: 'datasync:files_uploaded',
        disabled: true
      }
    ]

    const contractStep = {
      title: 'datasync:contracts_confirm',
      disabled: true
    }

    if (!singleFile) {
      stepItems.splice(1, 0, contractStep)
    }

    setSteps(stepItems)
  }, [properties_file, contracts_file, step, posting, singleFile])

  const _handleUpload = () => {
    setStatus('wait')
    setStep(step + 1)

    const lastStep = steps.length - 1
    const onSuccess = () => {
      setStatus('finish')
      setStep(lastStep)
    }
    const onError = () => {
      setStatus('error')
      setStep(lastStep)
    }

    dispatch({ type: POST_DATASYNC, data: { properties_file, contracts_file }, onSuccess, onError })
  }

  const _handleStepClick = (current) => {
    // go back to properties check if looking at contracts
    if (current === 0 && step <= 1 && !singleFile) setStep(0)
  }

  const _handleBtnClick = () => {
    switch (step) {
      case 0:
        if (!singleFile) {
          setStep(step + 1)
        } else {
          _handleUpload()
        }
        break
      case 1:
        if (!singleFile) {
          _handleUpload()
        }
        break
      default:
        break
    }
  }

  function _renderBtn () {
    if (step > 1 || (singleFile && step > 0)) return null

    let label
    switch (step) {
      case 0:
        if (!singleFile) {
          label = 'datasync:next'
        } else {
          label = 'datasync:upload'
        }
        break
      case 1:
        if (!singleFile) {
          label = 'datasync:upload'
        }
        break
      default:
        break
    }
    return (
      <RoleControlled authorized="custom.manual_datasync">
        <InfoButton
          label={label}
          handleClick={_handleBtnClick}
        />
      </RoleControlled>
    )
  }

  function _renderSteps () {
    switch (step) {
      case 0:
        return properties_file ? <ConfirmPage file={properties_file} type="properties" /> : <ConfirmPage file={contracts_file} type="contracts" />
      case 1:
        if (!singleFile) return <ConfirmPage file={contracts_file} type="contracts" />
        return <DataUploading />
      case 2:
        if (!singleFile) return <DataUploading />
        return <DataUploaded />
      case 3:
        return <DataUploaded />
      default:
        return null
    }
  }

  if (!steps) return null

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <Row justify="between" mb="ML">
        <Title label="datasync:confirm" />
        {_renderBtn()}
      </Row>
      <Row>
        <Steps
          current={step}
          status={status}
          steps={steps}
          handleChange={_handleStepClick}
        />
      </Row>
      { _renderSteps() }
    </PageLayout>
  )
}

DatasyncConfirmPage.propTypes = {
  location: PropTypes.object,
  navigate: PropTypes.func
}

export default DatasyncConfirmPage
