import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  FilePicker,
  Row,
  TextInput,
  Checkbox
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyDocumentsLoading,
  selectPropertyDocumentsPosting
} from '../../selectors'
import { propertyDocumentUpdateValidation } from '../../validation'

const PropertyDocumentForm = ({ data, saveDocument }) => {
  return (
    <Form
      id="propertyDocumentForm"
      handleSubmit={saveDocument}
      validationSchema={propertyDocumentUpdateValidation}
      loadingSelector={selectPropertyDocumentsLoading}
      postingSelector={selectPropertyDocumentsPosting}
      initialData={data}
      // debug
    >

      <Row>
        <TextInput
          flex={1}
          type="text"
          name="file_name"
          label='title'
          placeholder=""
          required
        />
      </Row>
      <Row>
        <Checkbox
          name="chairman"
          inputLabel="properties:documents.roles"
          valueLabel="properties:documents.chairman"
        />
        <Checkbox
          name="inspector"
          valueLabel="properties:documents.inspector"
        />
        <Checkbox
          name="contractors"
          valueLabel="properties:documents.contractors"
        />
        <Checkbox
          name="residents"
          valueLabel="properties:documents.residents"
        />
      </Row>
      <Row>
        <FilePicker
          label='select_file'
          name="file"
          delete_name="delete_file"
          types={['pdf', 'image']}
        />
      </Row>
    </Form>
  )
}
export default PropertyDocumentForm

PropertyDocumentForm.propTypes = {
  data: PropTypes.object,
  saveDocument: PropTypes.func.isRequired
}
PropertyDocumentForm.defaultProps = {

}
