import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import PropTypes from 'prop-types'

import {
  selectPropertyContactsData,
  selectPropertyContactsAllDisplayed,
  selectContractID
} from '../../../../selectors'
import { TOGGLE_CONTACT } from '../../../../types'

import ContactsList from '../common/ContactsList'

const PropertyContactsList = () => {
  const dispatch = useDispatch()

  const _handleContactDisplay = useCallback(
    (display, id, item) => {
      if (id) {
        const data = {
          id: item.id,
          display
        }
        const config = {
          type: 'property_contacts',
          contract: id
        }
        dispatch({ type: TOGGLE_CONTACT, data, config })
      } else {
        console.warning('no contract id')
      }
    },
    [dispatch]
  )

  return (
    <ContactsList
      dataSelector={selectPropertyContactsData}
      displaySelector={selectPropertyContactsAllDisplayed}
      idSelector={selectContractID}
      title="contacts:property_contacts"
      type="property_contacts"
      level="contract"
      handleContactDisplay={_handleContactDisplay}
    />
  )
}

export default PropertyContactsList

PropertyContactsList.propTypes = {}
