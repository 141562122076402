import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  InquiryID,
  Form,
  Card,
  Row,
  Title,
  FilePicker,
  TextInput,
  TextArea,
  FormSubmitButton,
  RoleControlled,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  selectOwnerID,
  selectLoadingProperty,
  selectPostingProperty
} from '../../selectors'
import { propertyUpdateValidation } from '../../validation'
import { POST_PROPERTY } from '../../types'
import { INQUIRY_PREFIX } from '../../constants'

import PropertyRoomCount from './subForms/PropertyRoomCount'
import SearchAddressButton from '../../components/Misc/SearchAddressButton'
import PropertyRooms from './subForms/PropertyRooms'
import PropertyOwnerInfo from './subForms/PropertyOwnerInfo'
import ChoueiCenterName from './subForms/ChoueiCenterName'

import {
  FormInnerStyle,
  CardStyle
} from './PropertyForm.style.js'
import PropertySettings from './subForms/PropertySettings'

const PropertyForm = ({ data, propertyID }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const ownerID = useSelector(selectOwnerID)
  const pages = useSelector(selectPages)

  const room_count = pages?.custom?.house_maker
    ? 1
    : data ? data.rooms && data.rooms.length : 0

  const rooms = pages?.custom?.house_maker
    ? [{ room_number: ' ' }]
    : data?.rooms || null

  const initialValues = {
    ...data,
    file: (data && data.file) || null,
    delete_file: (data && data.delete_file) || false,
    room_count,
    rooms,
    meeting_users: data?.meeting_users && data?.meeting_users.length > 0 ? data.meeting_users : null,
    property_owner_enabled: (data && data.property_owner_enabled) || false,
    property_owner: data && data.property_owner
      ? {
        ...data.property_owner,
        property_owner_image: null,
        delete_property_owner_image: false
      }
      : {
        owner_display_phone: false,
        property_owner_image: null,
        delete_property_owner_image: false

      }
  }

  const handleFormSubmit = (data) => {
    data.owner_id = ownerID

    const successRedirect = (id) => {
      // if (propertyID) {
      //   dispatch({ type: FETCH_PROPERTY, id: propertyID })
      // } else {
      //   navigate(`/properties/edit/${id}`)
      // }
      navigate('/properties')
    }
    dispatch({ type: POST_PROPERTY, data, successRedirect })
  }

  return (
    <Form
      id="addPropertyForm"
      handleSubmit={handleFormSubmit}
      validationSchema={propertyUpdateValidation}
      loadingSelector={selectLoadingProperty}
      postingSelector={selectPostingProperty}
      initialData={initialValues}
      authorized="properties.functions.update"
      // debug
    >
      <Row justify="between" wrap="wrap">
        {
          propertyID
            ? <Title text={data.name} />
            : <Title label='properties:add_new_property' />
        }
        <RoleControlled authorized="properties.functions.update">
          <FormSubmitButton editMode={!!propertyID} postingSelector={selectPostingProperty}/>
        </RoleControlled>
      </Row>
      <Row mb="ML">
        <InquiryID id={propertyID} prefix={INQUIRY_PREFIX.PROPERTY} />
      </Row>
      <FormInnerStyle>
        <CardStyle mb="L">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              type="text"
              name="name"
              label='properties:property_name'
              placeholder=""
              required
            />
            <TextInput
              type="text"
              name="property_manage_id"
              label='properties:property_manage_id'
              placeholder=""
              readonly={pages?.custom?.datasync}
            />
            <RoleControlled authorized='!custom.house_maker'>
              <PropertyRoomCount />
            </RoleControlled>
          </Row>
          <ChoueiCenterName />
          <Row wrap="wrap" align="start" mb="R">
            <TextInput
              size="L"
              mr="0"
              type="text"
              name="postcode"
              label='properties:postcode'
              pattern="^\d{7}$"
              placeholder="placeholders.postcode"
              required
            />
            <RoleControlled authorized="properties.functions.update">
              <SearchAddressButton />
            </RoleControlled>
          </Row>
          <Row wrap="wrap" align="start">
            <TextInput
              type="text"
              name="prefecture"
              label='properties:prefecture'
              readonly
              required
            />
            <TextInput
              type="text"
              name="city"
              label='properties:city'
              readonly
              required
            />
          </Row>
          <Row wrap="wrap">
            <TextInput
              flex={1}
              type="text"
              name="address"
              label='address'
              placeholder=""
              required
            />
          </Row>
          <RoleControlled authorized='!custom.house_maker'>
            <Row wrap="wrap">
              <TextInput
                flex={1}
                type="text"
                name="bank_account"
                label='properties:bank_account'
                placeholder="placeholders.bank_account"
              />
            </Row>
          </RoleControlled>
        </CardStyle>
        <Card title='properties:property_image' mb="L">
          <FilePicker
            name="file"
            delete_name="delete_file"
            types={['image']}
          />
        </Card>
        <RoleControlled authorized='!custom.house_maker'>
          <PropertyRooms />
          <RoleControlled authorized="properties.functions.managerSettings">
            <PropertyOwnerInfo />
          </RoleControlled>
        </RoleControlled>
        <RoleControlled authorized='custom.yahagi'>
          <PropertySettings />
        </RoleControlled>
        <Card title='memo'>
          <TextArea
            name="memo"
            placeholder="placeholders.memo"
          />
        </Card>
      </FormInnerStyle>
    </Form>
  )
}
export default PropertyForm

PropertyForm.propTypes = {
  data: PropTypes.object,
  propertyID: PropTypes.string
}
PropertyForm.defaultProps = {

}
