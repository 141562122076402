
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { SERVICES_LEVEL } from '../../../../constants'
import {
  PanelInnerContainerStyle
} from './PanelDisabled.style.js'

import DisabledMessageCard from './DisabledMessageCard'

export const PanelDisabledContext = React.createContext()

const PanelDisabled = ({ children, hiddenSelector, hiddenByIdSelector, noLegend, propertyID }) => {
  const [link, setLink] = useState('')
  const [hidden, setHidden] = useState(false)
  const emptySelector = () => null
  const hiddenBy = useSelector(hiddenSelector || emptySelector)
  const hiddenById = useSelector(hiddenByIdSelector || emptySelector)

  // console.log('hiddenBy', hiddenBy)
  // console.log('hiddenById', hiddenById)

  useEffect(() => {
    if (hiddenBy) {
      setHidden(true)
      switch (hiddenBy) {
        case SERVICES_LEVEL.ADMIN:
          setLink('/settings/services?type=1')
          break
        case SERVICES_LEVEL.PROPERTY:
          setLink(`/properties/edit/${hiddenById}/services?type=1`)
          break
        case SERVICES_LEVEL.ROOM:
          setLink(`/properties/edit/${propertyID}/rooms/edit/${hiddenById}/services?type=1`)
          break
        case SERVICES_LEVEL.CONTRACT:
          setLink(`/contracts/edit/${hiddenById}/services?type=1`)
          break
        default:
          break
      }
    }
  }, [hiddenBy, hiddenById, propertyID])

  return (
    <PanelInnerContainerStyle noLegend={noLegend}>
      { hidden ? <DisabledMessageCard hiddenBy={hiddenBy} link={link} /> : null}
      <PanelDisabledContext.Provider value={hidden}>
        {children}
      </PanelDisabledContext.Provider>
    </PanelInnerContainerStyle>
  )
}
export default PanelDisabled

PanelDisabled.propTypes = {
  children: PropTypes.node.isRequired,
  hiddenSelector: PropTypes.func,
  hiddenByIdSelector: PropTypes.func,
  propertyID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  roomID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contractID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  noLegend: PropTypes.bool
}
PanelDisabled.defaultProps = {

}
