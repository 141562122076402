import React, { useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import {
  RESET_PARAMS,
  selectQueryParams,
  PageLayout,
  Row,
  Title,
  useTranslation,
  Table
} from '@gk-devteam/apmc-core-web'

import {
  DELETE_INQUIRY,
  FETCH_INQUIRIES,
  RESET_INQUIRIES,
  TOGGLE_INQUIRY_MODAL,
  SET_ACTIVE_INQUIRY
} from '../../types'
import {
  selectInquiriesCount,
  selectInquiriesData,
  selectInquiriesLoading
} from '../../selectors'
import { inquiriesSearchSchema } from '../../validation'

import InquiriesPageSearchBar from './InquiriesPageSearchBar'
import InquiryModal from './InquiryModal'

const breadcrumbs = [
  {
    label: 'navigation:inquiries',
    link: '/inquiries'
  }
]

const InquiriesPage = ({ navigate, location }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const prevQuery = useRef(null)
  const { t } = useTranslation()

  const fetchData = useCallback(
    () => {
      const schema = inquiriesSearchSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_INQUIRIES, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_INQUIRIES })
          }
        })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_INQUIRIES })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const navigateToChat = useCallback(
    (id, close, data) => {
      const { contract_id, user_id } = data
      close()
      navigate(`/admin_tasks/chat/contract:${contract_id}_${user_id}`)
    },
    [navigate]
  )

  const _openInquiryModal = (id) => {
    dispatch({ type: TOGGLE_INQUIRY_MODAL })
    dispatch({ type: SET_ACTIVE_INQUIRY, payload: id })
  }

  const inquiriesColumns = [
    {
      title: t('inquiries:title'),
      dataIndex: 'title',
      key: 'title',
      cellTitle: true,
      sortable: true
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name',
      sortable: true
    },
    {
      title: t('app_users:app_user_name'),
      dataIndex: 'user_name',
      key: 'user_name',
      sortable: true
    },
    {
      title: t('inquiries:sent_datetime'),
      dataIndex: 'sent_date',
      key: 'sent_date',
      sortable: true
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('admin_tasks:to_chat'),
          onClick: (id, close, data) => {
            navigateToChat(id, close, data)
          },
          shouldRender: 'chat.enabled'
        },
        {
          label: t('delete'),
          dangerColor: true,
          onClick: (id, close) => {
            console.log('warning', id)
            dispatch({ type: DELETE_INQUIRY, id })
            close()
          }
        }
      ]
    }
  ]

  return (
    <PageLayout
      sidebar={
        () => <InquiriesPageSearchBar />
      }
      breadcrumbs={breadcrumbs}
    >
      <InquiryModal />
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:inquiries_list"/>
      </Row>
      <Table
        fullHeight
        columns={inquiriesColumns}
        rowKey="id"
        loadingSelector={selectInquiriesLoading}
        resultsCountSelector={selectInquiriesCount}
        dataSelector={selectInquiriesData}
        onRowClick={_openInquiryModal}
        rightStickyColumnCount={2}
      />
    </PageLayout>
  )
}
export default InquiriesPage

InquiriesPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
InquiriesPage.defaultProps = {

}
