import React, { useCallback, useEffect, useState } from 'react'
import { Card, FormSubmitButton, Row, SectionTitle, useTranslation } from '@gk-devteam/apmc-core-web'
import { CancelToken } from 'axios'

import ChecklistSettingsForm from '../../../../forms/checklist/ChecklistSettingsForm'
import { fetchChecklistSettings, postChecklistSettings } from '../../../../services'
import { message } from 'antd'

export default function ChecklistSettings () {
  const { t } = useTranslation()
  const [data, setData] = useState(null)

  const fetchData = useCallback(
    async () => {
      try {
        const cancelSource = CancelToken.source()
        const res = await fetchChecklistSettings(cancelSource)
        if (res?.data) setData(res.data)
      } catch (error) {
        console.warn('fetch error', error)
        message.error(t('fetch_error'))
        setData({ checklist_duration: 7 })
      }
    },
    [t]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const _handleSubmit = async (data) => {
    const post = {
      ...data,
      checklist_duration: Number(data?.checklist_duration)
    }
    try {
      const cancelSource = CancelToken.source()
      await postChecklistSettings(post, cancelSource)
      fetchData()
      message.success(t('post_success'))
    } catch (error) {
      console.warn('fetch error', error)
      message.error(t('post_error'))
    }
  }

  const _renderForm = () => {
    if (!data) return null
    return (
      <Card title="admin_tasks:checklist_duration_setting" mt="R">
        <ChecklistSettingsForm data={data} handleSubmit={_handleSubmit} />
      </Card>
    )
  }
  return (
    <>
      <Row justify="end" mt="R" mb="ML">
        <FormSubmitButton formID="checklist_settings_form" />
      </Row>
      <SectionTitle label="admin_tasks:checklist_settings" />

      { _renderForm() }

    </>

  )
}
