import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const faqSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    category: yup
      .number()
      .integer()
      .required()
      .label(i18n.t('faq:faq_category')),
    question: yup
      .string()
      .max(255)
      .nullable()
      .required()
      .label(i18n.t('faq:question')),
    answer: yup
      .string()
      .nullable()
      .required()
      .label(i18n.t('faq:answer'))
  })
}
