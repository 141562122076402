export default {
  search: '検索',
  clear: 'クリア', // Accessible label on "clear" button
  notfound: '絵文字が見つかりませんでした',
  skintext: 'デフォルトの肌の色を選択する',
  categories: {
    search: '検索結果',
    recent: 'Frequently Used',
    smileys: 'Smileys & Emotion',
    people: 'People & Body',
    nature: 'Animals & Nature',
    foods: 'Food & Drink',
    places: 'Travel & Places',
    activity: 'Activities',
    objects: 'Objects',
    symbols: 'Symbols',
    flags: 'Flags',
    custom: 'Custom'
  },
  categorieslabel: '絵文字カテゴリ', // Accessible title for the list of categories
  skintones: {
    1: 'Default Skin Tone',
    2: 'Light Skin Tone',
    3: 'Medium-Light Skin Tone',
    4: 'Medium Skin Tone',
    5: 'Medium-Dark Skin Tone',
    6: 'Dark Skin Tone'
  }
}
