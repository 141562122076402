import { put, call, takeLatest, cancelled, select } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  deleteConstructionWork,
  fetchConstructionWork,
  postConstructionWork
} from '../../services'
import {
  POST_CONSTRUCTION_WORK,
  POST_CONSTRUCTION_WORK_SUCCESS,
  POST_CONSTRUCTION_WORK_FAIL,
  FETCH_CONSTRUCTION_WORK_SUCCESS,
  FETCH_CONSTRUCTION_WORK_FAIL,
  FETCH_CONSTRUCTION_WORK,
  DELETE_CONSTRUCTION_WORK_SUCCESS,
  DELETE_CONSTRUCTION_WORK_FAIL,
  DELETE_CONSTRUCTION_WORK,
  FETCH_CONSTRUCTION_WORKS
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchConstructionWorkSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchConstructionWork, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_CONSTRUCTION_WORK_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_CONSTRUCTION_WORK_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_CONSTRUCTION_WORK_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postConstructionWorkSaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postConstructionWork, data, cancelSource)
    if (res && res.data && res.data.success) {
      if (successRedirect) yield call(successRedirect, res.data.id)
      yield put({ type: POST_CONSTRUCTION_WORK_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CONSTRUCTION_WORK_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CONSTRUCTION_WORK_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteConstructionWorkSaga ({ id, onSuccess, onFail }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteConstructionWork, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_CONSTRUCTION_WORK_SUCCESS })
      yield put({ type: FETCH_CONSTRUCTION_WORKS, query })
      if (onSuccess) yield call(onSuccess)
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_CONSTRUCTION_WORK_FAIL, payload: error.data || error.message })
    if (onFail) yield call(onFail)
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchConstructionWork () {
  yield takeLatest(FETCH_CONSTRUCTION_WORK, fetchConstructionWorkSaga)
  yield takeLatest(POST_CONSTRUCTION_WORK, postConstructionWorkSaga)
  yield takeLatest(DELETE_CONSTRUCTION_WORK, deleteConstructionWorkSaga)
}
