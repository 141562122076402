import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { dot } from 'dot-object'
import PropTypes from 'prop-types'
import {
  SideMenu,
  Row,
  CardTitle,
  TriStateCheckbox
} from '@gk-devteam/apmc-core-web'

import { selectPages } from '../../../../selectors'
import { PanelDisabledContext } from '../../../../components/Display/Panels/PanelDisabled'

const GuideCategoryItemHeader = ({
  item,
  idSelector,
  handleItemDisplay,
  menu
}) => {
  const disabled = useContext(PanelDisabledContext)
  const pages = useSelector(selectPages)
  const emptySelector = () => null
  const id = useSelector(idSelector || emptySelector)
  const {
    name,
    display
  } = item

  if (!pages) return null

  const _handleDisplayChange = (value) => {
    handleItemDisplay(value, id, item)
  }

  const _isDisabled = () => {
    const dotted = dot(pages)
    return !dotted['guides.functions.update']
  }

  return (
    <Row justify="between">
      <Row align="center">
        <TriStateCheckbox
          mr="L"
          mb="0"
          size="fluid"
          noLabel
          autoH
          value={display}
          handleChange={_handleDisplayChange}
          disabled={disabled || _isDisabled()}
        />
        <CardTitle text={name} />

      </Row>
      <SideMenu
        menu={menu}
        data={item}
      />
    </Row>
  )
}

export default GuideCategoryItemHeader

GuideCategoryItemHeader.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    display: PropTypes.oneOf([0, 1, 2, true, false]).isRequired,
    id: PropTypes.number
  }).isRequired,
  handleItemDisplay: PropTypes.func.isRequired,
  idSelector: PropTypes.func,
  menu: PropTypes.array
}
