import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Form,
  FilePicker,
  Card,
  TextInput,
  TextArea
} from '@gk-devteam/apmc-core-web'

import { manualSchema } from '../../validation'

const ManualForm = ({ handleSubmit }) => {
  return (
    <Form
      id="manual_form"
      handleSubmit={handleSubmit}
      validationSchema={manualSchema}
      authorized="manuals.functions.update"
      // loadingSelector={selectLoadingAppUser}
      // postingSelector={selectPostingAppUser}
      // initialData={initialValues}
      // debug
    >
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="title"
          label='title'
          placeholder=""
          required
        />
      </Row>
      <Row>
        <TextArea
          flex={1}
          name="content"
          label='description'
          placeholder=""
        />
      </Row>
      <Card>
        <FilePicker
          flex={1}
          name="file"
          delete_name="delete_file"
          types={['pdf']}
          placeholder=""
          required
        />
      </Card>
    </Form>
  )
}

export default memo(ManualForm)

ManualForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}
