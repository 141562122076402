import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const contractOpeloUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    serial_key: yup.string().length(9).required().label(i18n.t('opelo:serial_key')),
    local_key: yup.string().length(9).required().label(i18n.t('opelo:local_key'))
  })
}
