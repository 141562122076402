export const FETCH_CONTRACT_DOCUMENTS = 'fetch_contract_documents'
export const FETCH_CONTRACT_DOCUMENTS_SUCCESS = 'fetch_contract_documents_success'
export const FETCH_CONTRACT_DOCUMENTS_FAIL = 'fetch_contract_documents_fail'
export const RESET_CONTRACT_DOCUMENTS = 'reset_contract_documents'

export const DELETE_CONTRACT_DOCUMENT = 'delete_contract_document'
export const DELETE_CONTRACT_DOCUMENT_SUCCESS = 'delete_contract_document_success'
export const DELETE_CONTRACT_DOCUMENT_FAIL = 'delete_contract_document_fail'

export const POST_CONTRACT_DOCUMENT = 'post_contract_document'
export const POST_CONTRACT_DOCUMENT_SUCCESS = 'post_contract_document_success'
export const POST_CONTRACT_DOCUMENT_FAIL = 'post_contract_document_fail'

export const TOGGLE_ADD_DOCUMENT_MODAL = 'toggle_add_document_modal'
export const TOGGLE_VIEW_DOCUMENT_MODAL = 'toggle_view_document_modal'
export const SET_ACTIVE_CONTRACT_DOCUMENT = 'set_active_contract_document'
