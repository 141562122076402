import React from 'react'
import { Empty } from 'antd'
import dot from 'dot-object'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Spinner,
  LightText,
  Modal,
  TOGGLE_ARRAY_ITEM
} from '@gk-devteam/apmc-core-web'

import { MEETING_ROLES } from '../../../constants'

import {
  LoaderStyle,
  UsersListItemStyle,
  UsersListStyle
} from '../PropertyForm.style'

const PropertySettingsChairmanModal = ({
  modalVisible,
  closeModal,
  loading,
  users
}) => {
  const dispatch = useDispatch()

  const currentChairman = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    return form?.meeting_users?.find(user => user.role === MEETING_ROLES.CHAIRMAN)
  })

  const handleUserChange = (app_user_id, app_user_name) => {
    if (currentChairman) {
      const { app_user_id, app_user_name } = currentChairman
      dispatch({
        type: TOGGLE_ARRAY_ITEM,
        payload: [{
          key: 'meeting_users',
          value: {
            app_user_id,
            app_user_name,
            role: MEETING_ROLES.CHAIRMAN
          },
          objectComparatorKey: 'app_user_id'
        }]
      })
    }
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [{
        key: 'meeting_users',
        value: {
          app_user_id,
          app_user_name,
          role: MEETING_ROLES.CHAIRMAN
        },
        objectComparatorKey: 'app_user_id'
      }]
    })
    closeModal()
  }

  const _renderUsers = () => {
    if (!users) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <LightText label='search_instruction'/>
          }
        />
      )
    }
    if (users.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <LightText label='no_data'/>
          }
        />
      )
    }
    return users.map((user, i) => {
      const { id, app_user_name } = user
      return (
        <UsersListItemStyle
          key={i}
          onClick={ () => { handleUserChange(id, app_user_name) } }
        >
          {app_user_name}
        </UsersListItemStyle>
      )
    })
  }

  const _renderLoader = () => {
    if (!loading) return null
    return (
      <LoaderStyle>
        <Spinner
          size="default"
          color="info"
        />
      </LoaderStyle>
    )
  }

  return (
    <Modal
      visible={modalVisible}
      title='properties:select_chairman'
      onCancel={closeModal}
      cancelText='close'
      fullWidth
    >

      <UsersListStyle>
        { _renderLoader() }
        { _renderUsers() }
      </UsersListStyle>
    </Modal>
  )
}

export default PropertySettingsChairmanModal

PropertySettingsChairmanModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array
}
