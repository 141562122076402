import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_APP_USERS,
  FETCH_APP_USERS_SUCCESS,
  FETCH_APP_USERS_FAIL,
  RESET_APP_USERS,
  DELETE_APP_USER,
  // DELETE_APP_USER_SUCCESS,
  DELETE_APP_USER_FAIL,
  FETCH_APP_USERS_AVAILABLE_RECIPIENTS,
  FETCH_APP_USERS_AVAILABLE_RECIPIENTS_SUCCESS,
  FETCH_APP_USERS_AVAILABLE_RECIPIENTS_FAIL,
  RESET_APP_USERS_AVAILABLE_RECIPIENTS
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  max_pages: null,
  results_count: null,
  results: [],
  loading_available_recipients: false,
  available_recipients: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_APP_USERS]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_APP_USERS_SUCCESS]: (state, action) => {
    // console.debug('here')
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_APP_USERS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_APP_USERS]: (state, action) => {
    state.loading = true
    state.error = null
    state.max_pages = null
    state.results_count = null
    state.results = []
  },
  [DELETE_APP_USER]: (state, action) => {
    state.loading = true
  },
  [DELETE_APP_USER_FAIL]: (state, action) => {
    state.loading = false
  },
  [FETCH_APP_USERS_AVAILABLE_RECIPIENTS]: (state, action) => {
    state.loading_available_recipients = true
  },
  [FETCH_APP_USERS_AVAILABLE_RECIPIENTS_SUCCESS]: (state, action) => {
    state.loading_available_recipients = false
    state.available_recipients = action.payload
  },
  [FETCH_APP_USERS_AVAILABLE_RECIPIENTS_FAIL]: (state, action) => {
    state.loading_available_recipients = false
  },
  [RESET_APP_USERS_AVAILABLE_RECIPIENTS]: (state, action) => {
    state.loading_available_recipients = false
    state.available_recipients = []
  }
})
