import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { CancelToken } from 'axios'
import {
  useTranslation
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { previewTemplate } from '../../../services/templatesService'

import PreviewModal from '../PreviewModal'
import { PreviewButtonStyle } from './SelectContractTemplate.style'

function PreviewButton ({ type }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(null)
  const [fileURL, setFileURL] = useState(null)
  const [visible, setVisible] = useState(false)
  const templateID = useSelector(state => state.form.template_id)
  if (!templateID) return null

  const _handlePreview = async () => {
    if (templateID) {
      setLoading(true)
      try {
        const cancelSource = CancelToken.source()
        const res = await previewTemplate(templateID, type, cancelSource)
        const fileURL = URL.createObjectURL(res.data)
        setFileURL(fileURL)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.warning('error', error)
        message.error(t('fetch_error'))
      }
    }

    setVisible(true)
  }
  const _handleClose = () => {
    setVisible(false)
    setFileURL(null)
  }

  return (
    <>
      <PreviewModal
        visible={visible}
        close={_handleClose}
        fileURL={fileURL}
      />
      <PreviewButtonStyle
        label="contracts:preview"
        onClick={_handlePreview}
        type="button"
        loading={loading}
      />
    </>
  )
}

PreviewButton.propTypes = {
  type: PropTypes.number.isRequired
}

export default PreviewButton
