import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dot } from 'dot-object'
import PropTypes from 'prop-types'
import {
  INIT_FORM,
  Row,
  TriStateCheckbox
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyName,
  selectVicinitiesLoading,
  selectVicinitiesCategoriesOptionsWithReset,
  selectVicinitiesAllAdditionalDataDisplayed,
  selectPages,
  selectVicinitiesHiddenBy,
  selectVicinitiesHiddenById
} from '../../../../selectors'
import {
  FETCH_VICINITIES,
  RESET_VICINITIES,
  TOGGLE_VICINITIES_ADDITIONAL_DATA
} from '../../../../types'

import VicinityModal from './VicinityModal'
import VicinitiesList from './VicinitiesList'
import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import {
  FilterStyle,
  LabelStyle
} from './PropertyVicinitiesPage.style'

const PropertyVicinitiesPage = ({ propertyID }) => {
  const dispatch = useDispatch()
  const propertyName = useSelector(selectPropertyName)
  const loading = useSelector(selectVicinitiesLoading)
  const pages = useSelector(selectPages)
  const categoriesOptions = useSelector(selectVicinitiesCategoriesOptionsWithReset)
  const allAdditionalDataDisplayed = useSelector(selectVicinitiesAllAdditionalDataDisplayed)
  const [modalVisible, setModalVisible] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [categoryFilter, setCategoryFilter] = useState('')

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_vicinities',
      link: `/properties/edit/${propertyID}/vicinities`
    }
  ]

  useEffect(() => {
    if (propertyID) {
      dispatch({ type: FETCH_VICINITIES, id: propertyID })
    }
    return () => {
      dispatch({ type: RESET_VICINITIES })
    }
  }, [propertyID, dispatch])

  if (!pages) return null

  const _handleFilterChange = (value) => {
    setCategoryFilter(value)
  }

  const _openAddModal = () => {
    dispatch({
      type: INIT_FORM,
      payload: {
        category: null,
        title: '',
        business_hour: '',
        address: '',
        phone: '',
        url: '',
        content: '',
        display: true,
        file: null,
        delete_file: false
      }
    })
    setModalVisible(true)
  }
  const _openCopyModal = () => {
    setModalVisible(true)
  }
  const _openEditModal = () => {
    setModalVisible(true)
    setEditMode(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
    setEditMode(false)
  }

  // let displayLabel
  // switch (allAdditionalDataDisplayed) {
  //   case 0:
  //     displayLabel = 'vicinities:show_all_additional_data'
  //     break
  //   case 1:
  //     displayLabel = 'vicinities:show_all_additional_data'
  //     break
  //   case 2:
  //     displayLabel = 'vicinities:hide_all_additional_data'
  //     break

  //   default:
  //     break
  // }

  const _handleToggleAdditionalData = () => {
    const data = {
      enable: !allAdditionalDataDisplayed
    }
    dispatch({ type: TOGGLE_VICINITIES_ADDITIONAL_DATA, data, id: propertyID })
  }

  const _isDisabled = () => {
    const dotted = dot(pages)
    return !dotted['faq.functions.update']
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      title="navigation:properties_vicinities"
      hiddenSelector={selectVicinitiesHiddenBy}
      hiddenByIdSelector={selectVicinitiesHiddenById}
      propertyID={propertyID}
      authorized="vicinities.functions.add"
      info="services:level_message.property"
      infoTitle="services:level_message.propertyTitle"
      handleAddClick={_openAddModal}
    >
      <VicinityModal
        visible={modalVisible}
        editMode={editMode}
        onCancel={_closeModal}
        id={propertyID}
      />
      <Row mt="L" justify="between">
        <FilterStyle
          noLabel
          mb="0"
          handleChange={_handleFilterChange}
          value={categoryFilter}
          name="category_filter"
          options={categoriesOptions}
          loading={loading}
        />
        <Row justify="end">
          {/* <LabelStyle label={displayLabel}/> */}
          <LabelStyle label='vicinities:show_all_additional_data'/>
          <TriStateCheckbox
            size="fluid"
            mr="0"
            mb="0"
            autoH
            noLabel
            value={allAdditionalDataDisplayed}
            handleChange={_handleToggleAdditionalData}
            disabled={_isDisabled()}
          />
        </Row>
      </Row>
      <VicinitiesList filter={categoryFilter} copyModal={_openCopyModal} editModal={_openEditModal}/>
    </PanelLayout>
  )
}
export default PropertyVicinitiesPage

PropertyVicinitiesPage.propTypes = {
  propertyID: PropTypes.string.isRequired
}
PropertyVicinitiesPage.defaultProps = {}
