import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Document,
  Page,
  Row,
  SideMenu,
  EmptyData
} from '@gk-devteam/apmc-core-web'

import { BULK_TOGGLE_MANUALS } from '../../../../types'
import { PDF_CMAP_URL } from '../../../../constants'

import ManualItemHeader from './ManualItemHeader'
import { PanelDisabledContext } from '../../../../components/Display/Panels/PanelDisabled'
import PanelBlockHeader from '../../../../components/Display/Panels/PanelBlockHeader'
import {
  DescriptionStyle, FilePreviewStyle
} from '../manuals.style'
import {
  BlockStyle,
  CollapseStyle,
  PanelStyle
} from '../../panels.style'

const ManualsList = ({
  dataSelector,
  displaySelector,
  idSelector,
  title,
  type,
  handleItemDisplay,
  sideMenu,
  level
}) => {
  const { t } = useTranslation()
  const manuals = useSelector(dataSelector)
  const disabled = useContext(PanelDisabledContext)

  const onDocumentLoadSuccess = () => {
    console.log('manual loaded')
  }

  const _renderPDFPreview = (url) => {
    return (
      <Document
        file={url}
        loading={t('pdf_load')}
        error={t('pdf_load_fail')}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        options={{
          cMapUrl: PDF_CMAP_URL,
          cMapPacked: true
        }}
      >
        <Page
          pageNumber={1}
          height={100}
          renderAnnotationLayer={false}
        />
      </Document>
    )
  }

  const _renderList = () => {
    if (!manuals) return null
    if (manuals.length === 0) return <EmptyData />
    return manuals.map((item, i) => {
      return (
        <PanelStyle
          header={(
            <ManualItemHeader
              item={item}
              handleItemDisplay={handleItemDisplay}
              idSelector={idSelector}
            />
          )}
          key={`manual_${i}`}
        >
          <Row align="start">
            <FilePreviewStyle
              href={item.file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              { _renderPDFPreview(item.file_url) }
            </FilePreviewStyle>
            <DescriptionStyle>{item.content}</DescriptionStyle>
            <SideMenu
              menu={sideMenu}
              data={item}
            />
          </Row>
        </PanelStyle>
      )
    })
  }

  return (
    <BlockStyle>
      <PanelBlockHeader
        title={title}
        idSelector={idSelector}
        displaySelector={displaySelector}
        type={type}
        level={level}
        toggleType={BULK_TOGGLE_MANUALS}
        authorized="manuals.functions.update"
      />
      <CollapseStyle
        bordered={false}
        expandIconPosition="right"
        disabled={disabled}
        // defaultActiveKey="契約_item1"
      >
        { _renderList() }
      </CollapseStyle>
    </BlockStyle>
  )
}

export default ManualsList

ManualsList.propTypes = {
  dataSelector: PropTypes.func.isRequired,
  displaySelector: PropTypes.func.isRequired,
  idSelector: PropTypes.func,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['admin_manuals', 'property_manuals', 'room_manuals']).isRequired,
  level: PropTypes.oneOf(['admin', 'property', 'room']).isRequired,
  handleItemDisplay: PropTypes.func.isRequired,
  sideMenu: PropTypes.array
}
