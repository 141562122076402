import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectPages } from '@gk-devteam/apmc-core-web'

import {
  subscribeToChatWaitingRooms,
  subscribeToChecklists
} from '../actions'
import { selectUserRole } from '../selectors'
import { ROLES } from '../constants'

export const useFirebaseRoomsObserver = () => {
  const dispatch = useDispatch()
  const userRole = useSelector(selectUserRole)
  const pages = useSelector(selectPages)

  useEffect(() => {
    if (userRole && userRole !== ROLES.GUEST) {
      dispatch(subscribeToChecklists())
      dispatch(subscribeToChatWaitingRooms())
    }
  }, [dispatch, userRole, pages])
}
