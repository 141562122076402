import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  InquiryID,
  Form,
  Card,
  Row,
  Title,
  FormSubmitButton,
  RoleControlled,
  TextArea,
  TextInput
} from '@gk-devteam/apmc-core-web'

import {
  selectLoadingPropertiesGroup,
  selectPostingPropertiesGroup,
  selectPages
} from '../../../selectors'
import { propertiesGroupUpdateValidation } from '../../../validation'
import { POST_PROPERTIES_GROUP } from '../../../types'

import SelectItems from '../common/SelectItems'
import { INQUIRY_PREFIX } from '../../../constants'

const PropertiesGroupForm = ({ data, propertiesGroupID }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)
  const [authorized, setAuthorized] = useState(true)

  useEffect(() => {
    if (pages && data && data.id) {
      if (!data.editable || !pages.manage_propertiesGroup.functions.update) setAuthorized(false)
    }
  }, [pages, data])

  const initialValues = {
    ...data,
    properties: data.properties_list && data.properties_list.length > 0 ? data.properties_list.map(item => item.id) : null,
    properties_list: data.properties_list && data.properties_list.length > 0 ? data.properties_list : null
  }

  const handleFormSubmit = (data) => {
    const successRedirect = (id) => {
      navigate('/user_management/properties_groups')
    }
    dispatch({ type: POST_PROPERTIES_GROUP, data, successRedirect })
  }

  return (
    <Form
      id="addPropertiesGroupForm"
      handleSubmit={handleFormSubmit}
      validationSchema={propertiesGroupUpdateValidation}
      loadingSelector={selectLoadingPropertiesGroup}
      postingSelector={selectPostingPropertiesGroup}
      initialData={initialValues}
      authorized={authorized}
      // debug
    >
      <Row justify="between" wrap="wrap">
        {
          propertiesGroupID
            ? <Title text={data.name} />
            : <Title label='user_management:add_properties_group' />
        }
        <RoleControlled authorized="manage_propertiesGroup.functions.update">
          <FormSubmitButton editMode={!!propertiesGroupID} postingSelector={selectPostingPropertiesGroup}/>
        </RoleControlled>
      </Row>
      <Row mb="ML">
        <InquiryID id={propertiesGroupID} prefix={INQUIRY_PREFIX.PROPERTIES_GROUP} />
      </Row>
      <div>
        <Card mb="L">
          <Row wrap="wrap">
            <TextInput
              flex={1}
              size="M"
              type="text"
              name="name"
              label='user_management:properties_group.name'
              placeholder=""
              required
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="manage_id"
              label='user_management:properties_group.manage_id'
              placeholder=""
            />
          </Row>
          <Row>
            <TextArea
              flex={1}
              type="text"
              name="description"
              label='user_management:properties_group.description'
              placeholder=""
            />
          </Row>
        </Card>

        <Card title='user_management:properties_list'>
          <SelectItems itemsKey="properties" noLabel/>
        </Card>
      </div>
    </Form>
  )
}
export default PropertiesGroupForm

PropertiesGroupForm.propTypes = {
  data: PropTypes.object,
  propertiesGroupID: PropTypes.string
}
PropertiesGroupForm.defaultProps = {

}
