export const FETCH_APP_USERS = 'fetch_app_users'
export const FETCH_APP_USERS_SUCCESS = 'fetch_app_users_success'
export const FETCH_APP_USERS_FAIL = 'fetch_app_users_fail'
export const RESET_APP_USERS = 'reset_app_users'

export const DELETE_APP_USER = 'delete_app_user'
export const DELETE_APP_USER_SUCCESS = 'delete_app_user_success'
export const DELETE_APP_USER_FAIL = 'delete_app_user_fail'

export const FETCH_APP_USER = 'fetch_app_user'
export const FETCH_APP_USER_SUCCESS = 'fetch_app_user_success'
export const FETCH_APP_USER_FAIL = 'fetch_app_user_fail'
export const RESET_APP_USER = 'reset_app_user'

export const POST_APP_USER = 'post_app_user'
export const POST_APP_USER_SUCCESS = 'post_app_user_success'
export const POST_APP_USER_FAIL = 'post_app_user_fail'

export const SEND_APP_USER_SMS = 'send_app_user_sms'
export const SEND_APP_USER_LOGIN = 'send_app_user_login'

export const SEND_USER_VERIFY_EMAIL = 'send_user_verify_email'

export const RESET_USER_LOGIN = 'reset_user_login'

export const FETCH_APP_USERS_AVAILABLE_RECIPIENTS = 'fetch_app_users_available_recipients'
export const FETCH_APP_USERS_AVAILABLE_RECIPIENTS_SUCCESS = 'fetch_app_users_available_recipients_success'
export const FETCH_APP_USERS_AVAILABLE_RECIPIENTS_FAIL = 'fetch_app_users_available_recipients_fail'
export const RESET_APP_USERS_AVAILABLE_RECIPIENTS = 'reset_app_users_available_recipients'
