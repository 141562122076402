import { createSelector } from 'reselect'

const selectRooms = state => state.rooms

export const selectRoomsLoading = createSelector(
  selectRooms,
  rooms => rooms.loading
)

export const selectRoomsCount = createSelector(
  selectRooms,
  rooms => rooms.results_count
)
export const selectRoomsData = createSelector(
  selectRooms,
  rooms => rooms.results
)
