import React, { memo, useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Document,
  Page,
  Modal,
  Card,
  Image
} from '@gk-devteam/apmc-core-web'

import {
  selectFileViewModalState
} from '../../../selectors'
import { TOGGLE_VIEW_CONSTRUCTION_WORK_FILE_MODAL } from '../../../types'
import { PDF_CMAP_URL } from '../../../constants'

import { PreviewStyle } from './ContractDocumentsPage.style'

const ViewConstructionWorkFileModal = ({ fileURL, fileName, fileContentType }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visible = useSelector(selectFileViewModalState)
  const [height, setHeight] = useState(400)

  const handlePreviewHeight = useCallback(
    () => {
      const windowHeight = window.innerHeight
      setHeight(windowHeight * 0.8)
    },
    []
  )

  useEffect(() => {
    handlePreviewHeight()
    window.addEventListener('resize', handlePreviewHeight)
    return () => {
      window.removeEventListener('resize', handlePreviewHeight)
    }
  }, [handlePreviewHeight])

  const _closeViewModal = () => {
    dispatch({ type: TOGGLE_VIEW_CONSTRUCTION_WORK_FILE_MODAL })
  }

  const _saveDocument = () => {
    let ext = null
    switch (fileContentType) {
      case 'application/pdf':
        ext = 'pdf'
        break
      case 'image/png':
        ext = 'png'
        break
      case 'image/jpg':
        ext = 'jpg'
        break
      default:
        break
    }
    saveAs(fileURL, `${fileName}.${ext}`)
  }

  const _renderPreview = () => {
    if (!fileContentType) return <></>
    if (fileContentType === 'application/pdf') {
      return (
        <PreviewStyle>
          <Document
            file={fileURL}
            loading={t('pdf_load')}
            error={t('pdf_load_fail')}
            onLoadError={console.error}
            options={{
              cMapUrl: PDF_CMAP_URL,
              cMapPacked: true
            }}
          >
            <Page
              pageNumber={1}
              height={height}
              renderAnnotationLayer={false}
            />
          </Document>
        </PreviewStyle>

      )
    } else {
      return (
        <Image src={fileURL}/>
      )
    }
  }

  return (
    <Modal
      title={fileName}
      visible={visible && fileURL && !!fileContentType}
      onCancel={_closeViewModal}
      cancelText="close"
      onOk={_saveDocument}
      okText="download"
      fullWidth
      fullHeight
      large
    >
      <div>
        <Card>
          { _renderPreview() }
        </Card>
      </div>
    </Modal>
  )
}

export default memo(ViewConstructionWorkFileModal)

ViewConstructionWorkFileModal.propTypes = {
  fileURL: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  fileContentType: PropTypes.string.isRequired
}
