import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const propertiesSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    property_name: yup.string().label(i18n.t('properties:property_name')),
    property_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('properties:property_id')),
    property_manage_id: yup.string().label(i18n.t('properties:property_manage_id')),
    prefecture: yup.string().label(i18n.t('properties:prefecture')),
    city: yup.string().label(i18n.t('properties:city')),
    limit: yup.number().integer().label(i18n.t('limit')),
    page: yup.number().integer().label(i18n.t('page')),
    sort_by: yup.string().label(i18n.t('sort_by')),
    sort_order: yup.string().label(i18n.t('sort_order'))
  })
}
