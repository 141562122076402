import React from 'react'
import {
  useTranslation,
  Row,
  TextInput,
  Select,
  RangePicker
} from '@gk-devteam/apmc-core-web'

import { SPACE_RESERVATION_STATUS, SPACE_TYPE_OPTIONS } from '../../../constants'

const ReservationSearchForm = () => {
  const { t } = useTranslation()
  return (
    <>
      <Row>
        <TextInput
          flex={1}
          name="property_name"
          label='properties:property_name'
          placeholder="placeholders.partial_property_name"
        />
      </Row>
      <Row>
        <Select
          name="space_type"
          label='spaces:type'
          placeholder=""
          options={SPACE_TYPE_OPTIONS}
        />
      </Row>
      <Row>
        <Select
          name="status"
          label='status'
          placeholder=""
          options={[
            {
              label: t('spaces:reservation_statuses.unconfirmed'),
              value: SPACE_RESERVATION_STATUS.UNCONFIRMED
            },
            {
              label: t('spaces:reservation_statuses.confirmed'),
              value: SPACE_RESERVATION_STATUS.CONFIRMED
            },
            {
              label: t('spaces:reservation_statuses.canceled'),
              value: SPACE_RESERVATION_STATUS.CANCELED
            },
            {
              label: t('spaces:reservation_statuses.rejected'),
              value: SPACE_RESERVATION_STATUS.REJECTED
            }
          ]}
        />
      </Row>
      <Row>
        <RangePicker
          name_start="from_day"
          name_end="to_day"
          label='spaces:reserved_duration'
          placeholder=""
          mr="0"
        />
      </Row>
    </>
  )
}

export default ReservationSearchForm

ReservationSearchForm.propTypes = {}
