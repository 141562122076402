
import React from 'react'
import { Result } from 'antd'
import { useNavigate } from '@reach/router'
import {
  useTranslation,
  Card,
  InfoButton
} from '@gk-devteam/apmc-core-web'
import { useSelector } from 'react-redux'
import { selectDatasyncError } from '../../../selectors'

const DataUploaded = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const syncErrors = useSelector(selectDatasyncError)
  const _goToDatasync = () => {
    navigate('/datasync')
  }

  return (
    <Card mt="XL">
      <Result
        status={syncErrors ? 'error' : 'success'}
        title={syncErrors ? t('datasync:upload_error') : t('datasync:upload_success')}
        // subTitle={subTitle}
        extra={[
          <InfoButton label="datasync:back_to_datasync" key="back" handleClick={_goToDatasync}/>
        ]}
      />
    </Card>
  )
}
export default DataUploaded
