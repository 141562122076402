import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row,
  selectPages
} from '@gk-devteam/apmc-core-web'

import { UserNameLinkStyle } from '../../ContractForm.style'

const AppUserNameLink = ({ userType }) => {
  const pages = useSelector(selectPages)
  const userID = useSelector(state => state.form[`${userType}_user_id`])
  const userName = useSelector(state => {
    const firstName = state.form[`${userType}.first_name`]
    const lastName = state.form[`${userType}.last_name`]
    return `${lastName} ${firstName || ''}`
  })
  const userFurigana = useSelector(state => {
    const firstName = state.form[`${userType}.furigana_first_name`]
    const lastName = state.form[`${userType}.furigana_last_name`]
    return `${lastName || ''} ${firstName || ''}`
  })

  return (
    <Row wrap="wrap" align="baseline">
      <UserNameLinkStyle
        label={pages?.custom?.yasue ? 'yasue:contracts.contractor_name' : `contracts:${userType}_name`}
        to={`/app_users/edit/${userID}`}
        text={userName}
      />
      <UserNameLinkStyle
        label={pages?.custom?.yasue ? 'yasue:contracts.contractor_name_kana' : `contracts:${userType}_name_furigana`}
        to={`/app_users/edit/${userID}`}
        text={userFurigana}
      />
    </Row>
  )
}

export default memo(AppUserNameLink)

AppUserNameLink.propTypes = {
  userType: PropTypes.oneOf(['contractor', 'resident']).isRequired
}
