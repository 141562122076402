import {
  styled,
  Row,
  Column,
  SPACING,
  getColor,
  BORDER_RADIUS,
  Link,
  Text,
  LightText,
} from '@gk-devteam/apmc-core-web'
import { transparentize } from 'polished'
import { Link as RouterLink } from '@reach/router'

export const DrawerBodyStyle = styled(Column)`
  flex: 1 1 auto;
  overflow-y: scroll;
  padding-top: ${SPACING.SMALL}rem;
  & > .ant-tabs {
    overflow-y: scroll;
  }
`

export const NotificationMessageStyle = styled.div`
  &:not(:first-child) {
    margin-top: ${SPACING.MEDIUM_LARGE}rem;
  }
`
export const MessageStyle = styled(RouterLink)`
  ${BORDER_RADIUS}
  padding: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
  display: block;
  margin: 0;
  color: inherit;
  text-decoration: none;
  white-space: pre-wrap;
  border: 1px solid ${props => getColor(props, 'danger')};
  background-color: ${props => transparentize(0.9, getColor(props, 'danger'))};
  &:hover {
    color: ${props => getColor(props, 'text')};
  }
`
export const MessageFooterStyle = styled(Row)`
  color: ${props => getColor(props, 'lightText')};
`
export const UserStyle = styled(Link)``
export const TimeStyle = styled.time``

export const ContractsWrapperStyle = styled.ul`
  ${BORDER_RADIUS}
  padding: ${SPACING.SMALL}rem;
  margin: 0;
  list-style: none;
  border: 1px solid ${props => getColor(props, 'cardBorder')};
`
export const ContractItemStyle = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: ${SPACING.SMALLEST}rem 0;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid ${props => getColor(props, 'cardBorder')};
  }

  &:hover {
    background-color: ${props => transparentize(0.9, getColor(props, 'accent'))}
  }
`
export const ContractRightStyle = styled.div`
  flex: 1 1 auto;
`
export const ContractorStyle = styled(Text)`
  display: block;
  font-weight: bold;
  margin-bottom: ${SPACING.SMALLEST}rem;
`
export const PropertyStyle = styled(LightText)`
  margin-right: ${SPACING.SMALLEST}rem;
`
export const RoomStyle = styled(LightText)``
