import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import { POST_PUBLIC_FACILITY } from '../../../../types'
import { selectPublicFacilitiesPosting } from '../../../../selectors'

import PublicFacilityForm from '../../../../forms/PublicFacilityForm/PublicFacilityForm'

const FacilityModal = ({ visible, onCancel, id, editMode }) => {
  const dispatch = useDispatch()
  const posting = useSelector(selectPublicFacilitiesPosting)

  const _handleSubmit = (data) => {
    const onSuccess = () => {
      onCancel()
    }
    dispatch({ type: POST_PUBLIC_FACILITY, data, id, onSuccess })
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={_handleSubmit}
      okText="save"
      title={editMode ? 'publicFacilities:edit_public_facilities' : 'publicFacilities:add_public_facilities'}
      formID="public_facility_form"
      posting={posting}
      fullWidth
      // fullHeight
      large
    >
      <PublicFacilityForm handleSubmit={_handleSubmit} />
    </Modal>
  )
}

export default FacilityModal

FacilityModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  id: PropTypes.string
}
