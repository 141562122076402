import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { dot } from 'dot-object'
import PropTypes from 'prop-types'
import {
  Row,
  CardTitle,
  TriStateCheckbox
} from '@gk-devteam/apmc-core-web'

import { selectPages } from '../../../../selectors'

import { PanelDisabledContext } from '../../../../components/Display/Panels/PanelDisabled'

const ManualItemHeader = ({
  item,
  idSelector,
  handleItemDisplay
}) => {
  const disabled = useContext(PanelDisabledContext)
  const emptySelector = () => null
  const id = useSelector(idSelector || emptySelector)
  const pages = useSelector(selectPages)
  const {
    title,
    display
  } = item

  if (!pages) return null

  const _handleDisplayChange = (value) => {
    handleItemDisplay(value, id, item)
  }

  const _isDisabled = () => {
    const dotted = dot(pages)
    return !dotted['manuals.functions.update']
  }

  return (
    <Row justify="between">
      <Row align="center">
        <TriStateCheckbox
          mr="L"
          mb="0"
          size="fluid"
          noLabel
          autoH
          value={display}
          handleChange={_handleDisplayChange}
          disabled={disabled || _isDisabled()}
        />
        <CardTitle text={title} />

      </Row>

    </Row>
  )
}

export default ManualItemHeader

ManualItemHeader.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    display: PropTypes.oneOf([0, 1, 2, true, false]).isRequired,
    manual_id: PropTypes.number
  }).isRequired,
  handleItemDisplay: PropTypes.func.isRequired,
  idSelector: PropTypes.func
}
