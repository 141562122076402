import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Form,
  Row,
  Select,
  TextInput,
  TextArea
} from '@gk-devteam/apmc-core-web'

import { selectFaqCategoriesOptions, selectFaqLoading } from '../../selectors'
import { faqSchema } from '../../validation'

const FaqForm = ({ handleSubmit }) => {
  const loading = useSelector(selectFaqLoading)
  const categoryOptions = useSelector(selectFaqCategoriesOptions)
  return (
    <Form
      id="faq_form"
      handleSubmit={handleSubmit}
      validationSchema={faqSchema}
      authorized="faq.functions.update"
      // loadingSelector={selectLoadingAppUser}
      // postingSelector={selectPostingAppUser}
      // initialData={initialValues}
      // debug
    >
      <Row>
        <Select
          loading={loading}
          flex={1}
          name="category"
          label='faq:faq_category'
          options={categoryOptions}
          placeholder=""
          noClear
          required
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="question"
          label='faq:question'
          placeholder=""
          required
        />
      </Row>
      <Row>
        <TextArea
          flex={1}
          name="answer"
          label='faq:answer'
          placeholder=""
          required
        />
      </Row>
    </Form>
  )
}

export default memo(FaqForm)

FaqForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}
