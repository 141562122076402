import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dot } from 'dot-object'
import PropTypes from 'prop-types'
import {
  ToastTitle,
  Row,
  Rates,
  TriStateCheckbox
} from '@gk-devteam/apmc-core-web'

import { selectPublicFacilitiesCategories, selectPropertyID, selectPages } from '../../../../selectors'
import { TOGGLE_PUBLIC_FACILITY } from '../../../../types'

import {
  ListItemHeaderStyle,
  TagStyle
} from './PropertyPublicFacilitiesPage.style'

const FacilitiesListItemHeader = ({ item }) => {
  const dispatch = useDispatch()
  const categories = useSelector(selectPublicFacilitiesCategories)
  const propertyID = useSelector(selectPropertyID)
  const pages = useSelector(selectPages)
  if (!categories || !pages) return null
  const {
    category,
    display,
    id,
    title,
    public_facility_type_id,
    rating
  } = item

  const selectedCategory = categories.find(cat => cat.id === category)
  const categoryLabel = selectedCategory && selectedCategory.name
  if (!selectedCategory) {
    console.log('selectedCategory', selectedCategory, category)
    console.log('categoryLabel', categoryLabel)
  }

  const _handleTogglePublicFacility = () => {
    const data = {
      id,
      display: !display,
      type: public_facility_type_id
    }
    dispatch({ type: TOGGLE_PUBLIC_FACILITY, data, id: propertyID })
  }

  const _isDisabled = () => {
    const dotted = dot(pages)
    return !dotted['vicinities.functions.update']
  }

  return (
    <ListItemHeaderStyle justify="between" inactive={!display}>
      <Row>
        <TriStateCheckbox
          mr="ML"
          mb="0"
          size="fluid"
          noLabel
          autoH
          name={`vicinity_${id}`}
          value={display}
          handleChange={_handleTogglePublicFacility}
          small
          disabled={_isDisabled()}
        />
        <ToastTitle text={title} />
        <TagStyle color="info" label={categoryLabel}/>
      </Row>
      {
        rating ? <Rates initialValue={rating} readOnly/> : null
      }
    </ListItemHeaderStyle>
  )
}

export default FacilitiesListItemHeader

FacilitiesListItemHeader.propTypes = {
  item: PropTypes.shape(PropTypes.string.isRequired, {
    category: PropTypes.string.isRequired,
    display: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    public_facility_type_id: PropTypes.number.isRequired,
    rating: PropTypes.number
  }).isRequired
}
