import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import {
  RESET_PARAMS,
  selectQueryParams,
  UPDATE_FORM
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { FETCH_SPACES_RESERVATIONS, RESET_SPACES_RESERVATIONS } from '../../../types'
import { selectSpacesWaitingReservationsCount } from '../../../selectors'
import { spacesSearchSchema } from '../../../validation'

import ListPage from '../common/ListPage'
import ReservationSearchForm from './ReservationSearchForm'
import ReservationsList from './ReservationsList'

const initialValues = {
  property_id: null,
  property_name: null,
  space_id: null,
  space_type: null,
  from_day: null,
  to_day: null,
  status: null
}

function AdminTasksSpacesReservationsPage ({ location }) {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const prevQuery = useRef(null)

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        property_id: query?.property_id,
        property_name: query?.property_name,
        space_id: query?.space_id,
        space_type: query?.space_type,
        from_day: query?.from_day,
        to_day: query?.to_day,
        status: query?.status
      }
    })
  }, [dispatch, query])

  const fetchData = useCallback(
    () => {
      const schema = spacesSearchSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_SPACES_RESERVATIONS, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_SPACES_RESERVATIONS })
          }
        })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_SPACES_RESERVATIONS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const breadcrumbs = [
    {
      label: 'navigation:admin_tasks',
      link: '/admin_tasks'
    },
    {
      label: 'navigation:properties_spaces_reservations_list',
      link: '/admin_tasks/spaces_reservations'
    }
  ]

  return (
    <ListPage
      breadcrumbs={breadcrumbs}
      pageTitle="navigation:properties_spaces_reservations_list"
      countSelector={selectSpacesWaitingReservationsCount}
      validationSchema={spacesSearchSchema}
      initialValues={initialValues}
      searchInputs={<ReservationSearchForm />}
      table={<ReservationsList refetch={fetchData} />}
    />
  )
}

export default AdminTasksSpacesReservationsPage

AdminTasksSpacesReservationsPage.propTypes = {
  location: PropTypes.object.isRequired
}
