import React, { useCallback, useEffect, useState } from 'react'
import {
  Row,
  TextInput
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { APP_USERS_LOGIN_PDF_TYPE } from '../../../constants'

import { SearchButtonStyle } from '../../contract/ContractForm/ContractForm.style'

function RecipientsSearch ({ recipientType, fetchData }) {
  const [name, setName] = useState('')
  const [id, setID] = useState('')
  const [manageID, setManageID] = useState('')
  const nameLabel = recipientType === APP_USERS_LOGIN_PDF_TYPE.PROPERTIES ? 'properties:property_name' : 'app_users:user_name'

  // Reset form on type change
  useEffect(() => {
    setName('')
    setID('')
    setManageID('')
  }, [recipientType])

  const _handleNameChange = useCallback(
    (e) => {
      setName(e.target.value)
    },
    [setName]
  )
  const _handleIDChange = useCallback(
    (e) => {
      setID(e.target.value)
    },
    [setID]
  )
  const _handleManageIDChange = useCallback(
    (e) => {
      setManageID(e.target.value)
    },
    [setManageID]
  )

  const _handleSearchClick = useCallback(
    () => {
      fetchData({
        name,
        id,
        manageID
      })
    },
    [
      fetchData,
      name,
      id,
      manageID
    ]
  )

  const _handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    },
    []
  )
  return (
    <Row>
      <TextInput
        flex={1}
        type="text"
        name="name"
        label={nameLabel}
        placeholder=""
        onChange={_handleNameChange}
        value={name}
        onKeyPress={_handleKeyPress}
      />
      <TextInput
        flex={1}
        type="text"
        name="id"
        label='ID'
        placeholder=""
        onChange={_handleIDChange}
        value={id}
        onKeyPress={_handleKeyPress}
      />
      <TextInput
        flex={1}
        type="text"
        name="manageID"
        label='manage_id'
        placeholder=""
        onChange={_handleManageIDChange}
        value={manageID}
        onKeyPress={_handleKeyPress}
      />
      <SearchButtonStyle
        label="search"
        type="button"
        handleClick={_handleSearchClick}
        // loading={loading}
      />
    </Row>
  )
}

RecipientsSearch.propTypes = {
  recipientType: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired
}

export default RecipientsSearch
