import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { selectChecklistIssueComments } from '../../../selectors'

import IssueComment from './IssueComment'
import CommentMessageForm from './CommentMessageForm'
import {
  IssueCommentsContainerStyle,
  CommentsStyle
} from './AdminTasksChecklistRoomPage.style'

const IssueComments = ({ checklistID }) => {
  const comments = useSelector(selectChecklistIssueComments)
  const lastCommentRef = useRef(null)

  const _scrollToLastComment = () => {
    if (lastCommentRef.current) {
      lastCommentRef.current.scrollIntoView({
        // behavior: 'smooth',
        block: 'start'
      })
    }
  }

  useEffect(() => {
    _scrollToLastComment()
  }, [comments])

  const _renderComments = () => {
    if (!comments) return null
    return comments.map((comment, i) => {
      return (
        <IssueComment issueComment={comment} key={i} ref={comments.length - 1 === i ? lastCommentRef : null} />
      )
    })
  }

  return (
    <IssueCommentsContainerStyle>
      <CommentsStyle>
        { _renderComments() }
      </CommentsStyle>
      <CommentMessageForm checklistID={checklistID} />
    </IssueCommentsContainerStyle>
  )
}

export default IssueComments

IssueComments.propTypes = {
  checklistID: PropTypes.string.isRequired
}
