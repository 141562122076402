import { createSelector } from 'reselect'

const selectSurveys = state => state.surveys

export const selectSurveysLoading = createSelector(
  selectSurveys,
  surveys => surveys.loading
)
export const selectSurveysCount = createSelector(
  selectSurveys,
  surveys => surveys.results_count
)
export const selectSurveysData = createSelector(
  selectSurveys,
  surveys => surveys.results
)
