import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  postChoueiBilling
} from '../../services'

import {
  POST_CHOUEI_BILLING,
  POST_CHOUEI_BILLING_SUCCESS,
  POST_CHOUEI_BILLING_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * postChoueiBillingSaga ({ data, onSuccess, onError }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postChoueiBilling, data, cancelSource)
    if (res && res.status === 204) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_CHOUEI_BILLING_SUCCESS })
      // yield put({ type: RESET_FORM })
      // yield put({ type: INIT_FORM, payload: initialData })
      yield call(message.success, i18n.t('post_success'))
    } else {
      if (onError) yield call(onError)
      yield put({ type: POST_CHOUEI_BILLING_FAIL, error: true, payload: { message: i18n.t('post_error') } })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    if (onError) yield call(onError)
    yield put({ type: POST_CHOUEI_BILLING_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
    if (error?.data?.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchChouei () {
  yield takeLatest(POST_CHOUEI_BILLING, postChoueiBillingSaga)
}
