import { createSelector } from 'reselect'

const selectConstructionWork = state => state.yasueConstructionWork

export const selectLoadingConstructionWork = createSelector(
  selectConstructionWork,
  constructionWork => constructionWork.loading
)

export const selectPostingConstructionWork = createSelector(
  selectConstructionWork,
  constructionWork => constructionWork.results
)

export const selectConstructionWorkData = createSelector(
  selectConstructionWork,
  constructionWork => constructionWork.data || {}
)

export const selectConstructionWorkError = createSelector(
  selectConstructionWork,
  constructionWork => constructionWork.error
)

export const selectConstructionWorkFilesData = createSelector(
  selectConstructionWork,
  constructionWork => constructionWork.data.files
)
