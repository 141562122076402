import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const surveysSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    title: yup
      .string()
      .nullable()
      .label(i18n.t('surveys:title')),
    status: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .oneOf([1, 2, 3])
      .nullable()
      .label(i18n.t('status'))
  })
}
