import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_YASUE_MAINTENANCE_CONTACTS,
  FETCH_YASUE_MAINTENANCE_CONTACTS_SUCCESS,
  FETCH_YASUE_MAINTENANCE_CONTACTS_FAIL,
  RESET_YASUE_MAINTENANCE_CONTACTS,
  FETCH_YASUE_MAINTENANCE_SHOWROOMS,
  FETCH_YASUE_MAINTENANCE_SHOWROOMS_SUCCESS,
  FETCH_YASUE_MAINTENANCE_SHOWROOMS_FAIL,
  RESET_YASUE_MAINTENANCE_SHOWROOMS,
  POST_YASUE_MAINTENANCE_CONTACTS,
  POST_YASUE_MAINTENANCE_CONTACTS_SUCCESS,
  POST_YASUE_MAINTENANCE_CONTACTS_FAIL,
  POST_YASUE_MAINTENANCE_SHOWROOMS,
  POST_YASUE_MAINTENANCE_SHOWROOMS_SUCCESS,
  POST_YASUE_MAINTENANCE_SHOWROOMS_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: false,
  error: null,
  contacts: null,
  showrooms: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_YASUE_MAINTENANCE_CONTACTS]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_YASUE_MAINTENANCE_CONTACTS_SUCCESS]: (state, action) => {
    state.loading = false
    state.contacts = action.payload
  },
  [FETCH_YASUE_MAINTENANCE_CONTACTS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_YASUE_MAINTENANCE_CONTACTS]: (state, action) => {
    state.loading = null
    state.error = null
    state.contacts = null
  },
  [FETCH_YASUE_MAINTENANCE_SHOWROOMS]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_YASUE_MAINTENANCE_SHOWROOMS_SUCCESS]: (state, action) => {
    state.loading = false
    state.showrooms = action.payload
  },
  [FETCH_YASUE_MAINTENANCE_SHOWROOMS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_YASUE_MAINTENANCE_SHOWROOMS]: (state, action) => {
    state.loading = null
    state.error = null
    state.showrooms = null
  },
  [POST_YASUE_MAINTENANCE_CONTACTS]: (state, action) => {
    state.posting = true
  },
  [POST_YASUE_MAINTENANCE_CONTACTS_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_YASUE_MAINTENANCE_CONTACTS_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [POST_YASUE_MAINTENANCE_SHOWROOMS]: (state, action) => {
    state.posting = true
  },
  [POST_YASUE_MAINTENANCE_SHOWROOMS_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_YASUE_MAINTENANCE_SHOWROOMS_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  }
})
