export const PARSE_CSV = 'parse_csv'
export const PARSE_CSV_SUCCESS = 'parse_csv_success'
export const PARSE_CSV_FAIL = 'parse_csv_fail'

export const IMPORT_VALIDATION = 'import_validation'
export const IMPORT_VALIDATION_SUCCESS = 'import_validation_success'
export const IMPORT_VALIDATION_FAIL = 'import_validation_fail'

export const IMPORT_DATA = 'import_data'
export const IMPORT_DATA_PARTIAL_SUCCESS = 'import_data_partial_success'
export const IMPORT_DATA_PARTIAL_FAIL = 'import_data_partial_fail'
export const IMPORT_DATA_SUCCESS = 'import_data_success'
export const IMPORT_DATA_FAIL = 'import_data_fail'

export const UPDATE_IMPORT_TYPE = 'update_import_type'
export const UPDATE_STEP = 'update_step'
export const UPDATE_IMPORT_DATA = 'update_import_data'

export const RESET_IMPORT = 'reset_import'
