import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_ADMIN_PROFILE,
  FETCH_ADMIN_PROFILE_SUCCESS,
  FETCH_ADMIN_PROFILE_FAIL,
  POST_ADMIN_PROFILE,
  POST_ADMIN_PROFILE_SUCCESS,
  POST_ADMIN_PROFILE_FAIL,
  RESET_ADMIN_PROFILE
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_ADMIN_PROFILE]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_ADMIN_PROFILE_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_ADMIN_PROFILE_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_ADMIN_PROFILE]: (state, action) => {
    state.posting = true
  },
  [POST_ADMIN_PROFILE_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_ADMIN_PROFILE_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [RESET_ADMIN_PROFILE]: () => INITIAL_STATE
})
