import React, { useCallback } from 'react'
import { useNavigate } from '@reach/router'
import {
  dynamicLink,
  Table,
  Tag,
  useTranslation
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import { CONTRACT_STATUS } from '../../../constants'
import { selectContractsCount, selectContractsData, selectContractsLoading } from '../../../selectors'

function ContractsList () {
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'contracts'])

  const statusCell = useCallback(
    (value, cellKey, rowID, className) => {
      let status, color
      for (const key in CONTRACT_STATUS) {
        const element = CONTRACT_STATUS[key]
        if (value === element.value) {
          status = `contracts:status.${key}`
          color = element.color
        }
      }

      return <Tag label={status} color={color} className={className} />
      // return <TableDefaultCell value={status} key={cellKey} cellKey={cellKey} className={className} />
    },
    []
  )
  const contractsColumns = [
    {
      title: t('contracts:contractor_name'),
      dataIndex: 'contractor_name',
      key: 'contractor_name',
      cellTitle: true
      // sortable: true
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name'
      // sortable: true
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number',
      sortable: true
    },
    {
      title: t('contracts:resident_name'),
      dataIndex: 'resident_name',
      key: 'resident_name'
    },
    {
      title: t('contracts:contract_start_date'),
      dataIndex: 'contract_start_date',
      key: 'contract_start_date'
    },
    {
      title: t('contracts:contract_end_date'),
      dataIndex: 'contract_end_date',
      key: 'contract_end_date'
    },
    {
      title: t('contracts:move_out_date'),
      dataIndex: 'move_out_date',
      key: 'move_out_date'
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: statusCell
    }
  ]

  const navigateOnClick = (id, row, e) => {
    const { status } = row
    let path = ''
    switch (status) {
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value:
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value:
      case CONTRACT_STATUS.RENEWING.value:
        path = 'renew'
        break
      case CONTRACT_STATUS.REQUESTING_CANCEL.value:
      case CONTRACT_STATUS.CANCELING.value:
        path = 'cancel'
        break
      default:
        break
    }
    const url = `/contracts/edit/${id}/${path}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <Table
      fullHeight
      columns={contractsColumns}
      rowKey="id"
      loadingSelector={selectContractsLoading}
      resultsCountSelector={selectContractsCount}
      dataSelector={selectContractsData}
      onRowClick={navigateOnClick}
      rightStickyColumnCount={2}
    />
  )
}

ContractsList.propTypes = {}

export default ContractsList
