import { DEFAULT_QUERY_LIMIT, api, MULTIPART_HEADERS, getFileFromURL } from '@gk-devteam/apmc-core-web'

export const fetchConstructionWorks = (params = {}, cancelSource) => {
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }

  return api.get('/yasue/construction_work', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token
  })
}

export const fetchConstructionWork = (id, cancelSource) => {
  return api.get(`/yasue/construction_work/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const postConstructionWork = (data, cancelSource) => {
  return api.post('/yasue/construction_work', data, {
    cancelToken: cancelSource.token
  })
}

export const deleteConstructionWork = (id, cancelSource) => {
  return api.delete(`/yasue/construction_work/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const postConstructionWorkFile = async (data, constructionWorkID, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }
    delete data.file
    delete data.file_name
    data.yasue_construction_work_id = Number(constructionWorkID)

    formData.append('data', JSON.stringify(data))
    return api.post('/yasue/construction_work/files', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const deleteConstructionWorkFile = async (constructionWorkID, cancelSource) => {
  return api.delete(`/yasue/construction_work/files/${constructionWorkID}`, {
    cancelToken: cancelSource.token
  })
}
