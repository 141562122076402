import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  INIT_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectGuidesLoading,
  selectGuidesHiddenBy,
  selectPropertyName,
  selectCategoryName,
  selectGuidesHiddenById
} from '../../../../selectors'
import {
  FETCH_GUIDES,
  RESET_GUIDES
} from '../../../../types'
import initialData from '../common/initialData'

import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import GuideModal from '../common/GuideModal'
import AdminGuidesList from './AdminGuidesList'
import PropertyGuidesList from './PropertyGuidesList'

const AdminGuidesPage = ({ guideCategoryID, propertyID, guideType }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectGuidesLoading)
  const propertyName = useSelector(selectPropertyName)
  const categoryName = useSelector(selectCategoryName)
  const [modalVisible, setModalVisible] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_guides',
      link: `/properties/edit/${propertyID}/guides`
    },
    {
      text: categoryName,
      link: `/settings/guides/admin_details/${guideCategoryID}`
    }
  ]

  useEffect(() => {
    dispatch({
      type: FETCH_GUIDES,
      config: {
        guideCategoryID,
        type: guideType,
        property: propertyID
      }
    })
    return () => {
      dispatch({ type: RESET_GUIDES })
    }
  }, [dispatch, guideCategoryID, propertyID, guideType])

  const _openAddModal = () => {
    dispatch({ type: INIT_FORM, payload: initialData })
    setModalVisible(true)
  }
  const _openCopyModal = () => {
    setModalVisible(true)
  }
  const _openEditModal = () => {
    setModalVisible(true)
    setEditMode(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
    setEditMode(false)
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectGuidesHiddenBy}
      hiddenByIdSelector={selectGuidesHiddenById}
      title={categoryName || ''}
      authorized="guides.functions.add"
      info="services:level_message.property"
      infoTitle="services:level_message.propertyTitle"
      handleAddClick={guideType === 'guides' ? _openAddModal : null}
    >
      {
        guideType === 'guides'
          ? (
            <GuideModal
              visible={modalVisible}
              editMode={editMode}
              onCancel={_closeModal}
              guideCategoryID={guideCategoryID}
              guideType={guideType}
              type="property"
              id={propertyID}
            />
          )
          : null
      }

      {
        guideType === 'admin_guides'
          ? <AdminGuidesList guideCategoryID={guideCategoryID} />
          : <PropertyGuidesList guideCategoryID={guideCategoryID} copyModal={_openCopyModal} editModal={_openEditModal}/>
      }

    </PanelLayout>
  )
}
export default AdminGuidesPage

AdminGuidesPage.propTypes = {
  guideCategoryID: PropTypes.string.isRequired,
  propertyID: PropTypes.string.isRequired,
  guideType: PropTypes.oneOf(['admin_guides', 'guides'])
}
AdminGuidesPage.defaultProps = {}
