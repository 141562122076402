import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  ItemNotFound,
  PageLoader,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_APP_USER,
  RESET_APP_USER
} from '../../../types'
import {
  selectAppUserFormData,
  selectLoadingAppUser,
  selectAppUserError
} from '../../../selectors'

import AppUserHistory from './AppUserHistory'
import AppUserForm from '../../../forms/AppUserForm'
import YasueAppUserForm from '../../../forms/AppUserForm/YasueAppUserForm'
import YasueConstructionWorkHistory from './YasueConstructionWorkHistory'

const UserDetailsPage = ({ userID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const data = useSelector(selectAppUserFormData)
  const loading = useSelector(selectLoadingAppUser)
  const error = useSelector(selectAppUserError)
  const pages = useSelector(selectPages)

  useEffect(() => {
    dispatch({ type: FETCH_APP_USER, id: userID })
    return () => {
      dispatch({ type: RESET_APP_USER })
    }
  }, [dispatch, userID])

  const breadcrumbs = [
    {
      label: 'navigation:app_users',
      link: '/app_users'
    },
    {
      text: `${(data && data.last_name) || ''} ${(data && data.first_name) || ''}`,
      link: `/app_users/edit/${userID}`
    }
  ]

  return loading
    ? <PageLoader />
    : error && error.code === 404
      ? <ItemNotFound item={t('navigation:app_users')} link="/app_users" />
      : (
        <PageLayout
          breadcrumbs={breadcrumbs}
          loading={!data}
          lastEdited={data && data.modified}
          lastEditedBy={data && data.modifiedBy}
        >
          {pages?.custom?.yasue
            ? (
              <>
                <YasueAppUserForm data={data} />
                <YasueConstructionWorkHistory userID={userID} />
              </>
            )
            : (
              <>
                <AppUserForm data={data} />
                <AppUserHistory />
              </>
            )
          }
        </PageLayout>
      )
}
export default UserDetailsPage

UserDetailsPage.propTypes = {
  userID: PropTypes.string.isRequired
}
UserDetailsPage.defaultProps = {

}
