import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row,
  TextInput,
  Select,
  RepeaterRemoveButton,
  FormControlLabel
} from '@gk-devteam/apmc-core-web'

import { selectNoticeEditable } from '../../../../selectors'
import { SEX_OPTIONS } from '../../../../constants'

import {
  RangeStyle,
  RangeSymbolStyle
} from '../../NoticeForm.style'

const NoticeConditionsRow = ({ index }) => {
  // console.log('row index', index)
  const editable = useSelector(selectNoticeEditable)
  return (
    <Row wrap="wrap" align="baseline">
      <TextInput
        flex={1}
        mr="0"
        type="number"
        name={`recipients_condition[${index}].min_income`}
        label='notices:min_income'
        suffix="app_users:annual_income_unit"
        placeholder=""
        repeaterField
        readonly={!editable}
      />
      <RangeStyle>
        <FormControlLabel />
        <RangeSymbolStyle>〜</RangeSymbolStyle>
      </RangeStyle>
      <TextInput
        flex={1}
        mr="0"
        type="number"
        name={`recipients_condition[${index}].max_income`}
        label='notices:max_income'
        suffix="app_users:annual_income_unit"
        placeholder=""
        repeaterField
        readonly={!editable}
      />
      <Select
        mr="0"
        name={`recipients_condition[${index}].sex`}
        label='app_users:sex'
        placeholder=""
        options={SEX_OPTIONS}
        disabled={!editable}
      />
      <TextInput
        flex={1}
        mr="0"
        type="text"
        name={`recipients_condition[${index}].min_age`}
        label='notices:min_age'
        suffix="notices:age_unit"
        placeholder=""
        repeaterField
        readonly={!editable}
      />
      <RangeStyle>
        <FormControlLabel />
        <RangeSymbolStyle>〜</RangeSymbolStyle>
      </RangeStyle>
      <TextInput
        flex={1}
        mr="0"
        type="number"
        name={`recipients_condition[${index}].max_age`}
        label='notices:max_age'
        suffix="notices:age_unit"
        placeholder=""
        repeaterField
        readonly={!editable}
      />
      {
        editable
          ? (
            <RepeaterRemoveButton
              index={index}
              propertyKey="recipients_condition"
              label="delete"
            />
          )
          : null
      }
    </Row>
  )
}

export default memo(NoticeConditionsRow)

NoticeConditionsRow.propTypes = {
  index: PropTypes.number.isRequired
}
