import { createSelector } from 'reselect'
import dot from 'dot-object'
import dayjs from 'dayjs'
import { SERVER_DATE_FORMAT } from '@gk-devteam/apmc-core-web'

const selectContract = state => state.contract
const selectContractForm = state => state.form
const selectRenewContract = state => state.contract.renew
const selectCancelContract = state => state.contract.cancel

export const selectContractLoading = createSelector(
  selectContract,
  contract => contract.loading
)
export const selectContractPosting = createSelector(
  selectContract,
  contract => contract.posting
)
export const selectContractData = createSelector(
  selectContract,
  contract => contract.data
)
export const selectContractError = createSelector(
  selectContract,
  contract => contract.error
)
export const selectContractFormData = createSelector(
  selectContract,
  contract => {
    if (contract.data) {
      const data = { ...contract.data }
      if (data.custom_fields && data.custom_fields.length === 0) {
        data.custom_fields = null
      }
      delete data.invited_users
      return data
    }
    return null
  }
)
export const selectContractID = createSelector(
  selectContract,
  contract => contract.data && contract.data.id
)
export const selectContractStatus = createSelector(
  selectContract,
  contract => contract.data && contract.data.status
)
export const selectContractServices = createSelector(
  selectContract,
  contract => contract?.data?.services
)
export const selectContractRoomNumber = createSelector(
  selectContract,
  contract => contract.data && contract.data.room_number
)
export const selectContractRoomID = createSelector(
  selectContract,
  contract => contract.data && contract.data.room_id
)
export const selectContractProperty = createSelector(
  selectContract,
  contract => contract.data && contract.data.property_name
)
export const selectContractPropertyID = createSelector(
  selectContract,
  contract => contract.data && contract.data.property_id
)
export const selectContractorID = createSelector(
  selectContract,
  contract => contract.data && contract.data.contractor_user_id
)
export const selectContractorName = createSelector(
  selectContract,
  contract => contract.data && contract.data.contractor ? `${contract.data.contractor.last_name} ${contract.data.contractor.first_name}` : null
)
export const selectContractorFurigana = createSelector(
  selectContract,
  contract => contract.data && contract.data.contractor ? `${contract.data.contractor.furigana_last_name} ${contract.data.contractor.furigana_first_name}` : null
)
export const selectResidentID = createSelector(
  selectContract,
  contract => contract.data && contract.data.resident_user_id
)
export const selectContractDifferentUser = createSelector(
  selectContract,
  contract => contract.data && contract.data.different_resident
)
export const selectResidentName = createSelector(
  selectContract,
  contract => contract.data && contract.data.resident ? `${contract.data.resident.last_name} ${contract.data.resident.first_name}` : null
)
export const selectResidentFurigana = createSelector(
  selectContract,
  contract => contract.data && contract.data.resident ? `${contract.data.resident.furigana_last_name} ${contract.data.resident.furigana_first_name}` : null
)
export const selectInvitedUsers = createSelector(
  selectContract,
  contract => contract.data && contract.data.invited_users
)
export const selectFormAdditionalFees = createSelector(
  selectContractForm,
  contract => {
    const expanded = dot.object({ ...contract })
    if (expanded.rent_fees) {
      let fees = 0
      for (const fee of expanded.rent_fees) {
        if (fee.amount) {
          fees += Number(fee.amount)
        }
      }
      return fees
    }
    return 0
  }
)
export const selectLegacy = createSelector(
  selectContract,
  contract => contract.data && contract.data.legacy
)
export const selectRenewContractFormData = createSelector(
  selectContractFormData,
  selectRenewContract,
  (contract, renew) => {
    return {
      administrative_fees: renew?.administrative_fees || contract?.administrative_fees || null,
      auto_renewal: renew?.auto_renewal || contract?.auto_renewal || false,
      car_model_1: renew?.car_model_1 || contract?.car_model_1 || null,
      car_model_2: renew?.car_model_2 || contract?.car_model_2 || null,
      car_plate_number_1: renew?.car_plate_number_1 || contract?.car_plate_number_1 || null,
      car_plate_number_2: renew?.car_plate_number_2 || contract?.car_plate_number_2 || null,
      caution_money: renew?.caution_money || contract?.caution_money || null,
      contract_duration_unit: renew?.contract_duration_unit || contract?.contract_duration_unit || 1,
      contract_duration: renew?.contract_duration || contract?.contract_duration || null,
      contract_end: renew?.contract_end || null,
      contract_guarantor: contract?.contract_guarantor ? {
        type: contract.contract_guarantor.type,
        last_name: contract.contract_guarantor.last_name,
        first_name: contract.contract_guarantor.first_name,
        furigana_last_name: contract.contract_guarantor.furigana_last_name,
        furigana_first_name: contract.contract_guarantor.furigana_first_name,
        relation_id: contract.contract_guarantor.relation_id,
        birthday: contract.contract_guarantor.birthday,
        postcode: contract.contract_guarantor.postcode,
        prefecture: contract.contract_guarantor.prefecture,
        city: contract.contract_guarantor.city,
        address: contract.contract_guarantor.address,
        phone: contract.contract_guarantor.phone,
        mobile_phone: contract.contract_guarantor.mobile_phone,
        work: contract.contract_guarantor.work,
        work_postcode: contract.contract_guarantor.work_postcode,
        work_prefecture: contract.contract_guarantor.work_prefecture,
        work_city: contract.contract_guarantor.work_city,
        work_address: contract.contract_guarantor.work_address,
        work_phone: contract.contract_guarantor.work_phone,
        work_department: contract.contract_guarantor.work_department,
        work_position: contract.contract_guarantor.work_position,
        annual_income: contract.contract_guarantor.annual_income
      } : null,
      contract_sign_date: contract?.contract_sign_date || null,
      contract_start: renew?.contract_start || (contract?.contract_end && dayjs(contract.contract_end).add(1, 'day').format(SERVER_DATE_FORMAT)) || null,
      contractor: contract?.contractor ? {
        first_name: contract.contractor.first_name,
        last_name: contract.contractor.last_name,
        furigana_first_name: contract.contractor.furigana_first_name,
        furigana_last_name: contract.contractor.furigana_last_name,
        app_user_manage_id: contract.contractor.app_user_manage_id,
        mail_address: contract.contractor.mail_address,
        phone: contract.contractor.phone,
        emergency_phone: contract.contractor.emergency_phone,
        nationality: contract.contractor.nationality,
        residence_card: contract.contractor.residence_card,
        home_country_emergency_contact: contract.contractor.home_country_emergency_contact,
        occupation: contract.contractor.occupation,
        annual_income: contract.contractor.annual_income,
        birthday: contract.contractor.birthday,
        sex: contract.contractor.sex,
        postcode: contract.contractor.postcode || contract.property_postcode,
        prefecture: contract.contractor.prefecture || contract.property_prefecture,
        city: contract.contractor.city || contract.property_city,
        address: contract.contractor.address || `${contract.property_address} ${contract.property_name} ${contract.room_number}`,
        work: contract.contractor.work,
        work_postcode: contract.contractor.work_postcode,
        work_prefecture: contract.contractor.work_prefecture,
        work_city: contract.contractor.work_city,
        work_address: contract.contractor.work_address,
        work_phone: contract.contractor.work_phone,
        work_department: contract.contractor.work_department
      } : null,
      deposit: renew?.deposit || contract?.deposit || null,
      landlord_fee: renew?.landlord_fee || contract?.landlord_fee || null,
      landlord_name: renew?.landlord_name || contract?.landlord_name || null,
      landlord_address: renew?.landlord_address || contract?.landlord_address || null,
      link_label: renew?.link_label || null,
      link_url: renew?.link_url || null,
      remarks: renew?.remarks || null,
      renewal_administrative_fees: renew?.renewal_administrative_fees || contract?.renewal_administrative_fees || null,
      renewal_fees: renew?.renewal_fees || contract?.renewal_fees || null,
      renewal_notice: (contract?.renewal_notice && contract.renewal_notice.length > 0) ? contract.renewal_notice : null,
      renewal_period_end: renew?.renewal_period_end || null,
      renewal_period_start: renew?.renewal_period_start || null,
      rent_amount: renew?.rent_amount || contract?.rent_amount || null,
      rent_common_fees: renew?.rent_common_fees || contract?.rent_common_fees || null,
      rent_contract_account: renew?.rent_contract_account || contract?.rent_contract_account || null,
      rent_date: renew?.rent_date || contract?.rent_date || null,
      rent_fees: (renew?.rent_fees && renew.rent_fees.length > 0)
        ? renew.rent_fees
        : (contract?.rent_fees && contract.rent_fees.length > 0)
          ? contract.rent_fees
          : null,
      resident: contract?.resident ? {
        first_name: contract.resident.first_name,
        last_name: contract.resident.last_name,
        furigana_first_name: contract.resident.furigana_first_name,
        furigana_last_name: contract.resident.furigana_last_name,
        app_user_manage_id: contract.resident.app_user_manage_id,
        mail_address: contract.resident.mail_address,
        phone: contract.resident.phone,
        emergency_phone: contract.resident.emergency_phone,
        nationality: contract.resident.nationality,
        residence_card: contract.resident.residence_card,
        home_country_emergency_contact: contract.resident.home_country_emergency_contact,
        resident_relation_id: contract.resident.resident_relation_id,
        work: contract.resident.work,
        occupation: contract.resident.occupation,
        annual_income: contract.resident.annual_income,
        birthday: contract.resident.birthday,
        sex: contract.resident.sex
      } : null,
      roommates: (contract?.roommates && contract.roommates.length > 0) ? contract.roommates : null,
      template_id: renew?.template_id || null
    }
  }
)
export const selectRenewData = createSelector(
  selectRenewContract,
  contract => contract
)
export const selectCancelData = createSelector(
  selectCancelContract,
  contract => contract
)
