import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  useTranslation,
  selectQueryParams,
  RESET_PARAMS,
  dynamicLink,
  Card,
  Table,
  Link
} from '@gk-devteam/apmc-core-web'

import {
  selectContractsLoading,
  selectContractsCount,
  selectContractsData
} from '../../../selectors'
import { contractsSearchSchema } from '../../../validation'
import {
  FETCH_CONTRACTS,
  RESET_CONTRACTS
} from '../../../types'
import { USER_EMPTY_NAME } from '../../../constants'

import ContractSearch from './ContractSearch'

const RoomHistory = ({ roomID }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'contracts'])

  useEffect(() => {
    const schema = contractsSearchSchema()
    schema.isValid(query)
      .then(valid => {
        if (valid) {
          dispatch({
            type: FETCH_CONTRACTS,
            query: {
              ...query,
              room_id: roomID
            }
          })
        } else {
          console.warning('params invalid, query using default params')
          dispatch({ type: FETCH_CONTRACTS })
        }
      })
  }, [dispatch, query, roomID])
  useEffect(() => {
    return () => {
      dispatch({ type: RESET_CONTRACTS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const contractorCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/app_users/edit/${data.contractor_id}`}>{value || USER_EMPTY_NAME}</Link>
    },
    []
  )
  const residentCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/app_users/edit/${data.resident_id}`}>{value || USER_EMPTY_NAME}</Link>
    },
    []
  )

  const contractsColumns = [
    {
      title: t('contracts:contractor_name'),
      dataIndex: 'contractor_name',
      key: 'contractor_name',
      render: contractorCell
    },
    {
      title: t('contracts:resident_name'),
      dataIndex: 'resident_name',
      key: 'resident_name',
      render: residentCell
    },
    {
      title: t('contracts:contract_start_date'),
      dataIndex: 'contract_start_date',
      key: 'contract_start_date'
    },
    {
      title: t('contracts:contract_end_date'),
      dataIndex: 'contract_end_date',
      key: 'contract_end_date'
    },
    {
      title: t('contracts:move_out_date'),
      dataIndex: 'move_out_date',
      key: 'move_out_date'
    }
  ]
  const navigateToDetails = (id, row, e) => {
    const url = `/contracts/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <Card
      title="rooms:contract_history"
      mt="L"
    >
      <ContractSearch roomID={roomID}/>
      <Table
        columns={contractsColumns}
        rowKey="id"
        loadingSelector={selectContractsLoading}
        resultsCountSelector={selectContractsCount}
        dataSelector={selectContractsData}
        onRowClick={navigateToDetails}
        noLimit
        noHeader
        leftStickyColumnCount={0}
        rightStickyColumnCount={0}
      />
    </Card>
  )
}

export default RoomHistory

RoomHistory.propTypes = {
  roomID: PropTypes.string.isRequired
}
