export const FETCH_YASUE_MAINTENANCE_CONTACTS = 'fetch_yasue_maintenance_contacts'
export const FETCH_YASUE_MAINTENANCE_CONTACTS_SUCCESS = 'fetch_yasue_maintenance_contacts_success'
export const FETCH_YASUE_MAINTENANCE_CONTACTS_FAIL = 'fetch_yasue_maintenance_contacts_fail'
export const RESET_YASUE_MAINTENANCE_CONTACTS = 'reset_yasue_maintenance_contacts'

export const POST_YASUE_MAINTENANCE_CONTACTS = 'post_yasue_maintenance_contacts'
export const POST_YASUE_MAINTENANCE_CONTACTS_SUCCESS = 'post_yasue_maintenance_contacts_success'
export const POST_YASUE_MAINTENANCE_CONTACTS_FAIL = 'post_yasue_maintenance_contacts_fail'

export const FETCH_YASUE_MAINTENANCE_SHOWROOMS = 'fetch_yasue_maintenance_showrooms'
export const FETCH_YASUE_MAINTENANCE_SHOWROOMS_SUCCESS = 'fetch_yasue_maintenance_showrooms_success'
export const FETCH_YASUE_MAINTENANCE_SHOWROOMS_FAIL = 'fetch_yasue_maintenance_showrooms_fail'
export const RESET_YASUE_MAINTENANCE_SHOWROOMS = 'reset_yasue_maintenance_showrooms'

export const POST_YASUE_MAINTENANCE_SHOWROOMS = 'post_yasue_maintenance_showrooms'
export const POST_YASUE_MAINTENANCE_SHOWROOMS_SUCCESS = 'post_yasue_maintenance_showrooms_success'
export const POST_YASUE_MAINTENANCE_SHOWROOMS_FAIL = 'post_yasue_maintenance_showrooms_fail'
