import React from 'react'
import { useSelector } from 'react-redux'
import {
  Radio
} from '@gk-devteam/apmc-core-web'

import {
  NOTICE_USER_TYPE_OPTIONS
} from '../../../../constants'
import { selectNoticeEditable } from '../../../../selectors'

const NoticeUserType = () => {
  const recipientType = useSelector(state => state.form.recipient_type)
  const editable = useSelector(selectNoticeEditable)

  if (recipientType && recipientType === 2) return null

  return (
    <Radio
      noLabel
      autoH
      name="user_type"
      options={NOTICE_USER_TYPE_OPTIONS}
      readonly={!editable}
      classic
    />
  )
}

export default NoticeUserType

// NoticeUserType.propTypes = {}
