import React, { memo, useState, useCallback, useEffect } from 'react'
import { message } from 'antd'
import {
  useTranslation,
  Row,
  Spinner,
  ToastTitle,
  Text
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import { fetchFaqCategories } from '../../../services'

import {
  GenreListContainerStyle,
  GenreListHeaderStyle,
  GenreListItemStyle
} from './ImportsPage.style'

const HelpFaq = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState(null)

  const fetchCategories = useCallback(
    async () => {
      try {
        setLoading(true)
        const res = await fetchFaqCategories()
        if (res && res.data && res.data.results) {
          setCategories(res.data.results)
        } else {
          message.warning(t('import:help.faq_categories_fetch_error'))
        }
        setLoading(false)
      } catch (error) {
        message.warning(t('import:help.faq_categories_fetch_error'))
        setLoading(false)
      }
    },
    [t]
  )

  useEffect(() => {
    fetchCategories()
  }, [fetchCategories])

  const _renderCategoriesList = () => {
    if (loading) {
      return (
        <Row my="L" justify="center">
          <Spinner
            color="info"
            size="default"
            description={t('import:help.loading_faq_categories')}
          />
        </Row>
      )
    }
    if (!categories) return null
    return categories.map(category => {
      const { label, value } = category
      return (
        <GenreListItemStyle key={value}>
          <Text text={value}/>
          <Text text={label}/>
        </GenreListItemStyle>
      )
    })
  }

  return (
    <>
      <Row><ToastTitle label="import:help.about_faq_genre_id" /></Row>
      <Row mt="S" mb="ML"><Text label="import:help.about_faq_genre_id_text" /></Row>
      <GenreListContainerStyle>
        <GenreListHeaderStyle>
          <Text label="faq:faq_category_id"/>
          <Text label="faq:faq_category_name"/>
        </GenreListHeaderStyle>
        { _renderCategoriesList() }
      </GenreListContainerStyle>
    </>
  )
}

export default memo(HelpFaq)

HelpFaq.propTypes = {}
