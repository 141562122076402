export const FETCH_SERVICES = 'fetch_services'
export const FETCH_SERVICES_SUCCESS = 'fetch_services_success'
export const FETCH_SERVICES_FAIL = 'fetch_services_fail'
export const RESET_SERVICES = 'reset_services'

export const FETCH_SERVICE = 'fetch_service'
export const FETCH_SERVICE_SUCCESS = 'fetch_service_success'
export const FETCH_SERVICE_FAIL = 'fetch_service_fail'
export const RESET_SERVICE = 'reset_service'

export const FETCH_SERVICE_BY_LEVEL = 'fetch_service_by_level'
export const FETCH_SERVICE_BY_LEVEL_SUCCESS = 'fetch_service_by_level_success'
export const FETCH_SERVICE_BY_LEVEL_FAIL = 'fetch_service_by_level_fail'
export const RESET_SERVICE_BY_LEVEL = 'reset_service_by_level'

export const POST_SERVICE_DISPLAY = 'post_service_display'
export const POST_SERVICE_DISPLAY_SUCCESS = 'post_service_display_success'
export const POST_SERVICE_DISPLAY_FAIL = 'post_service_display_fail'

export const FETCH_SEIKATSU = 'fetch_seikatsu'
export const FETCH_SEIKATSU_SUCCESS = 'fetch_seikatsu_success'
export const FETCH_SEIKATSU_FAIL = 'fetch_seikatsu_fail'
export const RESET_SEIKATSU = 'reset_seikatsu'

export const TOGGLE_SEIKATSU = 'toggle_seikatsu'
export const TOGGLE_SEIKATSU_SUCCESS = 'toggle_seikatsu_success'
export const TOGGLE_SEIKATSU_FAIL = 'toggle_seikatsu_fail'

export const POST_CLOUDSIGN_CLIENT_ID = 'post_cloudsign_client_id'
export const POST_CLOUDSIGN_CLIENT_ID_SUCCESS = 'post_cloudsign_client_id_success'
export const POST_CLOUDSIGN_CLIENT_ID_FAIL = 'post_cloudsign_client_id_fail'
