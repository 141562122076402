import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row,
  Text,
  SideMenu,
  EmptyData
} from '@gk-devteam/apmc-core-web'

import { BULK_TOGGLE_CONTACTS } from '../../../../types'
import { PanelDisabledContext } from '../../../../components/Display/Panels/PanelDisabled/PanelDisabled'

import ContactHeader from './ContactHeader'
import PanelBlockHeader from '../../../../components/Display/Panels/PanelBlockHeader'
import {
  BlockStyle,
  CollapseStyle,
  PanelStyle
} from '../../panels.style'
import {
  ContactInfoContainerStyle,
  ContactInfoStyle
} from '../contacts.style'

const ContactsList = ({
  dataSelector,
  displaySelector,
  idSelector,
  title,
  type,
  handleContactDisplay,
  sideMenu,
  level
}) => {
  const contacts = useSelector(dataSelector)
  const disabled = useContext(PanelDisabledContext)

  const _renderList = () => {
    if (!contacts) return null
    if (contacts.length === 0) return <EmptyData />
    return contacts.map((contact, i) => {
      const { phone, mail_address, hours } = contact
      return (
        <PanelStyle
          header={(
            <ContactHeader
              item={contact}
              handleContactDisplay={handleContactDisplay}
              idSelector={idSelector}
            />
          )}
          key={`contact_${i}`}
        >
          <Row align="baseline">
            <ContactInfoContainerStyle wrap="wrap">
              <ContactInfoStyle>
                <Text label="phone" />
                <Text text={phone || '-'} />
              </ContactInfoStyle>
              <ContactInfoStyle>
                <Text label="email" />
                <Text text={mail_address || '-'} />
              </ContactInfoStyle>
              <ContactInfoStyle>
                <Text label="properties:contact_hours" />
                <Text text={hours || '-'} />
              </ContactInfoStyle>
            </ContactInfoContainerStyle>
            <SideMenu
              menu={sideMenu}
              data={contact}
            />
          </Row>
        </PanelStyle>
      )
    })
  }

  return (
    <BlockStyle>
      <PanelBlockHeader
        title={title}
        idSelector={idSelector}
        displaySelector={displaySelector}
        type={type}
        level={level}
        toggleType={BULK_TOGGLE_CONTACTS}
        authorized="contacts.functions.update"
      />
      <CollapseStyle
        bordered={false}
        expandIconPosition="right"
        disabled={disabled}
        // defaultActiveKey="contact_0"
      >
        { _renderList() }
      </CollapseStyle>

    </BlockStyle>
  )
}

export default ContactsList

ContactsList.propTypes = {
  dataSelector: PropTypes.func.isRequired,
  displaySelector: PropTypes.func.isRequired,
  idSelector: PropTypes.func,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['admin_contacts', 'property_contacts']).isRequired,
  level: PropTypes.oneOf(['admin', 'property', 'room', 'contract']).isRequired,
  handleContactDisplay: PropTypes.func.isRequired,
  sideMenu: PropTypes.array
}
