import { put, call, cancelled, takeLatest } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchRefund,
  updateRefundStatus
} from '../../services'

import {
  FETCH_REFUND_SUCCESS,
  FETCH_REFUND_FAIL,
  FETCH_REFUND,
  UPDATE_REFUND_STATUS,
  UPDATE_REFUND_STATUS_SUCCESS,
  UPDATE_REFUND_STATUS_FAIL
} from '../../types'
import { i18n } from '../../locales'

// const mock = {
//   id: 123,
//   property_id: 136775,
//   property_name: 'アルファコンフォート高松',
//   room_number: '201',
//   user_id: 123,
//   user_name: '山田太郎',
//   application_date: '2022-01-21 16:23',
//   status: 1,
//   parking: [
//     {
//       parking_date: '2022-01-14',
//       parking_name: '駐車場A',
//       amount: 1000,
//       file_url: 'https://placeimg.com/2000/2000'
//     },
//     {
//       parking_date: '2022-01-13',
//       parking_name: '駐車場B',
//       amount: 500,
//       file_url: 'https://placeimg.com/205/205'
//     },
//     {
//       parking_date: '2022-01-11',
//       parking_name: '駐車場A',
//       amount: 750,
//       file_url: 'https://placeimg.com/210/210'
//     }
//   ]
// }

export function * fetchRefundSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchRefund, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_REFUND_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_REFUND_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    // yield put({ type: FETCH_REFUND_SUCCESS, payload: mock })
    yield put({ type: FETCH_REFUND_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * updateRefundStatusSaga ({ data, id, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(updateRefundStatus, data, id, cancelSource)
    if (res?.status === 204) {
      if (successRedirect) yield call(successRedirect, id)
      yield put({ type: UPDATE_REFUND_STATUS_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: UPDATE_REFUND_STATUS_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: UPDATE_REFUND_STATUS_FAIL })
    yield call(message.error, i18n.t('post_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchRefund () {
  yield takeLatest(FETCH_REFUND, fetchRefundSaga)
  yield takeLatest(UPDATE_REFUND_STATUS, updateRefundStatusSaga)
}
