import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { dot } from 'dot-object'
import PropTypes from 'prop-types'
import {
  Row,
  Tag,
  CardTitle,
  TriStateCheckbox
} from '@gk-devteam/apmc-core-web'

import { selectPages } from '../../../../selectors'

import { PanelDisabledContext } from '../../../../components/Display/Panels/PanelDisabled'
import { QuestionStyle } from '../faq.style'

const FaqListItemHeader = ({
  item,
  categoryName,
  idSelector,
  handleFaqDisplay
}) => {
  const disabled = useContext(PanelDisabledContext)
  const emptySelector = () => null
  const id = useSelector(idSelector || emptySelector)
  const pages = useSelector(selectPages)
  const {
    question,
    display
    // owner_faq_id,
    // faq_id
  } = item

  if (!pages) return null

  const _handleDisplayChange = (value) => {
    handleFaqDisplay(value, id, item)
  }

  const _isDisabled = () => {
    const dotted = dot(pages)
    return !dotted['faq.functions.update']
  }

  return (
    <Row justify="between">
      <Row align="center">
        <TriStateCheckbox
          mr="L"
          mb="0"
          size="fluid"
          autoH
          noLabel
          value={display}
          handleChange={_handleDisplayChange}
          disabled={disabled || _isDisabled()}
        />
        <CardTitle text="Q" />
        <QuestionStyle text={question} />
      </Row>
      <Tag label={categoryName} color="info" />
    </Row>
  )
}

export default FaqListItemHeader

FaqListItemHeader.propTypes = {
  item: PropTypes.shape({
    question: PropTypes.string.isRequired,
    display: PropTypes.oneOf([0, 1, 2, true, false]).isRequired,
    owner_faq_id: PropTypes.number,
    faq_id: PropTypes.number
  }).isRequired,
  categoryName: PropTypes.string.isRequired,
  handleFaqDisplay: PropTypes.func.isRequired,
  idSelector: PropTypes.func
}
