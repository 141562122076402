import React, { memo, useState } from 'react'
// import PropTypes from 'prop-types'
import {
  PrimaryButton,
  Row
} from '@gk-devteam/apmc-core-web'

import UsersModal from './UsersModal'
import { useSelector } from 'react-redux'
import { selectCouponEditable } from '../../../../../selectors'

const UsersRecipients = () => {
  const [modaleVisible, setModaleVisible] = useState(false)
  const editable = useSelector(selectCouponEditable)
  const _openModal = () => {
    setModaleVisible(true)
  }
  const _closeModal = () => {
    setModaleVisible(false)
  }

  return (
    <Row mt="XS">
      {
        editable ? <PrimaryButton label="coupons:room_number_search" handleClick={_openModal} type="button" /> : null
      }
      <UsersModal
        visible={modaleVisible}
        close={_closeModal}
      />
    </Row>
  )
}

export default memo(UsersRecipients)
