import React, { memo, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useTranslation,
  UPDATE_FORM,
  REMOVE_REPEATER_ROWS,
  Card,
  TextInput
} from '@gk-devteam/apmc-core-web'

import { selectPropertyID } from '../../../selectors'
import {
  NoRoomStyle,
  RoomsContainerStyle
} from '../PropertyForm.style'

const PropertyRooms = () => {
  const dispatch = useDispatch()
  const roomCount = useSelector(state => state.form.room_count && Number(state.form.room_count))
  const propertyID = useSelector(selectPropertyID)
  const { t } = useTranslation()
  const prevCount = useRef(0)

  useEffect(() => {
    if (roomCount != null && !propertyID) {
      if (prevCount.current < roomCount) {
        const payload = {}
        for (let index = prevCount.current; index < roomCount; index++) {
          payload[`rooms[${index}].room_number`] = null
        }
        dispatch({ type: UPDATE_FORM, payload })
      } else if (prevCount.current > roomCount) {
        const indexes = []
        for (let index = roomCount; index < prevCount.current; index++) {
          indexes.push(index)
        }
        dispatch({ type: REMOVE_REPEATER_ROWS, payload: { indexes, key: 'rooms' } })
      }
      prevCount.current = roomCount
    }
  }, [roomCount, dispatch, propertyID])

  const _renderInputs = () => {
    const inputs = []
    if (!roomCount) {
      return (
        <NoRoomStyle>{ t('properties:room_count_null') }</NoRoomStyle>
      )
    }
    for (let i = 0; i < roomCount; i++) {
      inputs.push(
        <TextInput
          mr="0"
          mb="0"
          key={i}
          flex={1}
          type="text"
          name={`rooms[${i}].room_number`}
          label='properties:room_number'
          readonly={!!propertyID}
          placeholder=""
          required
        />
      )
    }
    return inputs
  }

  return (
    <Card title='properties:rooms_settings' mb="L">
      <RoomsContainerStyle>
        {
          _renderInputs()
        }
      </RoomsContainerStyle>
    </Card>
  )
}

export default memo(PropertyRooms)

PropertyRooms.propTypes = {}
