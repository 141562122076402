import React from 'react'
import { useSelector } from 'react-redux'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  useTranslation,
  Link,
  ToastTitle,
  Text
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { SERVICES_LEVEL } from '../../../../constants'
import { selectManageCompanyName } from '../../../../selectors'

import {
  DisabledCardStyle,
  MessageStyle
} from './PanelDisabled.style.js'

function DisabledMessageCard ({ hiddenBy, link }) {
  const { t } = useTranslation()
  const manageCompanyName = useSelector(selectManageCompanyName)
  const _renderMessage = () => {
    if (hiddenBy === SERVICES_LEVEL.MANAGE) {
      return (
        <MessageStyle mt="XS" wrap="wrap">
          <Text text={t('services:managed_service_help', { manageCompanyName })} />
        </MessageStyle>
      )
    }
    return (
      <MessageStyle mt="XS" wrap="wrap">
        <Text label="services:hidden.description_1" />
        <Link to={link}>{t('here')}</Link>
        <Text label="services:hidden.description_2" />
      </MessageStyle>
    )
  }

  return (
    <DisabledCardStyle mt="ML" align="baseline">
      <ExclamationCircleOutlined />
      <div>
        <ToastTitle label="services:hidden.title" />
        { _renderMessage() }
      </div>
    </DisabledCardStyle>
  )
}

DisabledMessageCard.propTypes = {
  hiddenBy: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired
}

export default DisabledMessageCard
