import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchFaq,
  fetchFaqCategories,
  postFaq,
  deleteFaq,
  toggleFaq,
  bulkToggleFaq
} from '../../services'
import {
  FETCH_FAQ,
  FETCH_FAQ_SUCCESS,
  FETCH_FAQ_FAIL,
  POST_FAQ,
  POST_FAQ_SUCCESS,
  POST_FAQ_FAIL,
  DELETE_FAQ,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAIL,
  TOGGLE_FAQ,
  TOGGLE_FAQ_SUCCESS,
  TOGGLE_FAQ_FAIL,
  BULK_TOGGLE_FAQ,
  BULK_TOGGLE_FAQ_SUCCESS,
  BULK_TOGGLE_FAQ_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchFaqSaga ({ config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchFaq, config, cancelSource)
    const categoriesRes = yield call(fetchFaqCategories, cancelSource)
    if (res && res.data && categoriesRes && categoriesRes.data && categoriesRes.data.results) {
      yield put({ type: FETCH_FAQ_SUCCESS, payload: { data: res.data, categories: categoriesRes.data.results } })
    } else {
      yield put({ type: FETCH_FAQ_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_FAQ_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postFaqSaga ({ data, config, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postFaq, data, config, cancelSource)
    if (res && res.data && res.data.success) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_FAQ_SUCCESS })
      yield put({ type: FETCH_FAQ, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_FAQ_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_FAQ_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * toggleFaqSaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(toggleFaq, data, config, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: TOGGLE_FAQ_SUCCESS })
      yield put({ type: FETCH_FAQ, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: TOGGLE_FAQ_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: TOGGLE_FAQ_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * bulkToggleFaqSaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(bulkToggleFaq, data, config, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: BULK_TOGGLE_FAQ_SUCCESS })
      yield put({ type: FETCH_FAQ, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: BULK_TOGGLE_FAQ_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: BULK_TOGGLE_FAQ_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteFaqSaga ({ id, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteFaq, id, config, cancelSource)
    if (res) {
      yield put({ type: DELETE_FAQ_SUCCESS })
      yield put({ type: FETCH_FAQ, config })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_FAQ_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchFaq () {
  yield takeLatest(FETCH_FAQ, fetchFaqSaga)
  yield takeLatest(POST_FAQ, postFaqSaga)
  yield takeLatest(DELETE_FAQ, deleteFaqSaga)
  yield takeLatest(TOGGLE_FAQ, toggleFaqSaga)
  yield takeLatest(BULK_TOGGLE_FAQ, bulkToggleFaqSaga)
}
