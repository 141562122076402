import React from 'react'
import { useSelector } from 'react-redux'
import dot from 'dot-object'
// import PropTypes from 'prop-types'

import RecipientsSelectedItem from './RecipientsSelectedItem'
import { ResultsContainer } from '../AppUsersDownloadLoginPdfForm.style'

function RecipientsSelected () {
  const selectedRecipients = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    return form.recipients_list
  })

  return (
    <ResultsContainer>
      {
        selectedRecipients?.map(recipient => <RecipientsSelectedItem key={recipient.id} id={recipient.id} label={recipient.label} />)
      }
    </ResultsContainer>
  )
}

RecipientsSelected.propTypes = {}

export default RecipientsSelected
