import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import { POST_MANUAL } from '../../../../types'
import { selectManualPosting } from '../../../../selectors'

import ManualForm from '../../../../forms/ManualForm/ManualForm'

const ManualModal = ({ visible, onCancel, type, id, editMode }) => {
  const dispatch = useDispatch()
  const posting = useSelector(selectManualPosting)

  const _handleSubmit = (data) => {
    const onSuccess = () => {
      onCancel()
    }
    dispatch({ type: POST_MANUAL, data, config: { [type]: id }, onSuccess })
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={_handleSubmit}
      okText="save"
      title={editMode ? 'manuals:edit_manual' : 'manuals:add_manual'}
      formID="manual_form"
      posting={posting}
      fullWidth
    >
      <ManualForm handleSubmit={_handleSubmit}/>
    </Modal>
  )
}

export default ManualModal

ManualModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['property', 'room']),
  id: PropTypes.string
}
