import { api, DEFAULT_QUERY_LIMIT, getFileFromURL, MULTIPART_HEADERS } from '@gk-devteam/apmc-core-web'

export const fetchInspections = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'next_inspection_date',
    sort_order: sort_order || 'desc'
  }

  return api.get('/inspection', {
    cancelToken: cancelSource.token,
    params: {
      ...params,
      ...defaultParams
    }
  })
}
export const fetchContractInspections = (contract_id, cancelSource) => {
  return api.get(`/inspection/${contract_id}`, {
    cancelToken: cancelSource.token
  })
}

export const downloadInspectionDocument = (contract_id, inspection_id, cancelSource) => {
  return api.get(`/inspection/${contract_id}/download/${inspection_id}`, {
    cancelToken: cancelSource.token,
    responseType: 'blob'
  })
}

export const postInspection = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }

    delete data.file
    delete data.file_filename

    formData.append('data', JSON.stringify(data))
    return api.post('/inspection', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const deleteInspection = (contract_id, inspection_id, cancelSource) => {
  return api.delete(`/inspection/${contract_id}/${inspection_id}`, {
    cancelToken: cancelSource.token
  })
}
