import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  useTranslation,
  Table,
  Link,
  PrimaryButton
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import {
  selectCouponHistoryCount,
  selectCouponHistoryData,
  selectLoadingCoupon
} from '../../../../selectors'
import { OPEN_COUPON_STATUS_MODAL } from '../../../../types'

const CouponHistory = ({ couponID }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const propertyCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/contracts/edit/${data.contract_id}`}>{value || ''}</Link>
    },
    []
  )
  const CouponModalCell = (value, cellKey, rowKey, className, data) => {
    return (
      <PrimaryButton
        type="button"
        label="coupons:history"
        handleClick={() => _handleDetailsClick(data)}
      />
    )
  }

  const _handleDetailsClick = (data) => {
    const couponData = {
      couponID,
      contract_data: data
    }
    dispatch({ type: OPEN_COUPON_STATUS_MODAL, payload: couponData })
  }

  const couponHistoryColumns = [
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number',
      render: propertyCell
    },
    {
      title: t('coupons:last_used_at'),
      dataIndex: 'used_at',
      key: 'used_at'
    },
    {
      title: t('coupons:history'),
      dataIndex: 'used_at',
      key: 'index',
      render: CouponModalCell
    }
  ]

  return (
    <Table
      columns={couponHistoryColumns}
      rowKey="id"
      loadingSelector={selectLoadingCoupon}
      dataSelector={selectCouponHistoryData}
      resultsCountSelector={selectCouponHistoryCount}
      noLimit
      noResults
      leftStickyColumnCount={0}
      rightStickyColumnCount={0}
    />
  )
}

export default CouponHistory

CouponHistory.propTypes = {
  couponID: PropTypes.string
}
