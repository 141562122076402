import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import { message, Tabs as AntTab } from 'antd'
import dayjs from 'dayjs'
import { CancelToken } from 'axios'
import PropTypes from 'prop-types'
import {
  Row,
  Title,
  Card,
  InquiryID,
  FormSubmitButton,
  Form,
  TextInput,
  TextArea,
  RangePicker,
  Tabs,
  useTranslation,
  Tag,
  UUID,
  RoleControlled,
  DangerButton,
  InfoButton
} from '@gk-devteam/apmc-core-web'

import {
  selectSurveyLoading,
  selectSurveyPosting,
  selectSurveyEditable,
  selectSurveyHasAnswers
} from '../../selectors'
import { surveyUpdateSchema } from '../../validation'
import { FETCH_SURVEY, POST_SURVEY } from '../../types'
import { INQUIRY_PREFIX, SURVEY_RECIPIENT_TYPE, SURVEY_STATUS } from '../../constants'
import { updateSurveyStatus } from '../../services'

import PanelInfo from '../../components/Display/Panels/PanelInfo/PanelInfo'
import RecipientsSettings from './RecipientsSettings/RecipientsSettings'
import Questions from './Questions/Questions'
import Answers from './Answers/Answers'

import { ButtonsStyle } from './SurveyForm.style.js'

const { TabPane } = AntTab

const saveButtonID = 'save'
const publishButtonID = 'publish'

const SurveyForm = ({ data, surveyID }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const editable = useSelector(selectSurveyEditable)
  const hasAnswers = useSelector(selectSurveyHasAnswers)
  const [loading, setLoading] = useState(false)

  const initialValues = {
    ...data,
    start_time: data?.start_time || new Date(),
    recipients_id: data?.recipients_id || data?.recipients?.map(recipient => recipient.recipient_id) || null,
    recipients_list: data?.recipients_list || data?.recipients?.map(recipient => ({
      id: recipient.recipient_id,
      label: recipient.recipient_name
    })),
    questions: data?.questions && Array.isArray(data?.questions)
      ? data.questions
        .map(question => ({
          uuid: UUID(),
          ...question
        }))
        .sort((a, b) => a.display_order - b.display_order)
      : null
  }

  if (data?.recipient_type === SURVEY_RECIPIENT_TYPE.ALL) {
    initialValues.recipients_list = null
  }

  const handleFormSubmit = (raw) => {
    const buttonID = document.activeElement.id
    // console.log(buttonID)
    // console.log('raw', raw)
    const data = { ...raw }
    const questions = raw.questions.map((question, index) => ({
      id: question?.id,
      label: question?.label,
      type: question?.type,
      required: question?.required || false,
      max_length: question?.max_length,
      options: question?.options,
      display_order: index
    }))
    data.questions = questions
    data.recipient_ids = raw.recipients_id
    if (raw.recipient_type === SURVEY_RECIPIENT_TYPE.ALL) data.recipient_ids = null
    switch (buttonID) {
      case publishButtonID:
        data.status = SURVEY_STATUS.PUBLISHED
        break
      case saveButtonID:
        data.status = SURVEY_STATUS.UNPUBLISHED
        break
      default:
        break
    }

    delete data.editable
    delete data.recipients
    delete data.recipients_list
    delete data.recipients_id

    // console.log('data', data)
    const successRedirect = (id) => {
      navigate('/surveys')
    }
    dispatch({ type: POST_SURVEY, data, successRedirect })
  }

  const updateSurveyPublishStatus = async (status) => {
    try {
      const cancelSource = CancelToken.source()
      setLoading(true)
      const res = await updateSurveyStatus({ survey_id: surveyID, status }, cancelSource)
      if (res.status === 204) {
        message.success(t('post_success'))
        dispatch({ type: FETCH_SURVEY, id: surveyID })
      }
    } catch (error) {
      // console.warn('status update error', error)
      message.error(t('post_error'))
    } finally {
      setLoading(false)
    }
  }

  const _publishSurvey = () => {
    updateSurveyPublishStatus(SURVEY_STATUS.PUBLISHED)
  }
  const _unpublishSurvey = () => {
    updateSurveyPublishStatus(SURVEY_STATUS.UNPUBLISHED)
  }

  const _renderSurveyStatus = () => {
    if (!surveyID) return null
    let label, color
    switch (data?.status) {
      case SURVEY_STATUS.UNPUBLISHED:
        label = 'surveys:statuses.unpublished'
        color = 'danger'
        break
      case SURVEY_STATUS.PUBLISHED:
        label = 'surveys:statuses.published'
        color = 'info'
        break
      case SURVEY_STATUS.EXPIRED:
        label = 'surveys:statuses.expired'
        color = 'text'
        break
      default:
        break
    }

    return (
      <Row mb="XS">
        <Tag label={label} color={color}/>
        {data?.end_time && dayjs().isAfter(dayjs(data.end_time)) && <Tag label="surveys:statuses.expired" color="text" />}
      </Row>
    )
  }

  return (
    <Form
      id="updateSurveyForm"
      handleSubmit={handleFormSubmit}
      validationSchema={surveyUpdateSchema}
      loadingSelector={selectSurveyLoading}
      postingSelector={selectSurveyPosting}
      initialData={initialValues}
      authorized={editable}
      ignoreDirty
      // debug
    >
      <Row justify="between" >
        {
          surveyID
            ? <Title text={data && data.title} />
            : <Title label='surveys:add_survey' />
        }
        <RoleControlled authorized={!editable && data?.status === SURVEY_STATUS.PUBLISHED}>
          <DangerButton type="button" handleClick={_unpublishSurvey} label="surveys:unpublish_survey" loading={loading} />
        </RoleControlled>
        <RoleControlled authorized={!editable && data?.status === SURVEY_STATUS.UNPUBLISHED}>
          <InfoButton type="button" handleClick={_publishSurvey} label="surveys:publish_survey" reverse loading={loading} />
        </RoleControlled>
        <RoleControlled authorized={editable}>
          <ButtonsStyle>
            <FormSubmitButton id={saveButtonID} label={surveyID ? 'surveys:save_survey' : 'surveys:save_survey'} postingSelector={selectSurveyPosting} withMargin ignoreDirty noReverse />
            <FormSubmitButton id={publishButtonID} label="surveys:publish_survey" postingSelector={selectSurveyPosting} withMargin ignoreDirty />
          </ButtonsStyle>
        </RoleControlled>
      </Row>
      { _renderSurveyStatus() }
      <Row mb="ML">
        <InquiryID id={surveyID} prefix={INQUIRY_PREFIX.SURVEY} />
      </Row>
      <RoleControlled authorized={!editable}>
        <PanelInfo
          infoTitle="surveys:not_editable_title"
          info ="surveys:not_editable"
        />
      </RoleControlled>
      <Tabs>
        <TabPane key="1" tab={t('surveys:main_settings')}>
          <Row wrap="wrap">
            <Card mb="L">
              <Row wrap="wrap" align="baseline">
                <TextInput
                  flex={1}
                  type="text"
                  name="title"
                  label='surveys:title'
                  placeholder=""
                  required
                />
              </Row>
              <Row wrap="wrap" align="baseline">
                <TextArea
                  flex={1}
                  name="description"
                  label='surveys:description'
                  placeholder=""
                  maxLength={5000}
                  required
                />
              </Row>
              <Row>
                <RangePicker
                  name_start="start_time"
                  name_end="end_time"
                  label='surveys:duration'
                  placeholder=""
                  showTime
                  required
                />
              </Row>
            </Card>
            <RecipientsSettings />
          </Row>
        </TabPane>
        <TabPane key="2" tab={t('surveys:questions')}>
          <Questions />
        </TabPane>
        {
          hasAnswers && (
            <TabPane key="3" tab={t('surveys:answers')}>
              <Answers />
            </TabPane>
          )
        }
      </Tabs>
    </Form>
  )
}
export default SurveyForm

SurveyForm.propTypes = {
  data: PropTypes.object,
  surveyID: PropTypes.string
}
SurveyForm.defaultProps = {

}
