import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  error: null,
  data: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_DASHBOARD]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_DASHBOARD_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_DASHBOARD_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  }
})
