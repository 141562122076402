import React, { useCallback } from 'react'
import dayjs from 'dayjs'
import { Tooltip } from 'antd'
import { useNavigate } from '@reach/router'
import {
  useTranslation,
  Table,
  Link,
  Tag,
  Text,
  SPACING,
  SERVER_TIMESTAMP_FORMAT,
  getTimeElapsed,
  dynamicLink
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import {
  selectLoadingAppUser,
  selectChatHistoryData
} from '../../../selectors'
import { USER_TYPES, SUPPORT_STATUS } from '../../../constants'

const ChatHistory = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const propertyCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/properties/edit/${data.propertyID}`}>{value || ''}</Link>
    },
    []
  )
  const contractCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/contracts/edit/${data.contractID}`}>{t('app_users:to_contract_page')}</Link>
    },
    [t]
  )
  const userTypeCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      let label, color
      switch (data.userType) {
        case USER_TYPES.RESIDENT_CONTRACTOR:
          label = 'user_type.resident_contractor'
          color = 'accent'
          break
        case USER_TYPES.CONTRACTOR:
          label = 'user_type.contractor'
          color = 'info'
          break
        case USER_TYPES.RESIDENT:
          label = 'user_type.resident'
          color = 'success'
          break

        default:
          label = ''
          color = 'text'
          break
      }
      return <Tag label={label} color={color} />
    },
    []
  )
  const statusCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      let label, color
      switch (data.managementStatus) {
        case SUPPORT_STATUS.UNDEFINED:
          label = 'admin_tasks:status.undefined'
          color = 'pageTitle'
          break
        case SUPPORT_STATUS.UNUSED:
          label = 'admin_tasks:status.is_unused'
          color = 'text'
          break
        case SUPPORT_STATUS.COMPLETED:
          label = 'admin_tasks:status.is_completed'
          color = 'success'
          break
        case SUPPORT_STATUS.CONFIRMED:
          label = 'admin_tasks:status.is_confirmed'
          color = 'info'
          break
        case SUPPORT_STATUS.WAITING:
          label = 'admin_tasks:status.is_waiting'
          color = 'danger'
          break
        default:
          break
      }

      return <Tag label={label} color={color} />
    },
    []
  )
  const elapsedCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const { lastUpdated } = data
      return (
        <Tooltip
          title={dayjs.unix(lastUpdated.seconds).format(SERVER_TIMESTAMP_FORMAT)}
          overlayStyle={{
            fontSize: `${SPACING.SMALL}rem`
          }}
          placement="top"
        >
          <Text text={getTimeElapsed(dayjs.unix(lastUpdated.seconds))} />
        </Tooltip>
      )
    },
    []
  )

  const chatHistoryColumns = [
    {
      title: t('properties:property_name'),
      dataIndex: 'propertyName',
      key: 'propertyName',
      render: propertyCell
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'roomNumber',
      key: 'roomNumber'
    },
    {
      title: t('navigation:contracts'),
      dataIndex: 'contract',
      key: 'contract',
      render: contractCell
    },
    {
      title: t('admin_tasks:user_type'),
      dataIndex: 'isResident',
      key: 'isResident',
      render: userTypeCell
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: statusCell
    },
    {
      title: t('elapsed'),
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: elapsedCell
    }

  ]

  const _handleRowClick = (id, row, e) => {
    const url = `/admin_tasks/chat/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <Table
      columns={chatHistoryColumns}
      rowKey="id"
      loadingSelector={selectLoadingAppUser}
      dataSelector={selectChatHistoryData}
      onRowClick={_handleRowClick}
      noLimit
      noResults
      noFooter
      leftStickyColumnCount={0}
      rightStickyColumnCount={0}
    />
  )
}

export default ChatHistory

// ContractHistory.propTypes = {}
