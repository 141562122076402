import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  RESET_FORM,
  INIT_FORM
} from '@gk-devteam/apmc-core-web'

import { selectContactsHiddenBy, selectContactsLoading } from '../../../../selectors'
import { FETCH_CONTACTS, RESET_CONTACTS } from '../../../../types'
import initialData from '../common/initialData'

import AdminContactsList from './AdminContactsList'
import ContactModal from '../common/ContactModal'
import PanelLayout from '../../../../components/Display/Panels/PanelLayout'

const AdminContactsPage = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectContactsLoading)
  const [modalVisible, setModalVisible] = useState(false)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    dispatch({ type: FETCH_CONTACTS, config: { } })
    return () => {
      dispatch({ type: RESET_CONTACTS })
    }
  }, [dispatch])

  const breadcrumbs = [
    {
      label: 'navigation:settings',
      link: '/settings'
    },
    {
      label: 'navigation:settings_contacts',
      link: '/settings/contacts'
    }
  ]

  const _openAddModal = () => {
    dispatch({ type: RESET_FORM })
    dispatch({ type: INIT_FORM, payload: initialData })
    setModalVisible(true)
  }
  const _openCopyModal = () => {
    setModalVisible(true)
  }
  const _openEditModal = () => {
    setModalVisible(true)
    setEditMode(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
    setEditMode(false)
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectContactsHiddenBy}
      title="navigation:settings_contacts"
      authorized="contacts.functions.add"
      info="services:level_message.admin"
      infoTitle="services:level_message.adminTitle"
      handleAddClick={_openAddModal}
    >
      <ContactModal
        visible={modalVisible}
        editMode={editMode}
        onCancel={_closeModal}
      />
      <AdminContactsList copyModal={_openCopyModal} editModal={_openEditModal} />
    </PanelLayout>
  )
}
export default AdminContactsPage

AdminContactsPage.propTypes = {}
AdminContactsPage.defaultProps = {}
