import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { i18n } from '../../locales'
import { CancelToken } from 'axios'

import {
  fetchRefuseCalendar,
  fetchCalendars,
  selectCalendar,
  postCalendar,
  postReport,
  deleteCalendar
} from '../../services'
import {
  FETCH_REFUSE_CALENDAR,
  FETCH_REFUSE_CALENDAR_SUCCESS,
  FETCH_REFUSE_CALENDAR_FAIL,
  FETCH_REFUSE_CALENDARS,
  FETCH_REFUSE_CALENDARS_SUCCESS,
  FETCH_REFUSE_CALENDARS_FAIL,
  SELECT_CALENDAR,
  SELECT_CALENDAR_SUCCESS,
  SELECT_CALENDAR_FAIL,
  POST_CALENDAR,
  POST_CALENDAR_SUCCESS,
  POST_CALENDAR_FAIL,
  REPORT_CALENDAR,
  REPORT_CALENDAR_SUCCESS,
  REPORT_CALENDAR_FAIL,
  DELETE_CALENDAR,
  DELETE_CALENDAR_SUCCESS,
  DELETE_CALENDAR_FAIL
} from '../../types'

export function * fetchCalendarSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchRefuseCalendar, id, cancelSource)
    if (res && res.data && res.data) {
      yield put({ type: FETCH_REFUSE_CALENDAR_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_REFUSE_CALENDAR_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_REFUSE_CALENDAR_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * fetchCalendarsSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchCalendars, id, cancelSource)
    if (res && res.data && res.data) {
      yield put({ type: FETCH_REFUSE_CALENDARS_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_REFUSE_CALENDARS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_REFUSE_CALENDARS_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * selectCalendarSaga ({ data, id, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(selectCalendar, data, id, cancelSource)
    if (res && res.status === 204) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: SELECT_CALENDAR_SUCCESS })
      yield put({ type: FETCH_REFUSE_CALENDAR, id })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: SELECT_CALENDAR_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: SELECT_CALENDAR_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * postCalendarSaga ({ data, id, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postCalendar, data, id, cancelSource)
    if (res && res.status === 204) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_CALENDAR_SUCCESS })
      yield put({ type: FETCH_REFUSE_CALENDAR, id })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CALENDAR_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CALENDAR_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * reportCalendarSaga ({ data, id, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postReport, data, id, cancelSource)
    if (res && res.status === 204) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: REPORT_CALENDAR_SUCCESS })
      yield put({ type: FETCH_REFUSE_CALENDAR, id })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: REPORT_CALENDAR_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: REPORT_CALENDAR_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteCalendarSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteCalendar, id, cancelSource)
    if (res) {
      yield put({ type: DELETE_CALENDAR_SUCCESS })
      yield put({ type: FETCH_REFUSE_CALENDAR, id })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_CALENDAR_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchRefuse () {
  yield takeLatest(FETCH_REFUSE_CALENDAR, fetchCalendarSaga)
  yield takeLatest(FETCH_REFUSE_CALENDARS, fetchCalendarsSaga)
  yield takeLatest(SELECT_CALENDAR, selectCalendarSaga)
  yield takeLatest(POST_CALENDAR, postCalendarSaga)
  yield takeLatest(REPORT_CALENDAR, reportCalendarSaga)
  yield takeLatest(DELETE_CALENDAR, deleteCalendarSaga)
}
