import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useTranslation,
  Table,
  TOGGLE_ARRAY_ITEM,
  CheckboxStandAlone,
  UPDATE_FORM_ARRAY
} from '@gk-devteam/apmc-core-web'
import dot from 'dot-object'

import {
  selectContractsLoading,
  selectContractsData
} from '../../../../../selectors'

import UserCheckmark from './UserCheckmark'
import { CheckmarkCellStyle, TableContainerStyle } from '../../../CouponForm.style'

const UsersList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const contractsData = useSelector(selectContractsData)
  const selectedContracts = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    return form.recipients_list
  })

  const _handleAllCheck = () => {
    if (contractsData?.length === selectedContracts?.length) {
      dispatch({
        type: UPDATE_FORM_ARRAY,
        payload: [
          {
            name: 'recipients_id',
            values: null
          },
          {
            name: 'recipients_list',
            values: null
          }
        ]
      })
    } else {
      dispatch({
        type: UPDATE_FORM_ARRAY,
        payload: [
          {
            name: 'recipients_id',
            values: null
          },
          {
            name: 'recipients_list',
            values: null
          }
        ]
      })
      const ids = []
      const properties = []
      for (const contract of contractsData) {
        const { id, room_number } = contract
        ids.push(id)
        properties.push({ id, label: room_number })
      }
      dispatch({
        type: UPDATE_FORM_ARRAY,
        payload: [
          {
            name: 'recipients_id',
            values: ids
          },
          {
            name: 'recipients_list',
            values: properties
          }
        ]
      })
    }
  }

  const checkmarkTitleCell = () => {
    return (
      <CheckmarkCellStyle>
        <CheckboxStandAlone
          name="allCheck"
          checked={contractsData?.length === selectedContracts?.length}
          handleChange={_handleAllCheck}
          noLabel
          autoH
          size="fluid"
          mb="0"
        />
      </CheckmarkCellStyle>
    )
  }

  const usersColumns = [
    {
      title: checkmarkTitleCell,
      dataIndex: '',
      key: 'selected_user',
      RenderComponent: UserCheckmark
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number',
      cellTitle: true
    },
    {
      title: t('rooms:room_id'),
      dataIndex: 'room_id',
      key: 'room_id'
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name'
    },
    {
      title: t('properties:property_id'),
      dataIndex: 'property_id',
      key: 'property_id'
    }
  ]

  const _handleDisableRow = useCallback(
    (data) => {
      return data && data.app_usable === false
    },
    []
  )

  const _handleRowClick = (_, row, e) => {
    const { id, room_number } = row
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [
        {
          key: 'recipients_list',
          value: { id, label: room_number },
          objectComparatorKey: 'id'
        },
        {
          key: 'recipients_id',
          value: id
        }
      ]
    })
  }

  return (
    <TableContainerStyle>
      <Table
        columns={usersColumns}
        rowKey="id"
        loadingSelector={selectContractsLoading}
        dataSelector={selectContractsData}
        onRowClick={_handleRowClick}
        disableRow={_handleDisableRow}
        leftStickyColumnCount={0}
        rightStickyColumnCount={0}
        noHeader
        noFooter
        noLoader
      />
    </TableContainerStyle>
  )
}

export default memo(UsersList)

// UsersList.propTypes = {}
