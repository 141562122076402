/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const forgotPasswordSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    mail_address: yup
      .string()
      .email()
      .required()
      .label(i18n.t('common:email'))
  })
}
