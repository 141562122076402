import { createSelector } from 'reselect'

const selectChouei = state => state.chouei
const selectForm = state => state.form

export const selectChoueiBillingPosting = createSelector(
  selectChouei,
  chouei => chouei.posting
)

export const selectChoueiBillingError = createSelector(
  selectChouei,
  chouei => chouei.error
)

export const selectBillingFormFile = createSelector(
  selectForm,
  form => form?.billing_file || null
)
