import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const chatPostMessageValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    file: yup.string().nullable().isBlob().required().label(i18n.t('common:file'))
  })
}
