import { createSelector } from 'reselect'

const selectNotice = state => state.notice

export const selectLoadingNotice = createSelector(
  selectNotice,
  notice => notice.loading
)
export const selectPostingNotice = createSelector(
  selectNotice,
  notice => notice.posting
)
export const selectNoticeData = createSelector(
  selectNotice,
  notice => notice.data || {}
)
export const selectNoticeError = createSelector(
  selectNotice,
  notice => notice.error
)
export const selectNoticeFormData = createSelector(
  selectNotice,
  notice => {
    const data = { ...notice.data }

    delete data.read_count
    delete data.unread_count
    delete data.sent_count
    delete data.recipients

    return data
  }
)
export const selectNoticeID = createSelector(
  selectNotice,
  notice => notice.data && notice.data.id
)
export const selectNoticeEditable = createSelector(
  selectNotice,
  notice => notice.editable
)
export const selectNoticeConditions = createSelector(
  selectNotice,
  notice => notice.recipients_condition
)
export const selectStoppingNotice = createSelector(
  selectNotice,
  notice => notice.stopping
)
