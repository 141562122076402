import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
  FONT_SIZE,
  Text,
  InfoButton
} from '@gk-devteam/apmc-core-web'

export const ImportProgressContainerStyle = styled.div`
  ${BORDER_RADIUS};
  z-index: 100;
  padding: ${SPACING.REGULAR}rem ${SPACING.MEDIUM_LARGE}rem;
  line-height: 1.5715;
  position: fixed;
  overflow: hidden;
  background: ${props => getColor(props, 'white')};
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  bottom: ${SPACING.LARGE}rem;
  left: ${SPACING.LARGE}rem;
  width: 300px;
`

export const ImportProgressBarContainerStyle = styled.div`
  width: 100%;
`

export const MessageStyle = styled(Text)`
  ${FONT_SIZE.SMALL}
`

export const CloseButtonStyle = styled.div`
  ${FONT_SIZE.MEDIUM}
  position: absolute;
  right: 4px;
  top: 4px;
  color: ${props => getColor(props, 'danger')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const ConfirmButtonStyle = styled(InfoButton)`
  ${FONT_SIZE.SMALL}
  padding-left: ${SPACING.REGULAR}rem;
  padding-right: ${SPACING.REGULAR}rem;
`
