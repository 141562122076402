import { createSelector } from 'reselect'

const selectDashboard = state => state.dashboard

export const selectDashboardLoading = createSelector(
  selectDashboard,
  dashboard => dashboard.loading
)
export const selectDashboardData = createSelector(
  selectDashboard,
  dashboard => dashboard.data
)
export const selectDashboardChart = createSelector(
  selectDashboard,
  dashboard => dashboard.data && dashboard.data.chart
)
