import React, { memo, useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  useTranslation,
  Row,
  Title,
  PrimaryButton,
  DangerButton,
  Confirm,
  RoleControlled,
  DeleteButtonStyle
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyName,
  selectRefuseIssue,
  selectCurrentCalendar,
  selectRefuseLoading,
  selectRefuseHiddenBy,
  selectRefuseHiddenById
} from '../../../../selectors'
import { FETCH_REFUSE_CALENDAR, RESET_REFUSE_CALENDAR, DELETE_CALENDAR } from '../../../../types/panels/refuseTypes'

import CurrentCalendar from './CurrentCalendar'
import CalendarModal from './CalendarModal'
import ReportModal from './ReportModal'
import PanelLayout from '../../../../components/Display/Panels/PanelLayout'

const PropertyRefusePage = ({ propertyID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const loading = useSelector(selectRefuseLoading)
  const propertyName = useSelector(selectPropertyName)
  const currentCalendar = useSelector(selectCurrentCalendar)
  const hasIssue = useSelector(selectRefuseIssue)
  const [reportModalVisible, setReportModalVisible] = useState(false)
  const [calendarModalVisible, setCalendarModalVisible] = useState(false)

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_refuse',
      link: `/properties/edit/${propertyID}/refuse`
    }
  ]

  useEffect(() => {
    dispatch({ type: FETCH_REFUSE_CALENDAR, id: propertyID })
    return () => {
      dispatch({ type: RESET_REFUSE_CALENDAR })
    }
  }, [dispatch, propertyID])

  const _closeReportModal = useCallback(
    () => {
      setReportModalVisible(false)
    },
    []
  )
  const _closeCalendarModal = useCallback(
    () => {
      setCalendarModalVisible(false)
    },
    []
  )

  const _handleReportClick = () => {
    setReportModalVisible(true)
  }
  const _handleCalendarClick = () => {
    setCalendarModalVisible(true)
  }
  const _handleRemoveCalendar = () => {
    dispatch({ type: DELETE_CALENDAR, id: propertyID })
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectRefuseHiddenBy}
      hiddenByIdSelector={selectRefuseHiddenById}
      propertyID={propertyID}
      noLegend
    >
      <ReportModal visible={reportModalVisible} onCancel={_closeReportModal} id={propertyID} />
      <CalendarModal visible={calendarModalVisible} onCancel={_closeCalendarModal} id={propertyID} />
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:properties_refuse"/>
        <div>
          {
            currentCalendar
              ? (
                <RoleControlled authorized="refuse.functions.delete">
                  <Confirm
                    title='delete_confirm'
                    placement="topRight"
                    onConfirm={_handleRemoveCalendar}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  >
                    <DeleteButtonStyle reverse>{t('refuse:remove_calendar')}</DeleteButtonStyle>
                  </Confirm>
                </RoleControlled>

              )
              : null
          }
          <RoleControlled authorized="refuse.functions.reportCalendar">

            <DangerButton
              label="refuse:report_error"
              handleClick={_handleReportClick}
              withMargin
              disabled={hasIssue}
              help={t(hasIssue ? 'refuse:report_disabled_tooltip' : 'refuse:report_active_tooltip', { companyName: 'GoldKey' })}
            />

          </RoleControlled>
          <RoleControlled authorized="refuse.functions.add">
            <PrimaryButton
              label="refuse:add_calendar"
              handleClick={_handleCalendarClick}
              reverse
              withMargin
            />
          </RoleControlled>
        </div>
      </Row>
      <CurrentCalendar />
    </PanelLayout>
  )
}
export default memo(PropertyRefusePage)

PropertyRefusePage.propTypes = {
  propertyID: PropTypes.string.isRequired
}
PropertyRefusePage.defaultProps = {}
