import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Link, Modal, Table, useTranslation } from '@gk-devteam/apmc-core-web'

import { FETCH_SURVEY_ANSWER_USERS, RESET_SURVEY_ANSWER_USERS } from '../../../types'
import { selectSurveyAnswerUsers, selectSurveyAnswerUsersLoading } from '../../../selectors'

function ResultModal ({ visible, close, answer, ids }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (visible && ids) {
      dispatch({ type: FETCH_SURVEY_ANSWER_USERS, answer_ids: ids })
    }
    return () => {
      dispatch({ type: RESET_SURVEY_ANSWER_USERS })
    }
  }, [visible, ids, dispatch])

  const propertyCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/properties/edit/${value}`}>{data.property_name}</Link>
    },
    []
  )
  const roomCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/rooms/edit/${value}`}>{data.room_number}</Link>
    },
    []
  )
  const contractCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/contracts/edit/${value}`}>{t('app_users:to_contract_page')}</Link>
    },
    [t]
  )
  const userCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/app_users/edit/${value}`}>{data.app_user_name}</Link>
    },
    []
  )

  const columns = [
    {
      title: t('properties:property_name'),
      dataIndex: 'property_id',
      key: 'property_id',
      render: propertyCell
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_id',
      key: 'room_id',
      render: roomCell
    },
    {
      title: t('navigation:contracts'),
      dataIndex: 'contract_id',
      key: 'contract_id',
      render: contractCell
    },
    {
      title: t('app_users:user_name'),
      dataIndex: 'app_user_id',
      key: 'app_user_id',
      render: userCell
    }
  ]

  return (
    <Modal
      visible={visible}
      onCancel={close}
      cancelText="close"
      title={`${answer} (${ids.length})`}
      fullWidth
      fullHeight
      large
    >
      <Table
        columns={columns}
        rowKey="answer_id"
        loadingSelector={selectSurveyAnswerUsersLoading}
        dataSelector={selectSurveyAnswerUsers}
        leftStickyColumnCount={0}
        rightStickyColumnCount={0}
        noFooter
        noResults
        noLimit
      />
    </Modal>
  )
}

ResultModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  answer: PropTypes.string.isRequired
}

export default ResultModal
