import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  useTranslation,
  dynamicLink,
  Table,
  Tag,
  LightText
} from '@gk-devteam/apmc-core-web'

import {
  DELETE_NOTICE,
  OPEN_NOTICE_STATUS_MODAL,
  OPEN_NOTICE_CONDITIONS_MODAL,
  SEND_NOTICE,
  STOP_NOTICE
} from '../../../types'
import {
  selectNoticesLoading,
  selectNoticesData,
  selectNoticesCount,
  selectTheme,
  selectPages
} from '../../../selectors'

const NoticesList = ({ navigate }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'notices'])
  const theme = useSelector(selectTheme)
  const pages = useSelector(selectPages)

  const tagCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      if (value) {
        return <Tag label={value} customColor={data.tag_color || (theme && theme.colors && theme.colors.info)} />
      }
      return <LightText text='-' />
    },
    [theme]
  )

  const _handleCopy = useCallback(
    (id, close) => {
      close()
      navigate('/notices/add', { state: { id, copy: true } })
    },
    [navigate]
  )

  const _handleInactiveRow = useCallback(
    (data) => {
      return data && data.notice_type === 0
    },
    []
  )
  const _canSendNotice = useCallback(
    (data) => {
      if (!pages) return false
      if (!pages.notices.functions.add) return false
      return data && data.notice_type === 0
    },
    [pages]
  )
  const _isStoppableRow = useCallback(
    (data) => {
      if (!pages) return false
      if (!pages.notices.functions.update) return false
      if (data) {
        const { sent_time, stop_time, notice_type } = data
        const start = sent_time && dayjs(sent_time)
        const end = stop_time && dayjs(stop_time)
        if (notice_type === 0) return false
        if (!sent_time) return false
        if (start && start.isAfter(dayjs())) return false
        return !end || (end && end.isAfter(dayjs()))
      }
      return false
    },
    [pages]
  )
  const _hasBeenSentRow = useCallback(
    (data) => {
      if (!pages) return false
      if (!pages.app_users.functions.details) return false
      if (data && data.sent_time) {
        return dayjs().isAfter(data.sent_time)
      }
      return false
    },
    [pages]
  )

  const noticesColumns = [
    {
      title: t('title'),
      dataIndex: 'title',
      key: 'title',
      cellTitle: true,
      sortable: true
    },
    {
      title: t('notices:recipients_condition'),
      dataIndex: 'recipients_condition',
      key: 'recipients_condition'
      // render: sentConditionsCell
    },
    {
      title: t('tag'),
      dataIndex: 'tag',
      key: 'tag',
      render: tagCell
    },
    {
      title: t('notices:sent_count'),
      dataIndex: 'sent_count',
      key: 'sent_count'
    },
    {
      title: t('notices:read_count'),
      dataIndex: 'read_count',
      key: 'read_count'
    },
    {
      title: t('notices:sent_time'),
      dataIndex: 'sent_time',
      key: 'sent_time',
      sortable: true
    },
    {
      title: t('notices:stop_time'),
      dataIndex: 'stop_time',
      key: 'stop_time',
      sortable: true
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('notices:send_notice'),
          onClick: (id, close) => {
            dispatch({ type: SEND_NOTICE, id })
            close()
          },
          shouldRender: _canSendNotice
        },
        {
          label: t('notices:read_status'),
          onClick: (id, close) => {
            _openNoticeStatus(id)
            close()
          },
          shouldRender: _hasBeenSentRow
        },
        {
          label: t('notices:recipients_condition'),
          onClick: (id, close) => {
            _openNoticeConditions(id)
            close()
          },
          shouldRender: 'notices.functions.details'
        },
        {
          label: t('copy'),
          onClick: (id, close) => {
            _handleCopy(id, close)
          },
          shouldRender: 'notices.functions.add'
        },
        {
          label: t('notices:stop_notice'),
          danger: true,
          dangerLabel: 'notices:stop_notice_confirm',
          onClick: (id, close) => {
            dispatch({ type: STOP_NOTICE, id, mode: 'list' })
            close()
          },
          shouldRender: _isStoppableRow
        },
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            dispatch({ type: DELETE_NOTICE, id })
            close()
          },
          shouldRender: 'notices.functions.delete'
        }
      ]
    }
  ]

  const navigateToDetails = (id, row, e) => {
    const url = `/notices/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  const _openNoticeStatus = (id) => {
    dispatch({ type: OPEN_NOTICE_STATUS_MODAL, payload: id })
  }
  const _openNoticeConditions = (id) => {
    dispatch({ type: OPEN_NOTICE_CONDITIONS_MODAL, payload: id })
  }

  return (
    <Table
      fullHeight
      columns={noticesColumns}
      rowKey="id"
      loadingSelector={selectNoticesLoading}
      resultsCountSelector={selectNoticesCount}
      dataSelector={selectNoticesData}
      onRowClick={navigateToDetails}
      inactiveRow={_handleInactiveRow}
    />
  )
}

export default NoticesList

NoticesList.propTypes = {
  navigate: PropTypes.func.isRequired
}
NoticesList.defaultProps = {

}
