import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal,
  Table,
  Tag,
  TOGGLE_ARRAY_ITEM,
  useTranslation
} from '@gk-devteam/apmc-core-web'

import { MEETING_ROLES } from '../../../constants'

import UserCheckmark from './UserCheckmark'
import SelectedInspectorItem from './SelectedInspectorItem'
import {
  BetweenStyle,
  InspectorModalBodyStyle,
  ResultsContainer,
  TableContainerStyle
} from '../PropertyForm.style'

const PropertySettingsInspectorsModal = ({ modalVisible, closeModal, users, loading, inspectors = [] }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const tagCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      if (!value) {
        return <Tag label="app_users:app_not_usable" color="text"/>
      }
      return null
    },
    []
  )

  const usersColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'selected_user',
      RenderComponent: UserCheckmark
    },
    {
      title: t('app_users:user_name'),
      dataIndex: 'app_user_name',
      key: 'app_user_name',
      cellTitle: true
    },
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '',
      dataIndex: 'app_usable',
      key: 'app_usable',
      render: tagCell
    }
  ]
  const _handleDisableRow = useCallback(
    (data) => {
      return data && data.app_usable === false
    },
    []
  )
  const _toggleInspector = useCallback(
    (app_user_id, app_user_name) => {
      dispatch({
        type: TOGGLE_ARRAY_ITEM,
        payload: [{
          key: 'meeting_users',
          value: {
            app_user_id,
            app_user_name,
            role: MEETING_ROLES.INSPECTOR
          },
          objectComparatorKey: 'app_user_id'
        }]
      })
    },
    [dispatch]
  )

  const _handleRowClick = useCallback(
    (_, row, e) => {
      const { id, app_user_name } = row
      _toggleInspector(id, app_user_name)
    },
    [_toggleInspector]
  )
  const loadingSelector = () => {
    return loading
  }
  const usersSelector = () => {
    return users
  }

  return (
    <Modal
      visible={modalVisible}
      title='properties:select_inspector'
      onCancel={closeModal}
      cancelText='close'
      fullWidth
      fullHeight
      large
    >
      <InspectorModalBodyStyle>
        <TableContainerStyle>
          <Table
            columns={usersColumns}
            rowKey="id"
            loadingSelector={loadingSelector}
            dataSelector={usersSelector}
            onRowClick={_handleRowClick}
            disableRow={_handleDisableRow}
            leftStickyColumnCount={0}
            rightStickyColumnCount={0}
            noHeader
            noFooter
            noLoader
          />
        </TableContainerStyle>
        <BetweenStyle> {'>>'} </BetweenStyle>
        <ResultsContainer>
          { inspectors?.map(inspector => <SelectedInspectorItem key={inspector.app_user_id} inspector={inspector} removeInspector={_toggleInspector} />) }
        </ResultsContainer>
      </InspectorModalBodyStyle>
    </Modal>
  )
}

export default PropertySettingsInspectorsModal

PropertySettingsInspectorsModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array,
  inspectors: PropTypes.array
}
