import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import {
  selectImportCellData,
  selectImportCellErrors
} from '../../../selectors'
import { UPDATE_IMPORT_DATA } from '../../../types'

import {
  ImportConfirmTableCellInnerStyle,
  TextInputStyle,
  ErrorStyle
} from './ImportsConfirmPage.style'

const ImportConfirmCell = ({ dataIndex, index, originalIndex }) => {
  const dispatch = useDispatch()
  const data = useSelector(state => selectImportCellData(state, originalIndex, dataIndex))
  const error = useSelector(state => selectImportCellErrors(state, originalIndex, dataIndex))
  // if (dataIndex === 'question' && error !== null) {
  //   console.group('question cell', index) // current data index
  //   console.log('originalIndex', originalIndex) // data[i].originalIndex
  //   console.log('dataIndex', dataIndex) // property key
  //   console.log('data', data) //
  //   console.log('error', error)
  //   console.groupEnd()
  // }

  const _handleInputChange = (e) => {
    let value = e.target.value
    if (Array.isArray(data)) {
      value = value.split(',')
    }
    dispatch({ type: UPDATE_IMPORT_DATA, payload: { key: dataIndex, value, index: originalIndex } })
  }

  const _renderData = () => {
    if (error) {
      return (
        <TextInputStyle value={data} onChange={_handleInputChange} />
      )
    }
    if (!data) return '-'
    if (Array.isArray(data)) return data.join(',')
    return data
  }

  if (!dataIndex || data == null) return null

  return (
    <ImportConfirmTableCellInnerStyle error={!!error}>
      { _renderData() }
      {error
        ? <ErrorStyle><ExclamationCircleOutlined />{error}</ErrorStyle>
        : null
      }
    </ImportConfirmTableCellInnerStyle>
  )
}

export default ImportConfirmCell

ImportConfirmCell.propTypes = {
  dataIndex: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  originalIndex: PropTypes.number.isRequired
}
