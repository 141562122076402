import { createSelector } from 'reselect'

const selectSocketUser = state => state.socketUser

export const selectFirebaseUser = createSelector(
  selectSocketUser,
  user => user.data
)
export const selectFirebaseUsername = createSelector(
  selectSocketUser,
  user => user.data && user.data.name
)
export const selectFirebaseUserID = createSelector(
  selectSocketUser,
  user => user.id
)
