import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dot from 'dot-object'
import {
  Row,
  FormControlLabel,
  useTranslation,
  PrimaryButton,
  FormControlError,
  Column,
  RemoveButton,
  TOGGLE_ARRAY_ITEM
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import PropertySettingsChairmanModal from './PropertySettingsChairmanModal'
import { BoardUserStyle, LinkStyle } from '../PropertyForm.style'
import { MEETING_ROLES } from '../../../constants'

const PropertySettingsChairman = ({ loading, users }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false)

  const chairman = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    return form?.meeting_users?.find(user => user.role === MEETING_ROLES.CHAIRMAN)
  })

  const _openModal = () => {
    setModalVisible(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
  }

  const _handleResetChairman = () => {
    if (chairman) {
      const { app_user_id, app_user_name } = chairman
      dispatch({
        type: TOGGLE_ARRAY_ITEM,
        payload: [{
          key: 'meeting_users',
          value: {
            app_user_id,
            app_user_name,
            role: MEETING_ROLES.CHAIRMAN
          },
          objectComparatorKey: 'app_user_id'
        }]
      })
    }
  }

  return (
    <>
      <PropertySettingsChairmanModal
        modalVisible={modalVisible}
        closeModal={_closeModal}
        loading={loading}
        users={users}
      />
      <Column mt="R">
        <FormControlLabel label={t('properties:documents.chairman')} />
        <Row align="center" justify="start">
          {
            chairman?.app_user_id && (
              <BoardUserStyle>
                <LinkStyle
                  text={chairman.app_user_name}
                  to={`/app_users/edit/${chairman.app_user_id}`}
                />
                <RemoveButton
                  label="delete"
                  handleClick={_handleResetChairman}
                />
              </BoardUserStyle>
            )
          }
          <PrimaryButton
            label={'properties:select_chairman'}
            type="button"
            onClick={_openModal}
          />
        </Row>
        <FormControlError name="meeting_users" />
      </Column>
    </>
  )
}

export default PropertySettingsChairman

PropertySettingsChairman.propTypes = {
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array
}
