import React, { memo, useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'
import { message } from 'antd'
import dayjs from 'dayjs'

import {
  useTranslation,
  Document,
  Page,
  api,
  FILE_TYPES,
  Modal,
  Spinner,
  Row,
  Card,
  Image,
  Text,
  LightText,
  FRONT_TIMESTAMP_FORMAT,
  Tag
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyDocumentViewModalState,
  selectActivePropertyDocument
} from '../../../selectors'
import { TOGGLE_VIEW_PROPERTY_DOCUMENT_MODAL } from '../../../types'
import { PDF_CMAP_URL } from '../../../constants'

import { PreviewStyle } from './PropertiesDocumentsPage.style'

const ViewDocumentModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visible = useSelector(selectPropertyDocumentViewModalState)
  const data = useSelector(selectActivePropertyDocument)

  const [loading, setLoading] = useState(null)
  const [fileURL, setFileURL] = useState(null)
  const [fileType, setFileType] = useState(null)
  const [extension, setExtension] = useState(null)
  const [height, setHeight] = useState(400)

  const handlePreviewHeight = useCallback(
    () => {
      const windowHeight = window.innerHeight
      setHeight(windowHeight * 0.8)
    },
    []
  )

  useEffect(() => {
    handlePreviewHeight()
    window.addEventListener('resize', handlePreviewHeight)
    return () => {
      window.removeEventListener('resize', handlePreviewHeight)
    }
  }, [handlePreviewHeight])

  const fetchDocument = useCallback(
    async () => {
      if (data?.id) {
        setLoading(true)
        try {
          const res = await api.get(`/meetings/${data.id}`, { responseType: 'blob' })
          if (res.headers && FILE_TYPES.pdfTypes.includes(res.headers['content-type'])) {
            setFileType('pdf')
            setExtension('pdf')
          } else {
            setFileType('image')
            setExtension(res.headers['content-type'].replace('image/', ''))
          }
          const fileURL = URL.createObjectURL(res.data)
          setFileURL(fileURL)
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.warning('error', error)
          message.error(t('fetch_error'))
        }
      }
    },
    [data, t]
  )
  useEffect(() => {
    fetchDocument()
    return () => {
      setFileType(null)
      setFileURL(null)
    }
  }, [fetchDocument])

  const _closeViewModal = () => {
    dispatch({ type: TOGGLE_VIEW_PROPERTY_DOCUMENT_MODAL })
  }

  const _saveDocument = () => {
    saveAs(fileURL, `${data?.file_name.replace(' ', '_')}.${extension}`)
  }

  const _renderTags = () => {
    if (!data) return null
    const { chairman, inspector, contractors, residents } = data
    const tags = []

    if (chairman) tags.push(<Tag key="chairman" label='properties:documents.chairman' color='accent' />)
    if (inspector) tags.push(<Tag key="inspector" label='properties:documents.inspector' color='info' />)
    if (contractors) tags.push(<Tag key="contractors" label='properties:documents.contractors' color='danger' />)
    if (residents) tags.push(<Tag key="residents" label='properties:documents.residents' color='success' />)

    return tags
  }

  const _renderPreview = () => {
    if (!fileType) return <></>
    if (fileType === 'pdf') {
      return (
        <PreviewStyle>
          <Document
            file={fileURL}
            loading={t('pdf_load')}
            error={t('pdf_load_fail')}
            // onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
            options={{
              cMapUrl: PDF_CMAP_URL,
              cMapPacked: true
            }}
          >
            <Page
              pageNumber={1}
              height={height}
              renderAnnotationLayer={false}
            />
          </Document>
        </PreviewStyle>

      )
    } else if (fileType === 'image') {
      return (
        <Image src={fileURL}/>
      )
    }
  }

  return (
    <Modal
      visible={visible && !!data}
      onCancel={_closeViewModal}
      cancelText="close"
      onOk={_saveDocument}
      okText="download"
      title={data?.title || ''}
      fullWidth
      fullHeight
      large
    >
      <Row mb="S">
        { _renderTags() }
      </Row>
      <div>
        {
          loading
            ? <Row justify="center"><Spinner size="default" color="info"/></Row>
            : (
              <>
                <Row mb="XS">
                  <Text label="file"/>
                </Row>
                <Card>
                  { _renderPreview() }
                </Card>
              </>
            )
        }
      </div>
      <Row justify="end" mt="R">
        <LightText label="properties:documents.uploaded_at" />
        <LightText text={`: ${data?.created_at && dayjs(data.created_at).format(FRONT_TIMESTAMP_FORMAT)}`} />
      </Row>
    </Modal>
  )
}

export default memo(ViewDocumentModal)
