import React, { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_CONTRACTS,
  RESET_CONTRACTS
} from '../../../../../types'

import {
  ResultsBlockStyle,
  BetweenStyle
} from '../../../NoticeForm.style'

import ContractsForm from './ContractsForm'
import ContractsList from './ContractsList'
import RecipientsResult from './RecipientsResult'

const CONTRACTS_LIMIT = 100

const ContractsModal = ({ visible, close }) => {
  const dispatch = useDispatch()

  const fetchData = useCallback(
    ({
      property_name = '',
      contractor_name = '',
      resident_name = ''
    }) => {
      dispatch({
        type: FETCH_CONTRACTS,
        query: {
          property_name,
          contractor_name,
          resident_name,
          limit: CONTRACTS_LIMIT
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    fetchData({})
    return () => {
      dispatch({ type: RESET_CONTRACTS })
    }
  }, [fetchData, dispatch])

  return (
    <Modal
      visible={visible}
      title='notices:select_contracts'
      onCancel={close}
      cancelText='close'
      fullWidth
      fullHeight
      large
    >
      <ContractsForm fetchData={fetchData}/>
      <ResultsBlockStyle>
        <ContractsList />
        <BetweenStyle> {'>>'} </BetweenStyle>
        <RecipientsResult />
      </ResultsBlockStyle>
    </Modal>
  )
}

export default memo(ContractsModal)

ContractsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}
