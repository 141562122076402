import React, { memo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslation,
  numberWithCommas,
  FormControlLabel
} from '@gk-devteam/apmc-core-web'

import {
  TotalFeesStyle,
  TotalValueStyle
} from '../../ContractForm.style'

const RenewalTotalFees = () => {
  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const renewalFees = useSelector(state => state.form.renewal_fees)
  const adminsitrativeFees = useSelector(state => state.form.renewal_administrative_fees)

  useEffect(() => {
    let totalFees = Number(renewalFees) || 0

    if (adminsitrativeFees) {
      totalFees += Number(adminsitrativeFees)
    }
    setTotal(numberWithCommas(totalFees))
  }, [renewalFees, adminsitrativeFees])

  return (

    <TotalFeesStyle flex={1}>
      <FormControlLabel label={t('total')} />
      <TotalValueStyle text={`${total} 円`} />
    </TotalFeesStyle>

  )
}

export default memo(RenewalTotalFees)

// RentFees.propTypes = {}
