import React, { useState } from 'react'
import { Tabs as AntTab } from 'antd'
import { PageLayout, Row, Title, useTranslation, Tabs } from '@gk-devteam/apmc-core-web'

import ContactsTab from './ContactsTab'
import ShowroomsTab from './ShowroomsTab'

// import { selectLoadingYasueContacts, selectYasueContactsCount, selectYasueContactsData } from '../../../selectors'
// import { FETCH_YASUE_CONTACTS, RESET_YASUE_CONTACTS } from '../../../types'

const { TabPane } = AntTab

const ContactsPage = () => {
  const { t } = useTranslation(['common', 'yasue'])
  const [activePane, setActivePane] = useState('contacts')

  const _handleTabChange = (tab) => {
    setActivePane(tab)
  }

  const breadcrumbs = [
    {
      label: 'navigation:yasue.maintenance',
      link: '/yasue/maintenance'
    }
  ]

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
    >
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:yasue.maintenance"/>
      </Row>

      <Tabs activeKey={activePane} onChange={_handleTabChange} destroyInactiveTabPane>
        <TabPane key="contacts" tab={t('yasue:maintenance.contacts')}>
          <ContactsTab />
        </TabPane>
        <TabPane key="showrooms" tab={t('yasue:maintenance.showrooms')}>
          <ShowroomsTab />
        </TabPane>

      </Tabs>
    </PageLayout>
  )
}

export default ContactsPage

ContactsPage.propTypes = {}
ContactsPage.defaultProps = {}
