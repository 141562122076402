import Papa from 'papaparse'
import Encoding from 'encoding-japanese'

export const customCsvParser = ({ file, errors, transformHeader }) => {
  return new Promise((resolve, reject) => {
    if (!file) return reject(new Error(errors?.noFile || 'No file to parse'))

    const onComplete = (results) => {
      const { data, errors } = results
      if (errors && errors.length > 0) return reject(new Error(errors?.parseError || 'Parsing error'))

      return resolve(data)
    }

    const onError = (err) => {
      return reject(err)
    }

    var reader = new FileReader()
    reader.onload = function (e) {
      var codes = new Uint8Array(e.target.result)
      var encoding = Encoding.detect(codes)
      // console.log(encoding)
      Papa.parse(file, {
        encoding: encoding === 'UTF8' ? 'UTF-8' : 'Shift_JIS',
        comments: '*',
        skipEmptyLines: true,
        header: true,
        transformHeader,
        complete: onComplete,
        error: onError
      })
    }

    reader.readAsArrayBuffer(file)
  })
}
