import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useTranslation,
  Table,
  TOGGLE_ARRAY_ITEM,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  selectContractsLoading,
  selectContractsData
} from '../../../../../selectors'

import UserCheckmark from './UserCheckmark'
import { TableContainerStyle } from '../../../NoticeForm.style'

const ContractsList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)

  let usersColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'selected_user',
      RenderComponent: UserCheckmark
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name'
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number'
    },
    {
      title: t('contracts:contractor_name'),
      dataIndex: 'contractor_name',
      key: 'contractor_name'
    },
    {
      title: t('contracts:resident_name'),
      dataIndex: 'resident_name',
      key: 'resident_name'
    }
  ]
  if (pages?.custom?.yasue) {
    usersColumns = [
      {
        title: '',
        dataIndex: '',
        key: 'selected_user',
        RenderComponent: UserCheckmark
      },
      {
        title: t('yasue:contracts.contract_manage_id'),
        dataIndex: 'contract_manage_id',
        key: 'contract_manage_id'
      },
      {
        title: t('yasue:contracts.contractor_name'),
        dataIndex: 'contractor_name',
        key: 'contractor_name'
      }
    ]
  }

  const _handleRowClick = (_, row, e) => {
    const { id, contractor_name } = row
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [
        {
          key: 'recipients_list',
          value: { id, label: contractor_name },
          objectComparatorKey: 'id'
        },
        {
          key: 'recipients_id',
          value: id
        }
      ]
    })
  }

  return (
    <TableContainerStyle>
      <Table
        columns={usersColumns}
        rowKey="id"
        loadingSelector={selectContractsLoading}
        dataSelector={selectContractsData}
        onRowClick={_handleRowClick}
        leftStickyColumnCount={0}
        rightStickyColumnCount={0}
        noHeader
        noFooter
        noLoader
      />
    </TableContainerStyle>
  )
}

export default memo(ContractsList)

// ContractsList.propTypes = {}
