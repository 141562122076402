import {
  styled
} from '@gk-devteam/apmc-core-web'
import { StatusRadioStyle } from '../adminTasks.style'

export const AllStatusRadioStyle = styled(StatusRadioStyle)`
  position: absolute;
  top: 54px;
  right: 0px;
`
