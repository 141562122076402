import React, { memo } from 'react'
import { useTranslation } from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import {
  UserRow,
  UserCell
} from './CouponDetailPage.style'

const CouponStatusUserRow = ({ history }) => {
  const { t } = useTranslation()
  if (!history) return null
  const {
    remaining_count,
    used_at
  } = history

  return (
    <UserRow>
      <UserCell className="cell">{ remaining_count ?? t('coupons:no_limit_text') }</UserCell>
      <UserCell className="cell">{ used_at }</UserCell>
    </UserRow>
  )
}

export default memo(CouponStatusUserRow)

CouponStatusUserRow.propTypes = {
  history: PropTypes.shape({
    property_name: PropTypes.string.isRequired,
    room_number: PropTypes.string.isRequired,
    remaining_count: PropTypes.number,
    used_at: PropTypes.string.isRequired
  }).isRequired
}
