import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EmptyData } from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import {
  selectExternalServices,
  selectInternalServices
} from '../../../selectors'
import { SERVICES_TYPES } from '../../../constants'

import ServicesListItem from './ServicesListItem'
import { ServiceListStyle } from './services.style'

function ServicesList ({ type }) {
  const [data, setData] = useState(null)
  const internalServices = useSelector(selectInternalServices)
  const externalServices = useSelector(selectExternalServices)

  useEffect(() => {
    if (type === SERVICES_TYPES.INTERNAL) setData(internalServices)
    if (type === SERVICES_TYPES.EXTERNAL) setData(externalServices)
  }, [type, internalServices, externalServices])

  if (!data) return <EmptyData description="services:no_service" />

  return (
    <ServiceListStyle>
      {data.map(service => <ServicesListItem key={service.id} item={service}/>)}
    </ServiceListStyle>
  )
}

ServicesList.propTypes = {
  type: PropTypes.number.isRequired
}

export default ServicesList
