import Papa from 'papaparse'
import {
  api, MULTIPART_HEADERS
} from '@gk-devteam/apmc-core-web'

export const validateChoueiBilling = async (data, cancelSource) => {
  return api.post('chouei/validate_billing', data, {
    cancelToken: cancelSource.token
  })
}

export const postChoueiBilling = async (data, cancelSource) => {
  if (Array.isArray(data) && data?.length > 0) {
    const formData = new FormData()
    try {
      const csv = Papa.unparse(data)
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      formData.append('csv', blob, 'billing.csv')
    } catch (error) {
      throw new Error('error while processing properties file')
    }

    return api.post('/chouei/billing', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}
