export const FETCH_GUESTS = 'fetch_guests'
export const FETCH_GUESTS_SUCCESS = 'fetch_guests_success'
export const FETCH_GUESTS_FAIL = 'fetch_guests_fail'
export const RESET_GUESTS = 'reset_guests'

export const DELETE_GUEST = 'delete_guest'
export const DELETE_GUEST_SUCCESS = 'delete_guest_success'
export const DELETE_GUEST_FAIL = 'delete_guest_fail'

export const FETCH_GUEST = 'fetch_guest'
export const FETCH_GUEST_SUCCESS = 'fetch_guest_success'
export const FETCH_GUEST_FAIL = 'fetch_guest_fail'
export const RESET_GUEST = 'reset_guest'

export const POST_GUEST = 'post_guest'
export const POST_GUEST_SUCCESS = 'post_guest_success'
export const POST_GUEST_FAIL = 'post_guest_fail'

export const SEND_GUEST_EMAIL = 'send_guest_email'
export const SEND_GUEST_EMAIL_SUCCESS = 'send_guest_email_success'
export const SEND_GUEST_EMAIL_FAIL = 'send_guest_email_fail'
