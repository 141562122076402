import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row,
  Text,
  SideMenu,
  Image,
  Link,
  EmptyData
} from '@gk-devteam/apmc-core-web'

import { BULK_TOGGLE_GUIDES } from '../../../../types'
import { PanelDisabledContext } from '../../../../components/Display/Panels/PanelDisabled/PanelDisabled'

import GuideHeader from './GuideHeader'
import PanelBlockHeader from '../../../../components/Display/Panels/PanelBlockHeader'
import {
  BlockStyle,
  CollapseStyle,
  PanelStyle
} from '../../panels.style'
import {
  GuideInfoContainerStyle,
  GuideInfoStyle,
  GuideImageStyle
} from '../guides.style'

const GuidesList = ({
  dataSelector,
  displaySelector,
  idSelector,
  title,
  type,
  handleToggleDisplay,
  sideMenu,
  level,
  extraConfig
}) => {
  const guides = useSelector(dataSelector)
  const disabled = useContext(PanelDisabledContext)

  const _renderList = () => {
    if (!guides) return null
    if (guides.length === 0) return <EmptyData />
    return guides.map((guide, i) => {
      const {
        content,
        file_url,
        url,
        video_url
      } = guide
      return (
        <PanelStyle
          header={(
            <GuideHeader
              item={guide}
              handleToggleDisplay={handleToggleDisplay}
              idSelector={idSelector}
            />
          )}
          key={`contact_${i}`}
        >
          <Row align="start">
            <GuideImageStyle>
              <Image src={file_url} />
            </GuideImageStyle>
            <GuideInfoContainerStyle wrap="wrap">
              <Row>
                <p>{content}</p>
              </Row>
              <Row>
                <GuideInfoStyle>
                  <Text label="external_url" />
                  {
                    url
                      ? <Link href={url} target="_blank" rel="noopener noreferrer">{ url }</Link>
                      : <Text text="-"/>
                  }
                </GuideInfoStyle>
                <GuideInfoStyle>
                  <Text label="video_url" />
                  {
                    video_url
                      ? <Link href={video_url} target="_blank" rel="noopener noreferrer">{ video_url }</Link>
                      : <Text text="-"/>
                  }
                </GuideInfoStyle>
              </Row>
            </GuideInfoContainerStyle>
            <SideMenu
              menu={sideMenu}
              data={guide}
            />
          </Row>
        </PanelStyle>
      )
    })
  }

  return (
    <BlockStyle>
      <PanelBlockHeader
        title={title}
        idSelector={idSelector}
        displaySelector={displaySelector}
        type={type}
        level={level}
        toggleType={BULK_TOGGLE_GUIDES}
        extraConfig={extraConfig}
        authorized="guides.functions.update"
      />
      <CollapseStyle
        bordered={false}
        expandIconPosition="right"
        disabled={disabled}
        // defaultActiveKey="contact_0"
      >
        { _renderList() }
      </CollapseStyle>

    </BlockStyle>
  )
}

export default GuidesList

GuidesList.propTypes = {
  dataSelector: PropTypes.func.isRequired,
  displaySelector: PropTypes.func.isRequired,
  idSelector: PropTypes.func,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['guides', 'admin_guides', 'property_guides']).isRequired,
  level: PropTypes.oneOf(['admin', 'property', 'room']).isRequired,
  handleToggleDisplay: PropTypes.func.isRequired,
  sideMenu: PropTypes.array,
  extraConfig: PropTypes.object
}
