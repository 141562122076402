import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormSubmitButton, Row } from '@gk-devteam/apmc-core-web'

import { yasueMaintenanceUpdateSchema } from '../../../validation'
import {
  FETCH_YASUE_MAINTENANCE_SHOWROOMS,
  RESET_YASUE_MAINTENANCE_SHOWROOMS,
  POST_YASUE_MAINTENANCE_SHOWROOMS
} from '../../../types'
import {
  selectYasueMaintenanceShowrooms,
  selectYasueMaintenanceLoading,
  selectYasueMaintenancePosting
} from '../../../selectors'
import { TextAreaStyle } from './yasueMaintenance.style'

export default function ContactsTab () {
  const dispatch = useDispatch()
  const data = useSelector(selectYasueMaintenanceShowrooms)

  useEffect(() => {
    dispatch({ type: FETCH_YASUE_MAINTENANCE_SHOWROOMS })
    return () => {
      dispatch({ type: RESET_YASUE_MAINTENANCE_SHOWROOMS })
    }
  }, [dispatch])

  const handleFormSubmit = (data) => {
    dispatch({ type: POST_YASUE_MAINTENANCE_SHOWROOMS, data })
  }

  return (
    <Form
      id="yasueMaintenanceShowrooms"
      handleSubmit={handleFormSubmit}
      validationSchema={yasueMaintenanceUpdateSchema}
      loadingSelector={selectYasueMaintenanceLoading}
      postingSelector={selectYasueMaintenancePosting}
      initialData={{
        showrooms: data
      }}
      authorized="custom.yasue"
      // debug
    >
      <TextAreaStyle name="showrooms" mr="0" maxLength={10_000}/>
      <Row justify="end" mb="ML">
        <FormSubmitButton formID="yasueMaintenanceShowrooms" postingSelector={selectYasueMaintenanceLoading}/>
      </Row>
    </Form>
  )
}
