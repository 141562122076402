import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import {
  Card,
  LightText,
  Link,
  Modal,
  Row,
  selectPages,
  Image
} from '@gk-devteam/apmc-core-web'

import {
  selectInquiryVisible,
  selectActiveInquiry
} from '../../selectors'
import { TOGGLE_INQUIRY_MODAL } from '../../types'
import { LabelTextStyle } from './InquiriesPage.style'

export default function InquiryModal () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const pages = useSelector(selectPages)
  const visible = useSelector(selectInquiryVisible)
  const inquiry = useSelector(selectActiveInquiry)

  const _closeModal = () => {
    dispatch({ type: TOGGLE_INQUIRY_MODAL })
  }
  const _navigateToChat = () => {
    if (inquiry?.contract_id && inquiry?.user_id) navigate(`/admin_tasks/chat/contract:${inquiry?.contract_id}_${inquiry?.user_id}`)
  }

  return (
    <Modal
      visible={visible && !!inquiry}
      onCancel={_closeModal}
      cancelText="close"
      onOk={pages?.chat?.enabled && _navigateToChat}
      okText="inquiries:answer_chat"
      title={inquiry?.title || ''}
      fullWidth
      fullHeight
      large
    >
      <Row justify="between">
        <div>
          <LabelTextStyle label="properties:property_name" />
          <LightText text=": " />
          <Link to={`/properties/edit/${(inquiry?.property_id) || ''}`}>{(inquiry?.property_name) || ''}</Link>
          <LabelTextStyle label="contracts:contract_id" />
          <LightText text=": " />
          <Link to={`/contracts/edit/${(inquiry?.contract_id) || ''}`}>{(inquiry?.contract_id) || ''}</Link>
          <LabelTextStyle label="app_users:user_name" />
          <LightText text=": " />
          <Link to={`/app_users/edit/${(inquiry?.user_id) || ''}`}>{(inquiry?.user_name) || ''}</Link>
        </div>
        <div>
          <LightText label="inquiries:sent_datetime" />
          <LightText text=": " />
          <LightText text={inquiry?.sent_date} />
        </div>
      </Row>

      <Card mt="R">
        { inquiry?.message }
      </Card>

      <Card mt="R">
        <Image src={inquiry?.file_url} previewable />
      </Card>

    </Modal>
  )
}
