import React from 'react'
import { useNavigate, useLocation } from '@reach/router'
import PropTypes from 'prop-types'
import {
  dynamicLink,
  Image
} from '@gk-devteam/apmc-core-web'

import GuideCategoryItemHeader from './GuideCategoryItemHeader'
import {
  GuideCategoryStyle,
  GuideCategoryContentStyle,
  GuideCategoryImageStyle,
  DescriptionStyle
} from '../guideCategories.style'

const GuideCategoryItem = ({
  item,
  handleItemDisplay,
  idSelector,
  sideMenu,
  level,
  guidePage
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const _redirectToDetails = (e) => {
    const url = `${location.pathname}/${guidePage}/${item.id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <GuideCategoryStyle onClick={_redirectToDetails}>
      <GuideCategoryItemHeader
        item={item}
        handleItemDisplay={handleItemDisplay}
        idSelector={idSelector}
        menu={sideMenu}
      />
      <GuideCategoryContentStyle align="start" mt="S">
        <GuideCategoryImageStyle>
          <Image src={item.file_url} cover full />
        </GuideCategoryImageStyle>
        <DescriptionStyle>{item.content}</DescriptionStyle>
      </GuideCategoryContentStyle>
    </GuideCategoryStyle>
  )
}

export default GuideCategoryItem

GuideCategoryItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    file_url: PropTypes.string,
    content: PropTypes.string
  }).isRequired,
  handleItemDisplay: PropTypes.func.isRequired,
  idSelector: PropTypes.func,
  sideMenu: PropTypes.array,
  level: PropTypes.string.isRequired,
  guidePage: PropTypes.oneOf(['admin_details', 'property_details']).isRequired
}
