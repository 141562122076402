import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import dot from 'dot-object'
import PropTypes from 'prop-types'
import {
  Checkmark
} from '@gk-devteam/apmc-core-web'

import { CheckmarkCellStyle } from './common.style'

const ItemCheckmark = ({ value, cellKey, rowKey, className, data }) => {
  const selectedItem = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    const key = cellKey.replace(/\d+_/, '')
    return form[key]
  })
  return (
    <CheckmarkCellStyle>
      <Checkmark
        checked={selectedItem ? selectedItem.includes(rowKey) : false}
      />
    </CheckmarkCellStyle>
  )
}

export default memo(ItemCheckmark)

ItemCheckmark.propTypes = {
  value: PropTypes.any,
  cellKey: PropTypes.string,
  rowKey: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.object
}
