/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const createPasswordSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    password: yup
      .string()
      .min(8)
      .max(64)
      .required()
      .label(i18n.t('login:password')),
    check_password: yup
      .string()
      .oneOf([yup.ref('password'), null], i18n.t('validation:check_password', { label: '${label}' }))
      .isValidPassword()
      .min(8)
      .max(64)
      .required()
      .label(i18n.t('login:password'))
  })
}
