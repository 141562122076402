/* eslint-disable react/jsx-no-undef */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from '@reach/router'

import {
  selectImportData,
  selectImportType
  // selectImportValidationErrors
  // selectParsedData
} from '../../../selectors'
import { IMPORT_DATA } from '../../../types'

import ImportConfirmTable from './ImportConfirmTable'
// import { Row } from '../../../components/Layout/Block'
// import Radio from '../../../components/Forms/FormControls/Radio/RadioStandAlone'

// const filterOptions = [
//   {
//     label: 'import:data_filter.all',
//     value: 1
//   },
//   {
//     label: 'import:data_filter.no_error',
//     value: 2
//   },
//   {
//     label: 'import:data_filter.error',
//     value: 3
//   }
// ]

const ImportConfirmForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const parsedData = useSelector(selectParsedData)
  const data = useSelector(selectImportData)
  const importType = useSelector(selectImportType)
  // const validationErrors = useSelector(selectImportValidationErrors)
  // const [dataFilter, setDataFilter] = useState(1)
  // const [filteredData, setFilteredData] = useState(null)

  // useEffect(() => {
  //   if (parsedData && dataFilter) {
  //     const errorRowIndexes = validationErrors && Object.keys(validationErrors)
  //     switch (dataFilter) {
  //       case 2: // no error
  //         if (errorRowIndexes) {
  //           const filtered = parsedData.filter((item, index) => {
  //             return !errorRowIndexes.includes(String(index))
  //           })

  //           setFilteredData(filtered)
  //         } else {
  //           setFilteredData(data)
  //         }
  //         break
  //       case 3: // error only
  //         if (errorRowIndexes) {
  //           setFilteredData(parsedData.filter((item, index) => errorRowIndexes.includes(String(index))))
  //         } else {
  //           setFilteredData(null)
  //         }
  //         break
  //       default:
  //         setFilteredData(data)
  //         break
  //     }
  //   }
  // }, [dataFilter, validationErrors])

  const _handleSubmit = (e) => {
    e.preventDefault()
    const onValidateSuccess = () => {
      navigate('/import/upload', { replace: false })
    }
    dispatch({ type: IMPORT_DATA, data, importType, onValidateSuccess })
  }

  // const _handleFilterChange = val => setDataFilter(val)

  return (
    <form id="data_import" onSubmit={_handleSubmit} style={{ paddingBottom: '2rem' }}>
      {/* <Row my="XS">
        <Radio
          handleChange={_handleFilterChange}
          name="filter"
          value={dataFilter}
          options={filterOptions}
        />
      </Row> */}
      <ImportConfirmTable />
    </form>
  )
}

export default ImportConfirmForm

ImportConfirmForm.propTypes = {
}
