
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  selectPages
} from '@gk-devteam/apmc-core-web'

import { FETCH_CONTRACT_SUCCESS, RESET_CONTRACT } from '../../../types'
import { fetchContract } from '../../../services'

import ContractForm from '../../../forms/contract/ContractForm'
import YasueContractForm from '../../../forms/contract/ContractForm/YasueContractForm'

const breadcrumbs = [
  {
    label: 'navigation:contracts',
    link: '/contracts'
  },
  {
    label: 'navigation:contracts_add',
    link: '/contracts/add'
  }
]

const initialData = {
  property_id: null,
  room_id: null,
  contract_manage_id: null,
  contract_sign_date: null,
  contract_duration: null,
  contract_duration_unit: null,
  contract_start: null,
  contract_end: null,
  move_in: null,
  about_contract: null,
  landlord_name: null,
  landlord_address: null,
  custom_fields: null,
  wifi_ssid: null,
  wifi_password: null,
  car_model_1: null,
  car_plate_number_1: null,
  car_model_2: null,
  car_plate_number_2: null,
  rent_date: null,
  rent_contract_account: null,
  rent_amount: null,
  rent_common_fees: null,
  rent_fees: null,
  landlord_fee: null,
  caution_money: null,
  deposit: null,
  administrative_fees: null,
  renewal_period_start: null,
  renewal_period_end: null,
  auto_renewal: false,
  renewal_fees: null,
  renewal_administrative_fees: null,
  renewal_notice: null,
  move_out: null,
  cancel_notice: null,
  contractor_user_id: null,
  contractor: {
    last_name: null,
    first_name: null,
    furigana_last_name: null,
    furigana_first_name: null,
    app_user_manage_id: null,
    mail_address: null,
    phone: null,
    emergency_phone: null,
    occupation: null,
    annual_income: null,
    birthday: null,
    sex: null,
    nationality: 'JPN',
    residence_card: null,
    home_country_emergency_contact: null,
    postcode: null,
    prefecture: null,
    city: null,
    address: null,
    work: null,
    work_postcode: null,
    work_prefecture: null,
    work_city: null,
    work_address: null,
    work_phone: null,
    work_department: null
  },
  different_resident: false,
  resident_user_id: null,
  resident: {
    last_name: null,
    first_name: null,
    furigana_last_name: null,
    furigana_first_name: null,
    app_user_manage_id: null,
    mail_address: null,
    phone: null,
    emergency_phone: null,
    occupation: null,
    annual_income: null,
    birthday: null,
    sex: null,
    nationality: 'JPN',
    residence_card: null,
    home_country_emergency_contact: null,
    resident_relation_id: null,
    work: null
  },
  roommates: null,
  contract_guarantor: {
    type: 1,
    last_name: null,
    first_name: null,
    furigana_last_name: null,
    furigana_first_name: null,
    relation_id: null,
    birthday: null,
    postcode: null,
    prefecture: null,
    city: null,
    address: null,
    phone: null,
    mobile_phone: null,
    work: null,
    work_postcode: null,
    work_prefecture: null,
    work_city: null,
    work_address: null,
    work_phone: null,
    work_department: null,
    work_position: null,
    annual_income: null
  },
  memo: null,
  premium_start_date: null
}

const ContractsAddPage = ({ location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(null)
  const pages = useSelector(selectPages)

  const prepareData = useCallback(
    (data) => {
      if (data && data.id) {
        const editedData = { ...data }
        delete editedData.id
        delete editedData.modified
        delete editedData.modifiedBy
        if (!editedData.different_resident) {
          editedData.resident = null
          editedData.resident_user_id = null
        }
        setFormData(editedData)
      } else if (location.state && location.state.propertyID) {
        const editedData = {
          ...data,
          property_id: (location.state && location.state.propertyID) || null,
          property_name: (location.state && location.state.propertyName) || null,
          room_id: (location.state && location.state.roomID) || null,
          room_number: (location.state && location.state.roomName) || null
        }
        setFormData(editedData)
      } else {
        setFormData(data)
      }
      dispatch({ type: FETCH_CONTRACT_SUCCESS, payload: null })
    },
    [dispatch, location.state]
  )

  const fetchData = useCallback(
    async () => {
      const cancelSource = CancelToken.source()
      if (location && location.state && location.state.copy) {
        setLoading(true)
        try {
          const res = await fetchContract(location.state.id, cancelSource)
          setLoading(false)
          if (res && res.data) {
            prepareData(res.data)
          } else {
            message.error(t('fetch_error'))
          }
        } catch (error) {
          setLoading(false)
          message.error(t('fetch_error'))
        }
      } else {
        prepareData(initialData)
      }
    },
    [location, prepareData, t]
  )

  useEffect(() => {
    fetchData()
    return () => {
      dispatch({ type: RESET_CONTRACT })
    }
  }, [location, dispatch, fetchData])

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      {
        formData
          ? pages?.custom?.yasue ? <YasueContractForm data={formData}/> : <ContractForm data={formData}/>
          : null
      }
    </PageLayout>
  )
}
export default ContractsAddPage

ContractsAddPage.propTypes = {
  location: PropTypes.object
}
ContractsAddPage.defaultProps = {

}
