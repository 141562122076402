import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ItemNotFound,
  LeftNavigationMenu,
  PageLayout,
  selectPages,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { selectServiceError, selectServiceLoading, selectServiceData } from '../../selectors'
import { FETCH_SERVICE, RESET_SERVICE } from '../../types'
import { SERVICES_TYPES, SERVICES_WITH_SETTINGS } from '../../constants'

import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage'

function ServiceBlock ({ enabled, children, serviceID }) {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const pages = useSelector(selectPages)
  const error = useSelector(selectServiceError)
  const loading = useSelector(selectServiceLoading)
  const service = useSelector(selectServiceData)

  useEffect(() => {
    if (serviceID) {
      dispatch({ type: FETCH_SERVICE, id: serviceID })
    }
    return () => {
      dispatch({ type: RESET_SERVICE })
    }
  }, [dispatch, serviceID])

  const getMenu = () => {
    const menu = []
    if (service.property_level) {
      menu.push({
        label: t('navigation:service_properties'),
        link: './properties'
      })
    }
    if (service.room_level && !pages?.custom?.house_maker) {
      menu.push({
        label: t('navigation:service_rooms'),
        link: './rooms'
      })
    }
    if (service.contract_level) {
      menu.push({
        label: t('navigation:service_contracts'),
        link: './contracts'
      })
    }

    if (SERVICES_WITH_SETTINGS.includes(String(serviceID))) {
      menu.push({
        label: t('navigation:settings'),
        link: './additional_settings'
      })
    }

    return menu
  }

  if (!serviceID) return null
  if (!enabled || !children || !pages) return <NotFoundPage />
  return (
    <PageLayout
      sidebar={loading || (error && error.code === 404) || getMenu().length === 0 ? null : () => <LeftNavigationMenu menu={getMenu()}/>}
      noMain
    >
      {
        loading
          ? null
          : error && error.code === 404
            ? <ItemNotFound withSidebar item={t('service:service')} link={`/settings/services?type=${SERVICES_TYPES.INTERNAL}`} />
            : children
      }
    </PageLayout>
  )
}

ServiceBlock.propTypes = {
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  serviceID: PropTypes.string
}

export default ServiceBlock
