import React, { memo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  RoleControlled,
  Row,
  TextInput
} from '@gk-devteam/apmc-core-web'

import { selectPropertiesLoading } from '../../../../../selectors'

import { SearchButtonStyle } from '../../../../contract/ContractForm/ContractForm.style'

const PropertiesForm = ({ fetchData }) => {
  const loading = useSelector(selectPropertiesLoading)
  const [propertyName, setPropertyName] = useState('')
  const [id, setID] = useState('')
  const [manageID, setManageID] = useState('')

  const _handlePropertyNameChange = useCallback(
    (e) => {
      setPropertyName(e.target.value)
    },
    [setPropertyName]
  )
  const _handleIDChange = useCallback(
    (e) => {
      setID(e.target.value)
    },
    [setID]
  )
  const _handleManageIDChange = useCallback(
    (e) => {
      setManageID(e.target.value)
    },
    [setManageID]
  )

  const _handleSearchClick = useCallback(
    () => {
      fetchData({
        propertyName,
        id,
        manageID
      })
    },
    [
      fetchData,
      propertyName,
      id,
      manageID
    ]
  )

  const _handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        // _handleSearchClick()
      }
    },
    // [_handleSearchClick]
    []
  )

  return (
    <div>
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="username"
          label='properties:property_name'
          placeholder="placeholders.partial_property_name"
          onChange={_handlePropertyNameChange}
          value={propertyName}
          onKeyPress={_handleKeyPress}
        />
        {/* </Row>
      <Row> */}
        <RoleControlled authorized='!custom.house_maker'>
          <TextInput
            flex={1}
            type="text"
            name="id"
            label='ID'
            placeholder=""
            onChange={_handleIDChange}
            value={id}
            onKeyPress={_handleKeyPress}
          />
        </RoleControlled>
        <TextInput
          flex={1}
          type="text"
          name="manageID"
          label='properties:property_manage_id'
          placeholder="placeholders.partial_manage_id"
          onChange={_handleManageIDChange}
          value={manageID}
          onKeyPress={_handleKeyPress}
        />
        <SearchButtonStyle
          label="search"
          type="button"
          handleClick={_handleSearchClick}
          loading={loading}
        />
      </Row>
    </div>
  )
}

export default memo(PropertiesForm)

PropertiesForm.propTypes = {
  fetchData: PropTypes.func.isRequired
}
