import {
  SPACING,
  styled,
  Column,
  Tag
} from "@gk-devteam/apmc-core-web";

export const ResidentCellStyle = styled(Column)`
  min-width: 120px;
`

export const TagStyle = styled(Tag)`
  &&& {
    font-size: .65rem;
    padding:  1px .25rem;
    margin-top: ${SPACING.SMALLEST}rem;
  }

`
