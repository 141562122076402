import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MainLayout
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import {
  selectRenewContractFormData,
  selectContractLoading,
  selectContractStatus,
  selectRenewData,
  selectContractServices
} from '../../../selectors'
import { CONTRACT_STATUS } from '../../../constants'
import { FETCH_RENEW_CONTRACT, RESET_RENEW_CONTRACT } from '../../../types'

import ContractRenewForm from '../../../forms/contract/ContractRenewForm'
import NotFoundPage from '../../NotFoundPage/NotFoundPage'

const renewingStatuses = [
  CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value,
  CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value,
  CONTRACT_STATUS.RENEWING.value,
  CONTRACT_STATUS.RENEWING_CONFIRMED.value,
  CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value
]

const authorizedStatuses = [
  CONTRACT_STATUS.RESIDING.value,
  CONTRACT_STATUS.IN_RENEWING_PERIOD.value,
  CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value,
  CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value,
  CONTRACT_STATUS.RENEWING.value,
  CONTRACT_STATUS.RENEWING_CONFIRMED.value,
  CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value
]

function ContractRenewPage ({ navigate, contractID }) {
  const dispatch = useDispatch()
  const data = useSelector(selectRenewContractFormData)
  const contractServices = useSelector(selectContractServices)
  const renew = useSelector(selectRenewData)
  const status = useSelector(selectContractStatus)
  const loading = useSelector(selectContractLoading)
  const [pageTitle, setPageTitle] = useState('')

  useEffect(() => {
    if (status != null && !authorizedStatuses.includes(status)) {
      navigate('../')
    }
  }, [status, navigate])

  useEffect(() => {
    if (status != null && renewingStatuses.includes(status)) {
      dispatch({ type: FETCH_RENEW_CONTRACT, id: contractID, noLoading: true })
    }
    return () => {
      dispatch({ type: RESET_RENEW_CONTRACT })
    }
  }, [dispatch, contractID, status])

  useEffect(() => {
    switch (status) {
      case CONTRACT_STATUS.RESIDING.value:
        setPageTitle('navigation:contracts_ask_renew')
        break
      case CONTRACT_STATUS.IN_RENEWING_PERIOD.value:
        setPageTitle('navigation:contracts_renew_settings')
        break
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value:
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value:
      case CONTRACT_STATUS.RENEWING.value:
      case CONTRACT_STATUS.RENEWING_CONFIRMED.value:
      case CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value:
        setPageTitle('navigation:contracts_renew_confirm')
        break
      default:
        break
    }
  }, [status])

  const breadcrumbs = [
    {
      label: 'navigation:contracts',
      link: '/contracts'
    },
    {
      label: 'navigation:contracts_details',
      link: `/contracts/edit/${contractID}`
    },
    {
      label: pageTitle,
      link: `/contracts/edit/${contractID}/renew`
    }
  ]

  const _renderForm = () => {
    if (!data || status == null) return null
    if (!authorizedStatuses.includes(status)) return null
    if (data.status && renewingStatuses.includes(data.status) && !renew) return null
    return (
      <ContractRenewForm
        pageTitle={pageTitle}
        data={data}
        contractID={contractID}
      />
    )
  }

  if (!contractServices?.cloudsign) return <NotFoundPage withSidebar />

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      { _renderForm() }
    </MainLayout>
  )
}

ContractRenewPage.propTypes = {
  contractID: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired
}

export default ContractRenewPage
