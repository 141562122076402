import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchServices,
  fetchSeikatsu,
  toggleSeikatsu
} from '../../services'
import {
  FETCH_SERVICES,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAIL,
  FETCH_SEIKATSU,
  FETCH_SEIKATSU_SUCCESS,
  FETCH_SEIKATSU_FAIL,
  TOGGLE_SEIKATSU,
  TOGGLE_SEIKATSU_SUCCESS,
  TOGGLE_SEIKATSU_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchServicesSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchServices, query, cancelSource)
    if (res && res.data && res.data.results) {
      yield put({ type: FETCH_SERVICES_SUCCESS, payload: res.data.results })
    } else {
      yield put({ type: FETCH_SERVICES_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    console.log('err', error)
    yield put({ type: FETCH_SERVICES_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * fetchSeikatsuSaga ({ config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchSeikatsu, config, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_SEIKATSU_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_SEIKATSU_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    console.log('err', error)
    yield put({ type: FETCH_SEIKATSU_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * toggleSeikatsuSaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(toggleSeikatsu, data, config, cancelSource)
    if (res && res.status && res.status === 204) {
      yield put({ type: TOGGLE_SEIKATSU_SUCCESS })
      yield put({ type: FETCH_SEIKATSU, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: TOGGLE_SEIKATSU_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: TOGGLE_SEIKATSU_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchServices () {
  yield takeLatest(FETCH_SERVICES, fetchServicesSaga)
  yield takeLatest(FETCH_SEIKATSU, fetchSeikatsuSaga)
  yield takeLatest(TOGGLE_SEIKATSU, toggleSeikatsuSaga)
}
