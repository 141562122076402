import React, { memo } from 'react'
import {
  Row,
  ToastTitle,
  Text
} from '@gk-devteam/apmc-core-web'

import {
  ExampleTitleStyle,
  ExampleRowStyle
} from './ImportsPage.style'

const HelpProperty = (props) => {
  return (
    <>
      <Row><ToastTitle label="import:help.about_room_number" /></Row>
      <Row mt="S" mb="ML"><Text label="import:help.about_room_number_text" /></Row>
      <Row mb="R">
        <ExampleTitleStyle label="import:help.about_room_number_example"/>
      </Row>
      <ExampleRowStyle py="XS">
        <Text label="import:help.about_room_number_example_label_1"/>
        <Text label="import:help.about_room_number_example_value_1"/>
      </ExampleRowStyle>
      <ExampleRowStyle py="XS">
        <Text label="import:help.about_room_number_example_label_2"/>
        <Text label="import:help.about_room_number_example_value_2"/>
      </ExampleRowStyle>
    </>
  )
}

export default memo(HelpProperty)

HelpProperty.propTypes = {}
