// import test from './testReducer'
// import saga from './sagaReducer'
import auth from './auth/authReducer'
import areas from './areasReducer'
import dashboard from './dashboard/dashboardReducer'
import properties from './properties/propertiesReducer'
import property from './properties/propertyReducer'
import propertyDocuments from './properties/propertyDocumentsReducer'
import propertyEvents from './properties/propertyEventsReducer'
import rooms from './rooms/roomsReducer'
import room from './rooms/roomReducer'
import contracts from './contracts/contractsReducer'
import contractsProcessing from './contracts/contractsProcessingReducer'
import contract from './contracts/contractReducer'
import contractDocuments from './contracts/contractDocumentsReducer'
import opelo from './contracts/contractOpeloReducer'
import appUsers from './app_users/appUsersReducer'
import appUser from './app_users/appUserReducer'
import notices from './notices/noticesReducer'
import notice from './notices/noticeReducer'
import userProfile from './settings/userProfileReducer'
import adminProfile from './settings/adminProfileReducer'
import userPreferences from './settings/userPreferencesReducer'
import services from './app_services/servicesReducer'
import service from './app_services/serviceReducer'
import seikatsu from './app_services/seikatsuReducer'
import faq from './panels/faqReducer'
import manuals from './panels/manualsReducer'
import vicinities from './panels/vicinitiesReducer'
import publicFacilities from './panels/publicFacilitiesReducer'
import guideCategories from './panels/guideCategoriesReducer'
import guides from './panels/guidesReducer'
import contacts from './panels/contactsReducers'
import refuse from './panels/refuseReducer'
import app from './app/appReducer'
import checklist from './checklist/checklistReducer'
import chat from './chat/chatReducer'
import socketUser from './firebaseUserReducer'
import dataImport from './import/importReducer'
import members from './user_management/membersReducer'
import member from './user_management/memberReducer'
import guests from './user_management/guestsReducer'
import guest from './user_management/guestReducer'
import propertiesGroups from './user_management/propertiesGroupsReducer'
import propertiesGroup from './user_management/propertiesGroupReducer'
import inui from './inui/inuiReducer'
import coupons from './coupons/couponsReducer'
import coupon from './coupons/couponReducer'
import datasync from './datasync/datasyncReducer'
import refunds from './refunds/refundsReducer'
import refund from './refunds/refundReducer'
import inspections from './house_maker/inspectionsReducer'
import contractInspections from './house_maker/contractInspectionsReducer'
import inquiries from './inquiries/inquiriesReducers'
import surveys from './surveys/surveysReducers'
import survey from './surveys/surveyReducer'
import spaces from './spaces/spacesReducers'
import space from './spaces/spaceReducers'
import support from './support/supportReducer'
import chouei from './chouei/choueiReducer'
import yasueConstructionWorks from './yasue/constructionWorksReducer'
import yasueConstructionWork from './yasue/constructionWorkReducer'
import yasueConsttuctionWorkFiles from './yasue/constructionWorkFilesReducer'
import yasueSurveys from './yasue/yasueSurveysReducer'
import yasueSurvey from './yasue/yasueSurveyReducer'
import yasueContacts from './yasue/contactsReducer'
import yasueMaintenance from './yasue/maintenanceReducer'

const rootReducer = {
  // test,
  // saga
  auth,
  app,
  socketUser,
  dataImport,
  areas,
  chat,
  checklist,
  userPreferences,
  dashboard,
  surveys,
  survey,
  spaces,
  space,
  properties,
  property,
  propertyDocuments,
  propertyEvents,
  rooms,
  room,
  contracts,
  contractsProcessing,
  contract,
  contractDocuments,
  opelo,
  appUsers,
  appUser,
  notices,
  notice,
  userProfile,
  adminProfile,
  faq,
  manuals,
  vicinities,
  publicFacilities,
  guideCategories,
  guides,
  services,
  service,
  seikatsu,
  contacts,
  refuse,
  members,
  member,
  guests,
  guest,
  propertiesGroups,
  propertiesGroup,
  inui,
  coupons,
  coupon,
  datasync,
  refunds,
  refund,
  inspections,
  contractInspections,
  inquiries,
  support,
  chouei,
  yasueConstructionWorks,
  yasueConstructionWork,
  yasueConsttuctionWorkFiles,
  yasueSurveys,
  yasueSurvey,
  yasueContacts,
  yasueMaintenance
}

export default rootReducer
