import React from 'react'
import {
  RangePicker, RoleControlled
} from '@gk-devteam/apmc-core-web'

const ContractDatepickers = () => {
  return (
    <>
      <RangePicker
        name_start="contract_start_date_from"
        name_end="contract_start_date_to"
        label='contracts:contract_start_date'
        placeholder=""
        mr="0"
      />
      <RoleControlled authorized='!custom.house_maker'>
        <RangePicker
          name_start="contract_end_date_from"
          name_end="contract_end_date_to"
          label='contracts:contract_end_date'
          placeholder=""
          mr="0"
        />
        <RangePicker
          name_start="move_out_date_from"
          name_end="move_out_date_to"
          label='contracts:move_out_date'
          placeholder=""
          mr="0"
        />
      </RoleControlled>
    </>
  )
}

export default ContractDatepickers

// ContractDatepickers.propTypes = {}
