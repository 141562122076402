import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import {
  RESET_PARAMS,
  useTranslation,
  selectQueryParams,
  dynamicLink,
  PageLayout,
  Row,
  Title,
  LightText,
  Table,
  Tag,
  selectPages,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_APP_USERS,
  RESET_APP_USERS,
  DELETE_APP_USER,
  SEND_APP_USER_SMS,
  SEND_APP_USER_LOGIN,
  RESET_USER_LOGIN,
  SEND_USER_VERIFY_EMAIL
} from '../../../types'
import {
  selectAppUsersLoading,
  selectAppUsersData,
  selectAppUsersCount,
  selectUserRole
} from '../../../selectors'
import { appUsersSearchSchema } from '../../../validation'
import { downloadLoginPDF, fetchAppUsers } from '../../../services'
import { LOGIN_STATUS, ROLES } from '../../../constants'

import AppUsersSearchBar from './AppUsersSearchBar'
import UserStatusLegend from '../../../components/Display/UserStatusLegend'
import DownloadCSVButton from '../../../components/Misc/DownloadCSVButton'
import QrModal from '../common/QrModal'
import DownloadUsersLoginFile from './DownloadUsersLoginFile'

const breadcrumbs = [
  {
    label: 'navigation:app_users_list',
    link: '/app_users'
  }
]

const UsersPage = ({ navigate, location }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const { t } = useTranslation(['common', 'contracts'])
  const prevQuery = useRef(null)
  const userRole = useSelector(selectUserRole)
  const pages = useSelector(selectPages)
  const [selectedId, setSelectedId] = useState(null)
  const [qrVisible, setQrVisible] = useState(false)
  const [columns, setColumns] = useState([])

  const fetchData = useCallback(
    () => {
      const schema = appUsersSearchSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_APP_USERS, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_APP_USERS })
          }
        })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_APP_USERS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const userStatus = useCallback(
    (value, cellKey, rowID, className) => {
      let label, color
      switch (value) {
        case 1:
          label = 'app_users:login_status.no_login'
          color = 'text'
          break
        case 2:
          label = 'app_users:login_status.active'
          color = 'info'
          break
        case 3:
          label = 'app_users:login_status.inactive'
          color = 'pageTitle'
          break
        case 4:
          label = 'app_users:login_status.no_confirmation'
          color = 'accent'
          break

        default:
          break
      }

      return <Tag label={label} color={color}/>
    },
    []
  )
  const currentPropertiesCell = useCallback(
    (value, cellKey, rowID, className) => {
      let text = ''
      if (value && value.length > 0) {
        text = value[0]
        if (value.length > 1) {
        // eslint-disable-next-line no-irregular-whitespace
          text += `　他${value.length - 1}件`
        }
      }

      return <LightText text={text || '-'}/>
    },
    []
  )
  const currentContractsCell = useCallback(
    (value, cellKey, rowID, className) => {
      let text = ''
      if (value && value.length > 0) {
        text = value[0].room_number
        if (value.length > 1) {
        // eslint-disable-next-line no-irregular-whitespace
          text += `　他${value.length - 1}件`
        }
      }

      return <LightText text={text || '-'}/>
    },
    []
  )
  const _handleLoginResettable = useCallback(
    (data) => {
      return userRole && userRole !== ROLES.GUEST &&
        // data.current_contracts != null &&
        data?.login_status === LOGIN_STATUS.NO_CONFIRMATION
    },
    [userRole]
  )
  const _handleSMSSendable = useCallback(
    (data) => {
      return userRole && userRole !== ROLES.GUEST &&
        // data.current_contracts != null &&
        data?.login_status === LOGIN_STATUS.NO_LOGIN
    },
    [userRole]
  )
  const _canShowLoginInfo = useCallback(
    (data) => {
      return userRole && userRole !== ROLES.GUEST &&
      // data.current_contracts != null &&
        data?.login_status === LOGIN_STATUS.NO_LOGIN
    },
    [userRole]
  )
  const _canSendVerifyToken = useCallback(
    (data) => {
      return userRole && userRole !== ROLES.GUEST &&
      // data.current_contracts != null &&
        data?.login_status === LOGIN_STATUS.NO_CONFIRMATION
    },
    [userRole]
  )
  const _canShowChatLink = useCallback(
    () => {
      if (!pages) return false
      return pages?.chat?.enabled && pages?.chat?.functions?.details
    },
    [pages]
  )
  const _canShowChecklistLink = useCallback(
    () => {
      if (!pages) return false
      if (pages?.custom?.house_maker || pages?.custom?.yasue) return false
      return pages?.checklist?.enabled && pages?.checklist?.functions?.details
    },
    [pages]
  )

  const showQR = (id) => {
    setQrVisible(true)
    setSelectedId(id)
  }
  const closeModal = () => {
    setQrVisible(false)
    setSelectedId(null)
  }

  useEffect(() => {
    let columns = [
      {
        title: t('app_users:app_user_name'),
        dataIndex: 'app_user_name',
        key: 'app_user_name',
        cellTitle: true,
        sortable: true
      },
      {
        title: t('app_users:login_status.label'),
        dataIndex: 'login_status',
        key: 'login_status',
        render: userStatus
      },
      {
        title: t('properties:property_name'),
        dataIndex: 'property_names',
        key: 'property_names',
        render: currentPropertiesCell
      },
      {
        title: t('properties:room_number'),
        dataIndex: 'current_contracts',
        key: 'current_contracts',
        render: currentContractsCell,
        authorized: '!custom.house_maker'
      },
      {
        title: t('app_users:app_user_id'),
        dataIndex: 'id',
        key: 'id',
        sortable: true
      },
      {
        title: t(pages?.custom?.chouei ? 'chouei:bellevie_id' : 'app_users:app_user_manage_id'),
        dataIndex: 'app_user_manage_id',
        key: 'app_user_manage_id',
        sortable: true
      },
      {
        title: t('app_users:residencies_count'),
        dataIndex: 'residencies_count',
        key: 'residencies_count',
        authorized: '!custom.house_maker'
      },
      {
        title: t('app_users:contracts_count'),
        dataIndex: 'contracts_count',
        key: 'contracts_count',
        authorized: '!custom.house_maker'
      },
      {
        title: ' ',
        dataIndex: '',
        key: 'option_menu',
        supportedRoles: [
          ROLES.AGENCY_MAIN_ADMIN,
          ROLES.AGENCY_ADMIN,
          ROLES.AGENCY_EDITOR,
          ROLES.AGENCY_MEMBER,
          ROLES.OWNER
        ],
        rowMenu: [
          {
            label: t('app_users:send_verify_token'),
            isDisabled: (data) => {
              return !data.can_send_verify_token
            },
            onClick: (id, close) => {
              dispatch({ type: SEND_USER_VERIFY_EMAIL, id, origin: 'list' })
              close()
            },
            shouldRender: _canSendVerifyToken
          },
          {
            label: t('app_users:reset_user_login'),
            danger: true,
            dangerLabel: 'app_users:reset_user_login_question',
            onClick: (id, close) => {
              dispatch({ type: RESET_USER_LOGIN, id, origin: 'list' })
              close()
            },
            shouldRender: _handleLoginResettable
          },
          {
            label: t('app_users:send_sms'),
            onClick: (id, close) => {
              dispatch({ type: SEND_APP_USER_SMS, id })
              close()
            },
            shouldRender: _handleSMSSendable
          },
          {
            label: t('app_users:temp_login.label'),
            items: [
              {
                label: t('app_users:temp_login.sms'),
                isDisabled: (data) => {
                  return !data.can_send_sms
                },
                onClick: (id, close) => {
                  dispatch({ type: SEND_APP_USER_LOGIN, id, sendType: 1, origin: 'list' })
                  close()
                }
              },
              {
                label: t('app_users:temp_login.email'),
                isDisabled: (data) => {
                  return !data.can_send_email
                },
                onClick: (id, close) => {
                  dispatch({ type: SEND_APP_USER_LOGIN, id, sendType: 2, origin: 'list' })
                  close()
                }
              },
              {
                label: t('app_users:temp_login.qr'),
                onClick: (id, close) => {
                  showQR(id)
                  close()
                }
              },
              {
                label: t('app_users:temp_login.download_qr'),
                onClick: (id, close) => {
                  downloadLoginPDF(id)
                  close()
                }
              }
            ],
            shouldRender: _canShowLoginInfo
          },
          {
            label: t('app_users:to_chat_page'),
            onClick: (id, close, row) => {
              navigate(`/admin_tasks/chat?userID=${id}`)
              close()
            },
            shouldRender: _canShowChatLink
          },
          {
            label: t('admin_tasks:to_checklist'),
            onClick: (id, close, row) => {
              navigate(`/admin_tasks/checklist?app_user_name=${row.app_user_name}`)
              close()
            },
            shouldRender: _canShowChecklistLink
          },
          {
            label: t('app_users:to_notice_add_page'),
            onClick: (id, close, row) => {
              navigate('/notices/add', { state: { userID: id, userName: row.app_user_name } })
              close()
            },
            shouldRender: 'notices.functions.add'
          },
          {
            label: t('delete'),
            danger: true,
            onClick: (id, close) => {
              console.log('warning', id)
              dispatch({ type: DELETE_APP_USER, id })
              close()
            },
            shouldRender: 'app_users.functions.delete'
          }
        ]
      }
    ]

    if (pages?.custom?.house_maker) {
      columns.splice(3, 0, {
        title: t('app_users:registered_name'),
        dataIndex: 'registered_name',
        key: 'registered_name'
      })
      columns.splice(4, 0, {
        title: t('app_users:registered_address'),
        dataIndex: 'registered_address',
        key: 'registered_address'
      })
    }

    if (pages?.custom?.yasue) {
      columns = [
        {
          title: t('app_users:app_user_name'),
          dataIndex: 'app_user_name',
          key: 'app_user_name',
          cellTitle: true,
          sortable: true
        },
        {
          title: t('app_users:login_status.label'),
          dataIndex: 'login_status',
          key: 'login_status',
          render: userStatus
        },
        {
          title: t('yasue:contracts.contract_manage_id'),
          dataIndex: 'property_names',
          key: 'property_names',
          render: currentPropertiesCell
        },
        {
          title: t('app_users:app_user_id'),
          dataIndex: 'id',
          key: 'id',
          sortable: true
        },
        {
          title: t('app_users:app_user_manage_id'),
          dataIndex: 'app_user_manage_id',
          key: 'app_user_manage_id',
          sortable: true
        },
        {
          title: ' ',
          dataIndex: '',
          key: 'option_menu',
          supportedRoles: [
            ROLES.AGENCY_MAIN_ADMIN,
            ROLES.AGENCY_ADMIN,
            ROLES.AGENCY_EDITOR,
            ROLES.AGENCY_MEMBER,
            ROLES.OWNER
          ],
          rowMenu: [
            {
              label: t('app_users:send_verify_token'),
              isDisabled: (data) => {
                return !data.can_send_verify_token
              },
              onClick: (id, close) => {
                dispatch({ type: SEND_USER_VERIFY_EMAIL, id, origin: 'list' })
                close()
              },
              shouldRender: _canSendVerifyToken
            },
            {
              label: t('app_users:reset_user_login'),
              danger: true,
              dangerLabel: 'app_users:reset_user_login_question',
              onClick: (id, close) => {
                dispatch({ type: RESET_USER_LOGIN, id, origin: 'list' })
                close()
              },
              shouldRender: _handleLoginResettable
            },
            {
              label: t('app_users:send_sms'),
              onClick: (id, close) => {
                dispatch({ type: SEND_APP_USER_SMS, id })
                close()
              },
              shouldRender: _handleSMSSendable
            },
            {
              label: t('app_users:temp_login.label'),
              items: [
                {
                  label: t('app_users:temp_login.sms'),
                  isDisabled: (data) => {
                    return !data.can_send_sms
                  },
                  onClick: (id, close) => {
                    dispatch({ type: SEND_APP_USER_LOGIN, id, sendType: 1, origin: 'list' })
                    close()
                  }
                },
                {
                  label: t('app_users:temp_login.email'),
                  isDisabled: (data) => {
                    return !data.can_send_email
                  },
                  onClick: (id, close) => {
                    dispatch({ type: SEND_APP_USER_LOGIN, id, sendType: 2, origin: 'list' })
                    close()
                  }
                },
                {
                  label: t('app_users:temp_login.qr'),
                  onClick: (id, close) => {
                    showQR(id)
                    close()
                  }
                },
                {
                  label: t('app_users:temp_login.download_qr'),
                  onClick: (id, close) => {
                    downloadLoginPDF(id)
                    close()
                  }
                }
              ],
              shouldRender: _canShowLoginInfo
            },
            {
              label: t('app_users:to_chat_page'),
              onClick: (id, close, row) => {
                navigate(`/admin_tasks/chat?userID=${id}`)
                close()
              },
              shouldRender: _canShowChatLink
            },
            {
              label: t('yasue:contracts.add_construction_work'),
              onClick: (id, close, row) => {
                navigate('/yasue/construction_works/add', {
                  state: { contract: row?.current_contracts?.[0] }
                })
                close()
              },
              shouldRender: 'admin_tasks.functions.chat'
            },
            {
              label: t('app_users:to_notice_add_page'),
              onClick: (id, close, row) => {
                navigate('/notices/add', { state: { userID: id, userName: row.app_user_name } })
                close()
              },
              shouldRender: 'notices.functions.add'
            },
            {
              label: t('delete'),
              danger: true,
              onClick: (id, close) => {
                console.log('warning', id)
                dispatch({ type: DELETE_APP_USER, id })
                close()
              },
              shouldRender: 'app_users.functions.delete'
            }
          ]
        }
      ]
    }

    setColumns(columns)
  }, [
    _canSendVerifyToken,
    _canShowChatLink,
    _canShowChecklistLink,
    _canShowLoginInfo,
    _handleLoginResettable,
    _handleSMSSendable,
    currentContractsCell,
    currentPropertiesCell,
    dispatch,
    navigate,
    pages.custom.chouei,
    t,
    userStatus,
    pages
  ])

  const navigateToDetails = (id, row, e) => {
    const url = `/app_users/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <>
      <QrModal
        id={selectedId}
        visible={qrVisible}
        close={closeModal}
      />
      <PageLayout
        sidebar={
          () => <AppUsersSearchBar />
        }
        breadcrumbs={breadcrumbs}
      >
        <Row justify="between" align="center" mt="R">
          <Title label="navigation:app_users_list"/>
          <div>
            <RoleControlled authorized="custom.anabuki">
              <DownloadUsersLoginFile />
            </RoleControlled>
            <RoleControlled authorized="!custom.yasue">
              <DownloadCSVButton
                schemaBuilder={appUsersSearchSchema}
                fetchCSV={fetchAppUsers}
                filename="user.csv"
              />
            </RoleControlled>
          </div>
        </Row>
        <Table
          fullHeight
          columns={columns}
          rowKey="id"
          loadingSelector={selectAppUsersLoading}
          resultsCountSelector={selectAppUsersCount}
          dataSelector={selectAppUsersData}
          onRowClick={navigateToDetails}
        />
        <UserStatusLegend />
      </PageLayout>
    </>
  )
}
export default UsersPage

UsersPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
UsersPage.defaultProps = {

}
