import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_FORM,
  LeftSearchMenu,
  TextInput,
  selectQueryParams,
  RangePicker
} from '@gk-devteam/apmc-core-web'

import { yasueConstructionWorksSearchSchema } from '../../../validation/schemas/yasueConstructionWorksSearchValidation'

const initialValues = {
  yasue_construction_work_id: '',
  contract_id: '',
  contractor_name: '',
  created_from: '',
  created_to: ''
}

const YasueSurveysSearchBar = () => {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)
  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        yasue_construction_work_id: queryParams.yasue_construction_work_id,
        contract_id: queryParams.contract_id,
        contractor_name: queryParams.contractor_name,
        created_from: queryParams.created_from,
        created_to: queryParams.created_to
      }
    })
  }, [queryParams, dispatch])

  return (
    <>
      <LeftSearchMenu
        resetValues={initialValues}
        validationSchema={yasueConstructionWorksSearchSchema}
      >
        <TextInput
          type="number"
          name="yasue_construction_work_id"
          label='yasue:construction.construction_work_id'
          mr="0"
        />
        <TextInput
          type="number"
          name="contract_id"
          label='contracts:contract_id'
          mr="0"
        />
        <TextInput
          type="text"
          name="contractor_name"
          label='contracts:contractor_name'
          mr="0"
        />
        <RangePicker
          name_start="created_from"
          name_end="created_to"
          label='yasue:survey.created'
          placeholder=""
          mr="0"
          showTime
        />

      </LeftSearchMenu>
    </>
  )
}

export default memo(YasueSurveysSearchBar)
