import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  DatePicker,
  Form,
  // FormControlLabel,
  // useTranslation,
  FormReadOnlyLink,
  RepeaterFields,
  Row,
  TextInput,
  TextInputStandAlone
} from '@gk-devteam/apmc-core-web'

import { selectRefundLoading } from '../../selectors'

import ParkingRow from './ParkingRow'
import Receipts from './Receipts'

function RefundForm ({ data }) {
  const [total, setTotal] = useState(0)
  // const { t } = useTranslation()
  const initialValues = {
    ...data,
    image: null,
    image_url: data?.image || null,
    delete_image: false,
    no_limit: false
  }

  const handleFormSubmit = (data) => {
    // console.log('do nothing', data)
  }

  useEffect(() => {
    let subtotal = 0
    if (data?.parking) {
      for (const receipt of data?.parking) {
        const { amount } = receipt
        subtotal += amount
      }
    }
    setTotal(subtotal)
  }, [data])

  return (
    <Form
      id="submitRefund"
      handleSubmit={handleFormSubmit}
      validationSchema={null}
      loadingSelector={selectRefundLoading}
      postingSelector={selectRefundLoading}
      initialData={initialValues}
      authorized={false}
    >
      <Card my="ML">
        <Row wrap="wrap" align="baseline">
          <FormReadOnlyLink
            label="app_users:app_user_name"
            text={data?.user_name}
            to={`/app_users/edit/${data?.user_id}`}
            flex='auto'
          />
          <FormReadOnlyLink
            label="properties:property_name"
            text={data?.property_name}
            to={`/properties/edit/${data?.property_id}`}
            flex='auto'
          />
          <TextInput
            type="text"
            name="room_number"
            label='properties:room_number'
            size="XS"
          />
          <DatePicker
            name="application_date"
            label='refunds:application_date'
            size="XS"
          />
          <TextInputStandAlone
            name="total"
            label="total"
            type="number"
            suffix="currency_symbol"
            value={total}
            readonly
          />
        </Row>
        {/* <Row wrap="wrap" align="baseline">
          <FormControlLabel label={t('refunds:parking')} />
        </Row> */}
        <Row wrap="wrap" align="baseline" mt="ML">
          <RepeaterFields
            propertyKey="parking"
            uniqField="index"
            field={(index) => <ParkingRow key={index} index={index}/>}
          />
        </Row>
      </Card>
      <Receipts />
    </Form>
  )
}

RefundForm.propTypes = {
  data: PropTypes.object
}

export default RefundForm
