import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_REFUNDS,
  FETCH_REFUNDS_SUCCESS,
  FETCH_REFUNDS_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  max_pages: null,
  result_count: null,
  results: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_REFUNDS]: (state, action) => {
    if (action && !action.noLoading) {
      state.loading = true
    }
    state.error = null
  },
  [FETCH_REFUNDS_SUCCESS]: (state, action) => {
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_REFUNDS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  }
})
