import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  PageLayout,
  Row,
  Title,
  Card
} from '@gk-devteam/apmc-core-web'

import SearchForm from './SearchForm'
import PanelDisabled from '../../../components/Display/Panels/PanelDisabled'
import {
  LeftColumnStyle,
  UnresolvedLabelStyle,
  UnresolvedCountStyle,
  UnresolvedCountUnitStyle
} from '../adminTasks.style'

const ListPage = ({
  breadcrumbs,
  pageTitle,
  hiddenSelector,
  hiddenByIdSelector,
  countSelector,
  validationSchema,
  initialValues,
  searchInputs,
  table
}) => {
  const rowRef = useRef(null)
  const count = useSelector(countSelector)

  const _renderContent = () => (
    <div ref={rowRef}>
      <Row mt="L" align="start">
        <LeftColumnStyle>
          <Card title="admin_tasks:info">
            <Row>
              <UnresolvedLabelStyle label="admin_tasks:unresolved_count" />
            </Row>
            <Row mt="R" align="end" pl="S">
              <UnresolvedCountStyle text={count} />
              <UnresolvedCountUnitStyle label="result_unit" />
            </Row>
          </Card>
          <SearchForm
            validationSchema={validationSchema}
            initialValues={initialValues}
            searchInputs={searchInputs}
          />
        </LeftColumnStyle>
        { table }
      </Row>
    </div>
  )

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      // loading={!data}
    >
      <Row>
        <Title label={pageTitle}/>
      </Row>
      {
        hiddenSelector && hiddenByIdSelector
          ? (
            <PanelDisabled hiddenSelector={hiddenSelector} hiddenByIdSelector={hiddenByIdSelector}>
              {_renderContent()}
            </PanelDisabled>
          )
          : _renderContent()
      }

    </PageLayout>
  )
}

export default ListPage

ListPage.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  pageTitle: PropTypes.string.isRequired,
  hiddenSelector: PropTypes.func,
  hiddenByIdSelector: PropTypes.func,
  countSelector: PropTypes.func.isRequired,
  validationSchema: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  searchInputs: PropTypes.node.isRequired,
  table: PropTypes.node.isRequired
}
