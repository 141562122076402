import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import { POST_GUIDE_CATEGORY } from '../../../../types'
import { selectGuideCategoriesPosting } from '../../../../selectors'

import GuideCategoryForm from '../../../../forms/GuideCategoryForm/GuideCategoryForm'

const GuideCategoryModal = ({ visible, onCancel, type, id, editMode }) => {
  const dispatch = useDispatch()
  const posting = useSelector(selectGuideCategoriesPosting)

  const _handleSubmit = (data) => {
    const onSuccess = () => {
      onCancel()
    }
    dispatch({ type: POST_GUIDE_CATEGORY, data, config: { [type]: id }, onSuccess })
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={_handleSubmit}
      okText="save"
      title={editMode ? 'guides:edit_guide_category' : 'guides:add_guide_category'}
      formID="guide_category_form"
      posting={posting}
      fullWidth
    >
      <GuideCategoryForm handleSubmit={_handleSubmit}/>
    </Modal>
  )
}

export default GuideCategoryModal

GuideCategoryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['property', 'room']),
  id: PropTypes.string
}
