import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchGuides,
  postGuide,
  deleteGuide,
  toggleGuide,
  bulkToggleGuides
} from '../../services'
import {
  FETCH_GUIDES,
  FETCH_GUIDES_SUCCESS,
  FETCH_GUIDES_FAIL,
  POST_GUIDE,
  POST_GUIDE_SUCCESS,
  POST_GUIDE_FAIL,
  DELETE_GUIDE,
  DELETE_GUIDE_SUCCESS,
  DELETE_GUIDE_FAIL,
  TOGGLE_GUIDE,
  TOGGLE_GUIDE_SUCCESS,
  TOGGLE_GUIDE_FAIL,
  BULK_TOGGLE_GUIDES,
  BULK_TOGGLE_GUIDES_SUCCESS,
  BULK_TOGGLE_GUIDES_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchGuidesSaga ({ config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchGuides, config, cancelSource)
    if (res && res.data && res.data.results) {
      yield put({ type: FETCH_GUIDES_SUCCESS, payload: { data: res.data.results, categoryName: res.data.category_name } })
    } else {
      yield put({ type: FETCH_GUIDES_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_GUIDES_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postGuideSaga ({ data, config, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postGuide, data, config, cancelSource)
    if (res && res.data && res.data.success) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_GUIDE_SUCCESS })
      yield put({ type: FETCH_GUIDES, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_GUIDE_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_GUIDE_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteGuideSaga ({ id, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteGuide, id, config, cancelSource)
    if (res) {
      yield put({ type: DELETE_GUIDE_SUCCESS })
      yield put({ type: FETCH_GUIDES, config })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_GUIDE_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * toggleGuideSaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(toggleGuide, data, config, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: TOGGLE_GUIDE_SUCCESS })
      yield put({ type: FETCH_GUIDES, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: TOGGLE_GUIDE_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: TOGGLE_GUIDE_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * bulkToggleGuidesSaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(bulkToggleGuides, data, config, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: BULK_TOGGLE_GUIDES_SUCCESS })
      yield put({ type: FETCH_GUIDES, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: BULK_TOGGLE_GUIDES_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: BULK_TOGGLE_GUIDES_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchGuides () {
  yield takeLatest(FETCH_GUIDES, fetchGuidesSaga)
  yield takeLatest(POST_GUIDE, postGuideSaga)
  yield takeLatest(DELETE_GUIDE, deleteGuideSaga)
  yield takeLatest(TOGGLE_GUIDE, toggleGuideSaga)
  yield takeLatest(BULK_TOGGLE_GUIDES, bulkToggleGuidesSaga)
}
