import React, { memo } from 'react'
import { Card } from '@gk-devteam/apmc-core-web'

import RecipientType from './RecipientType'
import RecipientsSearch from './RecipientsSearch'
import RecipientsList from './RecipientsList'

const RecipientsSettings = () => {
  return (
    <Card title='surveys:recipient_type' mb="L">
      <RecipientType />
      <RecipientsSearch />
      <RecipientsList />
    </Card>
  )
}

export default memo(RecipientsSettings)
