import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import {
  selectGuidesData,
  selectGuidesAllDisplayed,
  selectRoomID
} from '../../../../selectors'
import { TOGGLE_GUIDE } from '../../../../types'

import GuidesList from '../common/GuidesList'

const AdminGuidesList = ({
  editModal,
  guideCategoryID
}) => {
  const dispatch = useDispatch()

  const _handleToggleDisplay = useCallback(
    (display, id, item) => {
      const data = {
        id: item.id,
        display
      }
      const config = {
        guideCategoryID,
        type: 'admin_guides',
        room: id
      }
      dispatch({ type: TOGGLE_GUIDE, data, config })
    },
    [dispatch, guideCategoryID]
  )

  return (
    <GuidesList
      dataSelector={selectGuidesData}
      displaySelector={selectGuidesAllDisplayed}
      idSelector={selectRoomID}
      title="　"
      type="admin_guides"
      level="room"
      editModal={editModal}
      handleToggleDisplay={_handleToggleDisplay}
      extraConfig = {{ guideCategoryID }}
    />
  )
}

export default AdminGuidesList

AdminGuidesList.propTypes = {
  editModal: PropTypes.func,
  guideCategoryID: PropTypes.string.isRequired
}
