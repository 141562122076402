import {
  api,
  DEFAULT_QUERY_LIMIT
} from '@gk-devteam/apmc-core-web'

export const fetchSurveys = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }

  return api.get('/surveys', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token
  })
}

export const fetchSurvey = (id, cancelSource) => {
  return api.get(`/surveys/${id}`, {
    cancelToken: cancelSource.token
  })
}
export const fetchSurveyAnswer = (surveyId, answerId, cancelSource) => {
  return api.get(`/surveys/${surveyId}/answer/${answerId}`, {
    cancelToken: cancelSource.token
  })
}
export const fetchSurveyAnswerUsers = (data, cancelSource) => {
  return api.post('/surveys/get_answer_user', data, {
    cancelToken: cancelSource.token
  })
}
export const postSurvey = async (data, cancelSource) => {
  return api.post('/surveys', data, {
    cancelToken: cancelSource.token
  })
}
export const updateSurveyStatus = async (data, cancelSource) => {
  return api.post('/surveys/status', data, {
    cancelToken: cancelSource.token
  })
}

export const deleteSurvey = (id, cancelSource) => {
  return api.delete(`/surveys/${id}`, {
    cancelToken: cancelSource.token
  })
}
