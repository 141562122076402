import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  Row,
  Title,
  Card,
  InquiryID,
  FilePicker,
  FormSubmitButton,
  RoleControlled,
  Form,
  TextInput,
  TextArea
} from '@gk-devteam/apmc-core-web'

import {
  selectLoadingCoupon,
  selectPostingCoupon
} from '../../selectors'
import { couponUpdateValidation } from '../../validation'
import { FETCH_COUPON, POST_COUPON } from '../../types'
import { INQUIRY_PREFIX } from '../../constants'

import CouponLimitDate from './subForms/CouponLimitDate'
import CouponRecipientsSettings from './subForms/CouponRecipients/CouponRecipientsSettings'

import { CouponFormMainBlockStyle } from './CouponForm.style.js'
import CouponLimitCount from './CouponLimitCount'

const CouponForm = ({ data, couponID }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const initialValues = {
    ...data,
    image: null,
    image_url: data?.image || null,
    delete_image: false,
    no_limit: false
  }

  if (data && data.recipient_type === 0) {
    initialValues.recipients_list = null
  }

  const handleFormSubmit = (data) => {
    if (!couponID) {
      const successRedirect = (id) => {
        if (couponID) {
          dispatch({ type: FETCH_COUPON, id: couponID })
        } else {
          navigate(`/coupons/edit/${id}`)
        }
        navigate('/coupons')
      }
      dispatch({ type: POST_COUPON, data, successRedirect })
    }
  }

  return (
    <Form
      id="updateAppUserForm"
      handleSubmit={handleFormSubmit}
      validationSchema={couponUpdateValidation}
      loadingSelector={selectLoadingCoupon}
      postingSelector={selectPostingCoupon}
      initialData={initialValues}
      authorized={!couponID}
    >
      <Row justify="between" >
        {
          couponID
            ? <Title text={data && data.title} />
            : <Title label='coupons:add_coupon' />
        }
        <RoleControlled authorized={!couponID}>
          <FormSubmitButton editMode={!!couponID} postingSelector={selectPostingCoupon} />
        </RoleControlled>
      </Row>
      <Row mb="ML">
        <InquiryID prefix={INQUIRY_PREFIX.COUPON} id={couponID} />
      </Row>
      <Row wrap="wrap">
        <CouponFormMainBlockStyle>
          <Card mb="L">
            <Row wrap="wrap" align="baseline">
              <TextInput
                flex={1}
                type="text"
                name="title"
                label='title'
                placeholder=""
                required
              />
            </Row>
            <Row wrap="wrap" align="baseline">
              <TextInput
                flex={1}
                type="number"
                name="value"
                label='coupons:value'
                placeholder=""
                required
              />
              <CouponLimitCount/>
              <CouponLimitDate />
            </Row>
            <Row wrap="wrap" align="baseline">
              <TextArea
                flex={1}
                name="description"
                label='description'
                placeholder=""
                required
              />
            </Row>
          </Card>
          <Card title='image' mb="L">
            <FilePicker
              name="image"
              delete_name="delete_image"
              types={['image']}
            />
          </Card>
          <CouponRecipientsSettings/>
        </CouponFormMainBlockStyle>
      </Row>
    </Form>
  )
}
export default CouponForm

CouponForm.propTypes = {
  data: PropTypes.object,
  couponID: PropTypes.string
}
CouponForm.defaultProps = {

}
