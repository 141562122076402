import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  MainLayout
} from '@gk-devteam/apmc-core-web'

import { ROLES } from '../../../constants'
import { FETCH_ADMIN_PROFILE, RESET_ADMIN_PROFILE } from '../../../types'
import {
  selectSettingAdminProfileData,
  selectOwnerID,
  selectUserRole
} from '../../../selectors'

import AdminProfileForm from '../../../forms/settings/AdminProfileForm'

const AdminProfilePage = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectSettingAdminProfileData)
  const ownerID = useSelector(selectOwnerID)
  const userRole = useSelector(selectUserRole)

  useEffect(() => {
    if (ownerID) {
      dispatch({ type: FETCH_ADMIN_PROFILE, id: ownerID })
    }
    return () => {
      dispatch({ type: RESET_ADMIN_PROFILE })
    }
  }, [dispatch, ownerID])

  const breadcrumbs = [
    {
      label: 'navigation:settings',
      link: '/settings'
    },
    {
      label: userRole && userRole === ROLES.OWNER ? 'navigation:settings_owner_profile' : 'navigation:settings_admin_profile',
      link: '/settings/'
    }
  ]

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      // loading={loading}
      // lastEdited={lastEdited}
      // lastEditedBy={lastEditedBy}
    >
      <AdminProfileForm data={data}/>
    </MainLayout>
  )
}
export default AdminProfilePage

AdminProfilePage.propTypes = {}
AdminProfilePage.defaultProps = {}
