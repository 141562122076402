import React, { memo } from 'react'
import { Progress } from 'antd'
import { useSelector } from 'react-redux'

import {
  selectCurrentStep,
  selectImportProgress,
  selectImportError,
  selectImportPartialErrors,
  selectTheme
} from '../../selectors'
import { IMPORT_STEPS } from '../../constants'

const ImportProgressBar = (props) => {
  const step = useSelector(selectCurrentStep)
  const progress = useSelector(selectImportProgress)
  const error = useSelector(selectImportError)
  const partialErrors = useSelector(selectImportPartialErrors)
  const theme = useSelector(selectTheme)

  let status, strokeColor
  if (step === IMPORT_STEPS.UPLOAD) status = 'active'
  if (step === IMPORT_STEPS.DONE) {
    if (error) {
      status = 'exception'
    }
  }
  if (partialErrors && partialErrors.length > 0) {
    strokeColor = theme.colors.warning
  }
  return (
    <Progress
      percent={progress}
      size="small"
      status={status}
      strokeColor={strokeColor}
      { ...props }
    />
  )
}

export default memo(ImportProgressBar)

ImportProgressBar.propTypes = {}
