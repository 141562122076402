import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import {
  dynamicLink,
  MainLayout,
  PrimaryButton,
  RESET_PARAMS,
  Row,
  selectQueryParams,
  Table,
  Tag,
  Text,
  Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { SPACE_TYPE } from '../../../constants'
import { selectSpacesLoading, selectPropertyName, selectSpacesCount, selectSpacesData } from '../../../selectors'
import { spacesSearchSchema } from '../../../validation'
import { FETCH_SPACES, RESET_SPACES, DELETE_SPACE } from '../../../types'

export default function PropertySpacesPage ({ propertyID, location, navigate }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const prevQuery = useRef(null)
  const propertyName = useSelector(selectPropertyName)
  const loading = useSelector(selectSpacesLoading)

  const fetchData = useCallback(
    async (params) => {
      if (propertyID) {
        const schema = spacesSearchSchema()
        const currentQuery = {
          property_id: propertyID,
          ...query,
          ...params
        }
        schema.isValid(currentQuery)
          .then(valid => {
            if (valid) {
              dispatch({ type: FETCH_SPACES, query: currentQuery })
            } else {
              console.warning('params invalid, query using default params')
              dispatch({ type: FETCH_SPACES, query: { property_id: propertyID } })
            }
          })
      }
    },
    [dispatch, query, propertyID]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_SPACES })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_spaces_list',
      link: `/properties/edit/${propertyID}/spaces`
    }
  ]

  const _handleAddClick = () => {
    navigate('./add')
  }

  const typeCell = useCallback(
    (value, cellKey, rowID, className) => {
      let label, color
      switch (value) {
        case SPACE_TYPE.PARKING:
          label = 'spaces:types.parking'
          color = 'process'
          break
        case SPACE_TYPE.COMMUNITY_ROOM:
          label = 'spaces:types.community_room'
          color = 'danger'
          break
        case SPACE_TYPE.ELECTRIC_VEHICLE_PARKING:
          label = 'spaces:types.electric_vehicle_parking'
          color = 'accent'
          break
        case SPACE_TYPE.CAR_WASH:
          label = 'spaces:types.car_wash'
          color = 'success'
          break
        case SPACE_TYPE.GUEST_ROOM:
          label = 'spaces:types.guest_room'
          color = 'warning'
          break
        case SPACE_TYPE.SKY_DECK:
          label = 'spaces:types.sky_deck'
          color = 'info'
          break
        default:
          break
      }

      return <Tag label={label} color={color}/>
    },
    []
  )
  const reservableCell = useCallback(
    (value, cellKey, rowID, className) => {
      return <Text text={value ? t('spaces:days_before', { value }) : t('spaces:no_limit')} />
    },
    [t]
  )
  const reservableTimeCell = useCallback(
    (value, cellKey, rowID, className, data) => {
      return (
        <>
          <Text text={data.reservable_from_time} />
          { data.reservable_to_time && <Text text={` - ${data.reservable_to_time}`} /> }
        </>
      )
    },
    []
  )

  const _handleCopy = useCallback(
    (id, close) => {
      close()
      navigate(`/properties/edit/${propertyID}/spaces/add`, { state: { id, copy: true } })
    },
    [navigate, propertyID]
  )

  const columns = [
    {
      title: t('spaces:name'),
      dataIndex: 'name',
      key: 'name',
      cellTitle: true,
      sortable: true
    },
    {
      title: t('spaces:type'),
      dataIndex: 'type',
      key: 'type',
      render: typeCell
    },
    {
      title: t('spaces:reservable_from_days'),
      dataIndex: 'reservable_from_days',
      key: 'reservable_from_days',
      render: reservableCell
    },
    {
      title: t('spaces:reservable_time'),
      dataIndex: 'reservable_from_time',
      key: 'reservable_from_time',
      render: reservableTimeCell
    },
    {
      title: t('spaces:usage_limit'),
      dataIndex: 'usage_limit',
      key: 'usage_limit'
    },
    {
      title: '',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('copy'),
          onClick: (id, close) => {
            _handleCopy(id, close)
          }
        },
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            const onSuccess = () => {
              fetchData()
            }
            dispatch({ type: DELETE_SPACE, id, onSuccess })
            close()
          }
        }
      ]
    }
  ]

  const navigateToDetails = (id, row, e) => {
    const url = `/properties/edit/${propertyID}/spaces/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      <Row justify="between" align="center" my="R" wrap={'wrap'}>
        <Title label="navigation:properties_spaces_list"/>
        <PrimaryButton
          label="navigation:properties_spaces_add"
          handleClick={_handleAddClick}
          reverse
          withMargin
        />
      </Row>
      <Table
        fullHeight
        columns={columns}
        rowKey="space_id"
        loadingSelector={selectSpacesLoading}
        resultsCountSelector={selectSpacesCount}
        dataSelector={selectSpacesData}
        onRowClick={navigateToDetails}
      />
    </MainLayout>
  )
}

PropertySpacesPage.propTypes = {
  location: PropTypes.object.isRequired,
  propertyID: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired
}
PropertySpacesPage.defaultProps = {

}
