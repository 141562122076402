import React from 'react'
import { useSelector } from 'react-redux'

import { selectSurveyQuestions } from '../../../selectors'
import Answer from './Answer'

export default function Answers () {
  const questions = useSelector(selectSurveyQuestions)
  return questions.map((question, i) => <Answer data={question} key={i} />)
}
