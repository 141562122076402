import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from '@reach/router'
import { CloseCircleOutlined } from '@ant-design/icons'
import {
  Row
} from '@gk-devteam/apmc-core-web'

import {
  selectCurrentStep,
  selectImportError,
  selectImportPartialErrors
} from '../../../selectors'
import { IMPORT_STEPS } from '../../../constants'
import { RESET_IMPORT } from '../../../types'

import {
  ImportProgressContainerStyle,
  ImportProgressBarContainerStyle,
  MessageStyle,
  CloseButtonStyle,
  ConfirmButtonStyle
} from './ImportProgress.style'
import ImportProgressBar from '../ImportProgressBar'

const ImportProgress = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const step = useSelector(selectCurrentStep)
  const error = useSelector(selectImportError)
  const partialErrors = useSelector(selectImportPartialErrors)

  const _handleCloseClick = () => {
    dispatch({ type: RESET_IMPORT })
  }
  const _handleConfirmClick = () => {
    navigate('/import/done')
  }

  const _renderCloseButton = () => {
    if (step != null && step === IMPORT_STEPS.DONE) {
      return (
        <CloseButtonStyle onClick={_handleCloseClick}>
          <CloseCircleOutlined />
        </CloseButtonStyle>
      )
    }
    return null
  }
  const _renderConfirmButton = () => {
    if (step != null && step === IMPORT_STEPS.DONE) {
      return (
        <Row mt="XS" justify="end">
          <ConfirmButtonStyle label="import:confirm" handleClick={_handleConfirmClick} />
        </Row>
      )
    }
    return null
  }

  if (
    location &&
    location.pathname.indexOf('/import') === -1 &&
    step &&
    (step === IMPORT_STEPS.UPLOAD || step === IMPORT_STEPS.DONE)
  ) {
    let message = 'import:importing'
    if (step === IMPORT_STEPS.DONE) {
      if (error || (partialErrors && partialErrors.length > 0)) {
        message = 'import:import_fail_message'
      } else {
        message = 'import:import_success_message'
      }
    }

    return (
      <ImportProgressContainerStyle>
        { _renderCloseButton() }
        <div>
          <ImportProgressBarContainerStyle>
            <ImportProgressBar />
          </ImportProgressBarContainerStyle>
          <Row mt="S">
            <MessageStyle label={message} />
          </Row>
          { _renderConfirmButton() }
        </div>
      </ImportProgressContainerStyle>
    )
  }

  return null
}

export default ImportProgress

ImportProgress.propTypes = {}
