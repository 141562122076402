export const FETCH_GUIDES = 'fetch_guides'
export const FETCH_GUIDES_SUCCESS = 'fetch_guides_success'
export const FETCH_GUIDES_FAIL = 'fetch_guides_fail'

export const POST_GUIDE = 'post_guide'
export const POST_GUIDE_SUCCESS = 'post_guide_success'
export const POST_GUIDE_FAIL = 'post_guide_fail'

export const DELETE_GUIDE = 'delete_guide'
export const DELETE_GUIDE_SUCCESS = 'delete_guide_success'
export const DELETE_GUIDE_FAIL = 'delete_guide_fail'

export const TOGGLE_GUIDE = 'toggle_guide'
export const TOGGLE_GUIDE_SUCCESS = 'toggle_guide_success'
export const TOGGLE_GUIDE_FAIL = 'toggle_guide_fail'

export const BULK_TOGGLE_GUIDES = 'bulk_toggle_guides'
export const BULK_TOGGLE_GUIDES_SUCCESS = 'bulk_toggle_guides_success'
export const BULK_TOGGLE_GUIDES_FAIL = 'bulk_toggle_guides_fail'

export const RESET_GUIDES = 'reset_guides'
