import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
  FONT_SIZE,
  Row,
  LightText,
  Tag
} from '@gk-devteam/apmc-core-web'

export const WaitingApprovalContainerStyle = styled(Row)`
  ${BORDER_RADIUS}
  border: solid 2px ${props => getColor(props, 'warning')};
  padding: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
  background-color: #fffef0;

  .anticon {
    ${FONT_SIZE.LARGE}
    color: ${props => getColor(props, 'warning')};
    margin-right: ${SPACING.REGULAR}rem;
  }
`

export const UserLoginLabelStyle = styled(LightText)`
  margin-right: ${SPACING.SMALL}rem;
  margin-right: ${SPACING.SMALL}rem;
`
export const UserLoginStyle = styled(Tag)`
  margin-left: ${SPACING.REGULAR}rem;
  margin-right: 0;
`

export const ButtonsGroupStyle = styled.div`
  display: inline-flex;
  align-items: center;
`
