import { Checkbox, TextInput, UPDATE_FORM } from '@gk-devteam/apmc-core-web'
import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const CouponLimitCount = () => {
  const dispatch = useDispatch()
  const checked = useSelector(state => state?.form?.no_limit)

  useEffect(() => {
    if (checked) dispatch({ type: UPDATE_FORM, payload: { limit_count: null } })
  }, [checked, dispatch])

  return (
    <>
      <TextInput
        type="number"
        name="limit_count"
        label='coupons:limit_count'
        placeholder=""
        flex={1}
        mr='XS'
        readonly={checked}
      />
      <Checkbox
        name="no_limit"
        valueLabel='coupons:no_limit'
      />
    </>
  )
}

export default memo(CouponLimitCount)
