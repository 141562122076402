/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const couponUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    title: yup.string().max(255).nullable().required().label(i18n.t('common:title')),
    description: yup.string().max(5000).nullable().required().label(i18n.t('common:description')),
    value: yup.string().max(255).nullable().required().label(i18n.t('coupons:value')),
    limit_count: yup.string()
      .max(255)
      .nullable()
      .label(i18n.t('coupons:limit_count'))
      .test(
        'either-is-required',
        i18n.t('either_required', { label: '${label}', requiredField: i18n.t('coupons:no_limit') }),
        function (value) {
          const { no_limit } = this.parent
          if (!value && !no_limit) return false
          return true
        }),
    no_limit: yup.boolean().nullable().label(i18n.t('coupons:no_limit')),
    limit_date: yup.date().nullable().label(i18n.t('coupons:limit_date')),
    recipient_type: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .min(1)
      .max(3)
      .label(i18n.t('notices:recipient_type')),
    recipients_id: yup
      .array()
      .when('recipient_type', {
        is: 1,
        then: yup
          .array()
          .nullable(),
        otherwise: yup
          .array()
          .required()
          .of(
            yup
              .number()
              .transform((cv, ov) => ov === '' ? undefined : cv)
              .integer()
              .required()
          )
      })
      .label(i18n.t('notices:recipients_id'))
  })
}
