import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import dayjs from 'dayjs'
import {
  LeftSearchMenu,
  selectQueryParams,
  TextInput,
  Select,
  SERVER_DATE_FORMAT,
  UPDATE_FORM,
  RoleControlled,
  selectPages
} from '@gk-devteam/apmc-core-web'

import { i18n } from '../../../locales'
import { contractsSearchSchema } from '../../../validation'
import { CONTRACT_STATUS } from '../../../constants'

import ContractDatepickers from './search/ContractDatepickers'

const initialValues = {
  contract_manage_id: '',
  property_id: '',
  room_id: '',
  property_name: '',
  contractor_name: '',
  resident_name: '',
  status: null,
  premium: null,
  contract_start_date_from: null,
  contract_start_date_to: null,
  contract_end_date_from: null,
  contract_end_date_to: null,
  move_out_date_from: null,
  move_out_date_to: null,
  casa: null
}

const statusOptions = _.map(CONTRACT_STATUS, (value, key) => {
  return { label: i18n.t(`contracts:status.${key}`), value: value.value }
})

const ContractsSearchBar = () => {
  const queryParams = useSelector(selectQueryParams)
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        contract_manage_id: queryParams.contract_manage_id,
        property_id: queryParams.property_id,
        prefecture: queryParams.prefecture,
        city: queryParams.city,
        room_id: queryParams.room_id,
        property_name: queryParams.property_name,
        contractor_name: queryParams.contractor_name,
        resident_name: queryParams.resident_name,
        status: queryParams.status,
        premium: queryParams.premium,
        contract_start_date_from: queryParams.contract_start_date_from ? dayjs(queryParams.contract_start_date_from).format(SERVER_DATE_FORMAT) : null,
        contract_start_date_to: queryParams.contract_start_date_to ? dayjs(queryParams.contract_start_date_to).format(SERVER_DATE_FORMAT) : null,
        contract_end_date_from: queryParams.contract_end_date_from ? dayjs(queryParams.contract_end_date_from).format(SERVER_DATE_FORMAT) : null,
        contract_end_date_to: queryParams.contract_end_date_to ? dayjs(queryParams.contract_end_date_to).format(SERVER_DATE_FORMAT) : null,
        move_out_date_from: queryParams.move_out_date_from ? dayjs(queryParams.move_out_date_from).format(SERVER_DATE_FORMAT) : null,
        move_out_date_to: queryParams.move_out_date_to ? dayjs(queryParams.move_out_date_to).format(SERVER_DATE_FORMAT) : null,
        casa: queryParams.casa
      }
    })
  }, [queryParams, dispatch])

  return (
    <>
      <LeftSearchMenu
        resetValues={initialValues}
        validationSchema={contractsSearchSchema}
      >
        <TextInput
          name="contract_manage_id"
          label={pages?.custom?.yasue ? 'yasue:contracts.contract_manage_id' : 'contracts:contract_manage_id'}
          placeholder={pages?.custom?.yasue ? '' : 'placeholders.partial_manage_id'}
          mr="0"
        />
        <RoleControlled authorized="!custom.yasue">
          <TextInput
            name="property_name"
            label='properties:property_name'
            placeholder="placeholders.partial_property_name"
            mr="0"
          />
        </RoleControlled>
        <TextInput
          name="contractor_name"
          label={pages?.custom?.yasue ? 'yasue:contracts.contractor_name' : 'contracts:contractor_name'}
          placeholder={pages?.custom?.yasue ? '' : 'placeholders.partial_user_name'}
          mr="0"
        />
        <RoleControlled authorized="!custom.yasue">
          <TextInput
            name="resident_name"
            label='contracts:resident_name'
            placeholder="placeholders.partial_user_name"
            mr="0"
          />
        </RoleControlled>
        <RoleControlled authorized='!custom.house_maker'>
          <RoleControlled authorized='!custom.yasue'>
            <Select
              name="status"
              label='status'
              placeholder=""
              options={statusOptions}
              mr="0"
            />
          </RoleControlled>
        </RoleControlled>
        <RoleControlled authorized='custom.anabuki'>
          <Select
            name="premium"
            label='contracts:anabuki.premium.long'
            options={[
              { label: i18n.t('contracts:anabuki.premium.on'), value: 'true' },
              { label: i18n.t('contracts:anabuki.premium.off'), value: 'false' }
            ]}
            mr="0"
          />
        </RoleControlled>
        <RoleControlled authorized='!custom.yasue'>
          <ContractDatepickers />
        </RoleControlled>
        <RoleControlled authorized='!custom.house_maker'>
          <RoleControlled authorized='!custom.yasue'>
            <TextInput
              type="number"
              name="property_id"
              label='properties:property_id'
              mr="0"
              placeholder=""
            />
            <TextInput
              type="number"
              name="room_id"
              label='rooms:room_id'
              placeholder=""
              mr="0"
            />
          </RoleControlled>
        </RoleControlled>
        <RoleControlled authorized='custom.casa'>
          <Select
            name="casa"
            label='contracts:casa.search.long'
            options={[
              { label: i18n.t('contracts:casa.search.on'), value: 'true' },
              { label: i18n.t('contracts:casa.search.off'), value: 'false' }
            ]}
            mr="0"
          />
        </RoleControlled>
      </LeftSearchMenu>
    </>
  )
}

export default ContractsSearchBar

// PropertiesSearchBar.propTypes = {}
