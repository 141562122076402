import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  LeftNavigationMenu,
  ItemNotFound
} from '@gk-devteam/apmc-core-web'

import {
  selectPages,
  selectRoomLoading,
  selectRoomError
} from '../../selectors'
import {
  FETCH_ROOM,
  RESET_ROOM,
  FETCH_PROPERTY,
  RESET_PROPERTY
} from '../../types'

import NotFoundPage from '../../pages/NotFoundPage'

const RoomBlock = ({ enabled, children, propertyID, roomID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const pages = useSelector(selectPages)
  const loading = useSelector(selectRoomLoading)
  const error = useSelector(selectRoomError)

  useEffect(() => {
    if (propertyID) {
      dispatch({ type: FETCH_PROPERTY, id: propertyID })
    }
    if (roomID) {
      dispatch({ type: FETCH_ROOM, propertyID, roomID })
    }
    return () => {
      dispatch({ type: RESET_PROPERTY })
      dispatch({ type: RESET_ROOM })
    }
  }, [dispatch, roomID, propertyID])

  if (!roomID || !propertyID) return null
  if (!enabled || !children || !pages) return <NotFoundPage />

  const getMenu = () => {
    const { rooms } = pages
    const {
      details,
      faq,
      manuals,
      guides,
      services
    } = rooms.functions
    const menu = []
    if (details) {
      menu.push({
        label: t('navigation:rooms_details'),
        link: './'
      })
    }
    if (faq) {
      menu.push({
        label: t('navigation:rooms_faq'),
        link: './faq'
      })
    }
    if (manuals) {
      menu.push({
        label: t('navigation:rooms_manuals'),
        link: './manuals'
      })
    }
    if (guides) {
      menu.push({
        label: t('navigation:rooms_guides'),
        link: './guides'
      })
    }
    if (services) {
      menu.push({
        label: t('navigation:services'),
        link: './services?type=1'
      })
    }
    return menu
  }

  return (
    <PageLayout
      sidebar={loading || (error && error.code === 404) ? null : () => <LeftNavigationMenu menu={getMenu()}/>}
      noMain
    >
      {
        loading
          ? null
          : error && error.code === 404
            ? <ItemNotFound item={t('navigation:rooms')} link={`/properties/edit/${propertyID}/rooms`} />
            : children
      }
    </PageLayout>
  )
}

export default RoomBlock

RoomBlock.propTypes = {
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  roomID: PropTypes.string,
  propertyID: PropTypes.string
}
