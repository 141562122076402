import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const publicFacilitiesSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    category: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .label(i18n.t('common:category')),
    title: yup.string().max(255).nullable().required().label(i18n.t('common:title')),
    content: yup.string().max(500).nullable().label(i18n.t('common:description')),
    business_hour: yup.string().max(255).nullable().label(i18n.t('common:business_hour')),
    address: yup.string().max(255).nullable().required().label(i18n.t('common:address')),
    phone: yup.string().max(255).nullable().required().label(i18n.t('common:phone')),
    url: yup.string().nullable().label(i18n.t('common:external_url')),
    file: yup
      .string()
      .nullable()
      .isBlob()
      .label(i18n.t('common:file'))
  })
}
