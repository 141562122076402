import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  INIT_FORM,
  RESET_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectAdminFaqData,
  selectAdminFaqAllDisplayed,
  selectPropertyID
} from '../../../../selectors'
import { TOGGLE_FAQ } from '../../../../types'

import FaqList from '../common/FaqList'

const AdminFaqList = ({ filter, editModal, copyModal }) => {
  const dispatch = useDispatch()

  const _handleCopy = (data, closeMenu, rest) => {
    dispatch({ type: RESET_FORM })
    const { question, answer } = data
    dispatch({
      type: INIT_FORM,
      payload: {
        category: rest.category,
        question,
        answer
      }
    })
    copyModal()
    closeMenu()
  }
  const adminSideMenu = [
    {
      label: 'copy',
      onClick: _handleCopy,
      shouldRender: 'faq.functions.add'
    }
  ]

  const _handleFaqDisplay = useCallback(
    (display, id, item) => {
      if (id) {
        const data = {
          id: item.owner_faq_id,
          display
        }
        const config = {
          type: 'admin_faqs',
          property: id
        }
        dispatch({ type: TOGGLE_FAQ, data, config })
      } else {
        console.warning('no property id')
      }
    },
    [dispatch]
  )

  return (
    <FaqList
      dataSelector={selectAdminFaqData}
      displaySelector={selectAdminFaqAllDisplayed}
      idSelector={selectPropertyID}
      title="faq:admin_faq"
      filter={filter}
      type="admin_faqs"
      level="property"
      editModal={editModal}
      handleFaqDisplay={_handleFaqDisplay}
      sideMenu={adminSideMenu}
    />
  )
}

export default AdminFaqList

AdminFaqList.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  copyModal: PropTypes.func.isRequired,
  editModal: PropTypes.func.isRequired
}
