import { BORDER_RADIUS, SmallText, SPACING, styled, getColor } from "@gk-devteam/apmc-core-web";
import { transparentize } from "polished";

export const ColorPickerContainerStyle = styled.div`
  margin-right: ${SPACING.MEDIUM}rem;
  user-select: none;
`

export const ColorBlockContainerStyle = styled.div`
  ${BORDER_RADIUS}
  border: 2px solid ${({ active, color }) => active ? color : 'transparent'};
  background-color: ${({color}) => transparentize(0.85, color)};
  padding: ${SPACING.SMALLEST/2}rem ${SPACING.SMALL}rem;
  display: inline-flex;
  align-items: center;
  margin: ${SPACING.SMALLEST/1.5}rem ${SPACING.SMALLEST}rem;
  cursor: pointer;
  pointer-events: ${({ noClick }) => noClick ? 'none': 'default'};
`
export const ColorBlockStyle = styled.div`
  ${BORDER_RADIUS}
  width: 24px;
  height: 24px;
  background-color: ${({ color }) => color};
`
export const ColorBlockLabelStyle = styled(SmallText)`
  margin-left: ${SPACING.SMALLEST}rem;
`

export const ResetButtonStyle = styled.button`
  color: ${props => getColor(props, 'danger')};
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
`
