import React, { memo, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import {
  SERVER_TIMESTAMP_FORMAT,
  getTimeElapsed,
  dynamicLink,
  Text,
  ToastTitle,
  Row,
  Column
} from '@gk-devteam/apmc-core-web'

import { FETCH_CHAT_STATUS, RESET_CHAT_STATUS } from '../../../types'
import {
  selectChatLoading,
  selectChatLoadingHiddenBy,
  selectWaitingChatRoomsCount,
  selectWaitingChatRooms,
  selectChatHiddenBy,
  selectChatHiddenById
} from '../../../selectors'

import AdminTaskCard from './AdminTaskCard'

const ChatCard = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectChatLoadingHiddenBy)
  const count = useSelector(selectWaitingChatRoomsCount)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch({ type: FETCH_CHAT_STATUS })
    return () => {
      dispatch({ type: RESET_CHAT_STATUS })
    }
  }, [dispatch])

  const navigateToChatRoom = (id, row, e) => {
    const url = `/admin_tasks/chat/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  const roomCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return (
        <Row>
          <ToastTitle text={data.propertyName} />　<Text text={data.roomNumber} />
        </Row>
      )
    },
    []
  )
  const elapsedCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const { lastUpdated } = data
      return (
        <Column>
          <Text text={lastUpdated && dayjs.unix(lastUpdated.seconds).format(SERVER_TIMESTAMP_FORMAT)} />
          <Text text={getTimeElapsed(dayjs.unix(lastUpdated.seconds))} />
        </Column>
      )
    },
    []
  )

  const columns = [
    {
      title: '',
      dataIndex: 'propertyName',
      key: 'propertyName',
      render: roomCell
    },
    {
      title: '',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: elapsedCell
    }
  ]

  if (loading) return null

  return (
    <AdminTaskCard
      title="admin_tasks:chat"
      linkTo="./chat"
      linkLabel="admin_tasks:to_chat"
      count={count}
      columns={columns}
      loadingSelector={selectChatLoading}
      dataSelector={selectWaitingChatRooms}
      hiddenSelector={selectChatHiddenBy}
      hiddenByIdSelector={selectChatHiddenById}
      handleRowClick={navigateToChatRoom}
      rowKey="id"
    />
  )
}

export default memo(ChatCard)

ChatCard.propTypes = {}
