import { createSelector } from 'reselect'

const selectGuideCategories = state => state.guideCategories

const parseDisplay = (data) => {
  const items = []
  for (const item of data) {
    items.push(item.display)
  }

  if (items.every(item => item === 0 || item === false)) return 0 // all hidden
  if (items.every(item => item === 2 || item === true)) return 2 // all displayed
  return 1
}

export const selectGuideCategoriesLoading = createSelector(
  selectGuideCategories,
  categories => categories.loading
)
export const selectGuideCategoriesPosting = createSelector(
  selectGuideCategories,
  categories => categories.loading
)

export const selectAdminGuideCategoriesData = createSelector(
  selectGuideCategories,
  categories => categories.data && categories.data.admin_results
)
export const selectPropertyGuideCategoriesData = createSelector(
  selectGuideCategories,
  categories => categories.data && categories.data.property_results
)
export const selectRoomGuideCategoriesData = createSelector(
  selectGuideCategories,
  categories => categories.data && categories.data.room_results
)

export const selectAdminGuideCategoriesAllDisplayed = createSelector(
  selectGuideCategories,
  categories => categories.data && categories.data.admin_results ? parseDisplay(categories.data.admin_results) : 0
)
export const selectPropertyGuideCategoriesAllDisplayed = createSelector(
  selectGuideCategories,
  categories => categories.data && categories.data.property_results ? parseDisplay(categories.data.property_results) : 0
)
export const selectRoomGuideCategoriesAllDisplayed = createSelector(
  selectGuideCategories,
  categories => categories.data && categories.data.room_results ? parseDisplay(categories.data.room_results) : 0
)

export const selectGuideCategoriessHiddenBy = createSelector(
  selectGuideCategories,
  categories => categories.data && categories.data.hiddenBy
)
export const selectGuideCategoriessHiddenById = createSelector(
  selectGuideCategories,
  categories => categories.data && categories.data.hiddenById
)
