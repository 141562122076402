import { SPACING, styled } from "@gk-devteam/apmc-core-web";

export const ServiceIconStyle = styled.div`
  width: ${SPACING.LARGE}rem;
  height: ${SPACING.LARGE}rem;
  margin-right: ${SPACING.REGULAR}rem;
`

export const DisplayRadioContainerStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & label, & div {
    white-space: nowrap;
  }
`
