import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectPages } from '../selectors'
import { FETCH_SPACES_WAITING_RESERVATIONS, RESET_SPACES_WAITING_RESERVATIONS } from '../types'

export const useSpacesWaitingReservations = () => {
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)

  useEffect(() => {
    if (pages?.custom?.yahagi) {
      dispatch({ type: FETCH_SPACES_WAITING_RESERVATIONS })
    }
    return () => {
      dispatch({ type: RESET_SPACES_WAITING_RESERVATIONS })
    }
  }, [dispatch, pages])
}
