import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Select, selectPages } from '@gk-devteam/apmc-core-web'

import { CHOUEI } from '../../../constants'

export default function ChoueiCenterName () {
  const pages = useSelector(selectPages)
  if (pages?.custom?.chouei) {
    return (
      <Row wrap="wrap" align="start" mb="R">
        <Select
          size="L"
          name="center_name"
          label='properties:chouei.center_name'
          placeholder=""
          options={CHOUEI.CENTER_NAME_OPTIONS}
          noClear
        />
      </Row>
    )
  }
  return null
}
