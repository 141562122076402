import React from 'react'
import { useSelector } from 'react-redux'
import {
  Image,
  LightText,
  Row,
  ToastTitle
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { selectServiceData } from '../../../selectors'

import {
  ServiceIconStyle
} from './common.style'

export default function ServiceHeader ({ level }) {
  const service = useSelector(selectServiceData)
  if (!service) return null
  const { service_name, service_description, service_icon } = service
  return (
    <Row align="start" mb="ML">
      <ServiceIconStyle>
        <Image src={service_icon} />
      </ServiceIconStyle>
      <div>
        <Row>
          <ToastTitle text={service_name} />
          <ToastTitle text={` | ${level}`} />
        </Row>
        <LightText text={service_description} />
      </div>

    </Row>
  )
}

ServiceHeader.propTypes = {
  level: PropTypes.string.isRequired
}
