import {
  styled,
  Text,
  getColor,
  SPACING,
  BORDER_RADIUS,
  Row
} from '@gk-devteam/apmc-core-web'

const CellRow = styled.div`
  display: flex;
  width: 100%;
  & > .cell {
    &:first-child {
      flex: 1;
    }
    &:nth-child(2) {
      width: 150px;
    }
    &:nth-child(3) {
      width: 72px;
    }
    &:nth-child(4) {
      width: 72px;
    }
    &:nth-child(5) {
      width: 110px;
    }
    &:last-child {
      width: 24px;
    }
  }
`

const Cell = styled.div`
  padding: 14px 4px;
  box-sizing: border-box;
`

export const UserListTable = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: scroll;
`

export const UserListHeader = styled(CellRow)``

export const UserListHeaderText = styled(Cell)`
  font-weight: bold;
`

export const UserRow = styled(CellRow)`
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  &:not(:last-child) {
    border-bottom: none;
  }
`

export const UserCell = styled(Cell)`
  &:first-child{
    white-space: nowrap;
  }
`

export const DropDownCell = styled(UserCell)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: .5rem;
    height: auto;
    transform: ${props => props.collapsed ? 'rotate(180deg)' : 'none'};
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

export const UserExtraRow = styled(UserRow)`
  border-top-color: transparent;
  /* border-top-color: ${props => props.collapsed ? 'transparent' : getColor(props, 'cardBorder')}; */
  border-bottom-color: ${props => props.collapsed ? 'transparent' : getColor(props, 'cardBorder')};
  ${UserCell} {
    padding: ${props => props.collapsed ? '0' : '14px 0'};
    & > div {
      max-height: ${props => props.collapsed ? '0' : '1000px'};
      overflow: hidden;
      transition: max-height .3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
`

export const TextAccentStyle = styled(Text)`
  color: ${props => getColor(props, 'accent')};
  margin-right: ${SPACING.MEDIUM_LARGE}rem;
`

export const ConditionsContainerStyle = styled.div`
  max-height: 150px;
  overflow-y: scroll;
`

export const ConditionRowStyle = styled(Row)`
  border: solid 1px ${props => getColor(props, 'cardBorder')};
`

export const ConditionItemStyle = styled.div`
  margin-right: ${SPACING.SMALLEST}rem;
`

export const RecipientsContainerStyle = styled.div`
  ${BORDER_RADIUS};
  flex: 1;
  overflow-y: scroll;
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  padding: ${SPACING.SMALLEST}rem;
  display: flex;
  flex-wrap: wrap;
`
export const RecipientItemStyle = styled.div`
  ${BORDER_RADIUS};
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  padding: ${SPACING.SMALLEST / 2}rem ${SPACING.SMALLEST}rem;
  margin: ${SPACING.SMALLEST / 2}rem;
  cursor: ${props => props.link ? 'pointer' : 'default'};
`
