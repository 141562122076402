import { createSelector } from 'reselect'

const selectYasueMaintenance = state => state.yasueMaintenance

export const selectYasueMaintenanceLoading = createSelector(
  selectYasueMaintenance,
  maintenance => maintenance.loading
)
export const selectYasueMaintenancePosting = createSelector(
  selectYasueMaintenance,
  maintenance => maintenance.posting
)

export const selectYasueMaintenanceContacts = createSelector(
  selectYasueMaintenance,
  maintenance => maintenance.contacts
)
export const selectYasueMaintenanceShowrooms = createSelector(
  selectYasueMaintenance,
  maintenance => maintenance.showrooms
)
