import { put, call, takeLatest, select, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchProperty,
  postProperty,
  deleteProperty
} from '../../services'
import {
  FETCH_PROPERTY,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAIL,
  POST_PROPERTY,
  POST_PROPERTY_SUCCESS,
  POST_PROPERTY_FAIL,
  DELETE_PROPERTY,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAIL,
  FETCH_PROPERTIES
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchPropertySaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchProperty, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_PROPERTY_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_PROPERTY_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_PROPERTY_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postPropertySaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postProperty, data, cancelSource)
    if (res && res.data && res.data.success) {
      if (successRedirect) yield call(successRedirect, res.data.id)
      yield put({ type: POST_PROPERTY_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_PROPERTY_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_PROPERTY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deletePropertySaga ({ id, onSuccess, onFail }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteProperty, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_PROPERTY_SUCCESS })
      yield put({ type: FETCH_PROPERTIES, query })
      if (onSuccess) yield call(onSuccess)
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_PROPERTY_FAIL, payload: error.data || error.message })
    if (onFail) yield call(onFail)
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchProperty () {
  yield takeLatest(FETCH_PROPERTY, fetchPropertySaga)
  yield takeLatest(POST_PROPERTY, postPropertySaga)
  yield takeLatest(DELETE_PROPERTY, deletePropertySaga)
}
