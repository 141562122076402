import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  getFileFromURL,
  INIT_FORM,
  RESET_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyGuideCategoriesData,
  selectPropertyGuideCategoriesAllDisplayed,
  selectPropertyID
} from '../../../../selectors'
import { TOGGLE_GUIDE_CATEGORY, DELETE_GUIDE_CATEGORY } from '../../../../types'

import GuideCategoriesList from '../common/GuideCategoriesList'

const PropertyGuideCategoriesList = ({ editModal, copyModal }) => {
  const dispatch = useDispatch()
  const propertyID = useSelector(selectPropertyID)
  const { t } = useTranslation()

  const _handleEdit = (data, closeMenu) => {
    dispatch({ type: RESET_FORM })
    const { name, content, id, file_url } = data
    dispatch({
      type: INIT_FORM,
      payload: {
        name,
        content,
        file_url,
        id,
        file: null,
        delete_file: false
      }
    })
    editModal()
    closeMenu()
  }
  const _handleCopy = async (data, closeMenu) => {
    dispatch({ type: RESET_FORM })
    const { name, content, file_url } = data
    try {
      let blobURL, extension
      if (file_url) {
        const blob = await getFileFromURL(file_url)
        blobURL = URL.createObjectURL(blob)
        extension = blob && blob.type.replace('image/', '')
      }
      dispatch({
        type: INIT_FORM,
        payload: {
          name,
          content,
          file_url,
          file: blobURL || null,
          file_filename: extension ? `guide_category.${extension}` : null,
          delete_file: false
        }
      })
      copyModal()
    } catch (error) {
      console.warning('error retrieving file blob', error)
      message.warning(t('copy_text_error'))
    }
    closeMenu()
  }
  const _handleDelete = (data, closeMenu) => {
    dispatch({ type: DELETE_GUIDE_CATEGORY, id: data.id, config: { property: propertyID } })
    closeMenu()
  }

  const menu = [
    {
      label: 'edit',
      onClick: _handleEdit,
      shouldRender: 'guides.functions.update'
    },
    {
      label: 'copy',
      onClick: _handleCopy,
      shouldRender: 'guides.functions.add'
    },
    {
      label: 'delete',
      onClick: _handleDelete,
      danger: true,
      shouldRender: 'guides.functions.delete'
    }
  ]

  const _handleItemDisplay = useCallback(
    (display, id, item) => {
      if (id) {
        const data = {
          id: item.id,
          display
        }
        const config = {
          type: 'guide_categories',
          property: id
        }
        dispatch({ type: TOGGLE_GUIDE_CATEGORY, data, config })
      } else {
        console.warning('no property id')
      }
    },
    [dispatch]
  )

  return (
    <GuideCategoriesList
      dataSelector={selectPropertyGuideCategoriesData}
      displaySelector={selectPropertyGuideCategoriesAllDisplayed}
      idSelector={selectPropertyID}
      title="guides:property_guide_category"
      type="guide_categories"
      level="property"
      guidePage="property_details"
      editModal={editModal}
      handleItemDisplay={_handleItemDisplay}
      sideMenu={menu}
    />
  )
}

export default PropertyGuideCategoriesList

PropertyGuideCategoriesList.propTypes = {
  copyModal: PropTypes.func.isRequired,
  editModal: PropTypes.func.isRequired
}
