import { createSelector } from 'reselect'

const selectGuest = state => state.guest

export const selectLoadingGuest = createSelector(
  selectGuest,
  guest => guest.loading
)
export const selectPostingGuest = createSelector(
  selectGuest,
  guest => guest.posting
)
export const selectGuestError = createSelector(
  selectGuest,
  guest => guest.error
)
export const selectGuestData = createSelector(
  selectGuest,
  guest => guest.data || {}
)
export const selectGuestID = createSelector(
  selectGuest,
  guest => guest.data && guest.data.id
)
export const selectGuestName = createSelector(
  selectGuest,
  guest => guest.data && guest.data.name
)
