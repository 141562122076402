import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
  Confirm,
  DangerButton,
  PrimaryButton,
  RoleControlled,
  Row,
  Text,
  ToastTitle,
  useTranslation
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import { selectContractID, selectContractPosting, selectContractServices, selectContractStatus } from '../../../../selectors'
import { CONTRACT_STATUS } from '../../../../constants'
import {
  // POST_ACCEPT_RENEW_CONTRACT,
  POST_CANCEL_CONTRACT_CANCELING,
  POST_CANCEL_RENEW_CONTRACT
} from '../../../../types'

import { CardStyle } from './ProcessCard.style'

function ProcessCard () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const contractID = useSelector(selectContractID)
  const contractServices = useSelector(selectContractServices)
  const status = useSelector(selectContractStatus)
  const loading = useSelector(selectContractPosting)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [okLabel, setOkLabel] = useState('')
  const [cancelQuestion, setCancelQuestion] = useState('')
  const [cancelLabel, setCancelLabel] = useState('')

  useEffect(() => {
    if (status) {
      switch (status) {
        case CONTRACT_STATUS.RESIDING.value:
          setTitle('')
          setDescription('')
          setOkLabel('')
          setCancelQuestion('')
          setCancelLabel('')
          break
        case CONTRACT_STATUS.IN_RENEWING_PERIOD.value:
          setTitle('contracts:status_panel.IN_RENEWING_PERIOD.title')
          setDescription('contracts:status_panel.IN_RENEWING_PERIOD.description')
          setOkLabel('contracts:status_panel.IN_RENEWING_PERIOD.renewal_settings')
          setCancelQuestion('')
          setCancelLabel('')
          break
        case CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value:
        case CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value:
          setTitle('contracts:status_panel.ASKING_FOR_RENEWAL.title')
          setDescription('contracts:status_panel.ASKING_FOR_RENEWAL.description')
          setOkLabel('contracts:status_panel.ASKING_FOR_RENEWAL.confirm_renewal')
          setCancelQuestion('contracts:status_panel.renewal.cancel_question')
          setCancelLabel('contracts:status_panel.ASKING_FOR_RENEWAL.cancel')
          break
        case CONTRACT_STATUS.RENEWING.value:
          setTitle('contracts:status_panel.ASKING_FOR_RENEWAL.title')
          setDescription('contracts:status_panel.ASKING_FOR_RENEWAL.description')
          setOkLabel('contracts:status_panel.ASKING_FOR_RENEWAL.confirm_renewal')
          setCancelQuestion('')
          setCancelLabel('')
          break
        case CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value:
          setTitle('contracts:status_panel.WAITING_RENEWAL_CONFIRMATION.title')
          setDescription('contracts:status_panel.WAITING_RENEWAL_CONFIRMATION.description')
          // setOkLabel('contracts:status_panel.WAITING_RENEWAL_CONFIRMATION.accept_renewal')
          setOkLabel('contracts:status_panel.ASKING_FOR_RENEWAL.confirm_renewal')
          setCancelQuestion('contracts:status_panel.renewal.cancel_question')
          setCancelLabel('contracts:status_panel.WAITING_RENEWAL_CONFIRMATION.cancel')
          break
        case CONTRACT_STATUS.REQUESTING_CANCEL.value:
        case CONTRACT_STATUS.CANCELING.value:
          setTitle('contracts:status_panel.REQUESTING_CANCEL.title')
          setDescription('contracts:status_panel.REQUESTING_CANCEL.description')
          setOkLabel('contracts:status_panel.REQUESTING_CANCEL.confirm_cancel')
          setCancelQuestion('contracts:status_panel.cancel.cancel_question')
          setCancelLabel('contracts:status_panel.REQUESTING_CANCEL.cancel')
          break
        case CONTRACT_STATUS.MOVING_OUT.value:
          setTitle('contracts:status_panel.MOVING_OUT.title')
          setDescription('contracts:status_panel.MOVING_OUT.description')
          setOkLabel('contracts:status_panel.MOVING_OUT.confirm_cancel')
          setCancelQuestion('contracts:status_panel.cancel.cancel_question')
          setCancelLabel('contracts:status_panel.MOVING_OUT.cancel')
          break
        case CONTRACT_STATUS.MOVED_OUT.value:
          setTitle('contracts:status_panel.MOVED_OUT.title')
          setDescription('contracts:status_panel.MOVED_OUT.description')
          setOkLabel('contracts:status_panel.MOVED_OUT.confirm_cancel')
          setCancelQuestion('')
          setCancelLabel('')
          break
        default:
          setTitle('')
          setDescription('')
          setOkLabel('')
          setCancelQuestion('')
          setCancelLabel('')
          break
      }
    }
  }, [status, contractID, t])

  if (!contractID || !status || !title || !contractServices?.cloudsign) return null

  const _handleRenewalCancel = async () => {
    dispatch({ type: POST_CANCEL_RENEW_CONTRACT, id: contractID })
  }
  const _handleCancelingCancel = async () => {
    dispatch({ type: POST_CANCEL_CONTRACT_CANCELING, id: contractID })
  }

  const _redirect = () => {
    switch (status) {
      case CONTRACT_STATUS.RESIDING.value:
      case CONTRACT_STATUS.IN_RENEWING_PERIOD.value:
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value:
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value:
      case CONTRACT_STATUS.RENEWING.value:
      case CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value:
        navigate(`/contracts/edit/${contractID}/renew`)
        break
      // case CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value:
      //   dispatch({ type: POST_ACCEPT_RENEW_CONTRACT, id: contractID })
      //   break
      case CONTRACT_STATUS.REQUESTING_CANCEL.value:
      case CONTRACT_STATUS.CANCELING.value:
      case CONTRACT_STATUS.MOVING_OUT.value:
      case CONTRACT_STATUS.MOVED_OUT.value:
        navigate(`/contracts/edit/${contractID}/cancel`)
        break
      default:
        break
    }
  }
  const _cancel = () => {
    switch (status) {
      case CONTRACT_STATUS.RESIDING.value:
      case CONTRACT_STATUS.IN_RENEWING_PERIOD.value:
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value:
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value:
      case CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value:
        _handleRenewalCancel()
        break
      case CONTRACT_STATUS.REQUESTING_CANCEL.value:
      case CONTRACT_STATUS.CANCELING.value:
      case CONTRACT_STATUS.MOVING_OUT.value:
        _handleCancelingCancel()
        break
      default:
        break
    }
  }

  return (
    <CardStyle mt="ML" mb="L" align="start">
      <ExclamationCircleOutlined />
      <Row wrap="wrap">
        <Row>
          <ToastTitle label={title} />
        </Row>
        <Text label={description} />
        <Row mt="S">
          <PrimaryButton
            handleClick={_redirect}
            label={okLabel}
            disabled={loading}
            reverse
          />
          {
            cancelLabel
              ? (
                <Confirm
                  title={cancelQuestion}
                  placement="topRight"
                  onConfirm={_cancel}
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                  <DangerButton
                    label={cancelLabel}
                    loading={loading}
                    withMargin
                  />
                </Confirm>
              )
              : null
          }
        </Row>
      </Row>
    </CardStyle>
  )
}

function ContractProcessCard () {
  const status = useSelector(selectContractStatus)
  switch (status) {
    case CONTRACT_STATUS.RESIDING.value:
    case CONTRACT_STATUS.IN_RENEWING_PERIOD.value:
    case CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value:
    case CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value:
    case CONTRACT_STATUS.RENEWING.value:
    case CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value:
      return (
        <RoleControlled authorized="custom.cloudsign">
          <ProcessCard />
        </RoleControlled>
      )
    case CONTRACT_STATUS.REQUESTING_CANCEL.value:
    case CONTRACT_STATUS.CANCELING.value:
    case CONTRACT_STATUS.MOVING_OUT.value:
    case CONTRACT_STATUS.MOVED_OUT.value:
      return (
        <RoleControlled authorized="contracts.functions.cancel">
          <ProcessCard />
        </RoleControlled>
      )
    default:
      return null
  }
}

// ContractProcessCard.propTypes = {}

export default ContractProcessCard
