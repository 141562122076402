import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  ItemNotFound,
  MainLayout
} from '@gk-devteam/apmc-core-web'

import {
  selectGuestName,
  selectGuestData,
  selectLoadingGuest,
  selectGuestError
} from '../../../selectors'
import { FETCH_GUEST, RESET_GUEST } from '../../../types'

import GuestForm from '../../../forms/userManagement/GuestForm'

const GuestDetailsPage = ({ guestID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const data = useSelector(selectGuestData)
  const guestName = useSelector(selectGuestName)
  const error = useSelector(selectGuestError)
  const loading = useSelector(selectLoadingGuest)

  useEffect(() => {
    if (guestID) {
      dispatch({ type: FETCH_GUEST, id: guestID })
    }
    return () => {
      dispatch({ type: RESET_GUEST })
    }
  }, [dispatch, guestID])

  const breadcrumbs = [
    {
      label: 'navigation:management',
      link: '/user_management'
    },
    {
      text: guestName || '',
      link: `/user_management/guests/edit/${guestID}`
    }
  ]

  if (error && error.code === 404) return <ItemNotFound withSidebar item={t('user_management:guests')} link="/user_management/guests" />

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
      lastEdited={data && data.modified}
      lastEditedBy={data && data.modifiedBy}
    >
      {
        !loading && !error ? <GuestForm data={data} guestID={guestID}/> : null
      }
    </MainLayout>
  )
}
export default GuestDetailsPage

GuestDetailsPage.propTypes = {
  guestID: PropTypes.string.isRequired
}
GuestDetailsPage.defaultProps = {

}
