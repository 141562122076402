import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { NimblePicker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import data from 'emoji-mart/data/apple.json'
import {
  useTranslation,
  SPACING,
  PrimaryButton
} from '@gk-devteam/apmc-core-web'

import {
  SendOutlined,
  PictureOutlined,
  PaperClipOutlined,
  VideoCameraOutlined,
  SmileOutlined
} from '@ant-design/icons'
import { postChatMessage, postChatFile } from '../../../actions'
import { CHAT_MESSAGE_TYPES } from '../../../constants'
import {
  selectChatHiddenBy,
  selectChatPosting,
  selectFirebaseUsername,
  selectFirebaseUserID,
  selectTheme,
  selectBaseLocale
} from '../../../selectors'

import FileModal from './FileModal'
import {
  AddFileContainerStyle,
  PickerOuterContainerStyle,
  EmojiPickerContainerStyle
} from './AdminTasksChatRoomPage.style'
import {
  MessageStyle,
  TextAreaStyle,
  ButtonsRowStyle
} from '../adminTasks.style'

const ChatMessageForm = ({ chatID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const textareaRef = useRef(null)
  const hiddenBy = useSelector(selectChatHiddenBy)
  const posting = useSelector(selectChatPosting)
  const username = useSelector(selectFirebaseUsername)
  const userID = useSelector(selectFirebaseUserID)
  const theme = useSelector(selectTheme)
  const locale = useSelector(selectBaseLocale)
  const [message, setMessage] = useState('')
  const [fileModal, setFileModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [filePickerLabel, setFilePickerLabel] = useState('')
  const [filePickerTypes, setFilePickerTypes] = useState(null)
  const [messageType, setMessageType] = useState(null)
  const [pickerVisible, setPickerVisible] = useState(false)

  const _focusTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }

  useEffect(() => {
    _focusTextarea()
  }, [])

  if (!username || !userID) return null

  const getEmojiLocale = () => {
    let pickerLocale = require('../../../locales/ja/emojiPicker')
    if (locale && locale !== process.env.REACT_APP_DEFAULT_LOCALE) {
      pickerLocale = require(`../../../locales/${locale}/emojiPicker`)
    }

    return pickerLocale && pickerLocale.default
  }

  const _handleSubmit = (e) => {
    if (e) e.preventDefault()
    if (!hiddenBy && !posting && message.trim()) {
      const onSuccess = () => {
        setMessage('')
        _focusTextarea()
      }
      console.log({
        roomID: chatID,
        body: message.trim(),
        type: CHAT_MESSAGE_TYPES.TEXT,
        reads: {},
        user: {
          id: userID,
          name: username
        }
      })
      dispatch(postChatMessage({
        data: {
          roomID: chatID,
          body: message.trim(),
          type: CHAT_MESSAGE_TYPES.TEXT,
          reads: {},
          user: {
            id: userID,
            name: username
          }
        },
        onSuccess
      }))
    }
  }

  const _handlePostFile = (file, filename) => {
    if (!hiddenBy && !posting) {
      const onSuccess = () => {
        _closeAddFile()
      }
      dispatch(postChatFile({
        data: {
          roomID: chatID,
          file,
          filename,
          type: messageType,
          reads: {},
          user: {
            id: userID,
            name: username
          }
        },
        onSuccess
      }))
    }
  }

  const _handleChange = (e) => {
    setMessage(e.target.value)
  }
  const _handleKeyPress = (e) => {
    const {
      shiftKey
    } = e
    if (e.key === 'Enter' && shiftKey) {
      e.preventDefault()
      _handleSubmit()
    }
  }

  const _handleSelectEmoji = (emoji) => {
    // console.log('emoji', emoji)
    setMessage(message + emoji.native)
    _closeEmoji()
  }

  const _openEmoji = () => {
    setPickerVisible(true)
  }
  const _closeEmoji = () => {
    setPickerVisible(false)
  }
  const _openAddFile = () => {
    console.log('_handleAddFile')
    if (!hiddenBy) {
      setModalTitle('admin_tasks:send_file')
      setFilePickerLabel('select_file')
      setFilePickerTypes([
        'pdf',
        'csv',
        'doc',
        'xls',
        'ppt',
        'txt'
      ])
      setMessageType(CHAT_MESSAGE_TYPES.FILE)
      setFileModal(true)
    }
  }
  const _openAddVideo = () => {
    if (!hiddenBy) {
      setModalTitle('admin_tasks:send_video')
      setFilePickerLabel('select_video')
      setFilePickerTypes(['video'])
      setMessageType(CHAT_MESSAGE_TYPES.VIDEO)
      setFileModal(true)
    }
  }
  const _openAddImage = () => {
    if (!hiddenBy) {
      setModalTitle('admin_tasks:send_image')
      setFilePickerLabel('select_image')
      setFilePickerTypes(['image'])
      setMessageType(CHAT_MESSAGE_TYPES.IMAGE)
      setFileModal(true)
    }
  }
  const _closeAddFile = () => {
    setFileModal(false)
    setModalTitle('')
    setFilePickerLabel('')
    setFilePickerTypes(null)
    setMessageType(null)
  }

  return (
    <>
      <FileModal
        visible={fileModal}
        onCancel={_closeAddFile}
        handleSubmit={_handlePostFile}
        modalTitle={modalTitle}
        filePickerLabel={filePickerLabel}
        filePickerTypes={filePickerTypes}
      />
      <form onSubmit={_handleSubmit} data-lpignore="true">
        <MessageStyle>
          <TextAreaStyle
            ref={textareaRef}
            id="chat_message"
            flex={1}
            name="message"
            data-qa="chat_message"
            data-lpignore="true"
            placeholder={t('message_placeholder')}
            value={message}
            onChange={_handleChange}
            onKeyPress={_handleKeyPress}
            disabled={posting || hiddenBy}
            required
          />
          <ButtonsRowStyle align="center" justify="end" py="XS" px="XS">
            <EmojiPickerContainerStyle>
              <Tooltip
                title={t('admin_tasks:emoji')}
                overlayStyle={{
                  fontSize: `${SPACING.SMALL}rem`
                }}
              >
                <AddFileContainerStyle onClick={_openEmoji}>
                  <SmileOutlined />
                </AddFileContainerStyle>
              </Tooltip>
              {
                pickerVisible
                  ? (
                    <>
                      <PickerOuterContainerStyle onClick={_closeEmoji} />
                      <NimblePicker
                        set='apple'
                        data={data}
                        emojiSize={20}
                        onSelect={_handleSelectEmoji}
                        showPreview={false}
                        showSkinTones={false}
                        title=""
                        emojiTooltip={true}
                        color={theme && theme.colors && theme.colors.accent}
                        i18n={getEmojiLocale()}
                        native={true}
                      />
                    </>
                  )
                  : null
              }
            </EmojiPickerContainerStyle>
            <Tooltip
              title={t('admin_tasks:add_file')}
              overlayStyle={{
                fontSize: `${SPACING.SMALL}rem`
              }}
            >
              <AddFileContainerStyle onClick={_openAddFile}>
                <PaperClipOutlined />
              </AddFileContainerStyle>
            </Tooltip>
            <Tooltip
              title={t('admin_tasks:add_video')}
              overlayStyle={{
                fontSize: `${SPACING.SMALL}rem`
              }}
            >
              <AddFileContainerStyle onClick={_openAddVideo}>
                <VideoCameraOutlined />
              </AddFileContainerStyle>
            </Tooltip>
            <Tooltip
              title={t('admin_tasks:add_image')}
              overlayStyle={{
                fontSize: `${SPACING.SMALL}rem`
              }}
            >
              <AddFileContainerStyle onClick={_openAddImage} disabled={hiddenBy}>
                <PictureOutlined />
              </AddFileContainerStyle>
            </Tooltip>
            <PrimaryButton
              label="submit"
              icon={<SendOutlined />}
              type="submit"
              reverse
              loading={posting}
              disabled={hiddenBy}
            />
          </ButtonsRowStyle>
        </MessageStyle>
      </form>
    </>
  )
}

export default ChatMessageForm

ChatMessageForm.propTypes = {
  chatID: PropTypes.string.isRequired
}
