import React, { useState, useEffect, useCallback } from 'react'
import { Empty, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  useTranslation,
  api,
  Modal,
  Spinner,
  LightText
} from '@gk-devteam/apmc-core-web'

import {
  selectCouponStatusModal,
  selectActiveCouponStatus
} from '../../../../selectors'
import { CLOSE_COUPON_STATUS_MODAL } from '../../../../types'

import CouponStatusRow from './CouponStatusRow'
import {
  UserListTable,
  UserListHeader,
  UserListHeaderText
} from './CouponDetailPage.style'

const CouponStatusModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visible = useSelector(selectCouponStatusModal)
  const couponID = useSelector(selectActiveCouponStatus)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  const fetchData = useCallback(
    async () => {
      try {
        const res = await api.get(`/coupons/${couponID.couponID}/history`, {
          params: {
            contract_id: couponID.contract_data.contract_id
          }
        })
        if (res && res.status === 200) {
          setData(res.data)
          setLoading(false)
        } else {
          message.error(t('fetch_error'))
          setLoading(false)
        }
      } catch (error) {
        console.warning('error', error)
        message.error(t('fetch_error'))
        setLoading(false)
      }
    },
    [couponID, t]
  )

  useEffect(() => {
    if (visible && couponID) { fetchData() }
    return () => {
      setLoading(true)
      setData(null)
    }
  }, [fetchData, visible, couponID])

  const _closeModal = () => {
    dispatch({ type: CLOSE_COUPON_STATUS_MODAL })
  }

  const _renderRow = () => {
    if (!data) return null
    return data.results.map((history, i) => {
      return <CouponStatusRow history={history} key={i} />
    })
  }

  const _renderData = () => {
    if (loading) return <Spinner color="info" />
    if (!data) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <LightText label='no_data'/>
          }
        />
      )
    }
    return (
      <UserListTable>
        <UserListHeader>
          <UserListHeaderText className="cell">{t('coupons:remaining_count')}</UserListHeaderText>
          <UserListHeaderText className="cell">{t('coupons:used_at')}</UserListHeaderText>
        </UserListHeader>
        { _renderRow() }
      </UserListTable>
    )
  }

  return (
    <Modal
      visible={visible}
      onCancel={_closeModal}
      cancelText='close'
      title="coupons:history"
      large
      fullWidth
    >
      { _renderData() }
    </Modal>
  )
}

export default CouponStatusModal

CouponStatusModal.propTypes = {

}
CouponStatusModal.defaultProps = {

}
