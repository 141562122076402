import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import {
  useTranslation,
  Table,
  Row,
  MainLayout,
  Tag,
  Title,
  PrimaryButton,
  selectQueryParams,
  RESET_PARAMS
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_PROPERTY_DOCUMENTS,
  RESET_PROPERTY_DOCUMENTS,
  TOGGLE_ADD_PROPERTY_DOCUMENT_MODAL,
  TOGGLE_VIEW_PROPERTY_DOCUMENT_MODAL,
  SET_ACTIVE_PROPERTY_DOCUMENT,
  DELETE_PROPERTY_DOCUMENT
} from '../../../types'
import {
  selectPropertyName,
  selectPropertyDocumentsLoading,
  selectPropertyDocumentsData,
  selectPropertyDocumentsCount
} from '../../../selectors'
import { propertyDocumentsSearchSchema } from '../../../validation'

import AddDocumentModal from './AddDocumentModal'
import ViewDocumentModal from './ViewDocumentModal'

const PropertiesDocumentsPage = ({ location, propertyID }) => {
  const { t } = useTranslation(['common', 'properties'])
  const dispatch = useDispatch()
  const params = useSelector(selectQueryParams)
  const prevQuery = useRef(null)
  const propertyName = useSelector(selectPropertyName)

  const fetchData = useCallback(
    () => {
      const schema = propertyDocumentsSearchSchema()
      const query = {
        ...params,
        property_id: Number(propertyID)
      }
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_PROPERTY_DOCUMENTS, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_PROPERTY_DOCUMENTS, query })
          }
        })
    },
    [dispatch, params, propertyID]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = params
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, params)) {
        prevQuery.current = params
        fetchData()
      }
    }
  }, [params, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_PROPERTY_DOCUMENTS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_documents',
      link: `/properties/edit/${propertyID}/documents`
    }
  ]

  const rolesCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const { chairman, inspector, contractors, residents } = data
      const tags = []

      if (chairman) tags.push(<Tag key="chairman" label='properties:documents.chairman' color='accent' />)
      if (inspector) tags.push(<Tag key="inspector" label='properties:documents.inspector' color='info' />)
      if (contractors) tags.push(<Tag key="contractors" label='properties:documents.contractors' color='danger' />)
      if (residents) tags.push(<Tag key="residents" label='properties:documents.residents' color='success' />)

      return tags
    },
    []
  )

  const documentsColumns = [
    {
      title: t('properties:documents.title'),
      dataIndex: 'file_name',
      key: 'file_name',
      cellTitle: true
    },
    {
      title: t('properties:documents.readable_by'),
      dataIndex: 'id',
      key: 'id',
      render: rolesCell
    },
    {
      title: '',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            dispatch({ type: DELETE_PROPERTY_DOCUMENT, documentID: id, propertyID })
            close()
          }
        }
      ]
    }
  ]

  const _openAddModal = () => {
    dispatch({ type: TOGGLE_ADD_PROPERTY_DOCUMENT_MODAL })
  }
  const _openViewModal = (id) => {
    dispatch({ type: TOGGLE_VIEW_PROPERTY_DOCUMENT_MODAL })
    dispatch({ type: SET_ACTIVE_PROPERTY_DOCUMENT, payload: { id } })
  }

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      // loading={loading}
    >
      <AddDocumentModal propertyID={propertyID} />
      <ViewDocumentModal />
      <Row justify="between" align="center" mt="R" mb="L">
        <Title label="navigation:properties_documents" />
        <PrimaryButton
          label="properties:documents.add"
          handleClick={_openAddModal}
          reverse
          withMargin
        />
      </Row>
      <Table
        fullHeight
        columns={documentsColumns}
        rowKey="id"
        loadingSelector={selectPropertyDocumentsLoading}
        resultsCountSelector={selectPropertyDocumentsCount}
        dataSelector={selectPropertyDocumentsData}
        onRowClick={_openViewModal}
      />
    </MainLayout>
  )
}
export default PropertiesDocumentsPage

PropertiesDocumentsPage.propTypes = {
  location: PropTypes.object.isRequired,
  propertyID: PropTypes.string.isRequired
}
PropertiesDocumentsPage.defaultProps = {

}
