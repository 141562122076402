import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import {
  useTranslation,
  Table,
  TOGGLE_ARRAY_ITEM
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertiesLoading,
  selectPropertiesData
} from '../../../../selectors'

import UserCheckmark from './UserCheckmark'

import {
  TableContainerStyle
} from '../../SurveyForm.style'

const PropertiesList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const propertiesColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'selected_user',
      RenderComponent: UserCheckmark
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'name',
      key: 'name',
      cellTitle: true
    },
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      authorized: '!custom.house_maker'
    },
    {
      title: t('manage_id'),
      dataIndex: 'property_manage_id',
      key: 'property_manage_id'
    }
  ]

  const _handleRowClick = (_, row, e) => {
    const { id, name } = row
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [
        {
          key: 'recipients_list',
          value: { id, label: name },
          objectComparatorKey: 'id'
        },
        {
          key: 'recipients_id',
          value: id
        }]
    })
  }

  return (
    <TableContainerStyle>
      <Table
        columns={propertiesColumns}
        rowKey="id"
        loadingSelector={selectPropertiesLoading}
        dataSelector={selectPropertiesData}
        onRowClick={_handleRowClick}
        leftStickyColumnCount={0}
        rightStickyColumnCount={0}
        noHeader
        noFooter
        noLoader
      />
    </TableContainerStyle>
  )
}

export default memo(PropertiesList)

// UsersList.propTypes = {}
