import {
  styled,
  getColor,
  SPACING,
  FONT_SIZE,
  BORDER_RADIUS,
  Row
} from '@gk-devteam/apmc-core-web'
import { lighten } from 'polished'
import { Table } from 'antd'

export const ImportConfirmTableContainerStyle = styled.div`
  width: ${props => `${props.width}px` || 'auto'};
`

export const TableStyle = styled(Table)`
  .ant-table-tbody > tr {
    &.withError, &.withError > .ant-table-cell-fix-left, &.withError > .ant-table-cell-fix-right {
      background-color: ${props => lighten(0.35, getColor(props, 'danger'))};
      &:hover > td {
        background-color: ${props => lighten(0.35, getColor(props, 'danger'))};
      }
    }
  }

  .ant-pagination {

    .ant-pagination-item-active {
      border-color: ${props => getColor(props, 'accent')};
      a {
        color: ${props => getColor(props, 'accent')};
      }
    }

    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a,
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: ${props => getColor(props, 'accent')};
    }

    .ant-pagination-prev, .ant-pagination-next {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:focus .ant-pagination-item-link,
      &:hover .ant-pagination-item-link {
        color: ${props => getColor(props, 'accent')};
      }
      & > button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
      }
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: ${props => getColor(props, 'accent')};
    }

    .ant-pagination-item-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
    }
  }
`

export const ErrorCardStyle = styled(Row)`
  ${BORDER_RADIUS}
  border: solid 2px ${props => getColor(props, 'danger')};
  padding: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
  background-color: #fff0f0;
  margin-bottom: ${SPACING.SMALL}rem;

  .anticon {
    ${FONT_SIZE.LARGE}
    color: ${props => getColor(props, 'danger')};
    margin-right: ${SPACING.REGULAR}rem;
  }
`

export const ImportConfirmTableCellInnerStyle = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.error ? getColor(props, 'danger') : 'inherit'};
`

export const TextInputStyle = styled.input`
  ${BORDER_RADIUS};
  border: solid 1px ${props => getColor(props, 'danger')};
  outline: none;
  padding: ${SPACING.SMALLEST / 2}rem ${SPACING.SMALLEST}rem;
  width: 100%;
`

export const ErrorStyle = styled.span`
${FONT_SIZE.SMALL};
  margin-right: ${SPACING.REGULAR}rem;
  margin-top: ${SPACING.SMALLEST}rem;
  color: ${props => getColor(props, 'danger')};
  display: inline-flex;
  align-items: center;
  & > span {
    margin-right: ${SPACING.SMALLEST}rem;
  }
`
