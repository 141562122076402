import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Modal,
  TextInputStandAlone
} from '@gk-devteam/apmc-core-web'

import { selectPropertiesLoading } from '../../../selectors'
import { DELETE_PROPERTY } from '../../../types'

import { DangerMessageStyle, PropertyNameStyle, DeleteMessageStyle } from './PropertiesPage.style'

const DeleteModal = ({ visible, onCancel, propertyID, propertyName }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loading = useSelector(selectPropertiesLoading)
  const [propertyNameInput, setPropertyNameInput] = useState('')
  const [okDisabled, setOkDisabled] = useState(true)

  useEffect(() => {
    return () => {
      if (visible === false) {
        setPropertyNameInput('')
        setOkDisabled(true)
      }
    }
  }, [visible])

  useEffect(() => {
    if (propertyName && propertyNameInput && propertyNameInput === propertyName) {
      setOkDisabled(false)
    } else {
      setOkDisabled(true)
    }
  }, [propertyNameInput, propertyName])

  const _handleInputChange = (e) => {
    setPropertyNameInput(e.target.value)
  }

  const _deleteProperty = () => {
    if (propertyID) {
      if (propertyNameInput && propertyNameInput === propertyName) {
        dispatch({ type: DELETE_PROPERTY, id: propertyID, onSuccess: onCancel, onFail: onCancel })
      } else {
        message.error(t('properties:delete_property.name_no_match'))
      }
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={_deleteProperty}
      okText="delete"
      okDisabled={okDisabled}
      okDanger
      title={t('properties:delete_property.modal_title', { propertyName })}
      posting={loading}
    >
      <DangerMessageStyle>{t('properties:delete_property.warning_message')}</DangerMessageStyle>
      <DeleteMessageStyle>
        <span>{t('properties:delete_property.description_1')}</span>
        <PropertyNameStyle>{propertyName}</PropertyNameStyle>
        <span>{t('properties:delete_property.description_2')}</span>
      </DeleteMessageStyle>

      <TextInputStandAlone
        name="propertyName"
        handleChange={_handleInputChange}
        value={propertyNameInput}
      />
    </Modal>
  )
}

export default memo(DeleteModal)

DeleteModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  propertyID: PropTypes.number,
  propertyName: PropTypes.string
}
