import { createSelector } from 'reselect'

const selectNotices = state => state.notices

export const selectNoticesLoading = createSelector(
  selectNotices,
  notices => notices.loading
)
export const selectNoticesCount = createSelector(
  selectNotices,
  notices => notices.results_count
)
export const selectNoticesData = createSelector(
  selectNotices,
  notices => notices.results
)

export const selectNoticeStatusModal = createSelector(
  selectNotices,
  notices => notices.statusModal
)
export const selectNoticeConditionsModal = createSelector(
  selectNotices,
  notices => notices.conditionsModal
)
export const selectActiveNoticeStatus = createSelector(
  selectNotices,
  notices => notices.activeStatus
)
