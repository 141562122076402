import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  selectQueryParams,
  Modal,
  Title,
  Row,
  PrimaryButton,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import { POST_ROOM, FETCH_ROOMS } from '../../../types'

import AddRoomForm from './AddRoomForm'

const RoomListHeader = ({ propertyID }) => {
  const dispatch = useDispatch()
  const [addModalVisible, setAddModalVisible] = useState(false)
  const query = useSelector(selectQueryParams)

  const _showModal = () => {
    setAddModalVisible(true)
  }
  const _closeModal = () => {
    setAddModalVisible(false)
  }

  const _addRoom = (data) => {
    const success = (propertyID) => {
      dispatch({ type: FETCH_ROOMS, propertyID, query })
      _closeModal()
    }
    dispatch({ type: POST_ROOM, data, propertyID, success })
  }
  return (
    <>
      <Modal
        title="rooms:add_room"
        visible={addModalVisible}
        onCancel={_closeModal}
        onOk={_addRoom}
        okText="save"
        fullWidth
        formID="addRoomForm"
      >
        <AddRoomForm addRoom={_addRoom} />
      </Modal>
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:rooms_list"/>
        <div>
          <RoleControlled authorized="rooms.functions.add">
            <PrimaryButton
              label="rooms:add_room"
              handleClick={_showModal}
              reverse
              withMargin
            />
          </RoleControlled>
        </div>
      </Row>
    </>
  )
}

export default memo(RoomListHeader)

RoomListHeader.propTypes = {
  propertyID: PropTypes.string.isRequired
}
