import {
  styled,
  getColor,
  Title
} from '@gk-devteam/apmc-core-web'

export const TitleStyle = styled(Title)`
    font-size: 4rem;
`
export const HeadlineStyle = styled(Title)`
    font-size: 2rem;
    color: ${props => getColor(props, 'accent')};
`
