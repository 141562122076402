import React, { memo, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_FORM,
  LeftSearchMenu,
  TextInput,
  Select,
  selectQueryParams,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_AREAS,
  RESET_AREAS
} from '../../../types'

import {
  selectPrefecturesOptions,
  selectCitiesOptions,
  selectAreasLoading
} from '../../../selectors'
import { propertiesSearchSchema } from '../../../validation'

const initialValues = {
  property_name: '',
  id: '',
  property_manage_id: '',
  prefecture: '',
  city: ''
}

const PropertiesSearchBar = () => {
  const prevPrefectureRef = useRef()
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)
  const prefecturesOptions = useSelector(selectPrefecturesOptions)
  const cities = useSelector(selectCitiesOptions)
  const loadingAreas = useSelector(selectAreasLoading)
  const watchedPrefecture = useSelector(state => state.form.prefecture)

  useEffect(() => {
    if (!prevPrefectureRef.current) {
      prevPrefectureRef.current = watchedPrefecture
    } else {
      if (prevPrefectureRef.current !== watchedPrefecture) {
        prevPrefectureRef.current = watchedPrefecture
        dispatch({ type: UPDATE_FORM, payload: { city: '' } })
      }
    }
  }, [watchedPrefecture, dispatch])

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        property_name: queryParams.property_name,
        id: queryParams.id,
        property_manage_id: queryParams.property_manage_id,
        prefecture: queryParams.prefecture,
        city: queryParams.city
      }
    })
  }, [queryParams, dispatch])

  const cityOptions = watchedPrefecture ? cities[watchedPrefecture] : []

  useEffect(() => {
    dispatch({ type: FETCH_AREAS })
    return () => {
      dispatch({ type: RESET_AREAS })
    }
  }, [dispatch])

  return (
    <>
      <LeftSearchMenu
        resetValues={initialValues}
        validationSchema={propertiesSearchSchema}
      >
        <TextInput
          type="text"
          name="property_name"
          label='properties:property_name'
          placeholder="placeholders.partial_property_name"
          mr="0"
        />
        <RoleControlled authorized='!custom.house_maker'>
          <TextInput
            type="number"
            name="id"
            label='properties:property_id'
            placeholder=""
            mr="0"
          />
        </RoleControlled>
        <TextInput
          type="text"
          name="property_manage_id"
          label='properties:property_manage_id'
          placeholder="placeholders.partial_manage_id"
          mr="0"
        />
        <Select
          name="prefecture"
          label='properties:prefecture'
          placeholder=""
          options={prefecturesOptions}
          loading={loadingAreas}
          mr="0"
        />
        <Select
          name="city"
          label='properties:city'
          placeholder=""
          options={cityOptions}
          loading={loadingAreas}
          disabled={!watchedPrefecture}
          mr="0"
        />
      </LeftSearchMenu>
    </>
  )
}

export default memo(PropertiesSearchBar)

// PropertiesSearchBar.propTypes = {}
