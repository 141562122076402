import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Card,
  Copy,
  LightText,
  PrimaryButton,
  RoleControlled,
  Row,
  Text,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import { message, Tooltip } from 'antd'
import { CancelToken } from 'axios'

import { ROLES } from '../../../../constants'
import { POST_CLOUDSIGN_CLIENT_ID } from '../../../../types'

import ClientIdModal from './ClientIdModal'
import {
  CloudsignWebhookStyle,
  EditIconStyle
} from './CloudSign.style'
import { fetchCloudSignClientId } from '../../../../services'

function CloudSign () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false)
  const [clientID, setClientID] = useState(null)

  const fetchClientID = useCallback(
    async () => {
      try {
        const cancelSource = CancelToken.source()
        const res = await fetchCloudSignClientId(cancelSource)
        if (res?.data?.client_id) {
          setClientID(res.data.client_id)
        } else {
          message.error(t('fetch_error'))
        }
      } catch (error) {
        message.error(t('fetch_error'))
      }
    },
    [t]
  )

  useEffect(() => {
    fetchClientID()
  }, [fetchClientID])

  const _closeModal = () => {
    setModalVisible(false)
  }
  const _openModal = () => {
    setModalVisible(true)
  }

  const _handleIdUpdate = (data) => {
    const onSuccess = () => {
      _closeModal()
      fetchClientID()
    }
    dispatch({
      type: POST_CLOUDSIGN_CLIENT_ID,
      data,
      onSuccess
    })
  }

  const _renderClientIdButton = () => (
    <PrimaryButton
      label="services:cloudsign.register_id"
      handleClick={_openModal}
      small
    />
  )

  const _renderClientIdText = () => (
    <Row>
      <LightText label="services:cloudsign.client_id" />
      <LightText text={clientID} />
      <div onClick={_openModal}>
        <Tooltip title={t('services:cloudsign.edit_id')}>
          <div>
            <EditIconStyle name="edit" />
          </div>
        </Tooltip>
      </div>
    </Row>
  )

  return (
    <RoleControlled supportedRoles={[ROLES.AGENCY_MAIN_ADMIN, ROLES.AGENCY_ADMIN]}>
      <ClientIdModal
        visible={modalVisible}
        onCancel={_closeModal}
        onOk={_handleIdUpdate}
        id={clientID}
      />
      <Card
        title="services:cloudsign.settings"
      >
        <Row justify="between" align="center" mt="XS">
          {
            clientID ? _renderClientIdText() : _renderClientIdButton()
          }
          <CloudsignWebhookStyle>
            <Text label="services:cloudsign.webhook"/>
            <Copy
              dataToCopy={process.env.REACT_APP_CLOUDSIGN_WEBHOOK}
              copyTooltip="services:cloudsign.webhook_copy"
            />
          </CloudsignWebhookStyle>
        </Row>
      </Card>
    </RoleControlled>
  )
}

export default CloudSign
