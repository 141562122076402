export const FETCH_COUPONS = 'fetch_coupons'
export const FETCH_COUPONS_SUCCESS = 'fetch_coupons_success'
export const FETCH_COUPONS_FAIL = 'fetch_coupons_fail'
export const RESET_COUPONS = 'reset_coupons'

export const DELETE_COUPON = 'delete_coupon'
export const DELETE_COUPON_SUCCESS = 'delete_coupon_success'
export const DELETE_COUPON_FAIL = 'delete_coupon_fail'

export const FETCH_COUPON = 'fetch_coupon'
export const FETCH_COUPON_SUCCESS = 'fetch_coupon_success'
export const FETCH_COUPON_FAIL = 'fetch_coupon_fail'
export const RESET_COUPON = 'reset_coupon'

export const POST_COUPON = 'post_coupon'
export const POST_COUPON_SUCCESS = 'post_coupon_success'
export const POST_COUPON_FAIL = 'post_coupon_fail'

export const OPEN_COUPON_STATUS_MODAL = 'open_coupon_status_modal'
export const CLOSE_COUPON_STATUS_MODAL = 'close_coupon_status_modal'
