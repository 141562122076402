import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
} from '@gk-devteam/apmc-core-web'

export const MessageStyle = styled.div`
  ${BORDER_RADIUS};
  border: solid 1px ${props => getColor(props, 'lightText')};
  padding: ${SPACING.MEDIUM}rem;
  width: 100%;
`
