import { createReducer } from '@reduxjs/toolkit'
import { POST_CONSTRUCTION_WORK_FILE, POST_CONSTRUCTION_WORK_FILE_FAIL, POST_CONSTRUCTION_WORK_FILE_SUCCESS, TOGGLE_ADD_CONSTRUCTION_WORK_FILE_MODAL, TOGGLE_VIEW_CONSTRUCTION_WORK_FILE_MODAL } from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  error: null,
  items: [],
  addFileModal: false,
  viewFileModal: false,
  activeDocumentID: null
}

export default createReducer(INITIAL_STATE, {
  [TOGGLE_VIEW_CONSTRUCTION_WORK_FILE_MODAL]: (state, action) => {
    if (state.viewFileModal) {
      state.activeDocumentID = null
    }
    state.viewFileModal = !state.viewFileModal
  },
  [TOGGLE_ADD_CONSTRUCTION_WORK_FILE_MODAL]: (state, action) => {
    state.addFileModal = !state.addFileModal
  },
  [POST_CONSTRUCTION_WORK_FILE]: (state, action) => {
    state.posting = true
  },
  [POST_CONSTRUCTION_WORK_FILE_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CONSTRUCTION_WORK_FILE_FAIL]: (state, action) => {
    state.posting = false
  }

})
