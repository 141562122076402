import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import { CancelToken } from 'axios'
import { saveAs } from 'file-saver'
import PropTypes from 'prop-types'
import { DownloadOutlined } from '@ant-design/icons'
import {
  useTranslation,
  selectQueryParams,
  SuccessButton
} from '@gk-devteam/apmc-core-web'

const DownloadButton = ({ schemaBuilder, fetchCSV, filename }) => {
  const query = useSelector(selectQueryParams)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation(['common'])
  const schema = schemaBuilder()

  const downloadCSV = async () => {
    const cancelSource = CancelToken.source()
    setLoading(true)
    try {
      const isValid = await schema.isValid(query)
      if (isValid) {
        const res = await fetchCSV({
          ...query,
          csv: true
        }, cancelSource)
        const data = new Blob(['\uFEFF' + res.data], { type: 'text/csv;charset=utf-8' })
        saveAs(data, filename)
        setLoading(false)
      } else {
        console.warning('params invalid, query using default params')
        message.error(t('fetch_error'))
        setLoading(false)
      }
    } catch (error) {
      console.warning('error', error)
      message.error(t('fetch_error'))
      setLoading(false)
    }
  }

  return (
    <SuccessButton
      label="download_csv"
      icon={<DownloadOutlined />}
      handleClick={downloadCSV}
      withMargin
      loading={loading}
    />
  )
}

export default DownloadButton

DownloadButton.propTypes = {
  schemaBuilder: PropTypes.func.isRequired,
  fetchCSV: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired
}
