import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_CONTACTS,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAIL,
  POST_CONTACT,
  POST_CONTACT_SUCCESS,
  POST_CONTACT_FAIL,
  // DELETE_CONTACT,
  // DELETE_CONTACT_SUCCESS,
  // DELETE_CONTACT_FAIL,
  RESET_CONTACTS,
  TOGGLE_CONTACT,
  TOGGLE_CONTACT_FAIL,
  BULK_TOGGLE_CONTACTS,
  BULK_TOGGLE_CONTACTS_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_CONTACTS]: (state, action) => {
    state.loading = true
  },
  [FETCH_CONTACTS_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_CONTACTS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_CONTACT]: (state, action) => {
    state.posting = true
  },
  [POST_CONTACT_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CONTACT_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [TOGGLE_CONTACT]: (state, action) => {
    state.loading = true
  },
  [TOGGLE_CONTACT_FAIL]: (state, action) => {
    state.loading = false
  },
  [BULK_TOGGLE_CONTACTS]: (state, action) => {
    state.loading = true
  },
  [BULK_TOGGLE_CONTACTS_FAIL]: (state, action) => {
    state.loading = false
  },
  [RESET_CONTACTS]: () => INITIAL_STATE

})
