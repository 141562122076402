import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  LeftNavigationMenu,
  ItemNotFound
} from '@gk-devteam/apmc-core-web'

import {
  selectPages,
  selectSpaceError,
  selectSpaceLoading
} from '../../selectors'
import {
  FETCH_SPACE,
  RESET_SPACE,
  FETCH_PROPERTY,
  RESET_PROPERTY
} from '../../types'

import NotFoundPage from '../../pages/NotFoundPage'

const PropertySpaceBlock = ({ enabled, children, propertyID, spaceID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const pages = useSelector(selectPages)
  const loading = useSelector(selectSpaceLoading)
  const error = useSelector(selectSpaceError)

  useEffect(() => {
    if (propertyID) {
      dispatch({ type: FETCH_PROPERTY, id: propertyID })
    }
    if (spaceID) {
      dispatch({ type: FETCH_SPACE, id: spaceID })
    }
    return () => {
      dispatch({ type: RESET_PROPERTY })
      dispatch({ type: RESET_SPACE })
    }
  }, [dispatch, spaceID, propertyID])

  if (!spaceID || !propertyID) return null
  if (!enabled || !children || !pages) return <NotFoundPage />

  const sidemenu = [
    {
      label: t('navigation:properties_spaces_details'),
      link: './'
    },
    {
      label: t('navigation:properties_spaces_reservations_list'),
      link: './reservations'
    }
  ]

  return (
    <PageLayout
      sidebar={loading || (error && error.code === 404) ? null : () => <LeftNavigationMenu menu={sidemenu}/>}
      noMain
    >
      {
        loading
          ? null
          : error && error.code === 404
            ? <ItemNotFound item={t('navigation:rooms')} link={`/properties/edit/${propertyID}/rooms`} />
            : children
      }
    </PageLayout>
  )
}

export default PropertySpaceBlock

PropertySpaceBlock.propTypes = {
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  spaceID: PropTypes.string,
  propertyID: PropTypes.string
}
