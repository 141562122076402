/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const propertyDocumentUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('id')),
    property_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .label(i18n.t('properties:property_id')),
    file_name: yup.string().max(255).required().label(i18n.t('properties:documents.filename')),
    chairman: yup.bool().required().label(i18n.t('properties:documents.chairman')),
    inspector: yup.bool().required().label(i18n.t('properties:documents.inspector')),
    contractors: yup.bool().required().label(i18n.t('properties:documents.contractors')),
    residents: yup.bool().required().label(i18n.t('properties:documents.residents')),
    file: yup.string().nullable().isBlob().required().label(i18n.t('common:file')),
    delete_file: yup.bool().required().label(i18n.t('common:delete_file_flag'))
  })
}
