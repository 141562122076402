import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_YASUE_SURVEY,
  FETCH_YASUE_SURVEY_SUCCESS,
  FETCH_YASUE_SURVEY_FAIL,
  RESET_YASUE_SURVEY
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_YASUE_SURVEY]: (state, action) => {
    state.loading = true
  },
  [FETCH_YASUE_SURVEY_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_YASUE_SURVEY_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_YASUE_SURVEY]: () => INITIAL_STATE
})
