import {
  styled,
  Checkbox,
  SPACING
} from '@gk-devteam/apmc-core-web'

export const CheckboxCustomStyle = styled(Checkbox)`
  margin-right: ${SPACING.LARGE}rem;
  div:nth-child(2) {
    height: 40px;
  }
`
