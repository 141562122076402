/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const guideCategorySchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    name: yup.string().max(255).required().label(i18n.t('common:title')),
    content: yup.string().max(500).nullable().label(i18n.t('common:description')),
    file: yup
      .string()
      .nullable()
      .isBlob()
      .label(i18n.t('common:file'))
  })
}
