import { api, DEFAULT_QUERY_LIMIT, getFileFromURL, MULTIPART_HEADERS } from '@gk-devteam/apmc-core-web'

export const fetchContracts = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }

  return api.get('contracts', {
    cancelToken: cancelSource.token,
    params: {
      ...params,
      ...defaultParams
    },
    headers: {
      responseType: params.csv ? 'blob' : 'application/json'
    }
  })
}
export const fetchContractList = (cancelSource) => {
  return api.get('contracts/list', {
    cancelToken: cancelSource.token
  })
}
export const fetchContract = (id, cancelSource) => {
  return api.get(`contracts/${id}`, {
    cancelToken: cancelSource.token
  })
}
export const postContract = (data, cancelSource) => {
  return api.post('contracts', data, {
    cancelToken: cancelSource.token
  })
}
export const postYasueContract = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.property_image) {
      try {
        const file = await getFileFromURL(data.property_image)
        formData.append('property_image', file, data.property_image_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }

    delete data.property_image
    delete data.property_image_url
    delete data.property_image_filename

    formData.append('data', JSON.stringify(data))
    return api.post('contracts', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}
export const deleteContract = (id, cancelSource) => {
  return api.delete(`contracts/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const fetchApprovalNumbers = (cancelSource) => {
  return api.get('contracts/approval_numbers', {
    cancelToken: cancelSource.token
  })
}
export const deleteApprovalNumber = (data, cancelSource) => {
  return api.post('contracts/delete_approval_number', data, {
    cancelToken: cancelSource.token
  })
}

export const fetchContractsProcessingCount = (cancelSource) => {
  return api.get('/admin_tasks/contracts_count', {
    cancelToken: cancelSource.token
  })
}
export const fetchRenewContract = (id, cancelSource) => {
  return api.get(`/contracts/${id}/confirm_renewal`, {
    cancelToken: cancelSource.token
  })
}
export const postRenewContract = (id, data, cancelSource) => {
  return api.post(`contracts/${id}/confirm_renewal`, data, {
    cancelToken: cancelSource.token
  })
}
export const postPreviewRenewContract = (id, data, cancelSource) => {
  return api.post(`contracts/${id}/confirm_renewal`, {
    ...data,
    is_preview: true
  }, {
    cancelToken: cancelSource.token,
    responseType: 'blob'
  })
}
export const postAcceptContractRenewal = (id, cancelSource) => {
  return api.post(`contracts/${id}/renew`, null, {
    cancelToken: cancelSource.token
  })
}
export const cancelContractRenewal = (id, cancelSource) => {
  return api.delete(`contracts/${id}/confirm_renewal`, {
    cancelToken: cancelSource.token
  })
}

export const fetchCancelContract = (id, cancelSource) => {
  return api.get(`/contracts/${id}/cancel`, {
    cancelToken: cancelSource.token
  })
}
export const postCancelContract = (id, data, cancelSource) => {
  return api.post(`contracts/${id}/cancel`, data, {
    cancelToken: cancelSource.token
  })
}

export const postPreviewCancelContract = (id, data, cancelSource) => {
  return api.post(`contracts/${id}/cancel`, {
    ...data,
    is_preview: true
  }, {
    cancelToken: cancelSource.token,
    responseType: 'blob'
  })
}
export const cancelContractCanceling = (id, cancelSource) => {
  return api.delete(`contracts/${id}/cancel`, {
    cancelToken: cancelSource.token
  })
}

export const downloadChecklistPDF = (id, cancelSource) => {
  return api.get(`/contracts/${id}/checklist`, {
    timeout: 5 * 60 * 1000,
    responseType: 'blob',
    cancelToken: cancelSource.token
  })
}
