/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const guideSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    name: yup
      .string()
      .max(255)
      .nullable()
      .required()
      .label(i18n.t('common:title')),
    content: yup
      .string()
      .max(500)
      .nullable()
      .label(i18n.t('common:description')),
    url: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('common:external_url')),
    video_url: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('common:video_url')),
    file: yup
      .string()
      .nullable()
      .isBlob()
      .label(i18n.t('common:file'))
  })
}
