/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const inspectionUpdateSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    id: yup
      .number()
      .integer()
      .nullable()
      .label(i18n.t('id')),
    inspection_date: yup.date().nullable().label(i18n.t('inspections:inspection_date')),
    file: yup
      .string()
      .nullable()
      .isBlob()
      .label(i18n.t('common:file')),
    delete_file: yup.bool().required().label(i18n.t('common:delete_file_flag'))

  })
}
