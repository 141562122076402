import Cookies from 'js-cookie'
import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { CancelToken } from 'axios'
import { message } from 'antd'
import {
  encrypt,
  decrypt,
  USER_IDENTITY_COOKIE,
  USER_PREFERENCES_COOKIE,
  USER_PAGES_LOCAL_STORAGE,
  FORCE_APP_LOGOUT_LOCAL_STORAGE,
  APP_VERSION_LOCAL_STORAGE,
  FORCE_LOGOUT_LOCAL_STORAGE,
  USER_REFRESH_LOCAL_STORAGE,
  USER_FCM_COOKIE
} from '@gk-devteam/apmc-core-web'

import {
  loginUserService,
  refreshUserService,
  signOutUser
} from '../../services'
import { i18n } from '../../locales'

import {
  unSubscribeFromChatRooms,
  unSubscribeFromChecklists,
  unSubscribeFromApp,
  unSubscribeFromFirebaseUser
} from '../../actions'
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  UNSUBSCRIBE_CHAT_WAITING_ROOMS,
  UNSUBSCRIBE_CHECKLISTS,
  UNSUBSCRIBE_APP,
  UNSUBSCRIBE_FIREBASE_USER,
  REFRESH_USER,
  REFRESH_USER_SUCCESS,
  REFRESH_USER_FAIL,
  DELETE_FCM_TOKEN
} from '../../types'

export function * loginSaga ({ payload }) {
  const cancelSource = CancelToken.source()
  try {
    // Login user
    const response = yield call(loginUserService, payload, cancelSource)
    if (response && response.data && response.data.access_token && response.data.refresh_token && response.data.user) {
      const { access_token, refresh_token, user, theme, pages } = response.data
      // Set Cookies for persistence
      yield call(Cookies.set, USER_IDENTITY_COOKIE, encrypt(JSON.stringify({ access_token, refresh_token, user })), { expires: 365, secure: process.env.REACT_APP_STAGE === 'production' })
      yield call(Cookies.set, USER_PREFERENCES_COOKIE, encrypt(JSON.stringify(theme)), { expires: 365, secure: process.env.REACT_APP_STAGE === 'production' })
      yield call([localStorage, localStorage.setItem], USER_PAGES_LOCAL_STORAGE, encrypt(JSON.stringify(pages))) // Too large for a cookie...
      // Set user info in Redux state
      yield put({ type: LOGIN_USER_SUCCESS, payload: { user, theme, pages } })
    } else {
      // Send error if token or user data is missing
      yield put({ type: LOGIN_USER_FAIL, error: true, payload: { message: 'ログイン失敗' } })
      yield call(message.error, i18n.t('login_error'), 3)
    }
  } catch (error) {
    yield put({ type: LOGIN_USER_FAIL, error: true, payload: error.data })
    yield call(message.error, i18n.t('login_error'), 3)
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * refreshUserSaga () {
  const cancelSource = CancelToken.source()
  try {
    // refresh user
    const response = yield call(refreshUserService, cancelSource)
    if (response && response.data && response.data.access_token && response.data.refresh_token && response.data.user) {
      const { access_token, refresh_token, user, theme, pages } = response.data
      // Set Cookies for persistence
      yield call(Cookies.set, USER_IDENTITY_COOKIE, encrypt(JSON.stringify({ access_token, refresh_token, user })), { expires: 365, secure: process.env.REACT_APP_STAGE === 'production' })
      yield call(Cookies.set, USER_PREFERENCES_COOKIE, encrypt(JSON.stringify(theme)), { expires: 365, secure: process.env.REACT_APP_STAGE === 'production' })
      yield call([localStorage, localStorage.setItem], USER_PAGES_LOCAL_STORAGE, encrypt(JSON.stringify(pages))) // Too large for a cookie...
      // Set user info in Redux state
      yield put({ type: REFRESH_USER_SUCCESS, payload: { user, theme, pages } })
    } else {
      // Send error if token or user data is missing
      yield put({ type: REFRESH_USER_FAIL, payload: { message: 'リフレッシュ失敗' } })
    }
  } catch (error) {
    console.warning('error', error)
    yield put({ type: REFRESH_USER_FAIL, payload: error.data })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * logoutSaga ({ text }) {
  // Remove FCM token from cookies & DB
  const cookie = yield call(Cookies.get, USER_FCM_COOKIE)
  const decrypted = cookie ? yield call(decrypt, cookie) : null
  const parsed = decrypted ? yield call(JSON.parse, decrypted) : null
  const token = parsed && parsed.token
  yield put({ type: DELETE_FCM_TOKEN, token })

  // Unsubscribe from firebase & logout
  yield call(unSubscribeFromChatRooms)
  yield put({ type: UNSUBSCRIBE_CHAT_WAITING_ROOMS })
  yield call(unSubscribeFromChecklists)
  yield put({ type: UNSUBSCRIBE_CHECKLISTS })
  yield call(unSubscribeFromApp)
  yield put({ type: UNSUBSCRIBE_APP })
  yield call(unSubscribeFromFirebaseUser)
  yield put({ type: UNSUBSCRIBE_FIREBASE_USER })
  yield call(signOutUser)

  // Remove cookies & local storage
  yield call(Cookies.remove, USER_IDENTITY_COOKIE)
  yield call(Cookies.remove, USER_PREFERENCES_COOKIE)
  yield call([localStorage, localStorage.removeItem], USER_PAGES_LOCAL_STORAGE)
  yield call([localStorage, localStorage.removeItem], FORCE_APP_LOGOUT_LOCAL_STORAGE)
  yield call([localStorage, localStorage.removeItem], APP_VERSION_LOCAL_STORAGE)
  yield call([localStorage, localStorage.removeItem], FORCE_LOGOUT_LOCAL_STORAGE)
  yield call([localStorage, localStorage.removeItem], USER_REFRESH_LOCAL_STORAGE)

  yield call(message.config, { maxCount: 1 })
  if (text) yield call(message.warning, text, 3)
}

export function * watchLoginUser () {
  yield takeLatest(LOGIN_USER, loginSaga)
  yield takeLatest(REFRESH_USER, refreshUserSaga)
  yield takeLatest(LOGOUT_USER, logoutSaga)
}
