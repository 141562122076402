import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { notification } from 'antd'
import {
  encrypt,
  decrypt,
  FORCE_APP_LOGOUT_LOCAL_STORAGE,
  APP_USER_REFRESH_LOCAL_STORAGE,
  APP_VERSION_LOCAL_STORAGE,
  COLORS,
  CustomButton
} from '@gk-devteam/apmc-core-web'

import { i18n } from '../locales'
import { selectAppLogout, selectAppRefreshUser, selectAppVersion } from '../selectors'
import { LOGOUT_USER, REFRESH_USER } from '../types'

import { EmptyIcon, Description } from './common'

export const useFirebaseApp = () => {
  const dispatch = useDispatch()
  const forceLogout = useSelector(selectAppLogout)
  const appVersion = useSelector(selectAppVersion)
  const appRefreshUser = useSelector(selectAppRefreshUser)
  const localForceLogout = localStorage.getItem(FORCE_APP_LOGOUT_LOCAL_STORAGE)
  const localVersion = localStorage.getItem(APP_VERSION_LOCAL_STORAGE)
  const localRefreshUser = localStorage.getItem(APP_USER_REFRESH_LOCAL_STORAGE)
  const [appFirstCheck, setAppFirstCheck] = useState(true)

  const logoutKey = 'open_app_logout_notification'
  const refreshKey = 'open_refresh_notification'

  const logoutUser = useCallback(
    () => {
      localStorage.setItem(FORCE_APP_LOGOUT_LOCAL_STORAGE, encrypt(forceLogout))
      notification.close(logoutKey)
      dispatch({ type: LOGOUT_USER })
    },
    [dispatch, forceLogout, logoutKey]
  )
  const refreshPage = useCallback(
    () => {
      localStorage.setItem(APP_VERSION_LOCAL_STORAGE, encrypt(appVersion))
      notification.close(refreshKey)
      window.location.reload()
    },
    [appVersion, refreshKey]
  )

  useEffect(() => {
    if (forceLogout) {
      if (!localForceLogout) {
        localStorage.setItem(FORCE_APP_LOGOUT_LOCAL_STORAGE, encrypt(forceLogout) || '')
      } else {
        const token = decrypt(localForceLogout)
        if (token !== forceLogout) {
          // If token has changed, logout user
          notification.warning({
            message: i18n.t('force_logout_title'),
            description: <Description description="force_logout_description"/>,
            placement: 'bottomLeft',
            duration: 0,
            btn: (
              <CustomButton
                color={COLORS.accent}
                label="logout"
                handleClick={logoutUser}
                reverse
              />
            ),
            key: logoutKey,
            closeIcon: <EmptyIcon />
          })
        }
      }
    }
  }, [forceLogout, localForceLogout, logoutKey, logoutUser])

  useEffect(() => {
    if (appVersion) {
      if (!localVersion || appFirstCheck) {
        localStorage.setItem(APP_VERSION_LOCAL_STORAGE, encrypt(appVersion) || '')
        setAppFirstCheck(false)
      } else {
        const version = decrypt(localVersion)
        if (version !== appVersion) {
          // If token has changed, notify user to refresh page
          notification.warning({
            message: i18n.t('force_refresh_title'),
            description: <Description description="force_refresh_description"/>,
            placement: 'bottomLeft',
            duration: 0,
            btn: (
              <CustomButton
                color={COLORS.accent}
                label="refresh"
                handleClick={refreshPage}
                reverse
              />
            ),
            key: refreshKey,
            closeIcon: <EmptyIcon />
          })
        }
      }
    }
  }, [appVersion, localVersion, refreshKey, refreshPage, appFirstCheck])

  useEffect(() => {
    if (appRefreshUser) {
      if (!localRefreshUser) {
        localStorage.setItem(APP_USER_REFRESH_LOCAL_STORAGE, encrypt(appRefreshUser))
      } else {
        const token = decrypt(localRefreshUser)
        if (token !== appRefreshUser) {
          // If token has changed, refresh user info
          localStorage.setItem(APP_USER_REFRESH_LOCAL_STORAGE, encrypt(appRefreshUser))
          dispatch({ type: REFRESH_USER })
        }
      }
    }
  }, [dispatch, appRefreshUser, localRefreshUser])
}
