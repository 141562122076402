/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const loginSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    mail_address: yup
      .string()
      .email()
      .when('login_id', {
        is: (value) => !value || value.length === 0,
        then: yup.string().required(),
        otherwise: yup.string().nullable()
      })
      .label(i18n.t('common:email')),
    login_id: yup
      .string()
      .test(
        'either-is-required',
        i18n.t('either_required', { label: '${label}', requiredField: i18n.t('common:email') }),
        function (value) {
          const { mail_address } = this.parent
          if (!value && !mail_address) return false
          return true
        })
      .isLetterOrNumber()
      .label(i18n.t('common:login_id')),
    password: yup
      .string()
      .required()
      .label(i18n.t('login:password'))
  })
}
