import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  Confirm,
  DeleteButtonStyle,
  Modal,
  Row,
  EmptyData,
  useTranslation,
  ToastTitle,
  Text,
  Image,
  Document,
  Page
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyEventsViewModalState,
  selectActivePropertyEventDay,
  selectActivePropertyDayEvents
} from '../../../selectors'
import { DELETE_PROPERTY_EVENT, TOGGLE_VIEW_PROPERTY_EVENTS_MODAL } from '../../../types'
import { PDF_CMAP_URL } from '../../../constants'

import DayEventListItem from './DayEventListItem'

import {
  EventModalBodyStyle,
  ContentStyle,
  DatetimeStyle,
  ListStyle,
  ContentInnerStyle
} from './PropertyEventsPage.style'

export default function DayEventsModal () {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const wrapperRef = useRef(null)
  const isVisible = useSelector(selectPropertyEventsViewModalState)
  const activeDay = useSelector(selectActivePropertyEventDay)
  const activeDayEvents = useSelector(selectActivePropertyDayEvents)
  const [currentEvent, setCurrentEvent] = useState(0)
  const [width, setWidth] = useState(385)

  const handlePreviewWidth = useCallback(
    () => {
      const wrapper = wrapperRef.current
      if (isVisible && wrapper) {
        const { width } = wrapper.getBoundingClientRect()
        setWidth(width)
      }
    },
    [isVisible, wrapperRef]
  )

  useEffect(() => {
    handlePreviewWidth()
    window.addEventListener('resize', handlePreviewWidth)
    return () => {
      window.removeEventListener('resize', handlePreviewWidth)
    }
  }, [handlePreviewWidth])

  const _closeModal = () => {
    setCurrentEvent(0)
    dispatch({ type: TOGGLE_VIEW_PROPERTY_EVENTS_MODAL })
  }
  const _deleteEvent = () => {
    const onSuccess = () => {
      if (currentEvent > 0) setCurrentEvent(currentEvent - 1)
    }
    dispatch({ type: DELETE_PROPERTY_EVENT, eventID: activeDayEvents[currentEvent]?.id, onSuccess })
  }

  const _renderPreview = () => {
    if (!activeDayEvents[currentEvent]?.file_url) return null
    const url = activeDayEvents[currentEvent].file_url
    const regex = /\.(\w+)\?/gmi
    const match = url.match(regex)
    if (match[0]?.includes('pdf')) {
      return (
        <Document
          file={url}
          loading={t('pdf_load')}
          error={t('pdf_load_fail')}
          // onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          options={{
            cMapUrl: PDF_CMAP_URL,
            cMapPacked: true
          }}
        >
          <Page
            pageNumber={1}
            width={width}
            renderAnnotationLayer={false}
          />
        </Document>
      )
    }

    return (
      <Row mt="R">
        <Image src={activeDayEvents[currentEvent]?.file_url} previewable/>
      </Row>
    )
  }

  if (!activeDay) return null

  return (
    <Modal
      visible={isVisible}
      onCancel={_closeModal}
      cancelText='close'
      title={activeDay}
      large
      fullWidth
      fullHeight
    >
      <EventModalBodyStyle align="start">
        <ListStyle>
          {
            activeDayEvents?.length > 0
              ? activeDayEvents.map((event, i) => <DayEventListItem key={i} event={event} index={i} isCurrent={i === currentEvent} setCurrentevent={setCurrentEvent} />)
              : <EmptyData description={t('properties:events.no_data')} />
          }
        </ListStyle>
        {
          activeDayEvents?.length > 0 && (
            <ContentStyle align="start" justify="between">
              <ContentInnerStyle ref={wrapperRef}>
                <ToastTitle text={activeDayEvents[currentEvent]?.title} />
                <Row mt="R">
                  <Text text={activeDayEvents[currentEvent]?.content} />
                </Row>
                <Row mt="R">
                  <DatetimeStyle label="datetime" />
                </Row>
                <Text text={`${activeDayEvents[currentEvent]?.start_time} - ${activeDayEvents[currentEvent]?.end_time}`}/>
                { _renderPreview() }
              </ContentInnerStyle>
              <Row mt="L">
                <Confirm
                  title='properties:events.delete_question'
                  placement="topRight"
                  onConfirm={_deleteEvent}
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                  <DeleteButtonStyle reverse>{t('properties:events.delete')}</DeleteButtonStyle>
                </Confirm>
              </Row>
            </ContentStyle>
          )
        }
      </EventModalBodyStyle>
    </Modal>
  )
}
