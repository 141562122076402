import {
  styled,
  getColor,
  SPACING,
  Text,
  Row,
  InfoButton
} from '@gk-devteam/apmc-core-web'

export const ImportAgainButtonStyle = styled(InfoButton)`
  margin-right: ${SPACING.REGULAR}rem;
`
export const ToListButtonStyle = styled(InfoButton)`
  margin-left: ${SPACING.REGULAR}rem;
`

export const WarningBlockStyle = styled.div`
  flex: 1;

`

export const FailedTitleStyle = styled(Text)`
  font-weight: bold;
`
export const ErrorTextStyle = styled(Text)`
  margin-right: ${SPACING.SMALL}rem;
  white-space: nowrap;
`

export const ErrorRowStyle = styled(Row)`
  flex-wrap: wrap;
  margin-top: ${SPACING.SMALL}rem;
  padding: ${SPACING.SMALLEST}rem 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => getColor(props, 'cardBorder')};
  }
`
