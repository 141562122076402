import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  InquiryID,
  Form,
  Card,
  Row,
  Title,
  FilePicker,
  FormSubmitButton,
  RoleControlled,
  TextInput,
  Checkbox
} from '@gk-devteam/apmc-core-web'

import {
  selectLoadingGuest,
  selectPostingGuest,
  selectPages
} from '../../../selectors'
import { guestUpdateValidation } from '../../../validation'
import { POST_GUEST } from '../../../types'
import { INQUIRY_PREFIX } from '../../../constants'

import SelectItems from '../common/SelectItems'

import { MemoTextAreaStyle } from '../common/common.style'

const GuestForm = ({ data, guestID }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)
  const [authorized, setAuthorized] = useState(true)

  useEffect(() => {
    if (pages && data && data.id) {
      if (!data.editable || !pages.manage_guests.functions.update) setAuthorized(false)
    }
  }, [pages, data])

  const initialValues = {
    ...data,
    file: (data && data.file) || null,
    delete_file: (data && data.delete_file) || false,
    groups: data.groups_list && data.groups_list.length > 0 ? data.groups_list.map(item => item.id) : null,
    properties: data.properties_list && data.properties_list.length > 0 ? data.properties_list.map(item => item.id) : null,
    groups_list: data.groups_list && data.groups_list.length > 0 ? data.groups_list : null,
    properties_list: data.properties_list && data.properties_list.length > 0 ? data.properties_list : null
  }

  const handleFormSubmit = (data) => {
    const successRedirect = (id) => {
      navigate('/user_management/guests')
    }
    dispatch({ type: POST_GUEST, data, successRedirect })
  }

  return (
    <Form
      id="addGuestForm"
      handleSubmit={handleFormSubmit}
      validationSchema={guestUpdateValidation}
      loadingSelector={selectLoadingGuest}
      postingSelector={selectPostingGuest}
      initialData={initialValues}
      authorized={authorized}
      // debug
    >
      <Row justify="between" wrap="wrap">
        {
          guestID
            ? <Title text={data.name} />
            : <Title label='user_management:add_guest' />
        }
        <RoleControlled authorized="manage_guests.functions.update">
          <FormSubmitButton editMode={!!guestID} postingSelector={selectPostingGuest}/>
        </RoleControlled>
      </Row>
      <Row mb="ML">
        <InquiryID id={guestID} prefix={INQUIRY_PREFIX.GUEST} />
      </Row>
      <div>
        <Card mb="L">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="name"
              label='user_management:guest.name'
              placeholder=""
              required
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="furigana"
              label='furigana'
              placeholder=""
              required
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="mail_address"
              label='email'
              placeholder="placeholders.email"
              required
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="phone"
              label='phone'
              placeholder="placeholders.phone"
            />
          </Row>
        </Card>
        <Row mt="L" align="baseline">
          <Card title='image' mr="L">
            <FilePicker
              name="file"
              delete_name="delete_file"
              types={['image']}
            />
          </Card>
          <Card title='memo'>
            <MemoTextAreaStyle
              noLabel
              name="memo"
              placeholder="placeholders.memo"
            />
          </Card>
        </Row>
        <Card title='user_management:groups-properties' mt="L">
          <Checkbox
            name="all_properties"
            inputLabel='user_management:all_properties'
          />
          <SelectItems itemsKey="groups" />
          <SelectItems itemsKey="properties" />
        </Card>
      </div>
    </Form>
  )
}
export default GuestForm

GuestForm.propTypes = {
  data: PropTypes.object,
  guestID: PropTypes.string
}
