import React from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslation,
  Row,
  TextInput,
  Select,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import { selectChecklistCategoriesOptions, selectChecklistLoading } from '../../../selectors'
import { SUPPORT_STATUS } from '../../../constants'

const ChecklistSearchForm = () => {
  const { t } = useTranslation()
  const categoriesOptions = useSelector(selectChecklistCategoriesOptions)
  const loading = useSelector(selectChecklistLoading)
  return (
    <>
      <Row>
        <TextInput
          flex={1}
          name="app_user_name"
          label='contracts:resident_name'
          placeholder="placeholders.partial_user_name"
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          name="property_name"
          label='properties:property_name'
          placeholder="placeholders.partial_property_name"
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          name="room_number"
          label='properties:room_number'
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          type="number"
          name="contract_id"
          label='contracts:contract_id'
          placeholder=""
        />
      </Row>
      <Row justify="between">
        <Select
          mr="S"
          name="checklist_category_id"
          label='genre'
          placeholder=""
          loading={loading}
          options={categoriesOptions}
        />
        <Select
          mr="0"
          name="status"
          label='status'
          placeholder=""
          options={[
            {
              label: t('admin_tasks:status.is_waiting'),
              value: SUPPORT_STATUS.WAITING
            },
            {
              label: t('admin_tasks:status.is_confirmed'),
              value: SUPPORT_STATUS.CONFIRMED
            },
            {
              label: t('admin_tasks:status.is_completed'),
              value: SUPPORT_STATUS.COMPLETED
            },
            {
              label: t('admin_tasks:status.archive'),
              value: SUPPORT_STATUS.ARCHIVE
            }
          ]}
        />
      </Row>
      <RoleControlled authorized="custom.anabuki">
        <Row>
          <Select
            mr="0"
            name="repair"
            label='admin_tasks:checklist_repair.flag'
            placeholder=""
            options={[
              {
                label: t('admin_tasks:checklist_repair.repair_needed'),
                value: 'true'
              },
              {
                label: t('admin_tasks:checklist_repair.repair_not_needed'),
                value: 'false'
              }
            ]}
          />
        </Row>
      </RoleControlled>
    </>
  )
}

export default ChecklistSearchForm

ChecklistSearchForm.propTypes = {}
