import { createSelector } from 'reselect'

const selectPropertiesGroups = state => state.propertiesGroups

export const selectPropertiesGroupsLoading = createSelector(
  selectPropertiesGroups,
  propertiesGroups => propertiesGroups.loading
)
export const selectPropertiesGroupsCount = createSelector(
  selectPropertiesGroups,
  propertiesGroups => propertiesGroups.results_count
)
export const selectPropertiesGroupsData = createSelector(
  selectPropertiesGroups,
  propertiesGroups => propertiesGroups.results
)
