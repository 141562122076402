import {
  styled,
  getColor,
  SPACING,
  LEFT_SIDEBAR_WIDTH,
  BORDER_RADIUS,
} from '@gk-devteam/apmc-core-web'

export const PanelLegendContainerStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 21;
  padding-left: calc(${LEFT_SIDEBAR_WIDTH}px + ${SPACING.LARGER}rem);
  padding-right: ${SPACING.LARGER}rem;
  @media(max-width: 767px){
    padding-left: ${SPACING.LARGER}rem;
  }
`

export const PanelLegendInnerContainerStyle = styled.div`
  background-color: #fafafa;
  padding: ${SPACING.REGULAR}rem;
  user-select: none;
  box-shadow: 0px -2px 8px 0 #f0f1f2;
`
export const LegendItemStyle = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: ${SPACING.LARGER}rem;
  @media(max-width: 767px){
    margin-right: ${SPACING.REGULAR}rem;
  }
`

const BoxStyle = styled.div`
  ${BORDER_RADIUS};
  position: relative;
  height: ${SPACING.REGULAR}rem;
  width: ${SPACING.REGULAR}rem;
  border: 1px solid ${props => getColor(props, 'inputBorder')};
  margin-left: ${SPACING.SMALLEST}rem;
`

export const AllDisplayedStyle = styled(BoxStyle)`
  border-color: ${props => props.disabled ? getColor(props, 'inputBorder') : getColor(props, 'accent')};
  background-color: ${props => props.disabled ? getColor(props, 'inputBorder') : getColor(props, 'accent')};
  &:after {
    position: absolute;
    top: 50%;
    left: 22%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%,-50%);
    opacity: 1;
    transition: all .2s cubic-bezier(.71,-.46,.88,.6),opacity .1s;
    content: ' ';
  }
`
export const SomeDisplayedStyle = styled(BoxStyle)`
  &:after {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${props => props.disabled ? getColor(props, 'inputBorder') : getColor(props, 'accent')};
    content: '';
  }

`
export const NoneDisplayedStyle = styled(BoxStyle)``
