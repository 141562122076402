import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import {
  useTranslation,
  RESET_PARAMS,
  selectQueryParams,
  dynamicLink,
  PageLayout,
  Row,
  Table,
  PrimaryButton,
  Title,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_PROPERTIES,
  RESET_PROPERTIES
} from '../../../types'
import {
  selectPropertiesLoading,
  selectPropertiesData,
  selectPropertiesCount
} from '../../../selectors'
import { fetchProperties } from '../../../services'
import { propertiesSearchSchema } from '../../../validation'
import { IMPORT_TYPES } from '../../../constants'

import PropertiesSearchBar from './PropertiesSearchBar'
import DeleteModal from './DeleteModal'
import DownloadCSVButton from '../../../components/Misc/DownloadCSVButton'

const breadcrumbs = [
  {
    label: 'navigation:properties',
    link: '/properties'
  }
]

const PropertiesPage = ({ navigate, location }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const { t } = useTranslation(['common', 'properties'])
  const prevQuery = useRef(null)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [deletePropertyID, setDeletePropertyID] = useState(null)
  const [deletePropertyName, setDeletePropertyName] = useState('')

  const fetchData = useCallback(
    () => {
      const schema = propertiesSearchSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_PROPERTIES, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_PROPERTIES })
          }
        })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_PROPERTIES })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const propertiesColumns = [
    {
      title: t('properties:property_name'),
      dataIndex: 'name',
      key: 'name',
      cellTitle: true,
      sortable: true
    },
    {
      title: t('properties:property_id'),
      dataIndex: 'id',
      key: 'id',
      sortable: true,
      authorized: '!custom.house_maker'
    },
    {
      title: t('properties:property_manage_id'),
      dataIndex: 'property_manage_id',
      key: 'property_manage_id',
      sortable: true
    },
    {
      title: t('properties:move_out_count'),
      dataIndex: 'move_out',
      key: 'move_out',
      authorized: '!custom.house_maker'
    },
    {
      title: t('properties:move_in_count'),
      dataIndex: 'move_in',
      key: 'move_in',
      authorized: '!custom.house_maker'
    },
    {
      title: t('dashboard:appDownloadCount'),
      dataIndex: 'app_download_count',
      key: 'app_download_count'
    },
    {
      title: t('area'),
      dataIndex: 'area',
      key: 'area'
    },
    // {
    //   title: '最終更新日時',
    //   dateTime: true,
    //   dataIndex: 'modified',
    //   key: 'modified',
    //   sortable: true
    // },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('copy'),
          onClick: (id, close) => {
            _handleCopy(id, close)
          },
          shouldRender: 'properties.functions.add'
        },
        {
          label: t('navigation:contracts_list'),
          onClick: (id, close, data) => {
            navigateToContracts(id, close, data)
          },
          shouldRender: 'contracts.enabled'
        },
        {
          label: t('delete'),
          dangerColor: true,
          onClick: (id, close, row) => {
            _deleteProperty(id, close, row)
          },
          shouldRender: 'properties.functions.delete'
        }
      ]
    }
  ]

  const _handleCopy = useCallback(
    (id, close) => {
      close()
      navigate('/properties/add', { state: { id, copy: true } })
    },
    [navigate]
  )
  const navigateToContracts = useCallback(
    (id, close, data) => {
      close()
      navigate(`/contracts/?property_id=${id}`)
    },
    [navigate]
  )

  const navigateToAdd = () => {
    navigate('./add/')
  }
  const navigateToCSV = () => {
    navigate(`/import?type=${IMPORT_TYPES.PROPERTIES}`)
  }
  const navigateToDetails = (id, row, e) => {
    const url = `/properties/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  const _closeDeleteModal = () => {
    setDeleteModalVisible(false)
    setDeletePropertyID(null)
    setDeletePropertyName('')
  }
  const _deleteProperty = (id, close, row) => {
    setDeleteModalVisible(true)
    setDeletePropertyID(id)
    setDeletePropertyName(row && row.name)
    close()
  }

  return (
    <PageLayout
      sidebar={
        () => <PropertiesSearchBar />
      }
      breadcrumbs={breadcrumbs}
      // loading={loading}
    >
      <DeleteModal
        visible={deleteModalVisible}
        onCancel={_closeDeleteModal}
        propertyID={deletePropertyID}
        propertyName={deletePropertyName}
      />
      <Row justify="between" align="center" mt="R" wrap={'wrap'}>
        <Title label="navigation:properties_list"/>
        <div>
          <DownloadCSVButton
            schemaBuilder={propertiesSearchSchema}
            fetchCSV={fetchProperties}
            filename="properties.csv"
          />
          <RoleControlled authorized="imports.functions.properties">
            <PrimaryButton
              label="add_csv"
              handleClick={navigateToCSV}
              withMargin
            />
          </RoleControlled>
          <RoleControlled authorized="properties.functions.add">
            <PrimaryButton
              label="properties:add_property"
              handleClick={navigateToAdd}
              reverse
              withMargin
            />
          </RoleControlled>
        </div>
      </Row>
      <Table
        fullHeight
        columns={propertiesColumns}
        rowKey="id"
        loadingSelector={selectPropertiesLoading}
        resultsCountSelector={selectPropertiesCount}
        dataSelector={selectPropertiesData}
        onRowClick={navigateToDetails}
      />
    </PageLayout>
  )
}
export default PropertiesPage

PropertiesPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
PropertiesPage.defaultProps = {

}
