import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_CONSTRUCTION_WORK,
  FETCH_CONSTRUCTION_WORK_SUCCESS,
  FETCH_CONSTRUCTION_WORK_FAIL,
  RESET_CONSTRUCTION_WORK,
  POST_CONSTRUCTION_WORK,
  POST_CONSTRUCTION_WORK_SUCCESS,
  POST_CONTRACT_INSPECTION_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_CONSTRUCTION_WORK]: (state, action) => {
    state.loading = true
  },
  [FETCH_CONSTRUCTION_WORK_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_CONSTRUCTION_WORK_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_CONSTRUCTION_WORK]: (state, action) => {
    state.posting = true
  },
  [POST_CONSTRUCTION_WORK_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CONTRACT_INSPECTION_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [RESET_CONSTRUCTION_WORK]: () => INITIAL_STATE
})
