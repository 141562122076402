import { createReducer } from '@reduxjs/toolkit'
import {
  PARSE_CSV,
  PARSE_CSV_SUCCESS,
  PARSE_CSV_FAIL,
  IMPORT_VALIDATION,
  IMPORT_VALIDATION_SUCCESS,
  IMPORT_VALIDATION_FAIL,
  IMPORT_DATA,
  IMPORT_DATA_PARTIAL_SUCCESS,
  IMPORT_DATA_PARTIAL_FAIL,
  IMPORT_DATA_SUCCESS,
  IMPORT_DATA_FAIL,
  UPDATE_STEP,
  UPDATE_IMPORT_TYPE,
  UPDATE_IMPORT_DATA,
  RESET_IMPORT
} from '../../types'
import { IMPORT_STEPS } from '../../constants'

const INITIAL_STATE = {
  step: 0,
  parsing: null,
  validating: null,
  importing: null,
  error: null,
  validationErrors: null,
  originalData: null,
  data: null,
  importType: undefined,
  chunksCount: null,
  currentChunk: null,
  partialErrors: [],
  ids: []
}

export default createReducer(INITIAL_STATE, {
  [PARSE_CSV]: (state, action) => {
    state.parsing = true
    state.error = null
  },
  [PARSE_CSV_SUCCESS]: (state, action) => {
    state.parsing = false
    state.originalData = action.payload
    state.data = action.payload && action.payload.map((row, i) => {
      return {
        ...row,
        originalIndex: i
      }
    })
    state.step = 1
  },
  [PARSE_CSV_FAIL]: (state, action) => {
    state.parsing = false
    state.step = 1
    state.error = action.payload
  },
  [IMPORT_VALIDATION]: (state, action) => {
    state.validating = true
    state.error = null
    state.validationErrors = null
  },
  [IMPORT_VALIDATION_SUCCESS]: (state, action) => {
    state.validating = false
    state.error = null
    state.validationErrors = null
  },
  [IMPORT_VALIDATION_FAIL]: (state, action) => {
    const { message, errors, data } = action.payload
    const errorRowIndexes = errors && Object.keys(errors)
    const indexedData = data && data.map((row, i) => {
      return {
        ...row,
        originalIndex: i
      }
    })
    const sortData = (a, b) => {
      if (errorRowIndexes && errorRowIndexes.length > 0) {
        if (errorRowIndexes.includes(String(a.originalIndex)) && !errorRowIndexes.includes(String(b.originalIndex))) {
          return -1
        }
        if (!errorRowIndexes.includes(String(a.originalIndex)) && errorRowIndexes.includes(String(b.originalIndex))) {
          return 1
        }
      }
      return 0
    }
    const sorted = indexedData && indexedData.sort(sortData)

    state.validating = false
    state.error = message
    state.validationErrors = errors
    state.data = sorted
  },
  [IMPORT_DATA]: (state, action) => {
    state.validating = true
    state.importing = true
    state.error = null
    state.validationErrors = null
    state.ids = []
    state.partialErrors = []
    state.chunksCount = null
    state.currentChunk = null
  },
  [IMPORT_DATA_PARTIAL_SUCCESS]: (state, action) => {
    const { ids, total, count } = action.payload
    state.chunksCount = total
    state.currentChunk = count
    state.ids = [...state.ids, ...ids]
  },
  [IMPORT_DATA_PARTIAL_FAIL]: (state, action) => {
    const { total, count, chunk } = action.payload
    state.chunksCount = total
    state.currentChunk = count
    state.partialErrors = [...state.partialErrors, ...chunk]
  },
  [IMPORT_DATA_SUCCESS]: (state, action) => {
    const { ids, count } = action.payload
    state.importing = false
    state.currentChunk = count
    state.ids = [...state.ids, ...ids]
  },
  [IMPORT_DATA_FAIL]: (state, action) => {
    const { count, error } = action.payload
    state.importing = false
    state.currentChunk = count
    if (error) {
      state.error = error
    }
  },
  [UPDATE_IMPORT_TYPE]: (state, action) => {
    state.importType = action.payload
  },
  [UPDATE_STEP]: (state, action) => {
    state.step = action.payload
  },
  [UPDATE_IMPORT_DATA]: (state, action) => {
    const { index, key, value } = action.payload
    const currentIndex = state.data.findIndex(item => item.originalIndex === index)
    // console.log('index', index)
    // console.log('currentIndex', currentIndex)
    // use original index
    state.data[currentIndex][key] = value
  },
  [RESET_IMPORT]: (state) => {
    if (state.step != null && (state.step <= IMPORT_STEPS.CONFIRM || state.step === IMPORT_STEPS.DONE)) {
      return INITIAL_STATE
    }
  }
})
