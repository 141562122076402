import {
  styled,
  getColor,
  SPACING
} from '@gk-devteam/apmc-core-web'

const CellRow = styled.div`
  display: flex;
  width: 100%;
  & > .cell {
    &:first-child {
      flex: 1;
    }
    &:nth-child(2) {
      width: 150px;
    }
    &:nth-child(3) {
      width: 72px;
    }
    &:nth-child(4) {
      width: 72px;
    }
    &:nth-child(5) {
      width: 110px;
    }
    &:last-child {
      width: 50%;
    }
  }
`

const Cell = styled.div`
  padding: 14px 4px;
  box-sizing: border-box;
`

export const UserListTable = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: scroll;
`

export const UserListHeader = styled(CellRow)``

export const UserListHeaderText = styled(Cell)`
  font-weight: bold;
`

export const UserRow = styled(CellRow)`
  padding: 0 ${SPACING.REGULAR}rem;
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  &:not(:last-child) {
    border-bottom: none;
  }
`

export const UserCell = styled(Cell)`
  &:first-child{
    white-space: nowrap;
  }
`
