import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Column, FRONT_DATE_FORMAT
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { selectPropertyEventsByDay } from '../../../selectors'
import { TOGGLE_VIEW_PROPERTY_EVENTS_MODAL, SET_ACTIVE_PROPERTY_EVENTS } from '../../../types'

import { DayRowStyle, RowStyle } from './PropertyEventsPage.style'

function PropertyEventRow ({ day }) {
  const dispatch = useDispatch()
  const data = useSelector(state => selectPropertyEventsByDay(state, day))

  const _handleRowClick = () => {
    dispatch({ type: SET_ACTIVE_PROPERTY_EVENTS, payload: day.format(FRONT_DATE_FORMAT) })
    dispatch({ type: TOGGLE_VIEW_PROPERTY_EVENTS_MODAL })
  }

  if (!day) return null

  return (
    <RowStyle align="start" onClick={_handleRowClick}>
      <DayRowStyle day={day.day()}>
        <span>{day.format('DD')}</span>
        <span>({day.format('dd')})</span>
      </DayRowStyle>
      <Column align="start">
        {data?.map((event, i) => <div key={i}>{event.title}</div>)}
      </Column>
    </RowStyle>
  )
}

PropertyEventRow.propTypes = {
  day: PropTypes.object
}

export default PropertyEventRow
