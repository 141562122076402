import React from 'react'
import { useSelector } from 'react-redux'
import {
  MainLayout,
  // Row,
  // Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { selectServiceName, selectServiceType } from '../../../selectors'
import { SERVICES_ID, SERVICES_WITH_SETTINGS } from '../../../constants'

import ServiceHeader from '../common/ServiceHeader'
import NotFoundPage from '../../NotFoundPage'
import CloudSign from './CloudSign'

function ServiceSettingsPage ({ serviceID }) {
  const { t } = useTranslation()
  const serviceName = useSelector(selectServiceName)
  const type = useSelector(selectServiceType)
  const breadcrumbs = [
    {
      label: 'navigation:services',
      link: `/settings/services?type=${type}`
    },
    {
      label: serviceName,
      link: `/settings/services/${serviceID}/properties`
    },
    {
      label: 'navigation:settings',
      link: `/settings/services/${serviceID}/additional_settings`
    }
  ]

  const _renderSettingsContent = () => {
    switch (serviceID) {
      case SERVICES_ID.CLOUDSIGN:
        return <CloudSign />
      default:
        return null
    }
  }

  if (!SERVICES_WITH_SETTINGS.includes(serviceID)) return <NotFoundPage withSidebar />

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
    >
      {/* <Row mb="R">
        <Title label="navigation:settings" />
      </Row> */}
      <ServiceHeader level={t('navigation:settings')}/>
      { _renderSettingsContent() }
    </MainLayout>
  )
}

ServiceSettingsPage.propTypes = {
  serviceID: PropTypes.string.isRequired
}

export default ServiceSettingsPage
