import {
  BORDER_RADIUS,
  FONT_SIZE,
  getColor,
  Row,
  SPACING,
  styled
 } from "@gk-devteam/apmc-core-web";

export const ContractSignDateStyle = styled(Row)`
  ${BORDER_RADIUS}
  border: 2px solid ${props => getColor(props, 'warning')};
  padding: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
  background-color: #fffef0;

  .anticon {
    ${FONT_SIZE.LARGE}
    color: ${props => getColor(props, 'warning')};
    margin-right: ${SPACING.REGULAR}rem;
  }
`
