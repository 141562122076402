import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_CONTRACTS_PROCESSING,
  FETCH_CONTRACTS_PROCESSING_SUCCESS,
  FETCH_CONTRACTS_PROCESSING_FAIL,
  RESET_CONTRACTS_PROCESSING,
  FETCH_CONTRACTS_RENEWING,
  FETCH_CONTRACTS_RENEWING_SUCCESS,
  FETCH_CONTRACTS_RENEWING_FAIL,
  FETCH_CONTRACTS_CANCELING,
  FETCH_CONTRACTS_CANCELING_SUCCESS,
  FETCH_CONTRACTS_CANCELING_FAIL,
  FETCH_CONTRACTS_PROCESSING_COUNT_SUCCESS,
  RESET_CONTRACTS_RENEWING,
  RESET_CONTRACTS_CANCELING
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  askingForRenewalCount: 0,
  renewingCount: 0,
  requestingCancelCount: 0,
  cancelingCount: 0,
  renewingPeriodCount: 0,
  processingContracts: [],
  renewing: [],
  canceling: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_CONTRACTS_PROCESSING]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_CONTRACTS_PROCESSING_SUCCESS]: (state, action) => {
    state.loading = false
    state.processingContracts = action.payload
  },
  [FETCH_CONTRACTS_PROCESSING_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_CONTRACTS_PROCESSING]: (state, action) => {
    state.processingContracts = []
  },
  [FETCH_CONTRACTS_RENEWING]: (state, action) => {
  },
  [FETCH_CONTRACTS_RENEWING_SUCCESS]: (state, action) => {
    state.renewing = action.payload
  },
  [FETCH_CONTRACTS_RENEWING_FAIL]: (state, action) => {
  },
  [FETCH_CONTRACTS_CANCELING]: (state, action) => {
  },
  [FETCH_CONTRACTS_CANCELING_SUCCESS]: (state, action) => {
    state.canceling = action.payload
  },
  [FETCH_CONTRACTS_CANCELING_FAIL]: (state, action) => {
  },
  [FETCH_CONTRACTS_PROCESSING_COUNT_SUCCESS]: (state, action) => {
    const {
      asking_for_renewal_count,
      renewing_count,
      requesting_cancel_count,
      canceling_count,
      renewing_period_count
    } = action.payload
    state.askingForRenewalCount = asking_for_renewal_count
    state.renewingCount = renewing_count
    state.requestingCancelCount = requesting_cancel_count
    state.cancelingCount = canceling_count
    state.renewingPeriodCount = renewing_period_count
  },
  [RESET_CONTRACTS_RENEWING]: (state, action) => {
    state.renewing = []
  },
  [RESET_CONTRACTS_CANCELING]: (state, action) => {
    state.canceling = []
  }
})
