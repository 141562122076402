import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  RepeaterRemoveButton,
  TextInput
} from '@gk-devteam/apmc-core-web'

const CustomFieldRow = ({ index }) => {
  return (
    <Row wrap="wrap" align="baseline">
      <TextInput
        flex={1}
        type="text"
        name={`rent_fees[${index}].label`}
        label='contracts:rent_fees.label'
        placeholder=""
        repeaterField
        required
      />
      <TextInput
        flex={1}
        type="number"
        name={`rent_fees[${index}].amount`}
        label='contracts:rent_fees.amount'
        placeholder=""
        suffix="currency_symbol"
        repeaterField
        required
      />
      <RepeaterRemoveButton
        index={index}
        propertyKey="rent_fees"
        label="delete"
      />
    </Row>
  )
}

export default memo(CustomFieldRow)

CustomFieldRow.propTypes = {
  index: PropTypes.number.isRequired
}
