import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CancelToken } from 'axios'
import {
  Card
} from '@gk-devteam/apmc-core-web'

import { fetchAppUsers } from '../../../services'
import { selectPropertyID } from '../../../selectors'

import PropertySettingsChairman from './PropertySettingsChairman'
import PropertySettingsInspectors from './PropertySettingsInspectors'

const PropertySettings = () => {
  const propertyID = useSelector(selectPropertyID)
  const [users, setUsers] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(
    async () => {
      try {
        setLoading(true)
        const cancelSource = CancelToken.source()
        const res = await fetchAppUsers({ property_id: propertyID, limit: 5000 }, cancelSource)
        if (res.data && res.data.results) {
          setUsers(res.data.results)
        } else {
          setUsers(null)
        }
        setLoading(false)
      } catch (error) {
        console.warning('error', error)
        setLoading(false)
      }
    },
    [propertyID]
  )

  useEffect(() => {
    if (propertyID) {
      fetchData()
    }
  }, [fetchData, propertyID])

  return (
    <Card
      title='properties:settings'
      mb="L"
    >
      <PropertySettingsChairman
        loading={loading}
        users={users}
      />
      <PropertySettingsInspectors
        loading={loading}
        users={users}
      />
    </Card>
  )
}

export default PropertySettings

PropertySettings.propTypes = {}
