import { createSelector } from 'reselect'
import { i18n } from '../../locales'

const selectPublicFacilities = state => state.publicFacilities

const parseDisplay = (data) => {
  const items = []
  for (const item of data) {
    items.push(item.display)
  }

  if (items.every(item => item === 0 || item === false)) return 0 // all hidden
  if (items.every(item => item === 2 || item === true)) return 2 // all displayed
  return 1
}

export const selectPublicFacilitiesLoading = createSelector(
  selectPublicFacilities,
  publicFacilities => publicFacilities.loading
)
export const selectPublicFacilitiesPosting = createSelector(
  selectPublicFacilities,
  publicFacilities => publicFacilities.posting
)
export const selectPublicFacilitiesCategories = createSelector(
  selectPublicFacilities,
  publicFacilities => publicFacilities.categories
)
export const selectPublicFacilitiesCategoriesOptions = createSelector(
  selectPublicFacilities,
  publicFacilities => publicFacilities.categories && publicFacilities.categories.map(category => {
    return {
      label: category.name,
      value: category.id
    }
  })
)
export const selectPublicFacilitiesCategoriesOptionsWithReset = createSelector(
  selectPublicFacilities,
  publicFacilities => {
    if (publicFacilities.categories) {
      const options = publicFacilities.categories.map(category => {
        return {
          label: category.name,
          value: category.id
        }
      })
      return [
        {
          label: i18n.t('all'),
          value: ''
        },
        ...options
      ]
    }
    return null
  }
)
export const selectPublicFacilitiesData = createSelector(
  selectPublicFacilities,
  publicFacilities => publicFacilities.data
)
export const selectPublicFacilitiesAllAdditionalDataDisplayed = createSelector(
  selectPublicFacilities,
  publicFacilities => {
    if (publicFacilities.data) {
      const data = publicFacilities.data.filter(publicFacility => publicFacility.public_facility_type_id !== 0)
      return parseDisplay(data)
    }
    return 0
  }
)
export const selectPublicFacilitiesHiddenBy = createSelector(
  selectPublicFacilities,
  publicFacilities => publicFacilities.hiddenBy
)
export const selectPublicFacilitiesHiddenById = createSelector(
  selectPublicFacilities,
  publicFacilities => publicFacilities.hiddenById
)
