import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row,
  SideMenu,
  EmptyData
} from '@gk-devteam/apmc-core-web'

import { BULK_TOGGLE_FAQ } from '../../../../types'

import FaqListItemHeader from './FaqListItemHeader'
import { PanelDisabledContext } from '../../../../components/Display/Panels/PanelDisabled'
import PanelBlockHeader from '../../../../components/Display/Panels/PanelBlockHeader'
import { AnswerStyle } from '../faq.style'
import {
  BlockStyle,
  CollapseStyle,
  PanelStyle
} from '../../panels.style'

const FaqList = ({
  filter,
  dataSelector,
  displaySelector,
  idSelector,
  title,
  type,
  handleFaqDisplay,
  sideMenu,
  level
}) => {
  const faq = useSelector(dataSelector)
  const disabled = useContext(PanelDisabledContext)

  const _renderPanel = (items, categoryName, categoryID) => {
    if (!items) return null
    return items.map((item, i) => {
      return (
        <PanelStyle
          header={(
            <FaqListItemHeader
              item={item}
              categoryName={categoryName}
              handleFaqDisplay={handleFaqDisplay}
              idSelector={idSelector}
            />
          )}
          key={`${categoryName}_item${i}`}
        >
          <Row align="baseline">
            <span>A</span>
            <AnswerStyle>{item.answer}</AnswerStyle>
            <SideMenu
              menu={sideMenu}
              data={item}
              category={categoryID}
            />
          </Row>
        </PanelStyle>
      )
    })
  }

  const _renderList = () => {
    if (!faq) return null
    if (faq.every(category => category.items && category.items.length === 0)) return <EmptyData />
    if (!filter) {
      return faq.map(category => {
        return _renderPanel(category.items, category.category_name, category.id)
      })
    } else {
      const filtered = faq.find(category => filter === category.id)
      if (filtered) {
        return _renderPanel(filtered.items, filtered.category_name, filtered.id)
      }
    }
  }

  return (
    <BlockStyle>
      <PanelBlockHeader
        title={title}
        idSelector={idSelector}
        displaySelector={displaySelector}
        type={type}
        level={level}
        toggleType={BULK_TOGGLE_FAQ}
        authorized="faq.functions.update"
      />
      <CollapseStyle
        bordered={false}
        expandIconPosition="right"
        disabled={disabled}
        // defaultActiveKey="契約_item1"
      >
        { _renderList() }
      </CollapseStyle>
    </BlockStyle>
  )
}

export default FaqList

FaqList.propTypes = {
  dataSelector: PropTypes.func.isRequired,
  displaySelector: PropTypes.func.isRequired,
  idSelector: PropTypes.func,
  title: PropTypes.string.isRequired,
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.oneOf(['admin_faqs', 'property_faqs']).isRequired,
  level: PropTypes.oneOf(['admin', 'property', 'room']).isRequired,
  handleFaqDisplay: PropTypes.func.isRequired,
  sideMenu: PropTypes.array
}
