import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  NotFoundIcon,
  Row,
  Column,
  CustomText,
  CardTitle
} from '@gk-devteam/apmc-core-web'

import { selectPages } from '../../selectors'

import {
  PageStyle,
  LinkStyle,
  ImageContainerStyle
} from './NotFoundPage.style'

const NotFoundPage = ({ withSidebar }) => {
  const { t } = useTranslation(['common', 'not_found'])
  const pages = useSelector(selectPages)
  if (!pages) return null

  const {
    dashboard,
    imports,
    contracts,
    properties,
    notices,
    app_users,
    admin_tasks,
    user_management,
    settings
  } = pages
  return (
    <PageStyle fullScreen withSidebar={withSidebar}>
      <Column>
        <Row mb={'R'} justify={'center'}>
          <ImageContainerStyle>
            <NotFoundIcon />
          </ImageContainerStyle>
        </Row>
        <CustomText label={'not_found:not_found'} textColor={'#F4A800'} textSize={'2rem'}/>
        <Row mt={'R'}>
          <Row wrap={'wrap'}>
            <Row justify={'center'}>
              <CardTitle label={'not_found:wrong_url'}/>
            </Row>
            <Row justify={'center'}>
              <CardTitle label={'not_found:follow_link'}/>
            </Row>
          </Row>
        </Row>
        <Row mt={'L'} justify={'center'}>
          { dashboard && dashboard.enabled ? <LinkStyle to={'/'}>{t('navigation:dashboard')}</LinkStyle> : null }
          { imports && imports.enabled ? <LinkStyle to={'/import'}>{t('navigation:import')}</LinkStyle> : null }
          { contracts && contracts.enabled ? <LinkStyle to={'/contracts'}>{t('navigation:contracts')}</LinkStyle> : null }
          { properties && properties.enabled ? <LinkStyle to={'/properties'}>{t('navigation:properties')}</LinkStyle> : null }
          { notices && notices.enabled ? <LinkStyle to={'/notices'}>{t('navigation:notices')}</LinkStyle> : null }
          { app_users && app_users.enabled ? <LinkStyle to={'/app_users'}>{t('navigation:app_users')}</LinkStyle> : null }
          { admin_tasks && admin_tasks.enabled ? <LinkStyle to={'/admin_tasks'}>{t('navigation:admin_tasks')}</LinkStyle> : null }
          { user_management && user_management.enabled ? <LinkStyle to={'/user_management'}>{t('navigation:management')}</LinkStyle> : null }
          { settings && settings.enabled ? <LinkStyle to={'/settings'}>{t('navigation:settings')}</LinkStyle> : null }
        </Row>
      </Column>
    </PageStyle>
  )
}
export default NotFoundPage

NotFoundPage.propTypes = {
  withSidebar: PropTypes.bool
}
NotFoundPage.defaultProps = {

}
