import React from 'react'
import { useSelector } from 'react-redux'
import dot from 'dot-object'
import {
  Text,
  Row
} from '@gk-devteam/apmc-core-web'

import { SURVEY_RECIPIENT_TYPE } from '../../../constants'

import RecipientsItem from './RecipientsItem'

const RecipientsList = () => {
  const recipientType = useSelector(state => state.form.recipient_type)
  const recipients = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    return form.recipients_list
  })
  if (recipientType && recipientType === SURVEY_RECIPIENT_TYPE.ALL) return null
  if (!recipients) return null

  let label = ''
  switch (recipientType) {
    case SURVEY_RECIPIENT_TYPE.PROPERTY:
      label = 'notices:recipients_properties'
      break
    case SURVEY_RECIPIENT_TYPE.USER:
      label = 'notices:recipients_users'
      break
    default:
      break
  }

  const _renderRecipients = () => {
    return recipients.map(recipient => <RecipientsItem key={recipient.id} id={recipient.id} label={recipient.label} />)
  }

  return (
    <Row wrap="wrap" align="baseline" mt="S">
      <Row wrap="wrap" align="baseline" mt="S">
        <Text label={label} />
      </Row>
      <Row wrap="wrap" align="baseline" mt="S">
        { _renderRecipients() }
      </Row>
    </Row>
  )
}

export default RecipientsList

RecipientsList.propTypes = {}
