import { put, call, cancelled, takeLatest } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchRefunds
} from '../../services'

import {
  FETCH_REFUNDS_SUCCESS,
  FETCH_REFUNDS_FAIL,
  FETCH_REFUNDS
} from '../../types'
import { i18n } from '../../locales'

export function * fetchRefundsSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchRefunds, query, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_REFUNDS_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_REFUNDS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_REFUNDS_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchRefunds () {
  yield takeLatest(FETCH_REFUNDS, fetchRefundsSaga)
}
