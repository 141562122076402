import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchYasueSurvey
} from '../../services'
import {
  FETCH_YASUE_SURVEY,
  FETCH_YASUE_SURVEY_SUCCESS,
  FETCH_YASUE_SURVEY_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchYasueSurveySaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchYasueSurvey, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_YASUE_SURVEY_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_YASUE_SURVEY_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_YASUE_SURVEY_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchYasueSurvey () {
  yield takeLatest(FETCH_YASUE_SURVEY, fetchYasueSurveySaga)
}
