import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  ItemNotFound,
  PageLoader,
  Row,
  Title
} from '@gk-devteam/apmc-core-web'
// import { useNavigate } from '@reach/router'

import {
  FETCH_REFUND,
  RESET_REFUND,
  UPDATE_REFUND_STATUS
} from '../../../../types'
import {
  selectRefundLoading,
  selectRefundData,
  selectRefundError
} from '../../../../selectors'
import { ANABUKI } from '../../../../constants'

import RefundForm from '../../../../forms/RefundForm/RefundForm'
import { StatusRadioStyle } from './RefundDetailPage.style'

const RefundDetailPage = ({ refundID }) => {
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const data = useSelector(selectRefundData)
  const loading = useSelector(selectRefundLoading)
  const error = useSelector(selectRefundError)

  useEffect(() => {
    dispatch({ type: FETCH_REFUND, id: refundID })
    return () => {
      dispatch({ type: RESET_REFUND })
    }
  }, [dispatch, refundID])

  const breadcrumbs = [
    {
      label: 'navigation:anabuki.refunds',
      link: '/anabuki/refunds'
    },
    {
      label: 'navigation:anabuki.refund_details',
      link: `/anabuki/refunds/edit/${refundID}`
    }
  ]

  const _handleStatusChange = (status) => {
    console.log('status', status)
    const onSuccess = () => {
      // navigate('/anabuki/refunds')
      dispatch({ type: FETCH_REFUND, id: refundID })
    }
    dispatch({
      type: UPDATE_REFUND_STATUS,
      data: { status },
      id: refundID,
      successRedirect: onSuccess
    })
  }

  const _renderStatusToggle = () => {
    return (
      <StatusRadioStyle
        name="refund_status"
        handleChange={_handleStatusChange}
        value={data?.status}
        options={[
          {
            label: 'refunds:statuses.waiting',
            value: ANABUKI.SUPPORT_STATUS.WAITING
          },
          {
            label: 'refunds:statuses.in_progress',
            value: ANABUKI.SUPPORT_STATUS.IN_PROGRESS
          },
          {
            label: 'refunds:statuses.completed',
            value: ANABUKI.SUPPORT_STATUS.COMPLETED
          },
          {
            label: 'refunds:statuses.rejected',
            value: ANABUKI.SUPPORT_STATUS.REJECTED
          }
        ]}
        disabled={data?.status === ANABUKI.SUPPORT_STATUS.COMPLETED ? 1 : 0}
        noLabel
        size="fluid"
        autoH
        mb="0"
      />
    )
  }

  return loading
    ? <PageLoader />
    : error && error.code === 404
      ? <ItemNotFound item={t('navigation:refunds')} link="/anabuki/refunds" />
      : (
        <PageLayout
          breadcrumbs={breadcrumbs}
          loading={!data}
          lastEdited={data && data.modified}
          lastEditedBy={data && data.modifiedBy}
        >
          <Row justify="between" >
            <Title label='navigation:anabuki.refund_details' />
            { _renderStatusToggle() }
          </Row>
          <RefundForm data={data} />
        </PageLayout>
      )
}
export default RefundDetailPage

RefundDetailPage.propTypes = {
  location: PropTypes.object.isRequired,
  refundID: PropTypes.string.isRequired
}
RefundDetailPage.defaultProps = {

}
