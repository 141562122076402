import React from 'react'
import {
  Row,
  Checkbox,
  FormControlLabel,
  useTranslation
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

const weekdays = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun'
]

function Weekdays () {
  const { t } = useTranslation()

  return (
    <>
      <FormControlLabel label={t('chat.auto_reply_days')} help="chat.auto_reply_days_help"/>
      <Row mb="S" wrap="wrap">
        {
          weekdays.map(weekday => {
            return (
              <Checkbox
                key={weekday}
                mb="XS"
                autoH
                noLabel
                name={`daysOfWeek.${weekday}`}
                valueLabel={`daysOfWeek.${weekday}`}
              />
            )
          })
        }
      </Row>
    </>
  )
}

Weekdays.propTypes = {

}

export default Weekdays
