import { pdfjs } from 'react-pdf'

const {
  REACT_APP_SEIKATSU_SERVICE_ID,
  REACT_APP_CLOUDSIGN_SERVICE_ID
} = process.env

export const MAX_ROOMS = 100

export const USER_EMPTY_NAME = '*** ***'

export const LOGIN_STATUS = {
  NO_LOGIN: 1,
  NO_CONFIRMATION: 4,
  ACTIVE: 2,
  INACTIVE: 3
}

export const DISABLED_BY = {
  ADMIN: 1,
  PROPERTY: 2,
  ROOM: 3,
  CONTRACT: 4
}

export const SUPPORT_STATUS = {
  WAITING: 4,
  UNDEFINED: 3.5,
  CONFIRMED: 3,
  COMPLETED: 2,
  UNUSED: 1,
  ARCHIVE: 0.5
}
export const DOCUMENT_STATUS = {
  DRAFT: {
    value: 0,
    color: 'pageTitle'
  },
  WAITING_CONFIRMATION: {
    value: 1,
    color: 'process'
  },
  DONE: {
    value: 2,
    color: 'success'
  },
  CANCELED_REJECTED: {
    value: 3,
    color: 'danger'
  }
}
export const CONTRACT_STATUS = {
  MOVING_IN: {
    value: 0,
    color: 'success'
  },
  RESIDING: {
    value: 1,
    color: 'info'
  },
  MOVING_OUT: {
    value: 2,
    color: 'accent'
  },
  MOVED_OUT: {
    value: 3,
    color: 'pageTitle'
  },
  WAITING_APPROVAL: {
    value: 4,
    color: 'text'
  },
  ASKING_FOR_RENEWAL_UNREAD: {
    value: 5,
    color: 'danger'
  },
  ASKING_FOR_RENEWAL_READ: {
    value: 6,
    color: 'process'
  },
  RENEWING: {
    value: 7,
    color: 'process'
  },
  REQUESTING_CANCEL: {
    value: 8,
    color: 'danger'
  },
  CANCELING: {
    value: 9,
    color: 'process'
  },
  IN_RENEWING_PERIOD: {
    value: 10,
    color: 'warning'
  },
  RENEWING_CONFIRMED: {
    value: 11,
    color: 'success'
  },
  WAITING_RENEWAL_CONFIRMATION: {
    value: 12,
    color: 'danger'
  },
  EXPIRED_CONTRACT: {
    value: 13,
    color: 'ppt'
  }
}

export const CONTRACT_RENEWAL_DONE = 'renewal_done'
export const CONTRACT_CANCEL_DONE = 'cancel_done'

export const TEMPLATE_TYPES = {
  RENEW: 1,
  CANCEL: 2
}

export const ROLES = {
  AGENCY_MAIN_ADMIN: 1,
  AGENCY_ADMIN: 2,
  AGENCY_EDITOR: 3,
  AGENCY_MEMBER: 4,
  OWNER: 5,
  GUEST: 6,
  SUPPORT: 7
}

export const SEX_OPTIONS = [
  {
    label: 'app_users:sex_options.unknown',
    value: 0
  },
  {
    label: 'app_users:sex_options.male',
    value: 1
  },
  {
    label: 'app_users:sex_options.female',
    value: 2
  }
]
export const ADMIN_USER_ROLE_OPTIONS = [
  {
    label: 'user_management:admin_user_role_options.superadmin',
    value: 1
  },
  {
    label: 'user_management:admin_user_role_options.admin',
    value: 2
  },
  {
    label: 'user_management:admin_user_role_options.editor',
    value: 3
  },
  {
    label: 'user_management:admin_user_role_options.user',
    value: 4
  }
]

export const USER_TYPES = {
  RESIDENT_CONTRACTOR: 1,
  CONTRACTOR_ONLY: 2,
  RESIDENT_ONLY: 3,
  CONTRACTOR: 4,
  RESIDENT: 5
}

export const CONTRACT_DURATION_OPTIONS = [
  {
    label: 'contracts:contract_duration_options.year',
    value: 1
  },
  {
    label: 'contracts:contract_duration_options.month',
    value: 2
  },
  {
    label: 'contracts:contract_duration_options.week',
    value: 3
  },
  {
    label: 'contracts:contract_duration_options.day',
    value: 4
  }
]

export const CONTRACT_CANCEL_REASONS = [
  {
    label: 'contracts:contract_cancel_reasons.family_relocation',
    value: 1
  },
  {
    label: 'contracts:contract_cancel_reasons.transfer',
    value: 2
  },
  {
    label: 'contracts:contract_cancel_reasons.career_change',
    value: 3
  },
  {
    label: 'contracts:contract_cancel_reasons.new_job',
    value: 4
  },
  {
    label: 'contracts:contract_cancel_reasons.school',
    value: 5
  },
  {
    label: 'contracts:contract_cancel_reasons.cohabitation',
    value: 6
  },
  {
    label: 'contracts:contract_cancel_reasons.marriage',
    value: 7
  },
  {
    label: 'contracts:contract_cancel_reasons.homecoming',
    value: 8
  },
  {
    label: 'contracts:contract_cancel_reasons.misc',
    value: 9
  }
]
export const BANK_TYPE = [
  {
    label: 'contracts:bank_type.bank',
    value: 1
  },
  {
    label: 'contracts:bank_type.credit_union',
    value: 2
  },
  {
    label: 'contracts:bank_type.credit_association',
    value: 3
  },
  {
    label: 'contracts:bank_type.agricultural_cooperative',
    value: 4
  }
]
export const BRANCH_TYPE = [
  {
    label: 'contracts:branch_type.main',
    value: 1
  },
  {
    label: 'contracts:branch_type.branch',
    value: 2
  },
  {
    label: 'contracts:branch_type.sub-branch',
    value: 3
  }
]
export const ACCOUNT_TYPE = [
  {
    label: 'contracts:account_type.normal',
    value: 1
  },
  {
    label: 'contracts:account_type.checking',
    value: 2
  }
]

export const NOTICE_PUSH_NOTIFICATION_OPTIONS = [
  {
    label: 'notices:notice_push_notification_options.do_not_send',
    value: false
  },
  {
    label: 'notices:notice_push_notification_options.send',
    value: true
  }
]
export const NOTICE_RECIPIENT_TYPE_OPTIONS = [
  {
    label: 'notices:notice_recipient_type_options.all',
    value: 0
  },
  {
    label: 'notices:notice_recipient_type_options.property',
    value: 1
  },
  {
    label: 'notices:notice_recipient_type_options.area',
    value: 3
  },
  {
    label: 'notices:notice_recipient_type_options.user',
    value: 2
  },
  {
    label: 'notices:notice_recipient_type_options.contract',
    value: 4
  }
]
export const HOUSE_MAKER_NOTICE_RECIPIENT_TYPE_OPTIONS = [
  {
    label: 'notices:notice_recipient_type_options.all',
    value: 0
  },
  {
    label: 'notices:notice_recipient_type_options.property',
    value: 1
  },
  {
    label: 'notices:notice_recipient_type_options.area',
    value: 3
  },
  {
    label: 'notices:notice_recipient_type_options.user',
    value: 2
  }
]

export const YASUE_NOTICE_RECIPIENT_TYPE_OPTIONS = [
  {
    label: 'notices:notice_recipient_type_options.all',
    value: 0
  },
  {
    label: 'yasue:contracts.contract_manage_id',
    value: 4
  },
  {
    label: 'notices:notice_recipient_type_options.user',
    value: 2
  }
]

export const NOTICE_TYPE_OPTIONS = [
  {
    label: 'notices:notice_type_options.draft',
    value: 0
  },
  {
    label: 'notices:notice_type_options.publish',
    value: 1
  },
  {
    label: 'notices:notice_type_options.timer',
    value: 2
  }
]

export const NOTICE_USER_TYPE_OPTIONS = [
  {
    label: 'notices:notice_user_type_options.all',
    value: 1
  },
  {
    label: 'notices:notice_user_type_options.contractor',
    value: 2
  },
  {
    label: 'notices:notice_user_type_options.resident',
    value: 3
  },
  {
    label: 'notices:notice_user_type_options.contractor_only',
    value: 4
  },
  {
    label: 'notices:notice_user_type_options.resident_only',
    value: 5
  }
]

export const COUPON_RECIPIENT_TYPE_OPTIONS = [
  {
    label: '全員',
    value: 1
  },
  {
    label: '物件',
    value: 2
  },
  {
    label: '部屋番号',
    value: 3
  }
]

export const NATIONALITY_OPTIONS = [
  {
    label: 'アイスランド',
    value: 'ISL'
  },
  {
    label: 'アイルランド',
    value: 'IRL'
  },
  {
    label: 'アゼルバイジャン',
    value: 'AZE'
  },
  {
    label: 'アフガニスタン',
    value: 'AFG'
  },
  {
    label: 'アメリカ合衆国',
    value: 'USA'
  },
  {
    label: 'アメリカ領ヴァージン諸島',
    value: 'VIR'
  },
  {
    label: 'アメリカ領サモア',
    value: 'ASM'
  },
  {
    label: 'アラブ首長国連邦',
    value: 'ARE'
  },
  {
    label: 'アルジェリア',
    value: 'DZA'
  },
  {
    label: 'アルゼンチン',
    value: 'ARG'
  },
  {
    label: 'アルバ',
    value: 'ABW'
  },
  {
    label: 'アルバニア',
    value: 'ALB'
  },
  {
    label: 'アルメニア',
    value: 'ARM'
  },
  {
    label: 'アンギラ',
    value: 'AIA'
  },
  {
    label: 'アンゴラ',
    value: 'AGO'
  },
  {
    label: 'アンティグア・バーブーダ',
    value: 'ATG'
  },
  {
    label: 'アンドラ',
    value: 'AND'
  },
  {
    label: 'イエメン',
    value: 'YEM'
  },
  {
    label: 'イギリス',
    value: 'GBR'
  },
  {
    label: 'イギリス領インド洋地域',
    value: 'IOT'
  },
  {
    label: 'イギリス領ヴァージン諸島',
    value: 'VGB'
  },
  {
    label: 'イスラエル',
    value: 'ISR'
  },
  {
    label: 'イタリア',
    value: 'ITA'
  },
  {
    label: 'イラク',
    value: 'IRQ'
  },
  {
    label: 'イラン',
    value: 'IRN'
  },
  {
    label: 'インド',
    value: 'IND'
  },
  {
    label: 'インドネシア',
    value: 'IDN'
  },
  {
    label: 'ウォリス・フツナ',
    value: 'WLF'
  },
  {
    label: 'ウガンダ',
    value: 'UGA'
  },
  {
    label: 'ウクライナ',
    value: 'UKR'
  },
  {
    label: 'ウズベキスタン',
    value: 'UZB'
  },
  {
    label: 'ウルグアイ',
    value: 'URY'
  },
  {
    label: 'エクアドル',
    value: 'ECU'
  },
  {
    label: 'エジプト',
    value: 'EGY'
  },
  {
    label: 'エストニア',
    value: 'EST'
  },
  {
    label: 'エチオピア',
    value: 'ETH'
  },
  {
    label: 'エリトリア',
    value: 'ERI'
  },
  {
    label: 'エルサルバドル',
    value: 'SLV'
  },
  {
    label: 'オーストラリア',
    value: 'AUS'
  },
  {
    label: 'オーストリア',
    value: 'AUT'
  },
  {
    label: 'オーランド諸島',
    value: 'ALA'
  },
  {
    label: 'オマーン',
    value: 'OMN'
  },
  {
    label: 'オランダ',
    value: 'NLD'
  },
  {
    label: 'ガーナ',
    value: 'GHA'
  },
  {
    label: 'カーボベルデ',
    value: 'CPV'
  },
  {
    label: 'ガーンジー',
    value: 'GGY'
  },
  {
    label: 'ガイアナ',
    value: 'GUY'
  },
  {
    label: 'カザフスタン',
    value: 'KAZ'
  },
  {
    label: 'カタール',
    value: 'QAT'
  },
  {
    label: '合衆国領有小離島',
    value: 'UMI'
  },
  {
    label: 'カナダ',
    value: 'CAN'
  },
  {
    label: 'ガボン',
    value: 'GAB'
  },
  {
    label: 'カメルーン',
    value: 'CMR'
  },
  {
    label: 'ガンビア',
    value: 'GMB'
  },
  {
    label: 'カンボジア',
    value: 'KHM'
  },
  {
    label: '北マリアナ諸島',
    value: 'MNP'
  },
  {
    label: 'ギニア',
    value: 'GIN'
  },
  {
    label: 'ギニアビサウ',
    value: 'GNB'
  },
  {
    label: 'キプロス',
    value: 'CYP'
  },
  {
    label: 'キューバ',
    value: 'CUB'
  },
  {
    label: 'キュラソー',
    value: 'CUW'
  },
  {
    label: 'ギリシャ',
    value: 'GRC'
  },
  {
    label: 'キリバス',
    value: 'KIR'
  },
  {
    label: 'キルギス',
    value: 'KGZ'
  },
  {
    label: 'グアテマラ',
    value: 'GTM'
  },
  {
    label: 'グアドループ',
    value: 'GLP'
  },
  {
    label: 'グアム',
    value: 'GUM'
  },
  {
    label: 'クウェート',
    value: 'KWT'
  },
  {
    label: 'クック諸島',
    value: 'COK'
  },
  {
    label: 'グリーンランド',
    value: 'GRL'
  },
  {
    label: 'クリスマス島',
    value: 'CXR'
  },
  {
    label: 'グルジア',
    value: 'GEO'
  },
  {
    label: 'グレナダ',
    value: 'GRD'
  },
  {
    label: 'クロアチア',
    value: 'HRV'
  },
  {
    label: 'ケイマン諸島',
    value: 'CYM'
  },
  {
    label: 'ケニア',
    value: 'KEN'
  },
  {
    label: 'コートジボワール',
    value: 'CIV'
  },
  {
    label: 'ココス諸島',
    value: 'CCK'
  },
  {
    label: 'コスタリカ',
    value: 'CRI'
  },
  {
    label: 'コモロ',
    value: 'COM'
  },
  {
    label: 'コロンビア',
    value: 'COL'
  },
  {
    label: 'コンゴ共和国',
    value: 'COG'
  },
  {
    label: 'コンゴ民主共和国',
    value: 'COD'
  },
  {
    label: 'サウジアラビア',
    value: 'SAU'
  },
  {
    label: 'サウスジョージア・サウスサンドウィッチ諸島',
    value: 'SGS'
  },
  {
    label: 'サモア',
    value: 'WSM'
  },
  {
    label: 'サントメ・プリンシペ',
    value: 'STP'
  },
  {
    label: 'サン・バルテルミー',
    value: 'BLM'
  },
  {
    label: 'ザンビア',
    value: 'ZMB'
  },
  {
    label: 'サンピエール島・ミクロン島',
    value: 'SPM'
  },
  {
    label: 'サンマリノ',
    value: 'SMR'
  },
  {
    label: 'サン・マルタン',
    value: 'MAF'
  },
  {
    label: 'シエラレオネ',
    value: 'SLE'
  },
  {
    label: 'ジブチ',
    value: 'DJI'
  },
  {
    label: 'ジブラルタル',
    value: 'GIB'
  },
  {
    label: 'ジャージー',
    value: 'JEY'
  },
  {
    label: 'ジャマイカ',
    value: 'JAM'
  },
  {
    label: 'シリア',
    value: 'SYR'
  },
  {
    label: 'シンガポール',
    value: 'SGP'
  },
  {
    label: 'シント・マールテン',
    value: 'SXM'
  },
  {
    label: 'ジンバブエ',
    value: 'ZWE'
  },
  {
    label: 'スイス',
    value: 'CHE'
  },
  {
    label: 'スウェーデン',
    value: 'SWE'
  },
  {
    label: 'スーダン',
    value: 'SDN'
  },
  {
    label: 'スヴァールバル諸島およびヤンマイエン島',
    value: 'SJM'
  },
  {
    label: 'スペイン',
    value: 'ESP'
  },
  {
    label: 'スリナム',
    value: 'SUR'
  },
  {
    label: 'スリランカ',
    value: 'LKA'
  },
  {
    label: 'スロバキア',
    value: 'SVK'
  },
  {
    label: 'スロベニア',
    value: 'SVN'
  },
  {
    label: 'スワジランド',
    value: 'SWZ'
  },
  {
    label: 'セーシェル',
    value: 'SYC'
  },
  {
    label: '赤道ギニア',
    value: 'GNQ'
  },
  {
    label: 'セネガル',
    value: 'SEN'
  },
  {
    label: 'セルビア',
    value: 'SRB'
  },
  {
    label: 'セントクリストファー・ネイビス',
    value: 'KNA'
  },
  {
    label: 'セントビンセント・グレナディーン',
    value: 'VCT'
  },
  {
    label: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
    value: 'SHN'
  },
  {
    label: 'セントルシア',
    value: 'LCA'
  },
  {
    label: 'ソマリア',
    value: 'SOM'
  },
  {
    label: 'ソロモン諸島',
    value: 'SLB'
  },
  {
    label: 'タークス・カイコス諸島',
    value: 'TCA'
  },
  {
    label: 'タイ',
    value: 'THA'
  },
  {
    label: '大韓民国',
    value: 'KOR'
  },
  {
    label: '台湾',
    value: 'TWN'
  },
  {
    label: 'タジキスタン',
    value: 'TJK'
  },
  {
    label: 'タンザニア',
    value: 'TZA'
  },
  {
    label: 'チェコ',
    value: 'CZE'
  },
  {
    label: 'チャド',
    value: 'TCD'
  },
  {
    label: '中央アフリカ共和国',
    value: 'CAF'
  },
  {
    label: '中国',
    value: 'CHN'
  },
  {
    label: 'チュニジア',
    value: 'TUN'
  },
  {
    label: '朝鮮民主主義人民共和国',
    value: 'PRK'
  },
  {
    label: 'チリ',
    value: 'CHL'
  },
  {
    label: 'ツバル',
    value: 'TUV'
  },
  {
    label: 'デンマーク',
    value: 'DNK'
  },
  {
    label: 'ドイツ',
    value: 'DEU'
  },
  {
    label: 'トーゴ',
    value: 'TGO'
  },
  {
    label: 'トケラウ',
    value: 'TKL'
  },
  {
    label: 'ドミニカ共和国',
    value: 'DOM'
  },
  {
    label: 'ドミニカ国',
    value: 'DMA'
  },
  {
    label: 'トリニダード・トバゴ',
    value: 'TTO'
  },
  {
    label: 'トルクメニスタン',
    value: 'TKM'
  },
  {
    label: 'トルコ',
    value: 'TUR'
  },
  {
    label: 'トンガ',
    value: 'TON'
  },
  {
    label: 'ナイジェリア',
    value: 'NGA'
  },
  {
    label: 'ナウル',
    value: 'NRU'
  },
  {
    label: 'ナミビア',
    value: 'NAM'
  },
  {
    label: '南極',
    value: 'ATA'
  },
  {
    label: 'ニウエ',
    value: 'NIU'
  },
  {
    label: 'ニカラグア',
    value: 'NIC'
  },
  {
    label: 'ニジェール',
    value: 'NER'
  },
  {
    label: '日本',
    value: 'JPN'
  },
  {
    label: '西サハラ',
    value: 'ESH'
  },
  {
    label: 'ニューカレドニア',
    value: 'NCL'
  },
  {
    label: 'ニュージーランド',
    value: 'NZL'
  },
  {
    label: 'ネパール',
    value: 'NPL'
  },
  {
    label: 'ノーフォーク島',
    value: 'NFK'
  },
  {
    label: 'ノルウェー',
    value: 'NOR'
  },
  {
    label: 'ハード島とマクドナルド諸島',
    value: 'HMD'
  },
  {
    label: 'バーレーン',
    value: 'BHR'
  },
  {
    label: 'ハイチ',
    value: 'HTI'
  },
  {
    label: 'パキスタン',
    value: 'PAK'
  },
  {
    label: 'バチカン',
    value: 'VAT'
  },
  {
    label: 'パナマ',
    value: 'PAN'
  },
  {
    label: 'バヌアツ',
    value: 'VUT'
  },
  {
    label: 'バハマ',
    value: 'BHS'
  },
  {
    label: 'パプアニューギニア',
    value: 'PNG'
  },
  {
    label: 'バミューダ',
    value: 'BMU'
  },
  {
    label: 'パラオ',
    value: 'PLW'
  },
  {
    label: 'パラグアイ',
    value: 'PRY'
  },
  {
    label: 'バルバドス',
    value: 'BRB'
  },
  {
    label: 'パレスチナ',
    value: 'PSE'
  },
  {
    label: 'ハンガリー',
    value: 'HUN'
  },
  {
    label: 'バングラデシュ',
    value: 'BGD'
  },
  {
    label: '東ティモール',
    value: 'TLS'
  },
  {
    label: 'ピトケアン',
    value: 'PCN'
  },
  {
    label: 'フィジー',
    value: 'FJI'
  },
  {
    label: 'フィリピン',
    value: 'PHL'
  },
  {
    label: 'フィンランド',
    value: 'FIN'
  },
  {
    label: 'ブータン',
    value: 'BTN'
  },
  {
    label: 'ブーベ島',
    value: 'BVT'
  },
  {
    label: 'プエルトリコ',
    value: 'PRI'
  },
  {
    label: 'フェロー諸島',
    value: 'FRO'
  },
  {
    label: 'フォークランド諸島',
    value: 'FLK'
  },
  {
    label: 'ブラジル',
    value: 'BRA'
  },
  {
    label: 'フランス',
    value: 'FRA'
  },
  {
    label: 'フランス領ギアナ',
    value: 'GUF'
  },
  {
    label: 'フランス領ポリネシア',
    value: 'PYF'
  },
  {
    label: 'フランス領南方・南極地域',
    value: 'ATF'
  },
  {
    label: 'ブルガリア',
    value: 'BGR'
  },
  {
    label: 'ブルキナファソ',
    value: 'BFA'
  },
  {
    label: 'ブルネイ',
    value: 'BRN'
  },
  {
    label: 'ブルンジ',
    value: 'BDI'
  },
  {
    label: 'ベトナム',
    value: 'VNM'
  },
  {
    label: 'ベナン',
    value: 'BEN'
  },
  {
    label: 'ベネズエラ',
    value: 'VEN'
  },
  {
    label: 'ベラルーシ',
    value: 'BLR'
  },
  {
    label: 'ベリーズ',
    value: 'BLZ'
  },
  {
    label: 'ペルー',
    value: 'PER'
  },
  {
    label: 'ベルギー',
    value: 'BEL'
  },
  {
    label: 'ポーランド',
    value: 'POL'
  },
  {
    label: 'ボスニア・ヘルツェゴビナ',
    value: 'BIH'
  },
  {
    label: 'ボツワナ',
    value: 'BWA'
  },
  {
    label: 'BES諸島',
    value: 'BES'
  },
  {
    label: 'ボリビア',
    value: 'BOL'
  },
  {
    label: 'ポルトガル',
    value: 'PRT'
  },
  {
    label: '香港',
    value: 'HKG'
  },
  {
    label: 'ホンジュラス',
    value: 'HND'
  },
  {
    label: 'マーシャル諸島',
    value: 'MHL'
  },
  {
    label: 'マカオ',
    value: 'MAC'
  },
  {
    label: 'マケドニア共和国',
    value: 'MKD'
  },
  {
    label: 'マダガスカル',
    value: 'MDG'
  },
  {
    label: 'マヨット',
    value: 'MYT'
  },
  {
    label: 'マラウイ',
    value: 'MWI'
  },
  {
    label: 'マリ',
    value: 'MLI'
  },
  {
    label: 'マルタ',
    value: 'MLT'
  },
  {
    label: 'マルティニーク',
    value: 'MTQ'
  },
  {
    label: 'マレーシア',
    value: 'MYS'
  },
  {
    label: 'マン島',
    value: 'IMN'
  },
  {
    label: 'ミクロネシア連邦',
    value: 'FSM'
  },
  {
    label: '南アフリカ',
    value: 'ZAF'
  },
  {
    label: '南スーダン',
    value: 'SSD'
  },
  {
    label: 'ミャンマー',
    value: 'MMR'
  },
  {
    label: 'メキシコ',
    value: 'MEX'
  },
  {
    label: 'モーリシャス',
    value: 'MUS'
  },
  {
    label: 'モーリタニア',
    value: 'MRT'
  },
  {
    label: 'モザンビーク',
    value: 'MOZ'
  },
  {
    label: 'モナコ',
    value: 'MCO'
  },
  {
    label: 'モルディブ',
    value: 'MDV'
  },
  {
    label: 'モルドバ',
    value: 'MDA'
  },
  {
    label: 'モロッコ',
    value: 'MAR'
  },
  {
    label: 'モンゴル',
    value: 'MNG'
  },
  {
    label: 'モンテネグロ',
    value: 'MNE'
  },
  {
    label: 'モントセラト',
    value: 'MSR'
  },
  {
    label: 'ヨルダン',
    value: 'JOR'
  },
  {
    label: 'ラオス',
    value: 'LAO'
  },
  {
    label: 'ラトビア',
    value: 'LVA'
  },
  {
    label: 'リトアニア',
    value: 'LTU'
  },
  {
    label: 'リビア',
    value: 'LBY'
  },
  {
    label: 'リヒテンシュタイン',
    value: 'LIE'
  },
  {
    label: 'リベリア',
    value: 'LBR'
  },
  {
    label: 'ルーマニア',
    value: 'ROU'
  },
  {
    label: 'ルクセンブルク',
    value: 'LUX'
  },
  {
    label: 'ルワンダ',
    value: 'RWA'
  },
  {
    label: 'レソト',
    value: 'LSO'
  },
  {
    label: 'レバノン',
    value: 'LBN'
  },
  {
    label: 'レユニオン',
    value: 'REU'
  },
  {
    label: 'ロシア',
    value: 'RUS'
  }
]

export const IMPORT_CHUNK_SIZE = 25
export const IMPORT_STEPS = {
  SELECT_FILE: 0,
  CONFIRM: 1,
  UPLOAD: 2,
  DONE: 3
}
export const IMPORT_TYPES = {
  PROPERTIES: 1,
  CONTRACTS: 2,
  FAQ: 3,
  YASUE_CONTRUCTION_WORK: 4
}
export const IMPORT_TYPES_OPTIONS = [
  {
    label: 'import:import_types_options.property',
    value: 1
  },
  {
    label: 'import:import_types_options.contract',
    value: 2
  },
  {
    label: 'import:import_types_options.faq',
    value: 3
  },
  {
    label: 'import:import_types_options.yasue_construction_work',
    value: 4
  }
]
export const IMPORT_PROPERTIES_HEADERS = [
  'property_manage_id',
  'name',
  'postcode',
  'prefecture',
  'city',
  'address',
  'rooms',
  'bank_account',
  'memo'
]
export const IMPORT_CONTRACTS_HEADERS = [
  'property_id',
  'contract_manage_id',
  'room_number',
  'contract_sign_date',
  'move_in',
  'move_out',
  'contract_duration',
  'contract_duration_unit',
  'contract_start',
  'contract_end',
  'renewal_period_start',
  'renewal_period_end',
  'renewal_fees',
  'renewal_administrative_fees',
  'auto_renewal',
  'landlord_fee',
  'caution_money',
  'deposit',
  'administrative_fees',
  'about_contract',
  'rent_date',
  'rent_contract_account',
  'rent_amount',
  'rent_common_fees',
  'car_model_1',
  'car_plate_number_1',
  'car_model_2',
  'car_plate_number_2',
  'wifi_ssid',
  'wifi_password',
  'different_resident',
  'contractor_user_id',
  'contractor.app_user_manage_id',
  'contractor.last_name',
  'contractor.first_name',
  'contractor.furigana_last_name',
  'contractor.furigana_first_name',
  'contractor.birthday',
  'contractor.sex',
  'contractor.phone',
  'contractor.mail_address',
  'contractor.occupation',
  'contractor.annual_income',
  'contractor.emergency_phone',
  'contractor.nationality',
  'contractor.residence_card',
  'contractor.home_country_emergency_contact',
  'contractor.postcode',
  'contractor.prefecture',
  'contractor.city',
  'contractor.address',
  'contractor.work',
  'contractor.work_postcode',
  'contractor.work_prefecture',
  'contractor.work_city',
  'contractor.work_address',
  'contractor.work_phone',
  'contractor.work_department',
  'resident_user_id',
  'resident.app_user_manage_id',
  'resident.last_name',
  'resident.first_name',
  'resident.furigana_last_name',
  'resident.furigana_first_name',
  'resident.birthday',
  'resident.sex',
  'resident.phone',
  'resident.mail_address',
  'resident.occupation',
  'resident.annual_income',
  'resident.emergency_phone',
  'resident.nationality',
  'resident.residence_card',
  'resident.home_country_emergency_contact',
  'resident.work',
  'contract_guarantor.type',
  'contract_guarantor.last_name',
  'contract_guarantor.first_name',
  'contract_guarantor.furigana_last_name',
  'contract_guarantor.furigana_first_name',
  'contract_guarantor.birthday',
  'contract_guarantor.postcode',
  'contract_guarantor.prefecture',
  'contract_guarantor.city',
  'contract_guarantor.address',
  'contract_guarantor.phone',
  'contract_guarantor.mobile_phone',
  'contract_guarantor.annual_income',
  'contract_guarantor.work',
  'contract_guarantor.work_postcode',
  'contract_guarantor.work_prefecture',
  'contract_guarantor.work_city',
  'contract_guarantor.work_address',
  'contract_guarantor.work_phone',
  'contract_guarantor.work_position',
  'memo'
]
export const IMPORT_CASA_CONTRACTS_HEADERS = [
  ...IMPORT_CONTRACTS_HEADERS,
  'approval_number'
]
export const IMPORT_YASUE_CONTRACTS_HEADERS = [
  'contract_manage_id',
  'contractor.last_name',
  'contractor.furigana_last_name',
  'memo'
]
export const IMPORT_FAQ_HEADERS = [
  'category',
  'question',
  'answer'
]
export const IMPORT_YASUE_CONSTRUCTION_WORK_HEADERS = [
  'contract_manage_id',
  'work_number',
  'title',
  'contract_date',
  'delivery_date',
  'completion_date',
  'postcode',
  'prefecture',
  'city',
  'address',
  'content',
  'memo'
]
export const IMPORT_PROPERTIES_HEADERS_LABEL = [
  'properties:property_manage_id',
  'properties:property_name',
  'properties:postcode',
  'properties:prefecture_short',
  'properties:city_short',
  'address',
  'properties:room_number',
  'properties:bank_account',
  'memo'
]
export const IMPORT_CONTRACTS_HEADERS_LABEL = [
  'properties:property_id',
  'contracts:contract_manage_id',
  'properties:room_number',
  'contracts:contract_sign_date',
  'contracts:move_in_date',
  'contracts:move_out_date',
  'contracts:contract_duration',
  'contracts:contract_duration_unit',
  'contracts:contract_start_date',
  'contracts:contract_end_date',
  'contracts:renewal_period_start',
  'contracts:renewal_period_end',
  'contracts:renewal_fees',
  'contracts:renewal_administrative_fees',
  'contracts:auto_renewal',
  'contracts:landlord_fee',
  'contracts:caution_money',
  'contracts:deposit',
  'contracts:administrative_fees',
  'contracts:about_contract',
  'contracts:rent_date',
  'contracts:rent_contract_account',
  'contracts:rent_amount',
  'contracts:rent_fees.common_fees',
  'contracts:car_model_1',
  'contracts:plate_number_1',
  'contracts:car_model_2',
  'contracts:plate_number_2',
  'contracts:wifi_ssid',
  'contracts:wifi_password',
  'contracts:different_resident',
  'contracts:contractor_user_id',
  'app_users:app_user_manage_id',
  'contracts:contractor.last_name',
  'contracts:contractor.first_name',
  'contracts:contractor.furigana_last_name',
  'contracts:contractor.furigana_first_name',
  'app_users:birthday',
  'app_users:sex',
  'phone',
  'email',
  'app_users:occupation',
  'app_users:annual_income',
  'emergency_phone',
  'app_users:nationality',
  'app_users:residence_card',
  'app_users:home_country_emergency_contact',
  'properties:postcode',
  'properties:prefecture',
  'properties:city',
  'address',
  'app_users:work.name',
  'app_users:work.postcode',
  'properties:prefecture',
  'properties:city',
  'app_users:work.address',
  'app_users:work.phone',
  'app_users:work.position',
  'contracts:resident_user_id',
  'app_users:app_user_manage_id',
  'contracts:resident.last_name',
  'contracts:resident.first_name',
  'contracts:resident.furigana_last_name',
  'contracts:resident.furigana_first_name',
  'app_users:birthday',
  'app_users:sex',
  'phone',
  'email',
  'app_users:occupation',
  'app_users:annual_income',
  'emergency_phone',
  'app_users:nationality',
  'app_users:residence_card',
  'app_users:home_country_emergency_contact',
  'app_users:work.work_school',
  'contracts:guarantor.info',
  'contracts:guarantor.last_name',
  'contracts:guarantor.first_name',
  'contracts:guarantor.furigana_last_name',
  'contracts:guarantor.furigana_first_name',
  'app_users:birthday',
  'properties:postcode',
  'properties:prefecture',
  'properties:city',
  'address',
  'phone',
  'mobile_phone',
  'app_users:annual_income',
  'contracts:guarantor.work',
  'app_users:work.postcode',
  'properties:prefecture',
  'properties:city',
  'app_users:work.address',
  'app_users:work.phone',
  'contracts:guarantor.position',
  'memo'
]
export const IMPORT_CASA_CONTRACTS_HEADERS_LABEL = [
  ...IMPORT_CONTRACTS_HEADERS_LABEL,
  'contracts:casa.approval_number'
]
export const IMPORT_YASUE_CONTRACTS_HEADERS_LABEL = [
  'yasue:contracts.contract_manage_id',
  'yasue:contracts.contractor_name',
  'yasue:contracts.contractor_name_kana',
  'memo'
]
export const IMPORT_FAQ_HEADERS_LABEL = [
  'faq:faq_category',
  'faq:question',
  'faq:answer'
]
export const IMPORT_YASUE_CONSTRUCTION_WORK_HEADERS_LABEL = [
  'yasue:contracts.contract_manage_id',
  'yasue:construction.work_number',
  'yasue:construction.title',
  'yasue:construction.contract_date',
  'yasue:construction.delivery_date',
  'yasue:construction.completion_date',
  'yasue:construction.postcode',
  'yasue:construction.prefecture',
  'yasue:construction.city',
  'yasue:construction.address',
  'yasue:construction.content',
  'yasue:construction.memo'
]

export const PANEL_LEVEL = {
  admin: 1,
  property: 2,
  room: 3,
  contract: 4,
  adminTasks: 5
}

export const SERVICES_PLAN = {
  FREE: 1,
  DEFERRED: 2, // 後払い（後から請求）
  MANAGED: 3,
  PREPAID: 4, // 仕様未定
  SUBSCRIPTION: 5 // 仕様未定
}

export const SERVICES_TYPES = {
  INTERNAL: 1,
  EXTERNAL: 2
}
export const SERVICES_LEVEL = {
  MANAGE: 1,
  ADMIN: 2,
  PROPERTY: 3,
  ROOM: 4,
  CONTRACT: 5
}
export const SERVICES_DISPLAY = {
  ALL_DISPLAY: 1,
  NO_DISPLAY: 2,
  CUSTOM_DISPLAY: 3
}
export const SERVICES_DISPLAY_OPTIONS = [
  {
    label: 'services:services_display_options.display_all',
    value: 1
  },
  {
    label: 'services:services_display_options.hide_all',
    value: 2
  },
  {
    label: 'services:services_display_options.nothing',
    value: 3
  }
]
export const LAST_LEVEL_SERVICES_DISPLAY_OPTIONS = [
  {
    label: 'services:last_level_services_display_options.show',
    value: 1
  },
  {
    label: 'services:last_level_services_display_options.hide',
    value: 2
  }
]

export const SERVICES_ID = {
  SEIKATSU: REACT_APP_SEIKATSU_SERVICE_ID,
  CLOUDSIGN: REACT_APP_CLOUDSIGN_SERVICE_ID
}

export const SERVICES_WITH_SETTINGS = [
  REACT_APP_CLOUDSIGN_SERVICE_ID
]

export const CHAT_MESSAGE_TYPES = {
  IMAGE: 0,
  TEXT: 1,
  FILE: 2,
  VIDEO: 3
}

// TODO: group collections name in a single object
export const CHAT_ROOMS_COLLECTION_NAME = 'rooms_v2'
export const CHECKLIST_COLLECTION_NAME = 'checklist'
export const CHAT_MESSAGES_COLLECTION_NAME = 'messages'
export const CHAT_CONFIG_COLLECTION_NAME = 'config'
export const APP_COLLECTION_NAME = 'app'
export const CHAT_USERS_COLLECTION_NAME = 'users'
export const CHAT_STORAGE_REF = {
  0: '/images/',
  2: '/files/',
  3: '/videos/'
}
export const CONTRACT_STORAGE_REF = '/documents/'

export const PDF_CMAP_URL = `//unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`

export const CHOUEI = {
  CENTER_NAME_OPTIONS: [
    {
      label: 'ベルヴィ洛北',
      value: 'ベルヴィ洛北'
    },
    {
      label: 'ベルヴィ松ヶ崎',
      value: 'ベルヴィ松ヶ崎'
    },
    {
      label: 'ベルヴィ東山',
      value: 'ベルヴィ東山'
    },
    {
      label: 'ベルヴィ山科',
      value: 'ベルヴィ山科'
    },
    {
      label: 'ベルヴィ太秦',
      value: 'ベルヴィ太秦'
    },
    {
      label: 'ベルヴィかどの',
      value: 'ベルヴィかどの'
    },
    {
      label: 'ベルヴィ丹波口駅前',
      value: 'ベルヴィ丹波口駅前'
    },
    {
      label: 'ベルヴィ九条',
      value: 'ベルヴィ九条'
    },
    {
      label: 'ベルヴィ千代原口',
      value: 'ベルヴィ千代原口'
    },
    {
      label: 'ベルヴィ桂南',
      value: 'ベルヴィ桂南'
    },
    {
      label: 'ベルヴィ竹田',
      value: 'ベルヴィ竹田'
    },
    {
      label: 'ベルヴィ深草',
      value: 'ベルヴィ深草'
    },
    {
      label: 'ベルヴィ桃山',
      value: 'ベルヴィ桃山'
    },
    {
      label: 'ベルヴィ醍醐',
      value: 'ベルヴィ醍醐'
    },
    {
      label: 'ベルヴィ向島',
      value: 'ベルヴィ向島'
    },
    {
      label: 'ベルヴィ御池',
      value: 'ベルヴィ御池'
    },
    {
      label: 'ベルヴィ宇治',
      value: 'ベルヴィ宇治'
    },
    {
      label: 'ベルヴィ瀬田',
      value: 'ベルヴィ瀬田'
    },
    {
      label: 'ベルヴィ大阪',
      value: 'ベルヴィ大阪'
    },
    {
      label: 'ベルヴィ名古屋',
      value: 'ベルヴィ名古屋'
    },
    {
      label: 'わからない',
      value: 'わからない'
    }
  ]
}

export const INUI = {
  CONTACT_TYPES: [
    {
      label: '駐輪場',
      value: 1
    },
    {
      label: '駐車場',
      value: 2
    },
    {
      label: 'バイク置き場',
      value: 3
    },
    {
      label: 'トランクルーム',
      value: 4
    },
    {
      label: 'プレミアム駐輪場',
      value: 5
    }
  ]
}

export const INQUIRY_PREFIX = {
  PROPERTY: 'ppty',
  ROOM: 'rm',
  USER: 'usr',
  CONTRACT: 'cnt',
  NOTICE: 'ntc',
  MEMBER: 'mbr',
  PROPERTIES_GROUP: 'grp',
  GUEST: 'gst',
  COUPON: 'cpn',
  SURVEY: 'srv',
  SPACE: 'spc',
  YASUE: {
    CONSTRUCTION_WORK: 'yse-cw',
    SURVEY: 'yse-sv'
  }
}

export const THEME = {
  red: {
    white: '#ffffff',
    inputBorder: '#d9d9d9',
    cardBorder: '#ebedf0',
    accent: '#d62547',
    info: '#1890ff',
    success: '#52c41a',
    danger: '#d33f3f',
    warning: '#ffcc00',
    text: '#666666',
    lightText: '#999999',
    pageTitle: '#0d1a26'
  },
  blue: {
    white: '#ffffff',
    inputBorder: '#d9d9d9',
    cardBorder: '#ebedf0',
    accent: '#11b7da',
    info: '#1890ff',
    success: '#52c41a',
    danger: '#d33f3f',
    warning: '#ffcc00',
    text: '#666666',
    lightText: '#999999',
    pageTitle: '#0d1a26'
  },
  green: {
    white: '#ffffff',
    inputBorder: '#d9d9d9',
    cardBorder: '#ebedf0',
    accent: '#68b787',
    info: '#1890ff',
    success: '#52c41a',
    danger: '#d33f3f',
    warning: '#ffcc00',
    text: '#666666',
    lightText: '#999999',
    pageTitle: '#0d1a26'
  },
  purple: {
    white: '#ffffff',
    inputBorder: '#d9d9d9',
    cardBorder: '#ebedf0',
    accent: '#6C63FF',
    info: '#1890ff',
    success: '#52c41a',
    danger: '#d33f3f',
    warning: '#ffcc00',
    text: '#666666',
    lightText: '#999999',
    pageTitle: '#0d1a26'
  },
  orange: {
    white: '#ffffff',
    inputBorder: '#d9d9d9',
    cardBorder: '#ebedf0',
    accent: '#f4a800',
    info: '#1890ff',
    success: '#52c41a',
    danger: '#d33f3f',
    warning: '#ffcc00',
    text: '#666666',
    lightText: '#999999',
    pageTitle: '#0d1a26'
  },
  yellow: {
    white: '#ffffff',
    inputBorder: '#d9d9d9',
    cardBorder: '#ebedf0',
    accent: '#f6c760',
    info: '#1890ff',
    success: '#52c41a',
    danger: '#d33f3f',
    warning: '#ffcc00',
    text: '#666666',
    lightText: '#999999',
    pageTitle: '#0d1a26'
  },
  turquoise: {
    white: '#ffffff',
    inputBorder: '#d9d9d9',
    cardBorder: '#ebedf0',
    accent: '#73CCB5',
    info: '#1890ff',
    success: '#52c41a',
    danger: '#d33f3f',
    warning: '#ffcc00',
    text: '#666666',
    lightText: '#999999',
    pageTitle: '#0d1a26'
  },
  gold: {
    white: '#ffffff',
    inputBorder: '#d9d9d9',
    cardBorder: '#ebedf0',
    accent: '#C99E10',
    info: '#1890ff',
    success: '#52c41a',
    danger: '#d33f3f',
    warning: '#ffcc00',
    text: '#666666',
    lightText: '#999999',
    pageTitle: '#0d1a26'
  },
  gray: {
    white: '#ffffff',
    inputBorder: '#d9d9d9',
    cardBorder: '#ebedf0',
    accent: '#BCBABE',
    info: '#1890ff',
    success: '#52c41a',
    danger: '#d33f3f',
    warning: '#ffcc00',
    text: '#666666',
    lightText: '#999999',
    pageTitle: '#0d1a26'
  }
}

export const ANABUKI = {
  SUPPORT_STATUS: {
    REJECTED: 4,
    COMPLETED: 3,
    IN_PROGRESS: 2,
    WAITING: 1
  }
}
export const APP_USERS_LOGIN_PDF_TYPE = {
  ALL: 1,
  PROPERTIES: 2,
  AREA: 3,
  RESIDENTS: 4
}
export const APP_USERS_LOGIN_PDF_TYPE_OPTIONS = [
  {
    label: 'app_users:download_pdf_files.all_resident',
    value: 1
  },
  {
    label: 'notices:notice_recipient_type_options.property',
    value: 2
  },
  {
    label: 'common:area',
    value: 3
  },
  {
    label: 'common:user_type.resident',
    value: 4
  }
]

export const MEETING_ROLES = {
  CHAIRMAN: 1,
  INSPECTOR: 2
}

export const SURVEY_STATUS = {
  UNPUBLISHED: 1,
  PUBLISHED: 2,
  EXPIRED: 3
}
export const SURVEY_STATUS_OPTIONS = [
  {
    label: 'surveys:statuses.unpublished',
    value: 1
  },
  {
    label: 'surveys:statuses.published',
    value: 2
  },
  {
    label: 'surveys:statuses.expired',
    value: 3
  }
]
export const SURVEY_RECIPIENT_TYPE = {
  ALL: 1,
  PROPERTY: 2,
  USER: 3
}
export const SURVEY_RECIPIENT_TYPE_OPTIONS = [
  {
    label: 'notices:notice_recipient_type_options.all',
    value: 1
  },
  {
    label: 'notices:notice_recipient_type_options.property',
    value: 2
  },
  {
    label: 'notices:notice_recipient_type_options.user',
    value: 3
  }
]
export const SURVEY_QUESTION_TYPE = {
  TEXT: 1,
  TEXTAREA: 2,
  DATE: 3,
  RADIO: 4,
  CHECKBOX: 5
}
export const SURVEY_QUESTION_TYPE_OPTIONS = [
  {
    label: 'surveys:question_types.text',
    value: 1
  },
  {
    label: 'surveys:question_types.textarea',
    value: 2
  },
  {
    label: 'surveys:question_types.date',
    value: 3
  },
  {
    label: 'surveys:question_types.radio',
    value: 4
  },
  {
    label: 'surveys:question_types.checkbox',
    value: 5
  }
]
export const SPACE_TYPE = {
  PARKING: 1,
  COMMUNITY_ROOM: 2,
  ELECTRIC_VEHICLE_PARKING: 3,
  CAR_WASH: 4,
  GUEST_ROOM: 5,
  SKY_DECK: 6
}
export const SPACE_TYPE_OPTIONS = [
  {
    label: 'spaces:types.parking',
    value: 1
  },
  {
    label: 'spaces:types.community_room',
    value: 2
  },
  {
    label: 'spaces:types.electric_vehicle_parking',
    value: 3
  },
  {
    label: 'spaces:types.car_wash',
    value: 4
  },
  {
    label: 'spaces:types.guest_room',
    value: 5
  },
  {
    label: 'spaces:types.sky_deck',
    value: 6
  }
]
export const SPACE_RESERVATION_STATUS = {
  UNCONFIRMED: 1,
  CONFIRMED: 2,
  CANCELED: 3,
  REJECTED: 4
}
export const SPACE_RESERVATION_STATUS_OPTIONS = [
  {
    label: 'sapces:reservation_statuses.unconfirmed',
    value: 1
  },
  {
    label: 'sapces:reservation_statuses.confirmed',
    value: 2
  },
  {
    label: 'sapces:reservation_statuses.canceled',
    value: 4
  },
  {
    label: 'sapces:reservation_statuses.rejected',
    value: 4
  }
]
export const YASUE_NOTICE_CONTRACTS_IMPORT_HEADERS = [
  'contractor_id',
  'contractor_name'
]

export const AREA_TYPE = {
  yasue_construction_work: 'yasue_construction_work'
}

export const YASUE_MAINTENANCE_REQUESTS = {
  repair: 1,
  advice: 2,
  catalog: 3
}
