import {
  api,
  MULTIPART_HEADERS,
  getFileFromURL,
  DEFAULT_QUERY_LIMIT
} from '@gk-devteam/apmc-core-web'

export const fetchMembers = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }
  return api.get('admin_users', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token
  })
}

export const fetchMember = (id, cancelSource) => {
  return api.get(`admin_users/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const postMember = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }

    if (data.all_properties) {
      data.groups = null
      data.properties = null
    }

    delete data.file
    delete data.file_url
    delete data.file_filename
    delete data.groups_list
    delete data.properties_list

    formData.append('data', JSON.stringify(data))
    return api.post('admin_users', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const deleteMember = (id, cancelSource) => {
  return api.delete(`admin_users/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const sendMemberEmail = (id, cancelSource) => {
  return api.post(`admin_users/${id}/send`, null, {
    cancelToken: cancelSource.token
  })
}
