import { createSelector } from 'reselect'

const selectRefuse = state => state.refuse

export const selectRefuseLoading = createSelector(
  selectRefuse,
  refuse => refuse.loading
)
export const selectRefusePosting = createSelector(
  selectRefuse,
  refuse => refuse.posting
)
export const selectRefuseIssue = createSelector(
  selectRefuse,
  refuse => refuse.hasIssue
)
export const selectCurrentCalendar = createSelector(
  selectRefuse,
  refuse => refuse.calendar
)
export const selectCalendars = createSelector(
  selectRefuse,
  refuse => refuse.data
)
export const selectRefuseHiddenBy = createSelector(
  selectRefuse,
  refuse => refuse.hiddenBy
)
export const selectRefuseHiddenById = createSelector(
  selectRefuse,
  refuse => refuse.hiddenById
)
