import { createSelector } from 'reselect'

const selectContacts = state => state.contacts

export const selectContactsLoading = createSelector(
  selectContacts,
  contacts => contacts.loading
)
export const selectContactsPosting = createSelector(
  selectContacts,
  contacts => contacts.posting
)

export const selectAdminContactsData = createSelector(
  selectContacts,
  contacts => contacts.data && contacts.data.admin_results
)
export const selectPropertyContactsData = createSelector(
  selectContacts,
  contacts => contacts.data && contacts.data.property_results
)
export const selectAdminContactsAllDisplayed = createSelector(
  selectContacts,
  contacts => {
    const items = []
    if (contacts.data && contacts.data.admin_results) {
      for (const item of contacts.data.admin_results) {
        items.push(item.display)
      }
    }
    if (items.every(item => item === 0 || item === false)) return 0 // all hidden
    if (items.every(item => item === 2 || item === true)) return 2 // all displayed
    return 1
  }
)
export const selectPropertyContactsAllDisplayed = createSelector(
  selectContacts,
  contacts => {
    const items = []
    if (contacts.data && contacts.data.property_results) {
      for (const item of contacts.data.property_results) {
        items.push(item.display)
      }
    }
    if (items.every(item => item === 0 || item === false)) return 0 // all hidden
    if (items.every(item => item === 2 || item === true)) return 2 // all displayed
    return 1
  }
)

export const selectContactsHiddenBy = createSelector(
  selectContacts,
  contacts => contacts?.data?.hiddenBy
)
export const selectContactsHiddenById = createSelector(
  selectContacts,
  contacts => contacts?.data?.hiddenById
)
