import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import PropertiesRecipients from './Recipients/PropertiesRecipients'
import UsersRecipients from './Recipients/UsersRecipients'

const CouponRecipientsSearch = () => {
  const recipientType = useSelector(state => state.form.recipient_type)

  switch (recipientType) {
    case 2:
      return <PropertiesRecipients/>
    case 3:
      return <UsersRecipients/>
    default:
      return null
  }
}

export default memo(CouponRecipientsSearch)
