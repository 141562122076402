import React, { memo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  RoleControlled,
  Row,
  TextInput
} from '@gk-devteam/apmc-core-web'

import { selectAppUsersLoading } from '../../../../../selectors'

import { SearchButtonStyle } from '../../../../contract/ContractForm/ContractForm.style'

const UsersForm = ({ fetchData }) => {
  const loading = useSelector(selectAppUsersLoading)
  const [username, setUsername] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [id, setID] = useState('')
  const [manageID, setManageID] = useState('')

  const _handleUsernameChange = useCallback(
    (e) => {
      setUsername(e.target.value)
    },
    [setUsername]
  )
  const _handlePhoneChange = useCallback(
    (e) => {
      setPhone(e.target.value)
    },
    [setPhone]
  )
  const _handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value)
    },
    [setEmail]
  )
  const _handleIDChange = useCallback(
    (e) => {
      setID(e.target.value)
    },
    [setID]
  )
  const _handleManageIDChange = useCallback(
    (e) => {
      setManageID(e.target.value)
    },
    [setManageID]
  )

  const _handleSearchClick = useCallback(
    () => {
      fetchData({
        username,
        phone,
        email,
        id,
        manageID
      })
    },
    [
      fetchData,
      username,
      phone,
      email,
      id,
      manageID
    ]
  )

  const _handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        // _handleSearchClick()
      }
    },
    // [_handleSearchClick]
    []
  )

  return (
    <div>
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="username"
          label='app_users:app_user_name'
          placeholder="placeholders.partial_user_name"
          onChange={_handleUsernameChange}
          value={username}
          onKeyPress={_handleKeyPress}
        />
        <TextInput
          flex={1}
          type="text"
          name="phone"
          label='phone'
          placeholder="placeholders.phone"
          onChange={_handlePhoneChange}
          value={phone}
          onKeyPress={_handleKeyPress}
        />
        <RoleControlled authorized="!custom.yasue">
          <TextInput
            flex={1}
            type="text"
            name="email"
            label='email'
            placeholder="placeholders.email"
            onChange={_handleEmailChange}
            value={email}
            onKeyPress={_handleKeyPress}
          />
        </RoleControlled>
      </Row>
      <Row mb="S">
        <RoleControlled authorized="!custom.yasue">
          <TextInput
            flex={1}
            type="text"
            name="id"
            label='ID'
            placeholder=""
            onChange={_handleIDChange}
            value={id}
            onKeyPress={_handleKeyPress}
          />
          <TextInput
            flex={1}
            type="text"
            name="manageID"
            label='properties:property_manage_id'
            placeholder="placeholders.partial_manage_id"
            onChange={_handleManageIDChange}
            value={manageID}
            onKeyPress={_handleKeyPress}
          />
        </RoleControlled>
        <SearchButtonStyle
          label="search"
          type="button"
          handleClick={_handleSearchClick}
          loading={loading}
        />
      </Row>
    </div>
  )
}

export default memo(UsersForm)

UsersForm.propTypes = {
  fetchData: PropTypes.func.isRequired
}
