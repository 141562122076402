import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_PROPERTY_DOCUMENTS,
  FETCH_PROPERTY_DOCUMENTS_SUCCESS,
  FETCH_PROPERTY_DOCUMENTS_FAIL,
  RESET_PROPERTY_DOCUMENTS,
  POST_PROPERTY_DOCUMENT,
  POST_PROPERTY_DOCUMENT_SUCCESS,
  POST_PROPERTY_DOCUMENT_FAIL,
  DELETE_PROPERTY_DOCUMENT,
  // DELETE_PROPERTY_DOCUMENT_SUCCESS,
  // DELETE_PROPERTY_DOCUMENT_FAIL,
  TOGGLE_ADD_PROPERTY_DOCUMENT_MODAL,
  TOGGLE_VIEW_PROPERTY_DOCUMENT_MODAL,
  SET_ACTIVE_PROPERTY_DOCUMENT
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  error: null,
  max_pages: null,
  results_count: null,
  results: [],
  addDocumentModal: false,
  viewDocumentModal: false,
  activeDocumentID: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_PROPERTY_DOCUMENTS]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_PROPERTY_DOCUMENTS_SUCCESS]: (state, action) => {
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_PROPERTY_DOCUMENTS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_PROPERTY_DOCUMENT]: (state, action) => {
    state.posting = true
  },
  [POST_PROPERTY_DOCUMENT_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_PROPERTY_DOCUMENT_FAIL]: (state, action) => {
    state.posting = false
  },
  [RESET_PROPERTY_DOCUMENTS]: () => INITIAL_STATE,
  [DELETE_PROPERTY_DOCUMENT]: (state, action) => {
    state.loading = true
  },
  [TOGGLE_ADD_PROPERTY_DOCUMENT_MODAL]: (state, action) => {
    state.addDocumentModal = !state.addDocumentModal
  },
  [TOGGLE_VIEW_PROPERTY_DOCUMENT_MODAL]: (state, action) => {
    if (state.viewDocumentModal) {
      state.activeDocumentID = null
    }
    state.viewDocumentModal = !state.viewDocumentModal
  },
  [SET_ACTIVE_PROPERTY_DOCUMENT]: (state, action) => {
    const { id } = action.payload
    state.activeDocumentID = id
  }
})
