export const FETCH_CONSTRUCTION_WORKS = 'fetch_construction_works'
export const FETCH_CONSTRUCTION_WORKS_SUCCESS = 'fetch_construction_works_success'
export const FETCH_CONSTRUCTION_WORKS_FAIL = 'fetch_construction_works_fail'
export const RESET_CONSTRUCTION_WORKS = 'reset_construction_works'

export const FETCH_CONSTRUCTION_WORK = 'fetch_construction_work'
export const FETCH_CONSTRUCTION_WORK_SUCCESS = 'fetch_construction_work_success'
export const FETCH_CONSTRUCTION_WORK_FAIL = 'fetch_construction_work_fail'
export const RESET_CONSTRUCTION_WORK = 'reset_construction_work'

export const POST_CONSTRUCTION_WORK = 'post_construction_work'
export const POST_CONSTRUCTION_WORK_SUCCESS = 'post_construction_work_success'
export const POST_CONSTRUCTION_WORK_FAIL = 'post_construction_work_fail'

export const DELETE_CONSTRUCTION_WORK = 'delete_construction_work'
export const DELETE_CONSTRUCTION_WORK_SUCCESS = 'delete_construction_work_success'
export const DELETE_CONSTRUCTION_WORK_FAIL = 'delete_construction_worky_fail'

export const TOGGLE_ADD_CONSTRUCTION_WORK_FILE_MODAL = 'toggle_add_construction_work_file_modal'
export const TOGGLE_VIEW_CONSTRUCTION_WORK_FILE_MODAL = 'toggle_view_construction_work_file_modal'
export const SET_ACTIVE_CONSTRUCTION_WORK_FILE = 'set_active_construction_work_file'

export const POST_CONSTRUCTION_WORK_FILE = 'post_construction_work_file'
export const POST_CONSTRUCTION_WORK_FILE_SUCCESS = 'post_construction_work_file_success'
export const POST_CONSTRUCTION_WORK_FILE_FAIL = 'post_construction_work_file_fail'

export const DELETE_CONSTRUCTION_WORK_FILE = 'delete_construction_work_file'
export const DELETE_CONSTRUCTION_WORK_FILE_SUCCESS = 'delete_construction_work_file_success'
export const DELETE_CONSTRUCTION_WORK_FILE_FAIL = 'delete_construction_work_file_fail'
