export const FETCH_MANUALS = 'fetch_manuals'
export const FETCH_MANUALS_SUCCESS = 'fetch_manuals_success'
export const FETCH_MANUALS_FAIL = 'fetch_manuals_fail'

export const POST_MANUAL = 'post_manual'
export const POST_MANUAL_SUCCESS = 'post_manual_success'
export const POST_MANUAL_FAIL = 'post_manual_fail'

export const DELETE_MANUAL = 'delete_manual'
export const DELETE_MANUAL_SUCCESS = 'delete_manual_success'
export const DELETE_MANUAL_FAIL = 'delete_manual_fail'

export const TOGGLE_MANUAL = 'toggle_manual'
export const TOGGLE_MANUAL_SUCCESS = 'toggle_manual_success'
export const TOGGLE_MANUAL_FAIL = 'toggle_manual_fail'

export const BULK_TOGGLE_MANUALS = 'bulk_toggle_manuals'
export const BULK_TOGGLE_MANUALS_SUCCESS = 'bulk_toggle_manuals_success'
export const BULK_TOGGLE_MANUALS_FAIL = 'bulk_toggle_manuals_fail'

export const RESET_MANUALS = 'reset_manuals'
