import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchSpace,
  postSpace,
  deleteSpace,
  fetchSpaceReservations,
  updateSpaceReservationStatus
} from '../../services'
import {
  FETCH_SPACE,
  FETCH_SPACE_SUCCESS,
  FETCH_SPACE_FAIL,
  FETCH_SPACE_RESERVATIONS,
  FETCH_SPACE_RESERVATIONS_SUCCESS,
  FETCH_SPACE_RESERVATIONS_FAIL,
  POST_SPACE,
  POST_SPACE_SUCCESS,
  POST_SPACE_FAIL,
  DELETE_SPACE,
  DELETE_SPACE_SUCCESS,
  DELETE_SPACE_FAIL,
  UPDATE_SPACE_RESERVATION_STATUS,
  UPDATE_SPACE_RESERVATION_STATUS_SUCCESS,
  UPDATE_SPACE_RESERVATION_STATUS_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchSpaceSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchSpace, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_SPACE_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_SPACE_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_SPACE_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * fetchSpaceReservationsSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchSpaceReservations, query, cancelSource)
    if (res?.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_SPACE_RESERVATIONS_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_SPACE_RESERVATIONS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_SPACE_RESERVATIONS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postSpaceSaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postSpace, data, cancelSource)
    if (res?.status === 204) {
      if (successRedirect) yield call(successRedirect, res.data.id)
      yield put({ type: POST_SPACE_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_SPACE_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_SPACE_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * updateSpaceReservationStatusSaga ({ id, data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(updateSpaceReservationStatus, id, data, cancelSource)
    if (res?.status === 204) {
      if (successRedirect) yield call(successRedirect)
      yield put({ type: UPDATE_SPACE_RESERVATION_STATUS_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: UPDATE_SPACE_RESERVATION_STATUS_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: UPDATE_SPACE_RESERVATION_STATUS_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteSpaceSaga ({ id, onSuccess, onFail }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteSpace, id, cancelSource)
    if (res) {
      yield put({ type: DELETE_SPACE_SUCCESS })
      if (onSuccess) yield call(onSuccess)
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_SPACE_FAIL, payload: error.data || error.message })
    if (onFail) yield call(onFail)
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchSpace () {
  yield takeLatest(FETCH_SPACE, fetchSpaceSaga)
  yield takeLatest(FETCH_SPACE_RESERVATIONS, fetchSpaceReservationsSaga)
  yield takeLatest(POST_SPACE, postSpaceSaga)
  yield takeLatest(DELETE_SPACE, deleteSpaceSaga)
  yield takeLatest(UPDATE_SPACE_RESERVATION_STATUS, updateSpaceReservationStatusSaga)
}
