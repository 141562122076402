import {
  styled
} from '@gk-devteam/apmc-core-web'

export const ProgressContainerStyle = styled.div`
  width: 75%;
  margin: auto;
  padding: 75px 0;
  box-sizing: border-box;
`
