import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import {
  Card,
  Checkbox,
  Confirm,
  DangerButton,
  DatePicker,
  Form,
  Help,
  InfoButton,
  RepeaterAddButton,
  RepeaterFields,
  RoleControlled,
  Row,
  Select,
  Tag,
  Text,
  TextArea,
  TextInput,
  Title,
  ToastTitle,
  useTranslation,
  useIsMountedRef,
  selectFormRequiredFields,
  selectPages
} from '@gk-devteam/apmc-core-web'
import { CancelToken } from 'axios'
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import { contractRenewalValidation } from '../../../validation'
import { selectContractData, selectContractDifferentUser, selectContractLoading, selectContractPosting, selectContractStatus } from '../../../selectors'
import { CONTRACT_DURATION_OPTIONS, CONTRACT_STATUS, TEMPLATE_TYPES } from '../../../constants'
import { POST_ACCEPT_RENEW_CONTRACT, POST_CANCEL_RENEW_CONTRACT, POST_RENEW_CONTRACT } from '../../../types'
import { fetchRelations } from '../../../services'

import ContractFees from '../ContractForm/subForms/FeesInfo/ContractFees'
import RenewalNoticeRow from '../ContractForm/subForms/NoticeInfo/RenewalNoticeRow'
import RoommatesCard from '../ContractForm/subForms/RoommatesInfo/RoommatesCard'
import GuarantorCard from '../ContractForm/subForms/GuarantorInfo/GuarantorCard'
import SelectContractTemplate from '../SelectContractTemplate'
import ContractPreview from './ContractPreview'
import ContractorAddressButton from './ContractorAddressButton'
import ContractorWorkAddressButton from './ContractorWorkAddressButton'
import RenewalTotalFees from '../ContractForm/subForms/RenewalFeesInfo/RenewalTotalFees'

import { ButtonRowStyle, ContractSignDateStyle } from './ContractRenewForm.style'

const authorizedStatuses = [
  CONTRACT_STATUS.RESIDING.value,
  CONTRACT_STATUS.IN_RENEWING_PERIOD.value,
  CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value,
  CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value
]

function ContractRenewForm ({ pageTitle, data, contractID }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isMountedRef = useIsMountedRef()
  const contract = useSelector(selectContractData)
  const status = useSelector(selectContractStatus)
  const requiredFields = useSelector(selectFormRequiredFields)
  const differentResident = useSelector(selectContractDifferentUser)
  const pages = useSelector(selectPages)
  const [relationOptions, setRelationOptions] = useState()
  const [loadingRelations, setLoadingRelations] = useState(true)

  const fetchRelationOptions = useCallback(
    async () => {
      try {
        setLoadingRelations(true)
        const cancelSource = CancelToken.source()
        const res = await fetchRelations(cancelSource)
        if (!isMountedRef.current) return
        if (res?.data.results) {
          setLoadingRelations(false)
          setRelationOptions(res.data.results)
        } else {
          message.error(t('fetch_error'))
          setLoadingRelations(false)
        }
      } catch (error) {
        message.error(t('fetch_error'))
        setLoadingRelations(false)
      }
    },
    [t, isMountedRef]
  )

  useEffect(() => {
    fetchRelationOptions()
  }, [fetchRelationOptions])

  const handleFormSubmit = (formData) => {
    // const successRedirect = (id) => {
    //   navigate('/contracts')
    // }
    dispatch({ type: POST_RENEW_CONTRACT, id: contractID, data: formData })
  }

  const _handleCancel = () => {
    dispatch({ type: POST_CANCEL_RENEW_CONTRACT, id: contractID })
  }
  const _handleAcceptRenewal = () => {
    dispatch({ type: POST_ACCEPT_RENEW_CONTRACT, id: contractID })
  }

  const _renderStatusTag = () => {
    if (status != null) {
      let label, color
      for (const key in CONTRACT_STATUS) {
        const element = CONTRACT_STATUS[key]
        if (status === element.value) {
          label = `contracts:status.${key}`
          color = element.color
        }
      }
      return (
        <Tag label={label} color={color}/>
      )
    }
    return null
  }

  const _renderContractSignDate = () => {
    if (data?.contract_sign_date) return null
    return (
      <ContractSignDateStyle mb="L" align="baseline">
        <ExclamationCircleOutlined />
        <div>
          <ToastTitle label="contracts:register_sign_date" />
          <Text label="contracts:unregistered_sign_date" />
          <Row mt="S">
            <DatePicker
              flex={1}
              size="M"
              name="contract_sign_date"
              placeholder=""
              noLabel
              // help="contracts:register_sign_date_help"
            />
          </Row>
        </div>
      </ContractSignDateStyle>
    )
  }

  // console.log('requiredFields', requiredFields)

  return (
    <Form
      id="updateContractRenewalSettings"
      handleSubmit={handleFormSubmit}
      validationSchema={contractRenewalValidation}
      customValidation={requiredFields}
      loadingSelector={selectContractLoading}
      postingSelector={selectContractPosting}
      initialData={data}
      authorized={
        !authorizedStatuses.includes(status)
          ? false
          : 'custom.cloudsign'
      }
    >
      <Row justify="between" align="baseline" mb="L">
        <Row align="baseline" wrap={'wrap'}>
          <Title label={pageTitle} />
          <Row>
            {_renderStatusTag()}
          </Row>
        </Row>
        <RoleControlled authorized="custom.cloudsign">
          <ButtonRowStyle mt="S">
            {
              status === CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value ||
              status === CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value ||
              status === CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value
                ? (
                  <Confirm
                    title="contracts:status_panel.renewal.cancel_question"
                    placement="topRight"
                    onConfirm={_handleCancel}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  >
                    <DangerButton
                      type="button"
                      label="contracts:status_panel.ASKING_FOR_RENEWAL.cancel"
                      withMargin
                    />
                  </Confirm>
                )
                : null
            }
            <ContractPreview />
            {
              status === CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value
                ? (
                  <InfoButton
                    label="contracts:status_panel.WAITING_RENEWAL_CONFIRMATION.accept_renewal"
                    onClick={_handleAcceptRenewal}
                    reverse
                    withMargin
                  />
                )
                : null
            }
          </ButtonRowStyle>
        </RoleControlled>
      </Row>
      <SelectContractTemplate type={TEMPLATE_TYPES.RENEW} />
      { _renderContractSignDate() }
      <Card mb="L" title="contracts:new_contract_info">
        <Row wrap="wrap" align="baseline" justify="start">
          <TextInput
            flex={1}
            size="XS"
            mr="XS"
            type="number"
            name="contract_duration"
            label='contracts:new_contract_duration'
            placeholder=""
            required={requiredFields?.contract_duration}
          />
          <Select
            size="fluid"
            maxW={80}
            name="contract_duration_unit"
            placeholder=""
            options={CONTRACT_DURATION_OPTIONS}
            defaultValue={1}
            noClear
            required={requiredFields?.contract_duration_unit}
          />
          <DatePicker
            flex={1}
            size="M"
            name="contract_start"
            label='contracts:new_contract_start_date'
            placeholder=""
            required={requiredFields?.contract_start}
          />
          <DatePicker
            flex={1}
            size="M"
            name="contract_end"
            label='contracts:new_contract_end_date'
            placeholder=""
            required={requiredFields?.contract_end}
          />
        </Row>
        <Row wrap="wrap" align="baseline" justify="start">
          <DatePicker
            flex={1}
            size="M"
            name="renewal_period_start"
            label='contracts:renewal_period_start'
            placeholder=""
            required={requiredFields?.renewal_period_start}
          />
          <DatePicker
            flex={1}
            size="M"
            name="renewal_period_end"
            label='contracts:renewal_period_end'
            placeholder=""
            required={requiredFields?.renewal_period_end}
          />
          <Checkbox
            name="auto_renewal"
            inputLabel='contracts:auto_renewal'
            required={requiredFields?.auto_renewal}
          />
        </Row>
      </Card>
      <Card mb="L" title="contracts:landlord_info">
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            size="M"
            type="text"
            name="landlord_name"
            label='contracts:landlord_name'
            required={requiredFields?.landlord_name}
          />
        </Row>
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            type="text"
            name="landlord_address"
            label='contracts:landlord_address'
            help=""
            required={requiredFields?.landlord_address}
          />
        </Row>
      </Card>
      <Card mb="L" title="contracts:car_info">
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            size="M"
            type="text"
            name="car_model_1"
            label='contracts:car_model_1'
            placeholder=""
            help="contracts:help.car_model"
            required={requiredFields?.car_model_1}
          />
          <TextInput
            flex={1}
            size="M"
            type="text"
            name="car_plate_number_1"
            label='contracts:plate_number_1'
            placeholder=""
            help="contracts:help.plate_number"
            required={requiredFields?.car_plate_number_1}
          />
          <TextInput
            flex={1}
            size="M"
            type="text"
            name="car_model_2"
            label='contracts:car_model_2'
            placeholder=""
            required={requiredFields?.car_model_2}
          />
          <TextInput
            flex={1}
            size="M"
            type="text"
            name="car_plate_number_2"
            label='contracts:plate_number_2'
            placeholder=""
            required={requiredFields?.car_plate_number_2}
          />
        </Row>
      </Card>
      <Card mb="L" title="contracts:new_fees_info">
        <ContractFees />
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            size="S"
            type="number"
            name="landlord_fee"
            label='contracts:landlord_fee'
            suffix="currency_symbol"
            required={requiredFields?.landlord_fee}
          />
          <TextInput
            flex={1}
            size="S"
            type="number"
            name="caution_money"
            label='contracts:caution_money'
            suffix="currency_symbol"
            required={requiredFields?.caution_money}
          />
          <TextInput
            flex={1}
            size="S"
            type="number"
            name="deposit"
            label='contracts:deposit'
            suffix="currency_symbol"
            required={requiredFields?.deposit}
          />
        </Row>
      </Card>
      <Card mb="L" title="contracts:renewal_fees_info">
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            size="M"
            type="number"
            name="renewal_fees"
            label='contracts:renewal_fees'
            suffix="currency_symbol"
            required={requiredFields?.renewal_fees}
          />
          <TextInput
            flex={1}
            size="M"
            type="number"
            name="renewal_administrative_fees"
            label='contracts:renewal_administrative_fees'
            suffix="currency_symbol"
            required={requiredFields?.renewal_administrative_fees}
          />
          <RenewalTotalFees />
        </Row>
      </Card>
      <Card mb="L" title="contracts:bank_info">
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            size="M"
            type="text"
            name="rent_date"
            label='contracts:rent_date'
            placeholder="contracts:placeholders.rent_date"
            required={requiredFields?.rent_date}
          />
          <TextInput
            flex={1}
            type="text"
            name="rent_contract_account"
            label='contracts:rent_contract_account'
            placeholder="placeholders.bank_account"
            required={requiredFields?.rent_contract_account}
          />
        </Row>
      </Card>
      <Card mb="L" title="contracts:renew_notice_info" rightText={<Help content="contracts:help.notice_info" />}>
        <Row wrap="wrap" align="baseline">
          <RepeaterFields
            propertyKey="renewal_notice"
            uniqField="destination_email"
            field={(index) => <RenewalNoticeRow key={index} index={index}/>}
          />
        </Row>
        <RepeaterAddButton
          label="contracts:add_renewal_notice"
          propertyKey="renewal_notice"
          uniqField="destination_email"
          fields={['destination_name', 'destination_email']}
          // help="contracts:help.custom_fields"
        />
      </Card>
      <Card mb="L" title="contracts:contractor.contractor_info">
        <Row wrap="wrap" align="start">
          <TextInput
            size="M"
            type="text"
            name="contractor.last_name"
            label='contracts:contractor.last_name'
            required
          />
          <TextInput
            size="M"
            type="text"
            name="contractor.first_name"
            label='contracts:contractor.first_name'
            required={requiredFields?.['contractor.first_name']}
          />
          <TextInput
            size="M"
            type="text"
            name="contractor.furigana_last_name"
            label='contracts:contractor.furigana_last_name'
            required={requiredFields?.['contractor.furigana_last_name']}
          />
          <TextInput
            size="M"
            type="text"
            name="contractor.furigana_first_name"
            label='contracts:contractor.furigana_first_name'
            required={requiredFields?.['contractor.furigana_first_name']}
          />
          <TextInput
            size="M"
            type="text"
            name={'contractor.app_user_manage_id'}
            label={pages?.custom?.chouei ? 'chouei:bellevie_id' : 'app_users:app_user_manage_id' }
            readonly
            required={requiredFields?.['contractor.app_user_manage_id']}
          />
          <TextInput
            size="L"
            type="text"
            name={'contractor.mail_address'}
            label='email'
            required={requiredFields?.['contractor.mail_address']}
          />
          <TextInput
            size="L"
            type="text"
            name={'contractor.phone'}
            label='phone'
            required
          />
          <TextInput
            size="L"
            type="text"
            name={'contractor.emergency_phone'}
            label='emergency_phone'
            required={requiredFields?.['contractor.emergency_phone']}
          />
          <TextInput
            size="L"
            type="text"
            name={'contractor.nationality'}
            label='app_users:nationality'
            readonly
            required={requiredFields?.['contractor.nationality']}
          />
          {
            contract?.contractor?.nationality && contract.contractor.nationality !== 'JPN'
              ? (
                <>
                  <TextInput
                    size="L"
                    type="text"
                    name={'contractor.residence_card'}
                    label='app_users:residence_card'
                    required={requiredFields?.['contractor.residence_card']}
                  />
                  <TextInput
                    size="L"
                    type="text"
                    name={'contractor.home_country_emergency_contact'}
                    label='app_users:home_country_emergency_contact'
                    required={requiredFields?.['contractor.home_country_emergency_contact']}
                  />
                </>
              )
              : null
          }
        </Row>
        <Row wrap="wrap" align="start">
          <TextInput
            size="L"
            mr="0"
            type="text"
            name="contractor.postcode"
            label='properties:postcode'
            pattern="^\d{7}$"
            placeholder="placeholders.postcode"
            required
          />
          <ContractorAddressButton />
          <TextInput
            type="text"
            name="contractor.prefecture"
            label='properties:prefecture'
            readonly
            required
          />
          <TextInput
            type="text"
            name="contractor.city"
            label='properties:city'
            readonly
            required
          />
        </Row>
        <Row wrap="wrap">
          <TextInput
            flex={1}
            type="text"
            name="contractor.address"
            label='address'
            placeholder=""
            required
          />
        </Row>
        <Row wrap="wrap" align="start">
          <TextInput
            size="M"
            type="text"
            name="contractor.occupation"
            label='app_users:occupation'
            required={requiredFields?.['contractor.occupation']}
          />
          <TextInput
            size="M"
            type="text"
            name="contractor.annual_income"
            label='app_users:annual_income'
            suffix="app_users:annual_income_unit"
            required={requiredFields?.['contractor.annual_income']}
          />
          <TextInput
            size="M"
            type="text"
            name="contractor.birthday"
            label='app_users:birthday'
            readonly
            required={requiredFields?.['contractor.birthday']}
          />
          <TextInput
            size="M"
            type="text"
            name="contractor.sex"
            label='app_users:sex'
            readonly
            required={requiredFields?.['contractor.sex']}
          />
        </Row>
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            size="L"
            type="text"
            name="contractor.work"
            label='app_users:work.name'
            required={requiredFields?.['contractor.work']}
          />
          <TextInput
            flex={1}
            size="L"
            type="text"
            name="contractor.work_phone"
            label='app_users:work.phone'
            placeholder="placeholders.phone"
            required={requiredFields?.['contractor.work_phone']}
          />
          <TextInput
            flex={1}
            size="L"
            type="text"
            name="contractor.work_department"
            label='app_users:work.position'
            required={requiredFields?.['contractor.work_department']}
          />
        </Row>
        <Row wrap="wrap" align="start">
          <TextInput
            size="L"
            mr="0"
            type="text"
            name="contractor.work_postcode"
            label='app_users:work.postcode'
            pattern="^\d{7}$"
            placeholder="placeholders.postcode"
            required={requiredFields?.['contractor.work_postcode']}
          />
          <ContractorWorkAddressButton />
          <TextInput
            type="text"
            name="contractor.work_prefecture"
            label='properties:prefecture'
            readonly
            required={requiredFields?.['contractor.work_prefecture']}
          />
          <TextInput
            type="text"
            name="contractor.work_city"
            label='properties:city'
            readonly
            required={requiredFields?.['contractor.work_city']}
          />
        </Row>
        <Row wrap="wrap">
          <TextInput
            flex={1}
            type="text"
            name="contractor.work_address"
            label='app_users:work.address'
            placeholder=""
            required={requiredFields?.['contractor.work_address']}
          />
        </Row>
      </Card>
      {
        differentResident
          ? (
            <Card mb="L" title="contracts:resident.resident_info">
              <Row wrap="wrap" align="start">
                <TextInput
                  size="M"
                  type="text"
                  name="resident.last_name"
                  label='contracts:resident.last_name'
                  required
                />
                <TextInput
                  size="M"
                  type="text"
                  name="resident.first_name"
                  label='contracts:resident.first_name'
                  required={requiredFields?.['resident.first_name']}
                />
                <TextInput
                  size="M"
                  type="text"
                  name="resident.furigana_last_name"
                  label='contracts:resident.furigana_last_name'
                  required={requiredFields?.['resident.furigana_last_name']}
                />
                <TextInput
                  size="M"
                  type="text"
                  name="resident.furigana_first_name"
                  label='contracts:resident.furigana_first_name'
                  required={requiredFields?.['resident.furigana_first_name']}
                />
                <TextInput
                  size="M"
                  type="text"
                  name='resident.app_user_manage_id'
                  label={pages?.custom?.chouei ? 'chouei:bellevie_id' : 'app_users:app_user_manage_id' }
                  readonly
                  required={requiredFields?.['resident.app_user_manage_id']}
                />
                <TextInput
                  size="L"
                  type="text"
                  name={'contractor.mail_address'}
                  label='email'
                  required={requiredFields?.['contractor.mail_address']}
                />
                <TextInput
                  size="L"
                  type="text"
                  name='resident.phone'
                  label='phone'
                  required
                />
                <TextInput
                  size="L"
                  type="text"
                  name='resident.emergency_phone'
                  label='emergency_phone'
                  required={requiredFields?.['resident.emergency_phone']}
                />
                <TextInput
                  size="L"
                  type="text"
                  name='resident.nationality'
                  label='app_users:nationality'
                  readonly
                  required={requiredFields?.['resident.nationality']}
                />
                {
                  contract?.resident?.nationality && contract.resident.nationality !== 'JPN'
                    ? (
                      <>
                        <TextInput
                          size="L"
                          type="text"
                          name='resident.residence_card'
                          label='app_users:residence_card'
                          required={requiredFields?.['resident.residence_card']}
                        />
                        <TextInput
                          size="L"
                          type="text"
                          name='resident.home_country_emergency_contact'
                          label='app_users:home_country_emergency_contact'
                          required={requiredFields?.['resident.home_country_emergency_contact']}
                        />
                      </>
                    )
                    : null
                }
              </Row>
              <Row wrap="wrap" align="start">
                <Select
                  flex={1}
                  size="S"
                  name="resident.resident_relation_id"
                  label='contracts:invited_users.relation_name'
                  options={relationOptions}
                  loading={loadingRelations}
                  required={requiredFields?.['resident.resident_relation_id']}
                />
                <TextInput
                  flex={1}
                  size="M"
                  type="text"
                  name='resident.work'
                  label='app_users:work.work_school'
                  required={requiredFields?.['resident.work']}
                />
                <TextInput
                  size="M"
                  type="text"
                  name="resident.occupation"
                  label='app_users:occupation'
                  required={requiredFields?.['resident.occupation']}
                />
                <TextInput
                  size="M"
                  type="text"
                  name="resident.annual_income"
                  label='app_users:annual_income'
                  suffix="app_users:annual_income_unit"
                  required={requiredFields?.['resident.annual_income']}
                />
                <TextInput
                  size="M"
                  type="text"
                  name="resident.birthday"
                  label='app_users:birthday'
                  readonly
                  required={requiredFields?.['resident.birthday']}
                />
                <TextInput
                  size="M"
                  type="text"
                  name="resident.sex"
                  label='app_users:sex'
                  readonly
                  required={requiredFields?.['resident.sex']}
                />
              </Row>
            </Card>
          )
          : null
      }
      <RoommatesCard />
      <GuarantorCard />
      <Card mb="L" title="contracts:remarks">
        <TextArea
          flex={1}
          name='remarks'
          noLabel
          maxLength={250} // TODO: adjust max length to right size
          required={requiredFields?.remarks}
        />
      </Card>
      <Card mb="L" title="contracts:external_link_info" rightText={<Help content="contracts:help.external_link_info"/>} >
        <Row>
          <TextInput
            size="M"
            type="text"
            name="link_label"
            label='contracts:external_link_label'
            required={requiredFields?.link_label}
          />
          <TextInput
            flex={1}
            type="text"
            name="link_url"
            label='contracts:external_link_url'
            required={requiredFields?.link_url}
          />
        </Row>
      </Card>
    </Form>

  )
}

ContractRenewForm.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  data: PropTypes.object,
  contractID: PropTypes.string
}

export default ContractRenewForm
