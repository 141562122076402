import React, { memo, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import {
  Row,
  RepeaterAddButton,
  RepeaterFields,
  UUID,
  SWAP_REPEATER_ROW,
  ReadOnlyContext
} from '@gk-devteam/apmc-core-web'

import { selectSurveyFormQuestions } from '../../../selectors'

import QuestionsRow from './QuestionsRow'
import { QuestionsDropStyle } from '../SurveyForm.style'
import { SURVEY_QUESTION_TYPE } from '../../../constants'

const Questions = () => {
  const dispatch = useDispatch()
  const questions = useSelector(selectSurveyFormQuestions)
  const isReadOnly = useContext(ReadOnlyContext)

  const onDragEnd = useCallback((result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    dispatch({
      type: SWAP_REPEATER_ROW,
      payload: {
        key: 'questions',
        index1: result?.source?.index,
        index2: result?.destination?.index
      }
    })
  }, [dispatch])

  const _renderQuestions = (index) => {
    const question = questions[index]
    return (
      <QuestionsRow
        key={question?.uuid}
        item={question}
        index={index}
        length={questions?.length || 0}
      />
    )
  }

  return (
    <>
      <Row wrap="wrap" align="baseline">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" isDropDisabled={isReadOnly}>
            {(provided, snapshot) => (
              <QuestionsDropStyle
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                // style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                <RepeaterFields
                  propertyKey="questions"
                  uniqField="label"
                  field={_renderQuestions}
                />
                {provided.placeholder}
              </QuestionsDropStyle>
            )}
          </Droppable>
        </DragDropContext>
      </Row>
      <RepeaterAddButton
        label="surveys:add_question"
        propertyKey="questions"
        uniqField="label"
        fields={{
          uuid: UUID(),
          label: null,
          type: SURVEY_QUESTION_TYPE.TEXT,
          required: null,
          max_length: null,
          'options[0]': null
        }}
      />
    </>
  )
}

export default memo(Questions)

// ContractFees.propTypes = {}
