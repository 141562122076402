import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  PageLayout,
  Card,
  Spinner,
  Row,
  Title,
  SectionTitle
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_DASHBOARD
} from '../../types'
import {
  selectDashboardLoading
} from '../../selectors'

import DashboardChart from './DashboardChart'
import DashboardCardData from './DashboardCardData'

import {
  LoaderContainerStyle
} from './DashboardPage.style.js'

const DashboardPage = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectDashboardLoading)

  useEffect(() => {
    dispatch({ type: FETCH_DASHBOARD })
  }, [dispatch])

  const _renderLoader = () => {
    return (
      <LoaderContainerStyle>
        <Spinner />
      </LoaderContainerStyle>
    )
  }
  return (
    <PageLayout>
      <Row justify="between" mb="M">
        <Title label='dashboard:home' />
      </Row>
      {
        loading
          ? _renderLoader()
          : (
            <>
              <Row justify="between" mb="S">
                {/* <SectionTitle label='dashboard:weeklyData' /> */}
              </Row>
              <DashboardCardData />
              <Row justify="between" mb="S">
                <SectionTitle label='dashboard:dataTransition' />
              </Row>
              <Row>
                <Card>
                  <DashboardChart />
                </Card>
              </Row>
            </>
          )
      }
    </PageLayout>
  )
}
export default DashboardPage

DashboardPage.propTypes = {

}
DashboardPage.defaultProps = {

}
