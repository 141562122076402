import React, { useEffect } from 'react'
import { Redirect } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  useTranslation,
  Row,
  SectionTitle,
  ToastTitle,
  Text,
  InfoButton,
  PageLoader
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import { RESET_IMPORT, IMPORT_VALIDATION } from '../../../types'
import {
  IMPORT_STEPS
} from '../../../constants'
import {
  selectParsedData,
  selectImportType,
  selectCurrentStep,
  selectValidatingData,
  selectImportingData,
  selectImportError,
  selectImportValidationErrors,
  selectParsingData
} from '../../../selectors'

import ImportConfirmForm from './ImportConfirmForm'
import { ErrorCardStyle } from './ImportsConfirmPage.style'

const ImportsConfirmPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const parsing = useSelector(selectParsingData)
  const validating = useSelector(selectValidatingData)
  const importing = useSelector(selectImportingData)
  const data = useSelector(selectParsedData)
  const importType = useSelector(selectImportType)
  const step = useSelector(selectCurrentStep)
  const error = useSelector(selectImportError)
  const validationErrors = useSelector(selectImportValidationErrors)

  useEffect(() => {
    if (data && importType) {
      dispatch({ type: IMPORT_VALIDATION, data, importType })
    }

    return () => {
      dispatch({ type: RESET_IMPORT })
    }
  }, [dispatch, data, importType])

  if (!data) return <Redirect to="/import" noThrow/>
  if (parsing) return null
  if (step && step === IMPORT_STEPS.UPLOAD) return <Redirect to="/import/upload" noThrow/>
  if (step && step === IMPORT_STEPS.DONE) return <Redirect to="/import/done" noThrow/>

  const _renderErrorMessage = () => {
    if (!error) return null
    const count = validationErrors && Object.keys(validationErrors).length
    return (
      <ErrorCardStyle mt="ML" align="baseline">
        <ExclamationCircleOutlined />
        <div>
          <ToastTitle label="import:validation_error" />
          <Row mt="XS" wrap="wrap">
            <Text text={t('import:validation_error_message', { count })} />
          </Row>
        </div>
      </ErrorCardStyle>
    )
  }

  return (
    <>
      { validating ? <PageLoader description="import:validating" /> : null }
      { _renderErrorMessage() }
      <Row justify="between" mt="L" mb="R">
        <Row>
          <SectionTitle label={`import:import_type_label.${importType}`} />
          <SectionTitle label="import:import" />
        </Row>
        <InfoButton
          form="data_import"
          type="submit"
          label="import:import"
          reverse
          disabled={validating == null || (validating && !importing)}
          loading={importing}
        />
      </Row>
      <ImportConfirmForm />
    </>
  )
}
export default ImportsConfirmPage

ImportsConfirmPage.propTypes = {

}
ImportsConfirmPage.defaultProps = {

}
