import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { CancelToken } from 'axios'
import {
  api,
  useTranslation,
  FRONT_DATE_FORMAT,
  UPDATE_FORM,
  Card,
  Row,
  Checkbox,
  TextInput,
  DatePicker,
  Select,
  useIsMountedRef,
  selectPages
} from '@gk-devteam/apmc-core-web'

import { selectContractID } from '../../../../../selectors'
import { SEX_OPTIONS } from '../../../../../constants'
import { appUserPhoneSearchSchema } from '../../../../../validation'
import { fetchRelations } from '../../../../../services'

import AppUserModal from './AppUserModal'
import AppUserNameLink from './AppUserNameLink'
import WaitingUserApproval from './WaitingUserApproval'
import AppUserNameForm from './AppUserNameForm'
import AppUserNationality from '../../../../CommonForms/AppUserNationality'
import LegacyCard from './LegacyCard'
import SearchAddressButton from '../../../../../components/Misc/SearchAddressButton'

const UserCard = ({ userType, readonly }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMountedRef = useIsMountedRef()
  const [isVisible, setIsVisible] = useState(false)
  const [result, setResult] = useState(null)
  const [relationOptions, setRelationOptions] = useState()
  const [loadingRelations, setLoadingRelations] = useState(true)
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [searchError, setSearchError] = useState('')
  const waitingApproval = useSelector(state => state.form[`awaiting_${userType}_approval`])
  const userID = useSelector(state => state.form[`${userType}_user_id`])
  const contractID = useSelector(selectContractID)
  const pages = useSelector(selectPages)

  const fetchRelationOptions = useCallback(
    async () => {
      try {
        setLoadingRelations(true)
        const cancelSource = CancelToken.source()
        const res = await fetchRelations(cancelSource)
        if (!isMountedRef.current) return
        if (res?.data.results) {
          setLoadingRelations(false)
          setRelationOptions(res.data.results)
        } else {
          message.error(t('fetch_error'))
          setLoadingRelations(false)
        }
      } catch (error) {
        message.error(t('fetch_error'))
        setLoadingRelations(false)
      }
    },
    [t, isMountedRef]
  )

  useEffect(() => {
    fetchRelationOptions()
  }, [fetchRelationOptions, isMountedRef])

  const handleWaitForUser = useCallback(
    (userID) => {
      dispatch({
        type: UPDATE_FORM,
        payload: {
          [`${userType}_user_id`]: userID,
          [`awaiting_${userType}_approval`]: true
        }
      })
    },
    [dispatch, userType]
  )

  const _closeModal = useCallback(
    () => {
      setIsVisible(false)
    },
    []
  )
  const _handleSuccess = useCallback(
    ({ user, userID }) => {
      if (userID) {
        handleWaitForUser(userID)
      } else {
        const {
          app_user_id,
          last_name,
          first_name,
          furigana_last_name,
          furigana_first_name,
          app_user_manage_id,
          mail_address,
          phone,
          emergency_phone,
          postcode,
          prefecture,
          city,
          address,
          occupation,
          annual_income,
          birthday,
          sex,
          nationality,
          residence_card,
          home_country_emergency_contact,
          work,
          work_postcode,
          work_prefecture,
          work_city,
          work_address,
          work_phone,
          work_department
        } = user
        dispatch({
          type: UPDATE_FORM,
          payload: {
            [`${userType}_user_id`]: app_user_id,
            [`${userType}.last_name`]: last_name,
            [`${userType}.first_name`]: first_name,
            [`${userType}.furigana_last_name`]: furigana_last_name,
            [`${userType}.furigana_first_name`]: furigana_first_name,
            [`${userType}.app_user_manage_id`]: app_user_manage_id,
            [`${userType}.mail_address`]: mail_address,
            [`${userType}.phone`]: phone,
            [`${userType}.emergency_phone`]: emergency_phone,
            [`${userType}.postcode`]: postcode,
            [`${userType}.prefecture`]: prefecture,
            [`${userType}.city`]: city,
            [`${userType}.address`]: address,
            [`${userType}.occupation`]: occupation,
            [`${userType}.annual_income`]: annual_income,
            [`${userType}.birthday`]: birthday,
            [`${userType}.sex`]: sex,
            [`${userType}.nationality`]: nationality,
            [`${userType}.residence_card`]: residence_card,
            [`${userType}.home_country_emergency_contact`]: home_country_emergency_contact,
            [`${userType}.work`]: work,
            [`${userType}.work_postcode`]: work_postcode,
            [`${userType}.work_prefecture`]: work_prefecture,
            [`${userType}.work_city`]: work_city,
            [`${userType}.work_address`]: work_address,
            [`${userType}.work_phone`]: work_phone,
            [`${userType}.work_department`]: work_department
          }
        })
      }
      _closeModal()
    },
    [_closeModal, dispatch, userType, handleWaitForUser]
  )

  const _handleSearch = useCallback(
    async (search) => {
      if (search) {
        const isValid = await appUserPhoneSearchSchema().isValid({ phone: search })
        if (isValid) {
          setIsLoading(true)
          setSearchError('')
          setResult(null)
          setUser(null)
          try {
            const res = await api.get(`app_users/search?phone=${search}`)
            setIsLoading(false)
            setIsVisible(true)
            if (res.data) {
              setResult(res.data.result)
              setUser(res.data.app_user)
            } else {
              setSearchError(t('fetch_error'))
            }
          } catch (error) {
            console.warning('error', error)
            setIsLoading(false)
            message.error(t('fetch_error'))
            // setSearchError(error)
          }
        } else {
          setSearchError(t('contracts:wrong_phone'))
        }
      } else {
        setSearchError(t('contracts:no_phone'))
      }
    },
    [t]
  )

  const disableAfterToday = (current) => {
    if (current) {
      return current.isAfter(dayjs())
    }
    return false
  }

  if (waitingApproval) return <WaitingUserApproval userType={userType} />

  const _renderUserName = () => {
    if (contractID && userID) return <AppUserNameLink userType={userType} />

    return (
      <>
        <AppUserModal
          visible={isVisible}
          onCancel={_closeModal}
          onSuccess={_handleSuccess}
          result={result}
          user={user}
        />
        <AppUserNameForm
          userType={userType}
          handleSearch={_handleSearch}
          loading={isLoading}
          error={searchError}
        />
      </>
    )
  }

  const _renderResidentRelation = () => {
    if (userType !== 'resident') return null
    return (
      <Select
        flex={1}
        size="S"
        name="resident.resident_relation_id"
        label='contracts:invited_users.relation_name'
        options={relationOptions}
        loading={loadingRelations}
        readonly={readonly}
      />
    )
  }
  const _renderContractorAddress = () => {
    if (userType !== 'contractor') return null
    return (
      <>
        <Row wrap="wrap" align="start">
          <TextInput
            size="L"
            mr="0"
            type="text"
            name="contractor.postcode"
            label='properties:postcode'
            pattern="^\d{7}$"
            placeholder="placeholders.postcode"
            readonly={readonly}
          />
          <SearchAddressButton
            postcodeKey="contractor.postcode"
            prefectureKey="contractor.prefecture"
            cityKey="contractor.city"
            addressKey="contractor.address"
            disabled={readonly}
          />
          <TextInput
            type="text"
            name="contractor.prefecture"
            label='properties:prefecture'
            readonly
          />
          <TextInput
            type="text"
            name="contractor.city"
            label='properties:city'
            readonly
          />
        </Row>
        <Row wrap="wrap">
          <TextInput
            flex={1}
            type="text"
            name="contractor.address"
            label='address'
            placeholder=""
            readonly={readonly}
          />
        </Row>
      </>
    )
  }
  const _renderContractorWork = () => {
    if (userType !== 'contractor') return null
    return (
      <>
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            size="L"
            type="text"
            name="contractor.work"
            label='app_users:work.name'
            readonly={readonly}
          />
          <TextInput
            flex={1}
            size="L"
            type="text"
            name="contractor.work_phone"
            label='app_users:work.phone'
            placeholder="placeholders.phone"
            readonly={readonly}
          />
          <TextInput
            flex={1}
            size="L"
            type="text"
            name="contractor.work_department"
            label='app_users:work.position'
            readonly={readonly}
          />
        </Row>
        <Row wrap="wrap" align="start">
          <TextInput
            size="L"
            mr="0"
            type="text"
            name="contractor.work_postcode"
            label='app_users:work.postcode'
            pattern="^\d{7}$"
            placeholder="placeholders.postcode"
            readonly={readonly}
          />
          <SearchAddressButton
            postcodeKey="contractor.work_postcode"
            prefectureKey="contractor.work_prefecture"
            cityKey="contractor.work_city"
            addressKey="contractor.work_address"
            disabled={readonly}
          />
          <TextInput
            type="text"
            name="contractor.work_prefecture"
            label='properties:prefecture'
            readonly
          />
          <TextInput
            type="text"
            name="contractor.work_city"
            label='properties:city'
            readonly
          />
        </Row>
        <Row wrap="wrap">
          <TextInput
            flex={1}
            type="text"
            name="contractor.work_address"
            label='app_users:work.address'
            placeholder=""
            readonly={readonly}
          />
        </Row>
      </>
    )
  }
  const _renderResidentWork = () => {
    if (userType !== 'resident') return null
    return (
      <TextInput
        flex={1}
        size="M"
        type="text"
        name='resident.work'
        label='app_users:work.work_school'
        readonly={readonly}
      />
    )
  }

  return (
    <>
      { _renderUserName() }
      <Row wrap="wrap" align="start">
        { _renderResidentRelation() }
        <TextInput
          flex={2}
          type="text"
          name={`${userType}.app_user_manage_id`}
          label={pages?.custom?.chouei ? 'chouei:bellevie_id' : 'app_users:app_user_manage_id' }
          readonly={readonly}
          required={pages?.custom?.datasync}
        />
        <TextInput
          flex={5}
          type="text"
          name={`${userType}.mail_address`}
          label='email'
          readonly={readonly}
          placeholder="placeholders.email"
        />
      </Row>
      <Row wrap="wrap" align="baseline">
        <AppUserNationality baseName={`${userType}.`} readonly={readonly} />
        <TextInput
          flex={1}
          size="L"
          type="text"
          name={`${userType}.phone`}
          label='phone'
          readonly={readonly}
          placeholder="placeholders.phone"
        />
        <TextInput
          flex={1}
          size="L"
          type="text"
          name={`${userType}.emergency_phone`}
          label='emergency_phone'
          readonly={readonly}
        />
      </Row>
      { _renderContractorAddress() }
      <Row wrap="wrap" align="baseline">
        { _renderResidentWork() }
        <TextInput
          flex={1}
          type="text"
          size="M"
          name={`${userType}.occupation`}
          label='app_users:occupation'
          readonly={readonly}
        />
        <TextInput
          flex={1}
          type="number"
          size="S"
          name={`${userType}.annual_income`}
          label='app_users:annual_income'
          suffix="app_users:annual_income_unit"
          readonly={readonly}
        />
        <DatePicker
          flex={1}
          size="S"
          name={`${userType}.birthday`}
          label='app_users:birthday'
          placeholder={dayjs().subtract(20, 'year').format(FRONT_DATE_FORMAT)}
          disabledDate={disableAfterToday}
          readonly={readonly}
        />
        <Select
          flex={1}
          size="XS"
          name={`${userType}.sex`}
          label='app_users:sex'
          placeholder=""
          options={SEX_OPTIONS}
          disabled={readonly}
        />
      </Row>
      { _renderContractorWork() }
    </>
  )
}

export const ContractorCard = () => {
  const contractorID = useSelector(state => state.form.contractor_user_id)

  return (
    <Card mb="L" title="contracts:contractor.contractor_info">
      <LegacyCard />
      <UserCard userType="contractor" readonly={!!contractorID} />
    </Card>
  )
}

const DifferentUser = () => {
  const contractID = useSelector(selectContractID)
  const waitingApproval = useSelector(state => state.form.awaiting_resident_approval)

  if (waitingApproval) return null

  return (
    <Row wrap="wrap" mb="R" align="baseline">
      <Checkbox
        noLabel
        mb="0"
        autoH
        name="different_resident"
        valueLabel="contracts:different_resident"
        disabled={!!contractID}
      />
    </Row>
  )
}

export const ResidentCard = () => {
  const differentUser = useSelector(state => state.form.different_resident)
  const residentID = useSelector(state => state.form.resident_user_id)

  return (
    <Card mb="L" title="contracts:resident.resident_info">
      <DifferentUser />
      {
        differentUser ? <UserCard userType="resident" readonly={!!residentID} /> : null
      }
    </Card>
  )
}

UserCard.propTypes = {
  userType: PropTypes.oneOf(['resident', 'contractor']),
  readonly: PropTypes.bool
}
