import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { selectAdminGuideCategoriesData, selectAdminGuideCategoriesAllDisplayed, selectPropertyID } from '../../../../selectors'
import { TOGGLE_GUIDE_CATEGORY, COPY_GUIDE_CATEGORY } from '../../../../types'

import GuideCategoriesList from '../common/GuideCategoriesList'

const AdminGuideCategoriesList = ({ editModal, propertyID }) => {
  const dispatch = useDispatch()

  const _handleCopy = async (data, closeMenu) => {
    dispatch({ type: COPY_GUIDE_CATEGORY, propertyID, categoryID: data.id })
    closeMenu()
  }

  const menu = [
    {
      label: 'guides:copy_guide',
      onClick: _handleCopy,
      shouldRender: 'guides.functions.add'
    }
  ]

  const _handleItemDisplay = useCallback(
    (display, id, item) => {
      if (id) {
        const data = {
          id: item.id,
          display
        }
        const config = {
          type: 'admin_guide_categories',
          property: id
        }
        dispatch({ type: TOGGLE_GUIDE_CATEGORY, data, config })
      } else {
        console.warning('no property id')
      }
    },
    [dispatch]
  )

  return (
    <GuideCategoriesList
      dataSelector={selectAdminGuideCategoriesData}
      displaySelector={selectAdminGuideCategoriesAllDisplayed}
      idSelector={selectPropertyID}
      title="guides:admin_guide_category"
      type="admin_guide_categories"
      level="property"
      guidePage="admin_details"
      editModal={editModal}
      handleItemDisplay={_handleItemDisplay}
      sideMenu={menu}
    />
  )
}

export default AdminGuideCategoriesList

AdminGuideCategoriesList.propTypes = {
  propertyID: PropTypes.string.isRequired,
  editModal: PropTypes.func.isRequired
}
