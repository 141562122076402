import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Tabs,
  Row
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import { selectPages } from '../../../selectors'

import ResidenceHistory from './ResidenceHistory'
import ContractHistory from './ContractHistory'
import ChatHistory from './ChatHistory'

const AppUserHistory = () => {
  const pages = useSelector(selectPages)
  const [panes, setPanes] = useState(null)

  useEffect(() => {
    if (pages) {
      const tmpPanes = []
      if (
        pages.contracts.enabled && pages.contracts.functions.details &&
        pages.properties.enabled && pages.properties.functions.details &&
        pages.rooms.enabled && pages.rooms.functions.details
      ) {
        tmpPanes.push({
          key: 'residence_history',
          tab: 'app_users:residence_history',
          node: <ResidenceHistory />
        })
      }
      if (
        pages.contracts.enabled && pages.contracts.functions.details &&
        pages.properties.enabled && pages.properties.functions.details
        // pages.rooms.enabled && pages.rooms.functions.details
      ) {
        tmpPanes.push({
          key: 'contract_history',
          tab: 'app_users:contract_history',
          node: <ContractHistory />
        })
      }
      if (pages.chat.enabled) {
        tmpPanes.push({
          key: 'chat_history',
          tab: 'app_users:chat_history',
          node: <ChatHistory />
        })
      }
      setPanes(tmpPanes)
    }
  }, [pages])

  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0)
    }, 1)
  })

  return (
    <Row mt="ML">
      <Tabs
        panes={panes}
      />
    </Row>
  )
}

export default AppUserHistory

AppUserHistory.propTypes = {}
