import {
  styled,
  TextArea,
} from '@gk-devteam/apmc-core-web'

export const TextAreaStyle = styled(TextArea)`
  & textarea {
    min-height: 400px;
  }
`
