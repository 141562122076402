import { put, call, takeLatest, cancelled, select } from 'redux-saga/effects'
import { CancelToken } from 'axios'
import { message } from 'antd'

import {
  fetchPropertiesGroup,
  postPropertiesGroup,
  deletePropertiesGroup
} from '../../services'
import {
  FETCH_PROPERTIES_GROUPS,
  FETCH_PROPERTIES_GROUP,
  FETCH_PROPERTIES_GROUP_SUCCESS,
  FETCH_PROPERTIES_GROUP_FAIL,
  POST_PROPERTIES_GROUP,
  POST_PROPERTIES_GROUP_SUCCESS,
  POST_PROPERTIES_GROUP_FAIL,
  DELETE_PROPERTIES_GROUP,
  DELETE_PROPERTIES_GROUP_SUCCESS,
  DELETE_PROPERTIES_GROUP_FAIL
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchPropertiesGroupSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchPropertiesGroup, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_PROPERTIES_GROUP_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_PROPERTIES_GROUP_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_PROPERTIES_GROUP_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postPropertiesGroupSaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postPropertiesGroup, data, cancelSource)
    if (res && res.data && res.data.success) {
      if (successRedirect) yield call(successRedirect)
      yield put({ type: POST_PROPERTIES_GROUP_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_PROPERTIES_GROUP_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_PROPERTIES_GROUP_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deletePropertiesGroupSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deletePropertiesGroup, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_PROPERTIES_GROUP_SUCCESS })
      yield put({ type: FETCH_PROPERTIES_GROUPS, query })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_PROPERTIES_GROUP_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchPropertiesGroup () {
  yield takeLatest(FETCH_PROPERTIES_GROUP, fetchPropertiesGroupSaga)
  yield takeLatest(POST_PROPERTIES_GROUP, postPropertiesGroupSaga)
  yield takeLatest(DELETE_PROPERTIES_GROUP, deletePropertiesGroupSaga)
}
