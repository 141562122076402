import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchVicinities,
  postVicinity,
  toggleVicinity,
  toggleAdditionalVicinities,
  deleteVicinity
} from '../../services'
import {
  FETCH_VICINITIES,
  FETCH_VICINITIES_SUCCESS,
  FETCH_VICINITIES_FAIL,
  POST_VICINITY,
  POST_VICINITY_SUCCESS,
  POST_VICINITY_FAIL,
  TOGGLE_VICINITY,
  TOGGLE_VICINITY_SUCCESS,
  TOGGLE_VICINITY_FAIL,
  DELETE_VICINITY,
  DELETE_VICINITY_SUCCESS,
  DELETE_VICINITY_FAIL,
  TOGGLE_VICINITIES_ADDITIONAL_DATA,
  TOGGLE_VICINITIES_ADDITIONAL_DATA_SUCCESS,
  TOGGLE_VICINITIES_ADDITIONAL_DATA_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchVicinitiesSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchVicinities, id, cancelSource)
    if (res && res.data && res.data.list) {
      const { list, categories, hiddenBy, hiddenById } = res.data
      yield put({ type: FETCH_VICINITIES_SUCCESS, payload: { data: list, categories: categories, hiddenBy, hiddenById } })
    } else {
      yield put({ type: FETCH_VICINITIES_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_VICINITIES_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postVicinitySaga ({ data, id, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postVicinity, data, id, cancelSource)
    if (res && res.data && res.data.success) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_VICINITY_SUCCESS })
      yield put({ type: FETCH_VICINITIES, id })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_VICINITY_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_VICINITY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * toggleVicinitySaga ({ data, id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(toggleVicinity, data, id, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: TOGGLE_VICINITY_SUCCESS })
      yield put({ type: FETCH_VICINITIES, id })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: TOGGLE_VICINITY_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: TOGGLE_VICINITY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * toggleAdditionalVicinitiesSaga ({ data, id, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(toggleAdditionalVicinities, data, id, cancelSource)
    if (res && res.status === 204) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: TOGGLE_VICINITIES_ADDITIONAL_DATA_SUCCESS })
      yield put({ type: FETCH_VICINITIES, id })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: TOGGLE_VICINITIES_ADDITIONAL_DATA_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: TOGGLE_VICINITIES_ADDITIONAL_DATA_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteVicinitySaga ({ id, vicinityID }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteVicinity, id, vicinityID, cancelSource)
    if (res) {
      yield put({ type: DELETE_VICINITY_SUCCESS })
      yield put({ type: FETCH_VICINITIES, id })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_VICINITY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchVicinities () {
  yield takeLatest(FETCH_VICINITIES, fetchVicinitiesSaga)
  yield takeLatest(POST_VICINITY, postVicinitySaga)
  yield takeLatest(TOGGLE_VICINITY, toggleVicinitySaga)
  yield takeLatest(TOGGLE_VICINITIES_ADDITIONAL_DATA, toggleAdditionalVicinitiesSaga)
  yield takeLatest(DELETE_VICINITY, deleteVicinitySaga)
}
