import React from 'react'
import {
  Card,
  FilePicker,
  Form,
  RoleControlled,
  Row,
  Title
} from '@gk-devteam/apmc-core-web'

import { datasyncUpdateValidation } from '../../validation'
import { selectDatasyncPosting } from '../../selectors'
import ConfirmButton from './ConfirmButton'

function DatasyncForm () {
  const handleFormSubmit = (data) => {
    console.log('data', data)
  }
  return (
    <Form
      id="uploadDatasyncData"
      handleSubmit={handleFormSubmit}
      validationSchema={datasyncUpdateValidation}
      postingSelector={selectDatasyncPosting}
      initialData={{
        properties_file: null,
        properties_file_url: '',
        properties_file_filename: '',
        delete_properties_file: false,
        contracts_file: null,
        contracts_file_url: '',
        contracts_file_filename: '',
        delete_contracts_file: false
      }}
      authorized="custom.manual_datasync"
      // debug
    >
      <Row justify="between" mb="ML">
        <Title label="navigation:datasync" />
        <RoleControlled authorized="custom.manual_datasync">
          <ConfirmButton />
        </RoleControlled>
      </Row>
      <Card mb="L" title="datasync:properties_file">
        <FilePicker
          name="properties_file"
          delete_name="delete_properties_file"
          types={['csv']}
        />
      </Card>
      <Card mb="L" title="datasync:contracts_file">
        <FilePicker
          name="contracts_file"
          delete_name="delete_contracts_file"
          types={['csv']}
        />
      </Card>
    </Form>
  )
}

DatasyncForm.propTypes = {

}

export default DatasyncForm
