import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const yasueMaintenanceUpdateSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    contacts: yup.string().nullable().max(10000).label(i18n.t('yasue:maintenance.contacts')),
    showrooms: yup.string().nullable().max(10000).label(i18n.t('yasue:maintenance.showrooms'))
  })
}
