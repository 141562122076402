import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
  FONT_SIZE,
  Row
} from '@gk-devteam/apmc-core-web'
import { transparentize } from 'polished'

export const CardStyle = styled(Row)`
  ${BORDER_RADIUS}
  border: 2px solid ${props => getColor(props, 'warning')};
  padding: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
  background-color: ${props => transparentize(0.9, getColor(props, 'warning'))};

  .anticon {
    ${FONT_SIZE.LARGE}
    color: ${props => getColor(props, 'warning')};
    margin-right: ${SPACING.REGULAR}rem;
  }
`
