import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Row,
  TextArea
} from '@gk-devteam/apmc-core-web'

import {
  selectRefuseLoading,
  selectRefusePosting
} from '../../selectors'
import { refuseReportSchema } from '../../validation'

const ReportRefuseForm = ({ data, handleSubmit }) => {
  const initialValues = { ...data }

  return (
    <Form
      id="refuse_report"
      handleSubmit={handleSubmit}
      validationSchema={refuseReportSchema}
      loadingSelector={selectRefuseLoading}
      postingSelector={selectRefusePosting}
      initialData={initialValues}
      // debug
    >
      <Row>
        <TextArea
          flex={1}
          name="message"
          label='refuse:message'
          placeholder=""
          required
        />
      </Row>
    </Form>
  )
}
export default ReportRefuseForm

ReportRefuseForm.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired
}
ReportRefuseForm.defaultProps = {

}
