import React, { memo, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  UPDATE_FORM,
  Radio,
  TextInput,
  Select,
  selectPages,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import { HOUSE_MAKER_NOTICE_RECIPIENT_TYPE_OPTIONS, NOTICE_RECIPIENT_TYPE_OPTIONS, NOTICE_USER_TYPE_OPTIONS } from '../../../../constants'
import { FETCH_AREAS, RESET_AREAS } from '../../../../types'
import {
  selectPrefecturesOptions,
  selectCitiesOptions,
  selectAreasLoading
} from '../../../../selectors'

const RecipientType = () => {
  const prevPrefectureRef = useRef()
  const prevRecipientTypeRef = useRef()
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)
  const recipientType = useSelector(state => state.form.recipient_type)
  const prefecturesOptions = useSelector(selectPrefecturesOptions)
  const cities = useSelector(selectCitiesOptions)
  const loadingAreas = useSelector(selectAreasLoading)
  const watchedPrefecture = useSelector(state => state.form.prefecture)

  const cityOptions = watchedPrefecture ? cities[watchedPrefecture] : []

  useEffect(() => {
    dispatch({ type: FETCH_AREAS })
    return () => {
      dispatch({ type: RESET_AREAS })
    }
  }, [dispatch])

  useEffect(() => {
    if (!prevPrefectureRef.current) {
      prevPrefectureRef.current = watchedPrefecture
    } else {
      if (prevPrefectureRef.current !== watchedPrefecture) {
        prevPrefectureRef.current = watchedPrefecture
        dispatch({ type: UPDATE_FORM, payload: { city: '' } })
      }
    }
  }, [watchedPrefecture, dispatch])

  useEffect(() => {
    if (!prevRecipientTypeRef.current) {
      prevRecipientTypeRef.current = recipientType
    } else {
      if (recipientType != null && recipientType !== prevRecipientTypeRef) {
        dispatch({
          type: UPDATE_FORM,
          payload: {
            property_name: '',
            app_user_name: '',
            prefecture: '',
            city: ''
          }
        })
      }
    }
  }, [dispatch, recipientType])

  const _renderRecipientType = () => {
    if (!recipientType) return null
    switch (recipientType) {
      case 1:
        return (
          <TextInput
            name="property_name"
            label='notices:property_name'
            placeholder="placeholders.partial_property_name"
            mr="0"
          />
        )
      case 2:
        return (
          <TextInput
            name="app_user_name"
            label='notices:app_user_name'
            placeholder="placeholders.partial_user_name"
            mr="0"
          />
        )
      case 3:
        return (
          <>
            <Select
              name="prefecture"
              label='properties:prefecture'
              placeholder=""
              options={prefecturesOptions}
              loading={loadingAreas}
              mr="0"
            />
            <Select
              name="city"
              label='properties:city'
              placeholder=""
              options={cityOptions}
              loading={loadingAreas}
              disabled={!watchedPrefecture}
              mr="0"
            />
          </>
        )
      default:
        return null
    }
  }
  return (
    <>
      <Radio
        name="recipient_type"
        label='notices:recipient_type'
        options={pages?.custom?.house_maker ? HOUSE_MAKER_NOTICE_RECIPIENT_TYPE_OPTIONS : NOTICE_RECIPIENT_TYPE_OPTIONS}
        small
        mr="0"
      />
      { _renderRecipientType() }
      {
        recipientType !== 2
          ? (
            <RoleControlled authorized='!custom.house_maker'>
              <Select
                name="user_type"
                label='notices:user_type'
                placeholder=""
                options={NOTICE_USER_TYPE_OPTIONS}
                mr="0"
              />
            </RoleControlled>
          )
          : null
      }
    </>
  )
}

export default memo(RecipientType)

RecipientType.propTypes = {}
