import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import dot from 'dot-object'
import PropTypes from 'prop-types'
import {
  Checkmark
} from '@gk-devteam/apmc-core-web'

import { CheckmarkCellStyle } from '../PropertyForm.style'

const UserCheckmark = ({ value, cellKey, rowKey, className, data }) => {
  const selectedUsers = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    return form?.meeting_users?.map(user => user.app_user_id)
  })
  return (
    <CheckmarkCellStyle>
      <Checkmark
        checked={selectedUsers?.includes(rowKey) || false}
      />
    </CheckmarkCellStyle>
  )
}

export default memo(UserCheckmark)

UserCheckmark.propTypes = {
  value: PropTypes.any,
  cellKey: PropTypes.string,
  rowKey: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.object
}
