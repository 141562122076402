import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  useTranslation,
  InquiryID,
  Form,
  Card,
  Row,
  Title,
  FilePicker,
  FormSubmitButton,
  RoleControlled,
  TextInput,
  Select,
  Checkbox
} from '@gk-devteam/apmc-core-web'

import {
  selectLoadingMember,
  selectPostingMember,
  selectUserRole,
  selectPages
} from '../../../selectors'
import { memberUpdateValidation } from '../../../validation'
import { POST_MEMBER } from '../../../types'
import { INQUIRY_PREFIX, ROLES } from '../../../constants'

import SelectItems from '../common/SelectItems'
import { MemoTextAreaStyle } from '../common/common.style'

const MemberForm = ({ data, memberID }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector(selectUserRole)
  const [roleOptions, setRoleOptions] = useState(null)
  const [authorized, setAuthorized] = useState(true)
  const pages = useSelector(selectPages)

  useEffect(() => {
    if (userRole) {
      const options = []
      if (userRole === ROLES.AGENCY_ADMIN || userRole === ROLES.AGENCY_MAIN_ADMIN) {
        options.push({
          label: t('user_management:member.roles.AGENCY_ADMIN'),
          value: ROLES.AGENCY_ADMIN
        })
      }
      options.push({
        label: t('user_management:member.roles.AGENCY_EDITOR'),
        value: ROLES.AGENCY_EDITOR
      },
      {
        label: t('user_management:member.roles.AGENCY_MEMBER'),
        value: ROLES.AGENCY_MEMBER
      },
      {
        label: t('user_management:member.roles.SUPPORT'),
        value: ROLES.SUPPORT
      })

      setRoleOptions(options)
    }
  }, [userRole, t])

  useEffect(() => {
    if (pages && data && data.id) {
      if (!data.editable || !pages.manage_members.functions.update) setAuthorized(false)
    }
  }, [pages, data])

  const initialValues = {
    ...data,
    file: (data && data.file) || null,
    delete_file: (data && data.delete_file) || false,
    groups: data.groups_list && data.groups_list.length > 0 ? data.groups_list.map(item => item.id) : null,
    properties: data.properties_list && data.properties_list.length > 0 ? data.properties_list.map(item => item.id) : null,
    groups_list: data.groups_list && data.groups_list.length > 0 ? data.groups_list : null,
    properties_list: data.properties_list && data.properties_list.length > 0 ? data.properties_list : null
  }

  const handleFormSubmit = (data) => {
    const successRedirect = (id) => {
      navigate('/user_management/')
    }
    dispatch({ type: POST_MEMBER, data, successRedirect })
  }

  return (
    <Form
      id="addGuestForm"
      handleSubmit={handleFormSubmit}
      validationSchema={memberUpdateValidation}
      loadingSelector={selectLoadingMember}
      postingSelector={selectPostingMember}
      initialData={initialValues}
      authorized={authorized}
      // debug
    >
      <Row justify="between" wrap="wrap">
        {
          memberID
            ? <Title text={data.name} />
            : <Title label='user_management:add_member' />
        }
        <RoleControlled authorized="manage_members.functions.update">
          <FormSubmitButton editMode={!!memberID} postingSelector={selectPostingMember}/>
        </RoleControlled>
      </Row>
      <Row mb="ML">
        <InquiryID id={memberID} prefix={INQUIRY_PREFIX.MEMBER} />
      </Row>
      <div>
        <Card mb="L">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="name"
              label='user_management:member.name'
              placeholder=""
              required
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="furigana"
              label='furigana'
              placeholder=""
              required
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="mail_address"
              label='email'
              placeholder="placeholders.email"
              required
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="phone"
              label='phone'
              placeholder="placeholders.phone"
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="office"
              label='user_management:member.office'
              placeholder=""
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="department"
              label='user_management:member.department'
              placeholder=""
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="position"
              label='user_management:member.position'
              placeholder=""
            />
            <Select
              flex={1}
              minW={140}
              maxW={140}
              name="role"
              label='user_management:member.role'
              placeholder=""
              options={roleOptions}
              noClear
              required
              disabled={!!memberID}
            />
          </Row>
        </Card>
        <Row mt="L" align="stretch">
          <Card title='image' mr="L">
            <FilePicker
              name="file"
              delete_name="delete_file"
              types={['image']}
            />
          </Card>
          <Card title='memo'>
            <MemoTextAreaStyle
              noLabel
              name="memo"
              placeholder="placeholders.memo"
            />
          </Card>
        </Row>
        <Card title='user_management:groups-properties' mt="L">
          <Checkbox
            name="all_properties"
            inputLabel='user_management:all_properties'
            disabled={userRole !== ROLES.AGENCY_MAIN_ADMIN}
            help={t('user_management:all_properties_help')}
          />
          <SelectItems itemsKey="groups" />
          <SelectItems itemsKey="properties" />
        </Card>
      </div>
    </Form>
  )
}
export default MemberForm

MemberForm.propTypes = {
  data: PropTypes.object,
  memberID: PropTypes.string
}
