import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchService,
  fetchServiceByLevel,
  updateServiceDisplay,
  postCloudSignClientId
} from '../../services'
import {
  FETCH_SERVICE,
  FETCH_SERVICE_SUCCESS,
  FETCH_SERVICE_FAIL,
  FETCH_SERVICE_BY_LEVEL,
  FETCH_SERVICE_BY_LEVEL_SUCCESS,
  FETCH_SERVICE_BY_LEVEL_FAIL,
  POST_SERVICE_DISPLAY,
  POST_SERVICE_DISPLAY_SUCCESS,
  POST_SERVICE_DISPLAY_FAIL,
  POST_CLOUDSIGN_CLIENT_ID,
  POST_CLOUDSIGN_CLIENT_ID_SUCCESS,
  POST_CLOUDSIGN_CLIENT_ID_FAIL
} from '../../types'
import { i18n } from '../../locales'
import { SERVICES_LEVEL } from '../../constants'

export function * fetchServiceSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchService, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_SERVICE_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_SERVICE_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_SERVICE_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('fetch_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * fetchServiceByLevelSaga ({ query, level, id }) {
  const cancelSource = CancelToken.source()
  try {
    let path
    switch (level) {
      case SERVICES_LEVEL.PROPERTY:
        path = 'properties'
        break
      case SERVICES_LEVEL.ROOM:
        path = 'rooms'
        break
      case SERVICES_LEVEL.CONTRACT:
        path = 'contracts'
        break
      default:
        break
    }
    const res = yield call(fetchServiceByLevel, query, id, path, cancelSource)
    if (res && res.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_SERVICE_BY_LEVEL_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_SERVICE_BY_LEVEL_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_SERVICE_BY_LEVEL_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('fetch_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * postServiceDisplaySaga ({ data, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(updateServiceDisplay, data, cancelSource)
    if (res && res.status && res.status === 204) {
      yield put({ type: POST_SERVICE_DISPLAY_SUCCESS })
      if (onSuccess) yield call(onSuccess)
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_SERVICE_DISPLAY_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_SERVICE_DISPLAY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postCloudSignClientIdSaga ({ data, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postCloudSignClientId, data, cancelSource)
    if (res && res.status && res.status === 204) {
      yield put({ type: POST_CLOUDSIGN_CLIENT_ID_SUCCESS })
      if (onSuccess) yield call(onSuccess)
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CLOUDSIGN_CLIENT_ID_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CLOUDSIGN_CLIENT_ID_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchService () {
  yield takeLatest(FETCH_SERVICE, fetchServiceSaga)
  yield takeLatest(FETCH_SERVICE_BY_LEVEL, fetchServiceByLevelSaga)
  yield takeLatest(POST_SERVICE_DISPLAY, postServiceDisplaySaga)
  yield takeLatest(POST_CLOUDSIGN_CLIENT_ID, postCloudSignClientIdSaga)
}
