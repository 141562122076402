import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
  FONT_SIZE
} from '@gk-devteam/apmc-core-web'
import { transparentize } from 'polished'

export const ServiceListStyle = styled.ul`
  ${BORDER_RADIUS};
  margin: ${SPACING.MEDIUM_LARGE}rem 0 0 0;
  padding: 0;
  list-style: none;
  border: solid 1px ${props => getColor(props, 'cardBorder')};
`
export const ServiceItemStyle = styled.li`
  padding: ${SPACING.REGULAR}rem ${SPACING.MEDIUM_LARGE}rem;
  background-color: ${props => props.parentDisabled ? transparentize(0.65, getColor(props, 'cardBorder')) : 'inherit'};
  opacity: ${props => props.parentDisabled ? 0.65 : 1};
  cursor: ${props => props.link ? 'pointer' : 'inherit'};

  &:not(:last-of-type){
    border-bottom: solid 1px ${props => getColor(props, 'cardBorder')};
  }

  &[disabled] {
    background-color: ${props => transparentize(0.85, getColor(props, 'cardBorder'))};
  }
`

export const TopRowStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & label {
    line-height: 14px;
  }
`

export const ServiceItemMainStyle = styled.div`
  display: flex;
  width: auto;
  flex: 1;
  padding-top: ${SPACING.SMALLEST}rem;
`

export const ServiceContentStyle = styled.div`
  width: 100%;
`

export const IconStyle = styled.div`
  width: ${SPACING.LARGE}rem;
  height: ${SPACING.LARGE}rem;
  margin-right: ${SPACING.LARGE}rem;
`

export const ServiceDisplayContainerStyle = styled.div`
  margin-left: ${SPACING.LARGE}rem;
  & label span {
    white-space: nowrap;
  }
`

export const ServiceDisplayStyle = styled.div`
  ${BORDER_RADIUS};
  font-size: inherit;
  line-height: ${SPACING.REGULAR}rem;
  display: inline-flex;
  background-color: ${props => getColor(props, 'cardBorder')};
  border: 1px solid ${props => getColor(props, 'text')};
  color: ${props => getColor(props, 'text')};
  padding: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
  user-select: none;
  cursor: not-allowed;
`

export const ServiceItemContentStyle = styled.div`
  width: 100%;
`

export const HelpIconContainerStyle = styled.div`
  margin-left: ${SPACING.SMALL}rem;
  color: ${props => getColor(props, 'accent')};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
`

export const ParentHelpStyle = styled.div`
  ${BORDER_RADIUS};
  margin-top: ${SPACING.SMALL}rem;
  border: 1px solid ${props => getColor(props, 'warning')};
  padding: ${SPACING.SMALLEST}rem ${SPACING.SMALL}rem;
  background-color: ${props => transparentize(0.9, getColor(props, 'warning'))};
  box-shadow: 0px -2px 8px 0 #f0f1f2;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;

  .anticon {
    ${FONT_SIZE.MEDIUM}
    color: ${props => getColor(props, 'warning')};
    margin-right: ${SPACING.SMALL}rem;
  }
`
