import { put, call, takeLatest, cancelled, select } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchPropertyEvents,
  postPropertyEvent,
  deletePropertyEvent
} from '../../services'
import {
  FETCH_PROPERTY_EVENTS,
  FETCH_PROPERTY_EVENTS_SUCCESS,
  FETCH_PROPERTY_EVENTS_FAIL,
  POST_PROPERTY_EVENT,
  POST_PROPERTY_EVENT_SUCCESS,
  POST_PROPERTY_EVENT_FAIL,
  DELETE_PROPERTY_EVENT,
  DELETE_PROPERTY_EVENT_SUCCESS,
  DELETE_PROPERTY_EVENT_FAIL
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchPropertyEventsSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchPropertyEvents, query, cancelSource)
    if (res?.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_PROPERTY_EVENTS_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_PROPERTY_EVENTS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    console.log('err', error)
    yield put({ type: FETCH_PROPERTY_EVENTS_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postPropertyEventSaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()

  try {
    const res = yield call(postPropertyEvent, data, cancelSource)
    if (res?.status === 204) {
      const query = yield select(getQuery)
      yield put({ type: POST_PROPERTY_EVENT_SUCCESS })
      yield put({ type: FETCH_PROPERTY_EVENTS, query })
      if (successRedirect) yield call(successRedirect)
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_PROPERTY_EVENT_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_PROPERTY_EVENT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deletePropertyEventSaga ({ eventID, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deletePropertyEvent, eventID, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_PROPERTY_EVENT_SUCCESS })
      yield put({ type: FETCH_PROPERTY_EVENTS, query })
      if (onSuccess) yield call(onSuccess)
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_PROPERTY_EVENT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchPropertyEvents () {
  yield takeLatest(FETCH_PROPERTY_EVENTS, fetchPropertyEventsSaga)
  yield takeLatest(POST_PROPERTY_EVENT, postPropertyEventSaga)
  yield takeLatest(DELETE_PROPERTY_EVENT, deletePropertyEventSaga)
}
