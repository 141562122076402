import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const yasueConstructionWorksSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    work_number: yup.string().max(255).nullable().label(i18n.t('yasue:construction.work_number')),
    prefecture: yup.string().label(i18n.t('properties:prefecture')),
    city: yup.string().label(i18n.t('properties:city')),
    limit: yup.number().integer().label(i18n.t('limit')),
    page: yup.number().integer().label(i18n.t('page')),
    sort_by: yup.string().label(i18n.t('sort_by')),
    sort_order: yup.string().label(i18n.t('sort_order'))
  })
}
