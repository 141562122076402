import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { subscribeToAppInfo } from '../actions'
import { selectUserID, selectUserRole } from '../selectors'

export const useFirebaseAppObserver = () => {
  const dispatch = useDispatch()
  const userID = useSelector(selectUserID)
  const userRole = useSelector(selectUserRole)

  useEffect(() => {
    if (userID) {
      dispatch(subscribeToAppInfo())
    }
  }, [dispatch, userID, userRole])
}
