import React, { useState } from 'react'
import { message } from 'antd'
import { CancelToken } from 'axios'
import { InfoButton, Modal, selectUserRole, useTranslation } from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import { downloadUsersLoginFile } from '../../../services'
import AppUsersDownloadLoginPdfForm from '../../../forms/AppUsersDownloadLoginPdfForm'
import { useSelector } from 'react-redux'
import { ROLES } from '../../../constants'

function DownloadUsersLoginFile () {
  const userRole = useSelector(selectUserRole)
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [posting, setPosting] = useState(false)

  if (userRole !== ROLES.AGENCY_MAIN_ADMIN) return null

  const _openModal = () => {
    setShowModal(true)
  }
  const _closeModal = () => {
    setShowModal(false)
  }

  const _handleFormSubmit = async (data) => {
    const request = {
      date: data?.date,
      type: data?.type,
      ids: data?.ids,
      file: data?.file
    }
    setPosting(true)
    try {
      const cancelSource = CancelToken.source()
      const res = await downloadUsersLoginFile(request, cancelSource)
      if (res.status === 204) {
        message.success(t('app_users:download_pdf_files.wait_for_email'))
      } else {
        console.log('res', res)
        message.error(t('post_error'))
      }
    } catch (error) {
      message.error(error?.data?.message || t('post_error'))
    } finally {
      setPosting(false)
      setShowModal(false)
    }
  }
  return (
    <>
      <Modal
        formID="downloadAppUsersLoginPdfForm"
        title="app_users:download_pdf_files.modal_title"
        visible={showModal}
        onOk={_handleFormSubmit}
        onCancel={_closeModal}
        okText="submit"
        posting={posting}
        large
        fullWidth
        fullHeight
      >
        <AppUsersDownloadLoginPdfForm onSubmit={_handleFormSubmit}/>
      </Modal>
      <InfoButton
        label="app_users:download_pdf_files.download_button"
        handleClick={_openModal}
        reverse
        withMargin
      />
    </>
  )
}

DownloadUsersLoginFile.propTypes = {}

export default DownloadUsersLoginFile
