import React, { memo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import {
  dynamicLink,
  Text,
  Tag,
  Column,
  FRONT_TIMESTAMP_FORMAT,
  Row,
  LightText
} from '@gk-devteam/apmc-core-web'

import {
  selectSpacesWaitingReservationsCount,
  selectSpacesWaitingReservationsLoading,
  selectSpacesWaitingReservations
} from '../../../selectors'
import {
  FETCH_CONTRACTS_PROCESSING,
  RESET_CONTRACTS_PROCESSING
} from '../../../types'
import { SPACE_TYPE } from '../../../constants'

import AdminTaskCard from './AdminTaskCard'

import { ContractorStyle } from './AdminTasksPage.style'
import dayjs from 'dayjs'

const SpaceReservationsCard = () => {
  const dispatch = useDispatch()
  const count = useSelector(selectSpacesWaitingReservationsCount)
  const navigate = useNavigate()

  useEffect(() => {
    dispatch({ type: FETCH_CONTRACTS_PROCESSING })
    return () => {
      dispatch({ type: RESET_CONTRACTS_PROCESSING })
    }
  }, [dispatch])

  const navigateToSpace = (id, row, e) => {
    const { property_id, space_id } = row
    const url = `/properties/edit/${property_id}/spaces/edit/${space_id}/reservations`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  const mainCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const { app_user_name, property_name, room_number, space_name, reservation_from, reservation_to } = data
      return (
        <Column align="start">
          <ContractorStyle text={app_user_name} />
          <div>
            <Text text={property_name} />　<Text text={room_number} />　<Text text={space_name} />
          </div>
          <Row mt="XS" wrap="nowrap"><LightText text={`${dayjs(reservation_from).format(FRONT_TIMESTAMP_FORMAT)} - ${dayjs(reservation_to).format(FRONT_TIMESTAMP_FORMAT)}`} /></Row>
        </Column>
      )
    },
    []
  )

  const typeCell = useCallback(
    (value, cellKey, rowID, className) => {
      let label, color
      switch (value) {
        case SPACE_TYPE.PARKING:
          label = 'spaces:types.parking'
          color = 'process'
          break
        case SPACE_TYPE.COMMUNITY_ROOM:
          label = 'spaces:types.community_room'
          color = 'danger'
          break
        case SPACE_TYPE.ELECTRIC_VEHICLE_PARKING:
          label = 'spaces:types.electric_vehicle_parking'
          color = 'accent'
          break
        case SPACE_TYPE.CAR_WASH:
          label = 'spaces:types.car_wash'
          color = 'success'
          break
        case SPACE_TYPE.GUEST_ROOM:
          label = 'spaces:types.guest_room'
          color = 'warning'
          break
        case SPACE_TYPE.SKY_DECK:
          label = 'spaces:types.sky_deck'
          color = 'info'
          break
        default:
          break
      }

      return <Tag label={label} color={color}/>
    },
    []
  )

  const columns = [
    {
      title: '',
      dataIndex: 'property_name',
      key: 'property_name',
      render: mainCell
    },
    {
      title: '',
      dataIndex: 'space_type',
      key: 'space_type',
      render: typeCell
    }
  ]

  return (
    <AdminTaskCard
      title="spaces:space_reservation_status"
      linkTo={'./spaces_reservations'}
      linkLabel="admin_tasks:to_reservations"
      unresolvedCountLabel=""
      unresolvedListLabel=""
      count={count}
      columns={columns}
      loadingSelector={selectSpacesWaitingReservationsLoading}
      dataSelector={selectSpacesWaitingReservations}
      handleRowClick={navigateToSpace}
      rowKey="reservation_id"
    />
  )
}

export default memo(SpaceReservationsCard)

SpaceReservationsCard.propTypes = {}
