import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { DatePicker } from '@gk-devteam/apmc-core-web'

const ContractDates = () => {
  const startDate = useSelector(state => state.form.contract_start)
  const endDate = useSelector(state => state.form.contract_end)

  const disablePreviousDate = (current) => {
    if (current && startDate) {
      const start = dayjs(startDate)
      return start && start.isAfter(current)
    }
    return false
  }
  const disableNextDate = (current) => {
    if (current && endDate) {
      const end = dayjs(endDate)
      return end && current.isAfter(end)
    }
    return false
  }
  return (
    <>
      <DatePicker
        flex={1}
        size="M"
        name="contract_start"
        label='contracts:contract_start_date'
        placeholder=""
        disabledDate={disableNextDate}
        required
      />
      <DatePicker
        flex={1}
        size="M"
        name="contract_end"
        label='contracts:contract_end_date'
        placeholder=""
        disabledDate={disablePreviousDate}
      />
    </>
  )
}

export default ContractDates

// ContractDatepickers.propTypes = {}
