import { createReducer } from '@reduxjs/toolkit'

import {
  SUBSCRIBE_FIREBASE_USER,
  SUBSCRIBE_FIREBASE_USER_SUCCESS,
  SUBSCRIBE_FIREBASE_USER_FAIL,
  UNSUBSCRIBE_FIREBASE_USER
} from '../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  data: null,
  id: null
}

export default createReducer(INITIAL_STATE, {
  [SUBSCRIBE_FIREBASE_USER]: (state, action) => {
    state.loading = true
    state.data = null
  },
  [SUBSCRIBE_FIREBASE_USER_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload.data
    state.id = action.payload.id
  },
  [SUBSCRIBE_FIREBASE_USER_FAIL]: (state, action) => {
    state.loading = false
  },
  [UNSUBSCRIBE_FIREBASE_USER]: () => INITIAL_STATE
})
