import { createSelector } from 'reselect'

const selectProperty = state => state.property

export const selectLoadingProperty = createSelector(
  selectProperty,
  property => property.loading
)
export const selectPostingProperty = createSelector(
  selectProperty,
  property => property.posting
)
export const selectPropertyError = createSelector(
  selectProperty,
  property => property.error
)
export const selectPropertyData = createSelector(
  selectProperty,
  property => property.data || {}
)
export const selectPropertyID = createSelector(
  selectProperty,
  property => property.data && property.data.id
)
export const selectPropertyName = createSelector(
  selectProperty,
  property => property.data && property.data.name
)
