export const FETCH_PROPERTIES_GROUPS = 'fetch_properties_groups'
export const FETCH_PROPERTIES_GROUPS_SUCCESS = 'fetch_properties_groups_success'
export const FETCH_PROPERTIES_GROUPS_FAIL = 'fetch_properties_groups_fail'
export const RESET_PROPERTIES_GROUPS = 'reset_properties_groups'

export const DELETE_PROPERTIES_GROUP = 'delete_properties_group'
export const DELETE_PROPERTIES_GROUP_SUCCESS = 'delete_properties_group_success'
export const DELETE_PROPERTIES_GROUP_FAIL = 'delete_properties_group_fail'

export const FETCH_PROPERTIES_GROUP = 'fetch_properties_group'
export const FETCH_PROPERTIES_GROUP_SUCCESS = 'fetch_properties_group_success'
export const FETCH_PROPERTIES_GROUP_FAIL = 'fetch_properties_group_fail'
export const RESET_PROPERTIES_GROUP = 'reset_properties_group'

export const POST_PROPERTIES_GROUP = 'post_properties_group'
export const POST_PROPERTIES_GROUP_SUCCESS = 'post_properties_group_success'
export const POST_PROPERTIES_GROUP_FAIL = 'post_properties_group_fail'
