export const FETCH_GUIDE_CATEGORIES = 'fetch_guide_categories'
export const FETCH_GUIDE_CATEGORIES_SUCCESS = 'fetch_guide_categories_success'
export const FETCH_GUIDE_CATEGORIES_FAIL = 'fetch_guide_categories_fail'

export const POST_GUIDE_CATEGORY = 'post_guide_category'
export const POST_GUIDE_CATEGORY_SUCCESS = 'post_guide_category_success'
export const POST_GUIDE_CATEGORY_FAIL = 'post_guide_category_fail'

export const COPY_GUIDE_CATEGORY = 'copy_guide_category'
export const COPY_GUIDE_CATEGORY_SUCCESS = 'copy_guide_category_success'
export const COPY_GUIDE_CATEGORY_FAIL = 'copy_guide_category_fail'

export const DELETE_GUIDE_CATEGORY = 'delete_guide_category'
export const DELETE_GUIDE_CATEGORY_SUCCESS = 'delete_guide_category_success'
export const DELETE_GUIDE_CATEGORY_FAIL = 'delete_guide_category_fail'

export const TOGGLE_GUIDE_CATEGORY = 'toggle_guide_category'
export const TOGGLE_GUIDE_CATEGORY_SUCCESS = 'toggle_guide_category_success'
export const TOGGLE_GUIDE_CATEGORY_FAIL = 'toggle_guide_category_fail'

export const BULK_TOGGLE_GUIDE_CATEGORIES = 'bulk_toggle_guide_categories'
export const BULK_TOGGLE_GUIDE_CATEGORIES_SUCCESS = 'bulk_toggle_guide_categories_success'
export const BULK_TOGGLE_GUIDE_CATEGORIES_FAIL = 'bulk_toggle_guide_categories_fail'

export const RESET_GUIDE_CATEGORIES = 'reset_guide_categories'
