import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_FORM,
  selectQueryParams
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_CHECKLIST,
  RESET_CHECKLIST
} from '../../../types'
import {
  selectWaitingChecklistsCount,
  selectChecklistHiddenBy,
  selectChecklistLoadingSubscription,
  selectChecklistsStatuses,
  selectChecklistHiddenById
} from '../../../selectors'

import { checklistSearchSchema } from '../../../validation'

import ChecklistSearchForm from './ChecklistSearchForm'
import ChecklistsList from './ChecklistsList'
import ListPage from '../common/ListPage'

const initialValues = {
  contract_id: '',
  app_user_name: '',
  property_name: '',
  room_number: '',
  status: null,
  repair: null,
  checklist_category_id: null
}

const AdminTasksChecklistPage = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectChecklistLoadingSubscription)
  const statuses = useSelector(selectChecklistsStatuses)
  const queryParams = useSelector(selectQueryParams)

  useEffect(() => {
    let repair
    if (queryParams?.repair === 'true' || queryParams?.repair === 'false') {
      repair = queryParams.repair
    }
    dispatch({
      type: UPDATE_FORM,
      payload: {
        contract_id: queryParams.contract_id,
        app_user_name: queryParams.app_user_name,
        property_name: queryParams.property_name,
        room_number: queryParams.room_number,
        status: queryParams.status ? Number(queryParams.status) : queryParams.status,
        repair,
        checklist_category_id: queryParams.checklist_category_id
      }
    })
    const schema = checklistSearchSchema()
    schema.isValid(queryParams)
      .then(valid => {
        if (valid) {
          dispatch({ type: FETCH_CHECKLIST, params: queryParams })
        } else {
          console.warning('params invalid, query using default params')
          dispatch({ type: FETCH_CHECKLIST })
        }
      })

    return () => {
      dispatch({ type: RESET_CHECKLIST })
    }
  }, [queryParams, dispatch, statuses])

  const breadcrumbs = [
    {
      label: 'navigation:admin_tasks',
      link: '/admin_tasks'
    },
    {
      label: 'navigation:checklists_list',
      link: '/admin_tasks/checklist'
    }
  ]

  if (loading) return null

  return (
    <ListPage
      breadcrumbs={breadcrumbs}
      pageTitle="navigation:checklists_list"
      hiddenSelector={selectChecklistHiddenBy}
      hiddenByIdSelector={selectChecklistHiddenById}
      countSelector={selectWaitingChecklistsCount}
      validationSchema={checklistSearchSchema}
      initialValues={initialValues}
      searchInputs={<ChecklistSearchForm />}
      table={<ChecklistsList queryParams={queryParams}/>}
    />

  )
}
export default memo(AdminTasksChecklistPage)

AdminTasksChecklistPage.propTypes = {

}
AdminTasksChecklistPage.defaultProps = {

}
