import React from 'react'
import {
  PageLayout
} from '@gk-devteam/apmc-core-web'

import BillingForm from '../../../forms/chouei/BillingForm'

const breadcrumbs = [
  {
    label: 'navigation:chouei.billing',
    link: '/billing'
  }
]

function BillingPage (props) {
  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <BillingForm />
    </PageLayout>
  )
}

BillingPage.propTypes = {

}

export default BillingPage
