import React, { memo, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  ReadOnlyContext,
  Row,
  Column,
  Icon,
  Text,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import SelectedItems from '../common/SelectedItems'
import PropertiesModal from '../common/PropertiesModal'
import GroupsModal from '../common/GroupsModal'

import { SearchButtonStyle } from '../common/common.style'
import { useSelector } from 'react-redux'

const SelectItems = ({ itemsKey, noLabel, readOnly }) => {
  const [modaleVisible, setModaleVisible] = useState(false)
  const [label, setLabel] = useState('')
  const isReadOnly = useContext(ReadOnlyContext)
  const allProperties = useSelector(({ form }) => form.all_properties)

  useEffect(() => {
    switch (itemsKey) {
      case 'properties':
        setLabel('property')
        break
      case 'groups':
        setLabel('user_management:groups')
        break
      default:
        break
    }
  }, [itemsKey])

  const _openModal = () => {
    setModaleVisible(true)
  }
  const _closeModal = () => {
    setModaleVisible(false)
  }

  const _renderModal = () => {
    switch (itemsKey) {
      case 'properties':
        return (
          <PropertiesModal
            visible={modaleVisible}
            close={_closeModal}
          />
        )
      case 'groups':
        return (
          <GroupsModal
            visible={modaleVisible}
            close={_closeModal}
          />
        )

      default:
        return null
    }
  }

  if (allProperties) return null

  return (
    <Column mt="XS">
      {
        noLabel ? null : <Row><Text label={label} /></Row>
      }
      <Row mt="XS" align="start">
        <SelectedItems itemsKey={itemsKey} readOnly={readOnly || isReadOnly} />
        <RoleControlled authorized={!isReadOnly && !readOnly}>
          <SearchButtonStyle type="button" onClick={_openModal}>
            <Icon name="search" />
          </SearchButtonStyle>
        </RoleControlled>
      </Row>
      { _renderModal() }
    </Column>
  )
}

export default memo(SelectItems)

SelectItems.propTypes = {
  itemsKey: PropTypes.oneOf(['properties', 'groups']),
  noLabel: PropTypes.bool,
  readOnly: PropTypes.bool
}
