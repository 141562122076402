/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'
import dayjs from 'dayjs'

export const contractCancelValidation = (locale = 'ja', requiredFields) => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    template_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .label(i18n.t('contracts:template')),
    cancel_date: yup
      .date()
      .nullable()
      .isRequired(requiredFields?.cancel_date)
      .label(i18n.t('contracts:move_out_date')),
    contract_sign_date: yup
      .date()
      .nullable()
      .isRequired(requiredFields?.contract_sign_date)
      .label(i18n.t('contracts:contract_sign_date')),
    final_inspection_datetime: yup
      .date()
      .nullable()
      .isRequired(requiredFields?.final_inspection_datetime)
      .label(i18n.t('contracts:final_inspection_datetime')),
    final_inspection_datetime_1: yup
      .date()
      .nullable()
      .isRequired(requiredFields?.final_inspection_datetime_1)
      .test(
        'is-different',
        i18n.t('must_be_different', { label: '${label}', otherField: i18n.t('contracts:final_inspection_datetime_2') }),
        function (value) {
          const { final_inspection_datetime_2 } = this.parent
          if (value) {
            const date1 = dayjs(value)
            const date2 = dayjs(final_inspection_datetime_2)
            return !date1.isSame(date2)
          }
          return true
        }
      )
      .label(i18n.t('contracts:final_inspection_datetime_1')),
    final_inspection_datetime_2: yup
      .date()
      .nullable()
      .isRequired(requiredFields?.final_inspection_datetime_2)
      .test(
        'is-different',
        i18n.t('must_be_different', { label: '${label}', otherField: i18n.t('contracts:final_inspection_datetime_1') }),
        function (value) {
          const { final_inspection_datetime_1 } = this.parent
          if (value) {
            const date1 = dayjs(value)
            const date2 = dayjs(final_inspection_datetime_1)
            return !date1.isSame(date2)
          }
          return true
        }
      )
      .label(i18n.t('contracts:final_inspection_datetime_2')),
    message: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.message)
      .label(i18n.t('contracts:message')),
    new_postcode: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.new_postcode)
      .label(i18n.t('contracts:new_postcode')),
    new_address: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.new_address)
      .label(i18n.t('contracts:new_address')),
    cancel_reason: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .min(0).max(9)
      .integer()
      .nullable()
      .isRequired(requiredFields?.cancel_reason)
      .label(i18n.t('contracts:cancel_reason')),
    cancel_message: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.cancel_message)
      .label(i18n.t('contracts:cancel_message')),
    cancel_notice: yup
      .array()
      .nullable()
      .of(yup
        .object()
        .nullable()
        .shape({
          enabled: yup
            .boolean()
            .required()
            .label(i18n.t('contracts:notice_destination_enabled')),
          destination_name: yup
            .string()
            .max(255)
            .nullable()
            .required()
            .label(i18n.t('contracts:cancel_notice_destination_name')),
          destination_email: yup
            .string()
            .email()
            .nullable()
            .required()
            .label(i18n.t('contracts:cancel_notice_destination_email'))
        })
      )
      .label(i18n.t('contracts:renewal_notice')),
    repayment_bank_account: yup
      .object()
      .nullable()
      .shape({
        bank_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['repayment_bank_account.bank_name'])
          .label(i18n.t('contracts:repayment_bank_account.bank_name')),
        bank_type: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .nullable()
          .isRequired(requiredFields?.['repayment_bank_account.bank_type'])
          .label(i18n.t('contracts:repayment_bank_account.bank_type')),
        branch_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['repayment_bank_account.branch_name'])
          .label(i18n.t('contracts:repayment_bank_account.branch_name')),
        branch_type: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .nullable()
          .isRequired(requiredFields?.['repayment_bank_account.branch_type'])
          .label(i18n.t('contracts:repayment_bank_account.branch_type')),
        account_type: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .nullable()
          .isRequired(requiredFields?.['repayment_bank_account.account_type'])
          .label(i18n.t('contracts:repayment_bank_account.account_type')),
        account_number: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['repayment_bank_account.account_number'])
          .label(i18n.t('contracts:repayment_bank_account.account_number')),
        name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['repayment_bank_account.name'])
          .label(i18n.t('contracts:repayment_bank_account.name')),
        furigana: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['repayment_bank_account.furigana'])
          .label(i18n.t('contracts:repayment_bank_account.furigana'))
      })
      .label(i18n.t('contracts:repayment_bank_account.info'))
  })
}
