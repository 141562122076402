import React from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'

import { SPACING, useTranslation } from '@gk-devteam/apmc-core-web'

import {
  WeekdayStyle,
  WeekdayBarStyle,
  WeekdayLabelStyle
} from './ChatSettingsForm.style.js'

function WeekDayPreview ({ level, disabled, label, allDay }) {
  const { t } = useTranslation()
  const startTime = useSelector(state => {
    if (level === 'admin') {
      return state.chat.settings.admin?.startTime
    }
    if (level === 'property') {
      return state.chat.settings.property?.startTime
    }
    return state.form.startTime
  })
  const endTime = useSelector(state => {
    if (level === 'admin') {
      return state.chat.settings.admin?.endTime
    }
    if (level === 'property') {
      return state.chat.settings.property?.endTime
    }
    return state.form.endTime
  })
  const autoMessage = useSelector(state => {
    if (level === 'admin') {
      return state.chat.settings.admin?.autoMessage
    }
    if (level === 'property') {
      return state.chat.settings.property?.autoMessage
    }
    return state.form.autoMessage
  })

  function getStart () {
    let start
    if (startTime) {
      const [hours, minutes] = startTime.split(':')
      const min = Number(minutes) / 60
      start = Number(hours) + min
    }
    return allDay ? 0 : start
  }
  function getEnd () {
    let end
    if (endTime) {
      const [hours, minutes] = endTime.split(':')
      const min = Number(minutes) / 60
      end = Number(hours) + min
    }
    return allDay ? 24 : end
  }

  return (
    <Tooltip
      title={`${t('chat.auto_reply_hours')}\n ${allDay ? '終日' : `${startTime}-${endTime}`}`}
      overlayStyle={{
        fontSize: `${SPACING.SMALL}rem`
      }}
      placement="bottom"
    >
      <WeekdayStyle>
        <WeekdayBarStyle
          bottom={getEnd()}
          top={getStart()}
          active={disabled ? false : !!autoMessage}
        />
        <WeekdayLabelStyle label={label}/>
      </WeekdayStyle>
    </Tooltip>
  )
}

export default WeekDayPreview

WeekDayPreview.propTypes = {
  level: PropTypes.oneOf(['admin', 'property']),
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  allDay: PropTypes.bool
}
