import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  FormSubmitButton,
  Row,
  SectionTitle
} from '@gk-devteam/apmc-core-web'

import {
  selectAdminChatSettings,
  selectChatLoading,
  selectOwnerID
} from '../../../../selectors'
import { fetchChatSettings, updateChatSettings } from '../../../../actions'
import { FETCH_CHAT_STATUS, RESET_CHAT_SETTINGS } from '../../../../types'

import ChatSettingsForm from '../../../../forms/chat/ChatSettingsForm'

function ChatSettings () {
  const dispatch = useDispatch()
  const data = useSelector(selectAdminChatSettings)
  const ownerID = useSelector(selectOwnerID)

  const fetchData = useCallback(
    () => {
      dispatch(fetchChatSettings({
        adminDocument: `owner_${ownerID}`
      }))
    },
    [dispatch, ownerID]
  )

  useEffect(() => {
    dispatch({ type: FETCH_CHAT_STATUS })
  }, [dispatch])

  useEffect(() => {
    if (ownerID) {
      fetchData()
      dispatch({ type: FETCH_CHAT_STATUS })
    }
    return () => {
      dispatch({ type: RESET_CHAT_SETTINGS })
    }
  }, [dispatch, fetchData, ownerID])

  const _handleSubmit = (data) => {
    if (selectOwnerID) {
      dispatch(updateChatSettings({
        documentName: `owner_${ownerID}`,
        data,
        onSuccess: fetchData
        // onFail: message.error(t('post_error'))
      }))
    }
  }
  return (
    <>
      <Row justify="end" mt="R" mb="ML">
        <FormSubmitButton formID="chat_settings_form" postingSelector={selectChatLoading}/>
      </Row>
      <SectionTitle label="chat.chat_settings" />

      <Card title="chat.auto_reply" mt="R">
        <ChatSettingsForm data={data} handleSubmit={_handleSubmit}/>
      </Card>
    </>
  )
}

export default ChatSettings
