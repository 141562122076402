import React, { memo, useContext } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  ReadOnlyContext,
  TOGGLE_ARRAY_ITEM
} from '@gk-devteam/apmc-core-web'

import {
  RecipientStyle
} from '../SurveyForm.style'

const RecipientsItem = ({ id, label }) => {
  const dispatch = useDispatch()
  const isReadOnly = useContext(ReadOnlyContext)
  const _handleUserClick = () => {
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [
        {
          key: 'recipients_list',
          value: { id, label },
          objectComparatorKey: 'id'
        },
        {
          key: 'recipients_id',
          value: id
        }]
    })
  }
  return (
    <RecipientStyle
      handleClick={_handleUserClick}
      key={id}
      label={label ? label.replace('.', '') : ''}
      disabled={isReadOnly}
    />
  )
}

export default memo(RecipientsItem)

RecipientsItem.propTypes = {
  label: PropTypes.string,
  id: PropTypes.number
}
