import { createSelector } from 'reselect'

const selectSupport = state => state.support

export const selectSupportLoading = createSelector(
  selectSupport,
  support => support.loading
)
export const selectSupportData = createSelector(
  selectSupport,
  support => support.data
)
