import React from 'react'
import { useSelector } from 'react-redux'
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line
} from 'recharts'
import {
  COLORS,
  RoleControlled,
  selectPages,
  selectUserRole
} from '@gk-devteam/apmc-core-web'

import { selectDashboardChart } from '../../selectors'
import { ROLES } from '../../constants'

import { ChartWrapper } from './DashboardPage.style.js'

const DashboardChart = () => {
  const pages = useSelector(selectPages)
  const role = useSelector(selectUserRole)
  const chart = useSelector(selectDashboardChart)
  if (!chart) return null

  return (
    <ChartWrapper>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chart}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend iconType="line" />

          {
            role !== ROLES.SUPPORT && <Line type="monotone" dataKey="ダウンロード数" stroke={COLORS.danger} />
          }

          <RoleControlled authorized='!custom.yasue'>
            <Line type="monotone" dataKey="物件登録数" stroke={COLORS.success} />
          </RoleControlled>
          <RoleControlled authorized='!custom.yasue'>
            <Line type="monotone" dataKey='契約登録数' name={pages?.custom?.house_maker ? 'オーナー登録数' : '契約登録数'} stroke={COLORS.warning} />
          </RoleControlled>
        </LineChart>
      </ResponsiveContainer>
    </ChartWrapper>
  )
}

export default DashboardChart

// DashboardChart.propTypes = {}
