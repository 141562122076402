import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_INUI_CONTACTS,
  FETCH_INUI_CONTACTS_SUCCESS,
  FETCH_INUI_CONTACTS_FAIL,
  RESET_INUI_CONTACTS
  // DELETE_PROPERTY_SUCCESS,
  // DELETE_PROPERTY_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  max_pages: null,
  results_count: null,
  results: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_INUI_CONTACTS]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_INUI_CONTACTS_SUCCESS]: (state, action) => {
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_INUI_CONTACTS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_INUI_CONTACTS]: (state, action) => {
    state.loading = null
    state.error = null
    state.max_pages = null
    state.results_count = null
    state.results = null
  }
})
