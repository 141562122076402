import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const appUserPhoneSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    phone: yup.string().nullable().isCellPhone().label(i18n.t('phone'))
  })
}
