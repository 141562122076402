import { put, call, takeLatest, cancelled, select } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchInquiries,
  deleteInquiry
} from '../../services'

import {
  FETCH_INQUIRIES,
  FETCH_INQUIRIES_SUCCESS,
  FETCH_INQUIRIES_FAIL,
  DELETE_INQUIRY,
  DELETE_INQUIRY_SUCCESS,
  DELETE_INQUIRY_FAIL
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchInquiriesdSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchInquiries, query, cancelSource)
    if (res && res.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_INQUIRIES_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_INQUIRIES_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_INQUIRIES_FAIL, error: true, payload: { message: error.data || error.message || i18n.t('fetch_error') } })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteContractSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteInquiry, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_INQUIRY_SUCCESS })
      yield put({ type: FETCH_INQUIRIES, query })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_INQUIRY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchInquiries () {
  yield takeLatest(FETCH_INQUIRIES, fetchInquiriesdSaga)
  yield takeLatest(DELETE_INQUIRY, deleteContractSaga)
}
