import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_REFUSE_CALENDAR,
  FETCH_REFUSE_CALENDAR_SUCCESS,
  FETCH_REFUSE_CALENDAR_FAIL,
  // FETCH_REFUSE_CALENDARS,
  // FETCH_REFUSE_CALENDARS_SUCCESS,
  // FETCH_REFUSE_CALENDARS_FAIL,
  SELECT_CALENDAR,
  SELECT_CALENDAR_SUCCESS,
  SELECT_CALENDAR_FAIL,
  POST_CALENDAR,
  POST_CALENDAR_SUCCESS,
  POST_CALENDAR_FAIL,
  // REPORT_CALENDAR,
  // REPORT_CALENDAR_SUCCESS,
  // REPORT_CALENDAR_FAIL,
  DELETE_CALENDAR,
  // DELETE_CALENDAR_SUCCESS,
  DELETE_CALENDAR_FAIL,
  RESET_REFUSE_CALENDAR
  // RESET_REFUSE_CALENDARS
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  loadingList: true,
  posting: null,
  data: null,
  calendar: null,
  hasIssue: false,
  error: null,
  hiddenBy: null,
  hiddenById: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_REFUSE_CALENDAR]: (state, action) => {
    state.loading = true
  },
  [FETCH_REFUSE_CALENDAR_SUCCESS]: (state, action) => {
    const selectedCalendar = action.payload.results.find(item => item.isSelected)
    state.loading = false
    state.calendar = action.payload.garbage_original_url || (selectedCalendar && selectedCalendar.url) || ''
    state.hasIssue = action.payload.hasIssue
    state.data = action.payload.results
    state.hiddenBy = action.payload.hiddenBy
    state.hiddenById = action.payload.hiddenById
  },
  [FETCH_REFUSE_CALENDAR_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  // [FETCH_REFUSE_CALENDARS]: (state, action) => {
  //   state.loadingList = true
  // },
  // [FETCH_REFUSE_CALENDARS_SUCCESS]: (state, action) => {
  //   state.loadingList = false
  //   state.data = action.payload
  // },
  // [FETCH_REFUSE_CALENDARS_FAIL]: (state, action) => {
  //   state.loadingList = false
  //   state.error = action.payload
  // },
  [DELETE_CALENDAR]: (state, action) => {
    state.loading = true
  },
  [DELETE_CALENDAR_FAIL]: (state, action) => {
    state.loading = false
  },
  [SELECT_CALENDAR]: (state, action) => {
    state.posting = true
  },
  [SELECT_CALENDAR_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [SELECT_CALENDAR_FAIL]: (state, action) => {
    state.posting = false
  },
  [POST_CALENDAR]: (state, action) => {
    state.posting = true
  },
  [POST_CALENDAR_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CALENDAR_FAIL]: (state, action) => {
    state.posting = false
  },
  // [RESET_REFUSE_CALENDARS]: (state, action) => {
  //   state.data = null
  // },
  [RESET_REFUSE_CALENDAR]: () => INITIAL_STATE

})
