import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import { REPORT_CALENDAR } from '../../../../types'

import ReportRefuseForm from '../../../../forms/refuse/ReportRefuseForm'

const ReportModal = ({ visible, onCancel, id }) => {
  const dispatch = useDispatch()
  const initialValues = {
    message: ''
  }

  const _handleReportSubmit = (data) => {
    const onSuccess = () => {
      onCancel()
    }
    dispatch({ type: REPORT_CALENDAR, data, id, onSuccess })
  }

  return (
    <Modal
      title="refuse:report_modal_title"
      formID="refuse_report"
      visible={visible}
      onCancel={onCancel}
      onOk={_handleReportSubmit}
      okText="refuse:report"
      okDanger
      fullWidth
    >
      <ReportRefuseForm data={initialValues} handleSubmit={_handleReportSubmit} />
    </Modal>
  )
}

export default memo(ReportModal)

ReportModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
}
