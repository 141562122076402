import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import {
  DatePicker
} from '@gk-devteam/apmc-core-web'

dayjs.extend(isSameOrBefore)

const MovingInDate = () => {
  const startDate = useSelector(state => state.form.contract_start)
  const endDate = useSelector(state => state.form.contract_end)
  const moveOutDate = useSelector(state => state.form.move_out)

  const disableNextDate = (current) => {
    if (current && (endDate || moveOutDate)) {
      const start = dayjs(startDate)
      const end = dayjs(endDate)
      const moveOut = moveOutDate ? dayjs(moveOutDate) : null
      const date = moveOut || end
      return (start && start.isAfter(current, 'day')) || (date && current.isAfter(date, 'day'))
    }
    return false
  }
  return (
    <DatePicker
      flex={1}
      size="M"
      name="move_in"
      label='contracts:move_in_date'
      placeholder=""
      disabledDate={disableNextDate}
      required
    />
  )
}

export default MovingInDate

// ContractDatepickers.propTypes = {}
