import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const appUsersSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    app_user_name: yup.string().label(i18n.t('app_users:app_user_name')),
    app_user_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('app_users:app_user_id')),
    app_user_manage_id: yup.string().label(i18n.t('app_users:app_user_manage_id')),
    app_user_phone: yup.string().label(i18n.t('phone')),
    // min_residencies: yup
    //   .number()
    //   .transform((cv, ov) => ov === '' ? undefined : cv)
    //   .integer().nullable()
    //   .isMore('max_residencies', i18n.t('app_users:max_residencies'))
    //   .label(i18n.t('app_users:min_residencies')),
    // max_residencies: yup
    //   .number()
    //   .transform((cv, ov) => ov === '' ? undefined : cv)
    //   .integer().nullable()
    //   .isLess('min_residencies', i18n.t('app_users:min_residencies'))
    //   .label(i18n.t('app_users:max_residencies')),
    // min_contracts: yup
    //   .number()
    //   .transform((cv, ov) => ov === '' ? undefined : cv)
    //   .integer().nullable()
    //   .isMore('max_contracts', i18n.t('app_users:max_contracts'))
    //   .label(i18n.t('app_users:min_contracts')),
    // max_contracts: yup
    //   .number()
    //   .transform((cv, ov) => ov === '' ? undefined : cv)
    //   .integer().nullable()
    //   .isLess('min_contracts', i18n.t('app_users:min_contracts'))
    //   .label(i18n.t('app_users:max_contracts')),
    min_income: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable()
      .isMore('max_income', i18n.t('app_users:max_income'))
      .label(i18n.t('app_users:min_income')),
    max_income: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable()
      .isLess('min_income', i18n.t('app_users:min_income'))
      .label(i18n.t('app_users:max_income')),
    property_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('properties:property_id')),
    property_name: yup.string().label(i18n.t('properties:property_name')),
    room_number: yup.string().max(255).label(i18n.t('properties:room_number')),
    limit: yup.number().integer().label(i18n.t('limit')),
    page: yup.number().integer().label(i18n.t('page')),
    sort_by: yup.string().label(i18n.t('sort_by')),
    sort_order: yup.string().label(i18n.t('sort_order'))
  })
}
