import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Form,
  TextInput
} from '@gk-devteam/apmc-core-web'

import { checklistSettingsSchema } from '../../validation'

const initialData = {
  checklist_duration: 7
}

const ChecklistSettingsForm = ({ data, handleSubmit }) => {
  return (
    <Form
      id="checklist_settings_form"
      handleSubmit={handleSubmit}
      validationSchema={checklistSettingsSchema}
      // loadingSelector={selectLoadingAppUser}
      // postingSelector={selectPostingAppUser}
      initialData={{
        ...initialData,
        ...data
      }}
      // debug
    >
      <Row align="start" wrap="wrap">
        <TextInput
          flex={1}
          type="number"
          name="checklist_duration"
          label='admin_tasks:checklist_duration'
          suffix="日"
          placeholder=""
          size="XS"
        />
      </Row>
    </Form>
  )
}

export default memo(ChecklistSettingsForm)

ChecklistSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  data: PropTypes.object
}
