import { createSelector } from 'reselect'

const selectImport = state => state.dataImport
const selectImportRow = (state, rowIndex, propertyKey) => {
  return { index: rowIndex, key: propertyKey }
}

export const selectParsingData = createSelector(
  selectImport,
  dataImport => dataImport.parsing
)
export const selectValidatingData = createSelector(
  selectImport,
  dataImport => dataImport.validating
)
export const selectImportingData = createSelector(
  selectImport,
  dataImport => dataImport.importing
)
export const selectImportType = createSelector(
  selectImport,
  dataImport => dataImport.importType
)
export const selectParsedData = createSelector(
  selectImport,
  dataImport => dataImport.originalData
)
export const selectImportData = createSelector(
  selectImport,
  dataImport => dataImport.data
)
export const selectCurrentStep = createSelector(
  selectImport,
  dataImport => dataImport.step
)
export const selectImportError = createSelector(
  selectImport,
  dataImport => dataImport.error
)
export const selectImportCellData = createSelector(
  [selectImport, selectImportRow],
  (dataImports, { index, key }) => {
    if (index != null) {
      const currentIndex = dataImports.data && dataImports.data.findIndex(item => item.originalIndex === index)
      return currentIndex != null && dataImports.data[currentIndex] != null && dataImports.data[currentIndex][key]
    }
    return null
  }
)
export const selectImportValidationErrors = createSelector(
  selectImport,
  dataImport => dataImport.validationErrors
)
export const selectImportCellErrors = createSelector(
  [selectImport, selectImportRow],
  (dataImports, { index, key }) => {
    if (index != null) {
      return dataImports.validationErrors && dataImports.validationErrors[index] && dataImports.validationErrors[index][key]
    }
    return null
  }
)
export const selectImportedIds = createSelector(
  selectImport,
  dataImport => dataImport.ids
)
export const selectImportChunksCount = createSelector(
  selectImport,
  dataImport => dataImport.chunksCount
)
export const selectImportCurrentChunk = createSelector(
  selectImport,
  dataImport => dataImport.currentChunk
)
export const selectImportProgress = createSelector(
  selectImport,
  dataImport => {
    const { currentChunk, chunksCount } = dataImport
    if (!currentChunk || !chunksCount) return 0
    return Math.floor((currentChunk * 100) / chunksCount)
  }
)
export const selectImportPartialErrors = createSelector(
  selectImport,
  dataImport => dataImport.partialErrors
)
