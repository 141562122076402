import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  TextInput,
  Select
} from '@gk-devteam/apmc-core-web'

import { NATIONALITY_OPTIONS } from '../../constants'

const AppUserNationality = ({ baseName, readonly }) => {
  const nationality = useSelector(state => state.form[`${baseName}nationality`])

  return (
    <>
      <Select
        flex={1}
        size="M"
        name={`${baseName}nationality`}
        label='app_users:nationality'
        placeholder=""
        options={NATIONALITY_OPTIONS}
        showSearch
        defaultValue="JPN"
        disabled={readonly}
        autocomplete="off"
      />
      {
        nationality && nationality !== 'JPN'
          ? (
            <>
              <TextInput
                flex={1}
                type="text"
                name={`${baseName}residence_card`}
                label='app_users:residence_card'
                // required
                readonly={readonly}
              />
              <TextInput
                flex={1}
                type="text"
                name={`${baseName}home_country_emergency_contact`}
                label='app_users:home_country_emergency_contact'
                // required
                readonly={readonly}
              />
            </>
          )
          : null
      }
    </>
  )
}

export default memo(AppUserNationality)

AppUserNationality.propTypes = {
  baseName: PropTypes.string,
  readonly: PropTypes.bool
}
AppUserNationality.defaultProps = {
  baseName: ''
}
