import { createReducer } from '@reduxjs/toolkit'
import * as types from '../../types'

const INITIAL_STATE = {
  loading: null,
  error: null,
  user: null,
  theme: null,
  pages: null,
  locale: null
}

const customizeHeader = (header) => {
  let siteName, headerBgColor, headerTextColor
  switch (process.env.REACT_APP_STAGE) {
    case 'production':
      siteName = header.siteName
      headerBgColor = header.headerBgColor
      headerTextColor = header.headerTextColor
      break
    case 'demo':
      siteName = `${header.siteName} | デモ`
      headerBgColor = header.headerBgColor
      headerTextColor = header.headerTextColor
      break
    case 'staging':
      siteName = `${header.siteName} | 検証`
      headerBgColor = header.headerBgColor === '#0d1a26' ? '#D6363D' : header.headerBgColor
      headerTextColor = header.headerTextColor
      break
    case 'develop':
      siteName = `${header.siteName} | 開発`
      headerBgColor = header.headerBgColor === '#0d1a26' ? '#271166' : header.headerBgColor
      headerTextColor = header.headerTextColor
      break
    case 'local':
      siteName = `${header.siteName} | local`
      headerBgColor = header.headerBgColor === '#0d1a26' ? '#04380e' : header.headerBgColor
      headerTextColor = header.headerTextColor
      break
    default:
      break
  }
  return {
    ...header,
    siteName,
    headerBgColor,
    headerTextColor
  }
}

export default createReducer(INITIAL_STATE, {
  [types.LOGIN_USER]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [types.LOGIN_USER_SUCCESS]: (state, action) => {
    const { user, theme, pages } = action.payload
    state.loading = false
    state.user = user
    state.theme = {
      ...theme,
      header: customizeHeader(theme.header)
    }
    state.pages = pages
    state.locale = (user && user.locale) || 'ja'
  },
  [types.LOGIN_USER_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [types.REFRESH_USER_SUCCESS]: (state, action) => {
    const { user, theme, pages } = action.payload
    state.loading = false
    state.user = user
    state.theme = theme
    state.pages = pages
  },
  [types.REFRESH_USER_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [types.LOGOUT_USER]: () => INITIAL_STATE
})
