import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { subscribeToFirebaseUser } from '../actions'
import { selectUserID } from '../selectors'

export const useFirebaseUserObserver = () => {
  const dispatch = useDispatch()
  const userID = useSelector(selectUserID)

  useEffect(() => {
    if (userID) {
      dispatch(subscribeToFirebaseUser())
    }
  }, [dispatch, userID])
}
