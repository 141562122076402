import React, { memo } from 'react'
import PropTypes from 'prop-types'

import {
  NotificationMessageStyle,
  MessageStyle,
  MessageFooterStyle,
  UserStyle,
  TimeStyle
} from './Drawer.style'

const NotificationMessage = ({ message, closeDrawer }) => {
  if (!message) return null
  const {
    userID,
    userName,
    content,
    lastUpdated,
    link
  } = message
  return (
    <NotificationMessageStyle>
      <MessageStyle to={link} onClick={closeDrawer}>{ content || '' }</MessageStyle>
      <MessageFooterStyle mt="XS" justify="between">
        <UserStyle to={`/app_users/edit/${userID}`}>{userName}</UserStyle>
        <TimeStyle>{lastUpdated}</TimeStyle>
      </MessageFooterStyle>
    </NotificationMessageStyle>
  )
}

export default memo(NotificationMessage)

NotificationMessage.propTypes = {
  message: PropTypes.shape({
    userID: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    content: PropTypes.string,
    lastUpdated: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }).isRequired,
  closeDrawer: PropTypes.func.isRequired
}
