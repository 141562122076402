import { createSelector } from 'reselect'

const selectGuests = state => state.guests

export const selectGuestsLoading = createSelector(
  selectGuests,
  guests => guests.loading
)
export const selectGuestsCount = createSelector(
  selectGuests,
  guests => guests.results_count
)
export const selectGuestsData = createSelector(
  selectGuests,
  guests => guests.results
)
