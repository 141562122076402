import { createReducer } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import {
  FETCH_NOTICE,
  FETCH_NOTICE_SUCCESS,
  FETCH_NOTICE_FAIL,
  RESET_NOTICE,
  POST_NOTICE,
  POST_NOTICE_SUCCESS,
  POST_NOTICE_FAIL,
  STOP_NOTICE
  // DELETE_NOTICE,
  // DELETE_NOTICE_SUCCESS,
  // DELETE_NOTICE_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  stopping: null,
  data: null,
  editable: true,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_NOTICE]: (state, action) => {
    state.posting = true
  },
  [POST_NOTICE_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_NOTICE_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_NOTICE]: (state, action) => {
    state.loading = true
    state.stopping = false
    state.editable = false
  },
  [FETCH_NOTICE_SUCCESS]: (state, action) => {
    const editable = () => {
      if (action.payload) {
        const { notice_type, start_time } = action.payload
        if (notice_type === 1) return false
        if (notice_type === 2 && dayjs().isAfter(start_time)) return false
      }
      return true
    }
    state.loading = false
    state.data = action.payload
    state.editable = editable()
  },
  [FETCH_NOTICE_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [STOP_NOTICE]: (state, action) => {
    state.stopping = true
  },
  [RESET_NOTICE]: () => INITIAL_STATE

})
