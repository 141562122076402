import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { LoadingOutlined } from '@ant-design/icons'
import {
  PageLayout,
  Row,
  Title,
  Steps
} from '@gk-devteam/apmc-core-web'

import {
  selectPages,
  selectCurrentStep,
  selectImportError,
  selectImportingData,
  selectImportProgress
} from '../../selectors'
import { IMPORT_STEPS } from '../../constants'

import NotFoundPage from '../../pages/NotFoundPage'

const ImportBlock = ({ navigate, enabled, children }) => {
  const pages = useSelector(selectPages)
  const step = useSelector(selectCurrentStep)
  const importError = useSelector(selectImportError)
  const loading = useSelector(selectImportingData)
  const progress = useSelector(selectImportProgress)

  if (!enabled || !children || !pages) return <NotFoundPage />

  const breadcrumbs = [
    {
      label: 'navigation:import',
      link: '/import'
    }
  ]

  switch (step) {
    case IMPORT_STEPS.CONFIRM:
      breadcrumbs[1] = {
        label: 'navigation:import_confirmation',
        link: '/import/confirmation'
      }
      break
    case IMPORT_STEPS.UPLOAD:
      breadcrumbs[1] = {
        label: 'navigation:import_upload',
        link: '/import/upload'
      }
      break
    case IMPORT_STEPS.DONE:
      breadcrumbs[1] = {
        label: 'navigation:import_done',
        link: '/import/done'
      }
      break

    default:
      break
  }

  const steps = [
    {
      title: 'select_file',
      disabled: step > IMPORT_STEPS.CONFIRM
    },
    {
      title: 'import:confirm',
      disabled: true
    },
    {
      title: 'import:upload',
      subTitle: loading && step === IMPORT_STEPS.UPLOAD ? `${progress}%` : null,
      icon: loading && step === IMPORT_STEPS.UPLOAD ? <LoadingOutlined /> : null,
      disabled: true
    },
    {
      title: 'import:completed',
      disabled: true
    }
  ]

  let status
  if (step === IMPORT_STEPS.DONE) {
    status = importError ? 'error' : 'finish'
  } else {
    status = importError ? 'error' : 'process'
  }

  if (importError) {
    if (step === IMPORT_STEPS.DONE) {
      steps[IMPORT_STEPS.DONE].description = importError
    } else {
      steps[step].description = importError
    }
  }

  const _handleStepClick = (current) => {
    if (current === 0 && step <= IMPORT_STEPS.CONFIRM) navigate('/import')
  }

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
    >
      <Row mb="ML" align="center">
        <Title label='navigation:import' />
      </Row>
      <Row>
        <Steps
          current={step}
          status={status}
          steps={steps}
          handleChange={_handleStepClick}
        />
      </Row>
      { children }
    </PageLayout>
  )
}

export default ImportBlock

ImportBlock.propTypes = {
  navigate: PropTypes.func,
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}
