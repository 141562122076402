import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  LeftSearchMenu,
  RoleControlled,
  selectPages,
  selectQueryParams,
  TextInput,
  UPDATE_FORM
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import { appUsersSearchSchema } from '../../../validation'

const initialValues = {
  app_user_name: '',
  app_user_id: '',
  app_user_manage_id: '',
  app_user_phone: '',
  // min_residencies: '',
  // max_residencies: '',
  // min_contracts: '',
  // max_contracts: '',
  min_income: '',
  max_income: '',
  property_id: '',
  property_name: '',
  room_number: ''
}

const ContractsSearchBar = () => {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)
  const pages = useSelector(selectPages)

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        app_user_name: queryParams.app_user_name,
        app_user_id: queryParams.app_user_id,
        app_user_manage_id: queryParams.app_user_manage_id,
        app_user_phone: queryParams.app_user_phone,
        min_income: queryParams.min_income,
        max_income: queryParams.max_income,
        property_id: queryParams.property_id,
        property_name: queryParams.property_name,
        room_number: queryParams.room_number
      }
    })
  }, [queryParams, dispatch])

  return (
    <>
      <LeftSearchMenu
        resetValues={initialValues}
        validationSchema={appUsersSearchSchema}
      >
        <TextInput
          name="app_user_name"
          label='app_users:app_user_name'
          placeholder="placeholders.partial_user_name"
          mr="0"
        />
        <RoleControlled authorized='!custom.yasue'>
          <TextInput
            type="number"
            name="app_user_id"
            label='app_users:app_user_id'
            placeholder=""
            mr="0"
          />
          <TextInput
            name="app_user_manage_id"
            label={pages?.custom?.chouei ? 'chouei:bellevie_id' : 'app_users:app_user_manage_id' }
            placeholder="placeholders.partial_manage_id"
            mr="0"
          />
        </RoleControlled>
        <TextInput
          name="app_user_phone"
          label='phone'
          placeholder="placeholders.phone"
          mr="0"
        />
        <TextInput
          name="property_name"
          label={pages?.custom?.yasue ? 'yasue:contracts.contract_manage_id' : 'properties:property_name'}
          placeholder={pages?.custom?.yasue ? '' : 'placeholders.partial_property_name'}
          mr="0"
        />
        {/* <TextInput
          type="number"
          name="min_residencies"
          label='app_users:min_residencies'
          placeholder=""
        />
        <TextInput
          type="number"
          name="max_residencies"
          label='app_users:max_residencies'
          placeholder=""
        />
        <TextInput
          type="number"
          name="min_contracts"
          label='app_users:min_contracts'
          placeholder=""
        />
        <TextInput
          type="number"
          name="max_contracts"
          label='app_users:max_contracts'
          placeholder=""
        /> */}
        <RoleControlled authorized='!custom.yasue'>
          <TextInput
            type="number"
            name="min_income"
            label='app_users:min_income'
            suffix='app_users:annual_income_unit'
            placeholder=""
            mr="0"
          />
          <TextInput
            type="number"
            name="max_income"
            label='app_users:max_income'
            suffix='app_users:annual_income_unit'
            placeholder=""
            mr="0"
          />
        </RoleControlled>
        <RoleControlled authorized='!custom.house_maker'>
          <RoleControlled authorized='!custom.yasue'>
            <TextInput
              type="number"
              name="property_id"
              label='properties:property_id'
              placeholder=""
              mr="0"
            />
            <TextInput
              name="room_number"
              label='properties:room_number'
              placeholder=""
              mr="0"
            />
          </RoleControlled>
        </RoleControlled>
      </LeftSearchMenu>
    </>
  )
}

export default ContractsSearchBar

// PropertiesSearchBar.propTypes = {}
