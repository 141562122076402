import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { CancelToken } from 'axios'

import {
  fetchGuests
} from '../../services'
import {
  FETCH_GUESTS,
  FETCH_GUESTS_SUCCESS,
  FETCH_GUESTS_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchGuestsSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchGuests, query, cancelSource)
    if (res && res.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_GUESTS_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_GUESTS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_GUESTS_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchGuests () {
  yield takeLatest(FETCH_GUESTS, fetchGuestsSaga)
}
