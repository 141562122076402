import React from 'react'
import {
  Column,
  Row,
  Text
} from '@gk-devteam/apmc-core-web'
import { InfoCircleOutlined } from '@ant-design/icons'

import {
  ServicesLegendContainerStyle,
  BoldStyle
} from './ServicesLegend.style'

function ServicesLegend () {
  return (
    <ServicesLegendContainerStyle>
      <Row align="center">
        <InfoCircleOutlined />
        <BoldStyle label="services:legend.title"/>
      </Row>
      <Column mt="XS" align="start" pl="R">
        <Row>
          <BoldStyle label="services:display.all_display"/>：
          <Text label="services:legend.all_display"/>
        </Row>
        <Row>
          <BoldStyle label="services:display.no_display"/>：
          <Text label="services:legend.no_display"/>
        </Row>
        <Row>
          <BoldStyle label="services:display.custom_display"/>：
          <Text label="services:legend.custom_display"/>
        </Row>
      </Column>
    </ServicesLegendContainerStyle>
  )
}

export default ServicesLegend
