import { createReducer } from '@reduxjs/toolkit'
import {
  TOGGLE_ADD_INSPECTION_MODAL,
  FETCH_CONTRACT_INSPECTIONS,
  FETCH_CONTRACT_INSPECTIONS_SUCCESS,
  FETCH_CONTRACT_INSPECTIONS_FAIL,
  POST_CONTRACT_INSPECTION,
  POST_CONTRACT_INSPECTION_SUCCESS,
  POST_CONTRACT_INSPECTION_FAIL,
  DELETE_CONTRACT_INSPECTION,
  DELETE_CONTRACT_INSPECTION_SUCCESS,
  DELETE_CONTRACT_INSPECTION_FAIL,
  RESET_CONTRACT_INSPECTIONS
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: false,
  deleting: false,
  error: null,
  addInspectionModal: false,
  data: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_CONTRACT_INSPECTIONS]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_CONTRACT_INSPECTIONS_SUCCESS]: (state, action) => {
    // console.debug('here')
    state.loading = false
    state.data = action.payload
  },
  [FETCH_CONTRACT_INSPECTIONS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_CONTRACT_INSPECTION]: (state, action) => {
    state.posting = true
  },
  [POST_CONTRACT_INSPECTION_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CONTRACT_INSPECTION_FAIL]: (state, action) => {
    state.posting = false
  },
  [DELETE_CONTRACT_INSPECTION]: (state, action) => {
    state.deleting = true
  },
  [DELETE_CONTRACT_INSPECTION_SUCCESS]: (state, action) => {
    state.deleting = false
  },
  [DELETE_CONTRACT_INSPECTION_FAIL]: (state, action) => {
    state.deleting = false
  },
  [TOGGLE_ADD_INSPECTION_MODAL]: (state) => {
    state.addInspectionModal = !state.addInspectionModal
  },
  [RESET_CONTRACT_INSPECTIONS]: () => INITIAL_STATE
})
