import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Confirm,
  PrimaryButtonLinkStyle,
  DangerButton,
  Row,
  Text,
  UPDATE_FORM,
  useTranslation,
  ToastTitle
} from '@gk-devteam/apmc-core-web'
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { selectLegacy, selectContractData } from '../../../../../selectors'

import {
  LegacyContainerStyle,
  LegacyDescriptionStyle,
  LegacyTextStyle
} from '../../ContractForm.style'

function LegacyCard () {
  const { t } = useTranslation()
  const [reset, setReset] = useState(false)
  const dispatch = useDispatch()
  const isLegacy = useSelector(selectLegacy)
  const contract = useSelector(selectContractData)

  if (!isLegacy) return null

  const _handleRevertClick = () => {
    setReset(false)
    if (contract) {
      const { contractor_user_id, contractor } = contract
      dispatch({
        type: UPDATE_FORM,
        payload: {
          different_resident: false,
          contractor_user_id,
          'contractor.last_name': contractor.last_name,
          'contractor.first_name': contractor.first_name,
          'contractor.furigana_last_name': contractor.furigana_last_name,
          'contractor.furigana_first_name': contractor.furigana_first_name,
          'contractor.app_user_manage_id': contractor.app_user_manage_id,
          'contractor.mail_address': contractor.mail_address,
          'contractor.phone': contractor.phone,
          'contractor.emergency_phone': contractor.emergency_phone,
          'contractor.occupation': contractor.occupation,
          'contractor.annual_income': contractor.annual_income,
          'contractor.birthday': contractor.birthday,
          'contractor.sex': contractor.sex,
          'contractor.nationality': contractor.nationality,
          'contractor.residence_card': contractor.residence_card,
          'contractor.home_country_emergency_contact': contractor.home_country_emergency_contact
        }
      })
    }
  }

  const _handleResetClick = () => {
    setReset(true)
    // Set different_resident flag
    dispatch({
      type: UPDATE_FORM,
      payload: {
        different_resident: true,
        contractor_user_id: null,
        'contractor.last_name': '',
        'contractor.first_name': '',
        'contractor.furigana_last_name': '',
        'contractor.furigana_first_name': '',
        'contractor.app_user_manage_id': '',
        'contractor.mail_address': '',
        'contractor.phone': '',
        'contractor.emergency_phone': '',
        'contractor.occupation': '',
        'contractor.annual_income': '',
        'contractor.birthday': null,
        'contractor.sex': '',
        'contractor.nationality': '',
        'contractor.residence_card': '',
        'contractor.home_country_emergency_contact': ''
      }
    })
    // Reset contractor
  }
  return (
    <LegacyContainerStyle>
      <LegacyDescriptionStyle>
        <Row wrap="wrap">
          <InfoCircleOutlined />
          <ToastTitle label="contracts:legacy.title" />
        </Row>
        <Row mt="S">
          <Text label="contracts:legacy.description" />
        </Row>

      </LegacyDescriptionStyle>
      <Row mt="S">
        <LegacyTextStyle label="contracts:legacy.question" />
        {
          reset
            ? <DangerButton handleClick={_handleRevertClick} label="common:cancel" />
            : (
              <Confirm
                title='contracts:legacy.confirm_text'
                placement="topRight"
                onConfirm={_handleResetClick}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                <PrimaryButtonLinkStyle>{t('contracts:legacy.reset_button_label')}</PrimaryButtonLinkStyle>
              </Confirm>
            )
        }
      </Row>
    </LegacyContainerStyle>
  )
}

export default LegacyCard
