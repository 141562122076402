import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  MainLayout
} from '@gk-devteam/apmc-core-web'

import { FETCH_ADMIN_USER, RESET_ADMIN_USER } from '../../../types'
import {
  selectAdminUserData,
  selectUserID
} from '../../../selectors'

import UserProfileForm from '../../../forms/settings/UserProfileForm'

const UserProfilePage = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectAdminUserData)
  const userID = useSelector(selectUserID)

  useEffect(() => {
    if (userID) {
      dispatch({ type: FETCH_ADMIN_USER, id: userID })
    }
    return () => {
      dispatch({ type: RESET_ADMIN_USER })
    }
  }, [dispatch, userID])

  const breadcrumbs = [
    {
      label: 'navigation:settings',
      link: '/settings'
    },
    {
      label: 'navigation:settings_user_profile',
      link: '/settings/'
    }
  ]

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      // loading={loading}
      // lastEdited={lastEdited}
      // lastEditedBy={lastEditedBy}
    >
      <UserProfileForm data={data}/>
    </MainLayout>
  )
}
export default UserProfilePage

UserProfilePage.propTypes = {
}
UserProfilePage.defaultProps = {}
