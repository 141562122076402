import React from 'react'
import { useSelector } from 'react-redux'
import { InfoButton } from '@gk-devteam/apmc-core-web'
import { useNavigate } from '@reach/router'

import { selectBillingFormFile } from '../../../selectors'

export default function ConfirmButton () {
  const navigate = useNavigate()
  const file = useSelector(selectBillingFormFile)
  const errors = useSelector(state => state?.formErrors?.billing_file)

  if (!file || errors?.length > 0) return null

  const _handleConfirmClick = () => {
    navigate('/billing/confirm', { state: { file } })
  }

  return (
    <InfoButton
      // form="uploadDatasyncData"
      reverse
      label="import:confirm"
      type="button"
      handleClick={_handleConfirmClick}
    />
  )
}
