import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Row,
  Text
} from '@gk-devteam/apmc-core-web'

import { SeikatsuServiceStyle, ImageStyle } from './Seikatsu.style'

const SeikatsuCard = ({ item, handleToggle }) => {
  const {
    id,
    enable,
    image,
    name,
    items
  } = item

  const _handleSeikatsuToggle = useCallback(
    () => {
      handleToggle(id, !enable)
    },
    [id, enable, handleToggle]
  )

  const _renderItems = () => {
    if (!items) return null
    return items.map(service => {
      const { id, image, name } = service
      return (
        <SeikatsuServiceStyle key={id}>
          <ImageStyle src={image}/>
          <Text text={name} />
        </SeikatsuServiceStyle>
      )
    })
  }
  return (
    <Card
      title={name}
      titleImage={image}
      mt="L"
      checked={enable}
      handleToggle={_handleSeikatsuToggle}
      toggleName={name}
      authorized="services.functions.update"
    >
      <Row wrap="wrap" align="center">
        { _renderItems() }
      </Row>
    </Card>
  )
}

export default memo(SeikatsuCard)

SeikatsuCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    enable: PropTypes.bool.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })).isRequired
  }).isRequired,
  handleToggle: PropTypes.func
}
