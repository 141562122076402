/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const checklistSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    contract_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:contract_id')),
    app_user_name: yup
      .string()
      .nullable()
      .label(i18n.t('contracts:resident_name')),
    property_name: yup
      .string()
      .nullable()
      .label(i18n.t('properties:property_name')),
    status: yup
      .mixed()
      .nullable()
      .label(i18n.t('common:status')),
    checklist_category_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('common:genre')),
    room_number: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('properties:room_number')),
    repair: yup
      .mixed()
      .oneOf(['true', 'false', ''])
      .nullable()
      .label(i18n.t('admin_tasks:checklist_repair.flag'))
  })
}
