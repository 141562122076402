import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchSpaceReservations,
  fetchSpaces
} from '../../services'
import {
  FETCH_SPACES,
  FETCH_SPACES_SUCCESS,
  FETCH_SPACES_FAIL,
  FETCH_SPACES_RESERVATIONS_SUCCESS,
  FETCH_SPACES_RESERVATIONS_FAIL,
  FETCH_SPACES_RESERVATIONS,
  FETCH_SPACES_WAITING_RESERVATIONS,
  FETCH_SPACES_WAITING_RESERVATIONS_FAIL,
  FETCH_SPACES_WAITING_RESERVATIONS_SUCCESS
} from '../../types'
import { i18n } from '../../locales'
import { SPACE_RESERVATION_STATUS } from '../../constants'

export function * fetchSpacesSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchSpaces, query, cancelSource)
    if (res && res.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_SPACES_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_SPACES_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_SPACES_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * fetchSpacesReservationsSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchSpaceReservations, query, cancelSource)
    if (res?.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_SPACES_RESERVATIONS_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_SPACES_RESERVATIONS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_SPACES_RESERVATIONS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * fetchSpacesWaitingReservationsSaga () {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchSpaceReservations, { status: SPACE_RESERVATION_STATUS.UNCONFIRMED }, cancelSource)
    if (res?.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_SPACES_WAITING_RESERVATIONS_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_SPACES_WAITING_RESERVATIONS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_SPACES_WAITING_RESERVATIONS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchSpaces () {
  yield takeLatest(FETCH_SPACES, fetchSpacesSaga)
  yield takeLatest(FETCH_SPACES_RESERVATIONS, fetchSpacesReservationsSaga)
  yield takeLatest(FETCH_SPACES_WAITING_RESERVATIONS, fetchSpacesWaitingReservationsSaga)
}
