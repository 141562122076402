import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_COUPONS,
  FETCH_COUPONS_SUCCESS,
  FETCH_COUPONS_FAIL,
  OPEN_COUPON_STATUS_MODAL,
  CLOSE_COUPON_STATUS_MODAL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  max_pages: null,
  result_count: null,
  statusModal: false,
  results: [],
  activeStatus: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_COUPONS]: (state, action) => {
    if (action && !action.noLoading) {
      state.loading = true
    }
    state.error = null
  },
  [FETCH_COUPONS_SUCCESS]: (state, action) => {
    // console.debug('here')
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_COUPONS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [OPEN_COUPON_STATUS_MODAL]: (state, action) => {
    state.statusModal = true
    state.activeStatus = action.payload
  },
  [CLOSE_COUPON_STATUS_MODAL]: (state, action) => {
    state.statusModal = false
    state.activeStatus = null
  }
})
