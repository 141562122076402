
import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  MainLayout
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyData
} from '../../../selectors'

import PropertyForm from '../../../forms/PropertyForm'

const PropertyDetailsPage = ({ propertyID }) => {
  const data = useSelector(selectPropertyData)

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: (data && data.name) || '',
      link: `/properties/edit/${propertyID}`
    }
  ]

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={!data}
      lastEdited={data && data.modified}
      lastEditedBy={data && data.modifiedBy}
    >
      <PropertyForm data={data} propertyID={propertyID}/>
    </MainLayout>
  )
}
export default PropertyDetailsPage

PropertyDetailsPage.propTypes = {
  propertyID: PropTypes.string.isRequired
}
PropertyDetailsPage.defaultProps = {

}
