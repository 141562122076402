import { api, MULTIPART_HEADERS, getFileFromURL } from '@gk-devteam/apmc-core-web'

export const fetchManuals = (config, cancelSource) => {
  const { property, room } = config
  let path = 'admin'
  if (property) path = `properties/${property}`
  if (room) path = `rooms/${room}`

  return api.get(`${path}/manuals`, {
    cancelToken: cancelSource.token
  })
}

export const postManual = async (data, config, cancelSource) => {
  const { property, room } = config
  let path = 'admin'
  if (property) path = `properties/${property}`
  if (room) path = `rooms/${room}`

  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing document')
      }
    }

    delete data.file
    delete data.file_url
    delete data.file_filename
    delete data.delete_file

    formData.append('data', JSON.stringify(data))
    return api.post(`${path}/manuals`, formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const deleteManual = (id, config, cancelSource) => {
  const { property, room } = config
  let path = 'admin'
  if (property) path = `properties/${property}`
  if (room) path = `rooms/${room}`

  return api.delete(`${path}/manuals/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const toggleManual = (data, config, cancelSource) => {
  const { property, room, type } = config
  // type: admin_manuals, property_manuals, room_manuals
  let path = 'admin/manuals'
  if (property && type) path = `properties/${property}/${type}`
  if (room && type) path = `rooms/${room}/${type}`

  // console.log('path', path)
  // console.log('data', data)

  return api.post(`${path}/display`, data, {
    cancelToken: cancelSource.token
  })
}
export const bulkToggleManuals = (data, config, cancelSource) => {
  const { property, room, type } = config
  // type: admin_manuals, property_manuals, room_manuals
  let path = 'admin/manuals'
  if (property && type) path = `properties/${property}/${type}`
  if (room && type) path = `rooms/${room}/${type}`

  // console.log('path', path)
  // console.log('data', data)

  return api.post(`${path}/bulk_display`, data, {
    cancelToken: cancelSource.token
  })
}
