import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Radio, Row, Tree, UPDATE_FORM_ARRAY } from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import { selectAppUsersAvailableRecipients, selectAppUsersAvailableRecipientsLoading, selectAreasTree } from '../../../selectors'
import { FETCH_APP_USERS_AVAILABLE_RECIPIENTS, FETCH_AREAS, RESET_APP_USERS_AVAILABLE_RECIPIENTS, RESET_AREAS } from '../../../types'
import { APP_USERS_LOGIN_PDF_TYPE, APP_USERS_LOGIN_PDF_TYPE_OPTIONS, LOGIN_STATUS } from '../../../constants'

import RecipientsSearch from './RecipientsSearch'
import RecipientsList from './RecipientsList'
import RecipientsSelected from './RecipientsSelected'
import { BetweenStyle } from '../AppUsersDownloadLoginPdfForm.style'

const MAX_RESULTS = 25

function Recipients () {
  const dispatch = useDispatch()
  const prevTypeValue = useRef(null)
  const areasTree = useSelector(selectAreasTree)
  const recipientType = useSelector(state => state.form.type)

  const fetchData = useCallback(
    (query) => {
      if (APP_USERS_LOGIN_PDF_TYPE.AREA) {
        dispatch({ type: FETCH_AREAS })
      }

      if ([APP_USERS_LOGIN_PDF_TYPE.PROPERTIES, APP_USERS_LOGIN_PDF_TYPE.RESIDENTS].includes(recipientType)) {
        let params
        if (recipientType === APP_USERS_LOGIN_PDF_TYPE.PROPERTIES) {
          params = {
            id: query?.id || '',
            property_name: query?.name || '',
            property_manage_id: query?.manageID || '',
            limit: MAX_RESULTS
          }
        }
        if (recipientType === APP_USERS_LOGIN_PDF_TYPE.RESIDENTS) {
          params = {
            app_user_id: query?.id || '',
            app_user_name: query?.name || '',
            app_user_manage_id: query?.manageID || '',
            login_status: LOGIN_STATUS.NO_LOGIN,
            limit: MAX_RESULTS
          }
        }

        dispatch({ type: FETCH_APP_USERS_AVAILABLE_RECIPIENTS, params, recipientType })
      }
    },
    [recipientType, dispatch]
  )

  useEffect(() => {
    fetchData()

    return () => {
      dispatch({ type: RESET_AREAS })
      dispatch({ type: RESET_APP_USERS_AVAILABLE_RECIPIENTS })
    }
  }, [recipientType, dispatch, fetchData])

  useEffect(() => {
    if (prevTypeValue.current == null) {
      prevTypeValue.current = recipientType
    } else {
      if (prevTypeValue.current !== recipientType) {
        prevTypeValue.current = recipientType
        dispatch({
          type: UPDATE_FORM_ARRAY,
          payload: [
            {
              name: 'ids',
              values: null
            },
            {
              name: 'recipients_list',
              values: null
            }
          ]
        })
      }
    }
  }, [recipientType, dispatch, prevTypeValue])

  const _renderRecipientsSelection = () => {
    switch (recipientType) {
      case APP_USERS_LOGIN_PDF_TYPE.ALL:
        return null
      case APP_USERS_LOGIN_PDF_TYPE.AREA:
        return (
          <Row wrap="wrap" align="baseline" mt="R">
            <Tree
              treeData={areasTree}
              name="ids"
              idsOnly
            />
          </Row>
        )
      case APP_USERS_LOGIN_PDF_TYPE.PROPERTIES:
      case APP_USERS_LOGIN_PDF_TYPE.RESIDENTS:
        return (
          <>
            <RecipientsSearch
              recipientType={recipientType}
              fetchData={fetchData}
            />
            <Row align="start">
              <RecipientsList
                recipientType={recipientType}
                loadingSelector={selectAppUsersAvailableRecipientsLoading}
                dataSelector={selectAppUsersAvailableRecipients}
              />
              <BetweenStyle> {'>>'} </BetweenStyle>
              <RecipientsSelected />
            </Row>
          </>
        )
      default:
        return null
    }
  }

  return (
    <>
      <Radio
        name="type"
        options={APP_USERS_LOGIN_PDF_TYPE_OPTIONS}
      />
      { _renderRecipientsSelection() }
    </>
  )
}

Recipients.propTypes = {}

export default Recipients
