import React, { memo, useCallback, useRef, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from '@reach/router'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  LEFT_INFO,
  useTranslation,
  getTimeElapsed,
  dynamicLink,
  SPACING,
  SERVER_TIMESTAMP_FORMAT,
  TablePagination,
  Tag,
  Text,
  ToastTitle,
  Row,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  selectChatLoading,
  selectFilteredChatRooms,
  selectFilteredChatRoomsCount
} from '../../../selectors'
import { SUPPORT_STATUS, USER_TYPES, USER_EMPTY_NAME } from '../../../constants'

import { TableStyle } from '../adminTasks.style'

const ChatList = ({ queryParams }) => {
  const rowRef = useRef(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [tableMaxWidth, setTableMaxWidth] = useState(0)
  const pages = useSelector(selectPages)

  useEffect(() => {
    handleTableWidth()
    window.addEventListener('resize', handleTableWidth, false)
    return () => {
      window.removeEventListener('resize', handleTableWidth, false)
    }
  }, [])
  const handleTableWidth = () => {
    const row = rowRef.current
    if (row) {
      const pageWidth = document.documentElement.clientWidth
      const maxWidth = pageWidth - LEFT_INFO - (SPACING.LARGE * 14) - (3.5 * 2 * 14)
      setTableMaxWidth(maxWidth)
    }
  }

  const dataSelector = (state) => {
    return selectFilteredChatRooms(state, queryParams)
  }
  const countSelector = (state) => {
    return selectFilteredChatRoomsCount(state, queryParams)
  }

  const statusCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      let label, color
      switch (data.managementStatus) {
        case SUPPORT_STATUS.ARCHIVE:
          label = 'admin_tasks:status.archive'
          color = 'accent'
          break
        case SUPPORT_STATUS.UNDEFINED:
          label = 'admin_tasks:status.undefined'
          color = 'pageTitle'
          break
        case SUPPORT_STATUS.UNUSED:
          label = 'admin_tasks:status.is_unused'
          color = 'text'
          break
        case SUPPORT_STATUS.COMPLETED:
          label = 'admin_tasks:status.is_completed'
          color = 'success'
          break
        case SUPPORT_STATUS.CONFIRMED:
          label = 'admin_tasks:status.is_confirmed'
          color = 'info'
          break
        case SUPPORT_STATUS.WAITING:
          label = 'admin_tasks:status.is_waiting'
          color = 'danger'
          break
        default:
          break
      }

      return <Tag label={label} color={color} />
    },
    []
  )
  const userNameCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const name = value ? value.replace(/ null$/, '') : USER_EMPTY_NAME
      return <ToastTitle text={name} />
    },
    []
  )
  const userTypeCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      let label, color
      switch (data.userType) {
        case USER_TYPES.RESIDENT_CONTRACTOR:
          label = 'user_type.resident_contractor'
          color = 'accent'
          break
        case USER_TYPES.CONTRACTOR:
          label = 'user_type.contractor'
          color = 'info'
          break
        case USER_TYPES.RESIDENT:
          label = 'user_type.resident'
          color = 'success'
          break

        default:
          label = ''
          color = 'text'
          break
      }
      return <Tag label={label} color={color} />
    },
    []
  )
  const elapsedCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const { lastUpdated } = data
      return (
        <Tooltip
          title={dayjs.unix(lastUpdated.seconds).format(SERVER_TIMESTAMP_FORMAT)}
          overlayStyle={{
            fontSize: `${SPACING.SMALL}rem`
          }}
          placement="top"
        >
          <Text text={getTimeElapsed(dayjs.unix(lastUpdated.seconds))} />
        </Tooltip>
      )
    },
    []
  )

  const columns = [
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: statusCell
    },
    {
      title: pages?.custom?.yasue ? t('yasue:contracts.contractor_name') : t('app_users:app_user_name'),
      dataIndex: 'userName',
      key: 'userName',
      render: userNameCell
    },
    {
      title: t('admin_tasks:user_type'),
      dataIndex: 'isResident',
      key: 'isResident',
      render: userTypeCell,
      authorized: '!custom.yasue'
    },
    {
      title: pages?.custom?.yasue ? t('yasue:contracts.contract_manage_id') : t('properties:property_name'),
      dataIndex: 'propertyName',
      key: 'propertyName'
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'roomNumber',
      key: 'roomNumber',
      authorized: '!custom.yasue'
    },
    {
      title: t('elapsed'),
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: elapsedCell
    }

  ]

  const _handleWaitingRoomRow = useCallback(
    (data) => {
      return data && data.managementStatus && data.managementStatus === SUPPORT_STATUS.WAITING
    },
    []
  )

  const navigateToRoom = (id, row, e) => {
    const url = `/admin_tasks/chat/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <div ref={rowRef}>
      <TableStyle
        fullHeight
        tableMaxWidth={tableMaxWidth}
        columns={columns}
        rowKey="id"
        loadingSelector={selectChatLoading}
        dataSelector={dataSelector}
        onRowClick={navigateToRoom}
        dangerRow={_handleWaitingRoomRow}
        noHeader
        noFooter
      />
      <Row justify="center" mt="R">
        <TablePagination
          resultsCountSelector={countSelector}
        />
      </Row>
    </div>
  )
}

export default memo(ChatList)

ChatList.propTypes = {
  tableMaxWidth: PropTypes.number,
  queryParams: PropTypes.object
}
