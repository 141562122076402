import { SPACING, styled } from "@gk-devteam/apmc-core-web";

export const ImageContainerStyle = styled.div`
  width: ${SPACING.LARGEST * 3}rem;
  height: ${SPACING.LARGEST * 3}rem;
  margin: ${SPACING.SMALL / 2}rem;

  & > div {
    width: 100%;
    height: 100%;
  }
`
