import { createSelector } from 'reselect'

const selectContractOpelo = state => state.opelo

export const selectContractOpeloLoading = createSelector(
  selectContractOpelo,
  opelo => opelo.loading
)
export const selectContractOpeloPosting = createSelector(
  selectContractOpelo,
  opelo => opelo.posting
)
export const selectContractOpeloData = createSelector(
  selectContractOpelo,
  opelo => opelo.data
)
