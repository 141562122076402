
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  getFileExtension,
  getFileFromURL,
  MainLayout,
  useTranslation
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyData,
  selectPropertyName
} from '../../../selectors'

import PropertySpaceForm from '../../../forms/PropertySpaceForm/PropertySpaceForm'
import { fetchSpace } from '../../../services'
import { FETCH_SPACE_SUCCESS, RESET_SPACE } from '../../../types'

const initialData = {
  name: null,
  description: null,
  type: null,
  reservable_from_days: null,
  reservable_from_time: null,
  reservable_to_time: null,
  usage_limit: 4,
  file: null,
  delete_file: null
}

const PropertySpaceAddPage = ({ location, propertyID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(null)
  const data = useSelector(selectPropertyData)
  const propertyName = useSelector(selectPropertyName)

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_spaces',
      link: `/properties/edit/${propertyID}/spaces`
    },
    {
      label: 'navigation:properties_spaces_add',
      link: `/properties/edit/${propertyID}/spaces/add`
    }
  ]

  const prepareData = useCallback(
    async (data) => {
      if (data?.space_id) {
        const editedData = { ...data }
        editedData.delete_file = false
        delete editedData.space_id

        if (editedData.file_url) {
          try {
            const blob = await getFileFromURL(data.file_url)
            const extension = getFileExtension(data.file_url)
            const blobURL = URL.createObjectURL(blob)
            editedData.file = blobURL
            editedData.file_filename = `space.${extension}`
          } catch (error) {
            console.warning('error retrieving file blob', error)
          }
        }
        setFormData(editedData)
      }
    },
    []
  )

  const fetchData = useCallback(
    async () => {
      const cancelSource = CancelToken.source()
      if (location?.state?.copy) {
        setLoading(true)
        try {
          const res = await fetchSpace(location.state.id, cancelSource)
          setLoading(false)
          if (res && res.data) {
            prepareData(res.data)
            dispatch({ type: FETCH_SPACE_SUCCESS, payload: null })
          } else {
            message.error(t('fetch_error'))
          }
        } catch (error) {
          setLoading(false)
          message.error(t('fetch_error'))
        }
      } else {
        setFormData({ ...initialData, property_id: propertyID })
        dispatch({ type: FETCH_SPACE_SUCCESS, payload: null })
      }
    },
    [location, dispatch, prepareData, t, propertyID]
  )

  useEffect(() => {
    fetchData()
    return () => {
      dispatch({ type: RESET_SPACE })
    }
  }, [location, dispatch, fetchData])

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={!data || loading}
    >
      {
        formData
          ? <PropertySpaceForm propertyID={propertyID} data={formData}/>
          : null
      }
    </MainLayout>
  )
}
export default PropertySpaceAddPage

PropertySpaceAddPage.propTypes = {
  location: PropTypes.object,
  propertyID: PropTypes.string.isRequired
}
PropertySpaceAddPage.defaultProps = {

}
