import {
  styled,
  SPACING
} from '@gk-devteam/apmc-core-web'

export const DisplayAllStyle = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-left: ${SPACING.SMALLEST}rem;
  }
`
