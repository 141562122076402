import { UUID } from '@gk-devteam/apmc-core-web'
import dot from 'dot-object'
import { createSelector } from 'reselect'

const selectSurvey = state => state.survey
const selectForm = state => state.form

export const selectSurveyLoading = createSelector(
  selectSurvey,
  survey => survey.loading
)
export const selectSurveyPosting = createSelector(
  selectSurvey,
  survey => survey.posting
)
export const selectSurveyError = createSelector(
  selectSurvey,
  survey => survey.error
)
export const selectSurveyData = createSelector(
  selectSurvey,
  // survey => survey.data || {}
  survey => {
    return survey.data ? { ...survey.data, uuid: UUID() } : {} // TODO* check this when doing survey edit page
  }
)
export const selectSurveyID = createSelector(
  selectSurvey,
  survey => survey.data && survey.data.id
)
export const selectSurveyEditable = createSelector(
  selectSurvey,
  survey => survey?.data?.editable ?? true
)

export const selectSurveyFormQuestions = createSelector(
  selectForm,
  form => {
    const data = form && dot.object({ ...form })
    return data?.questions
  }
)
export const selectSurveyQuestions = createSelector(
  selectSurvey,
  survey => survey?.data?.questions
)
export const selectSurveyHasAnswers = createSelector(
  selectSurveyQuestions,
  questions => questions?.some(question => question.answers && question.answers.length > 0)
)

export const selectSurveyAnswerUsersLoading = createSelector(
  selectSurvey,
  survey => survey.loading_answer_users
)
export const selectSurveyAnswerUsersError = createSelector(
  selectSurvey,
  survey => survey.answer_users_error
)
export const selectSurveyAnswerUsers = createSelector(
  selectSurvey,
  // survey => survey.data || {}
  survey => {
    return survey.answer_users
  }
)
