import { createReducer } from '@reduxjs/toolkit'

import {
  POST_DATASYNC,
  POST_DATASYNC_SUCCESS,
  POST_DATASYNC_FAIL
} from '../../types'

const INITIAL_STATE = {
  posting: false,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_DATASYNC]: (state, action) => {
    state.posting = true
    state.error = null
  },
  [POST_DATASYNC_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_DATASYNC_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  }
})
