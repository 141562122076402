export const FETCH_INSPECTIONS = 'fetch_inspections'
export const FETCH_INSPECTIONS_SUCCESS = 'fetch_inspections_success'
export const FETCH_INSPECTIONS_FAIL = 'fetch_inspections_fail'
export const RESET_INSPECTIONS = 'reset_inspections'

export const FETCH_CONTRACT_INSPECTIONS = 'fetch_contract_inspections'
export const FETCH_CONTRACT_INSPECTIONS_SUCCESS = 'fetch_contract_inspections_success'
export const FETCH_CONTRACT_INSPECTIONS_FAIL = 'fetch_contract_inspections_fail'
export const RESET_CONTRACT_INSPECTIONS = 'reset_contract_inspections'

export const POST_CONTRACT_INSPECTION = 'post_contract_inspection'
export const POST_CONTRACT_INSPECTION_SUCCESS = 'post_contract_inspection_success'
export const POST_CONTRACT_INSPECTION_FAIL = 'post_contract_inspection_fail'

export const DELETE_CONTRACT_INSPECTION = 'delete_contract_inspection'
export const DELETE_CONTRACT_INSPECTION_SUCCESS = 'delete_contract_inspection_success'
export const DELETE_CONTRACT_INSPECTION_FAIL = 'delete_contract_inspection_fail'

export const TOGGLE_ADD_INSPECTION_MODAL = 'toggle_add_inspection_modal'
