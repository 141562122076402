import React from 'react'
import PropTypes from 'prop-types'
import {
  Column,
  FormControlLabel,
  FormReadOnlyLink,
  LightText,
  Modal,
  Row,
  Text,
  useTranslation
} from '@gk-devteam/apmc-core-web'

import { MessageStyle } from './Contacts.style'
import dayjs from 'dayjs'
import { YASUE_MAINTENANCE_REQUESTS } from '../../../constants/config'

export default function ContactModal ({ contact, visible, close }) {
  const { t } = useTranslation()

  return (
    <Modal
      visible={visible}
      onCancel={close}
      cancelText="close"
      title={t('yasue:contacts.message_from', { name: contact?.contractor_name })}
      fullWidth
      fullHeight
      large
    >
      <Row align="baseline">
        <FormReadOnlyLink
          label={'common:user_type.contractor'}
          to={`/contracts/edit/${contact?.contract_id}`}
          text={contact?.contractor_name}
        />
        <div>
          <LightText text={contact?.sent}/>
        </div>
      </Row>
      {
        contact?.yasue_construction_work_id && (
          <Row align="baseline">
            <FormReadOnlyLink
              label={'yasue:construction.work_number'}
              to={`/yasue/construction_works/edit/${contact?.yasue_construction_work_id}`}
              text={contact?.work_number}
            />
          </Row>
        )
      }
      <Row mt="M">
        <FormControlLabel label={t('yasue:contacts.next_maintenance_date_message')} />
      </Row>
      <div>
        {
          contact?.next_maintenance_date
            ? <Row><Text text={t('yasue:contacts.next_maintenance_date', { date: dayjs(contact?.next_maintenance_date).isValid() ? dayjs(contact?.next_maintenance_date).format('MM月DD日') : '' })} /></Row>
            : <Row mt="XS"><Text label='yasue:contacts.no_next_maintenance' /></Row>
        }
      </div>
      <Row mt="M">
        <FormControlLabel label={t('yasue:contacts.request')} />
      </Row>
      <div>
        {contact?.request === YASUE_MAINTENANCE_REQUESTS.repair && <Row mt="XS"><Text label='yasue:contacts.requests.repair' /></Row>}
        {contact?.request === YASUE_MAINTENANCE_REQUESTS.advice && <Row mt="XS"><Text label='yasue:contacts.requests.advice' /></Row>}
        {contact?.request === YASUE_MAINTENANCE_REQUESTS.catalog && <Row mt="XS"><Text label='yasue:contacts.requests.catalog' /></Row>}
      </div>
      <Column mt="XS">
        {Array.isArray(contact?.catalogs) && contact?.catalogs?.map((catalog, i) => <Text text={catalog} key={i} />)}
      </Column>
      <Row mt="M">
        <FormControlLabel label={t('yasue:contacts.message')}/>
      </Row>
      <Row mt="XS">
        <MessageStyle>{contact?.message}</MessageStyle>
      </Row>
    </Modal>
  )
}

ContactModal.propTypes = {
  contact: PropTypes.object,
  close: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
}
