import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import {
  useTranslation,
  PageLayout,
  ItemNotFound,
  PageLoader,
  selectQueryParams,
  RESET_PARAMS
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_COUPON,
  RESET_COUPON
} from '../../../../types'
import {
  selectCouponFormData,
  selectLoadingCoupon,
  selectCouponError
} from '../../../../selectors'

import CouponForm from '../../../../forms/CouponForm'
import CouponHistory from './CouponHistory'
import CouponStatusModal from './CouponStatusModal'

const CouponDetailPage = ({ location, couponID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const query = useSelector(selectQueryParams)
  const data = useSelector(selectCouponFormData)
  const loading = useSelector(selectLoadingCoupon)
  const error = useSelector(selectCouponError)
  const prevQuery = useRef(null)

  const fetchData = useCallback(() => {
    dispatch({ type: FETCH_COUPON, id: couponID, query })
  }, [dispatch, couponID, query])

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_COUPON })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const breadcrumbs = [
    {
      label: 'navigation:inui.coupons_list',
      link: '/coupons'
    },
    {
      text: `${data?.title || ''}`,
      link: `/coupons/edit/${couponID}`
    }
  ]

  return loading
    ? <PageLoader />
    : error && error.code === 404
      ? <ItemNotFound item={t('navigation:coupons')} link="/coupons" />
      : (
        <PageLayout
          breadcrumbs={breadcrumbs}
          loading={!data}
          lastEdited={data && data.modified}
          lastEditedBy={data && data.modifiedBy}
        >
          <CouponForm data={data} couponID={couponID} editable={false}/>
          <CouponHistory couponID={couponID}/>
          <CouponStatusModal />
        </PageLayout>
      )
}
export default CouponDetailPage

CouponDetailPage.propTypes = {
  location: PropTypes.object.isRequired,
  couponID: PropTypes.string.isRequired
}
CouponDetailPage.defaultProps = {

}
