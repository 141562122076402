import {
  styled,
  SPACING,
  Row,
  Column
} from '@gk-devteam/apmc-core-web'

export const GuideImageStyle = styled.div`
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
  margin-right: ${SPACING.REGULAR}rem;
  box-sizing: border-box;
`

export const DescriptionStyle = styled.p`
  margin: 0;
`

export const GuideInfoContainerStyle = styled(Row)`
  margin-right: ${SPACING.REGULAR}rem;
`
export const GuideInfoStyle = styled(Column)`
  flex: 1;
  align-items: flex-start;

  span:last-of-type {
    margin-top: ${SPACING.SMALLEST}rem;
    padding-left: ${SPACING.SMALLEST}rem;
  }
`
