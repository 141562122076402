import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dot from 'dot-object'
import {
  FormControlLabel,
  useTranslation,
  PrimaryButton,
  FormControlError,
  Column,
  RemoveButton,
  TOGGLE_ARRAY_ITEM
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { MEETING_ROLES } from '../../../constants'

import PropertySettingsInspectorsModal from './PropertySettingsInspectorsModal'
import { BoardUserStyle, LinkStyle } from '../PropertyForm.style'

const PropertySettingsInspectorItem = ({ inspector }) => {
  const dispatch = useDispatch()
  if (!inspector) return null

  const { app_user_id, app_user_name } = inspector
  const _handleResetInspector = () => {
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [{
        key: 'meeting_users',
        value: {
          app_user_id,
          app_user_name,
          role: MEETING_ROLES.INSPECTOR
        },
        objectComparatorKey: 'app_user_id'
      }]
    })
  }

  return (
    <BoardUserStyle>
      <LinkStyle
        text={app_user_name}
        to={`/app_users/edit/${app_user_id}`}
      />
      <RemoveButton
        label="delete"
        handleClick={_handleResetInspector}
      />
    </BoardUserStyle>
  )
}

PropertySettingsInspectorItem.propTypes = {
  inspector: PropTypes.shape({
    app_user_id: PropTypes.number.isRequired,
    app_user_name: PropTypes.string.isRequired
  }).isRequired
}

const PropertySettingsInspectors = ({ loading, users }) => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  const inspectors = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    return form?.meeting_users?.filter(user => user.role === MEETING_ROLES.INSPECTOR) || []
  })

  const _openModal = () => {
    setModalVisible(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
  }

  return (
    <>
      <PropertySettingsInspectorsModal
        modalVisible={modalVisible}
        closeModal={_closeModal}
        loading={loading}
        users={users}
        inspectors={inspectors}
      />
      <Column mt="R">
        <FormControlLabel label={t('properties:documents.inspector')} />
        <Column align="start" justify="start">
          { inspectors?.map((inspector, i) => <PropertySettingsInspectorItem key={i} inspector={inspector} />) }
          <PrimaryButton
            label={'properties:select_inspector'}
            type="button"
            onClick={_openModal}
          />
        </Column>
        <FormControlError name="meeting_users" />
      </Column>
    </>
  )
}

export default PropertySettingsInspectors

PropertySettingsInspectors.propTypes = {
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array
}
