import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useTranslation,
  Table,
  TOGGLE_ARRAY_ITEM,
  Tag,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  selectAppUsersLoading,
  selectAppUsersData
} from '../../../../../selectors'

import UserCheckmark from './UserCheckmark'
import { TableContainerStyle } from '../../../NoticeForm.style'

const UsersList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)

  const tagCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      if (!value) {
        return <Tag label="app_users:app_not_usable" color="text"/>
      }
      return null
    },
    []
  )

  const usersColumns = [
    {
      title: '',
      dataIndex: '',
      key: 'selected_user',
      RenderComponent: UserCheckmark
    },
    {
      title: t('app_users:user_name'),
      dataIndex: 'app_user_name',
      key: 'app_user_name',
      cellTitle: true
    },
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t(pages?.custom?.chouei ? 'chouei:bellevie_id' : 'manage_id'),
      dataIndex: 'app_user_manage_id',
      key: 'app_user_manage_id'
    },
    {
      title: '',
      dataIndex: 'app_usable',
      key: 'app_usable',
      render: tagCell
    }
  ]

  const _handleDisableRow = useCallback(
    (data) => {
      return data && data.app_usable === false
    },
    []
  )

  const _handleRowClick = (_, row, e) => {
    const { id, app_user_name } = row
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [
        {
          key: 'recipients_list',
          value: { id, label: app_user_name },
          objectComparatorKey: 'id'
        },
        {
          key: 'recipients_id',
          value: id
        }
      ]
    })
  }

  return (
    <TableContainerStyle>
      <Table
        columns={usersColumns}
        rowKey="id"
        loadingSelector={selectAppUsersLoading}
        dataSelector={selectAppUsersData}
        onRowClick={_handleRowClick}
        disableRow={_handleDisableRow}
        leftStickyColumnCount={0}
        rightStickyColumnCount={0}
        noHeader
        noFooter
        noLoader
      />
    </TableContainerStyle>
  )
}

export default memo(UsersList)

// UsersList.propTypes = {}
