import { createSelector } from 'reselect'

const selectPropertiesGroup = state => state.propertiesGroup

export const selectLoadingPropertiesGroup = createSelector(
  selectPropertiesGroup,
  propertiesGroup => propertiesGroup.loading
)
export const selectPostingPropertiesGroup = createSelector(
  selectPropertiesGroup,
  propertiesGroup => propertiesGroup.posting
)
export const selectPropertiesGroupError = createSelector(
  selectPropertiesGroup,
  propertiesGroup => propertiesGroup.error
)
export const selectPropertiesGroupData = createSelector(
  selectPropertiesGroup,
  propertiesGroup => propertiesGroup.data || {}
)
export const selectPropertiesGroupID = createSelector(
  selectPropertiesGroup,
  propertiesGroup => propertiesGroup.data && propertiesGroup.data.id
)
export const selectPropertiesGroupName = createSelector(
  selectPropertiesGroup,
  propertiesGroup => propertiesGroup.data && propertiesGroup.data.name
)
