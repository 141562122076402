/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const chatSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    userName: yup
      .string()
      .nullable()
      .label(i18n.t('app_users:app_user_name')),
    propertyName: yup
      .string()
      .nullable()
      .label(i18n.t('properties:property_name')),
    isResident: yup
      .mixed()
      .nullable()
      .label(i18n.t('admin_tasks:user_type')),
    status: yup
      .mixed()
      .nullable()
      .label(i18n.t('common:status'))
  })
}
