import React, { useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import {
  useTranslation,
  dynamicLink,
  RESET_PARAMS,
  selectQueryParams,
  MainLayout,
  Row,
  Title,
  Image,
  PrimaryButton,
  Table,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_GUESTS,
  RESET_GUESTS,
  DELETE_GUEST,
  SEND_GUEST_EMAIL
} from '../../../types'
import {
  selectGuestsLoading,
  selectGuestsCount,
  selectGuestsData,
  selectPages
} from '../../../selectors'

import { AvatarContainerStyle } from '../common.style'

const breadcrumbs = [
  {
    label: 'navigation:management',
    link: '/user_management'
  },
  {
    label: 'navigation:management_guests_list',
    link: '/user_management/'
  }
]

const GuestsPage = ({ navigate, location }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const { t } = useTranslation(['common', 'user_management'])
  const prevQuery = useRef(null)
  const pages = useSelector(selectPages)

  const fetchData = useCallback(
    () => {
      dispatch({ type: FETCH_GUESTS, query })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_GUESTS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const guestAvatarCell = useCallback(
    (value, cellKey, rowID, className) => {
      return (
        <AvatarContainerStyle>
          <Image src={value} contain/>
        </AvatarContainerStyle>
      )
    },
    []
  )

  const _handleCopy = useCallback(
    (id, close) => {
      close()
      navigate('/user_management/guests/add', { state: { id, copy: true } })
    },
    [navigate]
  )
  const _handleSendEmail = useCallback(
    (id, close) => {
      close()
      dispatch({ type: SEND_GUEST_EMAIL, id })
    },
    [dispatch]
  )

  const _canDelete = useCallback(
    (data) => {
      if (!pages) return false
      if (!pages.manage_guests.functions.delete) return false
      return data && data.deletable
    },
    [pages]
  )
  const _canSendLogin = useCallback(
    (data) => {
      if (!pages) return false
      if (!pages.manage_guests.functions.add) return false
      return data && data.editable
    },
    [pages]
  )

  const guestsColumns = [
    {
      title: '',
      dataIndex: 'file_url',
      key: 'file_url',
      render: guestAvatarCell
    },
    {
      title: t('user_management:guest.name'),
      dataIndex: 'name',
      key: 'name',
      cellTitle: true,
      sortable: true
    },
    {
      title: t('furigana'),
      dataIndex: 'furigana',
      key: 'furigana',
      sortable: true
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phone',
      sortable: true
    },
    {
      title: t('email'),
      dataIndex: 'mail_address',
      key: 'mail_address',
      sortable: true
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('user_management:send_login_info'),
          onClick: (id, close) => {
            _handleSendEmail(id, close)
          },
          shouldRender: _canSendLogin
        },
        {
          label: t('copy'),
          onClick: (id, close) => {
            _handleCopy(id, close)
          },
          shouldRender: 'manage_guests.functions.add'
        },
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            console.log('warning', id)
            dispatch({ type: DELETE_GUEST, id })
            close()
          },
          shouldRender: _canDelete
        }
      ]
    }
  ]

  const navigateToAdd = () => {
    navigate('/user_management/guests/add')
  }
  const navigateToDetails = (id, row, e) => {
    const url = `/user_management/guests/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      // loading={loading}
      // lastEdited={lastEdited}
      // lastEditedBy={lastEditedBy}
    >
      <Row justify="between" align="center" mt="R" wrap={'wrap'}>
        <Title label="navigation:management_guests_list"/>
        <div>
          <RoleControlled authorized="manage_guests.functions.add">
            <PrimaryButton
              label="user_management:add_guest"
              handleClick={navigateToAdd}
              reverse
              withMargin
            />
          </RoleControlled>
        </div>
      </Row>
      <Table
        fullHeight
        columns={guestsColumns}
        rowKey="id"
        loadingSelector={selectGuestsLoading}
        resultsCountSelector={selectGuestsCount}
        dataSelector={selectGuestsData}
        onRowClick={navigateToDetails}
        leftStickyColumnCount={2}
        rightStickyColumnCount={1}
      />
    </MainLayout>
  )
}
export default GuestsPage

GuestsPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
GuestsPage.defaultProps = {}
