import React from 'react'
import ReactDOM from 'react-dom'
import { datadogRum } from '@datadog/browser-rum'
import * as serviceWorker from './serviceWorker'
import packageJson from '../package.json'
import App from './App'

process.env.REACT_APP_STAGE !== 'local' && datadogRum.init({
  applicationId: '8bc6afec-2088-4215-83c4-1ea2ad43574f',
  clientToken: 'pub9d01fbf92e2037773b39bc038239a7e2',
  site: 'datadoghq.com',
  service: 'app-me!Cloud | main',
  env: process.env.REACT_APP_STAGE,
  version: packageJson.version,
  sampleRate: 100,
  trackInteractions: true
})

ReactDOM.render(
  (<App />),
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
