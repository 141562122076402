import {
  styled,
  SPACING,
  TextInput,
  CustomText
} from '@gk-devteam/apmc-core-web'

export const EmailInputStyle = styled(TextInput)`
  margin-bottom: ${SPACING.LARGE}rem;
`

export const CustomTextStyle = styled(CustomText)`
  user-select: none;
`
