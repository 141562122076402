import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  RepeaterRemoveButton,
  TextInput
} from '@gk-devteam/apmc-core-web'

const CustomFieldRow = ({ index }) => {
  return (
    <Row wrap="wrap" align="baseline">
      <TextInput
        flex={1}
        type="text"
        name={`custom_fields[${index}].label`}
        label='contracts:custom_fields.label'
        placeholder=""
        repeaterField
        required
      />
      <TextInput
        flex={1}
        type="text"
        name={`custom_fields[${index}].value`}
        label='contracts:custom_fields.value'
        placeholder=""
        repeaterField
        required
      />
      <RepeaterRemoveButton
        index={index}
        propertyKey="custom_fields"
        label="delete"
      />
    </Row>
  )
}

export default memo(CustomFieldRow)

CustomFieldRow.propTypes = {
  index: PropTypes.number.isRequired
}
