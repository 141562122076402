import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import {
  selectAdminFaqData,
  selectAdminFaqAllDisplayed,
  selectRoomID
} from '../../../../selectors'
import { TOGGLE_FAQ } from '../../../../types'

import FaqList from '../common/FaqList'

const AdminFaqList = ({ filter }) => {
  const dispatch = useDispatch()

  const _handleFaqDisplay = useCallback(
    (display, id, item) => {
      if (id) {
        const data = {
          id: item.owner_faq_id,
          display
        }
        const config = {
          type: 'admin_faqs',
          room: id
        }
        dispatch({ type: TOGGLE_FAQ, data, config })
      } else {
        console.warning('no room id')
      }
    },
    [dispatch]
  )

  return (
    <FaqList
      dataSelector={selectAdminFaqData}
      displaySelector={selectAdminFaqAllDisplayed}
      idSelector={selectRoomID}
      title="faq:admin_faq"
      filter={filter}
      type="admin_faqs"
      level="room"
      handleFaqDisplay={_handleFaqDisplay}
    />
  )
}

export default AdminFaqList

AdminFaqList.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}
