export const SUBSCRIBE_CHECKLISTS = 'subscribe_checklists'
export const SUBSCRIBE_CHECKLISTS_SUCCESS = 'subscribe_checklists_success'
export const SUBSCRIBE_CHECKLISTS_FAIL = 'subscribe_checklists_fail'
export const UNSUBSCRIBE_CHECKLISTS = 'unsubscribe_checklists'

export const FETCH_CHECKLIST_HOME = 'fetch_checklist_home'
export const FETCH_CHECKLIST_HOME_SUCCESS = 'fetch_checklist_home_success'
export const FETCH_CHECKLIST_HOME_FAIL = 'fetch_checklist_home_fail'

export const FETCH_CHECKLIST = 'fetch_checklist'
export const FETCH_CHECKLIST_SUCCESS = 'fetch_checklist_success'
export const FETCH_CHECKLIST_FAIL = 'fetch_checklist_fail'

export const FETCH_CHECKLIST_ISSUE = 'fetch_checklist_issue'
export const FETCH_CHECKLIST_ISSUE_SUCCESS = 'fetch_checklist_issue_success'
export const FETCH_CHECKLIST_ISSUE_FAIL = 'fetch_checklist_issue_fail'

export const POST_CHECKLIST_ISSUE_COMMENT = 'post_checklist_issue_comment'
export const POST_CHECKLIST_ISSUE_COMMENT_SUCCESS = 'post_checklist_issue_comment_success'
export const POST_CHECKLIST_ISSUE_COMMENT_FAIL = 'post_checklist_issue_comment_fail'

export const UPDATE_CHECKLIST_ISSUES_STATUS = 'update_checklist_issues_status'
export const UPDATE_CHECKLIST_ISSUES_STATUS_SUCCESS = 'update_checklist_issues_status_success'
export const UPDATE_CHECKLIST_ISSUES_STATUS_FAIL = 'update_checklist_issues_status_fail'

export const UPDATE_CHECKLIST_ISSUE_STATUS = 'update_checklist_issue_status'
export const UPDATE_CHECKLIST_ISSUE_STATUS_SUCCESS = 'update_checklist_issue_status_success'
export const UPDATE_CHECKLIST_ISSUE_STATUS_FAIL = 'update_checklist_issue_status_fail'

export const RESET_CHECKLIST = 'reset_checklist'
export const RESET_CHECKLIST_ISSUE = 'reset_checklist_issue'
