import { createSelector } from 'reselect'

const selectManuals = state => state.manuals

const parseDisplay = (data) => {
  const items = []
  for (const item of data) {
    items.push(item.display)
  }

  if (items.every(item => item === 0 || item === false)) return 0 // all hidden
  if (items.every(item => item === 2 || item === true)) return 2 // all displayed
  return 1
}

export const selectManualsLoading = createSelector(
  selectManuals,
  manuals => manuals.loading
)
export const selectManualPosting = createSelector(
  selectManuals,
  manuals => manuals.posting
)

export const selectAdminManualsData = createSelector(
  selectManuals,
  manuals => manuals.data && manuals.data.admin_results
)
export const selectPropertyManualsData = createSelector(
  selectManuals,
  manuals => manuals.data && manuals.data.property_results
)
export const selectRoomManualsData = createSelector(
  selectManuals,
  manuals => manuals.data && manuals.data.room_results
)

export const selectAdminManualsAllDisplayed = createSelector(
  selectManuals,
  manuals => manuals.data && manuals.data.admin_results ? parseDisplay(manuals.data.admin_results) : 0
)
export const selectPropertyManualsAllDisplayed = createSelector(
  selectManuals,
  manuals => manuals.data && manuals.data.property_results ? parseDisplay(manuals.data.property_results) : 0
)
export const selectRoomManualsAllDisplayed = createSelector(
  selectManuals,
  manuals => manuals.data && manuals.data.room_results ? parseDisplay(manuals.data.room_results) : 0
)

export const selectManualsHiddenBy = createSelector(
  selectManuals,
  manuals => manuals.data && manuals.data.hiddenBy
)
export const selectManualsHiddenById = createSelector(
  selectManuals,
  manuals => manuals.data && manuals.data.hiddenById
)
