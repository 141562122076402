import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { SURVEY_RECIPIENT_TYPE } from '../../../constants'

import PropertiesRecipients from './Recipients/PropertiesRecipients'
import UsersRecipients from './Recipients/UsersRecipients'

const RecipientsSearch = () => {
  const recipientType = useSelector(state => state.form.recipient_type)

  switch (recipientType) {
    case SURVEY_RECIPIENT_TYPE.PROPERTY:
      return <PropertiesRecipients />
    case SURVEY_RECIPIENT_TYPE.USER:
      return <UsersRecipients />
    default:
      return null
  }
}

export default memo(RecipientsSearch)

RecipientsSearch.propTypes = {}
