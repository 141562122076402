import { createSelector } from 'reselect'

const selectAuth = state => state.auth

export const selectAuthLoading = createSelector(
  selectAuth,
  auth => auth?.loading
)
export const selectUser = createSelector(
  selectAuth,
  auth => auth ? auth.user : null
)
export const selectUserRole = createSelector(
  selectAuth,
  auth => auth && auth.user ? auth.user.role : null
)
export const selectUserID = createSelector(
  selectAuth,
  auth => auth && auth.user ? auth.user.id : null
)
export const selectOwnerID = createSelector(
  selectAuth,
  auth => auth && auth.user ? auth.user.owner_id : null
)
export const selectManageCompanyName = createSelector(
  selectAuth,
  auth => auth && auth.user ? auth.user.manage_company_name : null
)
export const selectTheme = createSelector(
  selectAuth,
  auth => auth ? auth.theme : null
)
export const selectPages = createSelector(
  selectAuth,
  auth => auth ? auth.pages : null
)
export const selectLocale = createSelector(
  selectAuth,
  auth => auth ? auth.locale : null
)
export const selectBaseLocale = createSelector(
  selectAuth,
  auth => {
    const { locale } = auth
    if (!locale) return null

    if (locale.includes('|')) {
      const locales = locale.split('|')
      return locales[0]
    }

    return locale
  }
)
