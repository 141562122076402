import { api } from '@gk-devteam/apmc-core-web'
import { IMPORT_TYPES } from '../../constants'

export const validateData = (data, importType, customPages, cancelSource) => {
  let url = ''
  switch (importType) {
    case IMPORT_TYPES.PROPERTIES:
      url = 'properties/bulk/validate'
      break
    case IMPORT_TYPES.CONTRACTS:
      url = 'contracts/bulk/validate'
      if (customPages.yasue) {
        url = 'yasue/contracts/bulk/validate'
      }
      break
    case IMPORT_TYPES.FAQ:
      url = 'admin/faqs/bulk/validate'
      break
    case IMPORT_TYPES.YASUE_CONTRUCTION_WORK:
      url = 'yasue/construction_work/bulk/validate'
      break
    default:
      break
  }
  return api.post(url, data, {
    cancelToken: cancelSource.token
  })
}

export const importData = (data, importType, customPages, cancelSource) => {
  let url = ''
  switch (importType) {
    case IMPORT_TYPES.PROPERTIES:
      url = 'properties/bulk'
      break
    case IMPORT_TYPES.CONTRACTS:
      url = 'contracts/bulk'
      if (customPages.yasue) {
        url = 'yasue/contracts/bulk'
      }
      break
    case IMPORT_TYPES.FAQ:
      url = 'admin/faqs/bulk'
      break
    case IMPORT_TYPES.YASUE_CONTRUCTION_WORK:
      url = 'yasue/construction_work/bulk'
      break
    default:
      break
  }
  // return api.post(`${url}?dev=true`, data, {
  return api.post(url, data, {
    cancelToken: cancelSource.token
  })
}
