import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  LeftSearchMenu,
  Select,
  selectQueryParams,
  TextInput,
  UPDATE_FORM
} from '@gk-devteam/apmc-core-web'

import { inuiContactsSearchSchema } from '../../../validation'
import { INUI } from '../../../constants'

const initialValues = {
  property_name: '',
  app_user_name: '',
  app_user_phone: '',
  contact_type: null
}

export default function InuiContactsSearchBar () {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        property_name: queryParams.property_name,
        id: queryParams.id,
        property_manage_id: queryParams.property_manage_id,
        prefecture: queryParams.prefecture,
        city: queryParams.city
      }
    })
  }, [queryParams, dispatch])

  return (
    <LeftSearchMenu
      resetValues={initialValues}
      validationSchema={inuiContactsSearchSchema}
    >
      <TextInput
        type="text"
        name="property_name"
        label='properties:property_name'
        placeholder="placeholders.partial_property_name"
        mr="0"
      />
      <TextInput
        type="text"
        name="name"
        label='inui:name'
        placeholder="placeholders.partial_user_name"
        mr="0"
      />
      <TextInput
        type="text"
        name="phone"
        label='phone'
        placeholder="placeholders.phone"
        mr="0"
      />
      <Select
        name="contact_type"
        label='inui:contact_types'
        placeholder=""
        options={INUI.CONTACT_TYPES}
        mr="0"
      />
    </LeftSearchMenu>
  )
}
