import React from 'react'
import { notification } from 'antd'
import queryString from 'query-string'
import { InfoButton } from '@gk-devteam/apmc-core-web'

import {
  CONTRACT_CANCEL_DONE,
  CONTRACT_RENEWAL_DONE,
  CONTRACT_STATUS
} from '../../constants'
import {
  FETCH_CONTRACTS_RENEWING,
  FETCH_CONTRACTS_CANCELING,
  FETCH_CONTRACT,
  FETCH_CONTRACTS,
  FETCH_RENEW_CONTRACT,
  FETCH_CONTRACTS_PROCESSING,
  FETCH_CONTRACTS_PROCESSING_COUNT,
  FETCH_CONTRACT_DOCUMENTS,
  FETCH_CANCEL_CONTRACT
  // FETCH_CONTRACTS_PROCESSING
} from '../../types'

const listPathRegex = /\/contracts$/
const listWithParamsPathRegex = /\/contracts\?.*$/
const detailsPathRegex = /\/contracts\/edit\/(\d*)$/
const renewPathRegex = /\/contracts\/edit\/(\d*)\/renew$/
const documentsPathRegex = /\/contracts\/edit\/(\d*)\/documents$/
const contractBlockPathRegex = /\/contracts\/edit\/(\d*)/
const adminTasksPathRegex = /\/admin_tasks$/
const adminTasksDetailsPathRegex = /\/admin_tasks\/contracts/

let prevKeys = []

export const updateContractsHandler = ({
  userID,
  data,
  dispatch,
  navigate,
  contractUpdatedMessage,
  contractUpdateQuestion
}) => {
  const path = window.location.pathname
  console.log('update contracts', data)
  console.log('path', path)
  const { status, contract_id, user_id } = data

  const removePrevNotification = (key) => {
    if (prevKeys) {
      for (const key of prevKeys) {
        notification.destroy(key)
      }
      if (key) prevKeys = [key]
    }
  }

  const showNotification = (key, clickHandler) => {
    const btn = (
      <InfoButton
        type="button"
        size="small"
        handleClick={clickHandler}
        label="update"
      />
    )
    notification.info({
      key,
      duration: 0,
      message: contractUpdatedMessage,
      description: contractUpdateQuestion,
      btn
    })
  }

  if (status) {
    // Handle global updates on status change
    switch (status) {
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value:
      case CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value:
      case CONTRACT_RENEWAL_DONE:
        console.debug('global refetch of renewing contracts')
        dispatch({ type: FETCH_CONTRACTS_RENEWING })
        break
      case CONTRACT_STATUS.REQUESTING_CANCEL.value:
      case CONTRACT_STATUS.CANCELING.value:
      case CONTRACT_CANCEL_DONE:
        console.debug('global refetch of canceling contracts')
        dispatch({ type: FETCH_CONTRACTS_CANCELING })
        break
      default:
        break
    }

    // Handle contract list update on event
    if (path && (path.match(listPathRegex) || path.match(listWithParamsPathRegex))) {
      console.debug('refetch contracts list')
      const query = queryString.parse(window.location.search)
      dispatch({ type: FETCH_CONTRACTS, query, noLoading: true })
    }

    // Handle admin tasks main page update on event
    if (path && (path.match(adminTasksPathRegex))) {
      console.debug('refetch admin tasks contracts list')
      dispatch({ type: FETCH_CONTRACTS_PROCESSING, noLoading: true })
    }
    // Handle admin tasks contracts page update on event
    if (path && (path.match(adminTasksDetailsPathRegex))) {
      console.debug('refetch admin tasks contracts')
      const query = queryString.parse(window.location.search)
      dispatch({ type: FETCH_CONTRACTS_PROCESSING_COUNT })
      dispatch({ type: FETCH_CONTRACTS, query })
    }

    // Handle contract details update on event
    if (path && path.match(detailsPathRegex)) {
      const id = path.match(detailsPathRegex)[1]
      if (id && Number(id) === contract_id) {
        if (userID !== user_id) {
          const key = `details_${Date.now()}`
          removePrevNotification(key)
          const _handleClick = () => {
            console.debug('refetch contract details')
            dispatch({ type: FETCH_CONTRACT, id, noLoading: true })
            notification.destroy(key)
          }
          showNotification(key, _handleClick)
        } else {
          console.debug('refetch contract details')
          dispatch({ type: FETCH_CONTRACT, id, noLoading: true })
        }
      }
    }

    // Handle contract renew update on event
    if (path && path.match(renewPathRegex)) {
      const id = path.match(renewPathRegex)[1]
      if (id && Number(id) === contract_id) {
        if (userID !== user_id) {
          const key = `renew_${Date.now()}`
          removePrevNotification(key)
          const _handleClick = () => {
            dispatch({ type: FETCH_CONTRACT, id, noLoading: true })
            if (status === CONTRACT_STATUS.REQUESTING_CANCEL.value) {
              console.debug('fetch contract canceling & redirect')
              dispatch({
                type: FETCH_CANCEL_CONTRACT,
                id,
                noLoading: true,
                onSuccess: () => {
                  navigate(`/contracts/edit/${id}`)
                }
              })
            } else if (status !== CONTRACT_RENEWAL_DONE) {
              console.debug('refetch contract renewing')
              dispatch({ type: FETCH_RENEW_CONTRACT, id, noLoading: true })
            }
            notification.destroy(key)
          }
          showNotification(key, _handleClick)
        } else {
          console.debug('refetch contract renewing')
          if (status !== CONTRACT_RENEWAL_DONE) {
            dispatch({ type: FETCH_RENEW_CONTRACT, id, noLoading: true })
          }
          dispatch({ type: FETCH_CONTRACT, id, noLoading: true })
        }
      }
    }

    // Handle contract documents update on event
    if (path && path.match(documentsPathRegex)) {
      const id = path.match(documentsPathRegex)[1]
      if (status === CONTRACT_RENEWAL_DONE) {
        console.debug('refetch contract documents')
        dispatch({ type: FETCH_CONTRACT_DOCUMENTS, id })
      }
    }

    if (
      path &&
      path.match(contractBlockPathRegex) &&
      !path.match(detailsPathRegex) &&
      !path.match(renewPathRegex) &&
      !path.match(documentsPathRegex)
    ) {
      const id = path.match(contractBlockPathRegex)[1]
      dispatch({ type: FETCH_CONTRACT, id, noLoading: true })
    }

    // dispatch({ type: FETCH_CONTRACTS_PROCESSING })
  }
}
