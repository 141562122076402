import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchInuiContacts
} from '../../services'

import {
  FETCH_INUI_CONTACTS,
  FETCH_INUI_CONTACTS_SUCCESS,
  FETCH_INUI_CONTACTS_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchInuiContactsdSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchInuiContacts, query, cancelSource)
    if (res && res.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_INUI_CONTACTS_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_INUI_CONTACTS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_INUI_CONTACTS_FAIL, error: true, payload: { message: error.data || error.message || i18n.t('fetch_error') } })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchInui () {
  yield takeLatest(FETCH_INUI_CONTACTS, fetchInuiContactsdSaga)
}
