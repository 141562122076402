import React, { memo, useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CancelToken } from 'axios'
import {
  UPDATE_FORM,
  Row,
  TextInput,
  Select
} from '@gk-devteam/apmc-core-web'

import { fetchContractDocumentLabels } from '../../../services'

const ContractDocumentTitle = () => {
  const dispatch = useDispatch()
  const [titleOptions, setTitleOptions] = useState(null)
  const label = useSelector(state => state.form && state.form.label)

  const fetchLabels = useCallback(
    async () => {
      try {
        const cancelSource = CancelToken.source()
        const res = await fetchContractDocumentLabels(cancelSource)
        if (res && res.data && res.data.items) {
          const options = res.data.items.map(option => {
            return {
              label: option,
              value: option
            }
          })
          setTitleOptions(options)
        } else {
          setTitleOptions([{
            label: 'その他',
            value: 'その他'
          }])
        }
      } catch (error) {
        console.warn(error)
      }
    },
    []
  )

  useEffect(() => {
    fetchLabels()
  }, [fetchLabels])

  useEffect(() => {
    if (label && label !== 'その他') {
      dispatch({ type: UPDATE_FORM, payload: { custom_label: '' } })
    }
  }, [label, dispatch])

  return (
    <Row>
      <Select
        flex={1}
        minW={300}
        maxW={300}
        name="label"
        label='title'
        placeholder=""
        options={titleOptions}
        required
        noClear
      />
      {
        label && label === 'その他'
          ? (
            <TextInput
              flex={1}
              type="text"
              name="custom_label"
              placeholder="contracts:placeholders.document_custom_label"
            />
          )
          : null
      }
    </Row>
  )
}

export default memo(ContractDocumentTitle)

// ContractDocumentTitle.propTypes = {}
