import React, { useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import {
  RESET_PARAMS,
  selectQueryParams,
  PageLayout,
  Row,
  Title,
  PrimaryButton,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_NOTICES,
  RESET_NOTICES
} from '../../../types'
import { noticesSearchSchema } from '../../../validation'

import NoticesSearchBar from './NoticesSearchBar'
import NoticeStatusModal from './NoticeStatusModal'
import NoticeConditionsModal from './NoticeConditionsModal'
import NoticesList from './NoticesList'

const breadcrumbs = [
  {
    label: 'navigation:notices',
    link: '/notices'
  }
]

const NoticesPage = ({ navigate, location }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const prevQuery = useRef(null)

  const fetchData = useCallback(
    () => {
      const schema = noticesSearchSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_NOTICES, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_NOTICES })
          }
        })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_NOTICES })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const navigateToAdd = () => {
    navigate('./add/')
  }

  return (
    <PageLayout
      sidebar={
        () => <NoticesSearchBar />
      }
      breadcrumbs={breadcrumbs}
    >
      <NoticeStatusModal />
      <NoticeConditionsModal />
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:notices_list"/>
        <div>
          <RoleControlled authorized="notices.functions.add">
            <PrimaryButton
              label="notices:add_notice"
              handleClick={navigateToAdd}
              reverse
              withMargin
            />
          </RoleControlled>
        </div>
      </Row>
      <NoticesList navigate={navigate}/>
    </PageLayout>
  )
}
export default NoticesPage

NoticesPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
NoticesPage.defaultProps = {

}
