import {
  FONT_SIZE,
  getColor,
  SPACING,
  styled,
  Text
} from "@gk-devteam/apmc-core-web"
import { transparentize } from "polished"

export const UserStatusLegendContainerStyle = styled.div`
  margin-top: ${SPACING.LARGE}rem;
  background-color: ${props => transparentize(0.9, getColor(props, 'info'))};
  border: 1px solid ${props => getColor(props, 'info')};
  padding: ${SPACING.REGULAR}rem;
  user-select: none;
  box-shadow: 0px -2px 8px 0 #f0f1f2;

  .anticon {
    ${FONT_SIZE.LARGE}
    color: ${props => getColor(props, 'info')};
    margin-right: ${SPACING.SMALL}rem;
  }
`

export const BoldStyle = styled(Text)`
  font-weight: bold;
`
