import React from 'react'
import {
  FRONT_TIMESTAMP_FORMAT,
  LightText,
  Row,
  Spinner,
  Tag
} from '@gk-devteam/apmc-core-web'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'

import { SPACE_TYPE } from '../../constants'
import { selectSpacesWaitingReservations, selectSpacesWaitingReservationsLoading } from '../../selectors'

import {
  ContractItemStyle,
  ContractorStyle,
  ContractRightStyle,
  ContractsWrapperStyle,
  PropertyStyle,
  RoomStyle
} from './Drawer.style'

function SpacesWaitingReservations ({ closeDrawer }) {
  const navigate = useNavigate()
  const reservations = useSelector(selectSpacesWaitingReservations)
  const loading = useSelector(selectSpacesWaitingReservationsLoading)

  const _renderReservations = () => {
    return reservations.map((reservation, i) => {
      const {
        property_id,
        space_id,
        space_type,
        app_user_name,
        property_name,
        room_number,
        reservation_from,
        reservation_to
      } = reservation

      let label, color
      switch (space_type) {
        case SPACE_TYPE.PARKING:
          label = 'spaces:types.parking'
          color = 'process'
          break
        case SPACE_TYPE.COMMUNITY_ROOM:
          label = 'spaces:types.community_room'
          color = 'danger'
          break
        case SPACE_TYPE.ELECTRIC_VEHICLE_PARKING:
          label = 'spaces:types.electric_vehicle_parking'
          color = 'accent'
          break
        case SPACE_TYPE.CAR_WASH:
          label = 'spaces:types.car_wash'
          color = 'success'
          break
        case SPACE_TYPE.GUEST_ROOM:
          label = 'spaces:types.guest_room'
          color = 'warning'
          break
        case SPACE_TYPE.SKY_DECK:
          label = 'spaces:types.sky_deck'
          color = 'info'
          break
        default:
          break
      }

      const navigateToSpace = () => {
        navigate(`/properties/edit/${property_id}/spaces/edit/${space_id}/reservations`)
        closeDrawer()
      }
      return (
        <ContractItemStyle key={i} onClick={navigateToSpace}>
          <ContractRightStyle>
            <ContractorStyle text={app_user_name} />
            <PropertyStyle text={property_name} />
            <RoomStyle text={room_number} />
            <Row mt="XS" wrap="nowrap"><LightText text={`${dayjs(reservation_from).format(FRONT_TIMESTAMP_FORMAT)} - ${dayjs(reservation_to).format(FRONT_TIMESTAMP_FORMAT)}`} /></Row>
          </ContractRightStyle>
          <Tag color={color} label={label}/>
        </ContractItemStyle>
      )
    })
  }

  if (loading) return <Spinner size="small" color="info" />

  return (
    <ContractsWrapperStyle>
      { _renderReservations() }
    </ContractsWrapperStyle>
  )
}

SpacesWaitingReservations.propTypes = {
  closeDrawer: PropTypes.func.isRequired
}

export default SpacesWaitingReservations
