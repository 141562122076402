/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { notification } from 'antd'
import {
  encrypt,
  decrypt,
  FORCE_LOGOUT_LOCAL_STORAGE,
  USER_REFRESH_LOCAL_STORAGE,
  COLORS,
  CustomButton
} from '@gk-devteam/apmc-core-web'

import { i18n } from '../locales'
import { selectFirebaseUser } from '../selectors'
import { LOGOUT_USER, REFRESH_USER } from '../types'

import { EmptyIcon, Description } from './common'

export const useFirebaseUser = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectFirebaseUser)
  const localForceLogout = localStorage.getItem(FORCE_LOGOUT_LOCAL_STORAGE)
  const localRefreshUser = localStorage.getItem(USER_REFRESH_LOCAL_STORAGE)

  const logoutKey = 'open_user_logout_notification'

  const logoutUser = useCallback(
    () => {
      localStorage.setItem(FORCE_LOGOUT_LOCAL_STORAGE, encrypt(user.forceLogout))
      notification.close(logoutKey)
      dispatch({ type: LOGOUT_USER })
    },
    [dispatch, logoutKey, user]
  )

  useEffect(() => {
    if (user && user.forceLogout != null) {
      if (!localForceLogout) {
        localStorage.setItem(FORCE_LOGOUT_LOCAL_STORAGE, encrypt(user.forceLogout) || '')
      } else {
        const token = decrypt(localForceLogout)
        if (token !== user.forceLogout) {
          // If token has changed, logout user
          notification.warning({
            message: i18n.t('force_logout_title'),
            description: <Description description="force_logout_description"/>,
            placement: 'bottomLeft',
            duration: 0,
            btn: (
              <CustomButton
                color={COLORS.accent}
                label="logout"
                handleClick={logoutUser}
                reverse
              />
            ),
            key: logoutKey,
            closeIcon: <EmptyIcon />
          })
        }
      }
    }
  }, [user, localForceLogout, logoutKey, logoutUser])

  useEffect(() => {
    if (user) {
      if (!localRefreshUser) {
        localStorage.setItem(USER_REFRESH_LOCAL_STORAGE, encrypt(user.forceRefreshInfo) || '')
      } else {
        const token = decrypt(localRefreshUser)
        if (token !== (user.forceRefreshInfo || '')) {
          // If token has changed, refresh user info
          localStorage.setItem(USER_REFRESH_LOCAL_STORAGE, encrypt(user.forceRefreshInfo || ''))
          dispatch({ type: REFRESH_USER })
        }
      }
    }
  }, [dispatch, user, localRefreshUser])
}
