import React, { memo, useContext } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  TOGGLE_ARRAY_ITEM,
  ReadOnlyContext
} from '@gk-devteam/apmc-core-web'

import {
  SelectedItemStyle
} from './common.style'

const SelectedItem = ({ itemKey, id, label, readOnly }) => {
  const isReadOnly = useContext(ReadOnlyContext)
  const dispatch = useDispatch()

  if (!itemKey) return null

  const _handleUserClick = () => {
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [
        {
          key: `${itemKey}_list`,
          value: { id, label },
          objectComparatorKey: 'id'
        },
        {
          key: itemKey,
          value: id
        }]
    })
  }
  return (
    <SelectedItemStyle
      handleClick={_handleUserClick}
      key={id}
      label={label ? label.replace('.', '') : ''}
      disabled={isReadOnly || readOnly}
    />
  )
}

export default memo(SelectedItem)

SelectedItem.propTypes = {
  itemKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.number,
  readOnly: PropTypes.bool
}
