import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_FORM,
  LeftSearchMenu,
  TextInput,
  selectQueryParams,
  RangePicker
} from '@gk-devteam/apmc-core-web'

import { inquiriesSearchSchema } from '../../validation'

const initialValues = {
  title: '',
  property_name: '',
  contract_id: '',
  user_id: '',
  user_name: '',
  sent_date_from: '',
  sent_date_to: ''
}

const InquiriesSearchBar = () => {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        title: queryParams?.title,
        property_name: queryParams?.property_name,
        contract_id: queryParams?.contract_id,
        user_id: queryParams?.user_id,
        user_name: queryParams?.user_name,
        sent_date_from: queryParams?.sent_date_from,
        sent_date_to: queryParams?.sent_date_to
      }
    })
  }, [queryParams, dispatch])

  return (
    <>
      <LeftSearchMenu
        resetValues={initialValues}
        validationSchema={inquiriesSearchSchema}
      >
        <TextInput
          type="text"
          name="title"
          label='inquiries:title'
          placeholder=""
          mr="0"
        />
        <TextInput
          type="text"
          name="property_name"
          label='properties:property_name'
          placeholder="placeholders.partial_property_name"
          mr="0"
        />
        <TextInput
          type="number"
          name="contract_id"
          label='contracts:contract_id'
          placeholder=""
          mr="0"
        />
        <TextInput
          type="text"
          name="app_user_id"
          label='app_users:app_user_id'
          placeholder="placeholders.partial_manage_id"
          mr="0"
        />
        <RangePicker
          name_start="sent_date_from"
          name_end="sent_date_to"
          label='inquiries:sent_date'
          placeholder=""
          mr="0"
        />
      </LeftSearchMenu>
    </>
  )
}

export default memo(InquiriesSearchBar)

// InquiriesSearchBar.propTypes = {}
