export const FETCH_FAQ = 'fetch_faq'
export const FETCH_FAQ_SUCCESS = 'fetch_faq_success'
export const FETCH_FAQ_FAIL = 'fetch_faq_fail'

export const POST_FAQ = 'post_faq'
export const POST_FAQ_SUCCESS = 'post_faq_success'
export const POST_FAQ_FAIL = 'post_faq_fail'

export const DELETE_FAQ = 'delete_faq'
export const DELETE_FAQ_SUCCESS = 'delete_faq_success'
export const DELETE_FAQ_FAIL = 'delete_faq_fail'

export const TOGGLE_FAQ = 'toggle_faq'
export const TOGGLE_FAQ_SUCCESS = 'toggle_faq_success'
export const TOGGLE_FAQ_FAIL = 'toggle_faq_fail'

export const BULK_TOGGLE_FAQ = 'bulk_toggle_faq'
export const BULK_TOGGLE_FAQ_SUCCESS = 'bulk_toggle_faq_success'
export const BULK_TOGGLE_FAQ_FAIL = 'bulk_toggle_faq_fail'

export const RESET_FAQ = 'reset_faq'
