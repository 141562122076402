import { createSelector } from 'reselect'

const selectCoupon = state => state.coupon

export const selectLoadingCoupon = createSelector(
  selectCoupon,
  coupon => coupon.loading
)
export const selectPostingCoupon = createSelector(
  selectCoupon,
  coupon => coupon.posting
)
export const selectCouponData = createSelector(
  selectCoupon,
  coupon => coupon.data || {}
)
export const selectCouponHistoryData = createSelector(
  selectCoupon,
  coupon => coupon.data?.history || []
)
export const selectCouponHistoryCount = createSelector(
  selectCoupon,
  coupon => coupon.data?.results_count
)
export const selectCouponError = createSelector(
  selectCoupon,
  coupon => coupon.error
)
export const selectCouponFormData = createSelector(
  selectCoupon,
  coupon => {
    const data = { ...coupon.data }

    delete data.read_count
    delete data.unread_count
    delete data.sent_count
    delete data.recipients

    return data
  }
)
export const selectcouponID = createSelector(
  selectCoupon,
  coupon => coupon.data && coupon.data.id
)
export const selectCouponEditable = createSelector(
  selectCoupon,
  coupon => !coupon.data?.id
)
export const selectCouponConditions = createSelector(
  selectCoupon,
  coupon => coupon.recipients_condition
)
export const selectStoppingCoupon = createSelector(
  selectCoupon,
  coupon => coupon.stopping
)
