import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Table,
  ToastTitle,
  Row
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_CONTRACT_DOCUMENTS,
  RESET_CONTRACT_DOCUMENTS,
  TOGGLE_ADD_DOCUMENT_MODAL,
  TOGGLE_VIEW_DOCUMENT_MODAL,
  SET_ACTIVE_CONTRACT_DOCUMENT,
  DELETE_CONTRACT_DOCUMENT
} from '../../../types'
import {
  selectContractDocumentsHiddenBy,
  selectContractDocumentsLoading,
  selectContractDocumentsData,
  selectContractPropertyID,
  selectContractDocumentsHiddenById
} from '../../../selectors'
import { ROLES, DOCUMENT_STATUS } from '../../../constants'
import cloudsignLogo from '../../../assets/img/cloudsign.svg'

import PanelLayout from '../../../components/Display/Panels/PanelLayout'
import AddDocumentModal from './AddDocumentModal'
import ViewDocumentModal from './ViewDocumentModal'

import { ImageStyle, TagStyle } from './ContractDocumentsPage.style'

const ContractDocumentsPage = ({ contractID }) => {
  const { t } = useTranslation(['common', 'contracts'])
  const dispatch = useDispatch()
  const propertyID = useSelector(selectContractPropertyID)

  useEffect(() => {
    dispatch({ type: FETCH_CONTRACT_DOCUMENTS, id: contractID })
    return () => {
      dispatch({ type: RESET_CONTRACT_DOCUMENTS })
    }
  }, [dispatch, contractID])

  const breadcrumbs = [
    {
      label: 'navigation:contracts',
      link: '/contracts'
    },
    {
      label: 'navigation:contracts_details',
      link: `/contracts/edit/${contractID}`
    },
    {
      label: 'navigation:contracts_documents',
      link: `/contracts/edit/${contractID}/documents`
    }
  ]

  const _renderTitle = useCallback(
    (value, cellKey, rowKey, className, data) => {
      if (data.cloudsign) {
        let label, color
        switch (data.cloudsign_status) {
          case DOCUMENT_STATUS.DRAFT.value:
            label = 'contracts:document_status.DRAFT'
            color = DOCUMENT_STATUS.DRAFT.color
            break
          case DOCUMENT_STATUS.WAITING_CONFIRMATION.value:
            label = 'contracts:document_status.WAITING_CONFIRMATION'
            color = DOCUMENT_STATUS.WAITING_CONFIRMATION.color
            break
          case DOCUMENT_STATUS.DONE.value:
            label = 'contracts:document_status.DONE'
            color = DOCUMENT_STATUS.DONE.color
            break
          case DOCUMENT_STATUS.CANCELED_REJECTED.value:
            label = 'contracts:document_status.CANCELED_REJECTED'
            color = DOCUMENT_STATUS.CANCELED_REJECTED.color
            break
          default:
            break
        }
        return (
          <Row>
            <ImageStyle
              src={cloudsignLogo}
              w="32px"
            />
            <ToastTitle text={value || '-'}/>
            <TagStyle
              color={color}
              label={label}
            />
          </Row>
        )
      }

      return <ToastTitle text={value || '-'}/>
    },
    []
  )

  const _handleDeleteDisplay = useCallback(
    (data) => {
      if (data.cloudsign) return false
      return true
    },
    []
  )
  const _handleCloudSignDisplay = useCallback(
    (data) => {
      if (data.cloudsign && data.cloudsign_status === DOCUMENT_STATUS.DONE.value) return true
      return false
    },
    []
  )

  const documentsColumns = [
    {
      title: '',
      dataIndex: 'document_label',
      key: 'document_label',
      render: _renderTitle
    },
    {
      title: '',
      dataIndex: '',
      key: 'option_menu',
      supportedRoles: [
        ROLES.AGENCY_MAIN_ADMIN,
        ROLES.AGENCY_ADMIN,
        ROLES.AGENCY_EDITOR,
        ROLES.AGENCY_MEMBER,
        ROLES.OWNER
      ],
      rowMenu: [
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            dispatch({ type: DELETE_CONTRACT_DOCUMENT, documentID: id, contractID })
            close()
          },
          shouldRender: _handleDeleteDisplay
        },
        {
          label: t('contracts:document_certificate'),
          onClick: (id, close) => {
            dispatch({ type: TOGGLE_VIEW_DOCUMENT_MODAL })
            dispatch({ type: SET_ACTIVE_CONTRACT_DOCUMENT, payload: { id, cloudSignCertificate: true } })
            close()
          },
          shouldRender: _handleCloudSignDisplay
        }
      ]
    }
  ]

  const _openAddModal = () => {
    dispatch({ type: TOGGLE_ADD_DOCUMENT_MODAL })
  }
  const _openViewModal = (id) => {
    dispatch({ type: TOGGLE_VIEW_DOCUMENT_MODAL })
    dispatch({ type: SET_ACTIVE_CONTRACT_DOCUMENT, payload: { id } })
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      hiddenSelector={selectContractDocumentsHiddenBy}
      hiddenByIdSelector={selectContractDocumentsHiddenById}
      propertyID={propertyID}
      contractID={contractID}
      title="navigation:contracts_documents"
      authorized="contract_documents.functions.add"
      handleAddClick={_openAddModal}
      noLegend
    >
      <AddDocumentModal contractID={contractID} />
      <ViewDocumentModal contractID={contractID} />
      <Table
        fullHeight
        columns={documentsColumns}
        rowKey="document_id"
        loadingSelector={selectContractDocumentsLoading}
        dataSelector={selectContractDocumentsData}
        onRowClick={_openViewModal}
        listMode
      />
    </PanelLayout>
  )
}
export default ContractDocumentsPage

ContractDocumentsPage.propTypes = {
  contractID: PropTypes.string.isRequired
}
ContractDocumentsPage.defaultProps = {

}
