import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  UPDATE_FORM_ARRAY,
  Radio,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  HOUSE_MAKER_NOTICE_RECIPIENT_TYPE_OPTIONS,
  YASUE_NOTICE_RECIPIENT_TYPE_OPTIONS,
  NOTICE_RECIPIENT_TYPE_OPTIONS
} from '../../../../constants'
import { selectNoticeEditable } from '../../../../selectors'

const NoticeRecipientType = () => {
  const recipientType = useSelector(state => state.form.recipient_type)
  const editable = useSelector(selectNoticeEditable)
  const pages = useSelector(selectPages)
  const dispatch = useDispatch()
  const prevTypeValue = useRef(null)

  useEffect(() => {
    if (prevTypeValue.current == null) {
      prevTypeValue.current = recipientType
    } else {
      if (prevTypeValue.current !== recipientType) {
        prevTypeValue.current = recipientType
        if (recipientType === 2) {
          dispatch({
            type: UPDATE_FORM_ARRAY,
            payload:
            [
              {
                name: 'user_type',
                values: 1
              },
              {
                name: 'recipients_id',
                values: null
              },
              {
                name: 'recipients_list',
                values: null
              }
            ]
          })
        } else {
          dispatch({
            type: UPDATE_FORM_ARRAY,
            payload: [
              {
                name: 'recipients_id',
                values: null
              },
              {
                name: 'recipients_list',
                values: null
              }
            ]
          })
        }
      }
    }
  }, [recipientType, dispatch, prevTypeValue])

  let options = NOTICE_RECIPIENT_TYPE_OPTIONS
  if (pages?.custom?.house_maker) options = HOUSE_MAKER_NOTICE_RECIPIENT_TYPE_OPTIONS
  if (pages?.custom?.yasue) options = YASUE_NOTICE_RECIPIENT_TYPE_OPTIONS

  return (
    <Radio
      name="recipient_type"
      options={options}
      readonly={!editable}
    />
  )
}

export default NoticeRecipientType

NoticeRecipientType.propTypes = {}
