import { api, DEFAULT_QUERY_LIMIT } from '@gk-devteam/apmc-core-web'

export const fetchRefunds = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }

  return api.get('/anabuki_refund', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token
  })
}

export const fetchRefund = (id, cancelSource) => {
  return api.get(`/anabuki_refund/${id}`, {
    cancelToken: cancelSource.token
  })
}
export const fetchRefundFile = (id, cancelSource) => {
  return api.get(`/anabuki_refund/${id}/pdf`, {
    timeout: 5 * 60 * 1000,
    responseType: 'blob',
    cancelToken: cancelSource.token
  })
}

export const updateRefundStatus = (data, id, cancelSource) => {
  return api.post(`/anabuki_refund/${id}`, data, {
    cancelToken: cancelSource.token
  })
}
