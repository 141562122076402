import { put, call, takeLatest, select, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchCoupon,
  postCoupon,
  deleteCoupon
} from '../../services'

import {
  FETCH_COUPON,
  FETCH_COUPON_FAIL,
  FETCH_COUPON_SUCCESS,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAIL,
  POST_COUPON,
  POST_COUPON_SUCCESS,
  POST_COUPON_FAIL,
  FETCH_COUPONS
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchCouponSaga ({ id, query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchCoupon, id, query, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_COUPON_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_COUPON_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_COUPON_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postCouponSaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postCoupon, data, cancelSource)
    if (res && res.data && res.data.success) {
      if (successRedirect) yield call(successRedirect, res.data.id)
      yield put({ type: POST_COUPON_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_COUPON_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_COUPON_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteCouponSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteCoupon, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_COUPON_SUCCESS })
      yield put({ type: FETCH_COUPONS, query })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_COUPON_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchCoupon () {
  yield takeLatest(POST_COUPON, postCouponSaga)
  yield takeLatest(FETCH_COUPON, fetchCouponSaga)
  yield takeLatest(DELETE_COUPON, deleteCouponSaga)
}
