import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dot } from 'dot-object'
import PropTypes from 'prop-types'
import {
  Row,
  SectionTitle,
  Text,
  TriStateCheckbox
} from '@gk-devteam/apmc-core-web'

import { selectPages } from '../../../../selectors'
import { PanelDisabledContext } from '../PanelDisabled/PanelDisabled'

import {
  DisplayAllStyle
} from './PanelBlockHeader.style.js'

const PanelBlockHeader = ({ title, idSelector, displaySelector, type, level, toggleType, extraConfig, authorized }) => {
  const disabled = useContext(PanelDisabledContext)
  const dispatch = useDispatch()
  const allDisplayed = useSelector(displaySelector)
  const pages = useSelector(selectPages)
  const emtpySelector = () => null
  const id = useSelector(idSelector || emtpySelector)

  if (!pages) return null

  const _handleDisplayClick = (display) => {
    const data = {
      display
    }
    let config = {
      type,
      [level]: id
    }
    if (extraConfig) {
      config = {
        ...config,
        ...extraConfig
      }
    }
    dispatch({ type: toggleType, data, config })
  }

  const isDisabled = () => {
    if (authorized) {
      const dotted = dot(pages)
      return !dotted[authorized]
    }
    return false
  }

  // let displayLabel
  // switch (allDisplayed) {
  //   case 0:
  //     displayLabel = 'show_all'
  //     break
  //   case 1:
  //     displayLabel = 'show_all'
  //     break
  //   case 2:
  //     displayLabel = 'hide_all'
  //     break

  //   default:
  //     break
  // }
  return (
    <Row justify="between" align="center">
      <SectionTitle label={title} />
      <DisplayAllStyle>
        {/* <Text label={displayLabel}/> */}
        <Text label="show_all"/>
        <TriStateCheckbox
          size="fluid"
          mr="0"
          mb="0"
          autoH
          noLabel
          value={allDisplayed}
          handleChange={_handleDisplayClick}
          disabled={disabled || isDisabled()}
        />
      </DisplayAllStyle>
    </Row>
  )
}

export default PanelBlockHeader

PanelBlockHeader.propTypes = {
  title: PropTypes.string.isRequired,
  idSelector: PropTypes.func,
  displaySelector: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  level: PropTypes.oneOf(['admin', 'property', 'room', 'contract']).isRequired,
  toggleType: PropTypes.string.isRequired,
  extraConfig: PropTypes.object,
  authorized: PropTypes.string
}
PanelBlockHeader.defaultProps = {

}
