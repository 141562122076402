import React, { memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  UPDATE_FORM,
  Row,
  Text,
  SearchInput,
  TextInput,
  FormControlError,
  RoleControlled,
  selectPages
} from '@gk-devteam/apmc-core-web'

import { ResetUserStyle } from '../../ContractForm.style'

const AppUserNameForm = ({ userType, handleSearch, loading, error }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userID = useSelector(state => state.form[`${userType}_user_id`])
  const pages = useSelector(selectPages)

  const _handleUserReset = () => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        [`${userType}_user_id`]: null,
        [`${userType}.last_name`]: null,
        [`${userType}.first_name`]: null,
        [`${userType}.furigana_last_name`]: null,
        [`${userType}.furigana_first_name`]: null,
        [`${userType}.app_user_manage_id`]: null,
        [`${userType}.mail_address`]: null,
        [`${userType}.phone`]: null,
        [`${userType}.emergency_phone`]: null,
        [`${userType}.occupation`]: null,
        [`${userType}.annual_income`]: null,
        [`${userType}.birthday`]: null,
        [`${userType}.sex`]: null,
        [`${userType}.nationality`]: null,
        [`${userType}.residence_card`]: null,
        [`${userType}.home_country_emergency_contact`]: null
      }
    })
  }

  return (
    <>
      <Text label="contracts:already_registered" />
      <Row wrap="wrap" mb="S" mt="XS" align="baseline">
        <SearchInput
          placeholder="placeholders.phone"
          handleClick={handleSearch}
          loading={loading}
          error={error}
        />
        {
          userID
            ? (
              <ResetUserStyle type="button" onClick={_handleUserReset}>
                {t('contracts:reset_user')}
              </ResetUserStyle>
            )
            : null
        }
      </Row>
      <Row mb="S">
        <FormControlError name={`${userType}_user_id`}/>
      </Row>
      <Row wrap="wrap" align="baseline">
        <TextInput
          flex={1}
          type="text"
          name={`${userType}.last_name`}
          label={pages?.custom?.yasue ? 'yasue:contracts.contractor_name' : `contracts:${userType}.last_name`}
          placeholder=""
          readonly={!!userID}
          required
        />
        <RoleControlled authorized='!custom.yasue'>
          <TextInput
            flex={1}
            type="text"
            name={`${userType}.first_name`}
            label={`contracts:${userType}.first_name`}
            placeholder=""
            readonly={!!userID}
          />
        </RoleControlled>
        <TextInput
          flex={1}
          type="text"
          name={`${userType}.furigana_last_name`}
          label={pages?.custom?.yasue ? 'yasue:contracts.contractor_name_kana' : `contracts:${userType}.furigana_last_name`}
          placeholder=""
          readonly={!!userID}
        />
        <RoleControlled authorized='!custom.yasue'>
          <TextInput
            flex={1}
            type="text"
            name={`${userType}.furigana_first_name`}
            label={`contracts:${userType}.furigana_first_name`}
            placeholder=""
            readonly={!!userID}
          />
        </RoleControlled>
      </Row>
    </>
  )
}

export default memo(AppUserNameForm)

AppUserNameForm.propTypes = {
  userType: PropTypes.oneOf(['resident', 'contractor']),
  handleSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string
}
