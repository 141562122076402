import { api, MULTIPART_HEADERS, getFileFromURL } from '@gk-devteam/apmc-core-web'

export const fetchGuideCategories = (config, cancelSource) => {
  const { property, room } = config
  let path = 'admin'
  if (property) path = `properties/${property}`
  if (room) path = `rooms/${room}`

  return api.get(`${path}/guide_categories`, {
    cancelToken: cancelSource.token
  })
}

export const postGuideCategory = async (data, config, cancelSource) => {
  const { property, room } = config
  let path = 'admin'
  if (property) path = `properties/${property}`
  if (room) path = `rooms/${room}`

  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing document')
      }
    }

    delete data.file
    delete data.file_url
    delete data.file_filename

    formData.append('data', JSON.stringify(data))
    return api.post(`${path}/guide_categories`, formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}
export const copyGuideCategory = async (propertyID, categoryID, cancelSource) => {
  return api.post(`properties/${propertyID}/admin_guide_categories/copy/${categoryID}`, null, {
    cancelToken: cancelSource.token
  })
}

export const deleteGuideCategory = (id, config, cancelSource) => {
  const { property, room } = config
  let path = 'admin'
  if (property) path = `properties/${property}`
  if (room) path = `rooms/${room}`

  return api.delete(`${path}/guide_categories/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const toggleGuideCategory = (data, config, cancelSource) => {
  const { property, room, type } = config
  // type: admin_guide_categories, property_guide_categories, guide_categories
  let path = 'admin/guide_categories'
  if (property && type) path = `properties/${property}/${type}`
  if (room && type) path = `rooms/${room}/${type}`

  // console.log('path', path)
  // console.log('data', data)

  return api.post(`${path}/display`, data, {
    cancelToken: cancelSource.token
  })
}
export const bulkToggleGuideCategories = (data, config, cancelSource) => {
  const { property, room, type } = config
  // type: admin_guide_categories, property_guide_categories, guide_categories
  let path = 'admin/guide_categories'
  if (property && type) path = `properties/${property}/${type}`
  if (room && type) path = `rooms/${room}/${type}`

  // console.log('path', path)
  // console.log('data', data)

  return api.post(`${path}/bulk_display`, data, {
    cancelToken: cancelSource.token
  })
}
