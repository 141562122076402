import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
  selectAdminGuideCategoriesData,
  selectAdminGuideCategoriesAllDisplayed,
  selectRoomID
} from '../../../../selectors'
import {
  TOGGLE_GUIDE_CATEGORY
} from '../../../../types'

import GuideCategoriesList from '../common/GuideCategoriesList'

const AdminGuideCategoriesList = () => {
  const dispatch = useDispatch()

  const _handleItemDisplay = useCallback(
    (display, id, item) => {
      if (id) {
        const data = {
          id: item.id,
          display
        }
        const config = {
          type: 'admin_guide_categories',
          room: id
        }
        dispatch({ type: TOGGLE_GUIDE_CATEGORY, data, config })
      } else {
        console.warning('no property id')
      }
    },
    [dispatch]
  )

  return (
    <GuideCategoriesList
      dataSelector={selectAdminGuideCategoriesData}
      displaySelector={selectAdminGuideCategoriesAllDisplayed}
      idSelector={selectRoomID}
      title="guides:admin_guide_category"
      type="admin_guide_categories"
      level="room"
      guidePage="admin_details"
      handleItemDisplay={_handleItemDisplay}
      // sideMenu={menu}
    />
  )
}

export default AdminGuideCategoriesList

AdminGuideCategoriesList.propTypes = {}
