import { api, DEFAULT_QUERY_LIMIT } from '@gk-devteam/apmc-core-web'

export const fetchServices = (params = {}, cancelSource) => {
  return api.get('/services', {
    cancelToken: cancelSource.token,
    params
  })
}

export const updateServiceDisplay = (data, cancelSource) => {
  return api.post('/services/display', data, {
    cancelToken: cancelSource.token
  })
}

export const fetchService = (id, cancelSource) => {
  return api.get(`/services/${id}`, {
    cancelToken: cancelSource.token
  })
}
export const fetchServiceByLevel = (params = {}, id, level, cancelSource) => {
  if (!level) throw new Error('path undefined')
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params
  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }

  return api.get(`/services/${id}/${level}`, {
    cancelToken: cancelSource.token,
    params: {
      ...params,
      ...defaultParams
    }
  })
}

export const fetchCloudSignClientId = (cancelSource) => {
  return api.get('/services/cloudsign', {
    cancelToken: cancelSource.token
  })
}

export const postCloudSignClientId = (data, cancelSource) => {
  return api.post('/services/cloudsign', data, {
    cancelToken: cancelSource.token
  })
}

export const fetchSeikatsu = (property_id, cancelSource) => {
  return api.get('/services/seikatsu', {
    cancelToken: cancelSource.token,
    params: {
      property_id
    }
  })
}

export const toggleSeikatsu = (data, cancelSource) => {
  return api.post('/services/seikatsu', data, {
    cancelToken: cancelSource.token
  })
}
