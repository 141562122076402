import React, { useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import {
  RESET_PARAMS,
  selectQueryParams,
  PageLayout,
  Row,
  Title,
  useTranslation,
  Table,
  Tag,
  Text,
  PrimaryButton,
  dynamicLink
} from '@gk-devteam/apmc-core-web'

import {
  DELETE_SURVEY,
  FETCH_SURVEYS,
  RESET_SURVEYS
} from '../../../types'
import {
  selectSurveysCount,
  selectSurveysData,
  selectSurveysLoading
} from '../../../selectors'
import { surveysSearchSchema } from '../../../validation'

import SurveysPageSearchBar from './SurveysPageSearchBar'
import { SURVEY_STATUS } from '../../../constants'
import dayjs from 'dayjs'

const breadcrumbs = [
  {
    label: 'navigation:surveys_list',
    link: '/surveys'
  }
]

const SurveysPage = ({ navigate, location }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const prevQuery = useRef(null)
  const { t } = useTranslation()

  const fetchData = useCallback(
    () => {
      const schema = surveysSearchSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_SURVEYS, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_SURVEYS })
          }
        })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_SURVEYS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const statusCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      let label, color
      switch (value) {
        case SURVEY_STATUS.UNPUBLISHED:
          label = 'surveys:statuses.unpublished'
          color = 'danger'
          break
        case SURVEY_STATUS.PUBLISHED:
          label = 'surveys:statuses.published'
          color = 'info'
          break
        default:
          break
      }

      return (
        <>
          <Tag label={label} color={color} />
          {data?.end_time && dayjs().isAfter(dayjs(data.end_time)) && <Tag label="surveys:statuses.expired" color="text" />}
        </>
      )
    },
    []
  )
  const durationCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const { start_time, end_time } = data
      return <Text text={`${start_time} - ${end_time || ''}`} />
    },
    []
  )

  const _handleCopy = useCallback(
    (id, close) => {
      close()
      navigate('/surveys/add', { state: { id, copy: true } })
    },
    [navigate]
  )

  const surveysColumns = [
    {
      title: t('surveys:title'),
      dataIndex: 'title',
      key: 'title',
      cellTitle: true
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: statusCell
    },
    {
      title: t('surveys:duration'),
      dataIndex: 'start_time',
      key: 'start_time',
      render: durationCell
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('copy'),
          onClick: (id, close) => {
            _handleCopy(id, close)
          }
        },
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            console.log('warning', id)
            dispatch({ type: DELETE_SURVEY, id })
            close()
          }
        }
      ]
    }
  ]

  const navigateToAdd = () => {
    navigate('./add/')
  }

  const navigateToDetails = (id, row, e) => {
    const url = `/surveys/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <PageLayout
      sidebar={
        () => <SurveysPageSearchBar />
      }
      breadcrumbs={breadcrumbs}
    >
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:surveys_list"/>
        <PrimaryButton
          label="surveys:add_survey"
          handleClick={navigateToAdd}
          reverse
          withMargin
        />
      </Row>
      <Table
        fullHeight
        columns={surveysColumns}
        rowKey="id"
        loadingSelector={selectSurveysLoading}
        resultsCountSelector={selectSurveysCount}
        dataSelector={selectSurveysData}
        onRowClick={navigateToDetails}
        rightStickyColumnCount={1}
      />
    </PageLayout>
  )
}
export default SurveysPage

SurveysPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
SurveysPage.defaultProps = {

}
