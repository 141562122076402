import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CancelToken } from 'axios'
import { message } from 'antd'
import dayjs from 'dayjs'
import {
  Card,
  DatePicker,
  FRONT_DATE_FORMAT,
  ReadOnlyContext,
  Row,
  Select,
  Switch,
  TextInput,
  useTranslation,
  useIsMountedRef,
  selectFormRequiredFields
} from '@gk-devteam/apmc-core-web'
import { useSelector } from 'react-redux'
// import PropTypes from 'prop-types'

import { fetchRelations } from '../../../../../services'

import SearchAddressButton from '../../../../../components/Misc/SearchAddressButton'

function GuarantorCard () {
  const { t } = useTranslation()
  const isReadOnly = useContext(ReadOnlyContext)
  const isMountedRef = useIsMountedRef()
  const requiredFields = useSelector(selectFormRequiredFields)
  const [relationOptions, setRelationOptions] = useState()
  const [loadingRelations, setLoadingRelations] = useState(true)

  const fetchRelationOptions = useCallback(
    async () => {
      try {
        setLoadingRelations(true)
        const cancelSource = CancelToken.source()
        const res = await fetchRelations(cancelSource)
        if (!isMountedRef.current) return
        if (res?.data.results) {
          setLoadingRelations(false)
          setRelationOptions(res.data.results)
        } else {
          message.error(t('fetch_error'))
          setLoadingRelations(false)
        }
      } catch (error) {
        message.error(t('fetch_error'))
        setLoadingRelations(false)
      }
    },
    [t, isMountedRef]
  )

  useEffect(() => {
    fetchRelationOptions()
  }, [fetchRelationOptions, isMountedRef])

  const disableAfterToday = (current) => {
    if (current) {
      return current.isAfter(dayjs())
    }
    return false
  }

  return (
    <Card mb="L" title="contracts:guarantor.info">
      <Row wrap="wrap" align="baseline">
        <Switch
          name="contract_guarantor.type"
          prevValue={1}
          nextValue={2}
          size="XL"
          small
          noLabel
          prevLabel="contracts:guarantor.joint_guarantor"
          nextLabel="contracts:guarantor.emergency_contact"
          required={requiredFields?.['contract_guarantor.type']}
        />
      </Row>
      <Row wrap="wrap" align="baseline">
        <TextInput
          flex={1}
          size="M"
          type="text"
          name="contract_guarantor.last_name"
          label="contracts:guarantor.last_name"
          placeholder=""
          required={requiredFields?.['contract_guarantor.last_name']}
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name="contract_guarantor.first_name"
          label="contracts:guarantor.first_name"
          placeholder=""
          required={requiredFields?.['contract_guarantor.first_name']}
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name="contract_guarantor.furigana_last_name"
          label="contracts:guarantor.furigana_last_name"
          placeholder=""
          required={requiredFields?.['contract_guarantor.furigana_last_name']}
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name="contract_guarantor.furigana_first_name"
          label="contracts:guarantor.furigana_first_name"
          placeholder=""
          required={requiredFields?.['contract_guarantor.furigana_first_name']}
        />
      </Row>
      <Row wrap="wrap" align="baseline">
        <DatePicker
          flex={1}
          size="XS"
          name="contract_guarantor.birthday"
          label='app_users:birthday'
          placeholder={dayjs().subtract(20, 'year').format(FRONT_DATE_FORMAT)}
          disabledDate={disableAfterToday}
          required={requiredFields?.['contract_guarantor.birthday']}
        />
        <Select
          flex={1}
          size="XS"
          name="contract_guarantor.relation_id"
          label='contracts:invited_users.relation_name'
          placeholder=""
          options={relationOptions}
          loading={loadingRelations}
          required={requiredFields?.['contract_guarantor.relation_id']}
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name="contract_guarantor.phone"
          label='phone'
          placeholder="placeholders.phone"
          required={requiredFields?.['contract_guarantor.phone']}
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name="contract_guarantor.mobile_phone"
          label='mobile_phone'
          placeholder="placeholders.phone"
          required={requiredFields?.['contract_guarantor.mobile_phone']}
        />
      </Row>
      <Row wrap="wrap" align="start">
        <TextInput
          size="L"
          mr="0"
          type="text"
          name="contract_guarantor.postcode"
          label='properties:postcode'
          pattern="^\d{7}$"
          placeholder="placeholders.postcode"
          required={requiredFields?.['contract_guarantor.postcode']}
        />
        <SearchAddressButton
          postcodeKey="contract_guarantor.postcode"
          prefectureKey="contract_guarantor.prefecture"
          cityKey="contract_guarantor.city"
          addressKey="contract_guarantor.address"
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          name="contract_guarantor.prefecture"
          label='properties:prefecture'
          readonly
          required={requiredFields?.['contract_guarantor.prefecture']}
        />
        <TextInput
          type="text"
          name="contract_guarantor.city"
          label='properties:city'
          readonly
          required={requiredFields?.['contract_guarantor.city']}
        />
      </Row>
      <Row wrap="wrap">
        <TextInput
          flex={1}
          type="text"
          name="contract_guarantor.address"
          label='address'
          placeholder=""
          required={requiredFields?.['contract_guarantor.address']}
        />
      </Row>
      <Row wrap="wrap" align="baseline">
        <TextInput
          flex={1}
          size="L"
          type="text"
          name="contract_guarantor.work"
          label='contracts:guarantor.work'
          required={requiredFields?.['contract_guarantor.work']}
        />
        <TextInput
          flex={1}
          size="L"
          type="text"
          name="contract_guarantor.work_phone"
          label='app_users:work.phone'
          placeholder="placeholders.phone"
          required={requiredFields?.['contract_guarantor.work_phone']}
        />
        <TextInput
          flex={1}
          size="L"
          type="text"
          name="contract_guarantor.work_position"
          label='contracts:guarantor.position'
          required={requiredFields?.['contract_guarantor.work_position']}
        />
        <TextInput
          flex={1}
          type="number"
          size="S"
          name="contract_guarantor.annual_income"
          label='app_users:annual_income'
          suffix="app_users:annual_income_unit"
          required={requiredFields?.['contract_guarantor.annual_income']}
        />
      </Row>
      <Row wrap="wrap" align="start">
        <TextInput
          size="L"
          mr="0"
          type="text"
          name="contract_guarantor.work_postcode"
          label='app_users:work.postcode'
          pattern="^\d{7}$"
          placeholder="placeholders.postcode"
          required={requiredFields?.['contract_guarantor.work_postcode']}
        />
        <SearchAddressButton
          postcodeKey="contract_guarantor.work_postcode"
          prefectureKey="contract_guarantor.work_prefecture"
          cityKey="contract_guarantor.work_city"
          addressKey="contract_guarantor.work_address"
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          name="contract_guarantor.work_prefecture"
          label='properties:prefecture'
          readonly
          required={requiredFields?.['contract_guarantor.work_prefecture']}
        />
        <TextInput
          type="text"
          name="contract_guarantor.work_city"
          label='properties:city'
          readonly
          required={requiredFields?.['contract_guarantor.work_city']}
        />
      </Row>
      <Row wrap="wrap">
        <TextInput
          flex={1}
          type="text"
          name="contract_guarantor.work_address"
          label='app_users:work.address'
          placeholder=""
          required={requiredFields?.['contract_guarantor.work_address']}
        />
      </Row>
    </Card>
  )
}

GuarantorCard.propTypes = {

}

export default GuarantorCard
