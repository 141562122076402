import React, { useContext } from 'react'
import { ReadOnlyContext } from '@gk-devteam/apmc-core-web'
import SearchAddressButton from '../../../components/Misc/SearchAddressButton'

export default function ContractorAddressButton () {
  const isReadOnly = useContext(ReadOnlyContext)
  return (
    <SearchAddressButton
      postcodeKey="contractor.postcode"
      prefectureKey="contractor.prefecture"
      cityKey="contractor.city"
      addressKey="contractor.address"
      disabled={isReadOnly}
    />
  )
}
