import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  PageLayout,
  Card,
  Spinner,
  Row,
  Title,
  Text,
  FRONT_DATE_FORMAT,
  useTranslation,
  Tag
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_SUPPORT_CONTRACT,
  RESET_SUPPORT_CONTRACT
} from '../../types'
import {
  selectSupportLoading,
  selectSupportData
} from '../../selectors'

import {
  LoaderContainerStyle,
  RowStyle,
  GroupStyle,
  LabelStyle,
  ValueStyle
} from './SupportContractPage.style.js'
import dayjs from 'dayjs'
import { SEX_OPTIONS } from '../../constants'

const SupportContractPage = ({ contractId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const loading = useSelector(selectSupportLoading)
  const data = useSelector(selectSupportData)

  useEffect(() => {
    dispatch({ type: FETCH_SUPPORT_CONTRACT, id: contractId })
    return () => {
      dispatch({ type: RESET_SUPPORT_CONTRACT })
    }
  }, [dispatch, contractId])

  const _renderLoader = () => {
    return (
      <LoaderContainerStyle>
        <Spinner />
      </LoaderContainerStyle>
    )
  }

  const _renderTag = () => {
    if (data?.different_resident) return <Tag color="warning" label="support:different_resident" />
    return <Tag color="info" label="support:same_resident" />
  }

  return (
    <PageLayout>
      <Row justify="between" mb="M">
        <Title label='support:pageTitle' />
      </Row>
      {
        loading
          ? _renderLoader()
          : (
            <>
              <Card
                title="navigation:contracts"
                rightText={_renderTag()}
              >
                <RowStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="properties:property_name" />
                    </LabelStyle>
                    <ValueStyle>{data?.property_name}</ValueStyle>
                  </GroupStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="properties:property_id" />
                    </LabelStyle>
                    <ValueStyle>{data?.property_id}</ValueStyle>
                  </GroupStyle>
                </RowStyle>
                <RowStyle mt="M">
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="properties:room_number" />
                    </LabelStyle>
                    <ValueStyle>{data?.room_number}</ValueStyle>
                  </GroupStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="rooms:room_id" />
                    </LabelStyle>
                    <ValueStyle>{data?.room_id}</ValueStyle>
                  </GroupStyle>
                </RowStyle>
                <RowStyle mt="M">
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="address" />
                    </LabelStyle>
                    <ValueStyle>〒{data?.postcode || ''} {data?.prefecture || ''} {data?.city || ''} {data?.address || ''}</ValueStyle>
                  </GroupStyle>
                </RowStyle>
                <RowStyle mt="M">
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="contracts:move_in_date" />
                    </LabelStyle>
                    <ValueStyle>{data?.move_in ? dayjs(data?.move_in).format(FRONT_DATE_FORMAT) : '-'}</ValueStyle>
                  </GroupStyle>
                </RowStyle>
              </Card>

              <Card title="contracts:resident.resident_info" mt="L">
                <RowStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="contracts:resident_name" />
                    </LabelStyle>
                    <ValueStyle>{data?.resident_name}</ValueStyle>
                  </GroupStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="contracts:resident_name_furigana" />
                    </LabelStyle>
                    <ValueStyle>{data?.resident_name_furigana ?? '-'}</ValueStyle>
                  </GroupStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="contracts:resident_id" />
                    </LabelStyle>
                    <ValueStyle>{data?.resident_id}</ValueStyle>
                  </GroupStyle>
                </RowStyle>
                <RowStyle mt="M">
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="phone" />
                    </LabelStyle>
                    <ValueStyle>{data?.resident_phone ?? '-'}</ValueStyle>
                  </GroupStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="app_users:sex" />
                    </LabelStyle>
                    <ValueStyle>{data?.resident_sex != null ? t(SEX_OPTIONS.find(option => option.value === data?.resident_sex).label) : '-'}</ValueStyle>
                  </GroupStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="app_users:birthday" />
                    </LabelStyle>
                    <ValueStyle>{data?.resident_birthday ? dayjs(data?.resident_birthday).format(FRONT_DATE_FORMAT) : '-'}</ValueStyle>
                  </GroupStyle>
                </RowStyle>
              </Card>

              <Card title="contracts:contractor.contractor_info" mt="L">
                <RowStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="contracts:contractor_name" />
                    </LabelStyle>
                    <ValueStyle>{data?.contractor_name}</ValueStyle>
                  </GroupStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="contracts:contractor_name_furigana" />
                    </LabelStyle>
                    <ValueStyle>{data?.contractor_name_furigana ?? '-'}</ValueStyle>
                  </GroupStyle>
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="contracts:contractor_id" />
                    </LabelStyle>
                    <ValueStyle>{data?.contractor_id}</ValueStyle>
                  </GroupStyle>
                </RowStyle>
                <RowStyle mt="M">
                  <GroupStyle>
                    <LabelStyle>
                      <Text label="phone" />
                    </LabelStyle>
                    <ValueStyle>{data?.contractor_phone ?? '-'}</ValueStyle>
                  </GroupStyle>
                </RowStyle>
              </Card>
            </>
          )
      }
    </PageLayout>
  )
}
export default SupportContractPage

SupportContractPage.propTypes = {
  contractId: PropTypes.string.isRequired
}
SupportContractPage.defaultProps = {

}
