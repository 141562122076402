export const FETCH_NOTICES = 'fetch_notices'
export const FETCH_NOTICES_SUCCESS = 'fetch_notices_success'
export const FETCH_NOTICES_FAIL = 'fetch_notices_fail'
export const RESET_NOTICES = 'reset_notices'

export const DELETE_NOTICE = 'delete_notice'
export const DELETE_NOTICE_SUCCESS = 'delete_notice_success'
export const DELETE_NOTICE_FAIL = 'delete_notice_fail'

export const FETCH_NOTICE = 'fetch_notice'
export const FETCH_NOTICE_SUCCESS = 'fetch_notice_success'
export const FETCH_NOTICE_FAIL = 'fetch_notice_fail'
export const RESET_NOTICE = 'reset_notice'

export const POST_NOTICE = 'post_notice'
export const POST_NOTICE_SUCCESS = 'post_notice_success'
export const POST_NOTICE_FAIL = 'post_notice_fail'

export const OPEN_NOTICE_STATUS_MODAL = 'open_notice_status_modal'
export const CLOSE_NOTICE_STATUS_MODAL = 'close_notice_status_modal'

export const OPEN_NOTICE_CONDITIONS_MODAL = 'open_notice_conditions_modal'
export const CLOSE_NOTICE_CONDITIONS_MODAL = 'close_notice_conditions_modal'

export const SEND_NOTICE = 'send_notice'
export const STOP_NOTICE = 'stop_notice'
