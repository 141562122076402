import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_FORM,
  LeftSearchMenu,
  TextInput,
  selectQueryParams,
  Select
} from '@gk-devteam/apmc-core-web'

import { surveysSearchSchema } from '../../../validation'
import { SURVEY_STATUS_OPTIONS } from '../../../constants'

const initialValues = {
  title: '',
  status: ''
}

const SurveysSearchBar = () => {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        title: queryParams?.title,
        status: queryParams.status && Number(queryParams.status)
      }
    })
  }, [queryParams, dispatch])

  return (
    <>
      <LeftSearchMenu
        resetValues={initialValues}
        validationSchema={surveysSearchSchema}
      >
        <TextInput
          type="text"
          name="title"
          label='surveys:title'
          placeholder=""
          mr="0"
        />
        <Select
          name="status"
          label='status'
          placeholder=""
          options={SURVEY_STATUS_OPTIONS}
          mr="0"
        />
      </LeftSearchMenu>
    </>
  )
}

export default memo(SurveysSearchBar)

// InquiriesSearchBar.propTypes = {}
