import {
  styled,
  getColor,
  SPACING,
  FONT_SIZE,
  BORDER_RADIUS,
  HEAVY_BOX_SHADOW,
  NAVBAR_HEIGHT
} from '@gk-devteam/apmc-core-web'

export const RoomHeaderStyle = styled.div`
  position: sticky;
  top: ${NAVBAR_HEIGHT}rem;
  background-color: ${props => getColor(props, 'white')};
`

export const RoomInfoLinkStyle = styled.div`
  margin-right: ${SPACING.LARGE}rem;
`

export const ChatMessagesContainerStyle = styled.div`
  max-height: 450px;
  overflow-y: scroll;
`

export const AddFileContainerStyle = styled.div`
  ${FONT_SIZE.LARGE};
  ${BORDER_RADIUS};
  margin-right: ${SPACING.REGULAR}rem;
  display: flex;
  align-items: center;
  height: ${SPACING.LARGE}rem;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  padding: ${SPACING.SMALLEST / 2}rem ${SPACING.SMALLEST}rem;
  box-sizing: border-box;
  border: 1px solid ${props => getColor(props, 'lightText')};
  background-color: ${props => {
    if (props.disabled) return getColor(props, 'cardBorder')
  }};
  &:hover {
    background-color: ${props => {
      if (props.disabled) return getColor(props, 'lightText')
    }};
    color: ${props => {
      if (props.disabled) return getColor(props, 'white')
    }};
  }
`

export const PickerOuterContainerStyle = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 22;
`

export const EmojiPickerContainerStyle = styled.div`
  position: relative;

  .emoji-mart {
    ${HEAVY_BOX_SHADOW};
    position: absolute;
    bottom: 40px;
    left: -60px;
    z-index: 23;

    .emoji-mart-search, .emoji-mart-search > input {
      ${FONT_SIZE.REGULAR};
    }
    .emoji-mart-search {
      margin-bottom: ${SPACING.SMALLEST}rem;

      & > input {
        padding: 5px 25px 5px 10px;
      }

      .emoji-mart-search-icon {
        top: 50%;
        transform: translateY(-50%);
        outline: none;
      }
    }
    .emoji-mart-category-label {
      opacity: 0.9;
      & > span {
        ${FONT_SIZE.REGULAR};
      }
    }

    .emoji-mart-category-list > li > button {
      outline: none;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      & > span {
        display: inline-flex!important;
        justify-content: center!important;
        align-items: center!important;
      }
    }

  }
`

