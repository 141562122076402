import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const selectPropertyEvents = state => state.propertyEvents
const selectPropertyEventsDay = (state, day) => {
  return { day }
}

const findEventBetweenDates = (events, day) => {
  if (!day || !events) return null

  let selectedDay = day
  if (typeof day === 'string') selectedDay = dayjs(day)

  return events.filter(event => {
    const { start_time, end_time } = event
    const start = dayjs(start_time, 'YYYY-MM-DD')
    const end = dayjs(end_time, 'YYYY-MM-DD')

    return selectedDay.isSameOrBefore(end) && selectedDay.isSameOrAfter(start)
  })
}

export const selectPropertyEventsLoading = createSelector(
  selectPropertyEvents,
  events => events.loading
)
export const selectPropertyEventsPosting = createSelector(
  selectPropertyEvents,
  events => events.posting
)

export const selectPropertyEventsData = createSelector(
  selectPropertyEvents,
  events => events.results
)

export const selectPropertyEventsByDay = createSelector(
  [selectPropertyEventsData, selectPropertyEventsDay],
  (events, { day }) => {
    return findEventBetweenDates(events, day)
  }
)
export const selectPropertyEventsByDayCount = createSelector(
  [selectPropertyEventsData, selectPropertyEventsDay],
  (events, { day }) => {
    const total = findEventBetweenDates(events, day)
    return total?.length || 0
  }
)

export const selectPropertyEventsAddModalState = createSelector(
  selectPropertyEvents,
  events => events.addEventModal
)
export const selectPropertyEventsViewModalState = createSelector(
  selectPropertyEvents,
  events => events.viewEventsModal
)
export const selectActivePropertyEventDay = createSelector(
  selectPropertyEvents,
  events => events.activeDay
)
export const selectActivePropertyDayEvents = createSelector(
  selectPropertyEventsData,
  selectActivePropertyEventDay,
  (events, day) => {
    return findEventBetweenDates(events, day)
  }
)
