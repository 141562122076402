import {
  styled,
  getColor,
  SPACING,
  SelectStandAlone,
  Text,
  Row,
  Tag,
  Image,
  Link
} from '@gk-devteam/apmc-core-web'

export const FilterStyle = styled(SelectStandAlone)`
  width: 200px;
  color: ${props => getColor(props, 'lightText')};
`
export const LabelStyle = styled(Text)`
  margin-right: ${SPACING.SMALL}rem;
`

export const VicinityListItemHeaderStyle = styled(Row)`
  opacity: ${props => props.inactive ? 0.7 : 1};
`

export const TagStyle = styled(Tag)`
  &&& {
    margin-left: ${SPACING.LARGE}rem;
  }
`

export const ContentImageStyle = styled(Image)`
  width: 80px;
  height: 80px;
`

export const MainContentContainerStyle = styled.div`
  flex: 1;
  margin: 0 ${SPACING.REGULAR}rem;
`

export const ContentLabelStyle = styled(Text)`
  padding-left: ${SPACING.SMALLEST}rem;
`

export const LinkStyle = styled(Link)`
  display: inline-flex;
  align-items: center;

  span {
    margin-left: ${SPACING.SMALLEST}rem;
  }

`
