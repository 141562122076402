import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import {
  DatePicker
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

dayjs.extend(isSameOrBefore)

const FinalInspectionDate = ({ name, label }) => {
  const movingOutDate = useSelector(state => state?.form?.cancel_date)

  const disableNextDate = (current) => {
    if (current && movingOutDate) {
      const end = dayjs(movingOutDate).add(1, 'days')
      return current.isAfter(end)
    }
    return false
  }

  return (
    <DatePicker
      flex={1}
      size="M"
      name={name}
      label={label}
      placeholder=""
      disabledDate={disableNextDate}
      showTime
      required
    />
  )
}

export default FinalInspectionDate

FinalInspectionDate.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
