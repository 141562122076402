import React from 'react'
import {
  RangePicker
} from '@gk-devteam/apmc-core-web'

const NoticeTime = () => {
  return (
    <>
      <RangePicker
        name_start="start_date_from"
        name_end="start_date_to"
        label='notices:start_date'
        placeholder=""
        mr="0"
      />
      <RangePicker
        name_start="stop_time_from"
        name_end="stop_time_to"
        label='notices:stop_time'
        placeholder=""
        mr="0"
      />
    </>
  )
}

export default NoticeTime

// ContractDatepickers.propTypes = {}
