import React, { memo } from 'react'
import {
  PrivateRoute
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import NotFoundPage from '../pages/NotFoundPage'

const CustomPrivateRoute = (props) => {
  return <PrivateRoute notFound={NotFoundPage} {...props} />
}

export default memo(CustomPrivateRoute)

PrivateRoute.propTypes = {}
