import { createSelector } from 'reselect'

const inspectionSlice = state => state.contractInspections

export const selectContractInspectionsLoading = createSelector(
  inspectionSlice,
  contractInspections => contractInspections.loading
)
export const selectContractInspectionsData = createSelector(
  inspectionSlice,
  contractInspections => contractInspections.data
)
export const selectAddInspectionModalState = createSelector(
  inspectionSlice,
  contractInspections => contractInspections.addInspectionModal
)
export const selectContractInspectionsPosting = createSelector(
  inspectionSlice,
  contractInspections => contractInspections.posting
)
export const selectContractInspectionsDeleting = createSelector(
  inspectionSlice,
  contractInspections => contractInspections.deleting
)
