import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_PROPERTIES,
  RESET_PROPERTIES
} from '../../../types'

import PropertiesForm from './PropertiesForm'
import PropertiesList from './PropertiesList'
import SelectedItems from './SelectedItems'

import {
  ResultsBlockStyle,
  BetweenStyle
} from './common.style'

const PROPERTIES_LIMIT = 100

const PropertiesModal = ({ visible, close }) => {
  const dispatch = useDispatch()

  const fetchData = useCallback(
    ({
      propertyName = '',
      id = '',
      manageID = ''
    }) => {
      dispatch({
        type: FETCH_PROPERTIES,
        query: {
          property_name: propertyName,
          property_id: id,
          property_manage_id: manageID,
          limit: PROPERTIES_LIMIT
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    fetchData({})
    return () => {
      dispatch({ type: RESET_PROPERTIES })
    }
  }, [fetchData, dispatch])

  return (
    <Modal
      visible={visible}
      title='user_management:select_properties'
      onCancel={close}
      cancelText='close'
      fullWidth
      fullHeight
      large
    >
      <PropertiesForm fetchData={fetchData}/>
      <ResultsBlockStyle>
        <PropertiesList />
        <BetweenStyle> {'>>'} </BetweenStyle>
        <SelectedItems itemsKey="properties" modal/>
      </ResultsBlockStyle>
    </Modal>
  )
}

export default PropertiesModal

PropertiesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}
