import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FRONT_DATE_FORMAT, useTranslation } from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { selectPropertyEventsByDayCount } from '../../../selectors'
import { SET_ACTIVE_PROPERTY_EVENTS, TOGGLE_VIEW_PROPERTY_EVENTS_MODAL } from '../../../types'

import { DayCellStyle } from './PropertyEventsPage.style'

function PropertyEventCell ({ day, outOfMonth }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const count = useSelector(state => selectPropertyEventsByDayCount(state, day))

  const _handleCellClick = () => {
    dispatch({ type: SET_ACTIVE_PROPERTY_EVENTS, payload: day.format(FRONT_DATE_FORMAT) })
    dispatch({ type: TOGGLE_VIEW_PROPERTY_EVENTS_MODAL })
  }

  if (!day) return null
  return (
    <DayCellStyle outOfMonth={outOfMonth} onClick={_handleCellClick} align="start" withEvent={count > 0}>
      { day.format('DD') }
      <span>{count} {t('properties:events.event')}</span>
    </DayCellStyle>
  )
}

PropertyEventCell.propTypes = {
  day: PropTypes.object,
  outOfMonth: PropTypes.bool
}

export default PropertyEventCell
