import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  EmptyData
} from '@gk-devteam/apmc-core-web'

import { BULK_TOGGLE_GUIDE_CATEGORIES } from '../../../../types'

import GuideCategoryItem from './GuideCategoryItem'
import PanelBlockHeader from '../../../../components/Display/Panels/PanelBlockHeader'
import {
  BlockStyle
} from '../../panels.style'

const GuideCategoriesList = ({
  dataSelector,
  displaySelector,
  idSelector,
  title,
  type,
  handleItemDisplay,
  sideMenu,
  level,
  guidePage
}) => {
  const guideCategories = useSelector(dataSelector)

  const _renderList = () => {
    if (!guideCategories) return null
    if (guideCategories.length === 0) return <EmptyData />
    return guideCategories.map((item, i) => {
      return (
        <GuideCategoryItem
          key={i}
          item={item}
          handleItemDisplay={handleItemDisplay}
          sideMenu={sideMenu}
          idSelector={idSelector}
          level={level}
          guidePage={guidePage}
        />
      )
    })
  }

  return (
    <BlockStyle>
      <PanelBlockHeader
        title={title}
        idSelector={idSelector}
        displaySelector={displaySelector}
        type={type}
        level={level}
        toggleType={BULK_TOGGLE_GUIDE_CATEGORIES}
        authorized="guides.functions.update"
      />

      { _renderList() }

    </BlockStyle>
  )
}

export default GuideCategoriesList

GuideCategoriesList.propTypes = {
  dataSelector: PropTypes.func.isRequired,
  displaySelector: PropTypes.func.isRequired,
  idSelector: PropTypes.func,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['admin_guide_categories', 'property_guide_categories', 'guide_categories']).isRequired,
  level: PropTypes.oneOf(['admin', 'property', 'room']).isRequired,
  guidePage: PropTypes.oneOf(['admin_details', 'property_details']).isRequired,
  handleItemDisplay: PropTypes.func.isRequired,
  sideMenu: PropTypes.array
}
