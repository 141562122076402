import React from 'react'
import { useSelector } from 'react-redux'
import {
  Card,
  Row,
  FilePicker,
  TextInput,
  Checkbox
} from '@gk-devteam/apmc-core-web'

const PropertyOwnerInfo = () => {
  const enabled = useSelector(state => state.form.property_owner_enabled)
  return (
    <Card
      title='properties:property_owner_info'
      toggleName="property_owner_enabled"
      mb="L"
    >
      <Row wrap="wrap" align="start">
        <TextInput
          flex={1}
          type="text"
          name="property_owner.owner_name"
          label='properties:owner_name'
          placeholder=""
          required={enabled}
          readonly={!enabled}
        />
        <TextInput
          flex={1}
          type="text"
          name="property_owner.owner_furigana"
          label='furigana'
          placeholder=""
          required={enabled}
          readonly={!enabled}
        />
      </Row>
      <Row wrap="wrap" align="start">
        <TextInput
          flex={1}
          type="text"
          name="property_owner.owner_address"
          label='address'
          placeholder=""
          required={enabled}
          readonly={!enabled}
        />
      </Row>
      <Row wrap="wrap" align="start">
        <TextInput
          flex={1}
          type="text"
          name="property_owner.owner_phone"
          label='phone'
          placeholder="placeholders.phone"
          required={enabled}
          readonly={!enabled}
        />
        <Checkbox
          name="property_owner.owner_display_phone"
          inputLabel='properties:display_phone'
          disabled={!enabled}
          valueLabel='permission'
          help="properties:help.owner_display_phone"
        />
        <TextInput
          flex={1}
          type="text"
          name="property_owner.owner_contact_mail_address"
          label='properties:contact_mail_address'
          placeholder=""
          readonly={!enabled}
        />
      </Row>
      <Row wrap="wrap" align="start">
        <TextInput
          flex={1}
          type="text"
          name="property_owner.owner_url"
          label='homepage'
          placeholder=""
          readonly={!enabled}
        />
      </Row>
      <Row wrap="wrap" align="start">
        <TextInput
          flex={1}
          type="text"
          name="property_owner.owner_business_hour"
          label='properties:contact_hours'
          placeholder="properties:placeholder.owner_business_hour"
          readonly={!enabled}
        />
      </Row>
      <Row>
        <FilePicker
          name="property_owner.property_owner_image"
          label='properties:owner_image'
          delete_name="property_owner.delete_property_owner_image"
          types={['image']}
          readonly={!enabled}
          // defaultURL="https://firebasestorage.googleapis.com/v0/b/apm-cloud-stg.appspot.com/o/images%2Fsupport%3A158_1570521066461_2793868b-4508-badf-1d58-6005d1829ad8.jpeg?alt=media&token=21bd7629-f8b4-4759-9d04-24ef8479e2ab"
          // defaultURL="https://images.pexels.com/photos/459225/pexels-photo-459225.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
        />
      </Row>
    </Card>
  )
}

export default PropertyOwnerInfo

PropertyOwnerInfo.propTypes = {}
