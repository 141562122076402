import { put, call, takeLatest, cancelled, select } from 'redux-saga/effects'
import { CancelToken } from 'axios'
import { message } from 'antd'

import {
  fetchMember,
  postMember,
  deleteMember,
  sendMemberEmail
} from '../../services'
import {
  FETCH_MEMBERS,
  FETCH_MEMBER,
  FETCH_MEMBER_SUCCESS,
  FETCH_MEMBER_FAIL,
  POST_MEMBER,
  POST_MEMBER_SUCCESS,
  POST_MEMBER_FAIL,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  SEND_MEMBER_EMAIL
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchMemberSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchMember, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_MEMBER_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_MEMBER_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_MEMBER_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postMemberSaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postMember, data, cancelSource)
    if (res && res.data && res.data.success) {
      if (successRedirect) yield call(successRedirect)
      yield put({ type: POST_MEMBER_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_MEMBER_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_MEMBER_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteMemberSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteMember, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_MEMBER_SUCCESS })
      yield put({ type: FETCH_MEMBERS, query })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_MEMBER_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * sendMemberEmailSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(sendMemberEmail, id, cancelSource)
    if (res && res.status === 204) {
      yield call(message.success, i18n.t('mail_success'))
    } else {
      yield call(message.error, i18n.t('mail_error'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('mail_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchMember () {
  yield takeLatest(FETCH_MEMBER, fetchMemberSaga)
  yield takeLatest(POST_MEMBER, postMemberSaga)
  yield takeLatest(DELETE_MEMBER, deleteMemberSaga)
  yield takeLatest(SEND_MEMBER_EMAIL, sendMemberEmailSaga)
}
