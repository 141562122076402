import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  INIT_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectManualsLoading,
  selectManualsHiddenBy,
  selectManualsHiddenById
} from '../../../../selectors'
import { FETCH_MANUALS, RESET_MANUALS } from '../../../../types'
import initialData from '../common/initialData'

import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import AdminManualsList from './AdminManualsList'
import ManualModal from '../common/ManualModal'

const AdminManualsPage = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectManualsLoading)
  const [modalVisible, setModalVisible] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const breadcrumbs = [
    {
      label: 'navigation:settings',
      link: '/settings'
    },
    {
      label: 'navigation:settings_manuals',
      link: '/settings/manuals'
    }
  ]

  useEffect(() => {
    dispatch({ type: FETCH_MANUALS, config: { } })
    return () => {
      dispatch({ type: RESET_MANUALS })
    }
  }, [dispatch])

  const _openAddModal = () => {
    dispatch({ type: INIT_FORM, payload: initialData })
    setModalVisible(true)
  }
  const _openCopyModal = () => {
    setModalVisible(true)
  }
  const _openEditModal = () => {
    setModalVisible(true)
    setEditMode(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
    setEditMode(false)
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectManualsHiddenBy}
      hiddenByIdSelector={selectManualsHiddenById}
      title="navigation:settings_manuals"
      authorized="manuals.functions.add"
      info="services:level_message.admin"
      infoTitle="services:level_message.adminTitle"
      handleAddClick={_openAddModal}
    >
      <ManualModal
        visible={modalVisible}
        editMode={editMode}
        onCancel={_closeModal}
      />
      <AdminManualsList copyModal={_openCopyModal} editModal={_openEditModal} />
    </PanelLayout>
  )
}
export default AdminManualsPage

AdminManualsPage.propTypes = {}
AdminManualsPage.defaultProps = {}
