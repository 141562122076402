import { api, DEFAULT_QUERY_LIMIT } from '@gk-devteam/apmc-core-web'

export const fetchRooms = (params = {}, propertyID, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'room_number',
    sort_order: sort_order || 'asc'
  }

  return api.get(`properties/${propertyID}/rooms`, {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource && cancelSource.token

  })
}

export const fetchRoom = (propertyID, roomID, cancelSource) => {
  return api.get(`properties/${propertyID}/rooms/${roomID}`, {
    cancelToken: cancelSource.token
  })
}

export const postRoom = (data, propertyID, cancelSource) => {
  if (data && propertyID) {
    return api.post(`properties/${propertyID}/rooms`, data, {
      cancelToken: cancelSource.token
    })
  }
}

export const deleteRoom = (propertyID, roomID, cancelSource) => {
  return api.delete(`properties/${propertyID}/rooms/${roomID}`, {
    cancelToken: cancelSource && cancelSource.token
  })
}
