import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  INIT_FORM,
  RESET_FORM
} from '@gk-devteam/apmc-core-web'

import { selectAdminFaqData, selectAdminFaqAllDisplayed } from '../../../../selectors'
import { TOGGLE_FAQ, DELETE_FAQ } from '../../../../types'

import FaqList from '../common/FaqList'

const AdminFaqList = ({
  filter,
  copyModal,
  editModal
}) => {
  const dispatch = useDispatch()

  const _handleEdit = (data, closeMenu, rest) => {
    dispatch({ type: RESET_FORM })
    const { faq_id, question, answer } = data
    dispatch({
      type: INIT_FORM,
      payload: {
        id: faq_id,
        question: question,
        answer: answer,
        category: rest.category
      }
    })
    editModal()
    closeMenu()
  }
  const _handleCopy = (data, closeMenu, rest) => {
    dispatch({ type: RESET_FORM })
    const { question, answer } = data
    dispatch({
      type: INIT_FORM,
      payload: {
        category: rest.category,
        question,
        answer
      }
    })
    copyModal()
    closeMenu()
  }
  const _handleDelete = (data, closeMenu) => {
    dispatch({ type: DELETE_FAQ, id: data.faq_id, config: { } })
    closeMenu()
  }

  const menu = [
    {
      label: 'edit',
      onClick: _handleEdit,
      shouldRender: 'faq.functions.update'
    },
    {
      label: 'copy',
      onClick: _handleCopy,
      shouldRender: 'faq.functions.add'
    },
    {
      label: 'delete',
      onClick: _handleDelete,
      danger: true,
      shouldRender: 'faq.functions.delete'
    }
  ]

  const _handleFaqDisplay = useCallback(
    (display, id, item) => {
      const data = {
        id: item.faq_id,
        display
      }
      const config = {
        type: 'admin_faqs'
      }
      dispatch({ type: TOGGLE_FAQ, data, config })
    },
    [dispatch]
  )

  return (
    <FaqList
      dataSelector={selectAdminFaqData}
      displaySelector={selectAdminFaqAllDisplayed}
      // idSelector={}
      title=" "
      filter={filter}
      type="admin_faqs"
      level="property"
      editModal={editModal}
      handleFaqDisplay={_handleFaqDisplay}
      sideMenu={menu}
    />
  )
}

export default AdminFaqList

AdminFaqList.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  copyModal: PropTypes.func.isRequired,
  editModal: PropTypes.func.isRequired
}
