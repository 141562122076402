import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const noticeUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    push_notification: yup
      .boolean()
      .required()
      .label(i18n.t('notices:push_notification')),
    tag: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .label(i18n.t('common:tag')),
    title: yup.string().max(255).nullable().required().label(i18n.t('common:title')),
    content: yup.string().max(10000).nullable().required().label(i18n.t('common:content')),
    url: yup.string().max(255).nullable().label(i18n.t('external_url')),
    video_url: yup.string().max(255).nullable().label(i18n.t('video_url')),
    notice_type: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .min(0)
      .max(2)
      .label(i18n.t('notices:notice_type')),
    start_time: yup
      .date()
      .nullable()
      .when('notice_type', {
        is: 2,
        then: yup
          .date()
          .nullable()
          .required()
      })
      .label(i18n.t('notices:start_date')),
    stop_time: yup.date().nullable().label(i18n.t('notices:stop_time')),
    recipient_type: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .min(0)
      .max(4)
      .label(i18n.t('notices:recipient_type')),
    recipients_id: yup
      .array()
      .when('recipient_type', {
        is: 0,
        then: yup
          .array()
          .nullable(),
        otherwise: yup
          .array()
          .required()
          .of(
            yup
              .number()
              .transform((cv, ov) => ov === '' ? undefined : cv)
              .integer()
              .required()
          )
      })
      .label(i18n.t('notices:recipients_id')),
    recipients_condition: yup.array().of(
      yup.object().shape({
        min_income: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .isMore('max_income', i18n.t('notices:max_income'))
          .nullable()
          .label(i18n.t('notices:min_income')),
        max_income: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .isLess('min_income', i18n.t('notices:min_income'))
          .nullable()
          .label(i18n.t('notices:max_income')),
        sex: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .nullable()
          .min(0)
          .max(2)
          .label(i18n.t('app_users:sex')),
        min_age: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .isMore('max_age', i18n.t('notices:max_age'))
          .nullable()
          .label(i18n.t('notices:min_age')),
        max_age: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .isLess('min_age', i18n.t('notices:min_age'))
          .nullable()
          .label(i18n.t('notices:max_age'))
      })
    ).nullable().label(i18n.t('notices:recipients_condition')),
    user_type: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .min(1)
      .max(5)
      .label(i18n.t('notices:user_type')),
    delete_file: yup.bool().required().label(i18n.t('common:delete_file_flag'))
  })
}
