import { put, call, takeLatest, cancelled, select } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'
import { i18n } from '../../locales'

import {
  fetchChecklistHome,
  fetchChecklist,
  fetchChecklistIssue,
  postChecklistIssueComment,
  updateChecklistIssuesStatus,
  updateChecklistIssueStatus
} from '../../services'

import {
  FETCH_CHECKLIST_HOME,
  FETCH_CHECKLIST_HOME_SUCCESS,
  FETCH_CHECKLIST_HOME_FAIL,
  FETCH_CHECKLIST,
  FETCH_CHECKLIST_SUCCESS,
  FETCH_CHECKLIST_FAIL,
  FETCH_CHECKLIST_ISSUE,
  FETCH_CHECKLIST_ISSUE_SUCCESS,
  FETCH_CHECKLIST_ISSUE_FAIL,
  POST_CHECKLIST_ISSUE_COMMENT,
  POST_CHECKLIST_ISSUE_COMMENT_SUCCESS,
  POST_CHECKLIST_ISSUE_COMMENT_FAIL,
  UPDATE_CHECKLIST_ISSUE_STATUS,
  UPDATE_CHECKLIST_ISSUE_STATUS_SUCCESS,
  UPDATE_CHECKLIST_ISSUE_STATUS_FAIL,
  UPDATE_CHECKLIST_ISSUES_STATUS,
  UPDATE_CHECKLIST_ISSUES_STATUS_SUCCESS,
  UPDATE_CHECKLIST_ISSUES_STATUS_FAIL
} from '../../types'

const getQuery = state => state.query

export function * fetchChecklistHomeSaga () {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchChecklistHome, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_CHECKLIST_HOME_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_CHECKLIST_HOME_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_CHECKLIST_HOME_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * fetchChecklistSaga ({ params = {} }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchChecklist, params, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_CHECKLIST_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_CHECKLIST_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_CHECKLIST_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * fetchChecklistIssueSaga ({ checklistID }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchChecklistIssue, checklistID, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_CHECKLIST_ISSUE_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_CHECKLIST_ISSUE_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_CHECKLIST_ISSUE_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postChecklistIssueSaga ({ checklistID, data }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postChecklistIssueComment, checklistID, data, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: POST_CHECKLIST_ISSUE_COMMENT_SUCCESS })
      yield put({ type: FETCH_CHECKLIST_ISSUE, checklistID })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CHECKLIST_ISSUE_COMMENT_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
    yield put({ type: POST_CHECKLIST_ISSUE_COMMENT_FAIL, payload: error.data || error.message })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * updateChecklistIssuesStatusSaga ({ data, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(updateChecklistIssuesStatus, data, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: UPDATE_CHECKLIST_ISSUES_STATUS_SUCCESS })
      if (onSuccess) onSuccess() // reset selected rows
      const params = yield select(getQuery)
      yield put({ type: FETCH_CHECKLIST, params })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: UPDATE_CHECKLIST_ISSUES_STATUS_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
    yield put({ type: UPDATE_CHECKLIST_ISSUES_STATUS_FAIL, payload: error.data || error.message })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * updateChecklistIssueStatusSaga ({ checklistID, data }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(updateChecklistIssueStatus, checklistID, data, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: UPDATE_CHECKLIST_ISSUE_STATUS_SUCCESS })
      yield put({ type: FETCH_CHECKLIST_ISSUE, checklistID })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: UPDATE_CHECKLIST_ISSUE_STATUS_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
    yield put({ type: UPDATE_CHECKLIST_ISSUE_STATUS_FAIL, payload: error.data || error.message })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchChecklist () {
  yield takeLatest(FETCH_CHECKLIST_HOME, fetchChecklistHomeSaga)
  yield takeLatest(FETCH_CHECKLIST, fetchChecklistSaga)
  yield takeLatest(FETCH_CHECKLIST_ISSUE, fetchChecklistIssueSaga)
  yield takeLatest(POST_CHECKLIST_ISSUE_COMMENT, postChecklistIssueSaga)
  yield takeLatest(UPDATE_CHECKLIST_ISSUES_STATUS, updateChecklistIssuesStatusSaga)
  yield takeLatest(UPDATE_CHECKLIST_ISSUE_STATUS, updateChecklistIssueStatusSaga)
}
