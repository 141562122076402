import { put, call, cancelled, takeLatest } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchCoupons
} from '../../services'

import {
  FETCH_COUPONS_SUCCESS,
  FETCH_COUPONS_FAIL,
  FETCH_COUPONS
} from '../../types'
import { i18n } from '../../locales'

export function * fetchCouponsSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchCoupons, query, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_COUPONS_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_COUPONS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_COUPONS_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchCoupons () {
  yield takeLatest(FETCH_COUPONS, fetchCouponsSaga)
}
