import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_SERVICE,
  FETCH_SERVICE_SUCCESS,
  FETCH_SERVICE_FAIL,
  RESET_SERVICE,
  FETCH_SERVICE_BY_LEVEL,
  FETCH_SERVICE_BY_LEVEL_SUCCESS,
  FETCH_SERVICE_BY_LEVEL_FAIL,
  RESET_SERVICE_BY_LEVEL,
  POST_SERVICE_DISPLAY,
  POST_SERVICE_DISPLAY_SUCCESS,
  POST_SERVICE_DISPLAY_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  loading_level: true,
  posting: null,
  data: null,
  error: null,
  results: null,
  results_count: null,
  max_pages: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_SERVICE]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_SERVICE_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_SERVICE_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_SERVICE_DISPLAY]: (state, action) => {
    state.posting = true
    state.error = null
  },
  [POST_SERVICE_DISPLAY_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_SERVICE_DISPLAY_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_SERVICE_BY_LEVEL]: (state, action) => {
    state.loading_level = true
  },
  [FETCH_SERVICE_BY_LEVEL_SUCCESS]: (state, action) => {
    const { max_pages, results_count, results } = action.payload
    state.loading_level = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_SERVICE_BY_LEVEL_FAIL]: (state, action) => {
    state.loading_level = false
    state.error = action.payload
  },
  [RESET_SERVICE_BY_LEVEL]: (state) => {
    state.loading_level = true
    state.results = null
    state.results_count = null
    state.max_pages = null
  },
  [RESET_SERVICE]: () => INITIAL_STATE
})
