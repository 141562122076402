import React, { memo, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_FORM,
  LeftSearchMenu,
  TextInput,
  Select,
  selectQueryParams,
  DatePicker
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_AREAS,
  RESET_AREAS
} from '../../../types'

import {
  selectPrefecturesOptions,
  selectCitiesOptions,
  selectAreasLoading
} from '../../../selectors'
import { yasueConstructionWorksSearchSchema } from '../../../validation'
import { AREA_TYPE } from '../../../constants'

const initialValues = {
  work_number: '',
  delivery_date: '',
  prefecture: '',
  city: ''
}

const ConstructionWorksSearchBar = () => {
  const prevPrefectureRef = useRef()
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)
  const prefecturesOptions = useSelector(selectPrefecturesOptions)
  const cities = useSelector(selectCitiesOptions)
  const loadingAreas = useSelector(selectAreasLoading)
  const watchedPrefecture = useSelector(state => state.form.prefecture)

  useEffect(() => {
    if (!prevPrefectureRef.current) {
      prevPrefectureRef.current = watchedPrefecture
    } else {
      if (prevPrefectureRef.current !== watchedPrefecture) {
        prevPrefectureRef.current = watchedPrefecture
        dispatch({ type: UPDATE_FORM, payload: { city: '' } })
      }
    }
  }, [watchedPrefecture, dispatch])

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        work_number: queryParams.work_number,
        delivery_date: queryParams.delivery_date,
        prefecture: queryParams.prefecture,
        city: queryParams.city
      }
    })
  }, [queryParams, dispatch])

  const cityOptions = watchedPrefecture ? cities[watchedPrefecture] : []

  useEffect(() => {
    dispatch({ type: FETCH_AREAS, query: { type: AREA_TYPE.yasue_construction_work } })
    return () => {
      dispatch({ type: RESET_AREAS })
    }
  }, [dispatch])

  return (
    <>
      <LeftSearchMenu
        resetValues={initialValues}
        validationSchema={yasueConstructionWorksSearchSchema}
      >
        <TextInput
          name="work_number"
          label='yasue:construction.work_number'
          mr="0"
        />
        <DatePicker
          name="delivery_date"
          label='yasue:construction.delivery_date'
          placeholder=""
          mr="0"
        />
        <Select
          name="prefecture"
          label='properties:prefecture'
          placeholder=""
          options={prefecturesOptions}
          loading={loadingAreas}
          mr="0"
        />
        <Select
          name="city"
          label='properties:city'
          placeholder=""
          options={cityOptions}
          loading={loadingAreas}
          disabled={!watchedPrefecture}
          mr="0"
        />
      </LeftSearchMenu>
    </>
  )
}

export default memo(ConstructionWorksSearchBar)
