import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dot } from 'dot-object'
import PropTypes from 'prop-types'
import {
  ToastTitle,
  Row,
  Rates,
  TriStateCheckbox
} from '@gk-devteam/apmc-core-web'

import { selectVicinitiesCategories, selectPropertyID, selectPages } from '../../../../selectors'
import { TOGGLE_VICINITY } from '../../../../types'

import {
  VicinityListItemHeaderStyle,
  TagStyle
} from './PropertyVicinitiesPage.style'

const VicinityListItemHeader = ({ item }) => {
  const dispatch = useDispatch()
  const categories = useSelector(selectVicinitiesCategories)
  const propertyID = useSelector(selectPropertyID)
  const pages = useSelector(selectPages)
  if (!categories || !pages) return null
  const {
    category,
    display,
    id,
    title,
    vicinity_type_id,
    rating
  } = item

  const selectedCategory = categories.find(cat => cat.id === category)
  const categoryLabel = selectedCategory && selectedCategory.name
  // if (!selectedCategory) {
  //   console.log('selectedCategory', selectedCategory, category)
  //   console.log('categoryLabel', categoryLabel)
  // }

  const _handleToggleVicinity = () => {
    const data = {
      id,
      display: !display,
      type: vicinity_type_id
    }
    dispatch({ type: TOGGLE_VICINITY, data, id: propertyID })
  }

  const _isDisabled = () => {
    const dotted = dot(pages)
    return !dotted['vicinities.functions.update']
  }

  return (
    <VicinityListItemHeaderStyle justify="between" inactive={!display}>
      <Row>
        <TriStateCheckbox
          mr="ML"
          mb="0"
          size="fluid"
          noLabel
          autoH
          name={`vicinity_${id}`}
          value={display}
          handleChange={_handleToggleVicinity}
          small
          disabled={_isDisabled()}
        />
        <ToastTitle text={title} />
        <TagStyle color="info" label={categoryLabel}/>
      </Row>
      {
        rating ? <Rates initialValue={rating} readOnly/> : null
      }
    </VicinityListItemHeaderStyle>
  )
}

export default VicinityListItemHeader

VicinityListItemHeader.propTypes = {
  item: PropTypes.shape(PropTypes.string.isRequired, {
    category: PropTypes.string.isRequired,
    display: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    vicinity_type_id: PropTypes.number.isRequired,
    rating: PropTypes.number
  }).isRequired
}
