import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MainLayout,
  Tabs,
  updateQueryParameters,
  selectQueryParams,
  Row,
  Title,
  RoleControlled
} from '@gk-devteam/apmc-core-web'
import { Tabs as AntTab } from 'antd'

import ChatSettings from './ChatSettings'
import TabHeader from './TabHeader'
import ChecklistSettings from './ChecklistSettings'
const { TabPane } = AntTab

const AdminTasksSettingsPage = () => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)

  const [activeKey, setActiveKey] = useState('0')
  // const { t } = useTranslation()
  const breadcrumbs = [
    {
      label: 'navigation:settings',
      link: '/settings'
    },
    {
      label: 'navigation:settings_admin_tasks_settings',
      link: '/settings/admin_tasks'
    }
  ]

  useEffect(() => {
    switch (query?.type) {
      case 'chat':
        setActiveKey('0')
        break
      case 'checklist':
        setActiveKey('1')
        break
      default:
        break
    }
  }, [query, dispatch])

  const _handleTabChange = (activeKey) => {
    setActiveKey(activeKey)
    let type
    switch (Number(activeKey)) {
      case 0:
        type = 'chat'
        break
      case 1:
        type = 'checklist'
        break
      default:
        break
    }
    if (type) {
      dispatch(updateQueryParameters({
        type: String(type),
        limit: '',
        page: ''
      }))
    }
  }

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      // loading={loading}
      // lastEdited={lastEdited}
      // lastEditedBy={lastEditedBy}
    >
      <Row mb="ML">
        <Title label='navigation:settings_admin_tasks_settings' />
      </Row>

      <Tabs
        activeKey={activeKey}
        onChange={_handleTabChange}
        destroyInactiveTabPane={true}
      >
        <TabPane key="0" tab={<TabHeader header="chat.chat_settings" />}>
          <ChatSettings />
        </TabPane>

        <TabPane key="1" tab={<RoleControlled authorized='!custom.yasue'><TabHeader header="admin_tasks:checklist_settings" /></RoleControlled>}>
          <RoleControlled authorized='!custom.yasue'>
            <ChecklistSettings />
          </RoleControlled>
        </TabPane>

      </Tabs>
    </MainLayout>
  )
}
export default AdminTasksSettingsPage

AdminTasksSettingsPage.propTypes = {}
AdminTasksSettingsPage.defaultProps = {}
