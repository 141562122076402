import { createSelector } from 'reselect'

export const selectAdminProfile = state => state.adminProfile

export const selectLoadingSettingAdminProfile = createSelector(
  selectAdminProfile,
  adminProfile => adminProfile.loading
)

export const selectPostingSettingAdminProfile = createSelector(
  selectAdminProfile,
  adminProfile => adminProfile.posting
)
export const selectSettingAdminProfileData = createSelector(
  selectAdminProfile,
  adminProfile => adminProfile.data || {}
)
export const selectSettingAdminProfileID = createSelector(
  selectAdminProfile,
  adminProfile => adminProfile.data && adminProfile.data.id
)
export const selectSettingAdminProfileName = createSelector(
  selectAdminProfile,
  adminProfile => adminProfile.data && adminProfile.data.name
)
