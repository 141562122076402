/* eslint-disable no-template-curly-in-string */

import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'
import { customCsvParser } from '../../utils'

const testHeaders = (headers, validHeaders) => {
  // console.log(headers, validHeaders)
  if (headers.every(header => validHeaders.includes(header))) return true
  return false
}

const testCSV = async ({ data, headers }) => {
  if (!data) return false
  try {
    const file = await fetch(data).then(r => r.blob())
    const csv = await customCsvParser({ file })

    // Test if CSV is not empty
    if (!csv || csv.length === 0) return false
    // console.log('csv length ok')
    // Test if headers fit expected shape
    if (!testHeaders(Object.keys(csv[0]), headers)) return false
    // console.log('csv headers ok')
  } catch (error) {
    console.warn(error)
    return false
  }
  return true
}

const testBillingCSV = (data) => {
  if (!data) return true // field is not required so let the test pass if no data present
  return testCSV({
    data,
    headers: [
      'contract_manage_id',
      'resident.user_manage_id',
      'property_manage_id',
      'room_number',
      'resident.phone',
      'hot_water_variable',
      'water',
      'variable',
      'hot_water',
      'electricity',
      'phone',
      'three_phase_electricity',
      'cooling_heating',
      'gas',
      'GoukeiHontaiKingaku',
      'GoukeiShouhizeigaku',
      'KensinDate',
      'Konkaiti',
      'Siyouryou',
      'Zenkaiti'
    ]
  })
}

export const choueiBillingUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    billing_file: yup
      .string()
      .nullable()
      .isBlob()
      .test(
        'datasync-csv-format',
        i18n.t('not_type', { label: '${label}' }),
        testBillingCSV
      )
      .label(i18n.t('chouei:billing_file'))
  })
}
