import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Form,
  TextInput
} from '@gk-devteam/apmc-core-web'

import { contactSchema } from '../../validation'

const ContactForm = ({ saveContact }) => {
  return (
    <Form
      id="contact_form"
      handleSubmit={saveContact}
      validationSchema={contactSchema}
      authorized="contacts.functions.update"
      // loadingSelector={selectLoadingAppUser}
      // postingSelector={selectPostingAppUser}
      // initialData={initialValues}
      // debug
    >
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="label"
          label='contacts:contact_label'
          placeholder=""
          required
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="name"
          label='contacts:contact_name'
          placeholder=""
          required
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          type="phone"
          name="phone"
          label='phone'
          placeholder="placeholders.phone"
          help="contacts:help.email_or_phone"
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          type="email"
          name="mail_address"
          label='email'
          placeholder="placeholders.email"
          help="contacts:help.email_or_phone"
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="hours"
          label='properties:contact_hours'
          placeholder=""
        />
      </Row>
    </Form>
  )
}

export default memo(ContactForm)

ContactForm.propTypes = {
  saveContact: PropTypes.func.isRequired
}
