import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchPropertyDocuments,
  postPropertyDocument,
  deletePropertyDocument
} from '../../services'
import {
  FETCH_PROPERTY_DOCUMENTS,
  FETCH_PROPERTY_DOCUMENTS_SUCCESS,
  FETCH_PROPERTY_DOCUMENTS_FAIL,
  POST_PROPERTY_DOCUMENT,
  POST_PROPERTY_DOCUMENT_SUCCESS,
  POST_PROPERTY_DOCUMENT_FAIL,
  DELETE_PROPERTY_DOCUMENT,
  DELETE_PROPERTY_DOCUMENT_SUCCESS,
  DELETE_PROPERTY_DOCUMENT_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchPropertyDocumentsSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchPropertyDocuments, query, cancelSource)
    if (res?.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_PROPERTY_DOCUMENTS_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_PROPERTY_DOCUMENTS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    console.log('err', error)
    yield put({ type: FETCH_PROPERTY_DOCUMENTS_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postPropertyDocumentSaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()

  try {
    const res = yield call(postPropertyDocument, data, cancelSource)
    if (res?.status === 204) {
      yield put({ type: POST_PROPERTY_DOCUMENT_SUCCESS })
      yield put({ type: FETCH_PROPERTY_DOCUMENTS, query: { property_id: data.property_id } })
      if (successRedirect) yield call(successRedirect)
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_PROPERTY_DOCUMENT_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_PROPERTY_DOCUMENT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deletePropertyDocumentSaga ({ documentID, propertyID }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deletePropertyDocument, documentID, cancelSource)
    if (res) {
      yield put({ type: DELETE_PROPERTY_DOCUMENT_SUCCESS })
      yield put({ type: FETCH_PROPERTY_DOCUMENTS, query: { property_id: propertyID } })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_PROPERTY_DOCUMENT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchPropertyDocuments () {
  yield takeLatest(FETCH_PROPERTY_DOCUMENTS, fetchPropertyDocumentsSaga)
  yield takeLatest(POST_PROPERTY_DOCUMENT, postPropertyDocumentSaga)
  yield takeLatest(DELETE_PROPERTY_DOCUMENT, deletePropertyDocumentSaga)
}
