import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_MEMBERS,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAIL,
  RESET_MEMBERS,
  DELETE_MEMBER,
  // DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  max_pages: null,
  results_count: null,
  results: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_MEMBERS]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_MEMBERS_SUCCESS]: (state, action) => {
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_MEMBERS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_MEMBERS]: (state, action) => {
    state.loading = true
    state.error = null
    state.max_pages = null
    state.results_count = null
    state.results = []
  },
  [DELETE_MEMBER]: (state, action) => {
    state.loading = true
  },
  [DELETE_MEMBER_FAIL]: (state, action) => {
    state.loading = false
  }
})
