import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import {
  useTranslation,
  api,
  UPDATE_FORM,
  PrimaryButton,
  Row,
  Text
} from '@gk-devteam/apmc-core-web'

import { ApprovalCancelStyle } from '../../ContractForm.style'

const WaitingUserApproval = ({ userType }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const approvalLimit = useSelector(state => state.form[`${userType}_approval_limit`])
  const userID = useSelector(state => state.form[`${userType}_user_id`])

  if (!userID) return null

  const _handleCancelApproval = () => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        [`${userType}_user_id`]: null,
        [`awaiting_${userType}_approval`]: null
      }
    })
  }
  const _handleResendApproval = async () => {
    try {
      await api.post(`/app_users/${userID}/approval/mail`)
      message.success(t('mail_success'))
    } catch (error) {
      message.error(t('mail_error'))
    }
  }

  return (
    <>
      <Row>
        <Text label="contracts:waiting_user_description" />
      </Row>
      <Row my="R">
        <ApprovalCancelStyle
          label="cancel"
          type="button"
          handleClick={_handleCancelApproval}
        />
        {
          approvalLimit && dayjs().isAfter(approvalLimit)
            ? (
              <PrimaryButton
                label="resend"
                type="button"
                handleClick={_handleResendApproval}
              />
            )
            : null
        }
      </Row>
    </>
  )
}

export default memo(WaitingUserApproval)

WaitingUserApproval.propTypes = {
  userType: PropTypes.oneOf(['contractor', 'resident']).isRequired
}
