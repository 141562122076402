
import React from 'react'
import { Redirect } from '@reach/router'
import { useSelector } from 'react-redux'
import {
  Card,
  Row,
  Text
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import {
  selectCurrentStep,
  selectImportProgress
} from '../../../selectors'
import { IMPORT_STEPS } from '../../../constants'

import ImportProgressBar from '../ImportProgressBar'
import {
  ProgressContainerStyle
} from './ImportsUploadPage.style'

const ImportsUploadPage = () => {
  const step = useSelector(selectCurrentStep)
  const progress = useSelector(selectImportProgress)

  if (step != null && step !== IMPORT_STEPS.UPLOAD) return <Redirect to="/import" noThrow/>
  return (
    <Card mt="XL">
      <ProgressContainerStyle>
        <Row mb="S" justify="between">
          <Text label="import:importing" />
          <span>{progress}%</span>
        </Row>
        <ImportProgressBar showInfo={false} />
      </ProgressContainerStyle>
    </Card>
  )
}
export default ImportsUploadPage

ImportsUploadPage.propTypes = {}
ImportsUploadPage.defaultProps = {

}
