import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import {
  FRONT_DATE_FORMAT,
  Form,
  Card,
  Row,
  FormSubmitButton,
  TextArea,
  InquiryID,
  Text,
  RoleControlled,
  Title,
  TextInput,
  DatePicker,
  Select,
  Copy,
  selectPages,
  DropdownButton,
  Confirm,
  DeleteButtonStyle,
  useTranslation,
  Tag
} from '@gk-devteam/apmc-core-web'

import {
  selectContractHistoryCount,
  selectLoadingAppUser,
  selectPostingAppUser
} from '../../selectors'
import { appUserUpdateValidation } from '../../validation'
import {
  // FETCH_APP_USER,
  POST_APP_USER,
  SEND_APP_USER_LOGIN,
  RESET_USER_LOGIN
} from '../../types'
import { INQUIRY_PREFIX, LOGIN_STATUS, SEX_OPTIONS } from '../../constants'

import AppUserNationality from '../CommonForms/AppUserNationality'
import SearchAddressButton from '../../components/Misc/SearchAddressButton'

import { UserLoginStyle, WaitingApprovalContainerStyle, ButtonsGroupStyle } from './AppUserForm.style.js'
import QrModal from '../../pages/app_users/common/QrModal'
import { downloadLoginPDF } from '../../services'

const AppUserForm = ({ data }) => {
  const { t } = useTranslation()
  const pages = useSelector(selectPages)
  const contractsCount = useSelector(selectContractHistoryCount)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userID = data && data.id
  const [qrVisible, setQrVisible] = useState(false)

  const initialValues = {
    ...data,
    birthday: data?.birthday || null
  }

  const handleFormSubmit = (data) => {
    const successRedirect = (id) => {
      // if (userID) {
      //   dispatch({ type: FETCH_APP_USER, id: userID })
      // } else {
      //   navigate(`/app_users/edit/${id}`)
      // }
      navigate('/app_users')
    }
    dispatch({ type: POST_APP_USER, data, userID, successRedirect })
  }

  const disableAfterToday = (current) => {
    if (current) {
      return current.isAfter(dayjs())
    }
    return false
  }

  const showQR = () => {
    setQrVisible(true)
  }
  const closeModal = () => {
    setQrVisible(false)
  }

  const _handleResetLogin = () => {
    dispatch({ type: RESET_USER_LOGIN, id: userID, origin: 'details' })
  }

  const _renderUserStatus = () => {
    let label, color
    switch (data?.login_status) {
      case LOGIN_STATUS.NO_LOGIN:
        label = 'app_users:login_status.no_login'
        color = 'text'
        break
      case LOGIN_STATUS.ACTIVE:
        label = 'app_users:login_status.active'
        color = 'info'
        break
      case LOGIN_STATUS.INACTIVE:
        label = 'app_users:login_status.inactive'
        color = 'pageTitle'
        break
      case LOGIN_STATUS.NO_CONFIRMATION:
        label = 'app_users:login_status.no_confirmation'
        color = 'accent'
        break

      default:
        break
    }

    return <Tag label={label} color={color}/>
  }

  return (
    <>
      <QrModal
        id={userID}
        visible={qrVisible}
        close={closeModal}
      />
      <Form
        id="updateAppUserForm"
        handleSubmit={handleFormSubmit}
        validationSchema={appUserUpdateValidation}
        loadingSelector={selectLoadingAppUser}
        postingSelector={selectPostingAppUser}
        initialData={initialValues}
        authorized="app_users.functions.update"
      // debug
      >
        {
          data && !data.approved_user
            ? (
              <WaitingApprovalContainerStyle mt="ML" mb="R" align="center">
                <ExclamationCircleOutlined />
                <Text label="contracts:waiting_user_description" />
              </WaitingApprovalContainerStyle>
            )
            : null
        }
        <Row justify="between" >
          <div>
            <Title text={data?.last_name ? `${data.last_name} ${data.first_name || ''}` : ''} />
          </div>
          <ButtonsGroupStyle>
            <RoleControlled authorized={data?.login_status === LOGIN_STATUS.NO_CONFIRMATION && 'app_users.functions.add'}>
              <Confirm
                title='app_users:reset_user_login_question'
                placement="topRight"
                onConfirm={_handleResetLogin}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                <DeleteButtonStyle>{t('app_users:reset_user_login')}</DeleteButtonStyle>
              </Confirm>
            </RoleControlled>
            <RoleControlled authorized={contractsCount > 0 && data?.login_status === LOGIN_STATUS.NO_LOGIN && 'app_users.functions.update'}>
              <DropdownButton
                label="app_users:temp_login.label"
                color="accent"
                withMargin
                items={[
                  {
                    label: 'app_users:temp_login.sms',
                    disabled: !data?.can_send_sms,
                    handleClick: () => { dispatch({ type: SEND_APP_USER_LOGIN, id: userID, sendType: 1, origin: 'details' }) }
                  },
                  {
                    label: 'app_users:temp_login.email',
                    disabled: !data?.can_send_email,
                    handleClick: () => { dispatch({ type: SEND_APP_USER_LOGIN, id: userID, sendType: 2, origin: 'details' }) }
                  },
                  {
                    label: 'app_users:temp_login.qr',
                    handleClick: showQR
                  },
                  {
                    label: 'app_users:temp_login.download_qr',
                    handleClick: () => { downloadLoginPDF(userID) }
                  }
                ]}
              />
            </RoleControlled>
            <RoleControlled authorized="app_users.functions.update">
              <FormSubmitButton editMode disabled={data && !data.approved_user} withMargin />
            </RoleControlled>
          </ButtonsGroupStyle>
        </Row>
        <Row mb="XS">
          { _renderUserStatus() }
        </Row>
        <Row mb="ML">
          <InquiryID id={userID} prefix={INQUIRY_PREFIX.USER} />
          {
            data && data.login_email ? (
              <>
                <UserLoginStyle label={data.login_email} color="info" />
                <Copy
                  dataToCopy={data.login_email}
                  copyTooltip="app_users:copy_login_email"
                />
              </>
            ) : null
          }
        </Row>
        <RoleControlled authorized="custom.house_maker">
          <Card my="L">
            <Row wrap="wrap" align="baseline">
              <TextInput
                flex={2}
                type="text"
                name="registered_name"
                label='app_users:registered_name'
                readonly={true}
              />
              <TextInput
                flex={5}
                type="text"
                name="registered_address"
                label='app_users:registered_address'
                readonly={true}
              />
            </Row>
          </Card>
        </RoleControlled>
        <Card mb="L">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              type="text"
              name="last_name"
              label='app_users:last_name'
              placeholder=""
              required
              readonly={data && !data.approved_user}
            />
            <TextInput
              flex={1}
              type="text"
              name="first_name"
              label='app_users:first_name'
              placeholder=""
              readonly={data && !data.approved_user}
            />
            <TextInput
              flex={1}
              type="text"
              name="furigana_last_name"
              label='app_users:furigana_last_name'
              placeholder=""
              readonly={data && !data.approved_user}
            />
            <TextInput
              flex={1}
              type="text"
              name="furigana_first_name"
              label='app_users:furigana_first_name'
              placeholder=""
              readonly={data && !data.approved_user}
            />
          </Row>
          <Row wrap="wrap" align="start">
            <TextInput
              flex={2}
              type="text"
              name="app_user_manage_id"
              label={pages?.custom?.chouei ? 'chouei:bellevie_id' : 'app_users:app_user_manage_id' }
              readonly={!data?.approved_user || pages?.custom?.datasync}
            />
            <TextInput
              flex={5}
              type="text"
              name="mail_address"
              label='app_users:contact_email'
              readonly={data && !data.approved_user}
              placeholder="placeholders.email"
            />
          </Row>
          <Row wrap="wrap" align="baseline">
            <AppUserNationality readonly={data && !data.approved_user} />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name='phone'
              label='phone'
              placeholder="placeholders.phone"
              readonly={data && !data.approved_user}
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name='emergency_phone'
              label='emergency_phone'
              readonly={data && !data.approved_user}
            />
          </Row>
          <Row wrap="wrap" align="start">
            <TextInput
              size="L"
              mr="0"
              type="text"
              name="postcode"
              label='properties:postcode'
              pattern="^\d{7}$"
              placeholder="placeholders.postcode"
              readonly={data && !data.approved_user}
            />
            <SearchAddressButton
              postcodeKey="postcode"
              prefectureKey="prefecture"
              cityKey="city"
              addressKey="address"
              disabled={data && !data.approved_user}
            />
            <TextInput
              type="text"
              name="prefecture"
              label='properties:prefecture'
              readonly
            />
            <TextInput
              type="text"
              name="city"
              label='properties:city'
              readonly
            />
          </Row>
          <Row wrap="wrap">
            <TextInput
              flex={1}
              type="text"
              name="address"
              label='address'
              placeholder=""
              readonly={data && !data.approved_user}
            />
          </Row>
          <Row wrap="wrap">
            <TextInput
              flex={1}
              type="text"
              name="occupation"
              label='app_users:occupation'
              readonly={data && !data.approved_user}
            />
            <TextInput
              flex={1}
              type="text"
              name="annual_income"
              label='app_users:annual_income'
              suffix="app_users:annual_income_unit"
              readonly={data && !data.approved_user}
            />
            <DatePicker
              flex={1}
              name="birthday"
              label='app_users:birthday'
              placeholder={dayjs().subtract(20, 'year').format(FRONT_DATE_FORMAT)}
              readonly={data && !data.approved_user}
              disabledDate={disableAfterToday}
            />
            <Select
              flex={1}
              name="sex"
              label='app_users:sex'
              placeholder=""
              options={SEX_OPTIONS}
              noClear
              disabled={data && !data.approved_user}
            />
          </Row>
          <Row wrap="wrap">
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="work"
              label='app_users:work.name'
              readonly={data && !data.approved_user}
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="work_phone"
              label='app_users:work.phone'
              placeholder="placeholders.phone"
              readonly={data && !data.approved_user}
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name="work_department"
              label='app_users:work.position'
              readonly={data && !data.approved_user}
            />
          </Row>
          <Row wrap="wrap" align="start">
            <TextInput
              size="L"
              mr="0"
              type="text"
              name="work_postcode"
              label='app_users:work.postcode'
              pattern="^\d{7}$"
              placeholder="placeholders.postcode"
              readonly={data && !data.approved_user}
            />
            <SearchAddressButton
              postcodeKey="work_postcode"
              prefectureKey="work_prefecture"
              cityKey="work_city"
              addressKey="work_address"
              disabled={data && !data.approved_user}
            />
            <TextInput
              type="text"
              name="work_prefecture"
              label='properties:prefecture'
              readonly
            />
            <TextInput
              type="text"
              name="work_city"
              label='properties:city'
              readonly
            />
          </Row>
          <Row wrap="wrap">
            <TextInput
              flex={1}
              type="text"
              name="work_address"
              label='app_users:work.address'
              placeholder=""
              readonly={data && !data.approved_user}
            />
          </Row>
        </Card>
        <Card title='memo'>
          <TextArea
            name="memo"
            placeholder="placeholders.memo"
            readonly={data && !data.approved_user}
            maxLength={1000}
          />
        </Card>
      </Form>
    </>
  )
}
export default AppUserForm

AppUserForm.propTypes = {
  data: PropTypes.object
}
AppUserForm.defaultProps = {

}
