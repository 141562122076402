import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {
  Row,
  DatePicker,
  Checkbox,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

const ContractDates = () => {
  const startDate = useSelector(state => state.form.contract_start)
  const endDate = useSelector(state => state.form.contract_end)
  const renewalStart = useSelector(state => state.form.renewal_period_start)
  const renewalEnd = useSelector(state => state.form.renewal_period_end)

  const disablePreviousDate = (current) => {
    if (current && (startDate || renewalStart)) {
      const start = renewalStart || startDate
      const date = dayjs(start)
      return (date && date.isAfter(current))
    }
    return false
  }
  const disableNextDate = (current) => {
    if (current && (endDate || renewalEnd)) {
      const end = renewalEnd || endDate
      const date = dayjs(end)
      return (date && current.isAfter(date))
    }
    return false
  }
  return (
    <Row wrap="wrap" align="baseline">
      <DatePicker
        flex={1}
        size="M"
        name="renewal_period_start"
        label='contracts:renewal_period_start'
        placeholder=""
        disabledDate={disableNextDate}
      />
      <DatePicker
        flex={1}
        size="M"
        name="renewal_period_end"
        label='contracts:renewal_period_end'
        placeholder=""
        disabledDate={disablePreviousDate}
      />
      <RoleControlled authorized='!custom.house_maker'>
        <Checkbox
          name="auto_renewal"
          inputLabel='contracts:auto_renewal'
        />
      </RoleControlled>
    </Row>
  )
}

export default ContractDates

// ContractDatepickers.propTypes = {}
