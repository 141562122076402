import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_GUIDES,
  FETCH_GUIDES_SUCCESS,
  FETCH_GUIDES_FAIL,
  POST_GUIDE,
  POST_GUIDE_SUCCESS,
  POST_GUIDE_FAIL,
  DELETE_GUIDE,
  // DELETE_GUIDE_SUCCESS,
  DELETE_GUIDE_FAIL,
  TOGGLE_GUIDE,
  TOGGLE_GUIDE_FAIL,
  BULK_TOGGLE_GUIDES,
  BULK_TOGGLE_GUIDES_FAIL,
  RESET_GUIDES
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_GUIDES]: (state, action) => {
    state.loading = true
  },
  [FETCH_GUIDES_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload.data
    state.categoryName = action.payload.categoryName
  },
  [FETCH_GUIDES_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_GUIDE]: (state, action) => {
    state.posting = true
  },
  [POST_GUIDE_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_GUIDE_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [DELETE_GUIDE]: (state, action) => {
    state.loading = true
  },
  [DELETE_GUIDE_FAIL]: (state, action) => {
    state.loading = false
  },
  [TOGGLE_GUIDE]: (state, action) => {
    state.loading = true
  },
  [TOGGLE_GUIDE_FAIL]: (state, action) => {
    state.loading = false
  },
  [BULK_TOGGLE_GUIDES]: (state, action) => {
    state.loading = true
  },
  [BULK_TOGGLE_GUIDES_FAIL]: (state, action) => {
    state.loading = false
  },
  [RESET_GUIDES]: () => INITIAL_STATE

})
