import { createSelector } from 'reselect'

const selectContracts = state => state.contracts

export const selectContractsLoading = createSelector(
  selectContracts,
  contracts => contracts.loading
)
export const selectContractsCount = createSelector(
  selectContracts,
  contracts => contracts.results_count
)
export const selectContractsData = createSelector(
  selectContracts,
  contracts => contracts.results
)
