import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  ItemNotFound,
  PageLoader
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_SURVEY,
  RESET_SURVEY
} from '../../../types'
import {
  selectSurveyData,
  selectSurveyError,
  selectSurveyLoading
} from '../../../selectors'

import SurveyForm from '../../../forms/SurveyForm'

const SurveyDetailsPage = ({ surveyID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const data = useSelector(selectSurveyData)
  const loading = useSelector(selectSurveyLoading)
  const error = useSelector(selectSurveyError)

  useEffect(() => {
    dispatch({ type: FETCH_SURVEY, id: surveyID })
    return () => {
      dispatch({ type: RESET_SURVEY })
    }
  }, [dispatch, surveyID])

  const breadcrumbs = [
    {
      label: 'navigation:surveys',
      link: '/surveys'
    },
    {
      label: data?.title,
      link: `/surveys/edit/${surveyID}`
    }
  ]

  return loading
    ? <PageLoader />
    : error && error.code === 404
      ? <ItemNotFound item={t('navigation:surveys')} link="/surveys" />
      : (
        <PageLayout
          breadcrumbs={breadcrumbs}
          loading={!data}
          lastEdited={data && data.modified_at}
          // lastEditedBy={data && data.modifiedBy}
        >
          <SurveyForm data={data} surveyID={surveyID} />
        </PageLayout>
      )
}
export default SurveyDetailsPage

SurveyDetailsPage.propTypes = {
  location: PropTypes.object.isRequired,
  surveyID: PropTypes.string.isRequired
}
SurveyDetailsPage.defaultProps = {

}
