import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  getFileFromURL,
  getFileExtension,
  PageLayout
} from '@gk-devteam/apmc-core-web'

import {
  RESET_NOTICE,
  FETCH_NOTICE_SUCCESS
} from '../../../types'
import { fetchNotice } from '../../../services'

import NoticeForm from '../../../forms/NoticeForm'

const breadcrumbs = [
  {
    label: 'navigation:notices',
    link: '/notices'
  },
  {
    label: 'navigation:notices_add',
    link: '/notices/add'
  }
]

const initialData = {
  push_notification: true,
  tag: null,
  title: null,
  content: null,
  url: null,
  video_url: null,
  notice_type: 0,
  start_time: null,
  stop_time: null,
  recipient_type: 0,
  recipients_id: null,
  recipients_condition: null,
  user_type: 1,
  delete_file: false
}

const NoticesAddPage = ({ location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const loading = useSelector(selectLoadingNotice)
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(null)

  const prepareData = useCallback(
    async (data) => {
      if (data && data.id) {
        const editedData = { ...data }
        editedData.delete_file = false
        delete editedData.id
        delete editedData.modified
        delete editedData.modifiedBy
        delete editedData.status
        delete editedData.read_count
        delete editedData.unread_count
        delete editedData.sent_count
        delete editedData.recipients
        if (editedData.notice_type && editedData.notice_type === 1) {
          editedData.start_time = null
        }
        if (data.file_url) {
          try {
            const blob = await getFileFromURL(data.file_url)
            const extension = getFileExtension(data.file_url)
            const blobURL = URL.createObjectURL(blob)
            editedData.file = blobURL
            editedData.file_filename = `notice.${extension}`
          } catch (error) {
            console.warning('error retrieving file blob', error)
          }
        }
        setFormData(editedData)
      }
    },
    []
  )

  const fetchData = useCallback(
    async (id) => {
      const cancelSource = CancelToken.source()
      setLoading(true)
      try {
        const res = await fetchNotice(id, cancelSource)
        setLoading(false)
        if (res && res.data) {
          prepareData(res.data)
          dispatch({ type: FETCH_NOTICE_SUCCESS, payload: null })
        } else {
          message.error(t('fetch_error'))
        }
      } catch (error) {
        setLoading(false)
        message.error(t('fetch_error'))
      }
    },
    [dispatch, prepareData, t]
  )

  useEffect(() => {
    if (location && location.state && location.state.copy) {
      fetchData(location.state.id)
    } else if (location && location.state && location.state.userID) {
      setFormData({
        ...initialData,
        recipient_type: 2,
        recipients_id: [location.state.userID],
        recipients_list: [{
          id: location.state.userID,
          label: location.state.userName
        }]
      })
      dispatch({ type: FETCH_NOTICE_SUCCESS, payload: null })
    } else {
      setFormData(initialData)
      dispatch({ type: FETCH_NOTICE_SUCCESS, payload: null })
    }
    return () => {
      dispatch({ type: RESET_NOTICE })
    }
  }, [location, dispatch, fetchData])

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      {
        formData
          ? <NoticeForm data={formData}/>
          : null
      }
    </PageLayout>
  )
}
export default NoticesAddPage

NoticesAddPage.propTypes = {
  location: PropTypes.object
}
NoticesAddPage.defaultProps = {

}
