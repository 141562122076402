import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
  Row,
  FormRemovableLabel,
  Text
} from '@gk-devteam/apmc-core-web'
import { transparentize } from 'polished'

export const ResultsBlockStyle = styled(Row)`
  flex: 1;
  overflow: scroll;
`

export const TableContainerStyle = styled.div`
  flex: 5;
  height: 100%;
  overflow: scroll;
  position: relative;
`

export const ResultsContainer = styled.ul`
  ${BORDER_RADIUS};
  padding: ${SPACING.SMALL}rem;
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  margin: 0;
  list-style: none;
  flex: 2;
  height: 100%;
  overflow: scroll;
`

export const RecipientStyle = styled(FormRemovableLabel)`
  margin: ${SPACING.SMALLEST / 2}rem;
`

export const BetweenStyle = styled.div`
  margin: 0 ${SPACING.SMALLEST}rem;
  user-select: none;
  color: ${props => getColor(props, 'lightText')};
`

export const CheckmarkCellStyle = styled.div`
  padding: calc(${SPACING.SMALL}rem - 2px) 0;
  text-align: center;
`

export const ButtonsStyle = styled.div`
  display: inline-flex;
`

export const QuestionsDropStyle = styled.div`
  width: 100%;
  background-color: ${props => props.isDraggingOver ? getColor(props, 'cardBorder') : 'inherit'};
`
export const QuestionDragStyle = styled.div`
  background-color: ${props => props.isDragging ? transparentize(0.75, getColor(props, 'process')) : 'inherit'};
`
export const OptionsStyle = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: ${SPACING.MEDIUM}rem;

  && > div:last-of-type {
    margin-top: -1rem;
  }
`

export const AnswerLabelStyle = styled(Text)`
  margin-right: ${SPACING.REGULAR}rem;
`

export const BarStyle = styled.div`
  position: relative;
  height: 8px;
  width: 400px;
  background-color: ${props => getColor(props, 'inputBorder')};

  &:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    width: ${props => props.ratio ? `${props.ratio}%` : '0'};
    background-color: ${props => getColor(props, 'accent')};
  }
`

export const ClickableStyle = styled.div`
  cursor: pointer;
`

export const ResultWrapperStyle = styled.div`
  max-height: 250px;
  overflow-y: scroll;
`
