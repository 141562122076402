import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  updateQueryParameters,
  UPDATE_FORM,
  RESET_FORM_ERRORS,
  Form,
  Row,
  Card
} from '@gk-devteam/apmc-core-web'

import {
  ResetSearchStyle,
  SearchButtonStyle
} from '../adminTasks.style'

const SearchForm = ({
  validationSchema,
  initialValues,
  searchInputs
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const _handleReset = () => {
    dispatch(updateQueryParameters(initialValues))
    dispatch({ type: UPDATE_FORM, payload: initialValues })
    dispatch({ type: RESET_FORM_ERRORS })
  }

  const _handleFormSubmit = (data) => {
    dispatch(updateQueryParameters({
      ...initialValues,
      ...data
    }))
  }
  return (
    <Card title="search" mt="L">
      <Form
        id="searchForm"
        handleSubmit={_handleFormSubmit}
        validationSchema={validationSchema}
        ignoreDirty
      >
        { searchInputs }
        <Row justify="center" my="R">
          <SearchButtonStyle
            type="submit"
            label='search'
            reverse
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                <path d="M8.26,9a5.07,5.07,0,0,1-3.2,1.14A5,5,0,0,1,1.48,8.62,4.86,4.86,0,0,1,.4,7a5,5,0,0,1,0-3.93A5,5,0,0,1,1.48,1.48,5.12,5.12,0,0,1,3.09.4a5.18,5.18,0,0,1,2-.4A5.1,5.1,0,0,1,7,.4,5,5,0,0,1,8.64,1.48,5.21,5.21,0,0,1,9.73,3.09a5,5,0,0,1-.8,5.23l5.07,5-.66.65ZM3.44,1.23a4.21,4.21,0,0,0-1.32.89,4.1,4.1,0,0,0-.89,1.32A4.25,4.25,0,0,0,.9,5.05a4.26,4.26,0,0,0,.33,1.62A4.17,4.17,0,0,0,3.44,8.88a4,4,0,0,0,1.62.32,3.91,3.91,0,0,0,1.62-.32A4.14,4.14,0,0,0,8,8,4.39,4.39,0,0,0,8.9,6.67a4.24,4.24,0,0,0,0-3.23A4.27,4.27,0,0,0,8,2.12a4.14,4.14,0,0,0-1.33-.89A4.08,4.08,0,0,0,5.06.9a4.15,4.15,0,0,0-1.62.33Z" />
              </svg>
            }
          />
          <ResetSearchStyle
            type="button"
            onClick={_handleReset}
          >
            {t('reset_search')}
          </ResetSearchStyle>
        </Row>
      </Form>
    </Card>
  )
}

export default SearchForm

SearchForm.propTypes = {
  validationSchema: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  searchInputs: PropTypes.node.isRequired
}
