import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  getFileFromURL,
  getFileExtension,
  MainLayout
} from '@gk-devteam/apmc-core-web'

import { FETCH_MEMBER_SUCCESS, RESET_MEMBER } from '../../../types'
import { fetchMember } from '../../../services'

import MemberForm from '../../../forms/userManagement/MemberForm'

const breadcrumbs = [
  {
    label: 'navigation:management',
    link: '/user_management'
  },
  {
    label: 'user_management:add_member',
    link: '/user_management/members/add'
  }
]

const initialData = {
  name: null,
  furigana: null,
  mail_address: null,
  phone: null,
  office: null,
  department: null,
  position: null,
  role: null,
  all_properties: false,
  groups: null,
  groups_list: null,
  properties: null,
  properties_list: null,
  memo: null
}

const MembersAddPage = ({ location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(null)

  const prepareData = useCallback(
    async (data) => {
      if (data && data.id) {
        const editedData = { ...data }
        editedData.delete_file = false
        delete editedData.id
        delete editedData.modified
        delete editedData.modifiedBy

        if (editedData.image) {
          try {
            const blob = await getFileFromURL(data.image)
            const extension = getFileExtension(data.image)
            const blobURL = URL.createObjectURL(blob)
            editedData.file = blobURL
            editedData.file_filename = `member.${extension}`
          } catch (error) {
            console.warning('error retrieving file blob', error)
          }
        }
        setFormData(editedData)
      }
    },
    []
  )

  const fetchData = useCallback(
    async () => {
      const cancelSource = CancelToken.source()
      if (location && location.state && location.state.copy) {
        setLoading(true)
        try {
          const res = await fetchMember(location.state.id, cancelSource)
          setLoading(false)
          if (res && res.data) {
            prepareData(res.data)
            dispatch({ type: FETCH_MEMBER_SUCCESS, payload: null })
          } else {
            message.error(t('fetch_error'))
          }
        } catch (error) {
          setLoading(false)
          message.error(t('fetch_error'))
        }
      } else {
        setFormData(initialData)
        dispatch({ type: FETCH_MEMBER_SUCCESS, payload: null })
      }
    },
    [location, dispatch, prepareData, t]
  )

  useEffect(() => {
    fetchData()
    return () => {
      dispatch({ type: RESET_MEMBER })
    }
  }, [location, dispatch, fetchData])

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      {
        formData
          ? <MemberForm data={formData}/>
          : null
      }
    </MainLayout>
  )
}
export default MembersAddPage

MembersAddPage.propTypes = {
  location: PropTypes.object
}
MembersAddPage.defaultProps = {

}
