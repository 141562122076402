
import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Title,
  PrimaryButton,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

const PanelHeader = ({ title, handleClick, authorized }) => {
  return (
    <Row justify="between" align="center" mt="R" mb="L">
      <Title label={title}/>
      {
        handleClick
          ? (
            <div>
              <RoleControlled authorized={authorized || true}>
                <PrimaryButton
                  label="add"
                  handleClick={handleClick}
                  reverse
                  withMargin
                />
              </RoleControlled>
            </div>
          )
          : null
      }
    </Row>
  )
}
export default PanelHeader

PanelHeader.propTypes = {
  title: PropTypes.string,
  handleClick: PropTypes.func,
  authorized: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}
PanelHeader.defaultProps = {

}
