import {
  styled,
  getColor,
  SPACING,
  LEFT_INFO,
  Table,
  InfoButton,
  CardTitle,
  RadioStandAlone,
  Row
} from '@gk-devteam/apmc-core-web'

import PanelDisabled from '../../components/Display/Panels/PanelDisabled'

export const UnresolvedLabelStyle = styled(CardTitle)`
  color: ${props => getColor(props, 'danger')};
`
export const UnresolvedCountStyle = styled(CardTitle)`
  color: ${props => getColor(props, 'danger')};
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
`
export const UnresolvedCountUnitStyle = styled(CardTitle)`
  color: ${props => getColor(props, 'danger')};
`

export const TableStyle = styled(Table)`
  /* width: auto; */
  width: ${props => props.tableMaxWidth ? `${props.tableMaxWidth}px` : 'auto'};
  max-width: ${props => props.tableMaxWidth ? `${props.tableMaxWidth}px` : 'auto'};
  /* flex: 1; */

  tr > td, tr > td > * {
    white-space: nowrap;
  }
`

export const LeftColumnStyle = styled.div`
  margin-right: ${SPACING.LARGE}rem;
  width: ${LEFT_INFO}px;
`

export const SearchButtonStyle = styled(InfoButton)`
  width: 100px;
  height: ${SPACING.LARGE}rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: ${SPACING.REGULAR}rem;
`

export const ResetSearchStyle = styled.button`
  color: ${props => getColor(props, 'danger')};
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
`

export const StatusRadioStyle = styled(RadioStandAlone)`
  label {
    &:first-child {
      color: ${props => getColor(props, 'danger')};
      border-color: ${props => props.value === 4 ? getColor(props, 'danger') : getColor(props, 'cardBorder')};
    }
    &:nth-child(2) {
      color: ${props => getColor(props, 'info')};
      border-color: ${props => props.value === 3 ? getColor(props, 'info') : getColor(props, 'cardBorder')};
    }
    &:last-child {
      color: ${props => getColor(props, 'success')};
      border-color: ${props => props.value === 2 ? getColor(props, 'success') : getColor(props, 'cardBorder')};
    }
  }
`

export const PanelDisabledStyle = styled(PanelDisabled)`
  padding-bottom: 0;
`

export const ButtonsRowStyle = styled(Row)`
  border: solid 1px ${props => getColor(props, 'cardBorder')};
`

export const MessageStyle = styled.div`
  border: solid 1px ${props => getColor(props, 'cardBorder')};
`

export const TextAreaStyle = styled.textarea`
  flex: 1;
  border: none;
  resize: none;
  color: ${props => getColor(props, 'text')};
  outline: none;
  box-shadow: none;
  appearance: none;
  padding: ${SPACING.SMALLEST}rem ${SPACING.REGULAR}rem;
  min-height: 6rem;
  width: 100%;
`
