import * as yup from 'yup'
import dayjs from 'dayjs'
import { isObject } from 'lodash'
import { i18nValidation as i18n } from '../../locales'

/* eslint-disable no-template-curly-in-string */

// NOTE: Do not use arrow function here, need to keep "this" scope
const parseDayjsDate = function () {
  const message = i18n.t('bad_format', { label: '${label}' })
  return this.test('Date Format Error', message, function (value) {
    const { path, createError } = this
    if (value) {
      // console.debug('value', value)
      const isDate = isObject(value) && dayjs(value).isValid()
      return isDate || createError({ path, message })
    }
    return true
  })
}
const isLessTest = function (comparator, comparatorLabel) {
  const message = i18n.t('min_error', { label: '${label}', comparator: comparatorLabel })
  return this.test('Limit Error', message, function (value) {
    const { path, createError } = this
    const maxValue = this.parent[comparator]
    // console.debug('isLess')
    // console.debug(` ${value} > ${maxValue} => ${value > maxValue}`)
    if (!maxValue || !value) {
      return true
    }
    return value >= maxValue || createError({ path, message })
  })
}
const isMoreTest = function (comparator, comparatorLabel) {
  const message = i18n.t('max_error', { label: '${label}', comparator: comparatorLabel })
  return this.test('Limit Error', message, function (value) {
    const { path, createError } = this
    const minValue = this.parent[comparator]
    // console.debug('isMore')
    // console.debug(` ${value} < ${minValue} => ${value < minValue}`)
    if (!minValue || !value) {
      return true
    }
    return value <= minValue || createError({ path, message })
  })
}
const isBlobTest = function () {
  const message = i18n.t('bad_format', { label: '${label}' })
  return this.test('Blob Format Error', message, function (value) {
    const { path, createError } = this
    if (value) {
      // console.debug('value', value)
      const regex = /^blob.*/
      const isBlob = regex.test(value)
      return isBlob || createError({ path, message })
    }
    return true
  })
}
const isTimeTest = function () {
  const message = i18n.t('bad_format', { label: '${label}' })
  return this.test('Time Format Error', message, function (value) {
    const { path, createError } = this
    if (value) {
      // console.debug('value', value)
      const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
      const isTime = regex.test(value)
      // console.debug('isTime', isTime, path, message)
      return isTime || createError({ path, message })
    }
    return true
  })
}
const isPhoneTest = function () {
  const message = i18n.t('bad_format', { label: '${label}' })
  return this.test('Phone Format Error', message, function (value) {
    const { path, createError } = this
    if (value) {
      // console.debug('value', value)
      const strippedValue = value.replace('-', '')
      const regex = /^\d{1,4}\d{1,4}\d{3,4}$/
      const isPhone = regex.test(strippedValue)
      // console.debug('isPhone', isPhone, path, message)
      return isPhone || createError({ path, message })
    }
    return true
  })
}
const isCellPhoneTest = function () {
  const message = i18n.t('bad_format', { label: '${label}' })
  return this.test('Phone Format Error', message, function (value) {
    const { path, createError } = this
    if (value) {
      // console.debug('value', value)
      const strippedValue = value.replace('-', '')
      const regex = /^\d{3}\d{4}\d{4}$/
      const isPhone = regex.test(strippedValue)
      // console.debug('isPhone', isPhone, path, message)
      return isPhone || createError({ path, message })
    }
    return true
  })
}
const isLetterOrNumberTest = function () {
  const message = i18n.t('letter_or_number', { label: '${label}' })
  return this.test('半角英数字 Format Error', message, function (value) {
    const { path, createError } = this
    if (value) {
      // console.debug('value', value)
      const regex = /^[a-zA-Z0-9]*$/
      const isLetterorNumber = regex.test(value)
      // console.debug('isLetterorNumber', isLetterorNumber, path, message)
      return isLetterorNumber || createError({ path, message })
    }
    return true
  })
}
const isValidPasswordTest = function () {
  const message = i18n.t('valid_password', { label: '${label}' })
  return this.test('パスワード Format Error', message, function (value) {
    const { path, createError } = this
    if (value) {
      // console.debug('value', value)
      const regex = /^[a-zA-Z0-9*#$-_]*$/
      const isValid = regex.test(value)
      // console.debug('isLetterorNumber', isLetterorNumber, path, message)
      return isValid || createError({ path, message })
    }
    return true
  })
}
const isRequiredTest = function (required) {
  const message = i18n.t('required', { label: '${label}' })
  return this.test('Required Field Error', message, function (value) {
    const { path, createError } = this
    if (!value && required) {
      return createError({ path, message })
    }
    return true
  })
}
const isColorTest = function () {
  const message = i18n.t('bad_format', { label: '${label}' })
  return this.test('Hex Format Error', message, function (value) {
    const { path, createError } = this
    if (value) {
      // console.debug('value', value)
      const regex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i
      const isColor = regex.test(value)
      return isColor || createError({ path, message })
    }
    return true
  })
}

const customizeYup = () => {
  // Add isDate method to the object validation object
  yup.addMethod(yup.object, 'isDate', parseDayjsDate)
  yup.addMethod(yup.number, 'isLess', isLessTest)
  yup.addMethod(yup.number, 'isMore', isMoreTest)
  yup.addMethod(yup.string, 'isBlob', isBlobTest)
  yup.addMethod(yup.string, 'isColor', isColorTest)
  yup.addMethod(yup.string, 'isTime', isTimeTest)
  yup.addMethod(yup.string, 'isPhone', isPhoneTest)
  yup.addMethod(yup.string, 'isCellPhone', isCellPhoneTest)
  yup.addMethod(yup.string, 'isLetterOrNumber', isLetterOrNumberTest)
  yup.addMethod(yup.string, 'isValidPassword', isValidPasswordTest)
  yup.addMethod(yup.mixed, 'isRequired', isRequiredTest)

  yup.setLocale({
    mixed: {
      required: i18n.t('required', { label: '${label}' }),
      isRequired: i18n.t('required', { label: '${label}' }),
      notType: i18n.t('not_type', { label: '${label}' })
      // notType: function notType(_ref) {
      //   switch (_ref.type) {
      //     case 'number':
      //       return 'Not a number error or any other custom error message';
      //     case 'string':
      //       return 'Not a string error or any other custom error message';
      //     default:
      //       return 'Wrong type error or any other custom error message';
      //   }
      // }
    },
    string: {
      max: i18n.t('max_length', { label: '${label}' }),
      min: i18n.t('wrong_length', { label: '${label}' }),
      length: i18n.t('wrong_length', { label: '${label}' }),
      matches: i18n.t('no_match', { label: '${label}' }),
      email: i18n.t('not_email', { label: '${label}' })
      // min: 'Valor muito curto (mínimo ${min} caracteres)',
      // max: 'Valor muito longo (máximo ${max} caracteres)'
    },
    number: {
      integer: i18n.t('not_integer'),
      min: i18n.t('min_error', { label: '${label}', comparator: '${min}' })
      // min: 'Valor inválido (deve ser maior ou igual a ${min})',
      // max: 'Valor inválido (deve ser menor ou igual a ${max})'
    },
    boolean: {
    }
  })
  return yup
}

export default customizeYup
