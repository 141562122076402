import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// import { useTranslation } from 'react-i18next'
// import { message } from 'antd'
// import PropTypes from 'prop-types'

import {
  selectPropertyGuideCategoriesData,
  selectPropertyGuideCategoriesAllDisplayed,
  selectRoomID
} from '../../../../selectors'
import {
  TOGGLE_GUIDE_CATEGORY
  // UPDATE_FORM,
  // RESET_FORM
} from '../../../../types'
// import { getFileFromURL } from '../../../../utils'

import GuideCategoriesList from '../common/GuideCategoriesList'

const PropertyGuideCategoriesList = () => {
  const dispatch = useDispatch()
  // const { t } = useTranslation()

  // const _handleCopy = async (data, closeMenu) => {
  //   dispatch({ type: RESET_FORM })
  //   const { name, content, file_url } = data
  //   try {
  //     const blob = await getFileFromURL(file_url)
  //     const blobURL = URL.createObjectURL(blob)
  //     const extension = blob && blob.type.replace('image/', '')
  //     dispatch({
  //       type: UPDATE_FORM,
  //       payload: {
  //         name,
  //         content,
  //         file_url,
  //         file: blobURL,
  //         file_filename: `guide_category.${extension}`
  //       }
  //     })
  //     editModal()
  //   } catch (error) {
  //     console.warning('error retrieving file blob', error)
  //     message.warning(t('copy_text_error'))
  //   }
  //   closeMenu()
  // }

  // const menu = [
  //   {
  //     label: 'copy',
  //     onClick: _handleCopy
  //   }
  // ]

  const _handleItemDisplay = useCallback(
    (display, id, item) => {
      if (id) {
        const data = {
          id: item.id,
          display
        }
        const config = {
          type: 'property_guide_categories',
          room: id
        }
        dispatch({ type: TOGGLE_GUIDE_CATEGORY, data, config })
      } else {
        console.warning('no property id')
      }
    },
    [dispatch]
  )

  return (
    <GuideCategoriesList
      dataSelector={selectPropertyGuideCategoriesData}
      displaySelector={selectPropertyGuideCategoriesAllDisplayed}
      idSelector={selectRoomID}
      title="guides:property_guide_category"
      type="property_guide_categories"
      level="room"
      guidePage="property_details"
      handleItemDisplay={_handleItemDisplay}
    />
  )
}

export default PropertyGuideCategoriesList

PropertyGuideCategoriesList.propTypes = {}
