import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Card,
  Form,
  FormSubmitButton,
  RoleControlled,
  Row,
  TextInput,
  Title
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { contractOpeloUpdateValidation } from '../../../validation'
import { POST_CONTRACT_OPELO } from '../../../types'
import { selectContractOpeloLoading, selectContractOpeloPosting } from '../../../selectors'

function OpeloForm ({ data, contractID }) {
  const dispatch = useDispatch()
  const handleFormSubmit = (data) => {
    dispatch({ type: POST_CONTRACT_OPELO, id: contractID, data })
  }
  return (
    <Form
      id="contractOpeloForm"
      handleSubmit={handleFormSubmit}
      validationSchema={contractOpeloUpdateValidation}
      loadingSelector={selectContractOpeloLoading}
      postingSelector={selectContractOpeloPosting}
      initialData={data}
      // authorized={}
    >
      <Row justify="between">
        <Row align="baseline" wrap={'wrap'}>
          <Title label='navigation:opelo' />
        </Row>
        <RoleControlled authorized="contracts.functions.update">
          <FormSubmitButton postingSelector={selectContractOpeloPosting}/>
        </RoleControlled>
      </Row>
      <Card title="opelo:info">
        <Row align="baseline">
          <TextInput
            flex={1}
            // size="M"
            type="text"
            name="serial_key"
            label='opelo:serial_key'
            placeholder="000000001"
            required
          />
          <TextInput
            flex={1}
            // size="M"
            type="text"
            name="local_key"
            label='opelo:local_key'
            placeholder="000000001"
            required
          />
        </Row>
      </Card>
    </Form>
  )
}

OpeloForm.propTypes = {
  contractID: PropTypes.string,
  data: PropTypes.shape({
    serial_key: PropTypes.string,
    local_key: PropTypes.string
  })
}

export default OpeloForm
