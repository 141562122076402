import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { PageLayout } from '@gk-devteam/apmc-core-web'
import { selectLoadingYasueSurvey, selectYasueSurveyData } from '../../../selectors'
import { FETCH_YASUE_SURVEY } from '../../../types'
import YasueSurveyForm from '../../../forms/yasue/YasueSurveyForm/YasueSurveyForm'

const YasueSurveyDetailsPage = ({ surveyID }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectLoadingYasueSurvey)
  const data = useSelector(selectYasueSurveyData)

  useEffect(() => {
    dispatch({ type: FETCH_YASUE_SURVEY, id: surveyID })
  }, [dispatch, surveyID])

  const breadcrumbs = [
    {
      label: 'navigation:yasue.survey_list',
      link: '/yasue/survey'
    },
    {
      label: 'navigation:yasue.survey_detail',
      link: `/yasue/survey/edit/${surveyID}`
    }
  ]

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      {
        loading ? null : <YasueSurveyForm data={data} surveyID={surveyID} />
      }
    </PageLayout>
  )
}

export default YasueSurveyDetailsPage

YasueSurveyDetailsPage.propTypes = {
  surveyID: PropTypes.string.isRequired
}
