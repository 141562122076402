import {
  styled,
  Radio
} from '@gk-devteam/apmc-core-web'

export const OptionsStyle = styled(Radio)`
  & > div:first-child {
    display: none;
  }

  & label {
  white-space: nowrap;
  }
`
