import {
  styled,
  Row,
  Column,
  SPACING
} from '@gk-devteam/apmc-core-web'

export const ContactLabelStyle = styled(Row)`
  h3 {
    margin-right: ${SPACING.REGULAR}rem;
  }
`

export const ContactInfoContainerStyle = styled(Row)`
  margin-right: ${SPACING.REGULAR}rem;
`
export const ContactInfoStyle = styled(Column)`
  flex: 1;
  align-items: flex-start;

  span:last-of-type {
    margin-top: ${SPACING.SMALLEST}rem;
    padding-left: ${SPACING.SMALLEST}rem;
  }
`
