import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {
  Row,
  DatePicker
} from '@gk-devteam/apmc-core-web'

import { selectNoticeEditable } from '../../../selectors'

const NoticeStopTime = () => {
  const startTime = useSelector(state => state.form.start_time)
  const editable = useSelector(selectNoticeEditable)

  const disablePreviousDate = (current) => {
    if (current) {
      const start = startTime ? dayjs(startTime) : dayjs()
      // Substract 1 day to be able to select the same day as startTime
      return start && start.subtract(1, 'day').isAfter(current)
    }
    return false
  }

  return (
    <Row wrap="wrap" align="baseline">
      <DatePicker
        flex={1}
        size="M"
        name="stop_time"
        label='notices:stop_time_settings'
        placeholder=""
        disabledDate={disablePreviousDate}
        readonly={!editable}
        showTime
      />
    </Row>
  )
}

export default memo(NoticeStopTime)

// NoticeStopTime.propTypes = {}
