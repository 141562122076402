import {
  SUBSCRIBE_FIREBASE_USER,
  SUBSCRIBE_FIREBASE_USER_SUCCESS
// SUBSCRIBE_FIREBASE_USER_FAIL,
// UNSUBSCRIBE_FIREBASE_USER
} from '../types'

import { checkIfLoggedIn, db } from '../services/firebaseService'
import { CHAT_USERS_COLLECTION_NAME } from '../constants'

let unsubscribeFirebaseUser

const getFirebaseUser = (user, dispatch) => {
  if (user && user.uid) {
    // Remove listener if already exists
    if (unsubscribeFirebaseUser) {
      unsubscribeFirebaseUser()
    }
    const userRef = db.collection(CHAT_USERS_COLLECTION_NAME).doc(user.uid)
    unsubscribeFirebaseUser = userRef.onSnapshot(doc => {
      if (!doc.exists) {
        console.log('No user document!')
      } else {
        const userInfo = doc.data()
        dispatch({ type: SUBSCRIBE_FIREBASE_USER_SUCCESS, payload: { data: userInfo, id: user.uid } })
      }
    })
  }
}

export const subscribeToFirebaseUser = () => (dispatch) => {
  dispatch({ type: SUBSCRIBE_FIREBASE_USER })
  checkIfLoggedIn((user) => getFirebaseUser(user, dispatch))
}

export const unSubscribeFromFirebaseUser = () => {
  try {
    if (unsubscribeFirebaseUser) unsubscribeFirebaseUser()
  } catch (error) {
    // Handle Errors here.
    // var errorCode = error.code
    // var errorMessage = error.message
    console.warn(error)
  }
}
