import React, { useState } from 'react'
import {
  Centered,
  Card,
  Row,
  InfoButton,
  Form,
  SectionTitle,
  TextInput,
  Text,
  CustomButton,
  COLORS,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'

import { forgotPasswordSchema } from '../../../validation'
import { sendForgotPasswordLink } from '../../../services'

const {
  // REACT_APP_LOGIN_TITLE,
  // REACT_APP_LOGIN_LOGO,
  // REACT_APP_LOGIN_FONT_COLOR,
  // REACT_APP_LOGIN_ACCENT_FONT_COLOR,
  REACT_APP_LOGIN_BORDER_COLOR,
  REACT_APP_LOGIN_BG,
  REACT_APP_LOGIN_INNER_BG
} = process.env

function ForgotPassword ({ navigate }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const _submitForm = async (data) => {
    setLoading(true)
    try {
      const cancelSource = CancelToken.source()
      const res = await sendForgotPasswordLink(data, cancelSource)
      if (res.status === 204) {
        message.success(t('login:forgot.email_submit_success'))
        navigate('/')
      }
    } catch (error) {
      console.warn('error', error)
      message.error(t('login:forgot.email_submit_fail'))
    } finally {
      setLoading(false)
    }
  }

  const _backToLoginPage = () => {
    navigate('/')
  }

  return (
    <Centered fullScreen backgroundColor={REACT_APP_LOGIN_BG}>
      <Card w={500} h={500} borderColor={REACT_APP_LOGIN_BORDER_COLOR}>
        <Centered backgroundColor={REACT_APP_LOGIN_INNER_BG}>
          <Form
            id="loginForm"
            handleSubmit={_submitForm}
            initialData={{
              mail_address: ''
            }}
            validationSchema={forgotPasswordSchema}
            w={380}
          >
            <Row mb="ML">
              <SectionTitle label="login:forgot.title" />
            </Row>
            <Row mb="M">
              <Text label="login:forgot.description" />
            </Row>
            <TextInput
              label='email'
              name="mail_address"
              borderColor={REACT_APP_LOGIN_BORDER_COLOR}
              mr="0"
              placeholder="placeholders.email"
              required
            />

            <Row mt='L' justify="end">
              <CustomButton
                type="button"
                label='login:back'
                color={COLORS.lightText}
                loading={loading}
                withMargin
                handleClick={_backToLoginPage}
              />
              <InfoButton
                type="submit"
                label='submit'
                reverse
                loading={loading}
              />
            </Row>
          </Form>
        </Centered>
      </Card>
    </Centered>
  )
}

ForgotPassword.propTypes = {
  navigate: PropTypes.func
}

export default ForgotPassword
