import React from 'react'
import {
  DatePicker,
  FilePicker,
  Form
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { selectContractInspectionsPosting } from '../../selectors'
import { inspectionUpdateSchema } from '../../validation'

export default function InspectionForm ({ handleFormSubmit, initialData }) {
  return (
    <Form
      id="addInspectionForm"
      handleSubmit={handleFormSubmit}
      validationSchema={inspectionUpdateSchema}
      postingSelector={selectContractInspectionsPosting}
      initialData={initialData}
      authorized="custom.house_maker"
    >
      <DatePicker
        name="inspection_date"
        label="inspections:inspection_date"
        placeholder=""
        showTime
        required
      />
      <FilePicker
        name="file"
        label="inspections:inspection_file"
        delete_name="delete_file"
        types={['pdf']}
      />
    </Form>
  )
}

InspectionForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object.isRequired
}
