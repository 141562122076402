import React, { memo } from 'react'
import PropTypes from 'prop-types'

const SearchAddressItem = ({ prefecture, city, address, handleClick }) => {
  const handleAddressClick = () => {
    handleClick({ prefecture, city, address })
  }
  return (
    <li onClick={handleAddressClick}>
      {prefecture}{city}{address}
    </li>
  )
}

export default memo(SearchAddressItem)

SearchAddressItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  prefecture: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired
}
