import { createSelector } from 'reselect'

const selectPropertyDocuments = state => state.propertyDocuments
// const selectPropertyDocument = (state, documentID) => {
//   return { documentID }
// }

export const selectPropertyDocumentsLoading = createSelector(
  selectPropertyDocuments,
  documents => documents.loading
)
export const selectPropertyDocumentsPosting = createSelector(
  selectPropertyDocuments,
  documents => documents.posting
)

export const selectPropertyDocumentsData = createSelector(
  selectPropertyDocuments,
  documents => documents.results
)
export const selectPropertyDocumentsCount = createSelector(
  selectPropertyDocuments,
  documents => documents.results_count
)

export const selectPropertyDocumentAddModalState = createSelector(
  selectPropertyDocuments,
  documents => documents.addDocumentModal
)
export const selectPropertyDocumentViewModalState = createSelector(
  selectPropertyDocuments,
  documents => documents.viewDocumentModal
)
export const selectActivePropertyDocumentID = createSelector(
  selectPropertyDocuments,
  documents => documents.activeDocumentID
)
export const selectActivePropertyDocument = createSelector(
  selectPropertyDocumentsData,
  selectActivePropertyDocumentID,
  (documents, id) => {
    return documents && id && documents?.find(document => document?.id === id)
  }
)
