import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  INIT_FORM,
  RESET_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectAdminContactsData,
  selectAdminContactsAllDisplayed
} from '../../../../selectors'
import { TOGGLE_CONTACT, DELETE_CONTACT } from '../../../../types'

import ContactsList from '../common/ContactsList'

const AdminContactsList = ({
  copyModal,
  editModal
}) => {
  const dispatch = useDispatch()

  const _handleEdit = (data, closeMenu, rest) => {
    dispatch({ type: RESET_FORM })
    dispatch({
      type: INIT_FORM,
      payload: data
    })
    editModal()
    closeMenu()
  }
  const _handleCopy = (data, closeMenu, rest) => {
    const { label, name, phone, mail_address, hours } = data
    dispatch({ type: RESET_FORM })
    dispatch({
      type: INIT_FORM,
      payload: {
        label,
        name,
        phone,
        mail_address,
        hours
      }
    })
    copyModal()
    closeMenu()
  }
  const _handleDelete = (data, closeMenu) => {
    dispatch({ type: DELETE_CONTACT, id: data.id, config: { } })
    closeMenu()
  }

  const menu = [
    {
      label: 'edit',
      onClick: _handleEdit,
      shouldRender: 'contacts.functions.update'
    },
    {
      label: 'copy',
      onClick: _handleCopy,
      shouldRender: 'contacts.functions.add'
    },
    {
      label: 'delete',
      onClick: _handleDelete,
      danger: true,
      shouldRender: 'contacts.functions.delete'
    }
  ]

  const _handleContactDisplay = useCallback(
    (display, id, item) => {
      const data = {
        id: item.id,
        display
      }
      const config = {
        type: 'admin_contacts'
      }
      dispatch({ type: TOGGLE_CONTACT, data, config })
    },
    [dispatch]
  )

  return (
    <ContactsList
      dataSelector={selectAdminContactsData}
      displaySelector={selectAdminContactsAllDisplayed}
      // idSelector={}
      title=" "
      type="admin_contacts"
      level="admin"
      editModal={editModal}
      handleContactDisplay={_handleContactDisplay}
      sideMenu={menu}
    />
  )
}

export default AdminContactsList

AdminContactsList.propTypes = {
  copyModal: PropTypes.func.isRequired,
  editModal: PropTypes.func.isRequired
}
