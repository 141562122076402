import React, { memo } from 'react'
import { message } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  useTranslation,
  api,
  Row,
  Text,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  UserModalStyle,
  ErrorMessageStyle,
  WarningMessageStyle,
  SuccessMessageStyle,
  UserFieldNameStyle
} from '../../ContractForm.style'

const NoMatch = () => {
  const { t } = useTranslation()
  return (
    <>
      <ErrorMessageStyle>{t('contracts:user_not_found')}</ErrorMessageStyle>
      <Text label="contracts:user_not_found_description" />
    </>
  )
}
const UserFound = ({ user }) => {
  const { t } = useTranslation()
  const pages = useSelector(selectPages)
  const {
    first_name,
    last_name,
    mail_address,
    occupation,
    annual_income,
    app_user_manage_id,
    phone,
    emergency_phone,
    birthday,
    sex
  } = user
  const sexcheck = (type) => {
    switch (type) {
      case 0:
        return t('app_users:no_sex')
      case 1:
        return t('app_users:male')
      case 2:
        return t('app_users:female')
      default:
        return t('app_users:no_sex')
    }
  }
  return (
    <>
      <SuccessMessageStyle>{t('contracts:user_found')}</SuccessMessageStyle>
      <Text label="contracts:search_result" />
      <Row mt="R">
        <UserFieldNameStyle label="app_users:app_user_name"/>
      </Row>
      <Row mt="XS" pl="S">
        <Text text={`${last_name} ${first_name}`}/>
      </Row>
      <Row mt="ML">
        <UserFieldNameStyle label="email"/>
      </Row>
      <Row mt="XS" pl="S">
        <Text text={mail_address}/>
      </Row>
      <Row mt="ML">
        <Row>
          <UserFieldNameStyle label="app_users:occupation"/>
        </Row>
        <Row>
          <UserFieldNameStyle label="app_users:annual_income"/>
        </Row>
      </Row>
      <Row mt="XS">
        <Row pl="S">
          <Text text={occupation}/>
        </Row>
        <Row pl="S">
          <Text text={annual_income}/>
        </Row>
      </Row>
      <Row mt="ML">
        <UserFieldNameStyle label={pages?.custom?.chouei ? 'chouei:bellevie_id' : 'app_users:app_user_manage_id' }/>
      </Row>
      <Row>
        <Row pl="S">
          <Text text={app_user_manage_id}/>
        </Row>
      </Row>
      <Row mt="ML">
        <Row>
          <UserFieldNameStyle label="phone"/>
        </Row>
        <Row>
          <UserFieldNameStyle label="emergency_phone"/>
        </Row>
      </Row>
      <Row mt="XS">
        <Row pl="S">
          <Text text={phone}/>
        </Row>
        <Row pl="S">
          <Text text={emergency_phone}/>
        </Row>
      </Row>
      <Row mt="ML">
        <Row>
          <UserFieldNameStyle label="app_users:birthday"/>
        </Row>
        <Row>
          <UserFieldNameStyle label="app_users:sex"/>
        </Row>
      </Row>
      <Row mt="XS">
        <Row pl="S">
          <Text text={birthday}/>
        </Row>
        <Row pl="S">
          <Text text={sexcheck(sex)}/>
        </Row>
      </Row>
    </>
  )
}
const UserRequest = () => {
  const { t } = useTranslation()

  return (
    <>
      <WarningMessageStyle>{t('contracts:user_different_agency')}</WarningMessageStyle>
      <Text label="contracts:user_different_agency_description" />
    </>
  )
}
const WaitingUser = () => {
  const { t } = useTranslation()
  return (
    <>
      <WarningMessageStyle>{t('contracts:waiting_user_modal_title')}</WarningMessageStyle>
      <Text label="contracts:waiting_user_modal_description" />
    </>
  )
}

const UserModal = ({ visible, onCancel, onSuccess, result, user }) => {
  const { t } = useTranslation()
  if (result == null) return null
  if (result !== 0 && !user) return null

  const requestUserApproval = async () => {
    if (user && user.app_user_id) {
      const { app_user_id } = user
      console.debug('request approval for user', app_user_id)
      try {
        await api.post(`app_users/${app_user_id}/approval/mail`)
        message.success(t('mail_success'))
        onSuccess({ userID: app_user_id })
      } catch (error) {
        message.error(t('mail_error'))
        onCancel()
      }
    } else {
      message.error(t('server_error'))
      onCancel()
    }
  }

  const acceptUser = () => {
    onSuccess({ user })
  }
  const acceptWaitingUser = () => {
    onSuccess({ userID: user && user.app_user_id })
  }

  let cancelText, okText, onOk
  switch (result) {
    case 0:
      cancelText = 'close'
      break
    case 1:
      cancelText = 'cancel'
      okText = 'ok_decide'
      onOk = acceptUser
      break
    case 2:
      cancelText = 'cancel'
      okText = 'submit'
      onOk = requestUserApproval
      break
    case 3:
      cancelText = 'cancel'
      okText = 'ok_decide'
      onOk = acceptWaitingUser
      break
    default:
      break
  }

  const _renderModalContent = () => {
    switch (result) {
      case 0:
        return <NoMatch />
      case 1:
        return <UserFound user={user} />
      case 2:
        return <UserRequest />
      case 3:
        return <WaitingUser />

      default:
        // Modal expect a children so return this empty one
        return <></>
    }
  }

  return (
    <UserModalStyle
      visible={visible}
      title='contracts:select_user'
      onCancel={onCancel}
      cancelText={cancelText}
      onOk={onOk}
      okText={okText}
    >
      { _renderModalContent() }
    </UserModalStyle>
  )
}

export default memo(UserModal)

UserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  result: PropTypes.number,
  user: PropTypes.object
}
UserFound.propTypes = {
  user: PropTypes.object.isRequired
}
