import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dot } from 'dot-object'
import PropTypes from 'prop-types'
import {
  INIT_FORM,
  Row,
  TriStateCheckbox
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyName,
  selectPublicFacilitiesLoading,
  selectPublicFacilitiesCategoriesOptionsWithReset,
  selectPublicFacilitiesAllAdditionalDataDisplayed,
  selectPages,
  selectPublicFacilitiesHiddenBy,
  selectPublicFacilitiesHiddenById
} from '../../../../selectors'
import {
  FETCH_PUBLIC_FACILITIES,
  RESET_PUBLIC_FACILITIES,
  TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA
} from '../../../../types'

import FacilityModal from './FacilityModal'
import FacilitiesList from './FacilitiesList'
import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import {
  FilterStyle,
  LabelStyle
} from './PropertyPublicFacilitiesPage.style'

const PropertyPublicFacilitiesPage = ({ propertyID }) => {
  const dispatch = useDispatch()
  const propertyName = useSelector(selectPropertyName)
  const loading = useSelector(selectPublicFacilitiesLoading)
  const pages = useSelector(selectPages)
  const categoriesOptions = useSelector(selectPublicFacilitiesCategoriesOptionsWithReset)
  const allAdditionalDataDisplayed = useSelector(selectPublicFacilitiesAllAdditionalDataDisplayed)
  const [modalVisible, setModalVisible] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [categoryFilter, setCategoryFilter] = useState('')

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_public_facilities',
      link: `/properties/edit/${propertyID}/public_facilities`
    }
  ]

  useEffect(() => {
    if (propertyID) {
      dispatch({ type: FETCH_PUBLIC_FACILITIES, id: propertyID })
    }
    return () => {
      dispatch({ type: RESET_PUBLIC_FACILITIES })
    }
  }, [propertyID, dispatch])

  const _handleFilterChange = (value) => {
    setCategoryFilter(value)
  }

  const _openAddModal = () => {
    dispatch({
      type: INIT_FORM,
      payload: {
        category: null,
        title: '',
        business_hour: '',
        address: '',
        phone: '',
        url: '',
        content: '',
        display: true,
        file: null,
        delete_file: false
      }
    })
    setModalVisible(true)
  }
  const _openCopyModal = () => {
    setModalVisible(true)
  }
  const _openEditModal = () => {
    setModalVisible(true)
    setEditMode(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
    setEditMode(false)
  }

  // let displayLabel
  // switch (allAdditionalDataDisplayed) {
  //   case 0:
  //     displayLabel = 'publicFacilities:show_all_additional_data'
  //     break
  //   case 1:
  //     displayLabel = 'publicFacilities:show_all_additional_data'
  //     break
  //   case 2:
  //     displayLabel = 'publicFacilities:hide_all_additional_data'
  //     break

  //   default:
  //     break
  // }

  const _handleToggleAdditionalData = () => {
    const data = {
      enable: !allAdditionalDataDisplayed
    }
    dispatch({ type: TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA, data, id: propertyID })
  }

  const _isDisabled = () => {
    const dotted = dot(pages)
    return !dotted['faq.functions.update']
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectPublicFacilitiesHiddenBy}
      hiddenByIdSelector={selectPublicFacilitiesHiddenById}
      title="navigation:properties_public_facilities"
      authorized="public_facilities.functions.add"
      info="services:level_message.property"
      infoTitle="services:level_message.propertyTitle"
      handleAddClick={_openAddModal}
    >
      <FacilityModal
        noLabel
        mb="0"
        visible={modalVisible}
        editMode={editMode}
        onCancel={_closeModal}
        id={propertyID}
      />
      <Row mt="L" justify="between">
        <FilterStyle
          handleChange={_handleFilterChange}
          value={categoryFilter}
          name="category_filter"
          options={categoriesOptions}
          loading={loading}
        />
        <Row justify="end">
          {/* <LabelStyle label={displayLabel}/> */}
          <LabelStyle label="publicFacilities:show_all_additional_data" />
          <TriStateCheckbox
            size="fluid"
            mr="0"
            mb="0"
            autoH
            noLabel
            value={allAdditionalDataDisplayed}
            handleChange={_handleToggleAdditionalData}
            disabled={_isDisabled()}
          />
        </Row>
      </Row>
      <FacilitiesList filter={categoryFilter} copyModal={_openCopyModal} editModal={_openEditModal} />
    </PanelLayout>
  )
}
export default PropertyPublicFacilitiesPage

PropertyPublicFacilitiesPage.propTypes = {
  propertyID: PropTypes.string.isRequired
}
PropertyPublicFacilitiesPage.defaultProps = {}
