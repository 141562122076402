import {
  PrimaryButton,
  SPACING,
  styled
 } from "@gk-devteam/apmc-core-web";

export const PreviewButtonStyle = styled(PrimaryButton)`
  margin-top: ${SPACING.LARGE}rem;
  margin-left: ${SPACING.LARGE}rem;
`
