import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  getFileFromURL,
  RESET_FORM,
  INIT_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectGuidesData,
  selectGuidesAllDisplayed,
  selectPropertyID
} from '../../../../selectors'
import {
  TOGGLE_GUIDE,
  DELETE_GUIDE
} from '../../../../types'

import GuidesList from '../common/GuidesList'

const AdminGuidesList = ({
  copyModal,
  editModal,
  guideCategoryID
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const propertyID = useSelector(selectPropertyID)

  const _handleEdit = (data, closeMenu, rest) => {
    dispatch({ type: RESET_FORM })
    const {
      id,
      name,
      content,
      url,
      video_url,
      file_url
    } = data
    dispatch({
      type: INIT_FORM,
      payload: {
        id,
        name,
        content,
        url,
        video_url,
        file_url,
        file: null,
        delete_file: false
      }
    })
    editModal()
    closeMenu()
  }
  const _handleCopy = async (data, closeMenu, rest) => {
    dispatch({ type: RESET_FORM })
    const {
      name,
      content,
      url,
      video_url,
      file_url
    } = data
    try {
      let blobURL, extension
      if (file_url) {
        const blob = await getFileFromURL(file_url)
        blobURL = URL.createObjectURL(blob)
        extension = blob && blob.type.replace('image/', '')
      }
      dispatch({
        type: INIT_FORM,
        payload: {
          name,
          content,
          url,
          video_url,
          file_url,
          file: blobURL || null,
          file_filename: extension ? `guide.${extension}` : null,
          delete_file: false
        }
      })
      copyModal()
      closeMenu()
    } catch (error) {
      console.warning('error retrieving file blob', error)
      message.warning(t('copy_text_error'))
    }
  }
  const _handleDelete = (data, closeMenu) => {
    dispatch({
      type: DELETE_GUIDE,
      id: data.id,
      config: {
        guideCategoryID,
        type: 'guides',
        property: propertyID
      }
    })
    closeMenu()
  }

  const menu = [
    {
      label: 'edit',
      onClick: _handleEdit,
      shouldRender: 'guides.functions.update'
    },
    {
      label: 'copy',
      onClick: _handleCopy,
      shouldRender: 'guides.functions.add'
    },
    {
      label: 'delete',
      onClick: _handleDelete,
      danger: true,
      shouldRender: 'guides.functions.delete'
    }
  ]

  const _handleToggleDisplay = useCallback(
    (display, id, item) => {
      const data = {
        id: item.id,
        display
      }
      const config = {
        guideCategoryID,
        type: 'guides',
        property: id
      }
      dispatch({ type: TOGGLE_GUIDE, data, config })
    },
    [dispatch, guideCategoryID]
  )

  return (
    <GuidesList
      dataSelector={selectGuidesData}
      displaySelector={selectGuidesAllDisplayed}
      idSelector={selectPropertyID}
      title="　"
      type="guides"
      level="property"
      editModal={editModal}
      handleToggleDisplay={_handleToggleDisplay}
      sideMenu={menu}
      extraConfig = {{ guideCategoryID }}
    />
  )
}

export default AdminGuidesList

AdminGuidesList.propTypes = {
  copyModal: PropTypes.func.isRequired,
  editModal: PropTypes.func.isRequired,
  guideCategoryID: PropTypes.string.isRequired
}
