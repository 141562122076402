import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parse } from 'query-string'
import {
  MainLayout,
  Row,
  Tabs,
  Title,
  updateQueryParameters
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { SERVICES_LEVEL, SERVICES_TYPES } from '../../../constants'
import {
  selectPropertyName,
  selectRoomName,
  selectServicesLoading
} from '../../../selectors'
import { FETCH_SERVICES, RESET_SERVICES } from '../../../types'

import ServicesLegend from '../../../components/Display/ServicesLegend'
import ServicesList from './ServicesList'

export const LevelContext = React.createContext()

function ServicesPage ({ level, contractID, propertyID, roomID, location }) {
  const dispatch = useDispatch()
  const [breadcrumbs, setBreadcrumbs] = useState(null)
  const [activeKey, setActiveKey] = useState('0')
  const propertyName = useSelector(selectPropertyName)
  const roomName = useSelector(selectRoomName)
  const loading = useSelector(selectServicesLoading)

  useEffect(() => {
    if (level) {
      // console.log('level', level)
      // console.log('contractID', contractID)
      // console.log('propertyID', propertyID)
      // console.log('roomID', roomID)
      let query = null
      switch (level) {
        case SERVICES_LEVEL.ADMIN:
          setBreadcrumbs([{
            label: 'navigation:services',
            link: '/settings/services'
          }])
          break
        case SERVICES_LEVEL.PROPERTY:
          setBreadcrumbs([
            {
              label: 'navigation:properties',
              link: '/properties'
            },
            {
              text: propertyName || '',
              link: `/properties/edit/${propertyID}`
            },
            {
              label: 'navigation:services',
              link: `/properties/edit/${propertyID}/services`
            }
          ])
          query = { property_id: propertyID }
          break
        case SERVICES_LEVEL.ROOM:
          setBreadcrumbs([
            {
              label: 'navigation:properties',
              link: '/properties'
            },
            {
              text: propertyName || '',
              link: `/properties/edit/${propertyID}`
            },
            {
              text: roomName,
              link: `/properties/edit/${propertyID}/rooms/edit/${roomID}`
            },
            {
              label: 'navigation:services',
              link: `/properties/edit/${propertyID}/rooms/edit/${roomID}/services`
            }
          ])
          query = { room_id: roomID }
          break
        case SERVICES_LEVEL.CONTRACT:
          setBreadcrumbs([
            {
              label: 'navigation:contracts',
              link: '/contracts'
            },
            {
              label: 'navigation:contracts_details',
              link: `/contracts/edit/${contractID}`
            },
            {
              label: 'navigation:services',
              link: `/contracts/edit/${contractID}/services`
            }
          ])
          query = { contract_id: contractID }
          break

        default:
          break
      }

      dispatch({ type: FETCH_SERVICES, query })
    }
    return () => {
      dispatch({ type: RESET_SERVICES })
    }
  }, [level, contractID, propertyID, roomID, dispatch, propertyName, roomName])

  useEffect(() => {
    const query = parse(location.search)
    if (!query?.type) {
      dispatch(updateQueryParameters({
        limit: null,
        page: null,
        type: '1'
      }))
    } else {
      const key = String(Number(query.type) - 1)
      setActiveKey(key)
    }
  }, [location.search, dispatch])

  const _handleTabChange = (activeKey) => {
    setActiveKey(activeKey)
    const type = String(Number(activeKey) + 1)
    dispatch(updateQueryParameters({
      limit: null,
      page: null,
      type
    }))
  }

  if (!breadcrumbs) return null

  return (
    <MainLayout
      withSidebar
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      <Row mb="R">
        <Title label="navigation:services" />
      </Row>
      <LevelContext.Provider value={level}>
        <Tabs
          activeKey={activeKey}
          onChange={_handleTabChange}
          panes={[
            {
              key: 0,
              tab: 'services:type.internal',
              node: <ServicesList type={SERVICES_TYPES.INTERNAL} />
            },
            {
              key: 1,
              tab: 'services:type.external',
              node: <ServicesList type={SERVICES_TYPES.EXTERNAL} />
            }
          ]}
        />
        <ServicesLegend />
      </LevelContext.Provider>
    </MainLayout>
  )
}

ServicesPage.propTypes = {
  location: PropTypes.object.isRequired,
  level: PropTypes.oneOf(Object.values(SERVICES_LEVEL)).isRequired,
  contractID: PropTypes.string,
  propertyID: PropTypes.string,
  roomID: PropTypes.string
}

export default ServicesPage
