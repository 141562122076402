import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  selectGuidesLoading,
  selectGuidesHiddenBy,
  selectPropertyName,
  selectRoomName,
  selectCategoryName,
  selectGuidesHiddenById
} from '../../../../selectors'
import {
  FETCH_GUIDES,
  RESET_GUIDES
} from '../../../../types'

import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import AdminGuidesList from './AdminGuidesList'
import PropertyGuidesList from './PropertyGuidesList'

const RoomGuidesPage = ({ guideCategoryID, propertyID, roomID, guideType }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectGuidesLoading)
  const propertyName = useSelector(selectPropertyName)
  const roomName = useSelector(selectRoomName)
  const categoryName = useSelector(selectCategoryName)

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      text: roomName,
      link: `/properties/edit/${propertyID}/rooms/edit/${roomID}`
    },
    {
      label: 'navigation:rooms_guides',
      link: `/properties/edit/${propertyID}/rooms/edit/${roomID}/guides`
    },
    {
      text: categoryName,
      link: `/properties/edit/${propertyID}/rooms/edit/${roomID}/guides/${guideCategoryID}`
    }
  ]

  useEffect(() => {
    dispatch({
      type: FETCH_GUIDES,
      config: {
        guideCategoryID,
        type: guideType,
        room: roomID
      }
    })
    return () => {
      dispatch({ type: RESET_GUIDES })
    }
  }, [dispatch, guideCategoryID, roomID, guideType])

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectGuidesHiddenBy}
      hiddenByIdSelector={selectGuidesHiddenById}
      propertyID={propertyID}
      title={categoryName || ''}
      authorized="guides.functions.add"
      info="services:level_message.room"
      infoTitle="services:level_message.roomTitle"
    >
      {
        guideType === 'admin_guides'
          ? <AdminGuidesList guideCategoryID={guideCategoryID} />
          : <PropertyGuidesList guideCategoryID={guideCategoryID} />
      }

    </PanelLayout>
  )
}
export default RoomGuidesPage

RoomGuidesPage.propTypes = {
  guideCategoryID: PropTypes.string.isRequired,
  propertyID: PropTypes.string.isRequired,
  roomID: PropTypes.string.isRequired,
  guideType: PropTypes.oneOf(['admin_guides', 'property_guides'])
}
RoomGuidesPage.defaultProps = {}
