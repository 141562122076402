import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { CancelToken } from 'axios'

import {
  fetchContracts,
  fetchContractsProcessingCount
} from '../../services'
import {
  FETCH_CONTRACTS_PROCESSING,
  FETCH_CONTRACTS_PROCESSING_SUCCESS,
  FETCH_CONTRACTS_PROCESSING_FAIL,
  FETCH_CONTRACTS_RENEWING,
  FETCH_CONTRACTS_RENEWING_SUCCESS,
  FETCH_CONTRACTS_RENEWING_FAIL,
  FETCH_CONTRACTS_CANCELING,
  FETCH_CONTRACTS_CANCELING_SUCCESS,
  FETCH_CONTRACTS_CANCELING_FAIL,
  FETCH_CONTRACTS_PROCESSING_COUNT,
  FETCH_CONTRACTS_PROCESSING_COUNT_SUCCESS,
  FETCH_CONTRACTS_PROCESSING_COUNT_FAIL
} from '../../types'
import { i18n } from '../../locales'
import { CONTRACT_STATUS } from '../../constants'

function * fetchContractsProcessing (
  status,
  SUCCESS_TYPE,
  FAIL_TYPE
) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchContracts, {
      limit: 10000,
      status
    }, cancelSource)
    if (res && res.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: SUCCESS_TYPE, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FAIL_TYPE, error: true, payload: i18n.t('fetch_error') })
    }
  } catch (error) {
    yield put({ type: FAIL_TYPE, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * fetchContractsProcessingCountSaga () {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchContractsProcessingCount, cancelSource)
    if (res && res.data) {
      const {
        asking_for_renewal_count,
        renewing_count,
        requesting_cancel_count,
        canceling_count,
        renewing_period_count
      } = res.data
      yield put({
        type: FETCH_CONTRACTS_PROCESSING_COUNT_SUCCESS,
        payload: {
          asking_for_renewal_count,
          renewing_count,
          requesting_cancel_count,
          canceling_count,
          renewing_period_count
        }
      })
    } else {
      yield put({ type: FETCH_CONTRACTS_PROCESSING_COUNT_FAIL, error: true, payload: i18n.t('fetch_error') })
    }
  } catch (error) {
    yield put({ type: FETCH_CONTRACTS_PROCESSING_COUNT_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * fetchContractsProcessingSaga () {
  const statuses = [
    CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value,
    CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value,
    CONTRACT_STATUS.RENEWING.value,
    CONTRACT_STATUS.REQUESTING_CANCEL.value,
    CONTRACT_STATUS.CANCELING.value,
    CONTRACT_STATUS.IN_RENEWING_PERIOD.value
  ].join(',')
  yield call(fetchContractsProcessing, statuses, FETCH_CONTRACTS_PROCESSING_SUCCESS, FETCH_CONTRACTS_PROCESSING_FAIL)
}

export function * fetchContractsRenewingSaga () {
  const statuses = [
    CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value,
    CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value,
    CONTRACT_STATUS.RENEWING.value
  ].join(',')
  yield call(fetchContractsProcessing, statuses, FETCH_CONTRACTS_RENEWING_SUCCESS, FETCH_CONTRACTS_RENEWING_FAIL)
}
export function * fetchContractsCancelingSaga () {
  const statuses = [
    CONTRACT_STATUS.REQUESTING_CANCEL.value,
    CONTRACT_STATUS.CANCELING.value
  ].join(',')
  yield call(fetchContractsProcessing, statuses, FETCH_CONTRACTS_CANCELING_SUCCESS, FETCH_CONTRACTS_CANCELING_FAIL)
}

export function * watchContractsProcessing () {
  yield takeLatest(FETCH_CONTRACTS_PROCESSING_COUNT, fetchContractsProcessingCountSaga)
  yield takeLatest(FETCH_CONTRACTS_PROCESSING, fetchContractsProcessingSaga)
  yield takeLatest(FETCH_CONTRACTS_RENEWING, fetchContractsRenewingSaga)
  yield takeLatest(FETCH_CONTRACTS_CANCELING, fetchContractsCancelingSaga)
}
