import React from 'react'
import PropTypes from 'prop-types'
import { Card, FRONT_DATE_FORMAT, Row, ToastTitle } from '@gk-devteam/apmc-core-web'
import Result from './Result'
import { SURVEY_QUESTION_TYPE } from '../../../constants'
import dayjs from 'dayjs'
import { ResultWrapperStyle } from '../SurveyForm.style'

export default function Answer ({ data }) {
  const _renderResults = () => {
    const { answers, type } = data
    if (
      !answers ||
      answers.length === 0
    ) return null

    if (type === SURVEY_QUESTION_TYPE.TEXT || type === SURVEY_QUESTION_TYPE.TEXTAREA) {
      return answers.map((answer, i) => <Result key={i} answer={answer.answer} ids={[answer.answer_id]}/>)
    }

    const total = answers.length

    const countByAnswer = {}

    for (const item of answers) {
      const { answer, answer_id } = item
      let key = answer

      if (type === SURVEY_QUESTION_TYPE.DATE) key = dayjs(answer).format(FRONT_DATE_FORMAT)

      if (countByAnswer[key]) {
        countByAnswer[key].push(answer_id)
      } else {
        countByAnswer[key] = [answer_id]
      }
    }

    return Object.keys(countByAnswer)?.map((answer, i) => <Result key={i} answer={answer} ids={countByAnswer[answer]} totalCount={total}/>)
  }

  return (
    <Card mb="M">
      <Row mb="R">
        <ToastTitle text={data.label} />
      </Row>
      <ResultWrapperStyle>
        { _renderResults() }
      </ResultWrapperStyle>
    </Card>
  )
}

Answer.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    required: PropTypes.bool,
    max_length: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.string),
    answers: PropTypes.arrayOf(PropTypes.shape({
      answer_id: PropTypes.number.isRequired,
      answer: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    }))
  })
}
