import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { CancelToken } from 'axios'

import {
  fetchMembers
} from '../../services'
import {
  FETCH_MEMBERS,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchMembersSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchMembers, query, cancelSource)
    if (res && res.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_MEMBERS_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_MEMBERS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_MEMBERS_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchMembers () {
  yield takeLatest(FETCH_MEMBERS, fetchMembersSaga)
}
