import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const spacesSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    property_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .label(i18n.t('properties:property_id'))
  })
}
