import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import {
  useTranslation,
  getFileFromURL,
  RESET_FORM,
  INIT_FORM,
  SideMenu,
  Row,
  Column,
  Text
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyID
} from '../../../../selectors'
import { DELETE_VICINITY } from '../../../../types'

import {
  ContentImageStyle,
  MainContentContainerStyle,
  ContentLabelStyle,
  LinkStyle
} from './PropertyVicinitiesPage.style'

const VicinityListItemContent = ({ item, editModal, copyModal }) => {
  const { t } = useTranslation()
  const propertyID = useSelector(selectPropertyID)
  const dispatch = useDispatch()

  const {
    id,
    vicinity_type_id,
    // category,
    content,
    business_hour,
    address,
    phone,
    url,
    file_url
    // no_image_flg,
    // modified
  } = item

  const _handleEdit = (data, closeMenu, rest) => {
    dispatch({ type: RESET_FORM })
    dispatch({
      type: INIT_FORM,
      payload: {
        ...data,
        file: null,
        delete_file: false
      }
    })
    editModal()
    closeMenu()
  }
  const _handleCopy = async (data, closeMenu, rest) => {
    dispatch({ type: RESET_FORM })
    const {
      address,
      business_hour,
      category,
      content,
      display,
      file_url,
      phone,
      title,
      url,
      no_image_flg
      // delete_file
    } = data
    try {
      let blobURL, extension
      if (file_url && !no_image_flg) {
        const blob = await getFileFromURL(file_url)
        blobURL = URL.createObjectURL(blob)
        extension = blob && blob.type.replace('image/', '')
      }
      dispatch({
        type: INIT_FORM,
        payload: {
          address,
          business_hour,
          category,
          content,
          display,
          file_url: no_image_flg ? null : file_url,
          phone,
          title,
          url,
          file: blobURL || null,
          file_filename: extension ? `vicinity.${extension}` : null,
          delete_file: false
        }
      })
      copyModal()
    } catch (error) {
      console.warning('error retrieving file blob', error)
      message.warning(t('copy_text_error'))
    }
    closeMenu()
  }
  const _handleDelete = (data, closeMenu) => {
    dispatch({ type: DELETE_VICINITY, id: propertyID, vicinityID: id })
    closeMenu()
  }

  const menu = [
    {
      label: 'edit',
      onClick: _handleEdit,
      shouldRender: 'vicinities.functions.update'
    },
    {
      label: 'copy',
      onClick: _handleCopy,
      shouldRender: 'vicinities.functions.add'
    },
    {
      label: 'delete',
      onClick: _handleDelete,
      danger: true,
      shouldRender: 'vicinities.functions.delete'
    }
  ]

  return (
    <Row align="start">
      <ContentImageStyle src={file_url} cover full/>
      <MainContentContainerStyle>
        <Row>
          <p>{content}</p>
        </Row>
        <Row justify="between">
          <Column align="start">
            <ContentLabelStyle label="external_url" />
            {
              url
                ? (
                  <LinkStyle href={url} target="_blank" rel="noopener noreferrer">
                    {t('link')}
                    <LinkOutlined />
                  </LinkStyle>
                )
                : <Text text="-" />
            }
          </Column>
          <Column align="start">
            <ContentLabelStyle label="phone" />
            <Text text={phone || '-'} />
          </Column>
          <Column align="start">
            <ContentLabelStyle label="business_hour" />
            <Text text={business_hour || '-'} />
          </Column>
        </Row>
        <Row mt="S">
          <Column align="start">
            <ContentLabelStyle label="address" />
            {
              address
                ? (
                  <LinkStyle href={`https://maps.google.com/?q=${address}`} target="_blank" rel="noopener noreferrer">
                    <>
                      {address}
                      <LinkOutlined />
                    </>
                  </LinkStyle>
                )
                : <Text text="-" />
            }
          </Column>
        </Row>
      </MainContentContainerStyle>
      {
        vicinity_type_id === 0
          ? (
            <SideMenu
              menu={menu}
              data={item}
            />
          )
          : null
      }
    </Row>
  )
}

export default VicinityListItemContent

VicinityListItemContent.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    vicinity_type_id: PropTypes.number.isRequired,
    category: PropTypes.number.isRequired,
    content: PropTypes.string,
    business_hour: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    url: PropTypes.string,
    file_url: PropTypes.string,
    no_image_flg: PropTypes.bool.isRequired,
    modified: PropTypes.string.isRequired
  }).isRequired,
  copyModal: PropTypes.func.isRequired,
  editModal: PropTypes.func.isRequired
}
