import {
  styled,
  getColor,
  DatePicker
} from '@gk-devteam/apmc-core-web'

export const CustomDatePickerStyle = styled(DatePicker)`
  color: ${props => getColor(props, 'danger')};

  & .ant-picker:hover, & .ant-picker-focused {
    border-color: ${props => getColor(props, 'danger')};
    box-shadow: none;
  }

  & input {
    color: ${props => getColor(props, 'danger')}!important;
  }
`
