import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useTranslation,
  Table,
  TOGGLE_ARRAY_ITEM,
  PageLoader
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import UserCheckmark from './UserCheckmark'
import { TableContainerStyle } from '../AppUsersDownloadLoginPdfForm.style'
import { APP_USERS_LOGIN_PDF_TYPE } from '../../../constants'

function RecipientsList ({ recipientType, loadingSelector, dataSelector }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const loading = useSelector(loadingSelector)

  let nameLabel, nameKey, idKey, manageIdKey
  switch (recipientType) {
    case APP_USERS_LOGIN_PDF_TYPE.PROPERTIES:
      nameLabel = 'properties:property_name'
      idKey = 'id'
      nameKey = 'name'
      manageIdKey = 'property_manage_id'
      break
    case APP_USERS_LOGIN_PDF_TYPE.RESIDENTS:
      nameLabel = 'app_users:user_name'
      idKey = 'id'
      nameKey = 'app_user_name'
      manageIdKey = 'app_user_manage_id'

      break
    default:
      break
  }

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: 'selected_item',
      RenderComponent: UserCheckmark
    },
    {
      title: t(nameLabel),
      dataIndex: nameKey,
      key: nameKey,
      cellTitle: true
    },
    {
      title: t('id'),
      dataIndex: idKey,
      key: idKey
    },
    {
      title: t('manage_id'),
      dataIndex: manageIdKey,
      key: manageIdKey
    }
  ]

  const _handleRowClick = (_, row, e) => {
    const { id, name, app_user_name } = row
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [
        {
          key: 'recipients_list',
          value: { id, label: name || app_user_name },
          objectComparatorKey: 'id'
        },
        {
          key: 'ids',
          value: id
        }]
    })
  }

  return (
    <TableContainerStyle>
      {
        loading
          ? <PageLoader />
          : (
            <Table
              columns={columns}
              rowKey="id"
              loadingSelector={loadingSelector}
              dataSelector={dataSelector}
              onRowClick={_handleRowClick}
              leftStickyColumnCount={0}
              rightStickyColumnCount={0}
              noHeader
              noFooter
              noLoader
            />
          )
      }

    </TableContainerStyle>
  )
}

RecipientsList.propTypes = {
  recipientType: PropTypes.number.isRequired,
  loadingSelector: PropTypes.func.isRequired,
  dataSelector: PropTypes.func.isRequired
}

export default RecipientsList
