import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { CancelToken } from 'axios'
import { message } from 'antd'

import {
  fetchInspections,
  fetchContractInspections,
  postInspection,
  deleteInspection
} from '../../services'
import {
  FETCH_INSPECTIONS,
  FETCH_INSPECTIONS_SUCCESS,
  FETCH_INSPECTIONS_FAIL,
  FETCH_CONTRACT_INSPECTIONS,
  FETCH_CONTRACT_INSPECTIONS_SUCCESS,
  FETCH_CONTRACT_INSPECTIONS_FAIL,
  POST_CONTRACT_INSPECTION,
  POST_CONTRACT_INSPECTION_SUCCESS,
  POST_CONTRACT_INSPECTION_FAIL,
  DELETE_CONTRACT_INSPECTION,
  DELETE_CONTRACT_INSPECTION_SUCCESS,
  DELETE_CONTRACT_INSPECTION_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchInspectionsSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchInspections, query, cancelSource)
    if (res && res.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_INSPECTIONS_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_INSPECTIONS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_INSPECTIONS_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * fetchContractInspectionsSaga ({ contract_id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchContractInspections, contract_id, cancelSource)
    if (res?.data) {
      yield put({ type: FETCH_CONTRACT_INSPECTIONS_SUCCESS, payload: res.data?.inspections })
    } else {
      yield put({ type: FETCH_CONTRACT_INSPECTIONS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_CONTRACT_INSPECTIONS_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * postContractInspectionSaga ({ data, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postInspection, data, cancelSource)
    if (res.status === 204) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_CONTRACT_INSPECTION_SUCCESS })
      // yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CONTRACT_INSPECTION_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CONTRACT_INSPECTION_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * deleteContractInspectionSaga ({ contract_id, inspection_id, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteInspection, contract_id, inspection_id, cancelSource)
    if (res.status === 204) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: DELETE_CONTRACT_INSPECTION_SUCCESS })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_CONTRACT_INSPECTION_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchInspections () {
  yield takeLatest(FETCH_INSPECTIONS, fetchInspectionsSaga)
  yield takeLatest(FETCH_CONTRACT_INSPECTIONS, fetchContractInspectionsSaga)
  yield takeLatest(POST_CONTRACT_INSPECTION, postContractInspectionSaga)
  yield takeLatest(DELETE_CONTRACT_INSPECTION, deleteContractInspectionSaga)
}
