import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  ItemNotFound,
  PageLoader
} from '@gk-devteam/apmc-core-web'

import {
  selectNoticeFormData,
  selectLoadingNotice,
  selectNoticeError
} from '../../../selectors'
import {
  FETCH_NOTICE,
  RESET_NOTICE
} from '../../../types'

import NoticeForm from '../../../forms/NoticeForm'

const NoticeDetailsPage = ({ noticeID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const data = useSelector(selectNoticeFormData)
  const loading = useSelector(selectLoadingNotice)
  const error = useSelector(selectNoticeError)

  useEffect(() => {
    dispatch({ type: FETCH_NOTICE, id: noticeID })
    return () => {
      dispatch({ type: RESET_NOTICE })
    }
  }, [dispatch, noticeID])

  const breadcrumbs = [
    {
      label: 'navigation:notices',
      link: '/notices'
    },
    {
      label: 'navigation:notices_details',
      link: `/notices/edit/${noticeID}`
    }
  ]

  return loading
    ? <PageLoader />
    : error && error.code === 404
      ? <ItemNotFound item={t('navigation:notices')} link="/notices" />
      : (
        <PageLayout
          breadcrumbs={breadcrumbs}
          loading={!data}
          lastEdited={data && data.modified}
          lastEditedBy={data && data.modifiedBy}
        >
          <NoticeForm data={data} noticeID={noticeID} />
        </PageLayout>
      )
}
export default NoticeDetailsPage

NoticeDetailsPage.propTypes = {
  noticeID: PropTypes.string.isRequired
}
NoticeDetailsPage.defaultProps = {

}
