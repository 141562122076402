import React, { useCallback, useEffect, useState } from 'react'
import { CancelToken } from 'axios'
import { message } from 'antd'
import {
  Card,
  Image,
  Modal,
  PageLoader,
  Row,
  Text,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { showLoginQR, downloadLoginPDF, fetchTmpLogin } from '../../../services'

import { LoginInfoStyle, ModalLeftContentStyle } from './QrModal.style'

function QrModal ({ id, visible, close }) {
  const { t } = useTranslation()
  const [qr, setQr] = useState(null)
  const [tmpLogin, setTmpLogin] = useState('')
  const [tmpPassword, setTmpPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const fetchQr = useCallback(
    async () => {
      try {
        const cancelSource = CancelToken.source()
        const res = await showLoginQR(id, cancelSource)
        const blob = new Blob(
          [res.data],
          { type: res.headers['content-type'] }
        )
        const image = URL.createObjectURL(blob)
        setQr(image || null)
      } catch (error) {
        message.error(t('app_users:temp_login.qr_fetch_fail'))
        close()
      }
    },
    [id, t, close]
  )
  const fetchLoginInfo = useCallback(
    async () => {
      try {
        const cancelSource = CancelToken.source()
        const res = await fetchTmpLogin(id, cancelSource)
        if (res.data) {
          setTmpLogin(res.data.tmp_login_id)
          setTmpPassword(res.data.tmp_login_password)
        }
      } catch (error) {
        message.error(t('fetch_error'))
      }
    },
    [id, t]
  )
  useEffect(() => {
    if (id && visible) {
      fetchQr()
      fetchLoginInfo()
    }
  }, [id, visible, fetchQr, fetchLoginInfo])

  const downloadQr = async () => {
    setLoading(true)
    await downloadLoginPDF(id)
    setLoading(false)
  }

  if (!id) return null
  if (!qr && visible) return <PageLoader />

  return (
    <Modal
      visible={visible}
      onCancel={close}
      fullWidth
      title="app_users:temp_login.qr"
      cancelText="close"
      okText="app_users:temp_login.download_qr"
      onOk={downloadQr}
      posting={loading}
    >
      <Card title="app_users:temp_login.qr_card_title">
        <Row>
          <Image
            src={qr}
            w="186px"
            h="186px"
          />
          <ModalLeftContentStyle>
            <Row mb="XS">
              <Text label="app_users:temp_login.tmp_login_id"/>:
              <LoginInfoStyle text={tmpLogin} />
            </Row>
            <Row mb="R">
              <Text label="app_users:temp_login.tmp_login_password"/>:
              <LoginInfoStyle text={tmpPassword} />
            </Row>
            <Text label="app_users:temp_login.qr_card_description" />
          </ModalLeftContentStyle>
        </Row>
      </Card>
    </Modal>
  )
}

QrModal.propTypes = {
  id: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

export default QrModal
