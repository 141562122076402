import { api, getFileFromURL, MULTIPART_HEADERS } from '@gk-devteam/apmc-core-web'

export const fetchFCMStatus = (cancelSource) => {
  return api.get('fcm', {
    cancelToken: cancelSource.token
  })
}

export const postFCMToken = (data, cancelSource) => {
  if (data) {
    return api.post('fcm', data, {
      cancelToken: cancelSource.token
    })
  }
}
export const deleteFCMToken = (token, cancelSource) => {
  if (token) {
    return api.post('fcm/delete', { token }, {
      cancelToken: cancelSource.token
    })
  }
}

export const deleteFCMTokens = (cancelSource) => {
  return api.delete('fcm', {
    cancelToken: cancelSource && cancelSource.token
  })
}

export const fetchTheme = (cancelSource) => {
  return api.get('settings/theme', {
    cancelToken: cancelSource.token
  })
}

export const postTheme = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.header.file) {
      try {
        const file = await getFileFromURL(data.header.file)
        formData.append('file', file, data.header.file_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }

    delete data.header.file
    delete data.header.file_url
    delete data.header.file_filename

    formData.append('data', JSON.stringify(data))
    return api.post('settings/theme', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}
