import { createSelector } from 'reselect'
// import { SERVICES_PLAN } from '../../constants'

const selectService = state => state.service

export const selectServiceLoading = createSelector(
  selectService,
  service => service.loading
)
export const selectServicePosting = createSelector(
  selectService,
  service => service.posting
)
export const selectServiceError = createSelector(
  selectService,
  service => service.error
)

export const selectServiceData = createSelector(
  selectService,
  service => service.data
)
export const selectServiceID = createSelector(
  selectService,
  service => service?.data?.id
)
export const selectServiceName = createSelector(
  selectService,
  service => service?.data?.service_name
)
export const selectServiceType = createSelector(
  selectService,
  service => service?.data?.service_type || 1
)
export const selectServiceResults = createSelector(
  selectService,
  service => service?.results
)
export const selectServiceResultsCount = createSelector(
  selectService,
  service => service?.results_count
)
