import { api, MULTIPART_HEADERS, getFileFromURL } from '@gk-devteam/apmc-core-web'

export const fetchContractDocumentLabels = (cancelSource) => {
  return api.get('documents/label', {
    cancelToken: cancelSource.token

  })
}
export const fetchContractDocuments = (contractID, cancelSource) => {
  return api.get(`contracts/${contractID}/documents`, {
    cancelToken: cancelSource.token

  })
}

export const postContractDocument = async (data, contractID, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.document) {
      try {
        const document = await getFileFromURL(data.document)
        formData.append('document', document, data.document_filename)
      } catch (error) {
        console.warning('error while processing document')
      }
    }

    delete data.document
    delete data.document_filename

    formData.append('data', JSON.stringify(data))
    return api.post(`contracts/${contractID}/documents`, formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}
export const deleteContractDocument = (documentID, contractID, cancelSource) => {
  return api.delete(`contracts/${contractID}/documents/${documentID}`, {
    cancelToken: cancelSource.token
  })
}
