export const FETCH_CONTRACTS = 'fetch_contracts'
export const FETCH_CONTRACTS_SUCCESS = 'fetch_contracts_success'
export const FETCH_CONTRACTS_FAIL = 'fetch_contracts_fail'
export const RESET_CONTRACTS = 'reset_contracts'

export const DELETE_CONTRACT = 'delete_contract'
export const DELETE_CONTRACT_SUCCESS = 'delete_contract_success'
export const DELETE_CONTRACT_FAIL = 'delete_contract_fail'

export const FETCH_CONTRACT = 'fetch_contract'
export const FETCH_CONTRACT_SUCCESS = 'fetch_contract_success'
export const FETCH_CONTRACT_FAIL = 'fetch_contract_fail'
export const RESET_CONTRACT = 'reset_contract'

export const POST_CONTRACT = 'post_contract'
export const POST_CONTRACT_SUCCESS = 'post_contract_success'
export const POST_CONTRACT_FAIL = 'post_contract_fail'

export const FETCH_RENEW_CONTRACT = 'fetch_renew_contract'
export const FETCH_RENEW_CONTRACT_SUCCESS = 'fetch_renew_contract_success'
export const FETCH_RENEW_CONTRACT_FAIL = 'fetch_renew_contract_fail'
export const RESET_RENEW_CONTRACT = 'reset_renew_contract'

export const POST_RENEW_CONTRACT = 'post_renew_contract'
export const POST_RENEW_CONTRACT_SUCCESS = 'post_renew_contract_success'
export const POST_RENEW_CONTRACT_FAIL = 'post_renew_contract_fail'

export const POST_ACCEPT_RENEW_CONTRACT = 'post_accept_renew_contract'
export const POST_ACCEPT_RENEW_CONTRACT_SUCCESS = 'post_accept_renew_contract_success'
export const POST_ACCEPT_RENEW_CONTRACT_FAIL = 'post_accept_renew_contract_fail'

export const POST_CANCEL_RENEW_CONTRACT = 'post_cancel_renew_contract'
export const POST_CANCEL_RENEW_CONTRACT_SUCCESS = 'post_cancel_renew_contract_success'
export const POST_CANCEL_RENEW_CONTRACT_FAIL = 'post_cancel_renew_contract_fail'

export const FETCH_CANCEL_CONTRACT = 'fetch_cancel_contract'
export const FETCH_CANCEL_CONTRACT_SUCCESS = 'fetch_cancel_contract_success'
export const FETCH_CANCEL_CONTRACT_FAIL = 'fetch_cancel_contract_fail'
export const RESET_CANCEL_CONTRACT = 'reset_cancel_contract'

export const POST_CANCEL_CONTRACT = 'post_cancel_contract'
export const POST_CANCEL_CONTRACT_SUCCESS = 'post_cancel_contract_success'
export const POST_CANCEL_CONTRACT_FAIL = 'post_cancel_contract_fail'

export const POST_CANCEL_CONTRACT_CANCELING = 'post_cancel_contract_canceling'
export const POST_CANCEL_CONTRACT_CANCELING_SUCCESS = 'post_cancel_contract_canceling_success'
export const POST_CANCEL_CONTRACT_CANCELING_FAIL = 'post_cancel_contract_canceling_fail'

export const DELETE_CASA_APPROVAL_NUMBER = 'delete_casa_approval_number'
