import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import PropertiesRecipients from './Recipients/PropertiesRecipients'
import AreasRecipients from './Recipients/AreasRecipients'
import UsersRecipients from './Recipients/UsersRecipients'
import ContractsRecipients from './Recipients/ContractsRecipients'

const NoticeRecipientsSearch = () => {
  const recipientType = useSelector(state => state.form.recipient_type)

  switch (recipientType) {
    case 1:
      return <PropertiesRecipients />
    case 3:
      return <AreasRecipients />
    case 2:
      return <UsersRecipients />
    case 4:
      return <ContractsRecipients />
    default:
      return null
  }
}

export default memo(NoticeRecipientsSearch)

NoticeRecipientsSearch.propTypes = {}
