import React, { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_CONTRACTS,
  RESET_CONTRACTS
} from '../../../../../types'

import {
  ResultsBlockStyle,
  BetweenStyle
} from '../../../CouponForm.style'

import UsersForm from './UsersForm'
import UsersList from './UsersList'
import RecipientsResult from './RecipientsResult'

const USERS_LIMIT = 10000

const UsersModal = ({ visible, close }) => {
  const dispatch = useDispatch()

  const fetchData = useCallback(
    ({
      roomNumber = '',
      roomId = '',
      propertyName = '',
      propertyId = ''
    }) => {
      dispatch({
        type: FETCH_CONTRACTS,
        query: {
          room_number: roomNumber,
          room_id: roomId,
          property_name: propertyName,
          property_id: propertyId,
          limit: USERS_LIMIT
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    fetchData({})
    return () => {
      dispatch({ type: RESET_CONTRACTS })
    }
  }, [fetchData, dispatch])

  return (
    <Modal
      visible={visible}
      title='coupons:select_room_number'
      onCancel={close}
      cancelText='close'
      fullWidth
      fullHeight
      large
    >
      <UsersForm fetchData={fetchData}/>
      <ResultsBlockStyle>
        <UsersList />
        <BetweenStyle> {'>>'} </BetweenStyle>
        <RecipientsResult />
      </ResultsBlockStyle>
    </Modal>
  )
}

export default memo(UsersModal)

UsersModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}
