import { createSelector } from 'reselect'

const selectYasueSurvey = state => state.yasueSurvey

export const selectLoadingYasueSurvey = createSelector(
  selectYasueSurvey,
  survey => survey.loading
)

export const selectYasueSurveyData = createSelector(
  selectYasueSurvey,
  survey => survey.data || {}
)

export const selectYasueSurveyError = createSelector(
  selectYasueSurvey,
  survey => survey.error
)
