export const FETCH_FCM_STATUS = 'fetch_fcm_status'
export const FETCH_FCM_STATUS_SUCCESS = 'fetch_fcm_status_success'
export const FETCH_FCM_STATUS_FAIL = 'fetch_fcm_status_fail'

export const POST_FCM_TOKEN = 'post_fcm_token'
export const POST_FCM_TOKEN_SUCCESS = 'post_fcm_token_success'
export const POST_FCM_TOKEN_FAIL = 'post_fcm_token_fail'

export const DELETE_FCM_TOKEN = 'delete_fcm_token'
export const DELETE_FCM_TOKEN_SUCCESS = 'delete_fcm_token_success'
export const DELETE_FCM_TOKEN_FAIL = 'delete_fcm_token_fail'

export const RESET_FCM_STATUS = 'reset_fcm_status'

export const FETCH_THEME = 'fetch_theme'
export const FETCH_THEME_SUCCESS = 'fetch_theme_success'
export const FETCH_THEME_FAIL = 'fetch_theme_fail'
export const RESET_THEME = 'reset_theme'

export const POST_THEME = 'post_theme'
export const POST_THEME_SUCCESS = 'post_theme_success'
export const POST_THEME_FAIL = 'post_theme_fail'
