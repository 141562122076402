import { BORDER_RADIUS,FONT_SIZE,getColor, Text, SPACING, Row, css} from "@gk-devteam/apmc-core-web";
import styled from 'styled-components'

const OFFSET_LEFT = 44
const ROW_HEIGHT = 8
const BarStyle = () => css`
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  background-color: ${props=> props.active ? getColor(props, 'accent') : getColor(props, 'lightText')};
  transition: all 300ms ease-in-out;
`

export const LeftContainerStyle = styled.div`
  margin-right: ${SPACING.LARGE}rem;
  margin-bottom: ${SPACING.SMALLEST}rem;
`

export const TimeContainerBorder = styled.div`
  border: 1px solid ${props=>getColor(props,'cardBorder')};
  margin-top: ${SPACING.SMALL}rem;
  ${BORDER_RADIUS}
  padding: ${SPACING.SMALLEST}rem;
  width: 400px;
  height: 250px;
  position: relative;
`

export const TimeBorder = styled.div`
  height: 1px;
  width: calc(100% - ${OFFSET_LEFT}px);
  box-sizing: border-box;
  margin-right: 0;
  margin-left: auto;
  background-color: ${props=>getColor(props, 'cardBorder')};
`

export const TimeLabel = styled(Text)`
  ${FONT_SIZE.SMALL}
  margin-right: 6px;
`

export const TimeRow = styled(Row)`
  height: ${SPACING.SMALLEST}rem;
`

export const WeekdaysStyle = styled.div`
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 12px;
  left: ${OFFSET_LEFT}px;
  right: 0;
  bottom: ${SPACING.SMALLEST}rem;
`

export const WeekdayStyle = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const WeekdayBarStyle = styled.div`
  width: ${SPACING.SMALLEST}rem;
  flex: 1;
  position: relative;

  &:before{
    ${BarStyle}
    top: 0;
    bottom: ${({top, bottom}) => {
      if (top === 0 && bottom === 24) {
        return 0
      }
      if(top > bottom){
        return `calc(100% - ${bottom*ROW_HEIGHT}px)`
      }
        return 'inherit'
    }};
  }
  &:after{
    ${BarStyle}
    top: ${props => props.top != null ? `${props.top*ROW_HEIGHT}px` : 'inherit'};
    bottom: ${({ top, bottom }) => {
      if (top === 0 && bottom === 24) {
        return 0
      }
      if(top < bottom){
        return (24 - bottom)*ROW_HEIGHT+'px'
      }
      return 0
    }};
  }
`

export const WeekdayLabelStyle = styled(Text)`
  margin-top: ${SPACING.SMALLEST}rem;
`
