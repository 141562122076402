import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { saveAs } from 'file-saver'
import { CancelToken } from 'axios'
import {
  FRONT_TIMESTAMP_FORMAT,
  Icon,
  InquiryID,
  PageLayout,
  PrimaryButton,
  RoleControlled,
  Row,
  Table,
  Text,
  Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import dayjs from 'dayjs'
import { message } from 'antd'
import PropTypes from 'prop-types'

import { selectContractInspectionsData, selectContractInspectionsLoading } from '../../../selectors'
import {
  DELETE_CONTRACT_INSPECTION,
  FETCH_CONTRACT_INSPECTIONS,
  RESET_CONTRACT_INSPECTIONS,
  TOGGLE_ADD_INSPECTION_MODAL
} from '../../../types'
import { INQUIRY_PREFIX } from '../../../constants'
import { downloadInspectionDocument } from '../../../services'

import InspectionModal from './InspectionModal'
import { MainCellStyle } from './ContractInspectionsPage.style'

function ContractInspectionsPage ({ contractID }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [currentInspectionData, setCurrentInspectionData] = useState(null)

  const breadcrumbs = [
    {
      label: 'navigation:house_maker.inspections_list',
      link: '/inspections'
    },
    {
      text: contractID,
      link: `/inspections/${contractID}`
    }
  ]

  const fetchData = useCallback(
    () => {
      if (contractID) {
        dispatch({ type: FETCH_CONTRACT_INSPECTIONS, contract_id: contractID })
      }
    },
    [dispatch, contractID]
  )

  useEffect(() => {
    fetchData()
    return () => {
      dispatch({ type: RESET_CONTRACT_INSPECTIONS })
    }
  }, [dispatch, fetchData])

  const _showAddModal = (id) => {
    dispatch({ type: TOGGLE_ADD_INSPECTION_MODAL })
  }

  const dateCell = useCallback(
    (value, id, key, classname, row) => {
      return (
        <MainCellStyle>
          <Text text={dayjs(value).format(FRONT_TIMESTAMP_FORMAT)} />
          {row.with_file && <Icon name="pdf" />}
        </MainCellStyle>
      )
    },
    []
  )

  const _handleDownloadDisplay = useCallback(
    (data) => {
      if (data.with_file) return true
      return false
    },
    []
  )

  const _editInspection = (id, close, data) => {
    setCurrentInspectionData(data)
    dispatch({ type: TOGGLE_ADD_INSPECTION_MODAL })
    close()
  }
  const _resetActiveData = () => {
    setCurrentInspectionData(null)
  }

  const _downloadFile = async (id, closeMenu, row) => {
    const hideMessage = message.loading(t('inspections:downloading'), 0)
    closeMenu()
    try {
      const cancelSource = CancelToken.source()
      const res = await downloadInspectionDocument(contractID, id, cancelSource)
      if (res) saveAs(res.data, `【${contractID} 点検】${row?.inspection_date}.pdf`)
      if (hideMessage) hideMessage()
    } catch (error) {
      if (hideMessage) hideMessage()
      message.error(error?.data?.message || error?.message)
    }
  }

  const inspectionsColumns = [
    {
      title: '',
      dataIndex: 'inspection_date',
      key: 'inspection_date',
      render: dateCell
    },
    {
      title: '',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('edit'),
          onClick: _editInspection
        },
        {
          label: t('inspections:inspection_file_download'),
          onClick: _downloadFile,
          shouldRender: _handleDownloadDisplay
        },
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            dispatch({ type: DELETE_CONTRACT_INSPECTION, contract_id: contractID, inspection_id: id, onSuccess: fetchData })
            close()
          }
        }
      ]
    }
  ]

  const _renderContent = () => {
    return (
      <Table
        fullHeight
        columns={inspectionsColumns}
        rowKey="id"
        loadingSelector={selectContractInspectionsLoading}
        dataSelector={selectContractInspectionsData}
        listMode
      />
    )
  }

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
    >
      <InspectionModal refetch={fetchData} contractId={contractID} data={currentInspectionData} onClose={_resetActiveData}/>
      <Row justify="between">
        <Title label='inspections:inspection_details' />
        <RoleControlled authorized="custom.house_maker">
          <PrimaryButton
            label="inspections:add_inspection_date"
            handleClick={_showAddModal}
            reverse
            withMargin
          />
        </RoleControlled>
      </Row>
      <Row mb="ML">
        <InquiryID id={contractID} prefix={INQUIRY_PREFIX.CONTRACT} />
      </Row>
      { _renderContent() }
    </PageLayout>
  )
}

ContractInspectionsPage.propTypes = {
  contractID: PropTypes.string
}

export default ContractInspectionsPage
