/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const contactSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    label: yup
      .string()
      .max(255)
      .nullable()
      .required()
      .label(i18n.t('contacts:contact_label')),
    name: yup
      .string()
      .max(255)
      .nullable()
      .required()
      .label(i18n.t('contacts:contact_name')),
    phone: yup
      .string()
      .nullable()
      .test(
        'either-is-required',
        i18n.t('either_required', { label: '${label}', requiredField: i18n.t('common:email') }),
        function (value) {
          const { mail_address } = this.parent
          if (!value && !mail_address) return false
          return true
        })
      .label(i18n.t('common:phone')),
    mail_address: yup
      .string()
      .email()
      .nullable()
      .test(
        'either-is-required',
        i18n.t('either_required', { label: '${label}', requiredField: i18n.t('common:phone') }),
        function (value) {
          const { phone } = this.parent
          if (!value && !phone) return false
          return true
        })
      .label(i18n.t('common:email')),
    hours: yup
      .string()
      .nullable()
      .label(i18n.t('properties:contact_hours'))
  })
}
