/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Image
} from '@gk-devteam/apmc-core-web'

import {
  CommentStyle,
  CommentTitleStyle,
  CommentContentStyle,
  CommentDateStyle
} from './AdminTasksChecklistRoomPage.style'

const IssueComment = React.forwardRef(({ issueComment }, ref) => {
  const {
    // comment_id,
    comment,
    avatar,
    name,
    comment_datetime
  } = issueComment
  return (
    <CommentStyle ref={ref}>
      <Row>
        <Row>
          <Image src={avatar} w="32px" h="32px" cover/>
          <CommentTitleStyle text={name} />
        </Row>
        <CommentDateStyle text={comment_datetime || '2020/02/03 10:30'} />
      </Row>
      <Row mt="XS">
        <CommentContentStyle>{comment}</CommentContentStyle>
      </Row>
    </CommentStyle>
  )
})

export default IssueComment

IssueComment.propTypes = {
  issueComment: PropTypes.shape({
    comment_id: PropTypes.number.isRequired,
    comment: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    name: PropTypes.string.isRequired,
    comment_datetime: PropTypes.string
  }).isRequired
}
