import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  Column,
  FilePickerStandAlone,
  PrimaryButton,
  RoleControlled,
  Row,
  getFileFromURL,
  TOGGLE_ARRAY_ITEM
} from '@gk-devteam/apmc-core-web'

import { selectNoticeEditable } from '../../../../../selectors'
import { customCsvParser } from '../../../../../utils'

import UsersModal from './UsersModal'

const UsersRecipients = () => {
  const dispatch = useDispatch()
  const editable = useSelector(selectNoticeEditable)
  const [modaleVisible, setModaleVisible] = useState(false)

  const _openModal = () => {
    setModaleVisible(true)
  }
  const _closeModal = () => {
    setModaleVisible(false)
  }

  const _handleCSVUpload = async (file) => {
    try {
      const blob = await getFileFromURL(file.file)
      const data = await customCsvParser({
        file: blob,
        transformHeader: (header) => {
          switch (header) {
            case '契約者ID':
              return 'contractor_id'
            case '顧客名':
              return 'contractor_name'
            default:
              return header
          }
        }
      })

      // console.log('data', data?.[0])

      const update = data?.flatMap(row => {
        return [
          {
            key: 'recipients_list',
            value: { id: Number(row.contractor_id), label: row.contractor_name },
            objectComparatorKey: 'id'
          },
          {
            key: 'recipients_id',
            value: Number(row.contractor_id)
          }
        ]
      })

      dispatch({
        type: TOGGLE_ARRAY_ITEM,
        payload: update
      })
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <Row mt="XS">
      {
        editable ? (
          <Column align="start">
            <RoleControlled authorized="custom.yasue">
              <Card mb="XL" title="notices:contracts_from_csv">
                <FilePickerStandAlone
                  handleChange={_handleCSVUpload}
                  name="csv"
                  types={['csv']}
                />
              </Card>
            </RoleControlled>
            <PrimaryButton label="notices:search_users" handleClick={_openModal} type="button" />
          </Column>
        ) : null
      }
      <UsersModal
        visible={modaleVisible}
        close={_closeModal}
      />
    </Row>
  )
}

export default memo(UsersRecipients)

UsersRecipients.propTypes = {}
