import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const refuseReportSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    message: yup.string().required().label(i18n.t('refuse:message'))
  })
}
