import { createSelector } from 'reselect'

const selectRefund = state => state.refund

export const selectRefundLoading = createSelector(
  selectRefund,
  refund => refund.loading
)

export const selectRefundData = createSelector(
  selectRefund,
  refund => refund.data
)
export const selectRefundReceipts = createSelector(
  selectRefund,
  refund => refund.data?.files
)
export const selectRefundError = createSelector(
  selectRefund,
  refund => refund.error
)
