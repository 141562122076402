/* eslint-disable react/display-name */
import React from 'react'
import { useDispatch } from 'react-redux'
import { Tooltip } from 'antd'
import PropTypes from 'prop-types'
import {
  FILES_EXTENSIONS,
  useTranslation,
  Text,
  LightText,
  Image,
  Icon,
  Video,
  Row,
  SPACING,
  Confirm
} from '@gk-devteam/apmc-core-web'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { deleteChatMessage } from '../../../../actions'
import {
  ChatMessageContainerStyle,
  ChatBodyContainerStyle,
  ChatMessageMainStyle,
  MessageImageContainerStyle,
  MessageFileContainerStyle,
  FileDownloadStyle,
  FileIconContainerStyle,
  MessageVideoContainerStyle,
  ChatMessageTextStyle,
  ChatDateContainerStyle,
  FileSizeStyle,
  DeleteMessageStyle
} from './ChatMessage.style'

const ChatMessage = React.forwardRef(({
  id,
  type,
  content,
  fileExtension,
  fileSize,
  date,
  isOwn,
  isRead,
  onImageLoaded,
  author
}, ref) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const _renderMessageBody = () => {
    switch (type) {
      case 0:
        return _renderImageBody()
      case 1:
        return _renderTextBody()
      case 2:
        return _renderFileBody()
      case 3:
        return _renderVideoBody()
      default:
        return null
    }
  }
  const _renderImageBody = () => {
    return (
      <MessageImageContainerStyle>
        <Image
          src={content}
          onImageLoaded={onImageLoaded}
          contain
          full
          previewable
        />
      </MessageImageContainerStyle>
    )
  }
  const _renderFileBody = () => {
    let fileType
    if (fileExtension) {
      for (const type in FILES_EXTENSIONS) {
        const extensions = FILES_EXTENSIONS[type]
        if (extensions.includes(`.${fileExtension}`)) {
          fileType = type
          break
        }
      }
    }

    if (!fileType) return null
    return (
      <MessageFileContainerStyle>
        <Row>
          <FileIconContainerStyle>
            <Icon name={fileType} />
          </FileIconContainerStyle>
          <FileDownloadStyle href={content} download target="_blank" rel="noopener noreferrer">{t('download')}</FileDownloadStyle>
        </Row>
        {
          fileSize
            ? (
              <Row mt="XS">
                <FileSizeStyle text={t('admin_tasks:fileSize') + fileSize} />
              </Row>
            )
            : null
        }
      </MessageFileContainerStyle>
    )
  }
  const _renderVideoBody = () => {
    let fileType
    if (fileExtension) {
      for (const type in FILES_EXTENSIONS) {
        const extensions = FILES_EXTENSIONS[type]
        if (extensions.includes(`.${fileExtension.toLowerCase()}`)) {
          fileType = type
          break
        }
      }
    }

    if (!fileType) return null
    return (
      <MessageVideoContainerStyle>
        <Video src={content} height={'100%'}/>
      </MessageVideoContainerStyle>
    )
    // return (
    //   <MessageVideoContainerStyle>

    //   </MessageVideoContainerStyle>
    // )
  }
  const _renderTextBody = () => {
    return (
      <ChatMessageTextStyle isOwn={isOwn} isRead={isRead}>
        <Text text={content} />
      </ChatMessageTextStyle>
    )
  }

  const _handleDelete = () => {
    dispatch(deleteChatMessage({ messageID: id }))
  }

  return (
    <ChatMessageContainerStyle isOwn={isOwn}>
      <ChatMessageMainStyle>
        <ChatBodyContainerStyle isOwn={isOwn} isRead={isRead}>
          { _renderMessageBody() }
        </ChatBodyContainerStyle>
        <ChatDateContainerStyle>
          <Tooltip
            title={author?.name}
            overlayStyle={{
              fontSize: `${SPACING.SMALL}rem`
            }}
            placement="bottom"
          >
            <time ref={ref}>
              <LightText text={date} />
            </time>
          </Tooltip>
          <span>
            <LightText label={isRead && isOwn ? '既読' : ''} />
            {isOwn && (
              <Confirm
                title='properties:delete_property.warning_message'
                placement="topRight"
                onConfirm={_handleDelete}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                <DeleteMessageStyle>
                  <DeleteOutlined />
                </DeleteMessageStyle>
              </Confirm>
            )}
          </span>
        </ChatDateContainerStyle>
      </ChatMessageMainStyle>
    </ChatMessageContainerStyle>
  )
})
export default ChatMessage

ChatMessage.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  fileExtension: PropTypes.string,
  fileSize: PropTypes.string,
  date: PropTypes.string.isRequired,
  isOwn: PropTypes.bool.isRequired,
  isRead: PropTypes.bool.isRequired,
  onImageLoaded: PropTypes.func,
  author: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })
}
ChatMessage.defaultProps = {

}
