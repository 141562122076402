/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const manualSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    title: yup.string().max(255).required().label(i18n.t('common:title')),
    content: yup.string().max(500).nullable().label(i18n.t('common:description')),
    file: yup
      .string()
      .nullable()
      .isBlob()
      .test(
        'either-is-required',
        i18n.t('required', { label: '${label}' }),
        function (value) {
          const { file_url } = this.parent
          if (!value && !file_url) return false
          return true
        })
      .label(i18n.t('common:file'))
  })
}
