import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  getFileFromURL,
  getFileExtension,
  PageLayout
} from '@gk-devteam/apmc-core-web'

import { FETCH_PROPERTY_SUCCESS, RESET_PROPERTY } from '../../../types'
import { fetchProperty } from '../../../services'

import PropertyForm from '../../../forms/PropertyForm'

const breadcrumbs = [
  {
    label: 'navigation:properties',
    link: '/properties'
  },
  {
    label: 'navigation:properties_add',
    link: '/properties/add'
  }
]

const initialData = {
  name: null,
  property_manage_id: null,
  room_count: null,
  postcode: null,
  prefecture: null,
  city: null,
  address: null,
  bank_account: null,
  rooms: null
}

const PropertiesAddPage = ({ location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(null)

  const prepareData = useCallback(
    async (data) => {
      if (data && data.id) {
        const editedData = { ...data }
        editedData.delete_file = false
        delete editedData.id
        delete editedData.modified
        delete editedData.modifiedBy
        if (editedData.rooms) {
          for (const room of editedData.rooms) {
            delete room.room_id
          }
        }
        if (editedData.file_url) {
          try {
            const blob = await getFileFromURL(data.file_url)
            const extension = getFileExtension(data.file_url)
            const blobURL = URL.createObjectURL(blob)
            editedData.file = blobURL
            editedData.file_filename = `property.${extension}`
          } catch (error) {
            console.warning('error retrieving file blob', error)
          }
        }
        setFormData(editedData)
      }
    },
    []
  )

  const fetchData = useCallback(
    async () => {
      const cancelSource = CancelToken.source()
      if (location && location.state && location.state.copy) {
        setLoading(true)
        try {
          const res = await fetchProperty(location.state.id, cancelSource)
          setLoading(false)
          if (res && res.data) {
            prepareData(res.data)
            dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: null })
          } else {
            message.error(t('fetch_error'))
          }
        } catch (error) {
          setLoading(false)
          message.error(t('fetch_error'))
        }
      } else {
        setFormData(initialData)
        dispatch({ type: FETCH_PROPERTY_SUCCESS, payload: null })
      }
    },
    [location, dispatch, prepareData, t]
  )

  useEffect(() => {
    fetchData()
    return () => {
      dispatch({ type: RESET_PROPERTY })
    }
  }, [location, dispatch, fetchData])

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      {
        formData
          ? <PropertyForm data={formData}/>
          : null
      }
    </PageLayout>
  )
}
export default PropertiesAddPage

PropertiesAddPage.propTypes = {
  location: PropTypes.object
}
PropertiesAddPage.defaultProps = {

}
