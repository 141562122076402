import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Form,
  FilePicker,
  Card,
  TextInput,
  TextArea
} from '@gk-devteam/apmc-core-web'

import { guideCategorySchema } from '../../validation'

const GuideCategoryForm = ({ handleSubmit }) => {
  return (
    <Form
      id="guide_category_form"
      handleSubmit={handleSubmit}
      validationSchema={guideCategorySchema}
      authorized="guides.functions.update"
      // loadingSelector={selectLoadingAppUser}
      // postingSelector={selectPostingAppUser}
      // initialData={initialValues}
      // debug
    >
      <Card>
        <FilePicker
          flex={1}
          name="file"
          delete_name="delete_file"
          types={['image']}
          placeholder=""
        />
      </Card>
      <Row mt="R">
        <TextInput
          flex={1}
          type="text"
          name="name"
          label='title'
          placeholder=""
          required
        />
      </Row>
      <Row>
        <TextArea
          flex={1}
          name="content"
          label='content'
          placeholder=""
        />
      </Row>
    </Form>
  )
}

export default memo(GuideCategoryForm)

GuideCategoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}
