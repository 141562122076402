import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_ROOM,
  FETCH_ROOM_SUCCESS,
  FETCH_ROOM_FAIL,
  RESET_ROOM,
  POST_ROOM,
  POST_ROOM_SUCCESS,
  POST_ROOM_FAIL
  // DELETE_PROPERTY,
  // DELETE_PROPERTY_SUCCESS,
  // DELETE_PROPERTY_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_ROOM]: (state, action) => {
    state.posting = true
  },
  [POST_ROOM_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_ROOM_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_ROOM]: (state, action) => {
    state.loading = true
  },
  [FETCH_ROOM_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_ROOM_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_ROOM]: () => INITIAL_STATE

})
