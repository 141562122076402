import React, { memo, useContext, useState } from 'react'
import {
  PrimaryButton,
  ReadOnlyContext,
  Row
} from '@gk-devteam/apmc-core-web'

import UsersModal from './UsersModal'

const UsersRecipients = () => {
  const [modaleVisible, setModaleVisible] = useState(false)
  const isReadOnly = useContext(ReadOnlyContext)
  const _openModal = () => {
    setModaleVisible(true)
  }
  const _closeModal = () => {
    setModaleVisible(false)
  }

  return (
    <Row mt="XS">
      {
        !isReadOnly ? <PrimaryButton label="notices:search_users" handleClick={_openModal} type="button" /> : null
      }
      <UsersModal
        visible={modaleVisible}
        close={_closeModal}
      />
    </Row>
  )
}

export default memo(UsersRecipients)

UsersRecipients.propTypes = {}
