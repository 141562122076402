import React from 'react'
import { useSelector } from 'react-redux'
import {
  Modal,
  Row,
  Text,
  TextInput,
  Form
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { selectServicesPosting } from '../../../../selectors'

import { InputRowStyle } from './CloudSign.style'

function ClientIdModal ({ visible, onOk, onCancel, id }) {
  const posting = useSelector(selectServicesPosting)

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okText="register"
      title="services:cloudsign.register_id"
      formID="clientID"
      posting={posting}
    >
      <Row>
        <p><Text label="services:cloudsign.register_explanation" /></p>
      </Row>
      <Form
        id="clientID"
        handleSubmit={onOk}
        initialData={{
          client_id: id
        }}
      >
        <InputRowStyle>
          <TextInput
            name="client_id"
            label="services:cloudsign.client_id_label"
          />
        </InputRowStyle>
      </Form>
    </Modal>
  )
}

ClientIdModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  id: PropTypes.string
}

export default ClientIdModal
