import { put, call, takeLatest, select, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchSurvey,
  postSurvey,
  deleteSurvey,
  fetchSurveyAnswerUsers
} from '../../services'
import {
  FETCH_SURVEY,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAIL,
  FETCH_SURVEY_ANSWER_USERS,
  FETCH_SURVEY_ANSWER_USERS_SUCCESS,
  FETCH_SURVEY_ANSWER_USERS_FAIL,
  POST_SURVEY,
  POST_SURVEY_SUCCESS,
  POST_SURVEY_FAIL,
  DELETE_SURVEY,
  DELETE_SURVEY_SUCCESS,
  DELETE_SURVEY_FAIL,
  FETCH_SURVEYS
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchSurveySaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchSurvey, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_SURVEY_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_SURVEY_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_SURVEY_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * fetchSurveyAnswerUsersSaga ({ answer_ids }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchSurveyAnswerUsers, { answer_ids }, cancelSource)
    if (res?.data?.results) {
      yield put({ type: FETCH_SURVEY_ANSWER_USERS_SUCCESS, payload: res.data.results })
    } else {
      yield put({ type: FETCH_SURVEY_ANSWER_USERS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_SURVEY_ANSWER_USERS_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postSurveySaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postSurvey, data, cancelSource)
    if (res?.status === 204) {
      if (successRedirect) yield call(successRedirect, res.data.id)
      yield put({ type: POST_SURVEY_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_SURVEY_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_SURVEY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteSurveySaga ({ id, onSuccess, onFail }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteSurvey, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_SURVEY_SUCCESS })
      yield put({ type: FETCH_SURVEYS, query })
      if (onSuccess) yield call(onSuccess)
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_SURVEY_FAIL, payload: error.data || error.message })
    if (onFail) yield call(onFail)
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchSurvey () {
  yield takeLatest(FETCH_SURVEY, fetchSurveySaga)
  yield takeLatest(FETCH_SURVEY_ANSWER_USERS, fetchSurveyAnswerUsersSaga)
  yield takeLatest(POST_SURVEY, postSurveySaga)
  yield takeLatest(DELETE_SURVEY, deleteSurveySaga)
}
