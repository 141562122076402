import React, { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslation,
  Card,
  Table,
  LightText
} from '@gk-devteam/apmc-core-web'

import {
  selectContractLoading,
  selectInvitedUsers
} from '../../../../selectors'

const InvitedUsers = () => {
  const { t } = useTranslation()
  const invitedUsers = useSelector(selectInvitedUsers)

  const nameCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <LightText text={`${value} ${data.first_name}`}/>
    },
    []
  )
  const furiganaCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <LightText text={`${value} ${data.furigana_first_name}`}/>
    },
    []
  )

  const sexCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const activeSex = (value) => {
        switch (value) {
          case 1:
            return t('app_users:male')
          case 2:
            return t('app_users:female')
          default:
            return t('app_users:no_sex')
        }
      }
      return <LightText text={activeSex(value)}/>
    },
    [t]
  )

  if (!invitedUsers) return null

  const childUsersColumns = [
    {
      title: t('name'),
      dataIndex: 'last_name',
      key: 'last_name',
      render: nameCell
    },
    {
      title: t('furigana'),
      dataIndex: 'furigana_last_name',
      key: 'furigana_last_name',
      render: furiganaCell
    },
    {
      title: t('contracts:invited_users.relation_name'),
      dataIndex: 'relation_name',
      key: 'relation_name'
    },
    {
      title: t('email'),
      dataIndex: 'mail_address',
      key: 'mail_address'
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phoneame'
    },
    {
      title: t('app_users:sex'),
      dataIndex: 'sex',
      key: 'sex_name',
      render: sexCell
    },
    {
      title: t('app_users:birthday'),
      dataIndex: 'birthday',
      key: 'birthday'
    }
  ]

  return (
    <Card mb="L" title="contracts:invited_users.label">
      <Table
        columns={childUsersColumns}
        rowKey=""
        loadingSelector={selectContractLoading}
        dataSelector={selectInvitedUsers}
        noHeader
        noFooter
        leftStickyColumnCount={0}
        rightStickyColumnCount={0}
      />
    </Card>
  )
}

export default memo(InvitedUsers)

// InvitedUsers.propTypes = {}
