import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  INIT_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectGuidesLoading,
  selectGuidesHiddenBy,
  selectCategoryName,
  selectGuidesHiddenById
} from '../../../../selectors'
import {
  FETCH_GUIDES,
  RESET_GUIDES
} from '../../../../types'
import initialData from '../common/initialData'

import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import GuideModal from '../common/GuideModal'
import AdminGuidesList from './AdminGuidesList'

const AdminGuidesPage = ({ guideCategoryID }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectGuidesLoading)
  const categoryName = useSelector(selectCategoryName)
  const [modalVisible, setModalVisible] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const breadcrumbs = [
    {
      label: 'navigation:settings',
      link: '/settings'
    },
    {
      label: 'navigation:settings_guide_categories',
      link: '/settings/guides'
    },
    {
      text: categoryName,
      link: `/settings/guides/admin_details/${guideCategoryID}`
    }
  ]

  useEffect(() => {
    dispatch({
      type: FETCH_GUIDES,
      config: {
        guideCategoryID,
        type: 'guides'
      }
    })
    return () => {
      dispatch({ type: RESET_GUIDES })
    }
  }, [dispatch, guideCategoryID])

  const _openAddModal = () => {
    dispatch({ type: INIT_FORM, payload: initialData })
    setModalVisible(true)
  }
  const _openCopyModal = () => {
    setModalVisible(true)
  }
  const _openEditModal = () => {
    setModalVisible(true)
    setEditMode(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
    setEditMode(false)
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectGuidesHiddenBy}
      hiddenByIdSelector={selectGuidesHiddenById}
      title={categoryName || ''}
      authorized="guides.functions.add"
      info="services:level_message.admin"
      infoTitle="services:level_message.adminTitle"
      handleAddClick={_openAddModal}
    >
      <GuideModal
        visible={modalVisible}
        editMode={editMode}
        onCancel={_closeModal}
        guideCategoryID={guideCategoryID}
        guideType="guides"
      />
      <AdminGuidesList copyModal={_openCopyModal} editModal={_openEditModal} guideCategoryID={guideCategoryID} />
    </PanelLayout>
  )
}
export default AdminGuidesPage

AdminGuidesPage.propTypes = {
  guideCategoryID: PropTypes.string.isRequired
}
AdminGuidesPage.defaultProps = {}
