import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchContractOpelo,
  postContractOpelo
} from '../../services'
import {
  FETCH_CONTRACT_OPELO,
  FETCH_CONTRACT_OPELO_SUCCESS,
  FETCH_CONTRACT_OPELO_FAIL,
  POST_CONTRACT_OPELO,
  POST_CONTRACT_OPELO_SUCCESS,
  POST_CONTRACT_OPELO_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchContractOpeloSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchContractOpelo, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_CONTRACT_OPELO_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_CONTRACT_OPELO_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_CONTRACT_OPELO_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postContractOpeloSaga ({ id, data }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postContractOpelo, id, data, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: POST_CONTRACT_OPELO_SUCCESS })
      yield put({ type: FETCH_CONTRACT_OPELO, id })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CONTRACT_OPELO_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CONTRACT_OPELO_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchContractOpelo () {
  yield takeLatest(FETCH_CONTRACT_OPELO, fetchContractOpeloSaga)
  yield takeLatest(POST_CONTRACT_OPELO, postContractOpeloSaga)
}
