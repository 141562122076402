import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_FAQ,
  FETCH_FAQ_SUCCESS,
  FETCH_FAQ_FAIL,
  POST_FAQ,
  POST_FAQ_SUCCESS,
  POST_FAQ_FAIL,
  // DELETE_FAQ,
  // DELETE_FAQ_SUCCESS,
  // DELETE_FAQ_FAIL,
  RESET_FAQ,
  TOGGLE_FAQ,
  BULK_TOGGLE_FAQ,
  BULK_TOGGLE_FAQ_FAIL,
  TOGGLE_FAQ_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  data: null,
  categories: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_FAQ]: (state, action) => {
    state.loading = true
  },
  [FETCH_FAQ_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload.data
    state.categories = action.payload.categories
  },
  [FETCH_FAQ_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_FAQ]: (state, action) => {
    state.posting = true
  },
  [POST_FAQ_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_FAQ_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [TOGGLE_FAQ]: (state, action) => {
    state.loading = true
  },
  [TOGGLE_FAQ_FAIL]: (state, action) => {
    state.loading = false
  },
  [BULK_TOGGLE_FAQ]: (state, action) => {
    state.loading = true
  },
  [BULK_TOGGLE_FAQ_FAIL]: (state, action) => {
    state.loading = false
  },
  [RESET_FAQ]: () => INITIAL_STATE

})
