import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  FilePicker,
  Row,
  TextInput
} from '@gk-devteam/apmc-core-web'

import {
  selectLoadingAppUser,
  selectPostingAppUser
} from '../../../selectors'
import { yasueConstructionWorkFileUpdateValidation } from '../../../validation'

const ConstructionWorkFileForm = ({ data, saveDocument }) => {
  const initialValues = {
    ...data
  }

  return (
    <Form
      id="constructionWorkFileForm"
      handleSubmit={saveDocument}
      validationSchema={yasueConstructionWorkFileUpdateValidation}
      loadingSelector={selectLoadingAppUser}
      postingSelector={selectPostingAppUser}
      initialData={initialValues}
      authorized="contract_documents.functions.add"
    >
      <Row>
        <TextInput
          flex={1}
          label="yasue:construction.filename"
          type="text"
          name="filename"
          required
        />
      </Row>
      <Row>
        <FilePicker
          label='select_file'
          name="file"
          delete_name="delete_document"
          types={['pdf', 'image']}
          required
        />
      </Row>
    </Form>
  )
}
export default ConstructionWorkFileForm

ConstructionWorkFileForm.propTypes = {
  data: PropTypes.object,
  saveDocument: PropTypes.func.isRequired
}
ConstructionWorkFileForm.defaultProps = {

}
