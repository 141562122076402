import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  ItemNotFound,
  PageLayout,
  LeftNavigationMenu
} from '@gk-devteam/apmc-core-web'

import {
  selectPages,
  selectContractError,
  selectContractLoading,
  selectContractStatus,
  selectContractServices
} from '../../selectors'
import { FETCH_CONTRACT, RESET_CONTRACT } from '../../types'
import { CONTRACT_STATUS } from '../../constants'

import NotFoundPage from '../../pages/NotFoundPage'

const ContractMenu = () => {
  const status = useSelector(selectContractStatus)
  const contractServices = useSelector(selectContractServices)
  const { t } = useTranslation(['navigation'])
  const pages = useSelector(selectPages)
  const [menu, setMenu] = useState(null)

  useEffect(() => {
    const { contracts, contract_documents } = pages
    const { details, cancel, services, contacts } = contracts.functions
    const menu = []
    if (details) {
      menu.push({
        label: t('navigation:contracts_details'),
        link: './'
      })
    }
    if (contractServices?.cloudsign) {
      switch (status) {
        case CONTRACT_STATUS.RESIDING.value:
          menu.push({
            label: t('navigation:contracts_ask_renew'),
            link: './renew'
          })
          break
        case CONTRACT_STATUS.IN_RENEWING_PERIOD.value:
          menu.push({
            label: t('navigation:contracts_renew_settings'),
            link: './renew'
          })
          break
        case CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value:
        case CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value:
        case CONTRACT_STATUS.RENEWING.value:
        case CONTRACT_STATUS.RENEWING_CONFIRMED.value:
        case CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value:
          menu.push({
            label: t('navigation:contracts_renew_confirm'),
            link: './renew'
          })
          break
        default:
          break
      }
    }
    if (cancel) {
      switch (status) {
        case CONTRACT_STATUS.REQUESTING_CANCEL.value:
        case CONTRACT_STATUS.CANCELING.value:
        case CONTRACT_STATUS.MOVING_OUT.value:
        case CONTRACT_STATUS.MOVED_OUT.value:
          menu.push({
            label: t('navigation:contracts_cancel'),
            link: './cancel'
          })
          break
        default:
          break
      }
    }
    if (contract_documents.enabled) {
      menu.push({
        label: t('navigation:contracts_documents'),
        link: './documents'
      })
    }
    if (!contacts) { // TODO: remove ! when API is ready
      menu.push({
        label: t('navigation:properties_contacts'),
        link: './contacts'
      })
    }
    if (services) {
      menu.push({
        label: t('navigation:services'),
        link: './services?type=1'
      })
    }
    if (contractServices?.opelo) {
      menu.push({
        label: t('navigation:opelo'),
        link: './opelo'
      })
    }
    setMenu([...menu])
  }, [status, pages, t, contractServices])

  if (!menu) return null

  return <LeftNavigationMenu menu={menu} />
}

const ContractBlock = ({ enabled, children, contractID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const pages = useSelector(selectPages)
  const loading = useSelector(selectContractLoading)
  const error = useSelector(selectContractError)

  useEffect(() => {
    dispatch({ type: FETCH_CONTRACT, id: contractID })
    return () => {
      dispatch({ type: RESET_CONTRACT })
    }
  }, [dispatch, contractID])

  if (!contractID) return null
  if (!enabled || !children || !pages) return <NotFoundPage />

  return (
    <PageLayout
      sidebar={loading || (error && error.code === 404) ? null : () => <ContractMenu /> }
      noMain
    >
      {
        loading
          ? null
          : error && error.code === 404
            ? <ItemNotFound item={t('navigation:contracts')} link="/contracts" />
            : children
      }
    </PageLayout>
  )
}

export default ContractBlock

ContractBlock.propTypes = {
  contractID: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}
