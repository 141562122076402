import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const inuiContactsSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    property_name: yup
      .string()
      .label(i18n.t('properties:property_name')),
    app_user_name: yup
      .string()
      .label(i18n.t('app_users:app_user_name')),
    app_user_phone: yup
      .string()
      .label(i18n.t('phone')),
    contact_type: yup
      .mixed()
      .nullable()
      .oneOf([null, '', 1, 2, 3, 4, 5])
      .label(i18n.t('inui:contact_types')),
    limit: yup
      .number()
      .integer()
      .label(i18n.t('limit')),
    page: yup
      .number()
      .integer()
      .label(i18n.t('page')),
    sort_by: yup
      .string()
      .label(i18n.t('sort_by')),
    sort_order: yup
      .string()
      .label(i18n.t('sort_order'))
  })
}
