import React, { memo, useContext, useState } from 'react'
import {
  PrimaryButton,
  ReadOnlyContext,
  Row
} from '@gk-devteam/apmc-core-web'

import PropertiesModal from './PropertiesModal'

const PropertiesRecipients = () => {
  const isReadOnly = useContext(ReadOnlyContext)

  const [modaleVisible, setModaleVisible] = useState(false)
  const _openModal = () => {
    setModaleVisible(true)
  }
  const _closeModal = () => {
    setModaleVisible(false)
  }

  return (
    <Row mt="XS">
      {
        !isReadOnly ? <PrimaryButton label="notices:search_properties" handleClick={_openModal} type="button" /> : null
      }
      <PropertiesModal
        visible={modaleVisible}
        close={_closeModal}
      />
    </Row>
  )
}

export default memo(PropertiesRecipients)

PropertiesRecipients.propTypes = {}
