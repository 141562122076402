/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const contractRenewalValidation = (locale = 'ja', requiredFields) => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    administrative_fees: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .isRequired(requiredFields?.administrative_fees)
      .label(i18n.t('contracts:administrative_fees')),
    auto_renewal: yup
      .bool()
      .isRequired(requiredFields?.auto_renewal)
      .label(i18n.t('contracts:auto_renewal')),
    car_model_1: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.car_model_1)
      .label(i18n.t('contracts:car_model_1')),
    car_plate_number_1: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.car_plate_number_1)
      .label(i18n.t('contracts:car_plate_number_1')),
    car_model_2: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.car_model_2)
      .label(i18n.t('contracts:car_model_2')),
    car_plate_number_2: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.car_plate_number_2)
      .label(i18n.t('contracts:car_plate_number_2')),
    caution_money: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .isRequired(requiredFields?.caution_money)
      .label(i18n.t('contracts:caution_money')),
    contract_duration_unit: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .isRequired(requiredFields?.contract_duration_unit)
      .label(i18n.t('contracts:contract_duration_unit')),
    contract_duration: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .isRequired(requiredFields?.contract_duration)
      .label(i18n.t('contracts:contract_duration')),
    contract_end: yup
      .date()
      .nullable()
      .isRequired(requiredFields?.contract_end)
      .label(i18n.t('contracts:contract_end_date')),
    contract_guarantor: yup
      .object()
      .nullable()
      .shape({
        type: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.type'])
          .label(i18n.t('contracts:guarantor.info')),
        last_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.last_name'])
          .label(i18n.t('contracts:guarantor.last_name')),
        first_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.first_name'])
          .label(i18n.t('contracts:guarantor.first_name')),
        furigana_last_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.furigana_last_name'])
          .label(i18n.t('contracts:guarantor.furigana_last_name')),
        furigana_first_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.furigana_first_name'])
          .label(i18n.t('contracts:guarantor.furigana_first_name')),
        birthday: yup
          .date()
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.birthday'])
          .label(i18n.t('app_users:birthday')),
        relation_id: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.relation_id'])
          .label(i18n.t('contracts:invited_users.relation_name')),
        phone: yup
          .string()
          .nullable()
          .isPhone()
          .max(255)
          .isRequired(requiredFields?.['contract_guarantor.phone'])
          .label(i18n.t('common:phone')),
        mobile_phone: yup
          .string()
          .nullable()
          .isCellPhone()
          .max(255)
          .isRequired(requiredFields?.['contract_guarantor.mobile_phone'])
          .label(i18n.t('common:mobile_phone')),
        postcode: yup
          .string()
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.postcode'])
          .matches(/^\d{7}$/, { excludeEmptyString: true })
          .label(i18n.t('properties:postcode')),
        prefecture: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.prefecture'])
          .label(i18n.t('properties:prefecture')),
        city: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.city'])
          .label(i18n.t('properties:city')),
        address: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.address'])
          .label(i18n.t('common:address')),
        work: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.work'])
          .label(i18n.t('contracts:guarantor.work')),
        work_phone: yup
          .string()
          .nullable()
          .isPhone()
          .max(255)
          .isRequired(requiredFields?.['contract_guarantor.work_phone'])
          .label(i18n.t('app_users:work.phone')),
        work_position: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.work_position'])
          .label(i18n.t('contracts:guarantor.position')),
        annual_income: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.annual_income'])
          .integer().nullable().label(i18n.t('app_users:annual_income')),
        work_postcode: yup
          .string()
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.work_postcode'])
          .matches(/^\d{7}$/, { excludeEmptyString: true })
          .label(i18n.t('app_users:work.postcode')),
        work_prefecture: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.work_prefecture'])
          .label(i18n.t('properties:prefecture')),
        work_city: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.work_city'])
          .label(i18n.t('properties:city')),
        work_address: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contract_guarantor.work_address'])
          .label(i18n.t('app_users:work.address'))
      })
      .label(i18n.t('contracts:contractor.contractor_info')),
    contract_sign_date: yup
      .date()
      .nullable()
      .isRequired(requiredFields?.contract_sign_date)
      .label(i18n.t('contracts:contract_sign_date')),
    contract_start: yup
      .date()
      .isRequired(requiredFields?.contract_start)
      .label(i18n.t('contracts:contract_start_date')),
    contractor: yup
      .object()
      .nullable()
      .shape({
        last_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.last_name'])
          .label(i18n.t('app_users:last_name')),
        first_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.first_name'])
          .label(i18n.t('app_users:first_name')),
        furigana_last_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.furigana_last_name'])
          .label(i18n.t('app_users:furigana_last_name')),
        furigana_first_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.furigana_first_name'])
          .label(i18n.t('app_users:furigana_first_name')),
        app_user_manage_id: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.app_user_manage_id'])
          .label(i18n.t('app_users:app_user_manage_id')),
        mail_address: yup
          .string()
          .email()
          .nullable()
          .isRequired(requiredFields?.['contractor.mail_address'])
          .label(i18n.t('common:email')),
        phone: yup
          .string()
          .isCellPhone()
          .max(255)
          .isRequired(requiredFields?.['contractor.phone'])
          .label(i18n.t('common:phone')),
        emergency_phone: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.emergency_phone'])
          .label(i18n.t('app_users:emergency_phone')),
        postcode: yup
          .string()
          .nullable()
          .isRequired(requiredFields?.['contractor.postcode'])
          .matches(/^\d{7}$/, { excludeEmptyString: true })
          .label(i18n.t('properties:postcode')),
        prefecture: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.prefecture'])
          .label(i18n.t('properties:prefecture')),
        city: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.city'])
          .label(i18n.t('properties:city')),
        address: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.address'])
          .label(i18n.t('common:address')),
        occupation: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.occupation'])
          .label(i18n.t('app_users:occupation')),
        annual_income: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .nullable()
          .integer()
          .isRequired(requiredFields?.['contractor.annual_income'])
          .label(i18n.t('app_users:annual_income')),
        birthday: yup
          .date()
          .nullable()
          .isRequired(requiredFields?.['contractor.birthday'])
          .label(i18n.t('app_users:birthday')),
        sex: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .min(0).max(3)
          .integer()
          .nullable()
          .isRequired(requiredFields?.['contractor.sex'])
          .label(i18n.t('app_users:sex')),
        nationality: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.nationality'])
          .label(i18n.t('app_users:nationality')),
        residence_card: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.residence_card'])
          .label(i18n.t('app_users:residence_card')),
        home_country_emergency_contact: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.home_country_emergency_contact'])
          .label(i18n.t('app_users:home_country_emergency_contact')),
        work: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.work'])
          .label(i18n.t('app_users:work.name')),
        work_department: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.work_department'])
          .label(i18n.t('app_users:work.position')),
        work_phone: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.work_phone'])
          .label(i18n.t('app_users:work.phone')),
        work_postcode: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.work_postcode'])
          .label(i18n.t('app_users:work.postcode')),
        work_prefecture: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.work_prefecture'])
          .label(i18n.t('properties:prefecture')),
        work_city: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.work_city'])
          .label(i18n.t('properties:city')),
        work_address: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['contractor.work_address'])
          .label(i18n.t('app_users:work.address'))
      })
      .label(i18n.t('contracts:contractor.contractor_info')),
    deposit: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .isRequired(requiredFields?.deposit)
      .label(i18n.t('contracts:deposit')),
    landlord_fee: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .isRequired(requiredFields?.landlord_fee)
      .label(i18n.t('contracts:landlord_fee')),
    landlord_name: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.landlord_name)
      .label(i18n.t('contracts:landlord_name')),
    landlord_address: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.landlord_address)
      .label(i18n.t('contracts:landlord_address')),
    link_label: yup
      .string()
      .nullable()
      .isRequired(requiredFields?.link_label)
      .label(i18n.t('contracts:external_link_label')),
    link_url: yup
      .string()
      .nullable()
      .isRequired(requiredFields?.link_url)
      .label(i18n.t('contracts:external_link_url')),
    remarks: yup
      .string()
      .max(1000)
      .nullable()
      .isRequired(requiredFields?.remarks)
      .label(i18n.t('contracts:remarks')),
    renewal_administrative_fees: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .isRequired(requiredFields?.renewal_administrative_fees)
      .label(i18n.t('contracts:renewal_administrative_fees')),
    renewal_fees: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .isRequired(requiredFields?.renewal_fees)
      .label(i18n.t('contracts:renewal_fees')),
    renewal_notice: yup
      .array()
      .nullable()
      .isRequired(requiredFields?.renewal_notice)
      .of(yup
        .object()
        .nullable()
        .shape({
          enabled: yup
            .boolean()
            .required()
            .label(i18n.t('contracts:notice_destination_enabled')),
          destination_name: yup
            .string()
            .max(255)
            .nullable()
            .required()
            .label(i18n.t('contracts:renewal_notice_destination_name')),
          destination_email: yup
            .string()
            .email()
            .nullable()
            .required()
            .label(i18n.t('contracts:renewal_notice_destination_email'))
        })
      )
      .label(i18n.t('contracts:renewal_notice')),
    renewal_period_end: yup
      .date()
      .nullable()
      .isRequired(requiredFields?.renewal_period_end)
      .label(i18n.t('contracts:renewal_period_end')),
    renewal_period_start: yup
      .date()
      .nullable()
      .isRequired(requiredFields?.renewal_period_start)
      .label(i18n.t('contracts:renewal_period_start')),
    rent_amount: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .isRequired(requiredFields?.rent_amount)
      .label(i18n.t('contracts:rent_amount')),
    rent_common_fees: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:rent_fees.common_fees')),
    rent_contract_account: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.rent_contract_account)
      .label(i18n.t('contracts:rent_contract_account')),
    rent_date: yup
      .string()
      .max(255)
      .nullable()
      .isRequired(requiredFields?.rent_date)
      .label(i18n.t('contracts:rent_date')),
    rent_fees: yup
      .array()
      .nullable()
      .isRequired(requiredFields?.rent_fees)
      .of(
        yup.object().shape({
          label: yup
            .string()
            .max(255)
            .required()
            .label(i18n.t('contracts:rent_fees.label')),
          amount: yup
            .number()
            .transform((cv, ov) => ov === '' ? undefined : cv)
            .integer()
            .nullable()
            .required()
            .label(i18n.t('contracts:rent_fees.amount'))
        })
      ).label(i18n.t('properties:rent_fees.name')),
    resident: yup
      .object()
      .nullable()
      .shape({
        last_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.last_name'])
          .label(i18n.t('app_users:last_name')),
        first_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.first_name'])
          .label(i18n.t('app_users:first_name')),
        furigana_last_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.furigana_last_name'])
          .label(i18n.t('app_users:furigana_last_name')),
        furigana_first_name: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.furigana_first_name'])
          .label(i18n.t('app_users:furigana_first_name')),
        app_user_manage_id: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.app_user_manage_id'])
          .label(i18n.t('app_users:app_user_manage_id')),
        mail_address: yup
          .string()
          .email()
          .nullable()
          .isRequired(requiredFields?.['resident.mail_address'])
          .label(i18n.t('common:email')),
        phone: yup
          .string()
          .nullable()
          .isRequired(requiredFields?.['resident.phone'])
          .isCellPhone()
          .max(255)
          .label(i18n.t('common:phone')),
        emergency_phone: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.emergency_phone'])
          .label(i18n.t('app_users:emergency_phone')),
        occupation: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.occupation'])
          .label(i18n.t('app_users:occupation')),
        annual_income: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .nullable()
          .isRequired(requiredFields?.['resident.annual_income'])
          .integer().nullable().label(i18n.t('app_users:annual_income')),
        birthday: yup
          .date()
          .nullable()
          .isRequired(requiredFields?.['resident.birthday'])
          .label(i18n.t('app_users:birthday')),
        sex: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .min(0).max(3)
          .integer()
          .nullable()
          .isRequired(requiredFields?.['resident.sex'])
          .label(i18n.t('app_users:sex')),
        nationality: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.nationality'])
          .label(i18n.t('app_users:nationality')),
        residence_card: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.residence_card'])
          .label(i18n.t('app_users:residence_card')),
        home_country_emergency_contact: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.home_country_emergency_contact'])
          .label(i18n.t('app_users:home_country_emergency_contact')),
        resident_relation_id: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .nullable()
          .isRequired(requiredFields?.['resident.resident_relation_id'])
          .label(i18n.t('contracts:invited_users.relation_name')),
        work: yup
          .string()
          .max(255)
          .nullable()
          .isRequired(requiredFields?.['resident.work'])
          .label(i18n.t('app_users:work.work_school'))
      })
      .label(i18n.t('contracts:resident.resident_info')),
    roommates: yup
      .array()
      .nullable()
      .of(yup
        .object()
        .nullable()
        .shape({
          last_name: yup
            .string()
            .max(255)
            .nullable()
            .isRequired(requiredFields?.['roommates.last_name'])
            .label(i18n.t('app_users:last_name')),
          first_name: yup
            .string()
            .max(255)
            .nullable()
            .isRequired(requiredFields?.['roommates.first_name'])
            .label(i18n.t('app_users:first_name')),
          furigana_last_name: yup
            .string()
            .max(255)
            .nullable()
            .isRequired(requiredFields?.['roommates.furigana_last_name'])
            .label(i18n.t('app_users:furigana_last_name')),
          furigana_first_name: yup
            .string()
            .max(255)
            .nullable()
            .isRequired(requiredFields?.['roommates.furigana_first_name'])
            .label(i18n.t('app_users:furigana_first_name')),
          birthday: yup
            .date()
            .nullable()
            .isRequired(requiredFields?.['roommates.birthday'])
            .label(i18n.t('app_users:birthday')),
          sex: yup
            .number()
            .transform((cv, ov) => ov === '' ? undefined : cv)
            .min(0).max(3)
            .integer()
            .nullable()
            .isRequired(requiredFields?.['roommates.sex'])
            .label(i18n.t('app_users:sex')),
          relation_id: yup
            .number()
            .transform((cv, ov) => ov === '' ? undefined : cv)
            .integer()
            .nullable()
            .isRequired(requiredFields?.['roommates.relation_id'])
            .label(i18n.t('contracts:invited_users.relation_name')),
          phone: yup
            .string()
            .isPhone()
            .max(255)
            .isRequired(requiredFields?.['roommates.phone'])
            .label(i18n.t('common:phone')),
          work: yup
            .string()
            .max(255)
            .nullable()
            .isRequired(requiredFields?.['roommates.work'])
            .label(i18n.t('contracts:roommates.work'))
        })
      ).label(i18n.t('contracts:roommates.info')),
    template_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .label(i18n.t('contracts:template'))
  })
}
