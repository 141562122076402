import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_GUEST,
  FETCH_GUEST_SUCCESS,
  FETCH_GUEST_FAIL,
  RESET_GUEST,
  POST_GUEST,
  POST_GUEST_SUCCESS,
  POST_GUEST_FAIL
  // DELETE_GUEST,
  // DELETE_GUEST_SUCCESS,
  // DELETE_GUEST_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_GUEST]: (state, action) => {
    state.posting = true
  },
  [POST_GUEST_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_GUEST_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_GUEST]: (state, action) => {
    state.loading = true
  },
  [FETCH_GUEST_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_GUEST_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_GUEST]: () => INITIAL_STATE
})
