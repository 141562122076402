import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import {
  DatePicker,
  Row,
  UPDATE_FORM
} from '@gk-devteam/apmc-core-web'

import { CONTRACT_STATUS } from '../../../../../constants'

import { CancelButtonStyle } from '../../ContractForm.style'

dayjs.extend(isSameOrBefore)

const MovingDates = () => {
  const dispatch = useDispatch()
  const startDate = useSelector(state => state.form.contract_start)
  const endDate = useSelector(state => state.form.contract_end)
  const moveInDate = useSelector(state => state.form.move_in)
  const status = useSelector(state => state.form.status)

  const disablePreviousDate = (current) => {
    if (current && (startDate || moveInDate)) {
      const currentDay = dayjs(current)
      const start = dayjs(startDate)
      const end = dayjs(endDate)
      const moveIn = moveInDate ? dayjs(moveInDate) : null
      const date = moveIn || start
      if (endDate) {
        return !(current.isAfter(date) && currentDay.isSameOrBefore(end, 'day'))
      }
      return current.isBefore(date)
    }
    return false
  }

  const _handleCancel = () => {
    dispatch({ type: UPDATE_FORM, payload: { move_out: null } })
  }

  const _renderCancelButton = () => {
    if (status === CONTRACT_STATUS.MOVING_OUT.value) return <CancelButtonStyle handleClick={_handleCancel} label="contracts:cancel_move_out_date" small />
    return null
  }

  return (
    <Row align="center">
      <DatePicker
        flex={1}
        size="M"
        name="move_out"
        label='contracts:move_out_date'
        placeholder=""
        disabledDate={disablePreviousDate}
      />
      {_renderCancelButton()}
    </Row>
  )
}

export default MovingDates

// ContractDatepickers.propTypes = {}
