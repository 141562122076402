import React, { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal,
  Row,
  RadioStandAlone
} from '@gk-devteam/apmc-core-web'

import {
  POST_CALENDAR, SELECT_CALENDAR
} from '../../../../types'

import AddRefuseForm from '../../../../forms/refuse/AddRefuseForm'
import SelectRefuseForm from '../../../../forms/refuse/SelectRefuseForm'

const CalendarModal = ({ visible, onCancel, id }) => {
  const dispatch = useDispatch()
  const [formMode, setFormMode] = useState(0)

  const _handleSelectSubmit = (data) => {
    const onSuccess = () => {
      onCancel()
    }
    dispatch({ type: SELECT_CALENDAR, data, id, onSuccess })
  }
  const _handlePostSubmit = (data) => {
    const onSuccess = () => {
      onCancel()
    }
    dispatch({ type: POST_CALENDAR, data, id, onSuccess })
  }

  const _renderForm = () => {
    if (formMode === 0) return <SelectRefuseForm handleSubmit={_handleSelectSubmit} />
    if (formMode === 1) return <AddRefuseForm handleSubmit={_handlePostSubmit} />
    return null
  }

  return (
    <Modal
      title={ formMode ? 'refuse:add_calendar' : 'refuse:select_calendar' }
      // formID="refuse_select"
      formID="refuse_add"
      visible={visible}
      onCancel={onCancel}
      onOk={formMode ? _handlePostSubmit : _handleSelectSubmit}
      okText={ formMode ? 'save' : 'select_short' }
      fullWidth
    >
      <Row my="R">
        <RadioStandAlone
          name="refuse_form_mode"
          value={formMode}
          handleChange={setFormMode}
          options={[
            {
              label: 'refuse:select_calendar',
              value: 0
            },
            {
              label: 'refuse:upload_calendar',
              value: 1
            }
          ]}
        />
      </Row>
      { _renderForm() }
    </Modal>
  )
}

export default memo(CalendarModal)

CalendarModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
}
