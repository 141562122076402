import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchGuideCategories,
  postGuideCategory,
  copyGuideCategory,
  deleteGuideCategory,
  toggleGuideCategory,
  bulkToggleGuideCategories
} from '../../services'
import {
  FETCH_GUIDE_CATEGORIES,
  FETCH_GUIDE_CATEGORIES_SUCCESS,
  FETCH_GUIDE_CATEGORIES_FAIL,
  POST_GUIDE_CATEGORY,
  POST_GUIDE_CATEGORY_SUCCESS,
  POST_GUIDE_CATEGORY_FAIL,
  COPY_GUIDE_CATEGORY,
  COPY_GUIDE_CATEGORY_SUCCESS,
  COPY_GUIDE_CATEGORY_FAIL,
  DELETE_GUIDE_CATEGORY,
  DELETE_GUIDE_CATEGORY_SUCCESS,
  DELETE_GUIDE_CATEGORY_FAIL,
  TOGGLE_GUIDE_CATEGORY,
  TOGGLE_GUIDE_CATEGORY_SUCCESS,
  TOGGLE_GUIDE_CATEGORY_FAIL,
  BULK_TOGGLE_GUIDE_CATEGORIES,
  BULK_TOGGLE_GUIDE_CATEGORIES_SUCCESS,
  BULK_TOGGLE_GUIDE_CATEGORIES_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchGuideCategoriesSaga ({ config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchGuideCategories, config, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_GUIDE_CATEGORIES_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_GUIDE_CATEGORIES_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_GUIDE_CATEGORIES_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postGuideCategorySaga ({ data, config, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postGuideCategory, data, config, cancelSource)
    if (res && res.data && res.data.success) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_GUIDE_CATEGORY_SUCCESS })
      yield put({ type: FETCH_GUIDE_CATEGORIES, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_GUIDE_CATEGORY_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_GUIDE_CATEGORY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

// Saga used only at property level
export function * copyGuideCategorySaga ({ propertyID, categoryID, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(copyGuideCategory, propertyID, categoryID, cancelSource)
    if (res && res.status === 204) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: COPY_GUIDE_CATEGORY_SUCCESS })
      yield put({ type: FETCH_GUIDE_CATEGORIES, config: { property: propertyID } })
      yield call(message.success, i18n.t('guides:guide_category_copy_success'))
    } else {
      yield put({ type: COPY_GUIDE_CATEGORY_FAIL })
      yield call(message.error, i18n.t('guides:guide_category_copy_error'))
    }
  } catch (error) {
    yield put({ type: COPY_GUIDE_CATEGORY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('guides:guide_category_copy_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteGuideCategorySaga ({ id, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteGuideCategory, id, config, cancelSource)
    if (res) {
      yield put({ type: DELETE_GUIDE_CATEGORY_SUCCESS })
      yield put({ type: FETCH_GUIDE_CATEGORIES, config })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_GUIDE_CATEGORY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * toggleGuideCategorySaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(toggleGuideCategory, data, config, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: TOGGLE_GUIDE_CATEGORY_SUCCESS })
      yield put({ type: FETCH_GUIDE_CATEGORIES, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: TOGGLE_GUIDE_CATEGORY_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: TOGGLE_GUIDE_CATEGORY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * bulkToggleGuideCategoriesSaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(bulkToggleGuideCategories, data, config, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: BULK_TOGGLE_GUIDE_CATEGORIES_SUCCESS })
      yield put({ type: FETCH_GUIDE_CATEGORIES, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: BULK_TOGGLE_GUIDE_CATEGORIES_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: BULK_TOGGLE_GUIDE_CATEGORIES_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchGuideCategories () {
  yield takeLatest(FETCH_GUIDE_CATEGORIES, fetchGuideCategoriesSaga)
  yield takeLatest(POST_GUIDE_CATEGORY, postGuideCategorySaga)
  yield takeLatest(COPY_GUIDE_CATEGORY, copyGuideCategorySaga)
  yield takeLatest(DELETE_GUIDE_CATEGORY, deleteGuideCategorySaga)
  yield takeLatest(TOGGLE_GUIDE_CATEGORY, toggleGuideCategorySaga)
  yield takeLatest(BULK_TOGGLE_GUIDE_CATEGORIES, bulkToggleGuideCategoriesSaga)
}
