import React, { useCallback, useEffect, useState } from 'react'
import { CancelToken } from 'axios'
import { message } from 'antd'
import {
  Card,
  RepeaterAddButton,
  RepeaterFields,
  Row,
  useTranslation,
  useIsMountedRef
} from '@gk-devteam/apmc-core-web'

import { fetchRelations } from '../../../../../services'

import RoommateRow from './RoommateRow'

function RoommatesCard () {
  const { t } = useTranslation()
  const isMountedRef = useIsMountedRef()
  const [relationOptions, setRelationOptions] = useState()
  const [loadingRelations, setLoadingRelations] = useState(true)

  const fetchRelationOptions = useCallback(
    async () => {
      try {
        setLoadingRelations(true)
        const cancelSource = CancelToken.source()
        const res = await fetchRelations(cancelSource)
        if (!isMountedRef.current) return
        if (res?.data.results) {
          setLoadingRelations(false)
          setRelationOptions(res.data.results)
        } else {
          message.error(t('fetch_error'))
          setLoadingRelations(false)
        }
      } catch (error) {
        message.error(t('fetch_error'))
        setLoadingRelations(false)
      }
    },
    [t, isMountedRef]
  )

  useEffect(() => {
    fetchRelationOptions()
  }, [fetchRelationOptions])

  return (
    <Card mb="L" title="contracts:roommates.info">
      <Row wrap="wrap" align="baseline">
        <RepeaterFields
          propertyKey="roommates"
          uniqField="furigana_first_name"
          relationOptions={relationOptions}
          loadingRelations={loadingRelations}
          field={(index) => <RoommateRow key={index} index={index} relationOptions={relationOptions} loadingRelations={loadingRelations}/>}
        />
      </Row>
      <RepeaterAddButton
        label="contracts:roommates.add"
        propertyKey="roommates"
        uniqField="furigana_first_name"
        fields={[
          'first_name',
          'last_name',
          'furigana_first_name',
          'furigana_last_name',
          'birthday',
          'sex',
          'relation_id',
          'phone',
          'work'
        ]}
        maxItems={4}
        help="contracts:help.roommates"
      />
    </Card>
  )
}

RoommatesCard.propTypes = {

}

export default RoommatesCard
