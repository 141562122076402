import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'
import { i18n } from '../../locales'

import {
  fetchChatStatus
} from '../../services'

import {
  FETCH_CHAT_STATUS,
  FETCH_CHAT_STATUS_SUCCESS,
  FETCH_CHAT_STATUS_FAIL
} from '../../types'

export function * fetchChatStatusSaga ({ params = {} }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchChatStatus, params, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_CHAT_STATUS_SUCCESS, payload: { hiddenBy: res.data.hiddenBy, hiddenById: res.data.hiddenById } })
    } else {
      yield put({ type: FETCH_CHAT_STATUS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_CHAT_STATUS_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchChat () {
  yield takeLatest(FETCH_CHAT_STATUS, fetchChatStatusSaga)
  // yield takeLatest(UPDATE_CHAT_SETTINGS, updateChatSettingsSaga)
}
