import { put, call, takeLatest, select, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchNotice,
  postNotice,
  deleteNotice,
  sendNotice,
  stopNotice
} from '../../services'
import {
  FETCH_NOTICE,
  FETCH_NOTICE_SUCCESS,
  FETCH_NOTICE_FAIL,
  POST_NOTICE,
  POST_NOTICE_SUCCESS,
  POST_NOTICE_FAIL,
  DELETE_NOTICE,
  DELETE_NOTICE_SUCCESS,
  DELETE_NOTICE_FAIL,
  FETCH_NOTICES,
  SEND_NOTICE,
  STOP_NOTICE
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchNoticeSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchNotice, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_NOTICE_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_NOTICE_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_NOTICE_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postNoticeSaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postNotice, data, cancelSource)
    if (res && res.data && res.data.success) {
      if (successRedirect) yield call(successRedirect, res.data.id)
      yield put({ type: POST_NOTICE_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_NOTICE_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_NOTICE_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteNoticeSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteNotice, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_NOTICE_SUCCESS })
      yield put({ type: FETCH_NOTICES, query })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_NOTICE_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * sendNoticeSaga ({ id }) {
  const cancelSource = CancelToken.source()
  const closeSending = message.loading(i18n.t('notices:send_notice_loading'), 0)

  try {
    const res = yield call(sendNotice, id, cancelSource)
    if (res) {
      closeSending()
      const query = yield select(getQuery)
      yield put({ type: FETCH_NOTICES, query })
      yield call(message.success, i18n.t('notices:send_notice_success'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('notices:send_notice_fail'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * stopNoticeSaga ({ id, mode }) {
  const cancelSource = CancelToken.source()
  if (mode && ['list', 'details'].includes(mode)) {
    try {
      const res = yield call(stopNotice, id, cancelSource)
      if (res && res.status === 204) {
        yield call(message.success, i18n.t('notices:stop_notice_success'))
        if (mode === 'list') {
          const query = yield select(getQuery)
          yield put({ type: FETCH_NOTICES, query })
        }
        if (mode === 'details') {
          yield put({ type: FETCH_NOTICE, id })
        }
      } else {
        yield call(message.error, i18n.t('notices:stop_notice_fail'))
      }
    } catch (error) {
      if (error && error.data && error.data.message) {
        yield call(message.error, error.data.message)
      } else {
        yield call(message.error, i18n.t('notices:stop_notice_fail'))
      }
    } finally {
      if (yield cancelled()) {
        console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
        yield call(cancelSource.cancel)
      }
    }
  }
}

export function * watchNotice () {
  yield takeLatest(FETCH_NOTICE, fetchNoticeSaga)
  yield takeLatest(POST_NOTICE, postNoticeSaga)
  yield takeLatest(DELETE_NOTICE, deleteNoticeSaga)
  yield takeLatest(SEND_NOTICE, sendNoticeSaga)
  yield takeLatest(STOP_NOTICE, stopNoticeSaga)
}
