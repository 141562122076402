export const FETCH_SURVEYS = 'fetch_surveys'
export const FETCH_SURVEYS_SUCCESS = 'fetch_surveys_success'
export const FETCH_SURVEYS_FAIL = 'fetch_surveys_fail'
export const RESET_SURVEYS = 'reset_surveys'

export const FETCH_SURVEY = 'fetch_survey'
export const FETCH_SURVEY_SUCCESS = 'fetch_survey_success'
export const FETCH_SURVEY_FAIL = 'fetch_survey_fail'
export const RESET_SURVEY = 'reset_survey'

export const FETCH_SURVEY_ANSWER_USERS = 'fetch_survey_answer_users'
export const FETCH_SURVEY_ANSWER_USERS_SUCCESS = 'fetch_survey_answer_users_success'
export const FETCH_SURVEY_ANSWER_USERS_FAIL = 'fetch_survey_answer_users_fail'
export const RESET_SURVEY_ANSWER_USERS = 'reset_survey_answer_users'

export const POST_SURVEY = 'post_survey'
export const POST_SURVEY_SUCCESS = 'post_survey_success'
export const POST_SURVEY_FAIL = 'post_survey_fail'

export const DELETE_SURVEY = 'delete_survey'
export const DELETE_SURVEY_SUCCESS = 'delete_survey_success'
export const DELETE_SURVEY_FAIL = 'delete_survey_fail'
