import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import { TOGGLE_ADD_DOCUMENT_MODAL, POST_CONTRACT_DOCUMENT } from '../../../types'
import { selectDocumentAddModalState, selectContractDocumentsPosting } from '../../../selectors'

import ContractDocumentForm from '../../../forms/contract/ContractDocumentForm'

const initialData = {
  document: null,
  label: '',
  custom_label: ''
}

const AddDocumentModal = ({ contractID }) => {
  const dispatch = useDispatch()
  const visible = useSelector(selectDocumentAddModalState)
  const posting = useSelector(selectContractDocumentsPosting)

  const _closeAddModal = () => {
    dispatch({ type: TOGGLE_ADD_DOCUMENT_MODAL })
  }
  const _saveDocument = (data) => {
    const successRedirect = () => {
      _closeAddModal()
    }
    dispatch({ type: POST_CONTRACT_DOCUMENT, contractID, data, successRedirect })
  }
  return (
    <Modal
      visible={visible}
      onCancel={_closeAddModal}
      onOk={_saveDocument}
      okText="save"
      title="contracts:add_contract_document"
      fullWidth
      formID="contractDocumentForm"
      posting={posting}
    >
      <ContractDocumentForm saveDocument={_saveDocument} data={initialData}/>
    </Modal>
  )
}

export default memo(AddDocumentModal)

AddDocumentModal.propTypes = {
  contractID: PropTypes.string.isRequired
}
