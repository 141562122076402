import {
  api,
  MULTIPART_HEADERS,
  getFileFromURL,
  DEFAULT_QUERY_LIMIT
} from '@gk-devteam/apmc-core-web'

export const fetchPropertyDocuments = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }

  return api.get('/meetings', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token
  })
}

export const postPropertyDocument = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing document')
      }
    }

    delete data.file
    delete data.file_url
    delete data.file_filename

    formData.append('data', JSON.stringify(data))
    return api.post('/meetings', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const deletePropertyDocument = (id, cancelSource) => {
  return api.delete(`/meetings/${id}`, {
    cancelToken: cancelSource.token
  })
}
