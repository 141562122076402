import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'
import {
  DELETE_CONSTRUCTION_WORK_FILE,
  DELETE_CONSTRUCTION_WORK_FILE_FAIL,
  DELETE_CONSTRUCTION_WORK_FILE_SUCCESS,
  FETCH_CONSTRUCTION_WORK,
  POST_CONSTRUCTION_WORK_FILE,
  POST_CONSTRUCTION_WORK_FILE_FAIL,
  POST_CONSTRUCTION_WORK_FILE_SUCCESS
} from '../../types'
import { deleteConstructionWorkFile, postConstructionWorkFile } from '../../services'
import { i18n } from '../../locales'

export function * postConstructionWorkFileSaga ({ constructionWorkID, data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postConstructionWorkFile, data, constructionWorkID, cancelSource)
    if (res) {
      yield put({ type: POST_CONSTRUCTION_WORK_FILE_SUCCESS })
      yield put({ type: FETCH_CONSTRUCTION_WORK, id: constructionWorkID })
      if (successRedirect) yield call(successRedirect)
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CONSTRUCTION_WORK_FILE_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CONSTRUCTION_WORK_FILE_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteConstructionWorkFileSaga ({ constructionWorkID, constructionWorkFileID }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteConstructionWorkFile, constructionWorkFileID, cancelSource)
    if (res) {
      yield put({ type: DELETE_CONSTRUCTION_WORK_FILE_SUCCESS })
      yield put({ type: FETCH_CONSTRUCTION_WORK, id: constructionWorkID })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_CONSTRUCTION_WORK_FILE_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchConstructionWorkFile () {
  yield takeLatest(POST_CONSTRUCTION_WORK_FILE, postConstructionWorkFileSaga)
  yield takeLatest(DELETE_CONSTRUCTION_WORK_FILE, deleteConstructionWorkFileSaga)
}
