import {
  styled,
  getColor,
  SPACING,
  Column,
  Card
} from '@gk-devteam/apmc-core-web'

export const CardStyle = styled(Card)`
  opacity: ${props => props.disabled ? 0.85 : 1};
  border-color: ${props => props.disabled ? getColor(props, 'cardBorder') : getColor(props, 'accent')};
`

export const TimeStyle = styled(Column)`
  width: auto;
  margin-right: ${SPACING.LARGEST}rem;
  align-items: flex-start;

  span:last-child{
    margin-top: ${SPACING.SMALLEST}rem;
    padding-left: ${SPACING.REGULAR}rem;
  }
`

export const NoticeMessageStyle = styled.p`
  line-height: 1.9;
  em {
    border-bottom: 1px solid ${props => getColor(props, 'accent')};
    font-style: normal;
  }
`
