import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { CancelToken } from 'axios'

import {
  fetchAreas
} from '../services'
import {
  FETCH_AREAS,
  FETCH_AREAS_SUCCESS,
  FETCH_AREAS_FAIL
} from '../types'
import { i18n } from '../locales'

export function * fetchAreasSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchAreas, cancelSource, query)
    if (res && res.data) {
      yield put({ type: FETCH_AREAS_SUCCESS, payload: res.data.results })
    } else {
      yield put({ type: FETCH_AREAS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_AREAS_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchAreas () {
  yield takeLatest(FETCH_AREAS, fetchAreasSaga)
}
