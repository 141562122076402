import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Text,
  useTranslation
} from '@gk-devteam/apmc-core-web'

import { AnswerLabelStyle, BarStyle, ClickableStyle } from '../SurveyForm.style'
import ResultModal from './ResultModal'

function Result ({ answer, ids, totalCount }) {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  const _openModal = () => {
    setModalVisible(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
  }

  const _renderWithBar = () => {
    const ratio = Math.round((ids.length * 100) / totalCount)
    return (
      <>
        <BarStyle ratio={ratio}/>
        <Row mt="XS" mb="M">
          <AnswerLabelStyle text={answer}/>
          <AnswerLabelStyle text={`${ratio}%`}/>
          <Text text={t('surveys:answer_count', { count: ids.length })}/>
        </Row>
      </>
    )
  }

  const _renderSimpleAnswer = () => {
    return <p>・{answer}</p>
  }

  return (
    <>
      <ResultModal
        visible={modalVisible}
        close={_closeModal}
        answer={answer}
        ids={ids}
      />
      <ClickableStyle onClick={_openModal}>
        { totalCount ? _renderWithBar() : _renderSimpleAnswer() }
      </ClickableStyle>
    </>
  )
}

Result.propTypes = {
  answer: PropTypes.string.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  totalCount: PropTypes.number
}

export default Result
