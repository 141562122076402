import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CancelToken } from 'axios'
import {
  Confirm,
  getFileFromURL,
  InfoButton,
  InfoStyleButton,
  PageLayout,
  RoleControlled,
  Row,
  Steps,
  Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import { selectDatasyncPosting } from '../../../../selectors'
import { POST_CHOUEI_BILLING } from '../../../../types'
import { customCsvParser } from '../../../../utils'
import { validateChoueiBilling } from '../../../../services'

import ConfirmPage from './ConfirmPage'
import DataUploading from './DataUploading'
import DataUploaded from './DataUploaded'

const breadcrumbs = [
  {
    label: 'navigation:chouei.billing',
    link: '/billing'
  },
  {
    label: 'chouei:billing_confirm',
    link: '/billing'
  }
]

function BillingConfirmPage ({ location, navigate }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const posting = useSelector(selectDatasyncPosting)
  const [validating, setValidating] = useState(false)
  const [steps, setSteps] = useState(null)
  const [step, setStep] = useState(0)
  const [status, setStatus] = useState('process')
  const [data, setData] = useState([])

  const parseFile = useCallback(
    async (file) => {
      try {
        const blob = await getFileFromURL(file)
        // console.log('blob', blob)
        const csv = await customCsvParser({
          file: blob,
          transformHeader: (header) => {
            switch (header) {
              case 'resident.user_manage_id':
                return 'app_user_manage_id'
              case 'resident.phone':
                return 'resident_phone'
              default:
                return header
            }
          }
        })

        // Validate data
        const cancelSource = CancelToken.source()
        setValidating(true)
        const res = await validateChoueiBilling({
          user_manage_ids: csv.map(row => row.app_user_manage_id)
        }, cancelSource)
        setValidating(false)
        if (res?.data?.user_manage_id) {
          const validated = csv
            .map(row => ({
              ...row,
              unknown_user: res.data.user_manage_id.includes(row.app_user_manage_id)
            }))
            .sort((a, b) => Number(b.unknown_user) - Number(a.unknown_user))
          setData(validated)
        }
      } catch (error) {
        console.warn('file parsing error', error)
        setValidating(false)
        navigate('/billing')
      }
    },
    [navigate]
  )

  useEffect(() => {
    const file = location?.state?.file
    if (file) {
      parseFile(file)
    } else {
      navigate('/billing')
    }
  }, [location, navigate, parseFile])

  useEffect(() => {
    const stepItems = [
      {
        title: 'chouei:billing_confirm',
        icon: validating && step === 0 ? <LoadingOutlined /> : null,
        disabled: true
      },
      {
        title: 'chouei:file_upload',
        icon: posting && step === 1 ? <LoadingOutlined /> : null,
        disabled: true
      },
      {
        title: 'navigation:import_done',
        disabled: true
      }
    ]

    setSteps(stepItems)
  }, [data, step, posting, validating])

  const _handleUpload = () => {
    const file = location?.state?.file
    if (!file) return
    if (!data) return

    // filter row where water is null && user does not exist
    const upload = data.filter(row => {
      if (!row.water || row.water === 'NULL' || row.water === '0') return false
      if (row.unknown_user) return false

      return true
    })

    setStatus('wait')
    setStep(step + 1)

    const lastStep = steps.length - 1
    const onSuccess = () => {
      setStatus('finish')
      setStep(lastStep)
    }
    const onError = () => {
      setStatus('error')
      setStep(lastStep)
    }

    dispatch({
      type: POST_CHOUEI_BILLING,
      data: upload,
      onSuccess,
      onError
    })
  }

  function _renderSteps () {
    switch (step) {
      case 0:
        return <ConfirmPage data={data} />
      case 1:
        return <DataUploading />
      case 2:
        return <DataUploaded />
      default:
        return null
    }
  }

  function _renderSubmitButton () {
    if (data.some(row => row.unknown_user)) {
      return (
        <Confirm
          title='chouei:submit_with_error'
          placement="topRight"
          onConfirm={_handleUpload}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          okText={t('register')}
        >
          <InfoStyleButton>{t('register')}</InfoStyleButton>
        </Confirm>
      )
    }

    return (
      <InfoButton
        label="register"
        handleClick={_handleUpload}
      />
    )
  }

  if (!steps) return null

  return (
    <PageLayout breadcrumbs={breadcrumbs}>
      <Row justify="between" mb="ML">
        <Title label="chouei:billing_confirm" />
        <RoleControlled authorized="custom.chouei">
          {_renderSubmitButton()}
        </RoleControlled>
      </Row>
      <Row>
        <Steps
          current={step}
          status={status}
          steps={steps}
        />
      </Row>
      { _renderSteps() }
    </PageLayout>
  )
}

BillingConfirmPage.propTypes = {
  location: PropTypes.object,
  navigate: PropTypes.func
}

export default BillingConfirmPage
