import { createSelector } from 'reselect'

const selectMembers = state => state.members

export const selectMembersLoading = createSelector(
  selectMembers,
  members => members.loading
)
export const selectMembersCount = createSelector(
  selectMembers,
  members => members.results_count
)
export const selectMembersData = createSelector(
  selectMembers,
  members => members.results
)
