import { createSelector } from 'reselect'

const selectChecklist = state => state.checklist

export const selectChecklistLoading = createSelector(
  selectChecklist,
  checklist => checklist.loading
)
export const selectChecklistLoadingSubscription = createSelector(
  selectChecklist,
  checklist => checklist.loadingSubscription
)
export const selectChecklistPosting = createSelector(
  selectChecklist,
  checklist => checklist.posting
)
export const selectChecklistHiddenBy = createSelector(
  selectChecklist,
  checklist => checklist.hiddenBy
)
export const selectChecklistHiddenById = createSelector(
  selectChecklist,
  checklist => checklist.hiddenById
)
export const selectWaitingChecklistsCount = createSelector(
  selectChecklist,
  checklist => checklist.unresolved_count
)
export const selectChecklistCategories = createSelector(
  selectChecklist,
  checklist => checklist.categories
)
export const selectChecklistCategoriesOptions = createSelector(
  selectChecklist,
  checklist => checklist.categories && checklist.categories.map(category => {
    const { id, name } = category
    return {
      label: name,
      value: id
    }
  })
)
export const selectChecklistsCount = createSelector(
  selectChecklist,
  checklist => checklist.results_count
)
export const selectChecklistsData = createSelector(
  selectChecklist,
  checklist => checklist.data
)
export const selectChecklistsStatuses = createSelector(
  selectChecklist,
  checklist => checklist.statuses
)
export const selectRealtimeChecklists = createSelector(
  selectChecklist,
  checklist => checklist.checklists
)
export const selectChecklistIssueHiddenBy = createSelector(
  selectChecklist,
  checklist => checklist?.issue?.hiddenBy
)
export const selectChecklistIssueHiddenById = createSelector(
  selectChecklist,
  checklist => checklist?.issue?.hiddenById
)

export const selectChecklistIssue = createSelector(
  selectChecklist,
  checklist => checklist.issue
)
export const selectChecklistError = createSelector(
  selectChecklist,
  checklist => checklist.error
)
export const selectChecklistIssueComments = createSelector(
  selectChecklist,
  checklist => checklist.issue && checklist.issue.comments
)
export const selectChecklistIssueLastEdited = createSelector(
  selectChecklist,
  checklist => (checklist?.issue?.comments && checklist.issue.comments[checklist.issue.comments.length - 1]?.comment_datetime) || null
)
export const selectChecklistIssueLastEditedBy = createSelector(
  selectChecklist,
  checklist => (checklist?.issue?.comments && checklist.issue.comments[checklist.issue.comments.length - 1]?.name) || null
)
