import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const userPreferenceThemeUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    file: yup.string().nullable().isBlob().label(i18n.t('common:file')),
    fonts: yup
      .object()
      .nullable()
      .shape({
        font_family_jp: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('settings:theme.fonts.font_family_jp')),
        font_url_jp: yup
          .string()
          .url()
          .nullable()
          .label(i18n.t('settings:theme.fonts.font_url_jp')),
        font_family: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('settings:theme.fonts.font_family')),
        font_url: yup
          .string()
          .url()
          .nullable()
          .label(i18n.t('settings:theme.fonts.font_url')),
        font_size: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .min(0)
          .nullable()
          .label(i18n.t('settings:theme.fonts.font_size'))
      })
      .label(i18n.t('settings:theme.fonts.label')),
    colors: yup
      .object()
      .nullable()
      .shape({
        white: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.white')),
        inputBorder: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.inputBorder')),
        cardBorder: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.cardBorder')),
        accent: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.accent')),
        info: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.info')),
        success: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.success')),
        danger: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.danger')),
        warning: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.warning')),
        text: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.text')),
        lightText: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.lightText')),
        pageTitle: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.colors.pageTitle'))
      })
      .label(i18n.t('settings:theme.fonts.label')),
    header: yup
      .object()
      .nullable()
      .shape({
        siteName: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('settings:theme.header.siteName')),
        headerTextColor: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.header.headerTextColor')),
        headerBgColor: yup
          .string()
          .isColor()
          .nullable()
          .label(i18n.t('settings:theme.header.headerBgColor')),
        delete_siteLogo: yup
          .bool()
          .nullable()
          .label(i18n.t('settings:theme.header.delete_siteLogo'))
      })
      .label(i18n.t('settings:theme.header.label'))
  })
}
