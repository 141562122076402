import { api, DEFAULT_QUERY_LIMIT } from '@gk-devteam/apmc-core-web'

export const fetchPropertiesGroups = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }
  return api.get('properties_group', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token
  })
}

export const fetchPropertiesGroup = (id, cancelSource) => {
  return api.get(`properties_group/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const postPropertiesGroup = (data, cancelSource) => {
  if (data) {
    delete data.properties_list
    return api.post('properties_group', data, {
      cancelToken: cancelSource.token
    })
  }
}

export const deletePropertiesGroup = (id, cancelSource) => {
  return api.delete(`properties_group/${id}`, {
    cancelToken: cancelSource.token
  })
}
