import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FRONT_TIMESTAMP_FORMAT,
  MainLayout,
  RESET_PARAMS, Row,
  selectQueryParams,
  Table,
  Tag,
  Text,
  Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import {
  selectPropertyName,
  selectSpaceReservationsLoading,
  selectSpaceReservationsCount,
  selectSpaceReservations,
  selectSpaceData
} from '../../../selectors'
import { spacesReservationsSearchSchema } from '../../../validation'
import { FETCH_SPACE_RESERVATIONS, RESET_SPACE_RESERVATIONS, UPDATE_SPACE_RESERVATION_STATUS } from '../../../types'
import { SPACE_RESERVATION_STATUS } from '../../../constants'

function PropertySpaceReservationsPage ({ propertyID, spaceID, location }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const data = useSelector(selectSpaceData)
  const propertyName = useSelector(selectPropertyName)
  const params = useSelector(selectQueryParams)
  const prevQuery = useRef(null)

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_spaces',
      link: `/properties/edit/${propertyID}/spaces`
    },
    {
      label: data?.name || '',
      link: `/properties/edit/${propertyID}/spaces/edit/${spaceID}`
    },
    {
      label: 'navigation:properties_spaces_reservations_list',
      link: `/properties/edit/${propertyID}/spaces/edit/${spaceID}/reservations`
    }
  ]

  const fetchData = useCallback(
    () => {
      const schema = spacesReservationsSearchSchema()
      const query = { ...params, space_id: Number(spaceID) }

      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_SPACE_RESERVATIONS, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_SPACE_RESERVATIONS, query })
          }
        })
    },
    [dispatch, params, spaceID]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = params
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, params)) {
        prevQuery.current = params
        fetchData()
      }
    }
  }, [params, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_SPACE_RESERVATIONS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const statusCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      let label, color
      switch (value) {
        case SPACE_RESERVATION_STATUS.UNCONFIRMED:
          label = 'spaces:reservation_statuses.unconfirmed'
          color = 'text'
          break
        case SPACE_RESERVATION_STATUS.CONFIRMED:
          label = 'spaces:reservation_statuses.confirmed'
          color = 'success'
          break
        case SPACE_RESERVATION_STATUS.CANCELED:
          label = 'spaces:reservation_statuses.canceled'
          color = 'warning'
          break
        case SPACE_RESERVATION_STATUS.REJECTED:
          label = 'spaces:reservation_statuses.rejected'
          color = 'danger'
          break
        default:
          break
      }

      return <Tag label={label} color={color} />
    },
    []
  )
  const durationCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const { reservation_from, reservation_to } = data

      return <Text text={`${dayjs(reservation_from).format(FRONT_TIMESTAMP_FORMAT)} - ${dayjs(reservation_to).format(FRONT_TIMESTAMP_FORMAT)}`} />
    },
    []
  )

  const _canUpdateStatus = useCallback(
    (data) => {
      return data.status === SPACE_RESERVATION_STATUS.UNCONFIRMED
    },
    []
  )
  const _canResetStatus = useCallback(
    (data) => {
      return [SPACE_RESERVATION_STATUS.CONFIRMED, SPACE_RESERVATION_STATUS.REJECTED].includes(data.status)
    },
    []
  )

  const successRedirect = () => {
    fetchData()
  }

  const columns = [
    {
      title: t('app_users:app_user_name'),
      dataIndex: 'app_user_name',
      key: 'app_user_name',
      cellTitle: true
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number'
    },
    {
      title: t('spaces:reservation_status'),
      dataIndex: 'status',
      key: 'status',
      render: statusCell
    },
    {
      title: t('spaces:reserved_duration'),
      dataIndex: 'reservation_from',
      key: 'reservation_from',
      render: durationCell
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('spaces:reservation_statuses.unconfirmed'),
          dangerColor: true,
          onClick: (reservation_id, close) => {
            dispatch({ type: UPDATE_SPACE_RESERVATION_STATUS, id: spaceID, data: { reservation_id: 1, status: SPACE_RESERVATION_STATUS.UNCONFIRMED }, successRedirect }) // TODO: remove hard coded id
            close()
          },
          shouldRender: _canResetStatus
        },
        {
          label: t('spaces:reservation_statuses.confirmed'),
          onClick: (reservation_id, close) => {
            dispatch({ type: UPDATE_SPACE_RESERVATION_STATUS, id: spaceID, data: { reservation_id: 1, status: SPACE_RESERVATION_STATUS.CONFIRMED }, successRedirect }) // TODO: remove hard coded id
            close()
          },
          shouldRender: _canUpdateStatus
        },
        {
          label: t('spaces:reservation_statuses.rejected'),
          dangerColor: true,
          onClick: (reservation_id, close, row) => {
            dispatch({ type: UPDATE_SPACE_RESERVATION_STATUS, id: spaceID, data: { reservation_id: 1, status: SPACE_RESERVATION_STATUS.REJECTED }, successRedirect }) // TODO: remove hard coded id
            close()
          },
          shouldRender: _canUpdateStatus
        }
      ]
    }
  ]

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
    >
      <Row justify="between" align="center" mt="R" wrap={'wrap'}>
        <Title label="navigation:properties_spaces_reservations_list"/>
      </Row>
      <Table
        fullHeight
        columns={columns}
        rowKey="space_id"
        loadingSelector={selectSpaceReservationsLoading}
        resultsCountSelector={selectSpaceReservationsCount}
        dataSelector={selectSpaceReservations}
        rightStickyColumnCount={0}
      />
    </MainLayout>
  )
}

PropertySpaceReservationsPage.propTypes = {
  location: PropTypes.object,
  propertyID: PropTypes.string.isRequired,
  spaceID: PropTypes.string.isRequired
}

export default PropertySpaceReservationsPage
