import { createSelector } from 'reselect'
import { map } from 'lodash'
import { i18n } from '../locales'

const selectAreas = state => state.areas

export const selectAreasLoading = createSelector(
  selectAreas,
  areas => areas.loading
)
export const selectAreasData = createSelector(
  selectAreas,
  areas => areas.data
)
export const selectAreasTree = createSelector(
  selectAreas,
  areas => {
    const tree = []
    for (const prefectureKey in areas.data) {
      const prefecture = areas.data[prefectureKey]
      const prefectureTree = {
        title: prefectureKey,
        key: `pref_${prefectureKey}`,
        children: []
      }
      if (prefecture.cities) {
        for (const cityKey in prefecture.cities) {
          const city = prefecture.cities[cityKey]
          const cityTree = {
            title: cityKey,
            key: `city_${cityKey}`,
            children: []
          }
          if (city.areas) {
            for (const areaKey in city.areas) {
              const area = city.areas[areaKey]
              const areaTree = {
                title: area.area || i18n.t('no_area_name'),
                key: Number(areaKey)
              }
              cityTree.children.push(areaTree)
            }
          }

          prefectureTree.children.push(cityTree)
        }
      }

      tree.push(prefectureTree)
    }
    return tree
  }
)

export const selectPrefecturesOptions = createSelector(
  selectAreas,
  areas => {
    if (!areas.data) return []
    return map(areas.data, (value, key) => {
      return {
        label: key,
        value: key
      }
    })
  }
)

export const selectCitiesOptions = createSelector(
  selectAreas,
  areas => {
    if (!areas.data) return []
    const cities = {}
    for (const key in areas.data) {
      const prefecture = areas.data[key]
      cities[key] = map(prefecture.cities, (value, cityKey) => {
        return {
          label: cityKey,
          value: cityKey
        }
      })
    }
    return cities
  }
)
