export const FETCH_REFUSE_CALENDAR = 'fetch_refuse_calendar'
export const FETCH_REFUSE_CALENDAR_SUCCESS = 'fetch_refuse_calendar_success'
export const FETCH_REFUSE_CALENDAR_FAIL = 'fetch_refuse_calendar_fail'

export const FETCH_REFUSE_CALENDARS = 'fetch_refuse_calendars'
export const FETCH_REFUSE_CALENDARS_SUCCESS = 'fetch_refuse_calendars_success'
export const FETCH_REFUSE_CALENDARS_FAIL = 'fetch_refuse_calendars_fail'

export const SELECT_CALENDAR = 'select_calendar'
export const SELECT_CALENDAR_SUCCESS = 'select_calendar_success'
export const SELECT_CALENDAR_FAIL = 'select_calendar_fail'

export const POST_CALENDAR = 'post_calendar'
export const POST_CALENDAR_SUCCESS = 'post_calendar_success'
export const POST_CALENDAR_FAIL = 'post_calendar_fail'

export const REPORT_CALENDAR = 'report_calendar'
export const REPORT_CALENDAR_SUCCESS = 'report_calendar_success'
export const REPORT_CALENDAR_FAIL = 'report_calendar_fail'

export const DELETE_CALENDAR = 'delete_calendar'
export const DELETE_CALENDAR_SUCCESS = 'delete_calendar_success'
export const DELETE_CALENDAR_FAIL = 'delete_calendar_fail'

export const RESET_REFUSE_CALENDAR = 'reset_refuse_calendar'
export const RESET_REFUSE_CALENDARS = 'reset_refuse_calendars'
