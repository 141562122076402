import { Image, SPACING, styled, Tag } from "@gk-devteam/apmc-core-web"

export const ImageStyle = styled(Image)`
  margin-right: ${SPACING.SMALL}rem;
`

export const TagStyle = styled(Tag)`
  margin-left: ${SPACING.SMALL}rem;
`

export const PreviewStyle = styled.div`
  .react-pdf__Page__canvas {
    margin: auto;
  }
`
