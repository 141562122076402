import React, { memo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Modal,
  TextInputStandAlone
} from '@gk-devteam/apmc-core-web'

import { selectLoadingConstructionWorks } from '../../../selectors'
import { DELETE_CONSTRUCTION_WORK } from '../../../types'

import { DangerMessageStyle, ConstructionWorkStyle, DeleteMessageStyle } from './ConstructionWorksPage.style'

const DeleteModal = ({ visible, onCancel, constructionWorkID }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loading = useSelector(selectLoadingConstructionWorks)
  const [constructionWorkIDInput, setConstructionWorkIDInput] = useState('')
  const [okDisabled, setOkDisabled] = useState(true)

  useEffect(() => {
    return () => {
      if (visible === false) {
        setConstructionWorkIDInput('')
        setOkDisabled(true)
      }
    }
  }, [visible])

  useEffect(() => {
    if (constructionWorkID && constructionWorkIDInput && Number(constructionWorkIDInput) === constructionWorkID) {
      setOkDisabled(false)
    } else {
      setOkDisabled(true)
    }
  }, [constructionWorkIDInput, constructionWorkID])

  const _handleInputChange = (e) => {
    setConstructionWorkIDInput(e.target.value)
  }

  const _deleteConstructionWork = () => {
    if (constructionWorkID) {
      if (constructionWorkIDInput && Number(constructionWorkIDInput) === constructionWorkID) {
        dispatch({ type: DELETE_CONSTRUCTION_WORK, id: constructionWorkID, onSuccess: onCancel, onFail: onCancel })
      } else {
        message.error(t('yasue:construction.delete_construction_work.name_no_match'))
      }
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={_deleteConstructionWork}
      okText="delete"
      okDisabled={okDisabled}
      okDanger
      title={t('yasue:construction.delete_construction_work.modal_title', { constructionWorkID })}
      posting={loading}
    >
      <DangerMessageStyle>{t('yasue:construction.delete_construction_work.warning_message')}</DangerMessageStyle>
      <DeleteMessageStyle>
        <span>{t('yasue:construction.delete_construction_work.description_1')}</span>
        <ConstructionWorkStyle>{constructionWorkID}</ConstructionWorkStyle>
        <span>{t('yasue:construction.delete_construction_work.description_2')}</span>
      </DeleteMessageStyle>

      <TextInputStandAlone
        name="constructionWorkID"
        handleChange={_handleInputChange}
        value={constructionWorkIDInput}
      />
    </Modal>
  )
}

export default memo(DeleteModal)

DeleteModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  constructionWorkID: PropTypes.number
}
