import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  useTranslation
} from '@gk-devteam/apmc-core-web'

const TabHeader = ({ header }) => {
  const { t } = useTranslation()
  return (
    <div>{ t(header) }</div>
  )
}

export default memo(TabHeader)

TabHeader.propTypes = {
  header: PropTypes.string.isRequired
}
