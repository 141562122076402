import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const propertyEventSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    property_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .required()
      .label(i18n.t('properties:property_id')),
    title: yup
      .string()
      .nullable()
      .required()
      .label(i18n.t('properties:events.title')),
    content: yup
      .string()
      .nullable()
      .label(i18n.t('properties:events.content')),
    start_time: yup
      .string()
      .nullable()
      .required()
      .label(i18n.t('properties:events.event_date')),
    end_time: yup
      .string()
      .nullable()
      .required()
      .label(i18n.t('properties:events.event_date')),
    file: yup
      .string()
      .nullable()
      .isBlob()
      .label(i18n.t('file'))
  })
}
