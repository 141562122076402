import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { TOGGLE_ARRAY_ITEM } from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { RecipientStyle } from '../AppUsersDownloadLoginPdfForm.style'

const RecipientsSelectedItem = ({ id, label }) => {
  const dispatch = useDispatch()
  const _handleUserClick = () => {
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [
        {
          key: 'recipients_list',
          value: { id, label },
          objectComparatorKey: 'id'
        },
        {
          key: 'recipients_id',
          value: id
        }]
    })
  }
  return (
    <RecipientStyle
      handleClick={_handleUserClick}
      key={id}
      label={label ? label.replace('.', '') : ''}
    />
  )
}

export default memo(RecipientsSelectedItem)

RecipientsSelectedItem.propTypes = {
  label: PropTypes.string,
  id: PropTypes.number
}
