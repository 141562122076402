import commonJa from './ja/common.json'
import loginJa from './ja/pageLogin.json'
import homeJa from './ja/pageHome.json'
import propertiesJa from './ja/pageProperties.json'
import contractsJa from './ja/pageContracts.json'
import pageAppUsersJa from './ja/pageAppUsers.json'
import roomsJa from './ja/pageRooms.json'
import noticesJa from './ja/pageNotices.json'
import navigationJa from './ja/navigation.json'
import validationJa from './ja/validation.json'
import notFoundJa from './ja/pageNotFound.json'
import internetExplorerJa from './ja/pageInternetExplorer.json'
import maintenanceJa from './ja/maintenance.json'
import settingJa from './ja/pageSettings.json'
import dashboardJa from './ja/dashboard.json'
import faqJa from './ja/pageFaq.json'
import manualsJa from './ja/pageManuals.json'
import vicinitiesJa from './ja/pageVicinities.json'
import publicFacilitiesJa from './ja/pagePublicFacilities.json'
import guidesJa from './ja/pageGuides.json'
import servicesJa from './ja/pageServices.json'
import contactsJa from './ja/pageContact.json'
import refuseJa from './ja/pageRefuse.json'
import adminTasksJa from './ja/pageAdminTasks.json'
import importJa from './ja/pageImport.json'
import userManagementJa from './ja/pageUserManagement.json'
import choueiJa from './ja/chouei.json'
import osakiJa from './ja/osaki.json'
import inuiJa from './ja/inui.json'
import couponsJa from './ja/pageCoupons.json'
import opeloJa from './ja/opelo.json'
import datasyncJa from './ja/pageDatasync.json'
import refundsJa from './ja/pageRefunds.json'
import inspectionsJa from './ja/pageInspections.json'
import inquiriesJa from './ja/pageInquiries.json'
import surveysJa from './ja/pageSurveys.json'
import spacesJa from './ja/pageSpaces.json'
import supportJa from './ja/pageSupport.json'
import yasueJa from './ja/yasue.json'

import commonEn from './en/common.json'
import loginEn from './en/pageLogin.json'
import homeEn from './en/pageHome.json'
import propertiesEn from './en/pageProperties.json'
import contractsEn from './en/pageContracts.json'
import pageAppUsersEn from './en/pageAppUsers.json'
import roomsEn from './en/pageRooms.json'
import noticesEn from './en/pageNotices.json'
import navigationEn from './en/navigation.json'
import validationEn from './en/validation.json'
import notFoundEn from './en/pageNotFound.json'
import internetExplorerEn from './en/pageInternetExplorer.json'
import maintenanceEn from './en/maintenance.json'
import settingEn from './en/pageSettings.json'
import dashboardEn from './en/dashboard.json'
import faqEn from './en/pageFaq.json'
import manualsEn from './en/pageManuals.json'
import vicinitiesEn from './en/pageVicinities.json'
import publicFacilitiesEn from './en/pagePublicFacilities.json'
import guidesEn from './en/pageGuides.json'
import servicesEn from './en/pageServices.json'
import contactsEn from './en/pageContact.json'
import refuseEn from './en/pageRefuse.json'
import adminTasksEn from './en/pageAdminTasks.json'
import importEn from './en/pageImport.json'
import userManagementEn from './en/pageUserManagement.json'
import choueiEn from './en/chouei.json'
import osakiEn from './en/osaki.json'
import inuiEn from './en/inui.json'
import couponsEn from './en/pageCoupons.json'
import opeloEn from './en/opelo.json'
import datasyncEn from './en/pageDatasync.json'
import refundsEn from './en/pageRefunds.json'
import inspectionsEn from './en/pageInspections.json'
import inquiriesEn from './en/pageInquiries.json'
import surveysEn from './en/pageSurveys.json'
import spacesEn from './en/pageSpaces.json'
import supportEn from './en/pageSupport.json'
import yasueEn from './en/yasue.json'

import commonArr from './house_maker/common.json'
import dashboardArr from './house_maker/dashboard.json'
import navigationArr from './house_maker/navigation.json'
import pageAppUsersArr from './house_maker/pageAppUsers.json'
import contractsArr from './house_maker/pageContracts.json'
import servicesArr from './house_maker/pageServices.json'
import importArr from './house_maker/pageImport.json'
import noticesArr from './house_maker/pageNotices.json'
import propertiesArr from './house_maker/pageProperties.json'
import roomsArr from './house_maker/pageRooms.json'
import settingArr from './house_maker/pageSettings.json'
import userManagementArr from './house_maker/pageUserManagement.json'
import inspectionsArr from './house_maker/pageInspections.json'

const commonSettings = {
  resources: {
    house_maker: {
      common: commonArr,
      dashboard: dashboardArr,
      navigation: navigationArr,
      app_users: pageAppUsersArr,
      contracts: contractsArr,
      services: servicesArr,
      import: importArr,
      notices: noticesArr,
      properties: propertiesArr,
      rooms: roomsArr,
      setting: settingArr,
      user_management: userManagementArr,
      inspections: inspectionsArr
    },
    ja: {
      common: commonJa,
      login: loginJa,
      home: homeJa,
      properties: propertiesJa,
      contracts: contractsJa,
      rooms: roomsJa,
      app_users: pageAppUsersJa,
      notices: noticesJa,
      navigation: navigationJa,
      validation: validationJa,
      not_found: notFoundJa,
      internet_explorer: internetExplorerJa,
      maintenance: maintenanceJa,
      settings: settingJa,
      dashboard: dashboardJa,
      faq: faqJa,
      manuals: manualsJa,
      vicinities: vicinitiesJa,
      publicFacilities: publicFacilitiesJa,
      guides: guidesJa,
      services: servicesJa,
      contacts: contactsJa,
      refuse: refuseJa,
      admin_tasks: adminTasksJa,
      import: importJa,
      user_management: userManagementJa,
      chouei: choueiJa,
      osaki: osakiJa,
      inui: inuiJa,
      coupons: couponsJa,
      opelo: opeloJa,
      datasync: datasyncJa,
      refunds: refundsJa,
      inspections: inspectionsJa,
      inquiries: inquiriesJa,
      surveys: surveysJa,
      spaces: spacesJa,
      support: supportJa,
      yasue: yasueJa
    },
    en: {
      common: commonEn,
      login: loginEn,
      home: homeEn,
      properties: propertiesEn,
      contracts: contractsEn,
      rooms: roomsEn,
      app_users: pageAppUsersEn,
      notices: noticesEn,
      navigation: navigationEn,
      validation: validationEn,
      not_found: notFoundEn,
      internet_explorer: internetExplorerEn,
      maintenance: maintenanceEn,
      settings: settingEn,
      dashboard: dashboardEn,
      faq: faqEn,
      manuals: manualsEn,
      vicinities: vicinitiesEn,
      publicFacilities: publicFacilitiesEn,
      guides: guidesEn,
      services: servicesEn,
      contacts: contactsEn,
      refuse: refuseEn,
      admin_tasks: adminTasksEn,
      import: importEn,
      user_management: userManagementEn,
      chouei: choueiEn,
      osaki: osakiEn,
      inui: inuiEn,
      coupons: couponsEn,
      opelo: opeloEn,
      datasync: datasyncEn,
      refunds: refundsEn,
      inspections: inspectionsEn,
      inquiries: inquiriesEn,
      surveys: surveysEn,
      spaces: spacesEn,
      support: supportEn,
      yasue: yasueEn
    }
  },
  ns: [
    'common',
    'login',
    'home',
    'properties',
    'contracts',
    'rooms',
    'app_users',
    'navigation',
    'validation',
    'not_found',
    'internet_explorer',
    'maintenance',
    'dashboard',
    'faq',
    'manuals',
    'vicinities',
    'publicFacilities',
    'guides',
    'services',
    'seikatsu',
    'contacts',
    'refuse',
    'admin_tasks',
    'import',
    'user_management',
    'chouei',
    'osaki',
    'inui',
    'coupons',
    'opelo',
    'datasync',
    'inspections',
    'inquiries',
    'surveys',
    'spaces',
    'support',
    'yasue'
  ]
}

export default commonSettings
