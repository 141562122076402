import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CancelToken } from 'axios'
import { RoleControlled, Select, selectPages, UPDATE_FORM, useIsMountedRef } from '@gk-devteam/apmc-core-web'

import { selectContractID } from '../../../../../selectors'

import { fetchRooms } from '../../../../../services'
import { ContractReadOnlyLink } from '../../ContractForm.style'

const ContractRoom = () => {
  const [loading, setLoading] = useState(false)
  const [rooms, setRooms] = useState(null)
  const isMountedRef = useIsMountedRef()
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)
  const contractID = useSelector(selectContractID)
  const propertyID = useSelector(state => state.form && state.form.property_id)
  const roomID = useSelector(state => state.form && state.form.room_id)
  const roomNumber = useSelector(state => state.form && state.form.room_number)

  useEffect(() => {
    if (propertyID) {
      // Get all rooms of this properties
      setLoading(true)
      const fetchData = async () => {
        try {
          const cancelSource = CancelToken.source()
          const res = await fetchRooms({ limit: 1000, available: true }, propertyID, cancelSource)
          if (!isMountedRef.current) return
          if (res && res.data) {
            const roomsOptions = res.data.results.map(room => {
              const { id, room_number, available } = room
              return {
                label: room_number,
                value: id,
                disabled: !available
              }
            })
            setRooms(roomsOptions)
          }
          setLoading(false)
        } catch (error) {
          console.warning('error', error)
          setLoading(false)
        }
      }
      fetchData()
    }
    return () => {
      // reset data
      setLoading(false)
      setRooms([])
    }
  }, [propertyID, isMountedRef])

  useEffect(() => {
    if (pages?.custom?.house_maker && rooms?.length > 0) {
      dispatch({ type: UPDATE_FORM, payload: { room_id: rooms[0].value } })
    }
  }, [pages, rooms, dispatch])

  if (contractID) {
    return (
      <RoleControlled authorized='!custom.house_maker'>
        <ContractReadOnlyLink
          label="properties:room_number"
          text={roomNumber}
          to={`/properties/edit/${propertyID}/rooms/edit/${roomID}`}
        />
      </RoleControlled>
    )
  }

  return (
    <RoleControlled authorized='!custom.house_maker'>
      <Select
        flex={1}
        size="M"
        type="text"
        name="room_id"
        label='properties:room_number'
        placeholder=""
        options={rooms}
        loading={loading}
        required
        disabled={!propertyID}
        noClear
      />
    </RoleControlled>
  )
}

export default ContractRoom

// ContractRoom.propTypes = {}
