import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  MainLayout
} from '@gk-devteam/apmc-core-web'

import { FETCH_PROPERTIES_GROUP_SUCCESS, RESET_PROPERTIES_GROUP } from '../../../types'
import { fetchPropertiesGroup } from '../../../services'

import PropertiesGroupForm from '../../../forms/userManagement/PropertiesGroupForm'

const breadcrumbs = [
  {
    label: 'navigation:management',
    link: '/user_management'
  },
  {
    label: 'user_management:add_properties_group',
    link: '/user_management/properties_groups/add'
  }
]

const initialData = {
  name: null,
  manage_id: null,
  description: null,
  properties: null
}

const PropertiesGroupsAddPage = ({ location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(null)

  const prepareData = useCallback(
    async (data) => {
      if (data && data.id) {
        const editedData = { ...data }
        delete editedData.id
        delete editedData.modified
        delete editedData.modifiedBy
        setFormData(editedData)
      }
    },
    []
  )

  const fetchData = useCallback(
    async () => {
      const cancelSource = CancelToken.source()
      if (location && location.state && location.state.copy) {
        setLoading(true)
        try {
          const res = await fetchPropertiesGroup(location.state.id, cancelSource)
          setLoading(false)
          if (res && res.data) {
            prepareData(res.data)
            dispatch({ type: FETCH_PROPERTIES_GROUP_SUCCESS, payload: null })
          } else {
            message.error(t('fetch_error'))
          }
        } catch (error) {
          setLoading(false)
          message.error(t('fetch_error'))
        }
      } else {
        setFormData(initialData)
        dispatch({ type: FETCH_PROPERTIES_GROUP_SUCCESS, payload: null })
      }
    },
    [location, dispatch, prepareData, t]
  )

  useEffect(() => {
    fetchData()
    return () => {
      dispatch({ type: RESET_PROPERTIES_GROUP })
    }
  }, [location, dispatch, fetchData])

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      {
        formData
          ? <PropertiesGroupForm data={formData}/>
          : null
      }
    </MainLayout>
  )
}
export default PropertiesGroupsAddPage

PropertiesGroupsAddPage.propTypes = {
  location: PropTypes.object
}
PropertiesGroupsAddPage.defaultProps = {

}
