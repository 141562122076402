import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Card
} from '@gk-devteam/apmc-core-web'

import CouponRecipientType from './CouponRecipientType'
import CouponRecipientsSearch from './CouponRecipientsSearch'
import CouponRecipientsList from './CouponRecipientsList'

const CouponRecipientsSettings = () => {
  return (
    <Card title='notices:recipients_settings' mb="L">
      <CouponRecipientType/>
      <CouponRecipientsSearch/>
      <CouponRecipientsList/>
    </Card>
  )
}

export default memo(CouponRecipientsSettings)

CouponRecipientsSettings.propTypes = {
  editable: PropTypes.bool
}
