import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_GUIDE_CATEGORIES,
  FETCH_GUIDE_CATEGORIES_SUCCESS,
  FETCH_GUIDE_CATEGORIES_FAIL,
  POST_GUIDE_CATEGORY,
  POST_GUIDE_CATEGORY_SUCCESS,
  POST_GUIDE_CATEGORY_FAIL,
  COPY_GUIDE_CATEGORY,
  // COPY_GUIDE_CATEGORY_SUCCESS,
  // COPY_GUIDE_CATEGORY_FAIL,
  DELETE_GUIDE_CATEGORY,
  // DELETE_GUIDE_CATEGORY_SUCCESS,
  DELETE_GUIDE_CATEGORY_FAIL,
  TOGGLE_GUIDE_CATEGORY,
  TOGGLE_GUIDE_CATEGORY_FAIL,
  BULK_TOGGLE_GUIDE_CATEGORIES,
  BULK_TOGGLE_GUIDE_CATEGORIES_FAIL,
  RESET_GUIDE_CATEGORIES
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_GUIDE_CATEGORIES]: (state, action) => {
    state.loading = true
  },
  [FETCH_GUIDE_CATEGORIES_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_GUIDE_CATEGORIES_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_GUIDE_CATEGORY]: (state, action) => {
    state.posting = true
  },
  [POST_GUIDE_CATEGORY_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_GUIDE_CATEGORY_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [COPY_GUIDE_CATEGORY]: (state, action) => {
    state.loading = true
  },
  [DELETE_GUIDE_CATEGORY]: (state, action) => {
    state.loading = true
  },
  [DELETE_GUIDE_CATEGORY_FAIL]: (state, action) => {
    state.loading = false
  },
  [TOGGLE_GUIDE_CATEGORY]: (state, action) => {
    state.loading = true
  },
  [TOGGLE_GUIDE_CATEGORY_FAIL]: (state, action) => {
    state.loading = false
  },
  [BULK_TOGGLE_GUIDE_CATEGORIES]: (state, action) => {
    state.loading = true
  },
  [BULK_TOGGLE_GUIDE_CATEGORIES_FAIL]: (state, action) => {
    state.loading = false
  },
  [RESET_GUIDE_CATEGORIES]: () => INITIAL_STATE

})
