/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const contractUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    id: yup
      .number()
      .integer()
      .label(i18n.t('id')),
    property_id: yup
      .number()
      .integer()
      .required()
      .label(i18n.t('properties:property_id')),
    room_id: yup
      .number()
      .integer()
      .required()
      .label(i18n.t('rooms:room_id')),
    contract_manage_id: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:contract_manage_id')),
    contract_sign_date: yup
      .date()
      .nullable()
      .label(i18n.t('contracts:contract_sign_date')),
    contract_duration: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:contract_duration')),
    contract_duration_unit: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:contract_duration_unit')),
    contract_start: yup
      .date()
      .required()
      .label(i18n.t('contracts:contract_start_date')),
    contract_end: yup
      .date()
      .nullable()
      .when('move_out', {
        is: val => val != null,
        then: yup.date().required(i18n.t('contracts:contract_end_date_required'))
      })
      .label(i18n.t('contracts:contract_end_date')),
    move_in: yup
      .date()
      .required()
      .label(i18n.t('contracts:move_in_date')),
    about_contract: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:about_contract')),
    landlord_name: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:landlord_name')),
    landlord_address: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:landlord_address')),
    custom_fields: yup
      .array()
      .nullable()
      .of(
        yup.object().shape({
          label: yup
            .string()
            .max(255)
            .required()
            .label(i18n.t('contracts:custom_fields.label')),
          value: yup
            .string()
            .max(255)
            .required()
            .label(i18n.t('contracts:custom_fields.value'))
        })
      )
      .label(i18n.t('properties:custom_fields.name')),
    car_model_1: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:car_model_1')),
    car_plate_number_1: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:car_plate_number_1')),
    car_model_2: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:car_model_2')),
    car_plate_number_2: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:car_plate_number_2')),
    wifi_ssid: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:wifi_ssid')),
    wifi_password: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:wifi_password')),
    rent_date: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:rent_date')),
    rent_contract_account: yup
      .string()
      .max(255)
      .nullable()
      .label(i18n.t('contracts:rent_contract_account')),
    rent_amount: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:rent_amount')),
    rent_common_fees: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:rent_fees.common_fees')),
    rent_fees: yup
      .array()
      .nullable()
      .of(
        yup.object().shape({
          label: yup
            .string()
            .max(255)
            .nullable()
            .required()
            .label(i18n.t('contracts:rent_fees.label')),
          amount: yup
            .number()
            .transform((cv, ov) => ov === '' ? undefined : cv)
            .integer()
            .nullable()
            .required()
            .label(i18n.t('contracts:rent_fees.amount'))
        })
      ).label(i18n.t('properties:rent_fees.name')),
    landlord_fee: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:landlord_fee')),
    caution_money: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:caution_money')),
    deposit: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:deposit')),
    administrative_fees: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:administrative_fees')),
    renewal_period_start: yup
      .date()
      .nullable()
      .label(i18n.t('contracts:renewal_period_start')),
    renewal_period_end: yup
      .date()
      .nullable()
      .label(i18n.t('contracts:renewal_period_end')),
    auto_renewal: yup
      .bool()
      .label(i18n.t('contracts:auto_renewal')),
    renewal_fees: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:renewal_fees')),
    renewal_administrative_fees: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('contracts:renewal_administrative_fees')),
    renewal_notice: yup
      .array()
      .nullable()
      .of(yup
        .object()
        .nullable()
        .shape({
          enabled: yup
            .boolean()
            .required()
            .label(i18n.t('contracts:notice_destination_enabled')),
          destination_name: yup
            .string()
            .max(255)
            .nullable()
            .required()
            .label(i18n.t('contracts:renewal_notice_destination_name')),
          destination_email: yup
            .string()
            .email()
            .nullable()
            .required()
            .label(i18n.t('contracts:renewal_notice_destination_email'))
        })
      )
      .label(i18n.t('contracts:renewal_notice')),
    move_out: yup
      .date()
      .nullable()
      .label(i18n.t('contracts:move_out_date')),
    cancel_notice: yup
      .array()
      .nullable()
      .of(yup
        .object()
        .nullable()
        .shape({
          enabled: yup
            .boolean()
            .required()
            .label(i18n.t('contracts:notice_destination_enabled')),
          destination_name: yup
            .string()
            .max(255)
            .nullable()
            .required()
            .label(i18n.t('contracts:cancel_notice_destination_name')),
          destination_email: yup
            .string()
            .email()
            .nullable()
            .required()
            .label(i18n.t('contracts:cancel_notice_destination_email'))
        })
      )
      .label(i18n.t('contracts:cancel_notice')),
    different_resident: yup
      .boolean()
      .required()
      .label(i18n.t('contracts:different_resident')),
    contractor_user_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .test(
        'either-is-required',
        i18n.t('either_required', { label: '${label}', requiredField: i18n.t('contracts:contractor.contractor_info') }),
        function (value) {
          const { contractor } = this.parent
          if (!value && !contractor) return false
          if (!value && !contractor.last_name) return false
          return true
        })
      .label(i18n.t('contracts:contractor_user_id')),
    contractor: yup
      .object()
      .nullable()
      .shape({
        last_name: yup
          .string()
          .max(255)
          .nullable()
          .test(
            'conditional_required',
            i18n.t('required', { label: '${label}' }),
            function (value) {
              const { contractor_user_id } = this.from[1].value
              if (!value && !contractor_user_id) return false
              return true
            })
          .label(i18n.t('app_users:last_name')),
        first_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:first_name')),
        furigana_last_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:furigana_last_name')),
        furigana_first_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:furigana_first_name')),
        app_user_manage_id: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:app_user_manage_id')),
        mail_address: yup
          .string()
          .email()
          .nullable()
          .label(i18n.t('common:email')),
        phone: yup
          .string()
          .nullable()
          .isCellPhone()
          .label(i18n.t('common:phone')),
        emergency_phone: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:emergency_phone')),
        postcode: yup
          .string()
          .nullable()
          .matches(/^\d{7}$/, { excludeEmptyString: true })
          .label(i18n.t('properties:postcode')),
        prefecture: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('properties:prefecture')),
        city: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('properties:city')),
        address: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('common:address')),
        occupation: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:occupation')),
        annual_income: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .nullable()
          .integer()
          .nullable()
          .label(i18n.t('app_users:annual_income')),
        birthday: yup
          .date()
          .nullable()
          .label(i18n.t('app_users:birthday')),
        sex: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .min(0).max(3)
          .integer()
          .nullable()
          .label(i18n.t('app_users:sex')),
        nationality: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:nationality')),
        residence_card: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:residence_card')),
        home_country_emergency_contact: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:home_country_emergency_contact')),
        work: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:work.name')),
        work_department: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:work.position')),
        work_phone: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:work.phone')),
        work_postcode: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:work.postcode')),
        work_prefecture: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('properties:prefecture')),
        work_city: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('properties:city')),
        work_address: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:work.address'))
      })
      .label(i18n.t('contracts:contractor.contractor_info')),
    resident_user_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .test(
        'is-resident-required',
        i18n.t('either_required', { label: '${label}', requiredField: i18n.t('contracts:resident.resident_info') }),
        function (value) {
          const { resident, different_resident } = this.parent
          if (!value && different_resident && (!resident || !resident.last_name || resident.last_name === '')) return false
          return true
        })
      .label(i18n.t('contracts:resident_user_id')),
    resident: yup
      .object()
      .nullable()
      .test(
        'is-resident-required',
        i18n.t('either_required', { label: '${label}', requiredField: i18n.t('contracts:resident.resident_info') }),
        function (value) {
          const { resident, different_resident } = this.parent
          if (!value && different_resident && (!resident || resident.last_name === '')) return false
          return true
        })
      .shape({
        last_name: yup
          .string()
          .max(255)
          .nullable()
          .test(
            'conditional_required',
            i18n.t('required', { label: '${label}' }),
            function (value) {
              const { different_resident, resident_user_id } = this.from[1].value
              if (!value && different_resident && !resident_user_id) return false
              return true
            })
          .label(i18n.t('app_users:last_name')),
        first_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:first_name')),
        furigana_last_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:furigana_last_name')),
        furigana_first_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:furigana_first_name')),
        app_user_manage_id: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:app_user_manage_id')),
        mail_address: yup
          .string()
          .email()
          .nullable()
          .label(i18n.t('common:email')),
        phone: yup
          .string()
          .nullable()
          .isCellPhone()
          .label(i18n.t('common:phone')),
        emergency_phone: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:emergency_phone')),
        occupation: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:occupation')),
        annual_income: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .nullable()
          .integer().nullable().label(i18n.t('app_users:annual_income')),
        birthday: yup
          .date()
          .nullable()
          .label(i18n.t('app_users:birthday')),
        sex: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .min(0).max(3)
          .integer()
          .nullable()
          .label(i18n.t('app_users:sex')),
        nationality: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:nationality')),
        residence_card: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:residence_card')),
        home_country_emergency_contact: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:home_country_emergency_contact')),
        resident_relation_id: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .nullable()
          .label(i18n.t('contracts:invited_users.relation_name')),
        work: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:work.work_school'))
      })
      .label(i18n.t('contracts:resident.resident_info')),
    roommates: yup
      .array()
      .nullable()
      .of(yup
        .object()
        .nullable()
        .shape({
          last_name: yup
            .string()
            .max(255)
            .nullable()
            .required()
            .label(i18n.t('contracts:roommates.last_name')),
          first_name: yup
            .string()
            .max(255)
            .nullable()
            .required()
            .label(i18n.t('contracts:roommates.first_name')),
          furigana_last_name: yup
            .string()
            .max(255)
            .nullable()
            .required()
            .label(i18n.t('contracts:roommates.furigana_last_name')),
          furigana_first_name: yup
            .string()
            .max(255)
            .nullable()
            .required()
            .label(i18n.t('contracts:roommates.furigana_first_name')),
          birthday: yup
            .date()
            .nullable()
            .required()
            .label(i18n.t('app_users:birthday')),
          sex: yup
            .number()
            .transform((cv, ov) => ov === '' ? undefined : cv)
            .min(0).max(3)
            .integer()
            .nullable()
            .required()
            .label(i18n.t('app_users:sex')),
          relation_id: yup
            .number()
            .transform((cv, ov) => ov === '' ? undefined : cv)
            .integer()
            .nullable()
            .required()
            .label(i18n.t('contracts:invited_users.relation_name')),
          phone: yup
            .string()
            .isPhone()
            .max(255)
            .required()
            .label(i18n.t('common:phone')),
          work: yup
            .string()
            .max(255)
            .nullable()
            .required()
            .label(i18n.t('contracts:roommates.work'))
        })
      ).label(i18n.t('contracts:roommates.info')),
    contract_guarantor: yup
      .object()
      .nullable()
      .shape({
        type: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .nullable()
          .label(i18n.t('contracts:guarantor.info')),
        last_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('contracts:guarantor.last_name')),
        first_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('contracts:guarantor.first_name')),
        furigana_last_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('contracts:guarantor.furigana_last_name')),
        furigana_first_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('contracts:guarantor.furigana_first_name')),
        birthday: yup
          .date()
          .nullable()
          .label(i18n.t('app_users:birthday')),
        relation_id: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer()
          .nullable()
          .label(i18n.t('contracts:invited_users.relation_name')),
        phone: yup
          .string()
          .nullable()
          .isPhone()
          .max(255)
          .label(i18n.t('common:phone')),
        mobile_phone: yup
          .string()
          .nullable()
          .isCellPhone()
          .max(255)
          .label(i18n.t('common:mobile_phone')),
        postcode: yup
          .string()
          .nullable()
          .matches(/^\d{7}$/, { excludeEmptyString: true })
          .label(i18n.t('properties:postcode')),
        prefecture: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('properties:prefecture')),
        city: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('properties:city')),
        address: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('common:address')),
        work: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('contracts:guarantor.work')),
        work_phone: yup
          .string()
          .nullable()
          .isPhone()
          .max(255)
          .label(i18n.t('app_users:work.phone')),
        work_position: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('contracts:guarantor.position')),
        annual_income: yup
          .number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .nullable()
          .integer().nullable().label(i18n.t('app_users:annual_income')),
        work_postcode: yup
          .string()
          .matches(/^\d{7}$/, { excludeEmptyString: true })
          .nullable()
          .label(i18n.t('app_users:work.postcode')),
        work_prefecture: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('properties:prefecture')),
        work_city: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('properties:city')),
        work_address: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:work.address'))
      })
      .label(i18n.t('contracts:contractor.contractor_info')),
    memo: yup
      .string()
      .nullable()
      .label(i18n.t('memo')),
    premium_start_date: yup
      .date()
      .nullable()
      .label(i18n.t('contracts:anabuki.premium_start_date')),
    casa_approval_number: yup
      .string()
      .nullable()
      .label(i18n.t('contracts:casa.approval_number'))
  })
}

export const yasueContractUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    id: yup
      .number()
      .integer()
      .label(i18n.t('id')),
    contract_manage_id: yup
      .string()
      .max(255)
      .required()
      .label(i18n.t('yasue:contracts.contract_manage_id')),
    contractor_user_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .test(
        'either-is-required',
        i18n.t('either_required', { label: '${label}', requiredField: i18n.t('contracts:contractor.contractor_info') }),
        function (value) {
          const { contractor } = this.parent
          if (!value && !contractor) return false
          if (!value && !contractor.last_name) return false
          return true
        })
      .label(i18n.t('contracts:contractor_user_id')),
    contractor: yup
      .object()
      .nullable()
      .shape({
        last_name: yup
          .string()
          .max(255)
          .nullable()
          .test(
            'conditional_required',
            i18n.t('required', { label: '${label}' }),
            function (value) {
              const { contractor_user_id } = this.from[1].value
              if (!value && !contractor_user_id) return false
              return true
            })
          .label(i18n.t('app_users:last_name')),
        furigana_last_name: yup
          .string()
          .max(255)
          .nullable()
          .label(i18n.t('app_users:furigana_last_name'))
      }),
    memo: yup
      .string()
      .nullable()
      .label(i18n.t('memo'))
  })
}
