import { put, call, takeLatest, select, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchContract,
  postContract,
  deleteContract,
  postRenewContract,
  postAcceptContractRenewal,
  cancelContractRenewal,
  fetchRenewContract,
  fetchCancelContract,
  cancelContractCanceling,
  postCancelContract,
  deleteApprovalNumber,
  postYasueContract
} from '../../services'
import {
  FETCH_CONTRACT,
  FETCH_CONTRACT_SUCCESS,
  FETCH_CONTRACT_FAIL,
  POST_CONTRACT,
  POST_CONTRACT_SUCCESS,
  POST_CONTRACT_FAIL,
  DELETE_CONTRACT,
  DELETE_CONTRACT_SUCCESS,
  DELETE_CONTRACT_FAIL,
  FETCH_CONTRACTS,
  FETCH_RENEW_CONTRACT,
  FETCH_RENEW_CONTRACT_SUCCESS,
  FETCH_RENEW_CONTRACT_FAIL,
  POST_RENEW_CONTRACT,
  POST_RENEW_CONTRACT_SUCCESS,
  POST_RENEW_CONTRACT_FAIL,
  POST_ACCEPT_RENEW_CONTRACT,
  POST_ACCEPT_RENEW_CONTRACT_SUCCESS,
  POST_ACCEPT_RENEW_CONTRACT_FAIL,
  POST_CANCEL_RENEW_CONTRACT,
  POST_CANCEL_RENEW_CONTRACT_SUCCESS,
  POST_CANCEL_RENEW_CONTRACT_FAIL,
  FETCH_CANCEL_CONTRACT,
  FETCH_CANCEL_CONTRACT_SUCCESS,
  FETCH_CANCEL_CONTRACT_FAIL,
  POST_CANCEL_CONTRACT,
  POST_CANCEL_CONTRACT_SUCCESS,
  POST_CANCEL_CONTRACT_FAIL,
  POST_CANCEL_CONTRACT_CANCELING,
  POST_CANCEL_CONTRACT_CANCELING_SUCCESS,
  POST_CANCEL_CONTRACT_CANCELING_FAIL,
  DELETE_CASA_APPROVAL_NUMBER
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchContractSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchContract, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_CONTRACT_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_CONTRACT_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_CONTRACT_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postContractSaga ({ data, successRedirect, yasue }) {
  const cancelSource = CancelToken.source()
  try {
    let res
    if (yasue) {
      res = yield call(postYasueContract, data, cancelSource)
    } else {
      res = yield call(postContract, data, cancelSource)
    }
    if (res && res.data && res.data.success) {
      if (successRedirect) yield call(successRedirect, res.data.id)
      yield put({ type: POST_CONTRACT_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CONTRACT_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CONTRACT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteContractSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteContract, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_CONTRACT_SUCCESS })
      yield put({ type: FETCH_CONTRACTS, query })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_CONTRACT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * deleteCasaApprovalNumberSaga ({ id, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const data = { contract_id: id }
    const res = yield call(deleteApprovalNumber, data, cancelSource)
    if (res) {
      const contract = yield call(fetchContract, id, cancelSource)
      yield put({ type: FETCH_CONTRACT_SUCCESS, payload: contract.data })
      yield put({ type: POST_CONTRACT_SUCCESS })
      if (onSuccess) yield call(onSuccess)
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: POST_CONTRACT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * fetchRenewContractSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchRenewContract, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_RENEW_CONTRACT_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_RENEW_CONTRACT_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_RENEW_CONTRACT_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * postRenewContractSaga ({ id, data }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postRenewContract, id, data, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: POST_RENEW_CONTRACT_SUCCESS })
      yield put({ type: FETCH_CONTRACT, id, noLoading: true })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_RENEW_CONTRACT_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_RENEW_CONTRACT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postAcceptRenewContractSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postAcceptContractRenewal, id, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: POST_ACCEPT_RENEW_CONTRACT_SUCCESS })
      // yield put({ type: FETCH_CONTRACT, id, noLoading: true })
      yield call(message.success, i18n.t('contracts:status_panel.WAITING_RENEWAL_CONFIRMATION.accept_success'))
    } else {
      yield put({ type: POST_ACCEPT_RENEW_CONTRACT_FAIL })
      yield call(message.error, i18n.t('contracts:status_panel.WAITING_RENEWAL_CONFIRMATION.accept_fail'))
    }
  } catch (error) {
    yield put({ type: POST_ACCEPT_RENEW_CONTRACT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('contracts:status_panel.WAITING_RENEWAL_CONFIRMATION.accept_fail'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * postCancelRenewContractSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(cancelContractRenewal, id, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: POST_CANCEL_RENEW_CONTRACT_SUCCESS })
      yield put({ type: FETCH_CONTRACT, id, noLoading: true })
      yield call(message.success, i18n.t('contracts:status_panel.renewal.cancel_success'))
    } else {
      yield put({ type: POST_CANCEL_RENEW_CONTRACT_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CANCEL_RENEW_CONTRACT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('contracts:status_panel.renewal.cancel_fail'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * fetchCancelContractSaga ({ id, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchCancelContract, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_CANCEL_CONTRACT_SUCCESS, payload: res.data })
      if (onSuccess) yield call(onSuccess)
    } else {
      yield put({ type: FETCH_CANCEL_CONTRACT_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_CANCEL_CONTRACT_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * postCancelContractSaga ({ id, data }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postCancelContract, id, data, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: POST_CANCEL_CONTRACT_SUCCESS })
      yield put({ type: FETCH_CONTRACT, id, noLoading: true })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CANCEL_CONTRACT_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CANCEL_CONTRACT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * postCancelContractCancelingSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(cancelContractCanceling, id, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: POST_CANCEL_CONTRACT_CANCELING_SUCCESS })
      yield put({ type: FETCH_CONTRACT, id, noLoading: true })
      yield call(message.success, i18n.t('contracts:status_panel.cancel.cancel_success'))
    } else {
      yield put({ type: POST_CANCEL_CONTRACT_CANCELING_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CANCEL_CONTRACT_CANCELING_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('contracts:status_panel.cancel.cancel_fail'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchContract () {
  yield takeLatest(FETCH_CONTRACT, fetchContractSaga)
  yield takeLatest(POST_CONTRACT, postContractSaga)
  yield takeLatest(DELETE_CONTRACT, deleteContractSaga)
  yield takeLatest(FETCH_RENEW_CONTRACT, fetchRenewContractSaga)
  yield takeLatest(POST_RENEW_CONTRACT, postRenewContractSaga)
  yield takeLatest(POST_ACCEPT_RENEW_CONTRACT, postAcceptRenewContractSaga)
  yield takeLatest(POST_CANCEL_RENEW_CONTRACT, postCancelRenewContractSaga)
  yield takeLatest(FETCH_CANCEL_CONTRACT, fetchCancelContractSaga)
  yield takeLatest(POST_CANCEL_CONTRACT, postCancelContractSaga)
  yield takeLatest(POST_CANCEL_CONTRACT_CANCELING, postCancelContractCancelingSaga)
  yield takeLatest(DELETE_CASA_APPROVAL_NUMBER, deleteCasaApprovalNumberSaga)
}
