import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  FRONT_DATE_FORMAT,
  Form,
  DatePicker,
  FilePicker
} from '@gk-devteam/apmc-core-web'

import { appUserDownloadLoginValidation } from '../../validation'

import Recipients from './recipients/Recipients'

const initialValues = {
  date: dayjs().format(FRONT_DATE_FORMAT), // 今日
  type: 1, // 全員
  ids: null,
  file: null
}

const AppUsersDownloadLoginPdfForm = ({ onSubmit }) => {
  return (
    <Form
      id="downloadAppUsersLoginPdfForm"
      handleSubmit={onSubmit}
      validationSchema={appUserDownloadLoginValidation}
      initialData={initialValues}
      // authorized="app_users.functions.update"
    // debug
    >
      <DatePicker
        flex={1}
        name="date"
        label='app_users:download_pdf_files.created_date'
        placeholder={dayjs().format(FRONT_DATE_FORMAT)}
        required
      />
      <FilePicker
        name="file"
        label="app_users:download_pdf_files.template_file"
        delete_name="delete_file"
        types={['pdf']}
        required
      />
      <Recipients />
    </Form>
  )
}
export default AppUsersDownloadLoginPdfForm

AppUsersDownloadLoginPdfForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
