import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  UPDATE_FORM,
  selectQueryParams
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_CHAT_STATUS,
  RESET_CHAT_ROOMS,
  RESET_CHAT_STATUS
} from '../../../types'
import {
  selectChatLoadingHiddenBy,
  selectWaitingChatRoomsCount,
  selectChatHiddenBy,
  selectChatHiddenById
} from '../../../selectors'

import { chatSearchSchema } from '../../../validation'

import ChatSearchForm from './ChatSearchForm'
import ListPage from '../common/ListPage'
import ChatList from './ChatList'
import { fetchChatRooms } from '../../../actions'

const initialValues = {
  userName: '',
  userID: '',
  propertyName: '',
  managementStatus: null
  // limit: '',
  // page: ''
}

const AdminTasksChatRoomsListPage = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectChatLoadingHiddenBy)
  const queryParams = useSelector(selectQueryParams)
  const waitingChatRoomsCount = useSelector(selectWaitingChatRoomsCount)

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        userName: queryParams.userName,
        userID: queryParams.userID,
        propertyName: queryParams.propertyName,
        managementStatus: queryParams.managementStatus ? Number(queryParams.managementStatus) : queryParams.managementStatus
      }
    })
  }, [queryParams, dispatch])

  useEffect(() => {
    dispatch(fetchChatRooms())
    return () => {
      dispatch({ type: RESET_CHAT_ROOMS })
    }
  }, [dispatch, waitingChatRoomsCount])

  useEffect(() => {
    dispatch({ type: FETCH_CHAT_STATUS })
    return () => {
      dispatch({ type: RESET_CHAT_STATUS })
    }
  }, [dispatch])

  const breadcrumbs = [
    {
      label: 'navigation:admin_tasks',
      link: '/admin_tasks'
    },
    {
      label: 'navigation:chat_rooms_list',
      link: '/admin_tasks/chat'
    }
  ]

  if (loading) return null

  return (
    <ListPage
      breadcrumbs={breadcrumbs}
      pageTitle="navigation:chat_rooms_list"
      hiddenSelector={selectChatHiddenBy}
      hiddenByIdSelector={selectChatHiddenById}
      countSelector={selectWaitingChatRoomsCount}
      validationSchema={chatSearchSchema}
      initialValues={initialValues}
      searchInputs={<ChatSearchForm />}
      table={<ChatList queryParams={queryParams}/>}
    />

  )
}
export default AdminTasksChatRoomsListPage

AdminTasksChatRoomsListPage.propTypes = {

}
AdminTasksChatRoomsListPage.defaultProps = {

}
