import { api, MULTIPART_HEADERS, getFileFromURL } from '@gk-devteam/apmc-core-web'

export const fetchVicinities = (id, cancelSource) => {
  return api.get(`properties/${id}/vicinities`, {
    cancelToken: cancelSource.token
  })
}

export const postVicinity = async (data, id, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }

    delete data.file
    delete data.file_url
    delete data.file_filename
    delete data.modified

    formData.append('data', JSON.stringify(data))
    return api.post(`properties/${id}/vicinities`, formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const toggleVicinity = (data, id, cancelSource) => {
  return api.post(`properties/${id}/vicinities/display`, data, {
    cancelToken: cancelSource.token
  })
}

export const toggleAdditionalVicinities = async (data, id, cancelSource) => {
  return api.post(`properties/${id}/vicinities/enable_additional_data`, data, {
    cancelToken: cancelSource.token
  })
}

export const deleteVicinity = (propertyID, vicinityID, cancelSource) => {
  return api.delete(`properties/${propertyID}/vicinities/${vicinityID}`, {
    cancelToken: cancelSource.token
  })
}
