import {
  api,
  MULTIPART_HEADERS,
  getFileFromURL
} from '@gk-devteam/apmc-core-web'

export const postDatasync = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.properties_file) {
      try {
        const file = await getFileFromURL(data.properties_file)
        formData.append('properties_file', file, 'properties.csv')
      } catch (error) {
        throw new Error('error while processing properties file')
      }
    }
    if (data.contracts_file) {
      try {
        const file = await getFileFromURL(data.contracts_file)
        formData.append('contracts_file', file, 'contracts.csv')
      } catch (error) {
        throw new Error('error while processing contracts file')
      }
    }

    return api.post('/datasync', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}
