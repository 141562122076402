import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, FormSubmitButton, Row } from '@gk-devteam/apmc-core-web'

import { yasueMaintenanceUpdateSchema } from '../../../validation'
import {
  FETCH_YASUE_MAINTENANCE_CONTACTS,
  RESET_YASUE_MAINTENANCE_CONTACTS,
  POST_YASUE_MAINTENANCE_CONTACTS
} from '../../../types'
import {
  selectYasueMaintenanceContacts,
  selectYasueMaintenanceLoading,
  selectYasueMaintenancePosting
} from '../../../selectors'
import { TextAreaStyle } from './yasueMaintenance.style'

export default function ContactsTab () {
  const dispatch = useDispatch()
  const data = useSelector(selectYasueMaintenanceContacts)

  useEffect(() => {
    dispatch({ type: FETCH_YASUE_MAINTENANCE_CONTACTS })
    return () => {
      dispatch({ type: RESET_YASUE_MAINTENANCE_CONTACTS })
    }
  }, [dispatch])

  const handleFormSubmit = (data) => {
    dispatch({ type: POST_YASUE_MAINTENANCE_CONTACTS, data })
  }

  return (
    <Form
      id="yasueMaintenanceContacts"
      handleSubmit={handleFormSubmit}
      validationSchema={yasueMaintenanceUpdateSchema}
      loadingSelector={selectYasueMaintenanceLoading}
      postingSelector={selectYasueMaintenancePosting}
      initialData={{
        contacts: data
      }}
      authorized="custom.yasue"
      // debug
    >
      <TextAreaStyle name="contacts" mr="0" maxLength={10_000}/>
      <Row justify="end" mb="ML">
        <FormSubmitButton formID="yasueMaintenanceContacts" postingSelector={selectYasueMaintenanceLoading}/>
      </Row>
    </Form>
  )
}
