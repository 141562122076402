import React from 'react'
import PropTypes from 'prop-types'
import {
  MainLayout
} from '@gk-devteam/apmc-core-web'

import PanelLegend from '../PanelLegend'
import PanelHeader from '../PanelHeader'
import PanelDisabled from '../PanelDisabled'
import PanelInfo from '../PanelInfo'

const PanelLayout = ({
  breadcrumbs,
  children,
  loading,
  title,
  handleAddClick,
  hiddenSelector,
  hiddenByIdSelector,
  propertyID,
  roomID,
  contractID,
  noHeader,
  noLegend,
  authorized,
  info,
  infoTitle
}) => {
  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      <PanelInfo info={info} infoTitle={infoTitle} />
      <PanelDisabled
        hiddenSelector={hiddenSelector}
        hiddenByIdSelector={hiddenByIdSelector}
        propertyID={propertyID}
        roomID={roomID}
        contractID={contractID}
      >
        {
          noHeader
            ? null
            : (
              <PanelHeader
                title={title}
                handleClick={handleAddClick}
                authorized={authorized}
              />
            )
        }
        { children }
        {
          noLegend
            ? null
            : <PanelLegend />
        }

      </PanelDisabled>
    </MainLayout>
  )
}
export default PanelLayout

PanelLayout.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  handleAddClick: PropTypes.func,
  hiddenSelector: PropTypes.func,
  hiddenByIdSelector: PropTypes.func,
  propertyID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  roomID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contractID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noLegend: PropTypes.bool,
  noHeader: PropTypes.bool,
  authorized: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  info: PropTypes.string,
  infoTitle: PropTypes.string
}
PanelLayout.defaultProps = {

}
