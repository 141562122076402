import {
  styled,
  getColor,
  FONT_SIZE,
  SPACING
} from '@gk-devteam/apmc-core-web'

export const DangerMessageStyle = styled.h3`
  ${FONT_SIZE.MEDIUM}
  font-weight: bold;
  margin: 0;
  white-space: pre-wrap;
  color: ${props => getColor(props, 'danger')};
`

export const DeleteMessageStyle = styled.p`
  margin-top: ${SPACING.REGULAR}rem;
  margin-bottom: 0;
  white-space: pre-line;
`
export const ConstructionWorkStyle = styled.span`
  font-weight: bold;
`
