import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row,
  FilePicker,
  TextInput,
  TextArea
} from '@gk-devteam/apmc-core-web'

import {
  selectVicinitiesLoading,
  selectVicinitiesCategoriesOptions
} from '../../selectors'
import { vicinitiesSchema } from '../../validation'

import {
  FormStyle,
  FileContainerStyle,
  InputContainerStyle,
  TextInputStyle,
  SelectStyle
} from './VicinityForm.style'

const VicinityForm = ({ handleSubmit, initialValues }) => {
  const options = useSelector(selectVicinitiesCategoriesOptions)
  const loading = useSelector(selectVicinitiesLoading)
  return (
    <FormStyle
      id="vicinity_form"
      handleSubmit={handleSubmit}
      validationSchema={vicinitiesSchema}
      authorized="vicinities.functions.update"
      // loadingSelector={selectVicinitiesLoading}
      // postingSelector={selectVicinitiesPosting}
      initialData={initialValues}
      // debug
    >
      <Row>
        <FileContainerStyle>
          <FilePicker
            flex={1}
            name="file"
            delete_name="delete_file"
            types={['image']}
            placeholder=""
          />
        </FileContainerStyle>
        <InputContainerStyle>
          <TextInputStyle
            flex={1}
            type="text"
            name="title"
            label='title'
            placeholder=""
            required
          />
          <SelectStyle
            flex={1}
            name="category"
            label='category'
            options={options}
            loading={loading}
            placeholder=""
            required
            noClear
          />
          <TextInputStyle
            flex={1}
            type="text"
            name="business_hour"
            label='business_hour'
            placeholder=""
          />
        </InputContainerStyle>
      </Row>
      <Row mt="S">
        <TextInput
          flex={1}
          name="url"
          label='external_url'
          placeholder=""
        />
        <TextInput
          flex={1}
          name="phone"
          label='phone'
          placeholder="placeholders.phone"
        />
      </Row>
      <Row mt="S">
        <TextInput
          flex={1}
          name="address"
          label='address'
          placeholder=""
          required
        />
      </Row>
      <Row mt="S">
        <TextArea
          flex={1}
          name="content"
          label='content'
          placeholder=""
        />
      </Row>
    </FormStyle>
  )
}

export default VicinityForm

VicinityForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}
