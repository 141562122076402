import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  Row,
  Form,
  FormSubmitButton,
  RoleControlled,
  TextInput,
  TextArea
} from '@gk-devteam/apmc-core-web'

import {
  selectRoomLoading,
  selectPostingRoom
} from '../../selectors'
import { roomUpdateValidation } from '../../validation'
import { POST_ROOM } from '../../types'

const RoomForm = ({ data, propertyID }) => {
  const navigate = useNavigate()
  // const data = useSelector(selectRoomFormData)
  const dispatch = useDispatch()

  const handleFormSubmit = (data) => {
    const success = (propertyID, roomID) => {
      // dispatch({ type: FETCH_ROOM, propertyID, roomID })
      navigate(`/properties/edit/${propertyID}/rooms`)
    }
    dispatch({ type: POST_ROOM, data, propertyID, success })
  }
  return (
    <Form
      id="room_form"
      handleSubmit={handleFormSubmit}
      validationSchema={roomUpdateValidation}
      loadingSelector={selectRoomLoading}
      postingSelector={selectPostingRoom}
      initialData={data}
      authorized="rooms.functions.update"
      // debug
    >
      <Row>
        <TextInput
          flex={1}
          size="M"
          type="text"
          name="room_number"
          label='properties:room_number'
          placeholder=""
          required
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name="room_manage_id"
          label='rooms:room_manage_id'
          placeholder=""
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          size="S"
          type="number"
          name="room_surface"
          label='rooms:room_surface'
          placeholder=""
          suffix="surface"
        />
        <TextInput
          flex={1}
          size="S"
          type="text"
          name="room_plan"
          label='rooms:room_plan'
          placeholder=""
        />
      </Row>
      <Row>
        <TextArea
          name="memo"
          placeholder="placeholders.memo"
          flex={1}
        />
      </Row>
      <Row justify="end">
        <RoleControlled authorized="rooms.functions.update">
          <FormSubmitButton postingSelector={selectPostingRoom} />
        </RoleControlled>
      </Row>
    </Form>
  )
}

export default memo(RoomForm)

RoomForm.propTypes = {
  data: PropTypes.object,
  propertyID: PropTypes.string.isRequired
}
