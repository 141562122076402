import { api } from '@gk-devteam/apmc-core-web'

export const fetchTemplates = (params, cancelSource) => {
  return api.get('templates', {
    cancelToken: cancelSource.token,
    params
  })
}
export const fetchTemplateDefinition = (templateID, type, cancelSource) => {
  return api.get(`templates/${templateID}/definition`, {
    params: {
      type
    },
    cancelToken: cancelSource.token
  })
}
export const previewTemplate = (templateID, type, cancelSource) => {
  return api.get(`templates/${templateID}/preview`, {
    params: {
      type
    },
    cancelToken: cancelSource.token,
    responseType: 'blob'
  })
}
