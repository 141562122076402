import React, { useEffect, useState, useCallback } from 'react'
import { Empty } from 'antd'
import PropTypes from 'prop-types'
import {
  TextInput,
  Spinner,
  Row,
  Text,
  LightText
} from '@gk-devteam/apmc-core-web'

import {
  PropertiesModalStyle,
  SearchButtonStyle,
  PropertiesLoaderStyle,
  PropertiesListStyle,
  PropertiesListItemStyle
} from '../../ContractForm.style'

const ContractPropertyModal = ({
  modalVisible,
  closeModal,
  handleSelect,
  loading,
  properties,
  searchProperties
}) => {
  const [propertyName, setPropertyName] = useState('')

  useEffect(() => {
    if (modalVisible) {
      const input = document.querySelector('input[name=propertyName]')
      if (input) input.focus()
    }
    return () => {
      setPropertyName('')
    }
  }, [modalVisible])

  const _handlePropertyNameChange = useCallback(
    (e) => {
      setPropertyName(e.target.value)
    },
    [setPropertyName]
  )
  const _handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        searchProperties(propertyName)
      }
    },
    [searchProperties, propertyName]
  )

  const _onClose = () => {
    closeModal()
    searchProperties()
  }
  const handleSearchProperties = () => {
    searchProperties(propertyName)
  }

  const _renderProperties = () => {
    if (!properties) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <LightText label='search_instruction'/>
          }
        />
      )
    }
    if (properties.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <LightText label='no_data'/>
          }
        />
      )
    }
    return properties.map(property => {
      const { id, name } = property
      return (
        <PropertiesListItemStyle
          key={id}
          onClick={ () => { handleSelect(id, name) } }
        >
          {name}
        </PropertiesListItemStyle>
      )
    })
  }

  const _renderPropertiesLoader = () => {
    if (!loading) return null
    return (
      <PropertiesLoaderStyle>
        <Spinner
          size="default"
          color="info"
        />
      </PropertiesLoaderStyle>
    )
  }

  return (
    <PropertiesModalStyle
      visible={modalVisible}
      onCancel={_onClose}
      title={'contracts:select_property'}
    >
      <Row align="center">
        <TextInput
          flex={1}
          type="text"
          name="propertyName"
          label='properties:property_name'
          placeholder="placeholders.partial_property_name"
          onChange={_handlePropertyNameChange}
          value={propertyName}
          onKeyPress={_handleKeyPress}
        />
        <SearchButtonStyle
          label="search"
          type="button"
          handleClick={handleSearchProperties}
          loading={loading}
        />
      </Row>
      <Row>
        <Text label="contracts:select_property"/>
      </Row>
      <PropertiesListStyle>
        { _renderPropertiesLoader() }
        { _renderProperties() }
      </PropertiesListStyle>
    </PropertiesModalStyle>
  )
}

export default ContractPropertyModal

ContractPropertyModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  properties: PropTypes.array,
  searchProperties: PropTypes.func.isRequired
}
