import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  Confirm,
  DangerButton,
  DatePicker,
  EmptyData,
  Form,
  Radio,
  RepeaterAddButton,
  RepeaterFields,
  RoleControlled,
  Row,
  Select,
  Tag,
  Text,
  TextArea,
  TextInput,
  Title,
  ToastTitle,
  selectFormRequiredFields
} from '@gk-devteam/apmc-core-web'
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import {
  POST_CANCEL_CONTRACT,
  POST_CANCEL_CONTRACT_CANCELING
} from '../../../types'
import {
  CONTRACT_STATUS,
  TEMPLATE_TYPES,
  BANK_TYPE,
  BRANCH_TYPE,
  ACCOUNT_TYPE
} from '../../../constants'
import { contractCancelValidation } from '../../../validation'
import {
  selectContractLoading,
  selectContractPosting,
  selectContractStatus
} from '../../../selectors'

import ContractPreview from './ContractPreview'
import MovingOutDate from './MovingOutDate'
import FinalInspectionDate from './FinalInspectionDate'
import CancelReason from './CancelReason'
import SelectContractTemplate from '../SelectContractTemplate'
import CancelNoticeRow from '../ContractForm/subForms/NoticeInfo/CancelNoticeRow'
import SelectFinalInspectionDate from './SelectFinalInspectionDate'

import { ContractSignDateStyle } from './ContractCancelForm.style'

const authorizedStatuses = [
  CONTRACT_STATUS.REQUESTING_CANCEL.value
]
const authorizedCancelStatus = [
  CONTRACT_STATUS.REQUESTING_CANCEL.value,
  CONTRACT_STATUS.CANCELING.value,
  CONTRACT_STATUS.MOVING_OUT.value
]

function ContractCancelForm ({ data, contractID }) {
  const dispatch = useDispatch()
  const status = useSelector(selectContractStatus)
  const requiredFields = useSelector(selectFormRequiredFields)

  const handleFormSubmit = (formData) => {
    // const successRedirect = (id) => {
    //   navigate('/contracts')
    // }
    dispatch({ type: POST_CANCEL_CONTRACT, id: contractID, data: formData })
  }

  const _handleCancel = () => {
    dispatch({ type: POST_CANCEL_CONTRACT_CANCELING, id: contractID })
  }

  const _renderStatusTag = () => {
    if (status != null) {
      let label, color
      for (const key in CONTRACT_STATUS) {
        const element = CONTRACT_STATUS[key]
        if (status === element.value) {
          label = `contracts:status.${key}`
          color = element.color
        }
      }
      return (
        <Tag label={label} color={color}/>
      )
    }
    return null
  }

  const _renderContractSignDate = () => {
    if (!data || data?.contract_sign_date) return null
    return (
      <ContractSignDateStyle mb="L" align="baseline">
        <ExclamationCircleOutlined />
        <div>
          <ToastTitle label="contracts:register_sign_date" />
          <Text label="contracts:unregistered_sign_date" />
          <Row mt="S">
            <DatePicker
              flex={1}
              size="M"
              name="contract_sign_date"
              placeholder=""
              noLabel
            />
          </Row>
        </div>
      </ContractSignDateStyle>
    )
  }

  if (!data) return null

  if (!data?.cancel_date) {
    return (
      <>
        <Row justify="between" align="baseline" mb="XL">
          <Row align="baseline" wrap={'wrap'}>
            <Title label="navigation:contracts_cancel" />
            <Row>
              {_renderStatusTag()}
            </Row>
          </Row>
        </Row>
        {
          !data?.cancel_date
            ? <EmptyData />
            : null
        }
      </>
    )
  }

  return (
    <Form
      id="cancelContractForm"
      handleSubmit={handleFormSubmit}
      validationSchema={contractCancelValidation}
      customValidation={requiredFields}
      loadingSelector={selectContractLoading}
      postingSelector={selectContractPosting}
      initialData={data}
      authorized={
        !authorizedStatuses.includes(status)
          ? false
          : 'contracts.functions.cancel'
      }
    >
      <Row justify="between" align="baseline" mb="L">
        <Row align="baseline" wrap={'wrap'}>
          <Title label="navigation:contracts_cancel" />
          <Row>
            {_renderStatusTag()}
          </Row>
        </Row>
        <RoleControlled authorized="contracts.functions.cancel">
          {
            authorizedCancelStatus.includes(status)
              ? (
                <Confirm
                  title="contracts:status_panel.cancel.cancel_question"
                  placement="topRight"
                  onConfirm={_handleCancel}
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                >
                  <DangerButton
                    label="contracts:status_panel.cancel.label"
                    withMargin
                  />
                </Confirm>
              )
              : null
          }
          <ContractPreview buttonLabel='contracts:contract_cancel_preview'/>
        </RoleControlled>
      </Row>
      <SelectContractTemplate type={TEMPLATE_TYPES.CANCEL} />
      { _renderContractSignDate() }
      <Card mb="L" title="contracts:moving_out_info">
        <Row align="baseline">
          <MovingOutDate />
          <FinalInspectionDate
            name="final_inspection_datetime_1"
            label="contracts:final_inspection_datetime_1"
          />
          <FinalInspectionDate
            name="final_inspection_datetime_2"
            label="contracts:final_inspection_datetime_2"
          />
        </Row>
        <SelectFinalInspectionDate />
        <Row align="baseline">
          <TextInput
            flex={1}
            size="M"
            type="text"
            name="new_postcode"
            label='contracts:new_postcode'
            placeholder="placeholders.postcode"
            required={requiredFields?.new_postcode}
          />
        </Row>
        <Row align="baseline">
          <TextInput
            flex={1}
            type="text"
            name="new_address"
            label='contracts:new_address'
            placeholder=""
            required={requiredFields?.new_address}
          />
        </Row>
        <CancelReason />
        <Row>
          <TextArea
            flex={1}
            name="message"
            label='contracts:message'
            placeholder=""
            required={requiredFields?.message}
          />
        </Row>
      </Card>
      <Card mb="L" title="contracts:cancel_notices_info">
        <Row wrap="wrap" align="baseline">
          <RepeaterFields
            propertyKey="cancel_notice"
            uniqField="destination_email"
            field={(index) => <CancelNoticeRow key={index} index={index}/>}
          />
        </Row>
        <RepeaterAddButton
          label="contracts:add_cancel_notice"
          propertyKey="cancel_notice"
          uniqField="destination_email"
          fields={['enabled', 'destination_name', 'destination_email']}
          // help="contracts:help.custom_fields"
        />
      </Card>
      <Card mb="L" title="contracts:repayment_bank_account.info">
        <Row align="baseline">
          <TextInput
            flex={1}
            type="text"
            size="L"
            name="repayment_bank_account.bank_name"
            label='contracts:repayment_bank_account.bank_name'
            placeholder=""
            required={requiredFields?.['repayment_bank_account.bank_name']}
          />
          <Select
            size="S"
            name="repayment_bank_account.bank_type"
            label="contracts:repayment_bank_account.bank_type"
            placeholder=""
            options={BANK_TYPE}
            noClear
            required={requiredFields?.['repayment_bank_account.bank_type']}
          />
        </Row>
        <Row align="baseline">
          <TextInput
            flex={1}
            type="text"
            size="L"
            name="repayment_bank_account.branch_name"
            label='contracts:repayment_bank_account.branch_name'
            placeholder=""
            required={requiredFields?.['repayment_bank_account.branch_name']}
          />
          <Select
            size="S"
            name="repayment_bank_account.branch_type"
            label="contracts:repayment_bank_account.branch_type"
            placeholder=""
            options={BRANCH_TYPE}
            noClear
            required={requiredFields?.['repayment_bank_account.branch_type']}
          />
        </Row>
        <Row align="baseline">
          <Radio
            size="fluid"
            name="repayment_bank_account.account_type"
            label="contracts:repayment_bank_account.account_type"
            options={ACCOUNT_TYPE}
            required={requiredFields?.['repayment_bank_account.account_type']}
          />
          <TextInput
            flex={1}
            type="number"
            name="repayment_bank_account.account_number"
            label='contracts:repayment_bank_account.account_number'
            placeholder="placeholders.bank_account_number"
            required={requiredFields?.['repayment_bank_account.account_number']}
          />
        </Row>
        <Row align="baseline">
          <TextInput
            flex={1}
            type="text"
            size="M"
            name="repayment_bank_account.name"
            label='contracts:repayment_bank_account.name'
            placeholder=""
            required={requiredFields?.['repayment_bank_account.name']}
          />
          <TextInput
            flex={1}
            type="text"
            size="M"
            name="repayment_bank_account.furigana"
            label='contracts:repayment_bank_account.furigana'
            placeholder=""
            required={requiredFields?.['repayment_bank_account.furigana']}
          />
        </Row>
      </Card>
    </Form>
  )
}

ContractCancelForm.propTypes = {
  data: PropTypes.object,
  contractID: PropTypes.string
}

export default ContractCancelForm
