import {
  BORDER_RADIUS,
  FormRemovableLabel,
  getColor,
  SPACING,
  styled
  } from "@gk-devteam/apmc-core-web";

export const TableContainerStyle = styled.div`
  flex: 5;
  height: 100%;
  overflow: scroll;
  position: relative;
`

export const CheckmarkCellStyle = styled.div`
  padding: calc(${SPACING.SMALL}rem - 2px) 0;
  text-align: center;
`


export const BetweenStyle = styled.div`
  align-self: center;
  margin: 0 ${SPACING.SMALLEST}rem;
  user-select: none;
  color: ${props => getColor(props, 'lightText')};
`

export const ResultsContainer = styled.ul`
  ${BORDER_RADIUS};
  padding: ${SPACING.SMALL}rem;
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  margin: 0;
  list-style: none;
  flex: 2;
  height: 100%;
  overflow: scroll;
`
export const RecipientStyle = styled(FormRemovableLabel)`
  margin: ${SPACING.SMALLEST / 2}rem;
`
