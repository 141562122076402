import {
  styled,
  getColor,
  SPACING,
  BOX_SHADOW,
  Card,
  LightText,
  CommentTitle
} from '@gk-devteam/apmc-core-web'

export const IssueDetailsStyle = styled(Card)`
  ${BOX_SHADOW};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: none;
`

export const CaptionStyle = styled.p`
  margin-bottom: ${SPACING.REGULAR}rem;
  white-space: pre-wrap;
`

export const IssueCommentsContainerStyle = styled.div`
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border: solid 1px ${props => getColor(props, 'cardBorder')};
`
export const CommentsStyle = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 250px;
  overflow-y: scroll;
`
export const CommentStyle = styled.li`
  padding: ${SPACING.SMALL}rem;
  &:not(:last-child) {
    border-bottom: solid 1px ${props => getColor(props, 'cardBorder')};
  }
`
export const CommentTitleStyle = styled(CommentTitle)`
  margin-left: ${SPACING.SMALLEST}rem;
`
export const CommentDateStyle = styled(LightText)`
  white-space: nowrap;
`
export const CommentContentStyle = styled.p`
  margin: 0;
  color: ${props => getColor(props, 'lightText')};
  white-space: pre-wrap;
`
