import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  FilePicker,
  Row
} from '@gk-devteam/apmc-core-web'

import {
  selectRefuseLoading,
  selectRefusePosting
} from '../../selectors'
import { refusePostSchema } from '../../validation'

const AddRefuseForm = ({ handleSubmit }) => {
  return (
    <Form
      id="refuse_add"
      handleSubmit={handleSubmit}
      validationSchema={refusePostSchema}
      loadingSelector={selectRefuseLoading}
      postingSelector={selectRefusePosting}
      initialData={{
        delete_pdf: false,
        pdf: null
      }}
      // debug
    >
      <Row>
        <FilePicker
          label='select_file'
          name="pdf"
          delete_name="delete_pdf"
          types={['pdf']}
          required
        />
      </Row>
    </Form>
  )
}
export default AddRefuseForm

AddRefuseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}
AddRefuseForm.defaultProps = {

}
