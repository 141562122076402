import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import { CancelToken } from 'axios'
import {
  useTranslation,
  Form,
  Card,
  Row,
  TextArea,
  Title,
  FormSubmitButton,
  RepeaterFields,
  RepeaterAddButton,
  InquiryID,
  RoleControlled,
  TextInput,
  Select,
  DatePicker,
  PrimaryButton,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  selectContractLoading,
  selectContractPosting
} from '../../../selectors'
import { contractUpdateValidation } from '../../../validation'
import { DELETE_CASA_APPROVAL_NUMBER, POST_CONTRACT } from '../../../types'
import { CONTRACT_DURATION_OPTIONS, CONTRACT_STATUS, INQUIRY_PREFIX, USER_EMPTY_NAME } from '../../../constants'

import ContractProperty from './subForms/ContractInfo/ContractProperty'
import ContractRoom from './subForms/ContractInfo/ContractRoom'
import ContractDates from './subForms/ContractInfo/ContractDates'
import MovingInDate from './subForms/ContractInfo/MovingInDate'
import CustomFieldRow from './subForms/ContractInfo/CustomFieldRow'
import ContractFees from './subForms/FeesInfo/ContractFees'
import RenewingDates from './subForms/RenewalInfo/RenewingDates'
import MovingOutDate from './subForms/CancelInfo/MovingOutDate'
import { ContractorCard, ResidentCard } from './subForms/AppUserInfo/AppUserCard'
import RoommatesCard from './subForms/RoommatesInfo/RoommatesCard'
import GuarantorCard from './subForms/GuarantorInfo/GuarantorCard'
import InvitedUsers from './subForms/InvitedUsers'
import ProcessCard from './ProcessCard'
import NoticeCard from './subForms/NoticeInfo/NoticeCard'

import {
  StatusStyle
} from './ContractForm.style.js'
import CasaCard from './CasaCard/CasaCard'
import { fetchApprovalNumbers } from '../../../services'
import { message } from 'antd'

const authorizedStatuses = [
  undefined,
  CONTRACT_STATUS.MOVING_IN.value,
  CONTRACT_STATUS.RESIDING.value,
  CONTRACT_STATUS.WAITING_APPROVAL.value,
  CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value,
  CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value,
  CONTRACT_STATUS.RENEWING.value,
  CONTRACT_STATUS.REQUESTING_CANCEL.value,
  CONTRACT_STATUS.CANCELING.value,
  CONTRACT_STATUS.IN_RENEWING_PERIOD.value,
  CONTRACT_STATUS.RENEWING_CONFIRMED.value
]

const ContractForm = ({ data, contractID }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const elemRef = useRef()
  const pages = useSelector(selectPages)
  const [residentName, setResidentName] = useState('')
  const [casaReadOnly, setCasaReadOnly] = useState(!!data?.casa_approval_number)
  const [casaApprovalNumbers, setCasaApprovalNumbers] = useState()

  const initialValues = {
    ...data,
    auto_renewal: data?.auto_renewal || false,
    contract_duration_unit: data?.contract_duration_unit || 1, // set to if not already set 年間
    rent_fees: data?.rent_fees && data.rent_fees.length > 0 ? data.rent_fees : null,
    renewal_notice: data?.renewal_notice && data.renewal_notice.length > 0 ? data.renewal_notice : null,
    cancel_notice: data?.cancel_notice && data.cancel_notice.length > 0 ? data.cancel_notice : null,
    roommates: data?.roommates && data.roommates.length > 0 ? data.roommates : null,
    premium_start_date: data?.premium_start_date || null
  }

  const fetchAvailableApprovalNumbers = useCallback(
    async () => {
      const cancelSource = CancelToken.source()
      try {
        const res = await fetchApprovalNumbers(cancelSource)
        setCasaApprovalNumbers(res?.data?.results || [])
      } catch (error) {
        // console.warn(error)
        message.error(t('contracts:casa.fetch_approval_number_error'))
      }
    },
    [t]
  )

  // Fetch Casa approval numbers
  useEffect(() => {
    if (pages.custom.casa) {
      fetchAvailableApprovalNumbers()
    }
  }, [fetchAvailableApprovalNumbers, pages])

  // focus top of the page
  useEffect(() => {
    if (elemRef.current) {
      elemRef.current.scrollIntoView()
    }
  }, [elemRef])

  useEffect(() => {
    if (data?.different_resident) {
      if (data?.awaiting_resident_approval) {
        setResidentName(USER_EMPTY_NAME)
      } else {
        setResidentName(`${data?.resident?.last_name} ${data?.resident?.first_name || ''}`)
      }
    } else {
      if (data?.awaiting_contractor_approval) {
        setResidentName(USER_EMPTY_NAME)
      } else {
        setResidentName(`${data?.contractor?.last_name} ${data?.contractor?.first_name || ''}`)
      }
    }
  }, [data])

  const handleFormSubmit = (data) => {
    const successRedirect = (id) => {
      navigate('/contracts')
    }
    console.log('data', data)
    dispatch({ type: POST_CONTRACT, data, successRedirect })
  }

  const _handleApprovalNumberDelete = async () => {
    if (contractID) {
      const onSuccess = () => {
        fetchAvailableApprovalNumbers()
        setCasaReadOnly(false)
        elemRef.current.scrollIntoView()
      }
      dispatch({ type: DELETE_CASA_APPROVAL_NUMBER, id: contractID, onSuccess })
    }
  }

  const _redirectToRenew = () => {
    navigate(`/contracts/edit/${contractID}/renew`)
  }
  // const _redirectToCancel = () => {
  //   console.log('redirect')
  // }

  const _renderStatusTag = () => {
    if (data && data.status != null) {
      let status, color
      for (const key in CONTRACT_STATUS) {
        const element = CONTRACT_STATUS[key]
        if (data.status === element.value) {
          status = `contracts:status.${key}`
          color = element.color
        }
      }
      return (
        <StatusStyle label={status} color={color}/>
      )
    }
    return null
  }

  const _renderRenewButton = () => {
    if (data && data.status === CONTRACT_STATUS.RESIDING.value) {
      return (
        <RoleControlled authorized="custom.cloudsign">
          <Row mb="R">
            <PrimaryButton label="contracts:status_panel.renewal.ask_renewal" handleClick={_redirectToRenew}/>
          </Row>
        </RoleControlled>
      )
    }
    return null
  }

  return (
    <Form
      id="updateAppUserForm"
      handleSubmit={handleFormSubmit}
      validationSchema={contractUpdateValidation}
      loadingSelector={selectContractLoading}
      postingSelector={selectContractPosting}
      initialData={initialValues}
      authorized={
        !authorizedStatuses.includes(data?.status)
          ? false
          : 'contracts.functions.update'
      }
      // ignoreDirty
      // debug
    >
      <span id="focus_ref" ref={elemRef}></span>
      <Row justify="between">
        <Row align="baseline" wrap={'wrap'}>
          {
            contractID
              ? (
                <Title
                  text={t('contracts:contract_details', { residentName })}
                />
              )
              : <Title label='contracts:add_contract' />
          }
          { _renderStatusTag() }
        </Row>
        <RoleControlled authorized="contracts.functions.update">
          <FormSubmitButton editMode={!!contractID} postingSelector={selectContractPosting}/>
        </RoleControlled>
      </Row>
      <Row mb="ML" wrap={'wrap'}>
        <InquiryID id={contractID} prefix={INQUIRY_PREFIX.CONTRACT} />
      </Row>

      <ProcessCard />

      <CasaCard
        casaApprovalNumbers={casaApprovalNumbers}
        readonly={casaReadOnly}
        onNumberDelete={_handleApprovalNumberDelete}
      />

      <Card mb="L" title="contracts:contract_info">
        <Row wrap="wrap" align="baseline" justify="start">
          <ContractProperty />
          <ContractRoom />
          <TextInput
            flex={1}
            size="M"
            type="text"
            name="contract_manage_id"
            label='contracts:contract_manage_id'
            readonly={pages?.custom?.datasync}
            placeholder=""
          />
          <RoleControlled authorized='!custom.house_maker'>
            <DatePicker
              flex={1}
              size="M"
              name="contract_sign_date"
              label='contracts:contract_sign_date'
              placeholder=""
            />
          </RoleControlled>
        </Row>
        <Row wrap="wrap" align="baseline">
          <RoleControlled authorized='!custom.house_maker'>
            <TextInput
              flex={1}
              size="XS"
              mr="XS"
              type="number"
              name="contract_duration"
              label='contracts:contract_duration'
              placeholder=""
            />
            <Select
              size="fluid"
              maxW={80}
              name="contract_duration_unit"
              placeholder=""
              options={CONTRACT_DURATION_OPTIONS}
              defaultValue={1}
              noClear
            />
          </RoleControlled>
          <ContractDates />
          <MovingInDate />
        </Row>
        <RoleControlled authorized='!custom.house_maker'>
          <RenewingDates />
        </RoleControlled>
        <RoleControlled authorized='!custom.house_maker'>
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              type="text"
              name="about_contract"
              label='contracts:about_contract'
              placeholder="contracts:placeholders.about_contract"
              help=""
            />
          </Row>
        </RoleControlled>
        <Row wrap="wrap" align="baseline">
          <RepeaterFields
            propertyKey="custom_fields"
            uniqField="label"
            field={(index) => <CustomFieldRow key={index} index={index}/>}
          />
        </Row>
        <RepeaterAddButton
          label="contracts:custom_fields.add_custom_fields"
          propertyKey="custom_fields"
          uniqField="label"
          fields={['label', 'value']}
          help="contracts:help.custom_fields"
        />
      </Card>

      <RoleControlled authorized='custom.anabuki'>
        <Card mb="L" title="contracts:anabuki.premium.long">
          <DatePicker
            flex={1}
            size="M"
            name="premium_start_date"
            label='contracts:anabuki.premium_start_date'
            placeholder=""
            // disabledDate={disableNextDate}
          />
        </Card>
      </RoleControlled>

      <RoleControlled authorized='!custom.house_maker'>
        <Card mb="L" title="contracts:landlord_info">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              size="M"
              type="text"
              name="landlord_name"
              label='contracts:landlord_name'
            />
          </Row>
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              type="text"
              name="landlord_address"
              label='contracts:landlord_address'
              help=""
            />
          </Row>
        </Card>

        <Card mb="L" title="contracts:car_info">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              size="M"
              type="text"
              name="car_model_1"
              label='contracts:car_model_1'
              placeholder=""
              help="contracts:help.car_model"
            />
            <TextInput
              flex={1}
              size="M"
              type="text"
              name="car_plate_number_1"
              label='contracts:plate_number_1'
              placeholder=""
              help="contracts:help.plate_number"
            />
            <TextInput
              flex={1}
              size="M"
              type="text"
              name="car_model_2"
              label='contracts:car_model_2'
              placeholder=""
            />
            <TextInput
              flex={1}
              size="M"
              type="text"
              name="car_plate_number_2"
              label='contracts:plate_number_2'
              placeholder=""
            />
          </Row>
        </Card>

        <Card mb="L" title="contracts:wifi_info">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              type="text"
              name="wifi_ssid"
              label='contracts:wifi_ssid'
              placeholder=""
            />
            <TextInput
              flex={1}
              type="text"
              name="wifi_password"
              label='contracts:wifi_password'
              placeholder=""
            />
          </Row>
        </Card>

        <Card mb="L" title="contracts:fees_info">
          <ContractFees />
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              size="M"
              type="number"
              name="landlord_fee"
              label='contracts:landlord_fee'
              suffix="currency_symbol"
            />
            <TextInput
              flex={1}
              size="M"
              type="number"
              name="caution_money"
              label='contracts:caution_money'
              suffix="currency_symbol"
            />
            <TextInput
              flex={1}
              size="M"
              type="number"
              name="deposit"
              label='contracts:deposit'
              suffix="currency_symbol"
            />
          </Row>
        </Card>

        <Card mb="L" title="contracts:renewal_fees_info">
          { _renderRenewButton() }
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              size="M"
              type="number"
              name="renewal_fees"
              label='contracts:renewal_fees'
              suffix="currency_symbol"
            />
            <TextInput
              flex={1}
              size="M"
              type="number"
              name="renewal_administrative_fees"
              label='contracts:renewal_administrative_fees'
              suffix="currency_symbol"
            />
          </Row>
        </Card>

        <Card mb="L" title="contracts:bank_info">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              size="M"
              type="text"
              name="rent_date"
              label='contracts:rent_date'
              placeholder="contracts:placeholders.rent_date"
            />
            <TextInput
              flex={1}
              type="text"
              name="rent_contract_account"
              label='contracts:rent_contract_account'
              placeholder="placeholders.bank_account"
            />
          </Row>
        </Card>

        <Card mb="L" title="contracts:cancel_info">
          <MovingOutDate />
        </Card>

        <NoticeCard />
      </RoleControlled>

      <ContractorCard />

      <ResidentCard />

      <RoommatesCard />

      <GuarantorCard />

      <InvitedUsers />

      <Card title='memo'>
        <TextArea
          name="memo"
          placeholder="placeholders.memo"
        />
      </Card>

    </Form>
  )
}
export default ContractForm

ContractForm.propTypes = {
  data: PropTypes.object,
  contractID: PropTypes.string
}
ContractForm.defaultProps = {

}
