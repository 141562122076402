import React from 'react'
import PropTypes from 'prop-types'

import { i18n } from '../locales'

export const EmptyIcon = () => null
export const Description = ({ description }) => {
  return <p style={{ whiteSpace: 'pre-wrap' }}>{i18n.t(description)}</p>
}

Description.propTypes = {
  description: PropTypes.string.isRequired
}
