import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import {
  Form,
  Card,
  Row,
  FormSubmitButton,
  TextArea,
  InquiryID,
  Text,
  RoleControlled,
  Title,
  TextInput,
  Copy,
  DropdownButton,
  Confirm,
  DeleteButtonStyle,
  useTranslation,
  Tag,
  PrimaryButton,
  LightText,
  selectPages
} from '@gk-devteam/apmc-core-web'

import {
  selectContractHistoryCount,
  selectContractHistoryData,
  selectLoadingAppUser,
  selectPostingAppUser
} from '../../selectors'
import { appUserUpdateValidation } from '../../validation'
import {
  // FETCH_APP_USER,
  POST_APP_USER,
  SEND_APP_USER_LOGIN,
  RESET_USER_LOGIN
} from '../../types'
import { INQUIRY_PREFIX, LOGIN_STATUS } from '../../constants'

import { UserLoginStyle, WaitingApprovalContainerStyle, ButtonsGroupStyle } from './AppUserForm.style.js'
import QrModal from '../../pages/app_users/common/QrModal'
import { downloadLoginPDF } from '../../services'

const YasueAppUserForm = ({ data }) => {
  const { t } = useTranslation()
  const contractsCount = useSelector(selectContractHistoryCount)
  const pages = useSelector(selectPages)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userID = data && data.id
  const [qrVisible, setQrVisible] = useState(false)
  const contractsHistory = useSelector(selectContractHistoryData)

  const initialValues = {
    ...data,
    birthday: data?.birthday || null
  }

  const handleFormSubmit = (data) => {
    const successRedirect = (id) => {
      // if (userID) {
      //   dispatch({ type: FETCH_APP_USER, id: userID })
      // } else {
      //   navigate(`/app_users/edit/${id}`)
      // }
      navigate('/app_users')
    }
    dispatch({ type: POST_APP_USER, data, userID, successRedirect })
  }

  const showQR = () => {
    setQrVisible(true)
  }
  const closeModal = () => {
    setQrVisible(false)
  }

  const _handleResetLogin = () => {
    dispatch({ type: RESET_USER_LOGIN, id: userID, origin: 'details' })
  }

  const _renderUserStatus = () => {
    let label, color
    switch (data?.login_status) {
      case LOGIN_STATUS.NO_LOGIN:
        label = 'app_users:login_status.no_login'
        color = 'text'
        break
      case LOGIN_STATUS.ACTIVE:
        label = 'app_users:login_status.active'
        color = 'info'
        break
      case LOGIN_STATUS.INACTIVE:
        label = 'app_users:login_status.inactive'
        color = 'pageTitle'
        break
      case LOGIN_STATUS.NO_CONFIRMATION:
        label = 'app_users:login_status.no_confirmation'
        color = 'accent'
        break

      default:
        break
    }

    return <Tag label={label} color={color}/>
  }

  return (
    <>
      <QrModal
        id={userID}
        visible={qrVisible}
        close={closeModal}
      />
      <Form
        id="updateAppUserForm"
        handleSubmit={handleFormSubmit}
        validationSchema={appUserUpdateValidation}
        loadingSelector={selectLoadingAppUser}
        postingSelector={selectPostingAppUser}
        initialData={initialValues}
        authorized="app_users.functions.update"
      // debug
      >
        {
          data && !data.approved_user
            ? (
              <WaitingApprovalContainerStyle mt="ML" mb="R" align="center">
                <ExclamationCircleOutlined />
                <Text label="contracts:waiting_user_description" />
              </WaitingApprovalContainerStyle>
            )
            : null
        }
        <Row justify="between" >
          <div>
            <Title text={data?.last_name ? `${data.last_name} ${data.first_name || ''}` : ''} />
          </div>
          <ButtonsGroupStyle>
            <RoleControlled authorized={data?.login_status === LOGIN_STATUS.NO_CONFIRMATION && 'app_users.functions.add'}>
              <Confirm
                title='app_users:reset_user_login_question'
                placement="topRight"
                onConfirm={_handleResetLogin}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                <DeleteButtonStyle>{t('app_users:reset_user_login')}</DeleteButtonStyle>
              </Confirm>
            </RoleControlled>
            <RoleControlled authorized={contractsCount > 0 && data?.login_status === LOGIN_STATUS.NO_LOGIN && 'app_users.functions.update'}>
              <DropdownButton
                label="app_users:temp_login.label"
                color="accent"
                withMargin
                items={[
                  {
                    label: 'app_users:temp_login.sms',
                    disabled: !data?.can_send_sms,
                    handleClick: () => { dispatch({ type: SEND_APP_USER_LOGIN, id: userID, sendType: 1, origin: 'details' }) }
                  },
                  {
                    label: 'app_users:temp_login.email',
                    disabled: !data?.can_send_email,
                    handleClick: () => { dispatch({ type: SEND_APP_USER_LOGIN, id: userID, sendType: 2, origin: 'details' }) }
                  },
                  {
                    label: 'app_users:temp_login.qr',
                    handleClick: showQR
                  },
                  {
                    label: 'app_users:temp_login.download_qr',
                    handleClick: () => { downloadLoginPDF(userID) }
                  }
                ]}
              />
            </RoleControlled>
            <PrimaryButton
              label="yasue:contracts.add_construction_work"
              handleClick={() => {
                navigate('/yasue/construction_works/add', {
                  state: { contract: contractsHistory?.[0], redirect: `/app_users/edit/${userID}` }
                })
              }}
              withMargin
            />
            <RoleControlled authorized="app_users.functions.update">
              <FormSubmitButton editMode disabled={data && !data.approved_user} withMargin />
            </RoleControlled>
          </ButtonsGroupStyle>
        </Row>
        <Row mb="XS">
          { _renderUserStatus() }
        </Row>
        <Row mb={pages?.custom?.yasue ? '0' : 'ML'}>
          <InquiryID id={userID} prefix={INQUIRY_PREFIX.USER} />
          {
            data && data.login_email ? (
              <>
                <UserLoginStyle label={data.login_email} color="info" />
                <Copy
                  dataToCopy={data.login_email}
                  copyTooltip="app_users:copy_login_email"
                />
              </>
            ) : null
          }
        </Row>
        <RoleControlled authorized='custom.yasue'>
          <Row mb="ML">
            {
            contractsHistory?.[0] ? (
              <>
                <LightText text={`${t('yasue:contracts.contract_manage_id')}：${contractsHistory?.[0].property_name}`} color="info" />
                <Copy
                  dataToCopy={contractsHistory?.[0].property_name}
                  copyTooltip="yasue:app_users.copy_contract_manage_id"
                />
              </>
            ) : null
            }
          </Row>
        </RoleControlled>
        <Card mb="L">
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              type="text"
              name="last_name"
              label='app_users:first_name'
              placeholder=""
              required
              readonly={data && !data.approved_user}
            />
            <TextInput
              flex={1}
              type="text"
              name="furigana_last_name"
              label='yasue:app_users.furigana_first_name'
              placeholder=""
              readonly={data && !data.approved_user}
            />
          </Row>
          <Row wrap="wrap" align="baseline">
            <TextInput
              flex={1}
              size="L"
              type="text"
              name='phone'
              label='phone'
              placeholder="placeholders.phone"
              readonly={data && !data.approved_user}
            />
            <TextInput
              flex={1}
              size="L"
              type="text"
              name='emergency_phone'
              label='emergency_phone'
              readonly={data && !data.approved_user}
            />
            <TextInput
              flex={5}
              type="text"
              name="mail_address"
              label='email'
              readonly={data && !data.approved_user}
              placeholder="placeholders.email"
            />
          </Row>
        </Card>
        <Card title='memo'>
          <TextArea
            name="memo"
            placeholder="placeholders.memo"
            readonly={data && !data.approved_user}
            maxLength={1000}
          />
        </Card>
      </Form>
    </>
  )
}
export default YasueAppUserForm

YasueAppUserForm.propTypes = {
  data: PropTypes.object
}
YasueAppUserForm.defaultProps = {

}
