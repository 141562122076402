export const FETCH_PROPERTY_DOCUMENTS = 'fetch_property_documents'
export const FETCH_PROPERTY_DOCUMENTS_SUCCESS = 'fetch_property_documents_success'
export const FETCH_PROPERTY_DOCUMENTS_FAIL = 'fetch_property_documents_fail'
export const RESET_PROPERTY_DOCUMENTS = 'reset_property_documents'

export const DELETE_PROPERTY_DOCUMENT = 'delete_property_document'
export const DELETE_PROPERTY_DOCUMENT_SUCCESS = 'delete_property_document_success'
export const DELETE_PROPERTY_DOCUMENT_FAIL = 'delete_property_document_fail'

export const POST_PROPERTY_DOCUMENT = 'post_property_document'
export const POST_PROPERTY_DOCUMENT_SUCCESS = 'post_property_document_success'
export const POST_PROPERTY_DOCUMENT_FAIL = 'post_property_document_fail'

export const TOGGLE_ADD_PROPERTY_DOCUMENT_MODAL = 'toggle_add_property_document_modal'
export const TOGGLE_VIEW_PROPERTY_DOCUMENT_MODAL = 'toggle_view_property_document_modal'
export const SET_ACTIVE_PROPERTY_DOCUMENT = 'set_active_property_document'
