import React, { memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Document,
  Page,
  useTranslation,
  getFileExtension,
  Icon,
  UPDATE_FORM,
  Text
} from '@gk-devteam/apmc-core-web'

import { PDF_CMAP_URL } from '../../constants'
import {
  CalendarContainerStyle,
  CheckmarkStyle,
  ImageStyle,
  LinkPreviewStyle,
  CalendarLinkStyle
} from './refuse.style'

const CalendarItem = ({ item }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const id = useSelector(state => state.form && state.form.id)
  const {
    url,
    isSelected
  } = item

  const onDocumentLoadSuccess = () => {
    console.log('calendar loaded')
  }

  const _handleSelect = () => {
    if (!isSelected) {
      dispatch({ type: UPDATE_FORM, payload: { id: item.id } })
    }
  }

  const _renderPDFPreview = () => {
    return (
      <Document
        file={url}
        loading={t('pdf_load')}
        error={t('pdf_load_fail')}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        options={{
          cMapUrl: PDF_CMAP_URL,
          cMapPacked: true
        }}
      >
        <Page
          pageNumber={1}
          height={146} // 150 - 2px * 2 of border
          renderAnnotationLayer={false}
        />
      </Document>
    )
  }
  const _renderImagePreview = () => {
    return (
      <ImageStyle src={url} cover full />
    )
  }
  const _renderLinkPreview = () => {
    return (
      <LinkPreviewStyle>
        <Icon name="file" />
      </LinkPreviewStyle>
    )
  }
  const _renderPreview = () => {
    const extension = getFileExtension(url)
    // console.log('extension', extension)
    switch (extension) {
      case 'pdf':
        return _renderPDFPreview()
      case 'jpeg':
      case 'jpg':
      case 'png':
        return _renderImagePreview()
      default:
        return _renderLinkPreview()
    }
  }

  return (
    <div>
      <CalendarContainerStyle selected={isSelected} onClick={_handleSelect} isFormSelected={id === item.id}>
        { _renderPreview() }
        { isSelected || id === item.id ? <CheckmarkStyle checked={true} isFormSelected={id === item.id} /> : null }
      </CalendarContainerStyle>
      <CalendarLinkStyle href={url} target="_blank" rel="noopener noreferrer">
        <Text label="check_separate_tab" />
      </CalendarLinkStyle>
    </div>
  )
}

export default memo(CalendarItem)

CalendarItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired
  }).isRequired
}
