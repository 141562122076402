import { createSelector } from 'reselect'

const selectConstructionWorkFiles = state => state.yasueConsttuctionWorkFiles

export const selectFileViewModalState = createSelector(
  selectConstructionWorkFiles,
  files => files.viewFileModal
)
export const selectFileAddModalState = createSelector(
  selectConstructionWorkFiles,
  files => files.addFileModal
)
export const selectFilePosting = createSelector(
  selectConstructionWorkFiles,
  files => files.posting
)
