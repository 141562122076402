import { createReducer } from '@reduxjs/toolkit'

import {
  SUBSCRIBE_CHECKLISTS,
  SUBSCRIBE_CHECKLISTS_SUCCESS,
  UNSUBSCRIBE_CHECKLISTS,
  FETCH_CHECKLIST_HOME,
  FETCH_CHECKLIST_HOME_SUCCESS,
  FETCH_CHECKLIST_HOME_FAIL,
  FETCH_CHECKLIST,
  FETCH_CHECKLIST_SUCCESS,
  FETCH_CHECKLIST_FAIL,
  FETCH_CHECKLIST_ISSUE,
  FETCH_CHECKLIST_ISSUE_SUCCESS,
  FETCH_CHECKLIST_ISSUE_FAIL,
  POST_CHECKLIST_ISSUE_COMMENT,
  POST_CHECKLIST_ISSUE_COMMENT_SUCCESS,
  POST_CHECKLIST_ISSUE_COMMENT_FAIL,
  UPDATE_CHECKLIST_ISSUE_STATUS,
  UPDATE_CHECKLIST_ISSUE_STATUS_SUCCESS,
  UPDATE_CHECKLIST_ISSUE_STATUS_FAIL,
  UPDATE_CHECKLIST_ISSUES_STATUS,
  UPDATE_CHECKLIST_ISSUES_STATUS_SUCCESS,
  UPDATE_CHECKLIST_ISSUES_STATUS_FAIL,
  RESET_CHECKLIST,
  RESET_CHECKLIST_ISSUE
} from '../../types'

const INITIAL_STATE = {
  loadingSubscription: true,
  loading: true,
  posting: false,
  error: null,
  hiddenBy: null,
  hiddenById: null,
  categories: null,
  unresolved_count: null,
  data: null,
  max_pages: null,
  results_count: null,
  issue: null,
  checklists: null,
  statuses: null
}

export default createReducer(INITIAL_STATE, {
  [SUBSCRIBE_CHECKLISTS]: (state, action) => {
    state.loadingSubscription = true
  },
  [SUBSCRIBE_CHECKLISTS_SUCCESS]: (state, action) => {
    state.loadingSubscription = false
    state.unresolved_count = action.payload.count
    state.checklists = action.payload.checklists
    state.statuses = action.payload.statuses
  },
  [UNSUBSCRIBE_CHECKLISTS]: (state, action) => {
    state.loadingSubscription = true
    state.unresolved_count = null
    state.checklists = null
    state.statuses = null
  },
  [FETCH_CHECKLIST_HOME]: (state, action) => {
    state.loading = true
  },
  [FETCH_CHECKLIST_HOME_SUCCESS]: (state, action) => {
    state.loading = false
    state.hiddenBy = action.payload.hiddenBy
    state.hiddenById = action.payload.hiddenById
    state.data = action.payload.results
  },
  [FETCH_CHECKLIST_HOME_FAIL]: (state, action) => {
    state.loading = false
  },
  [FETCH_CHECKLIST]: (state, action) => {
    state.loading = true
  },
  [FETCH_CHECKLIST_SUCCESS]: (state, action) => {
    state.loading = false
    state.hiddenBy = action.payload.hiddenBy
    state.hiddenById = action.payload.hiddenById
    state.categories = action.payload.checklist_categories
    state.data = action.payload.results
    state.max_pages = action.payload.max_pages
    state.results_count = action.payload.results_count
  },
  [FETCH_CHECKLIST_FAIL]: (state, action) => {
    state.loading = false
  },
  [FETCH_CHECKLIST_ISSUE]: (state, action) => {
    state.loading = true
  },
  [FETCH_CHECKLIST_ISSUE_SUCCESS]: (state, action) => {
    state.loading = false
    state.issue = action.payload
  },
  [FETCH_CHECKLIST_ISSUE_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_CHECKLIST]: (state, action) => {
    state.hiddenBy = null
    state.hiddenById = null
    state.categories = null
    state.data = null
    state.max_pages = null
    state.results_count = null
  },
  [POST_CHECKLIST_ISSUE_COMMENT]: (state, action) => {
    state.posting = true
  },
  [POST_CHECKLIST_ISSUE_COMMENT_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CHECKLIST_ISSUE_COMMENT_FAIL]: (state, action) => {
    state.posting = false
  },
  [UPDATE_CHECKLIST_ISSUES_STATUS]: (state, action) => {
    state.posting = true
  },
  [UPDATE_CHECKLIST_ISSUES_STATUS_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [UPDATE_CHECKLIST_ISSUES_STATUS_FAIL]: (state, action) => {
    state.posting = false
  },
  [UPDATE_CHECKLIST_ISSUE_STATUS]: (state, action) => {
    state.loading = true
  },
  [UPDATE_CHECKLIST_ISSUE_STATUS_SUCCESS]: (state, action) => {
    state.loading = false
  },
  [UPDATE_CHECKLIST_ISSUE_STATUS_FAIL]: (state, action) => {
    state.loading = false
  },
  [RESET_CHECKLIST_ISSUE]: (state, action) => {
    state.issue = null
  }
})
