import { createSelector } from 'reselect'

export const selectUserProfile = state => state.userProfile

export const selectLoadingAdminUser = createSelector(
  selectUserProfile,
  userProfile => userProfile.loading
)

export const selectPostingAdminUser = createSelector(
  selectUserProfile,
  userProfile => userProfile.posting
)
export const selectAdminUserData = createSelector(
  selectUserProfile,
  userProfile => userProfile.data || {}
)
export const selectAdminUserID = createSelector(
  selectUserProfile,
  userProfile => userProfile.data && userProfile.data.id
)
export const selectAdminUserName = createSelector(
  selectUserProfile,
  userProfile => userProfile.data && userProfile.data.name
)
