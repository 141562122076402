import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  Card,
  Row,
  Title,
  Text,
  Tag,
  Link,
  ItemNotFound,
  PageLoader,
  SERVER_TIMESTAMP_FORMAT,
  selectUserRole,
  selectPages,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  selectChatRoom,
  selectChatHiddenBy,
  selectChatLoading,
  selectChatError,
  selectChatHiddenById,
  selectChatRoomLastEdited,
  selectChatRoomLastEditedBy
} from '../../../selectors'
import { FETCH_CHAT_STATUS } from '../../../types'
import { subscribeToChatRoom, unSubscribeFromChatRoom, updateChatRoomStatus } from '../../../actions'
import { ROLES, SUPPORT_STATUS, USER_EMPTY_NAME } from '../../../constants'

import ChatMessages from './ChatMessages'
import ChatMessageForm from './ChatMessageForm'

import {
  RoomHeaderStyle,
  RoomInfoLinkStyle,
  ChatMessagesContainerStyle
} from './AdminTasksChatRoomPage.style'
import { StatusRadioStyle, PanelDisabledStyle } from '../adminTasks.style'

const AdminTasksChatRoomPage = ({ chatID }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)
  const role = useSelector(selectUserRole)
  const room = useSelector(selectChatRoom)
  const hiddenBy = useSelector(selectChatHiddenBy)
  const loading = useSelector(selectChatLoading)
  const error = useSelector(selectChatError)
  const lastEdited = useSelector(selectChatRoomLastEdited)
  const lastEditedBy = useSelector(selectChatRoomLastEditedBy)
  const userID = chatID && chatID.replace(/contract:\d*_/, '')

  useEffect(() => {
    dispatch(subscribeToChatRoom(chatID))
    return () => {
      dispatch(unSubscribeFromChatRoom())
      // dispatch({ type: UNSUBSCRIBE_CHAT_ROOM })
    }
  }, [dispatch, chatID])

  useEffect(() => {
    if (room && room.contractID) {
      dispatch({ type: FETCH_CHAT_STATUS, params: { contract_id: room.contractID } })
    }
  }, [dispatch, room])

  const breadcrumbs = [
    {
      label: 'navigation:admin_tasks',
      link: '/admin_tasks'
    },
    {
      label: 'navigation:chat_room',
      link: `/admin_tasks/chat/${chatID}`
    }
  ]

  const _handleStatusChange = (managementStatus) => {
    dispatch(updateChatRoomStatus(chatID, { managementStatus }))
  }

  const _renderStatusTag = () => {
    if (!room) return null
    let label, color
    switch (room.managementStatus) {
      case SUPPORT_STATUS.UNDEFINED:
        label = 'admin_tasks:status.undefined'
        color = 'pageTitle'
        break
      case SUPPORT_STATUS.UNUSED:
        label = 'admin_tasks:status.is_unused'
        color = 'text'
        break
      case SUPPORT_STATUS.COMPLETED:
        label = 'admin_tasks:status.is_completed'
        color = 'success'
        break
      case SUPPORT_STATUS.CONFIRMED:
        label = 'admin_tasks:status.is_confirmed'
        color = 'info'
        break
      case SUPPORT_STATUS.WAITING:
        label = 'admin_tasks:status.is_waiting'
        color = 'danger'
        break
      default:
        break
    }
    return <Tag label={label} color={color} />
  }

  const _renderUserTag = (value, cellKey, rowKey, className, data) => {
    if (pages?.custom?.yasue) return null
    const label = room && room.isResident ? 'user_type.resident' : 'user_type.contractor'
    const color = room && room.isResident ? 'success' : 'info'
    return (
      <Tag label={label} color={color} />
    )
  }

  const _renderStatusToggle = () => {
    if (!room) return null
    return (
      <StatusRadioStyle
        name="checklist_status"
        handleChange={_handleStatusChange}
        value={room.managementStatus}
        options={[
          {
            label: 'admin_tasks:status.is_waiting',
            value: SUPPORT_STATUS.WAITING
          },
          {
            label: 'admin_tasks:status.is_confirmed',
            value: SUPPORT_STATUS.CONFIRMED
          },
          {
            label: 'admin_tasks:status.is_completed',
            value: SUPPORT_STATUS.COMPLETED
          }
        ]}
        disabled={hiddenBy}
      />
    )
  }

  if (loading) return <PageLoader />

  if (error && error.code === 404) return <ItemNotFound item={t('navigation:chat_room')} link="/admin_tasks/chat" />

  if (room) {
    return (
      <PageLayout
        breadcrumbs={breadcrumbs}
        lastEdited={lastEdited && dayjs.unix(lastEdited).format(SERVER_TIMESTAMP_FORMAT)}
        lastEditedBy={lastEditedBy}
      >
        <PanelDisabledStyle
          hiddenSelector={selectChatHiddenBy}
          hiddenByIdSelector={selectChatHiddenById}
          // propertyID={room?.propertyID}
          // contractID={room?.contractID}
        >
          <RoomHeaderStyle>
            <Row justify="between">
              <Title text={`${room?.userName ? room?.userName.replace(/ null$/, '') : USER_EMPTY_NAME} ${t('navigation:chat_room')}`} />
              { _renderStatusToggle() }
            </Row>
            <Row mt="XS" justify="start">
              <RoomInfoLinkStyle>
                {
                  pages?.custom?.yasue
                    ? (
                      <>
                        <Text label="yasue:contracts.contract_manage_id" />：
                        <Link to={`/contracts/edit/${(room?.contractID) || ''}`}>{(room?.propertyName)}</Link>
                      </>
                    )
                    : (
                      <>
                        <Text label = "properties:property_name" /><span>：</span>
                        {
                          role !== ROLES.SUPPORT
                            ? <Link to={`/properties/edit/${(room?.propertyID) || ''}`}>{(room?.propertyName) || ''}</Link>
                            : <Text text={room?.propertyName} />
                        }
                      </>
                    )
                }
              </RoomInfoLinkStyle>
              <RoleControlled authorized="!custom.yasue">
                <RoomInfoLinkStyle>
                  <Text label="properties:room_number"/>
                      ：
                  {
                    role !== ROLES.SUPPORT
                      ? <Link to={`/properties/edit/${(room?.propertyID) || ''}/rooms`}>{room?.roomNumber}</Link>
                      : <Text text={room?.roomNumber}/>
                  }
                </RoomInfoLinkStyle>
                <RoomInfoLinkStyle>
                  <Text label="contracts:contract_id"/>
                    ：
                  <Link to={
                    role !== ROLES.SUPPORT
                      ? `/contracts/edit/${(room?.contractID) || ''}`
                      : `/support/${(room?.contractID) || ''}`
                  }>{(room?.contractID) || ''}</Link>
                </RoomInfoLinkStyle>
                <RoomInfoLinkStyle>
                  <Text label="app_users:app_user_id"/>
                    ：
                  {
                    role !== ROLES.SUPPORT
                      ? <Link to={`/app_users/edit/${userID}`}>{userID}</Link>
                      : <Text text={userID}/>
                  }
                </RoomInfoLinkStyle>
              </RoleControlled>
            </Row>
            <Row mt="XS">
              { _renderUserTag() }
              { _renderStatusTag() }
            </Row>
          </RoomHeaderStyle>
          <Card mt="R">
            <ChatMessagesContainerStyle>
              <ChatMessages />
            </ChatMessagesContainerStyle>
            <ChatMessageForm chatID={chatID} />
          </Card>
        </PanelDisabledStyle>
      </PageLayout>
    )
  }

  return null
}
export default AdminTasksChatRoomPage

AdminTasksChatRoomPage.propTypes = {
  chatID: PropTypes.string.isRequired
}
AdminTasksChatRoomPage.defaultProps = {

}
