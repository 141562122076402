import { createReducer } from '@reduxjs/toolkit'

import {
  SUBSCRIBE_APP,
  SUBSCRIBE_APP_SUCCESS,
  SUBSCRIBE_APP_FAIL,
  UNSUBSCRIBE_APP
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  forceLogout: null,
  forceRefreshUser: null,
  version: null,
  maintenance: null
}

export default createReducer(INITIAL_STATE, {
  [SUBSCRIBE_APP]: (state, action) => {
    state.loading = true
  },
  [SUBSCRIBE_APP_SUCCESS]: (state, action) => {
    const {
      forceLogout,
      forceRefreshUser,
      version,
      maintenance
    } = action.payload
    state.loading = false
    state.forceLogout = forceLogout
    state.forceRefreshUser = forceRefreshUser
    state.version = version
    state.maintenance = maintenance
  },
  [SUBSCRIBE_APP_FAIL]: (state, action) => {
    state.loading = false
  },
  [UNSUBSCRIBE_APP]: () => INITIAL_STATE
})
