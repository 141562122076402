export const FETCH_PUBLIC_FACILITIES = 'fetch_public_facilities'
export const FETCH_PUBLIC_FACILITIES_SUCCESS = 'fetch_public_facilities_success'
export const FETCH_PUBLIC_FACILITIES_FAIL = 'fetch_public_facilities_fail'

export const POST_PUBLIC_FACILITY = 'post_public_facility'
export const POST_PUBLIC_FACILITY_SUCCESS = 'post_public_facility_success'
export const POST_PUBLIC_FACILITY_FAIL = 'post_public_facility_fail'

export const DELETE_PUBLIC_FACILITY = 'delete_public_facility'
export const DELETE_PUBLIC_FACILITY_SUCCESS = 'delete_public_facility_success'
export const DELETE_PUBLIC_FACILITY_FAIL = 'delete_public_facility_fail'

export const TOGGLE_PUBLIC_FACILITY = 'toggle_public_facility'
export const TOGGLE_PUBLIC_FACILITY_SUCCESS = 'toggle_public_facility_success'
export const TOGGLE_PUBLIC_FACILITY_FAIL = 'toggle_public_facility_fail'

export const TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA = 'toggle_additional_data'
export const TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA_SUCCESS = 'toggle_additional_data_success'
export const TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA_FAIL = 'toggle_additional_data_fail'

export const RESET_PUBLIC_FACILITIES = 'reset_public_facilities'
