import React, { useEffect, useRef, useState } from 'react'
import { SPACING, COLORS, useTranslation, ToastTitle, Text } from '@gk-devteam/apmc-core-web'
import { Row, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import { ConfirmTableContainerStyle, ErrorCardStyle, ManageIdCell, TableStyle } from './BillingConfirmPage.style'

function ConfirmPage ({ data }) {
  const { t } = useTranslation()
  const tableRef = useRef()
  const [width, setWidth] = useState(0)
  const [pageSize, setPageSize] = useState(100)

  const _handleResizing = () => {
    const main = document.getElementById('main')
    const table = tableRef && tableRef.current
    if (table) {
      const pageWidth = document.documentElement.clientWidth
      // console.log('pageWidth', pageWidth)
      // const pageHeight = document.documentElement.clientHeight
      const parentEl = main.parentElement
      const parentStyles = window.getComputedStyle(parentEl)
      const parentPaddingLeft = parseFloat(parentStyles.getPropertyValue('padding-left'))
      // console.log('parentPaddingLeft', parentPaddingLeft)
      const parentPaddingRight = parseFloat(parentStyles.getPropertyValue('padding-right'))
      // console.log('parentPaddingRight', parentPaddingRight)
      // const parentPaddingBottom = parseFloat(parentStyles.getPropertyValue('padding-bottom'))

      // const { top } = table.getBoundingClientRect()

      setWidth(pageWidth - parentPaddingLeft - parentPaddingRight)
      // console.log('set width', pageWidth - parentPaddingLeft - parentPaddingRight)
      // setHeight(pageHeight - top - parentPaddingBottom)
    }
  }

  useEffect(() => {
    _handleResizing()
    window.addEventListener('resize', _handleResizing, false)
    return () => {
      window.removeEventListener('resize', _handleResizing, false)
    }
  }, [data])

  const _handlePageSizeChange = (current, size) => {
    setPageSize(size)
  }
  const _handlePageChange = (page, pageSize) => {
    window.scroll(0, 0)
  }

  const _renderErrorMessage = () => {
    const count = data?.filter(row => row.unknown_user).length
    if (!count) return null
    return (
      <ErrorCardStyle mt="ML" align="baseline">
        <ExclamationCircleOutlined />
        <div>
          <ToastTitle label="import:validation_error" />
          <Row mt="XS" wrap="wrap">
            <Text text={t('chouei:validation_error_message', { count })} />
          </Row>
        </div>
      </ErrorCardStyle>
    )
  }

  if (!data || data.length === 0) return null

  const columns = Object.keys(data[0])
    .filter(header => {
      return header !== 'unknown_user'
    })
    .map(key => {
      if (key === 'app_user_manage_id') {
        return {
          title: t(`chouei:billing_headers.${key}`),
          dataIndex: key,
          // eslint-disable-next-line react/display-name
          render: (manage_id, row) => (
            <ManageIdCell isUnknown={row.unknown_user}>
              { row.unknown_user && (
                <Tooltip
                  title={t('chouei:unknown_user')}
                  color={COLORS.danger}
                  overlayStyle={{
                    fontSize: `${SPACING.SMALL}rem`
                  }}
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              )}
              {manage_id}
            </ManageIdCell>
          )
        }
      }
      return {
        title: t(`chouei:billing_headers.${key}`),
        dataIndex: key
      }
    })

  return (
    <ConfirmTableContainerStyle ref={tableRef} width={width}>
      {_renderErrorMessage()}
      <TableStyle
        rowKey='contract_manage_id'
        rowClassName={(record) => record.unknown_user ? 'table-row-error' : ''}
        dataSource={data}
        columns={columns}
        pagination={{ pageSize, onShowSizeChange: _handlePageSizeChange, onChange: _handlePageChange, pageSizeOptions: [20, 50, 100, 250, 500] }}
        size="small"
        scroll={{ x: 'max-content' }}
      />
    </ConfirmTableContainerStyle>
  )
}

ConfirmPage.propTypes = {
  data: PropTypes.array.isRequired
}

export default ConfirmPage
