export const FETCH_ROOMS = 'fetch_rooms'
export const FETCH_ROOMS_SUCCESS = 'fetch_rooms_success'
export const FETCH_ROOMS_FAIL = 'fetch_rooms_fail'
export const RESET_ROOMS = 'reset_rooms'

export const FETCH_ROOM = 'fetch_room'
export const FETCH_ROOM_SUCCESS = 'fetch_room_success'
export const FETCH_ROOM_FAIL = 'fetch_room_fail'
export const RESET_ROOM = 'reset_room'

export const DELETE_ROOM = 'delete_room'
export const DELETE_ROOM_SUCCESS = 'delete_room_success'
export const DELETE_ROOM_FAIL = 'delete_room_fail'

export const POST_ROOM = 'post_room'
export const POST_ROOM_SUCCESS = 'post_room_success'
export const POST_ROOM_FAIL = 'post_room_fail'
