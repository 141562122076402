import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import {
  PageLayout,
  Title,
  Row,
  RoleControlled,
  selectPages
} from '@gk-devteam/apmc-core-web'

import ChatCard from './ChatCard'
import ChecklistCard from './ChecklistCard'
import ContractsCard from './ContractsCard'
import SpaceReservationsCard from './SpaceReservationsCard'
import { GridStyle } from './AdminTasksPage.style'

const AdminTasksPage = () => {
  const pages = useSelector(selectPages)
  if (!pages) return null
  const breadcrumbs = [
    {
      label: 'navigation:admin_tasks',
      link: '/admin_tasks'
    }
  ]

  const {
    contracts,
    admin_tasks,
    custom
  } = pages

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      // loading={!data}
    >
      <Row>
        <Title label="navigation:admin_tasks"/>
      </Row>
      <GridStyle>
        <RoleControlled authorized={admin_tasks.enabled && admin_tasks.functions.chat}>
          <ChatCard />
        </RoleControlled>
        <RoleControlled authorized={admin_tasks.enabled && admin_tasks.functions.checklist}>
          <ChecklistCard />
        </RoleControlled>
        <RoleControlled authorized={custom?.cloudsign || contracts?.functions?.cancel}>
          <ContractsCard />
        </RoleControlled>
        <RoleControlled authorized={custom?.yahagi}>
          <SpaceReservationsCard />
        </RoleControlled>
      </GridStyle>

    </PageLayout>
  )
}
export default memo(AdminTasksPage)

AdminTasksPage.propTypes = {

}
AdminTasksPage.defaultProps = {

}
