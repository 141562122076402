import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchManuals,
  postManual,
  deleteManual,
  toggleManual,
  bulkToggleManuals
} from '../../services'
import {
  FETCH_MANUALS,
  FETCH_MANUALS_SUCCESS,
  FETCH_MANUALS_FAIL,
  POST_MANUAL,
  POST_MANUAL_SUCCESS,
  POST_MANUAL_FAIL,
  DELETE_MANUAL,
  DELETE_MANUAL_SUCCESS,
  DELETE_MANUAL_FAIL,
  TOGGLE_MANUAL,
  TOGGLE_MANUAL_SUCCESS,
  TOGGLE_MANUAL_FAIL,
  BULK_TOGGLE_MANUALS,
  BULK_TOGGLE_MANUALS_SUCCESS,
  BULK_TOGGLE_MANUALS_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchManualsSaga ({ config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchManuals, config, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_MANUALS_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_MANUALS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_MANUALS_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postManualSaga ({ data, config, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postManual, data, config, cancelSource)
    if (res && res.data && res.data.success) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_MANUAL_SUCCESS })
      yield put({ type: FETCH_MANUALS, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_MANUAL_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_MANUAL_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * toggleManualSaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(toggleManual, data, config, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: TOGGLE_MANUAL_SUCCESS })
      yield put({ type: FETCH_MANUALS, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: TOGGLE_MANUAL_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: TOGGLE_MANUAL_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * bulkToggleManualsSaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(bulkToggleManuals, data, config, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: BULK_TOGGLE_MANUALS_SUCCESS })
      yield put({ type: FETCH_MANUALS, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: BULK_TOGGLE_MANUALS_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: BULK_TOGGLE_MANUALS_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteManualSaga ({ id, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteManual, id, config, cancelSource)
    if (res) {
      yield put({ type: DELETE_MANUAL_SUCCESS })
      yield put({ type: FETCH_MANUALS, config })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_MANUAL_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchManuals () {
  yield takeLatest(FETCH_MANUALS, fetchManualsSaga)
  yield takeLatest(POST_MANUAL, postManualSaga)
  yield takeLatest(DELETE_MANUAL, deleteManualSaga)
  yield takeLatest(TOGGLE_MANUAL, toggleManualSaga)
  yield takeLatest(BULK_TOGGLE_MANUALS, bulkToggleManualsSaga)
}
