import { createSelector } from 'reselect'
import { SERVICES_PLAN } from '../../constants'

const selectServices = state => state.services
const selectSeikatsu = state => state.seikatsu

const parseDisplay = (data) => {
  const items = []
  for (const item of data) {
    if (item.parent_enable && item.plan !== SERVICES_PLAN.MANAGED) items.push(item.display)
  }

  if (items.every(item => item === 0 || item === false)) return 0 // all hidden
  if (items.every(item => item === 2 || item === true)) return 2 // all displayed
  return 1
}

export const selectServicesLoading = createSelector(
  selectServices,
  services => services.loading
)
export const selectServicesPosting = createSelector(
  selectServices,
  services => services.posting
)

export const selectInternalServices = createSelector(
  selectServices,
  services => services.internalServices
)
export const selectExternalServices = createSelector(
  selectServices,
  services => services.externalServices
)

export const selectServicesAllDisabled = createSelector(
  selectServices,
  services => services.items && services.items.every(item => !item.parent_enable)
)

export const selectServicesAllDisplayed = createSelector(
  selectServices,
  services => services.items ? parseDisplay(services.items) : 0
)

export const selectSeikatsuLoading = createSelector(
  selectSeikatsu,
  seikatsu => seikatsu.loading
)

export const selectSeikatsuData = createSelector(
  selectSeikatsu,
  seikatsu => seikatsu.items
)
