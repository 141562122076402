import { api } from '@gk-devteam/apmc-core-web'

export const fetchContacts = (config, cancelSource) => {
  const { property, contract } = config
  let path = 'admin'
  if (property) path = `properties/${property}`
  if (contract) path = `contracts/${contract}`

  return api.get(`${path}/contacts`, {
    cancelToken: cancelSource.token
  })
}

export const postContact = (data, config, cancelSource) => {
  const { property } = config
  let path = 'admin'
  if (property) path = `properties/${property}`

  return api.post(`${path}/contacts`, data, {
    cancelToken: cancelSource.token
  })
}

export const deleteContact = (id, config, cancelSource) => {
  const { property } = config
  let path = 'admin'
  if (property) path = `properties/${property}`

  return api.delete(`${path}/contacts/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const toggleContact = (data, config, cancelSource) => {
  const { property, contract, type } = config
  // type: admin_contacts, property_contacts
  let path = 'admin/contacts'
  if (property && type) path = `properties/${property}/${type}`
  if (contract && type) path = `contracts/${contract}/${type}`

  // console.log('path', path)
  // console.log('data', data)

  return api.post(`${path}/display`, data, {
    cancelToken: cancelSource.token
  })
}
export const bulkToggleContacts = (data, config, cancelSource) => {
  const { property, contract, type } = config
  // type: admin_contacts, property_contacts
  let path = 'admin/contacts'
  if (property && type) path = `properties/${property}/${type}`
  if (contract && type) path = `contracts/${contract}/${type}`

  // console.log('path', path)
  // console.log('data', data)

  return api.post(`${path}/bulk_display`, data, {
    cancelToken: cancelSource.token
  })
}
