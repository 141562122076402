import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const inspectionsSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    property_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('properties:property_id')),
    contract_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('contracts:contract_id')),
    contractor_name: yup.string().label(i18n.t('contracts:contractor_name')),
    contractor_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('contracts:contractor_id')),
    next_inspection_date_from: yup.date().nullable().label(i18n.t('inspections:next_inspection_date_from')),
    next_inspection_date_to: yup.date().nullable().label(i18n.t('inspections:next_inspection_date_to')),
    limit: yup.number().integer().label(i18n.t('limit')),
    page: yup.number().integer().label(i18n.t('page')),
    sort_by: yup.string().label(i18n.t('sort_by')),
    sort_order: yup.string().label(i18n.t('sort_order'))
  })
}
