import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  useTranslation,
  Table,
  Link
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import {
  DELETE_CONSTRUCTION_WORK,
  FETCH_APP_USER
} from '../../../types'
import {
  selectLoadingAppUser,
  selectConstructionWorkHistoryData
} from '../../../selectors'

const YasueConstructionWorkHistory = ({ userID }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const workCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link
        to={`/yasue/construction_works/edit/${data.id}`}
        state={{
          redirect: `/app_users/edit/${userID}`
        }}
      >
        {value || ''}
      </Link>
    },
    [userID]
  )
  const workHistoryColumns = [
    {
      title: t('yasue:construction.work_number'),
      dataIndex: 'work_number',
      key: 'work_number',
      cellTitle: true,
      render: workCell
    },
    {
      title: t('yasue:construction.title'),
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: t('yasue:construction.contract_date'),
      dataIndex: 'contract_date',
      key: 'contract_date'
    },
    {
      title: t('yasue:construction.delivery_date'),
      dataIndex: 'delivery_date',
      key: 'delivery_date'
    },
    {
      title: t('yasue:construction.completion_date'),
      dataIndex: 'completion_date',
      key: 'completion_date'
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            console.log('warning', id)
            dispatch({
              type: DELETE_CONSTRUCTION_WORK,
              id,
              onSuccess: () => {
                dispatch({ type: FETCH_APP_USER, id: userID })
              }
            })
            close()
          },
          shouldRender: 'app_users.functions.delete'
        }
      ]
    }
  ]

  return (
    <Table
      columns={workHistoryColumns}
      rowKey="id"
      loadingSelector={selectLoadingAppUser}
      dataSelector={selectConstructionWorkHistoryData}
      noLimit
      noResults
      noFooter
      leftStickyColumnCount={0}
      rightStickyColumnCount={0}
    />
  )
}

export default YasueConstructionWorkHistory

YasueConstructionWorkHistory.propTypes = {
  userID: PropTypes.string.isRequired
}
