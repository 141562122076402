/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Router } from '@reach/router'
import {
  ThemeProvider,
  useTranslation,
  ErrorBoundary,
  PageLoader
} from '@gk-devteam/apmc-core-web'

import {
  selectUser,
  selectTheme,
  selectPages,
  selectMaintenance,
  selectFirebaseLoading,
  selectAppVersion,
  selectCurrentStep
} from '../selectors'
import { IMPORT_STEPS, ROLES, SERVICES_LEVEL } from '../constants'

import PrivateRoute from './PrivateRoute'
import NavBar from '../components/NavBar'

import NotFoundPage from '../pages/NotFoundPage'
import LoginPage from '../pages/auth/LoginPage'
import MaintenancePage from '../pages/MaintenancePage'

import DashboardPage from '../pages/DashboardPage'
import ContractsPage from '../pages/contracts/ContractsPage'
import ContractsAddPage from '../pages/contracts/ContractsAddPage'
import ContractDetailsPage from '../pages/contracts/ContractDetailsPage'
import ContractRenewPage from '../pages/contracts/ContractRenewPage'
import ContractCancelPage from '../pages/contracts/ContractCancelPage'
import ContractDocumentsPage from '../pages/contracts/ContractDocumentsPage'
import ContractContactsPage from '../pages/panels/contacts/ContractContactsPage'
import ContractOpeloPage from '../pages/contracts/ContractOpeloPage'
import UsersPage from '../pages/app_users/UsersPage'
import UserDetailsPage from '../pages/app_users/UserDetailsPage'
import PropertiesPage from '../pages/properties/PropertiesPage'
import PropertiesAddPage from '../pages/properties/PropertiesAddPage'
import PropertyDetailsPage from '../pages/properties/PropertyDetailsPage'
import PropertyAdminTasksSettingsPage from '../pages/panels/adminTasks/PropertyAdminTasksSettingsPage'
import PropertyFaqsPage from '../pages/panels/faq/PropertyFaqsPage'
import PropertyManualsPage from '../pages/panels/manuals/PropertyManualsPage'
import PropertyVicinitiesPage from '../pages/panels/vicinities/PropertyVicinitiesPage'
import PropertyPublicFacilitiesPage from '../pages/panels/public_facilities/PropertyPublicFacilitiesPage'
import PropertyGuideCategoriesPage from '../pages/panels/guideCategories/PropertyGuideCategoriesPage'
import PropertyGuidesPage from '../pages/panels/guides/PropertyGuidesPage'
import PropertyRefusePage from '../pages/panels/refuse/PropertyRefusePage'
import PropertyContactsPage from '../pages/panels/contacts/PropertyContactsPage'
import PropertyDocumentsPage from '../pages/properties/PropertiesDocumentsPage'
import PropertyEventsPage from '../pages/properties/PropertyEventsPage'
import PropertySpacesPage from '../pages/properties/PropertySpacesPage'
import PropertySpaceAddPage from '../pages/properties/PropertySpaceAddPage'
import PropertySpaceDetailsPage from '../pages/properties/PropertySpaceDetailsPage'
import PropertySpaceReservationsPage from '../pages/properties/PropertySpaceReservationsPage'
import RoomsPage from '../pages/rooms/RoomsPage'
import RoomDetailsPage from '../pages/rooms/RoomDetailsPage'
import RoomFaqsPage from '../pages/panels/faq/RoomFaqsPage'
import RoomManualsPage from '../pages/panels/manuals/RoomManualsPage'
import RoomGuideCategoriesPage from '../pages/panels/guideCategories/RoomGuideCategoriesPage'
import RoomGuidesPage from '../pages/panels/guides/RoomGuidesPage'
import NoticesPage from '../pages/notices/NoticesPage'
import NoticesAddPage from '../pages/notices/NoticesAddPage'
import NoticeDetailsPage from '../pages/notices/NoticeDetailsPage'
import AdminTasksPage from '../pages/admin_tasks/AdminTasksPage'
import AdminTasksChatRoomsListPage from '../pages/admin_tasks/AdminTasksChatRoomsListPage'
import AdminTasksChatRoomPage from '../pages/admin_tasks/AdminTasksChatRoomPage'
import AdminTasksChecklistPage from '../pages/admin_tasks/AdminTasksChecklistPage'
import AdminTasksChecklistRoomPage from '../pages/admin_tasks/AdminTasksChecklistRoomPage'
import AdminTasksContractsPage from '../pages/admin_tasks/AdminTasksContractsPage'
import AdminTasksSpacesReservationsPage from '../pages/admin_tasks/AdminTasksSpacesReservationsPage'
import MembersPage from '../pages/user_management/MembersPage'
import MembersAddPage from '../pages/user_management/MembersAddPage'
import MemberDetailsPage from '../pages/user_management/MemberDetailsPage'
import PropertiesGroupsPage from '../pages/user_management/PropertiesGroupsPage'
import PropertiesGroupsAddPage from '../pages/user_management/PropertiesGroupsAddPage'
import PropertiesGroupDetailsPage from '../pages/user_management/PropertiesGroupDetailsPage'
import GuestsPage from '../pages/user_management/GuestsPage'
import GuestsAddPage from '../pages/user_management/GuestsAddPage'
import GuestDetailsPage from '../pages/user_management/GuestDetailsPage'
import UserProfilePage from '../pages/settings/UserProfilePage'
import AdminProfilePage from '../pages/settings/AdminProfilePage'
import AdminTasksSettingsPage from '../pages/panels/adminTasks/AdminTasksSettingsPage'
import UserPreferencesPage from '../pages/settings/UserPreferencesPage'
import AdminFaqPage from '../pages/panels/faq/AdminFaqPage'
import AdminManualsPage from '../pages/panels/manuals/AdminManualsPage'
import AdminGuideCategoriesPage from '../pages/panels/guideCategories/AdminGuideCategoriesPage'
import AdminGuidesPage from '../pages/panels/guides/AdminGuidesPage'
import AdminContactsPage from '../pages/panels/contacts/AdminContactsPage'
import ImportsPage from '../pages/imports/ImportsPage'
import ImportsConfirmPage from '../pages/imports/ImportsConfirmPage'
import ImportsUploadPage from '../pages/imports/ImportsUploadPage'
import ImportsDonePage from '../pages/imports/ImportsDonePage'
import ImportProgress from '../pages/imports/ImportProgress'
import ServicesPage from '../pages/app_services/ServicesPage'
import PropertiesServicePage from '../pages/app_services/PropertiesServicePage'
import RoomsServicePage from '../pages/app_services/RoomsServicePage'
import ContractsServicePage from '../pages/app_services/ContractsServicePage'
import ServiceSettingsPage from '../pages/app_services/ServiceSettingsPage'
import SeikatsuServicePage from '../pages/app_services/SeikatsuServicePage'

import InuiContactsPage from '../pages/inui/InuiContactsPage'
import CouponsAddPage from '../pages/inui/coupons/CouponsAddPage'
import CouponDetailPage from '../pages/inui/coupons/CouponDetailPage'
import CouponsPage from '../pages/inui/coupons/CouponsPage'

import RefundsPage from '../pages/anabuki/refunds/RefundsPage'
import RefundDetailPage from '../pages/anabuki/refunds/RefundDetailPage'

import InspectionsPage from '../pages/house_maker/InspectionsPage'
import ContractInspectionsPage from '../pages/house_maker/ContractInspectionsPage'

import DatasyncPage from '../pages/DatasyncPage'
import DatasyncConfirmPage from '../pages/DatasyncPage/DatasyncConfirmPage'

import BillingPage from '../pages/chouei/BillingPage'
import BillingConfirmPage from '../pages/chouei/BillingPage/BillingPageConfirmPage'

import SurveysPage from '../pages/surveys/SurveysPage'
import SurveyAddPage from '../pages/surveys/SurveyAddPage'
import SurveyDetailsPage from '../pages/surveys/SurveyDetailsPage'

import InquiriesPage from '../pages/InquiriesPage'

import SupportContractPage from '../pages/SupportContractPage'

import YasueContactsPage from '../pages/yasue/ContactsPage'
import ConstructionWorksPage from '../pages/yasue/ConstructionWorksPage'
import ConstructionWorkAddPage from '../pages/yasue/ConstructionWorkAddPage'
import ConstructionWorkDetailsPage from '../pages/yasue/ConstructionWorkDetailsPage'
import ConstructionWorkFilesPage from '../pages/yasue/ConstructionWorkFilesPage'
import YasueSurveysPage from '../pages/yasue/YasueSurveysPage'
import YasueSurveyDetailsPage from '../pages/yasue/YasueSurveyDetailsPage'
import YasueMaintenancePage from '../pages/yasue/YasueMaintenancePage'

import ImportBlock from './RouteBlocks/ImportBlock'
import ContractBlock from './RouteBlocks/ContractBlock'
import PropertyBlock from './RouteBlocks/PropertyBlock'
import RoomBlock from './RouteBlocks/RoomBlock'
import PropertySpaceBlock from './RouteBlocks/PropertySpaceBlock'
import UserManagementBlock from './RouteBlocks/UserManagementBlock'
import SettingsBlock from './RouteBlocks/SettingsBlock'
import ServiceBlock from './RouteBlocks/ServiceBlock'
import ConstructionWorkBlock from './RouteBlocks/yasue/ConstructionWorkBlock'

const PrivateRouter = () => {
  const { t } = useTranslation()
  const step = useSelector(selectCurrentStep)
  const user = useSelector(selectUser)
  const theme = useSelector(selectTheme)
  const pages = useSelector(selectPages)
  const isMaintenance = useSelector(selectMaintenance)
  const isLoadingFirebase = useSelector(selectFirebaseLoading)
  const appVersion = useSelector(selectAppVersion)

  const onUnload = useCallback(
    (e) => {
      // Ask before reloading only when uploading data
      if (step && step === IMPORT_STEPS.UPLOAD) {
        // Cancel the event
        e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = t('import:do_not_leave')
      }
    },
    [step, t]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [step, onUnload])

  if (!user || !pages) return <LoginPage default />
  if (isLoadingFirebase && !appVersion) return <PageLoader />
  if (isMaintenance && !user.privileged) return <MaintenancePage />

  const {
    dashboard,
    contracts,
    contract_documents,
    app_users,
    properties,
    rooms,
    notices,
    admin_tasks,
    chat,
    checklist,
    user_management,
    manage_members,
    manage_propertiesGroup,
    manage_guests,
    settings,
    imports,
    custom
  } = pages

  return (
    <ErrorBoundary user={user} version={appVersion}>
      <ThemeProvider theme={theme}>
        <NavBar />
        <ImportProgress />
        <Router>
          <PrivateRoute as={DashboardPage} path="/" enabled={dashboard.enabled} />

          <ImportBlock path="/import" enabled={imports.enabled} >
            <PrivateRoute as={ImportsPage} path="/" enabled={imports.enabled} />
            <PrivateRoute as={ImportsConfirmPage} path="/confirmation" enabled={imports.enabled} />
            <PrivateRoute as={ImportsUploadPage} path="/upload" enabled={imports.enabled} />
            <PrivateRoute as={ImportsDonePage} path="/done" enabled={imports.enabled} />
          </ImportBlock>

          <PrivateRoute as={ContractsPage} path="/contracts" enabled={contracts.enabled} />
          <PrivateRoute as={ContractsAddPage} path="/contracts/add" enabled={contracts.enabled && contracts.functions.add} />
          <ContractBlock path="/contracts/edit/:contractID" enabled={contracts.enabled && contracts.functions.details} >
            <PrivateRoute as={ContractDetailsPage} path="/" enabled={contracts.enabled && contracts.functions.details} />
            <PrivateRoute as={ContractRenewPage} path="/renew" enabled={contracts.enabled && custom?.cloudsign} />
            <PrivateRoute as={ContractCancelPage} path="/cancel" enabled={contracts.enabled && contracts.functions.cancel} />
            <PrivateRoute as={ContractDocumentsPage} path="/documents" enabled={contract_documents.enabled} />
            {/* TODO: remove ! when API is ready */}
            <PrivateRoute as={ContractContactsPage} path="/contacts" enabled={contracts.enabled && !contracts.functions.contacts} />
            <PrivateRoute as={ServicesPage} path="/services" enabled={contracts.enabled && contracts.functions.services} level={SERVICES_LEVEL.CONTRACT} />
            <PrivateRoute as={ContractOpeloPage} path="/opelo" enabled={contracts.enabled} />
            <NotFoundPage default withSidebar/>
          </ContractBlock>

          <PrivateRoute as={UsersPage} path="/app_users" enabled={app_users.enabled} />
          <PrivateRoute as={UserDetailsPage} path="/app_users/edit/:userID" enabled={app_users.enabled && app_users.functions.details} />

          <PrivateRoute as={PropertiesPage} path="/properties" enabled={properties.enabled} />
          <PrivateRoute as={PropertiesAddPage} path="/properties/add" enabled={properties.enabled && properties.functions.add} />
          <PropertyBlock path="/properties/edit/:propertyID" enabled={properties.enabled && properties.functions.details} >
            <PrivateRoute as={PropertyDetailsPage} path="/" enabled={properties.enabled && properties.functions.details} />
            <PrivateRoute as={PropertyAdminTasksSettingsPage} path="/admin_tasks" enabled={properties.enabled && properties.functions.adminTasksSettings} />
            <PrivateRoute as={PropertyFaqsPage} path="/faq" enabled={properties.enabled && properties.functions.faq} />
            <PrivateRoute as={PropertyManualsPage} path="/manuals" enabled={properties.enabled && properties.functions.manuals} />
            <PrivateRoute as={PropertyVicinitiesPage} path="/vicinities" enabled={properties.enabled && properties.functions.vicinities} />
            <PrivateRoute as={PropertyPublicFacilitiesPage} path="/public_facilities" enabled={properties.enabled && properties.functions.public_facilities} />
            <PrivateRoute as={PropertyGuideCategoriesPage} path="/guides" enabled={properties.enabled && properties.functions.guides} />
            <PrivateRoute as={PropertyGuidesPage} path="/guides/admin_details/:guideCategoryID" guideType="admin_guides" enabled={properties.enabled && properties.functions.guides} />
            <PrivateRoute as={PropertyGuidesPage} path="/guides/property_details/:guideCategoryID" guideType="guides" enabled={properties.enabled && properties.functions.guides} />
            <PrivateRoute as={PropertyRefusePage} path="/refuse" enabled={properties.enabled && properties.functions.refuse} />
            <PrivateRoute as={PropertyContactsPage} path="/contacts" enabled={properties.enabled && properties.functions.contacts} />
            <PrivateRoute as={ServicesPage} path="/services" enabled={properties.enabled && properties.functions.services} level={SERVICES_LEVEL.PROPERTY} />
            <PrivateRoute as={PropertyDocumentsPage} path="/documents" enabled={properties.enabled && custom?.yahagi} />
            <PrivateRoute as={PropertyEventsPage} path="/events" enabled={properties.enabled && custom?.yahagi} />
            <PrivateRoute as={PropertySpacesPage} path="/spaces" enabled={properties.enabled && custom?.yahagi} />
            <PrivateRoute as={PropertySpaceAddPage} path="/spaces/add" enabled={properties.enabled && custom?.yahagi} />
            <PrivateRoute as={RoomsPage} path="/rooms" enabled={rooms.enabled} />
            <NotFoundPage default withSidebar/>
          </PropertyBlock>
          <PropertySpaceBlock path="/properties/edit/:propertyID/spaces/edit/:spaceID" enabled={properties.enabled && custom?.yahagi}>
            <PrivateRoute as={PropertySpaceDetailsPage} path="/" enabled={properties.enabled && custom?.yahagi} />
            <PrivateRoute as={PropertySpaceReservationsPage} path="/reservations" enabled={properties.enabled && custom?.yahagi} />
          </PropertySpaceBlock>
          <RoomBlock path="/properties/edit/:propertyID/rooms/edit/:roomID" enabled={rooms.enabled && rooms.functions.details} >
            <PrivateRoute as={RoomDetailsPage} path="/" enabled={rooms.enabled && rooms.functions.details} />
            <PrivateRoute as={RoomFaqsPage} path="/faq" enabled={rooms.enabled && rooms.functions.faq} />
            <PrivateRoute as={RoomManualsPage} path="/manuals" enabled={rooms.enabled && rooms.functions.manuals} />
            <PrivateRoute as={RoomGuideCategoriesPage} path="/guides" enabled={rooms.enabled && rooms.functions.guides} />
            <PrivateRoute as={RoomGuidesPage} path="/guides/admin_details/:guideCategoryID" guideType="admin_guides" enabled={rooms.enabled && rooms.functions.guides} />
            <PrivateRoute as={RoomGuidesPage} path="/guides/property_details/:guideCategoryID" guideType="property_guides" enabled={rooms.enabled && rooms.functions.guides} />
            <PrivateRoute as={ServicesPage} path="/services" enabled={rooms.enabled && rooms.functions.services} level={SERVICES_LEVEL.ROOM} />
            <NotFoundPage default withSidebar/>
          </RoomBlock>

          <PrivateRoute as={NoticesPage} path="/notices" enabled={notices.enabled} />
          <PrivateRoute as={NoticesAddPage} path="/notices/add" enabled={notices.enabled && notices.functions.add} />
          <PrivateRoute as={NoticeDetailsPage} path="/notices/edit/:noticeID" enabled={notices.enabled && notices.functions.details} />

          <PrivateRoute as={AdminTasksPage} path="/admin_tasks" enabled={admin_tasks.enabled} />
          <PrivateRoute as={AdminTasksChatRoomsListPage} path="/admin_tasks/chat" enabled={admin_tasks.enabled && admin_tasks.functions.chat} />
          <PrivateRoute as={AdminTasksChatRoomPage} path="/admin_tasks/chat/:chatID" enabled={chat.enabled && chat.functions.details} />
          <PrivateRoute as={AdminTasksChecklistPage} path="/admin_tasks/checklist" enabled={admin_tasks.enabled && admin_tasks.functions.checklist} />
          <PrivateRoute as={AdminTasksChecklistRoomPage} path="/admin_tasks/checklist/:checklistID" enabled={checklist.enabled && checklist.functions.details} />
          <PrivateRoute as={AdminTasksContractsPage} path="/admin_tasks/contracts" enabled={custom?.cloudsign || contracts?.functions?.cancel} />
          <PrivateRoute as={AdminTasksSpacesReservationsPage} path="/admin_tasks/spaces_reservations" enabled={custom?.yahagi} />

          <UserManagementBlock path="/user_management" enabled={user_management.enabled && user_management.functions.members} >
            <PrivateRoute as={MembersPage} path="/" enabled={user_management.enabled && user_management.functions.members} />
            <PrivateRoute as={MembersAddPage} path="/members/add" enabled={manage_members.enabled && manage_members.functions.add} />
            <PrivateRoute as={MemberDetailsPage} path="/members/edit/:memberID" enabled={manage_members.enabled && manage_members.functions.details} />
            <PrivateRoute as={PropertiesGroupsPage} path="/properties_groups" enabled={user_management.enabled && user_management.functions.propertiesGroup} />
            <PrivateRoute as={PropertiesGroupsAddPage} path="/properties_groups/add" enabled={manage_propertiesGroup.enabled && manage_propertiesGroup.functions.add} />
            <PrivateRoute as={PropertiesGroupDetailsPage} path="/properties_groups/edit/:groupID" enabled={manage_propertiesGroup.enabled && manage_propertiesGroup.functions.details} />
            <PrivateRoute as={GuestsPage} path="/guests" enabled={user_management.enabled && user_management.functions.guests} />
            <PrivateRoute as={GuestsAddPage} path="/guests/add" enabled={manage_guests.enabled && manage_guests.functions.add} />
            <PrivateRoute as={GuestDetailsPage} path="/guests/edit/:guestID" enabled={manage_guests.enabled && manage_guests.functions.details} />
            <NotFoundPage default withSidebar/>
          </UserManagementBlock>

          <SettingsBlock path="/settings" enabled={settings.enabled}>
            {
              user.role === ROLES.OWNER
                ? <PrivateRoute as={AdminProfilePage} path="/" enabled={settings.enabled && settings.functions.adminProfile} />
                : (
                  <>
                    <PrivateRoute as={UserProfilePage} path="/" enabled={settings.enabled && settings.functions.profile} />
                    <PrivateRoute as={AdminProfilePage} path='/admin_profile' enabled={settings.enabled && settings.functions.adminProfile} />
                  </>
                )
            }
            <PrivateRoute as={UserPreferencesPage} path="/preferences" enabled={settings.enabled && settings.functions.preferences} />
            <PrivateRoute as={AdminTasksSettingsPage} path="/admin_tasks" enabled={settings.enabled && settings.functions.adminTasksSettings} />
            <PrivateRoute as={AdminFaqPage} path="/faq" enabled={settings.enabled && settings.functions.faq} />
            <PrivateRoute as={AdminManualsPage} path="/manuals" enabled={settings.enabled && settings.functions.manuals} />
            <PrivateRoute as={AdminGuideCategoriesPage} path="/guides" enabled={settings.enabled && settings.functions.guides} />
            <PrivateRoute as={AdminGuidesPage} path="/guides/admin_details/:guideCategoryID" enabled={settings.enabled && settings.functions.guides} />
            <PrivateRoute as={AdminContactsPage} path="/contacts" enabled={settings.enabled && settings.functions.contacts} />
            <PrivateRoute as={ServicesPage} path="/services" enabled={settings.enabled && settings.functions.services} level={SERVICES_LEVEL.ADMIN} />
            <ServiceBlock path="/services/:serviceID" enabled={settings.enabled && settings.functions.services} >
              <PrivateRoute as={PropertiesServicePage} path="/properties" enabled={settings.enabled && settings.functions.services} />
              <PrivateRoute as={SeikatsuServicePage} path="/properties/:propertyID/seikatsu" enabled={settings.enabled && settings.functions.services} />
              <PrivateRoute as={RoomsServicePage} path="/rooms" enabled={settings.enabled && settings.functions.services} />
              <PrivateRoute as={ContractsServicePage} path="/contracts" enabled={settings.enabled && settings.functions.services} />
              <PrivateRoute as={ServiceSettingsPage} path="/additional_settings" enabled={settings.enabled && settings.functions.services} />
              <NotFoundPage default withSidebar/>
            </ServiceBlock>
            <NotFoundPage default withSidebar/>
          </SettingsBlock>

          <PrivateRoute as={InuiContactsPage} path="/inui/contacts" enabled={custom?.inui} />
          <PrivateRoute as={CouponsAddPage} path="/coupons/add" enabled={custom?.inui} />
          <PrivateRoute as={CouponDetailPage} path="/coupons/edit/:couponID" enabled={custom?.inui} />
          <PrivateRoute as={CouponsPage} path="/coupons" enabled={custom?.inui} />

          <PrivateRoute as={RefundsPage} path="/anabuki/refunds" enabled={custom?.anabuki} />
          <PrivateRoute as={RefundDetailPage} path="/anabuki/refunds/:refundID" enabled={custom?.anabuki} />

          <PrivateRoute as={InspectionsPage} path="/inspections" enabled={custom?.house_maker} />
          <PrivateRoute as={ContractInspectionsPage} path="/inspections/:contractID" enabled={custom?.house_maker} />

          <PrivateRoute as={DatasyncPage} path="/datasync" enabled={custom?.manual_datasync} />
          <PrivateRoute as={DatasyncConfirmPage} path="/datasync/confirm" enabled={custom?.manual_datasync} />

          <PrivateRoute as={BillingPage} path="/billing" enabled={custom?.chouei} />
          <PrivateRoute as={BillingConfirmPage} path="/billing/confirm" enabled={custom?.chouei} />

          <PrivateRoute as={ConstructionWorksPage} path="/yasue/construction_works" enabled={custom?.yasue} />
          <PrivateRoute as={ConstructionWorkAddPage} path="/yasue/construction_works/add" enabled={custom?.yasue} />
          <ConstructionWorkBlock path="/yasue/construction_works/edit/:constructionWorkID" enabled={custom?.yasue}>
            <PrivateRoute as={ConstructionWorkDetailsPage} path="/" enabled={custom?.yasue} />
            <PrivateRoute as={ConstructionWorkFilesPage} path="/files" enabled={custom?.yasue} />
          </ConstructionWorkBlock>
          <PrivateRoute as={YasueSurveysPage} path="/yasue/survey" enabled={custom?.yasue} />
          <PrivateRoute as={YasueSurveyDetailsPage} path="/yasue/survey/edit/:surveyID" enabled={custom?.yasue} />
          <PrivateRoute as={YasueContactsPage} path="/yasue/contacts" enabled={custom?.yasue} />
          <PrivateRoute as={YasueMaintenancePage} path="/yasue/maintenance" enabled={custom?.yasue} />

          {/* TODO: update enabled */}
          <PrivateRoute as={SurveysPage} path="/surveys" enabled={custom?.yahagi} />
          {/* TODO: update enabled */}
          <PrivateRoute as={SurveyAddPage} path="/surveys/add" enabled={custom?.yahagi} />
          {/* TODO: update enabled */}
          <PrivateRoute as={SurveyDetailsPage} path="/surveys/edit/:surveyID" enabled={custom?.yahagi} />

          {/* TODO: update enabled */}
          <PrivateRoute as={InquiriesPage} path="/inquiries" enabled={custom?.yahagi} />

          <PrivateRoute as={SupportContractPage} path="/support/:contractId" enabled={user.role === ROLES.SUPPORT} />

          <NotFoundPage default />
        </Router>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default PrivateRouter

PrivateRouter.propTypes = {}
