import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const contractsSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    contract_manage_id: yup.string().label(i18n.t('contracts:contract_manage_id')),
    room_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('rooms:room_id')),
    property_name: yup.string().label(i18n.t('properties:property_name')),
    property_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('properties:property_name')),
    prefecture: yup.string().label(i18n.t('properties:prefecture')), // yasue
    city: yup.string().label(i18n.t('properties:city')), // yasue
    resident_id: yup.number().integer().label(i18n.t('contracts:resident_id')),
    resident_name: yup.string().label(i18n.t('contracts:resident_name')),
    contractor_id: yup.number().integer().label(i18n.t('contracts:contractor_id')),
    contractor_name: yup.string().label(i18n.t('contracts:contractor_name')),
    status: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .min(0)
      .max(11)
      .nullable()
      .label(i18n.t('status')),
    premium: yup
      .boolean()
      .transform((value) => {
        if (value === '') return null
        if (value === 'true') return true
        if (value === 'false') return false
        return value
      })
      .nullable()
      .label(i18n.t('contracts:anabuki.premium.short')),
    casa: yup
      .boolean()
      .transform((value) => {
        if (value === '') return null
        if (value === 'true') return true
        if (value === 'false') return false
        return value
      })
      .nullable()
      .label(i18n.t('contracts:casa.search.long')),
    contract_start_date_from: yup.date().nullable().label(i18n.t('contracts:contract_start_date_from')),
    contract_start_date_to: yup.date().nullable().label(i18n.t('contracts:contract_start_date_to')),
    contract_end_date_from: yup.date().nullable().label(i18n.t('contracts:contract_end_date_from')),
    contract_end_date_to: yup.date().nullable().label(i18n.t('contracts:contract_end_date_to')),
    move_out_date_from: yup.date().nullable().label(i18n.t('contracts:move_out_date_from')),
    move_out_date_to: yup.date().nullable().label(i18n.t('contracts:move_out_date_to')),
    limit: yup.number().integer().label(i18n.t('limit')),
    page: yup.number().integer().label(i18n.t('page')),
    sort_by: yup.string().label(i18n.t('sort_by')),
    sort_order: yup.string().label(i18n.t('sort_order'))
  })
}
