import { createSelector } from 'reselect'
import { paginate } from '@gk-devteam/apmc-core-web'

const selectChat = state => state.chat
const selectChatFilter = (state, filter) => {
  return filter
}

const filterChatRooms = (data, params) => {
  const filter = { ...params }
  delete filter.sort_by
  delete filter.sort_order

  if (!filter || Object.keys(filter).length === 0) return data.rooms

  const {
    userName,
    userID,
    propertyName,
    managementStatus
  } = filter

  const filtered = data.rooms && data.rooms.filter(room => {
    const match = []

    if (userName) {
      match.push(room.userName && room.userName.toLowerCase().indexOf(userName.toLowerCase()) !== -1)
    }
    if (userID) {
      match.push(room.userID && room.userID === Number(userID))
    }
    if (propertyName) {
      match.push(room.propertyName && room.propertyName.toLowerCase().indexOf(propertyName.toLowerCase()) !== -1)
    }
    if (managementStatus) {
      match.push(room.managementStatus === Number(managementStatus))
    }

    return match.every(item => item === true)
  })

  return filtered
}
const sortChatRooms = (data, params) => {
  // Handle pagination here
  let filtered
  if (data && params) {
    const { limit, page } = params
    if (limit && page) {
      filtered = paginate(data, limit, page)
    }
  }
  return filtered
}

export const selectChatLoading = createSelector(
  selectChat,
  chat => chat.loading
)
export const selectChatPosting = createSelector(
  selectChat,
  chat => chat.posting
)
export const selectChatLoadingHiddenBy = createSelector(
  selectChat,
  chat => chat.loadingHiddenBy
)
export const selectChatHiddenBy = createSelector(
  selectChat,
  chat => chat.hiddenBy
)
export const selectChatHiddenById = createSelector(
  selectChat,
  chat => chat.hiddenById
)

export const selectChatRooms = createSelector(
  selectChat,
  chat => chat.rooms
)
export const selectWaitingChatRooms = createSelector(
  selectChat,
  chat => chat?.waitingRooms
)
export const selectWaitingChatRoomsCount = createSelector(
  selectChat,
  chat => chat?.waitingRooms?.length
)
export const selectFilteredChatRooms = createSelector(
  [selectChat, selectChatFilter],
  (chat, params) => {
    let filtered = filterChatRooms(chat, params)
    // console.log('filtered', filtered)
    filtered = sortChatRooms(filtered, params)
    // console.log('filtered', filtered)
    return filtered
  }
)
export const selectFilteredChatRoomsCount = createSelector(
  [selectChat, selectChatFilter],
  (chat, params) => {
    const filtered = filterChatRooms(chat, params)
    return filtered && filtered.length
  }
)

export const selectAdminChatSettings = createSelector(
  selectChat,
  chat => chat.settings && chat.settings.admin
)
export const selectPropertyChatSettings = createSelector(
  selectChat,
  chat => chat.settings && chat.settings.property
)
export const selectRoomChatSettings = createSelector(
  selectChat,
  chat => chat.settings && chat.settings.room
)

export const selectChatRoom = createSelector(
  selectChat,
  chat => chat.room
)
export const selectChatRoomLastEdited = createSelector(
  selectChat,
  chat => (chat.messages && chat.messages[chat.messages.length - 1]?.created?.seconds) || null
)
export const selectChatRoomLastEditedBy = createSelector(
  selectChat,
  chat => (chat.messages && chat.messages[chat.messages.length - 1]?.user?.name) || null
)
export const selectChatError = createSelector(
  selectChat,
  chat => chat.error
)
export const selectChatMessages = createSelector(
  selectChat,
  chat => chat.messages
)
