import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { CancelToken } from 'axios'
import { message } from 'antd'
import { INIT_FORM, RESET_FORM } from '@gk-devteam/apmc-core-web'

import { i18n } from '../../locales'
import { postDatasync } from '../../services'
import {
  POST_DATASYNC,
  POST_DATASYNC_SUCCESS,
  POST_DATASYNC_FAIL
} from '../../types'

const initialData = {
  properties_file: null,
  properties_file_url: '',
  properties_file_filename: '',
  delete_properties_file: false,
  contracts_file: null,
  contracts_file_url: '',
  contracts_file_filename: '',
  delete_contracts_file: false
}

export function * postDatasyncSaga ({ data, onSuccess, onError }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postDatasync, data, cancelSource)
    if (res && res.status === 204) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_DATASYNC_SUCCESS })
      yield put({ type: RESET_FORM })
      yield put({ type: INIT_FORM, payload: initialData })
      yield call(message.success, i18n.t('post_success'))
    } else {
      if (onError) yield call(onError)
      yield put({ type: POST_DATASYNC_FAIL, error: true, payload: { message: i18n.t('post_error') } })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    if (onError) yield call(onError)
    yield put({ type: POST_DATASYNC_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
    if (error?.data?.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchDatasync () {
  yield takeLatest(POST_DATASYNC, postDatasyncSaga)
}
