import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Badge
} from '@gk-devteam/apmc-core-web'

const TabHeader = ({ header, count }) => {
  const { t } = useTranslation()
  return (
    <div>
      <Badge count={count} offset={[10, 0]}>
        { t(header) }
      </Badge>
    </div>
  )
}

export default memo(TabHeader)

TabHeader.propTypes = {
  header: PropTypes.string.isRequired,
  count: PropTypes.number
}
