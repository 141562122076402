import React, { memo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  INIT_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyName,
  selectManualsLoading,
  selectManualsHiddenBy,
  selectManualsHiddenById
} from '../../../../selectors'
import { FETCH_MANUALS, RESET_MANUALS } from '../../../../types'
import initialData from '../common/initialData'

import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import ManualModal from '../common/ManualModal'
import AdminManualsList from './AdminManualsList'
import PropertyManualsList from './PropertyManualsList'

const PropertyManualsPage = ({ propertyID }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectManualsLoading)
  const propertyName = useSelector(selectPropertyName)
  const [modalVisible, setModalVisible] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_manuals',
      link: `/properties/edit/${propertyID}/manuals`
    }
  ]

  useEffect(() => {
    dispatch({ type: FETCH_MANUALS, config: { property: propertyID } })
    return () => {
      dispatch({ type: RESET_MANUALS })
    }
  }, [dispatch, propertyID])

  const _openAddModal = () => {
    dispatch({ type: INIT_FORM, payload: initialData })
    setModalVisible(true)
  }
  const _openCopyModal = () => {
    setModalVisible(true)
  }
  const _openEditModal = () => {
    setModalVisible(true)
    setEditMode(true)
  }
  const _closeModal = () => {
    setModalVisible(false)
    setEditMode(false)
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectManualsHiddenBy}
      hiddenByIdSelector={selectManualsHiddenById}
      title="navigation:properties_manuals"
      authorized="manuals.functions.add"
      info="services:level_message.property"
      infoTitle="services:level_message.propertyTitle"
      handleAddClick={_openAddModal}
    >
      <ManualModal
        visible={modalVisible}
        editMode={editMode}
        onCancel={_closeModal}
        type="property"
        id={propertyID}
      />
      <AdminManualsList copyModal={_openCopyModal} editModal={_openEditModal} />
      <PropertyManualsList copyModal={_openCopyModal} editModal={_openEditModal} />
    </PanelLayout>
  )
}
export default memo(PropertyManualsPage)

PropertyManualsPage.propTypes = {
  propertyID: PropTypes.string.isRequired
}
PropertyManualsPage.defaultProps = {}
