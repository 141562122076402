import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import {
  DatePicker
} from '@gk-devteam/apmc-core-web'

dayjs.extend(isSameOrBefore)

const MovingOutDate = () => {
  const startDate = useSelector(state => state?.contract?.data?.contract_start)
  const endDate = useSelector(state => state?.contract?.data?.contract_end)
  const moveInDate = useSelector(state => state?.contract?.data?.move_in)

  const disablePreviousDate = (current) => {
    if (current && (startDate || moveInDate)) {
      const currentDay = dayjs(current)
      const start = dayjs(startDate)
      const end = dayjs(endDate)
      const moveIn = moveInDate ? dayjs(moveInDate) : null
      const date = moveIn || start
      if (endDate) {
        return !(current.isAfter(date) && currentDay.isSameOrBefore(end, 'day'))
      }
      return current.isBefore(date)
    }
    return false
  }

  return (
    <DatePicker
      flex={1}
      size="M"
      name="cancel_date"
      label='contracts:move_out_date'
      placeholder=""
      disabledDate={disablePreviousDate}
      required
    />
  )
}

export default MovingOutDate

// ContractDatepickers.propTypes = {}
