export const FETCH_SPACES = 'fetch_spaces'
export const FETCH_SPACES_SUCCESS = 'fetch_spaces_success'
export const FETCH_SPACES_FAIL = 'fetch_spaces_fail'
export const RESET_SPACES = 'reset_spaces'

export const FETCH_SPACES_RESERVATIONS = 'fetch_spaces_reservations'
export const FETCH_SPACES_RESERVATIONS_SUCCESS = 'fetch_spaces_reservations_success'
export const FETCH_SPACES_RESERVATIONS_FAIL = 'fetch_spaces_reservations_fail'
export const RESET_SPACES_RESERVATIONS = 'reset_spaces_reservations'

export const FETCH_SPACE = 'fetch_space'
export const FETCH_SPACE_SUCCESS = 'fetch_space_success'
export const FETCH_SPACE_FAIL = 'fetch_space_fail'
export const RESET_SPACE = 'reset_space'

export const FETCH_SPACE_RESERVATIONS = 'fetch_space_reservations'
export const FETCH_SPACE_RESERVATIONS_SUCCESS = 'fetch_space_reservations_success'
export const FETCH_SPACE_RESERVATIONS_FAIL = 'fetch_space_reservations_fail'
export const RESET_SPACE_RESERVATIONS = 'reset_space_reservations'

export const FETCH_SPACES_WAITING_RESERVATIONS = 'fetch_spaces_waiting_reservations'
export const FETCH_SPACES_WAITING_RESERVATIONS_SUCCESS = 'fetch_spaces_waiting_reservations_success'
export const FETCH_SPACES_WAITING_RESERVATIONS_FAIL = 'fetch_spaces_waiting_reservations_fail'
export const RESET_SPACES_WAITING_RESERVATIONS = 'reset_spaces_waiting_reservations'

export const POST_SPACE = 'post_space'
export const POST_SPACE_SUCCESS = 'post_space_success'
export const POST_SPACE_FAIL = 'post_space_fail'

export const UPDATE_SPACE_RESERVATION_STATUS = 'update_space_reservation_status'
export const UPDATE_SPACE_RESERVATION_STATUS_SUCCESS = 'update_space_reservation_status_success'
export const UPDATE_SPACE_RESERVATION_STATUS_FAIL = 'update_space_reservation_status_fail'

export const DELETE_SPACE = 'delete_space'
export const DELETE_SPACE_SUCCESS = 'delete_space_success'
export const DELETE_SPACE_FAIL = 'delete_space_fail'
