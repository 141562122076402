import React, { useRef, useEffect } from 'react'
import dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux'
import {
  SERVER_TIMESTAMP_FORMAT
} from '@gk-devteam/apmc-core-web'

import { selectChatMessages, selectFirebaseUserID } from '../../../selectors'
import { updateChatMessagesRead } from '../../../actions'

import ChatMessage from './ChatMessage'

const ChatMessages = () => {
  const dispatch = useDispatch()
  const lastMessageRef = useRef(null)
  const messages = useSelector(selectChatMessages)
  const userID = useSelector(selectFirebaseUserID)

  const _scrollToLastMessage = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({
        // behavior: 'smooth',
        block: 'start'
      })
    }
  }

  useEffect(() => {
    _scrollToLastMessage()
  }, [messages, lastMessageRef])

  useEffect(() => {
    if (messages) {
      dispatch(updateChatMessagesRead(messages, userID))
    }
  }, [messages, userID, dispatch])

  if (!messages) return null

  return messages.map((message, i) => {
    const {
      body,
      fileExtension,
      fileSize,
      created,
      type,
      user,
      reads
    } = message
    const isOwn = user.id.indexOf('admin_') !== -1
    const read = isOwn
      ? reads && Object.values(reads).filter(it => it).length !== 0 // return true if at least one user in the reads map has read the massge
      : reads && Object.keys(reads).includes(userID)
    return (
      <ChatMessage
        key={i}
        ref={messages.length - 1 === i ? lastMessageRef : null}
        type={type}
        content={body}
        fileExtension={fileExtension}
        fileSize={fileSize}
        date={dayjs.unix(created.seconds).format(SERVER_TIMESTAMP_FORMAT)}
        isOwn={isOwn}
        isRead={read}
        onImageLoaded={_scrollToLastMessage}
        author={user}
        id={message.id}
      />
    )
  })
}

export default ChatMessages

ChatMessages.propTypes = {}
