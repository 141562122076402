import React, { useCallback } from 'react'
import {
  useTranslation,
  Table,
  Link
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import {
  selectLoadingAppUser,
  selectContractHistoryData
} from '../../../selectors'

const ContractHistory = () => {
  const { t } = useTranslation()

  const propertyCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/properties/edit/${data.property_id}`}>{value || ''}</Link>
    },
    []
  )
  const roomCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/properties/edit/${data.property_id}/rooms/edit/${data.room_id}`}>{value || ''}</Link>
    },
    []
  )
  const contractCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Link to={`/contracts/edit/${data.contract_id}`}>{t('app_users:to_contract_page')}</Link>
    },
    [t]
  )
  const residenceHistoryColumns = [
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name',
      render: propertyCell
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number',
      render: roomCell
    },
    {
      title: t('navigation:contracts'),
      dataIndex: 'contract',
      key: 'contract',
      render: contractCell
    },
    {
      title: t('contracts:contract_start_date'),
      dataIndex: 'contract_start_date',
      key: 'contract_start_date'
    },
    {
      title: t('contracts:contract_end_date'),
      dataIndex: 'contract_end_date',
      key: 'contract_end_date'
    },
    {
      title: t('contracts:move_out_date'),
      dataIndex: 'move_out_date',
      key: 'move_out_date',
      authorized: '!custom.house_maker'
    }
  ]

  return (
    <Table
      columns={residenceHistoryColumns}
      rowKey=""
      loadingSelector={selectLoadingAppUser}
      dataSelector={selectContractHistoryData}
      noLimit
      noResults
      noFooter
      leftStickyColumnCount={0}
      rightStickyColumnCount={0}
    />
  )
}

export default ContractHistory

// ContractHistory.propTypes = {}
