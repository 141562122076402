import {
  styled,
  SPACING,
  Card,
  FormReadOnlyLink,
  getColor,
  Row,
  FormRemovableLabel,
  BORDER_RADIUS
} from '@gk-devteam/apmc-core-web'
import { transparentize } from 'polished'

export const FormInnerStyle = styled.div`
  max-width: 85%; /* TODO: check if this width is correct */
  @media(max-width: 768px){
    max-width: 100%;
  }
`

export const CardStyle = styled(Card)``

export const NoRoomStyle = styled.div`
  text-align: center;
  padding: ${SPACING.REGULAR};
`

export const RoomsContainerStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
  grid-gap: ${SPACING.REGULAR}rem ${SPACING.LARGE}rem;
`

export const LinkStyle = styled(FormReadOnlyLink)`
  && {
    flex: 0 1 auto;
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    white-space: nowrap;
  }
  & + div {
    margin-top: 0;
  }
`

export const LoaderStyle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UsersListStyle = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: ${SPACING.SMALLEST}rem;
  border-top: solid 1px ${props => getColor(props, 'cardBorder')};
  overflow-y: scroll;
  flex: 1;
  position: relative;
`

export const UsersListItemStyle = styled.li`
  margin-bottom: ${SPACING.SMALL}rem;
  padding: ${SPACING.SMALLEST}rem;
  cursor: pointer;
  &:hover {
    background-color: ${props => transparentize(0.9, getColor(props, 'accent'))};
  }
`

export const BoardUserStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: ${SPACING.REGULAR}rem;
`

export const InspectorModalBodyStyle = styled(Row)`
  flex: 1;
  overflow: scroll;
`

export const BetweenStyle = styled.div`
  margin: 0 ${SPACING.SMALLEST}rem;
  user-select: none;
  color: ${props => getColor(props, 'lightText')};
`
export const CheckmarkCellStyle = styled.div`
  padding: calc(${SPACING.SMALL}rem - 2px) 0;
  text-align: center;
`
export const TableContainerStyle = styled.div`
  flex: 5;
  height: 100%;
  overflow: scroll;
  position: relative;
`
export const ResultsContainer = styled.ul`
  ${BORDER_RADIUS};
  padding: ${SPACING.SMALL}rem;
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  margin: 0;
  list-style: none;
  flex: 2;
  height: 100%;
  overflow: scroll;
`

export const InspectorItemStyle = styled(FormRemovableLabel)`
  margin: ${SPACING.SMALLEST / 2}rem;
`
