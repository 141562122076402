import React from 'react'
import { useSelector } from 'react-redux'
import { Tag } from '@gk-devteam/apmc-core-web'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'

import {
  selectContractsCanceling,
  selectContractsRenewing
} from '../../selectors'
import { CONTRACT_STATUS } from '../../constants'

import {
  ContractItemStyle,
  ContractRightStyle,
  ContractorStyle,
  PropertyStyle,
  RoomStyle,
  ContractsWrapperStyle
} from './Drawer.style'

function Contract ({ data, closeDrawer }) {
  const navigate = useNavigate()
  if (!data) return null
  const {
    id,
    contractor_name,
    property_name,
    room_number,
    status
  } = data
  let color, label
  for (const key in CONTRACT_STATUS) {
    const element = CONTRACT_STATUS[key]
    if (status === element.value) {
      label = `contracts:status.${key}`
      color = element.color
    }
  }

  const renewing = [
    CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value,
    CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value,
    CONTRACT_STATUS.RENEWING.value
  ]

  const canceling = [
    CONTRACT_STATUS.REQUESTING_CANCEL.value,
    CONTRACT_STATUS.CANCELING.value
  ]

  const _handleClick = () => {
    if (renewing.includes(status)) {
      navigate(`/contracts/edit/${id}/renew`)
      closeDrawer()
    }
    if (canceling.includes(status)) {
      navigate(`/contracts/edit/${id}/cancel`)
      closeDrawer()
    }
  }
  return (
    <ContractItemStyle onClick={_handleClick}>
      <ContractRightStyle>
        <ContractorStyle text={contractor_name} />
        <PropertyStyle text={property_name} />
        <RoomStyle text={room_number} />
      </ContractRightStyle>
      <Tag color={color} label={label}/>
    </ContractItemStyle>
  )
}
Contract.propTypes = {
  data: PropTypes.shape({
    contract_end_date: PropTypes.string,
    contract_start_date: PropTypes.string,
    contractor_id: PropTypes.number,
    contractor_name: PropTypes.string,
    id: PropTypes.number,
    move_out_date: PropTypes.string,
    property_id: PropTypes.number,
    property_name: PropTypes.string,
    resident_id: PropTypes.number,
    resident_login_status: PropTypes.number,
    resident_name: PropTypes.string,
    room_id: PropTypes.number,
    room_number: PropTypes.string,
    status: PropTypes.number
  }),
  closeDrawer: PropTypes.func.isRequired
}

function Contracts ({ contracts, closeDrawer }) {
  if (contracts && contracts.length > 0) {
    return (
      <ContractsWrapperStyle>
        {
          contracts.map(contract => <Contract data={contract} closeDrawer={closeDrawer} key={contract.id}/>)
        }
      </ContractsWrapperStyle>
    )
  }

  return null
}

Contracts.propTypes = {
  contracts: PropTypes.array,
  closeDrawer: PropTypes.func.isRequired
}

export function RenewingContracts ({ closeDrawer }) {
  const renewingContracts = useSelector(selectContractsRenewing)
  if (!renewingContracts) return null

  return <Contracts contracts={renewingContracts} closeDrawer={closeDrawer} />
}
RenewingContracts.propTypes = {
  closeDrawer: PropTypes.func.isRequired
}

export function CancelingContracts ({ closeDrawer }) {
  const cancelingContracts = useSelector(selectContractsCanceling)
  if (!cancelingContracts) return null

  return <Contracts contracts={cancelingContracts} closeDrawer={closeDrawer} />
}
CancelingContracts.propTypes = {
  closeDrawer: PropTypes.func.isRequired
}
