import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_SPACE,
  FETCH_SPACE_SUCCESS,
  FETCH_SPACE_FAIL,
  RESET_SPACE,
  POST_SPACE,
  POST_SPACE_SUCCESS,
  POST_SPACE_FAIL,
  FETCH_SPACE_RESERVATIONS,
  FETCH_SPACE_RESERVATIONS_SUCCESS,
  FETCH_SPACE_RESERVATIONS_FAIL,
  RESET_SPACE_RESERVATIONS
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  data: null,
  error: null,
  reservations_loading: null,
  reservations_max_pages: null,
  reservations_count: null,
  reservations: [],
  reservations_error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_SPACE]: (state, action) => {
    state.posting = true
  },
  [POST_SPACE_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_SPACE_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_SPACE]: (state, action) => {
    state.loading = true
  },
  [FETCH_SPACE_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_SPACE_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_SPACE]: () => INITIAL_STATE,
  [FETCH_SPACE_RESERVATIONS]: (state, action) => {
    state.reservations_loading = true
  },
  [FETCH_SPACE_RESERVATIONS_SUCCESS]: (state, action) => {
    const { max_pages, results_count, results } = action.payload
    state.reservations_loading = false
    state.reservations_max_pages = max_pages
    state.reservations_count = results_count
    state.reservations = results
  },
  [FETCH_SPACE_RESERVATIONS_FAIL]: (state, action) => {
    state.reservations_loading = false
    state.reservations_error = action.payload
  },
  [RESET_SPACE_RESERVATIONS]: (state) => {
    state.reservations_loading = null
    state.reservations = null
    state.reservations_error = null
  }
})
