import React, { useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import {
  useTranslation,
  dynamicLink,
  RESET_PARAMS,
  selectQueryParams,
  MainLayout,
  Row,
  Title,
  PrimaryButton,
  Table,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_PROPERTIES_GROUPS,
  RESET_PROPERTIES_GROUPS,
  DELETE_PROPERTIES_GROUP
} from '../../../types'
import {
  selectPropertiesGroupsLoading,
  selectPropertiesGroupsCount,
  selectPropertiesGroupsData,
  selectPages
} from '../../../selectors'

const breadcrumbs = [
  {
    label: 'navigation:management',
    link: '/user_management'
  },
  {
    label: 'navigation:management_properties_groups_list',
    link: '/user_management/properties_groups'
  }
]

const PropertiesGroupsPage = ({ navigate, location }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const { t } = useTranslation(['common', 'user_management'])
  const prevQuery = useRef(null)
  const pages = useSelector(selectPages)

  const fetchData = useCallback(
    () => {
      dispatch({ type: FETCH_PROPERTIES_GROUPS, query })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_PROPERTIES_GROUPS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const _handleCopy = useCallback(
    (id, close) => {
      close()
      navigate('/user_management/properties_groups/add', { state: { id, copy: true } })
    },
    [navigate]
  )

  const _canDelete = useCallback(
    (data) => {
      if (!pages) return false
      if (!pages.manage_propertiesGroup.functions.delete) return false
      return data && data.deletable
    },
    [pages]
  )

  const propertiesGroupsColumns = [
    {
      title: t('user_management:properties_group.name'),
      dataIndex: 'name',
      key: 'name',
      cellTitle: true,
      sortable: true
    },
    {
      title: t('user_management:properties_group.manage_id'),
      dataIndex: 'manage_id',
      key: 'manage_id'
    },
    {
      title: t('user_management:properties_group.description'),
      dataIndex: 'description',
      key: 'description',
      sortable: true
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('copy'),
          onClick: (id, close) => {
            _handleCopy(id, close)
          },
          shouldRender: 'manage_propertiesGroup.functions.add'
        },
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            console.log('warning', id)
            dispatch({ type: DELETE_PROPERTIES_GROUP, id })
            close()
          },
          shouldRender: _canDelete
        }
      ]
    }
  ]

  const navigateToAdd = () => {
    navigate('/user_management/properties_groups/add')
  }
  const navigateToDetails = (id, row, e) => {
    const url = `/user_management/properties_groups/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      // loading={loading}
      // lastEdited={lastEdited}
      // lastEditedBy={lastEditedBy}
    >
      <Row justify="between" align="center" mt="R" wrap={'wrap'}>
        <Title label="navigation:management_properties_groups_list"/>
        <div>
          <RoleControlled authorized="manage_propertiesGroup.functions.add">
            <PrimaryButton
              label="user_management:add_properties_group"
              handleClick={navigateToAdd}
              reverse
              withMargin
            />
          </RoleControlled>
        </div>
      </Row>
      <Table
        fullHeight
        columns={propertiesGroupsColumns}
        rowKey="id"
        loadingSelector={selectPropertiesGroupsLoading}
        resultsCountSelector={selectPropertiesGroupsCount}
        dataSelector={selectPropertiesGroupsData}
        onRowClick={navigateToDetails}
      />
    </MainLayout>
  )
}
export default PropertiesGroupsPage

PropertiesGroupsPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
PropertiesGroupsPage.defaultProps = {}
