import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_SEIKATSU,
  FETCH_SEIKATSU_SUCCESS,
  FETCH_SEIKATSU_FAIL,
  RESET_SEIKATSU,
  TOGGLE_SEIKATSU,
  TOGGLE_SEIKATSU_SUCCESS,
  TOGGLE_SEIKATSU_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  error: null,
  items: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_SEIKATSU]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_SEIKATSU_SUCCESS]: (state, action) => {
    state.loading = false
    state.items = action.payload
  },
  [FETCH_SEIKATSU_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [TOGGLE_SEIKATSU]: (state, action) => {
    state.loading = true
  },
  [TOGGLE_SEIKATSU_SUCCESS]: (state, action) => {
    // state.loading = false
  },
  [TOGGLE_SEIKATSU_FAIL]: (state, action) => {
    // state.loading = false
  },
  [RESET_SEIKATSU]: (state, action) => {
    state.loading = true
    state.error = null
    state.items = []
  }
})
