import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_GUESTS,
  FETCH_GUESTS_SUCCESS,
  FETCH_GUESTS_FAIL,
  RESET_GUESTS,
  DELETE_GUEST,
  // DELETE_GUEST_SUCCESS,
  DELETE_GUEST_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  max_pages: null,
  results_count: null,
  results: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_GUESTS]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_GUESTS_SUCCESS]: (state, action) => {
    // console.debug('here')
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_GUESTS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_GUESTS]: (state, action) => {
    state.loading = true
    state.error = null
    state.max_pages = null
    state.results_count = null
    state.results = []
  },
  [DELETE_GUEST]: (state, action) => {
    state.loading = true
  },
  [DELETE_GUEST_FAIL]: (state, action) => {
    state.loading = false
  }
})
