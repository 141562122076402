import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { CancelToken } from 'axios'
import { saveAs } from 'file-saver'
import {
  dynamicLink,
  FRONT_DATE_FORMAT,
  LightText,
  PageLayout,
  RESET_PARAMS,
  Row,
  selectQueryParams,
  selectTheme,
  Table,
  Tag,
  Text,
  Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import dayjs from 'dayjs'

import {
  FETCH_REFUNDS,
  RESET_REFUNDS
} from '../../../../types'

import {
  selectRefundsLoading,
  selectRefundsData,
  selectRefundsCount
} from '../../../../selectors'
import { ANABUKI } from '../../../../constants'
import { refundsSearchSchema } from '../../../../validation'

import RefundsSearchBar from './RefundsSearchBar'
import { fetchRefundFile } from '../../../../services'
import { message } from 'antd'

const breadcrumbs = [
  {
    label: 'navigation:anabuki.refunds',
    link: '/anabuki/refunds'
  }
]

const RefundsPage = ({ location, navigate }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const theme = useSelector(selectTheme)
  const { t } = useTranslation(['common', 'refunds'])
  const prevQuery = useRef(null)

  const fetchData = useCallback(
    () => {
      const schema = refundsSearchSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_REFUNDS, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_REFUNDS })
          }
        })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_REFUNDS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const dateCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      return <Text text={dayjs(value).format(FRONT_DATE_FORMAT)} />
    },
    []
  )
  const statusCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      switch (value) {
        case ANABUKI.SUPPORT_STATUS.WAITING:
          return <Tag label="refunds:statuses.waiting" customColor={theme?.colors?.danger} />
        case ANABUKI.SUPPORT_STATUS.IN_PROGRESS:
          return <Tag label="refunds:statuses.in_progress" customColor={theme?.colors?.info} />
        case ANABUKI.SUPPORT_STATUS.COMPLETED:
          return <Tag label="refunds:statuses.completed" customColor={theme?.colors?.success} />
        case ANABUKI.SUPPORT_STATUS.REJECTED:
          return <Tag label="refunds:statuses.rejected" customColor={theme?.colors?.warning} />
        default:
          return <LightText text='-' />
      }
    },
    [theme]
  )

  const _handleDownload = async (id, closeMenu, row) => {
    const { user_name, application_date } = row
    const date = dayjs(application_date).format('YYYYMMDD')
    const hideMessage = message.loading(t('refunds:download'), 0)
    closeMenu()
    if (id) {
      try {
        const cancelSource = CancelToken.source()
        const res = await fetchRefundFile(id, cancelSource)
        if (hideMessage) hideMessage()
        saveAs(res.data, `【${date}】${user_name}.zip`)
      } catch (error) {
        if (hideMessage) hideMessage()
        console.warn(error)
      }
    }
  }

  const refundsColumns = [
    {
      title: t('app_users:app_user_name'),
      dataIndex: 'user_name',
      key: 'user_name',
      cellTitle: true
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name'
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number'
    },
    {
      title: t('refunds:application_date'),
      dataIndex: 'application_date',
      key: 'application_date',
      render: dateCell
    },
    {
      title: t('refunds:status'),
      dataIndex: 'status',
      key: 'status',
      render: statusCell
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('download'),
          onClick: _handleDownload
        }
      ]
    }
  ]

  const navigateToDetails = (id, row, e) => {
    const url = `/anabuki/refunds/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <PageLayout
      sidebar={() => <RefundsSearchBar />}
      breadcrumbs={breadcrumbs}
      // loading={loading}
      // lastEdited={lastEdited}
      // lastEditedBy={lastEditedBy}
    >
      <Row justify="between" align="center" mt="R" wrap={'wrap'}>
        <Title label="navigation:anabuki.refunds"/>
      </Row>
      <Table
        fullHeight
        columns={refundsColumns}
        rowKey="id"
        loadingSelector={selectRefundsLoading}
        resultsCountSelector={selectRefundsCount}
        dataSelector={selectRefundsData}
        onRowClick={navigateToDetails}
        leftStickyColumnCount={1}
        rightStickyColumnCount={1}
      />
    </PageLayout>
  )
}
export default RefundsPage

RefundsPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}

RefundsPage.defaultProps = {

}
