import { createSelector } from 'reselect'

const selectSpace = state => state.space

export const selectSpaceLoading = createSelector(
  selectSpace,
  space => space.loading
)
export const selectSpacePosting = createSelector(
  selectSpace,
  space => space.posting
)
export const selectSpaceError = createSelector(
  selectSpace,
  space => space.error
)
export const selectSpaceData = createSelector(
  selectSpace,
  space => space.data || {}
)
export const selectSpaceReservationsLoading = createSelector(
  selectSpace,
  space => space?.reservations_loading
)
export const selectSpaceReservationsError = createSelector(
  selectSpace,
  space => space?.reservations_error
)
export const selectSpaceReservations = createSelector(
  selectSpace,
  space => space.reservations
)
export const selectSpaceReservationsCount = createSelector(
  selectSpace,
  space => space.reservations_count
)
