import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CancelToken } from 'axios'
import { message } from 'antd'
import dot from 'dot-object'
import {
  InfoButton,
  PrimaryButton,
  selectFormData,
  selectFormDirtyState,
  selectFormRequiredFields,
  selectLocale,
  useTranslation,
  VALIDATE_FORM
} from '@gk-devteam/apmc-core-web'
// import PropTypes from 'prop-types'

import { postPreviewRenewContract } from '../../../services'
import { selectContractID, selectContractPosting } from '../../../selectors'
import { contractRenewalValidation } from '../../../validation'

import PreviewModal from '../PreviewModal'

function ContractPreview () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const contractID = useSelector(selectContractID)
  const formData = useSelector(selectFormData)
  const isDirty = useSelector(selectFormDirtyState)
  const posting = useSelector(selectContractPosting)
  const requiredFields = useSelector(selectFormRequiredFields)
  const locale = useSelector(selectLocale)
  const [loading, setLoading] = useState(false)
  const [fileURL, setFileURL] = useState(null)
  const [visible, setVisible] = useState(false)
  const [isPreview, setIsPreview] = useState(true)

  const _handlePreview = async () => {
    if (contractID && formData) {
      const data = dot.object({ ...formData })
      try {
        setLoading(true)
        const cancelSource = CancelToken.source()
        const res = await postPreviewRenewContract(contractID, data, cancelSource)
        const fileURL = URL.createObjectURL(res.data)
        setFileURL(fileURL)
        setVisible(true)
        setLoading(false)
      } catch (error) {
        message.error(error?.data?.message || t('contracts:preview_error'))
        setLoading(false)
      }
    }
  }
  const _handlePreviewClick = () => {
    setIsPreview(true)
    _handlePreview()
  }
  const _handlePreviewSaveClick = async () => {
    setIsPreview(false)
    const schema = contractRenewalValidation(locale, requiredFields)
    const names = formData ? Object.keys(formData) : []
    const expandedData = { ...formData }
    dot.object(expandedData)

    try {
      await schema.validate(expandedData)
      _handlePreview()
    } catch (error) {
      console.warn(error)
      message.error(t('validation:form_error'))
    }

    dispatch({
      type: VALIDATE_FORM,
      payload: {
        validation: schema,
        names
      }
    })
  }
  const _closePreview = () => {
    setFileURL(null)
    setVisible(false)
  }
  return (
    <>
      <PreviewModal
        visible={visible}
        close={_closePreview}
        fileURL={fileURL}
        isPreview={isPreview}
      />
      {
        isDirty
          ? (
            <InfoButton
              type="button"
              label="contracts:contract_preview_save"
              handleClick={_handlePreviewSaveClick}
              loading={loading || posting}
              disabled={!formData?.template_id || !isDirty}
              reverse
            />
          )
          : (
            <PrimaryButton
              type="button"
              label="contracts:contract_preview"
              handleClick={_handlePreviewClick}
              loading={loading}
              disabled={!formData?.template_id}
            />
          )
      }
    </>
  )
}

ContractPreview.propTypes = {}

export default ContractPreview
