import {
  api,
  MULTIPART_HEADERS,
  getFileFromURL,
  DEFAULT_QUERY_LIMIT
} from '@gk-devteam/apmc-core-web'

export const fetchProperties = (params = {}, cancelSource) => {
  // Set default params
  const {
    limit,
    page,
    sort_by,
    sort_order
  } = params

  const defaultParams = {
    limit: limit || DEFAULT_QUERY_LIMIT,
    page: page || 1,
    sort_by: sort_by || 'id',
    sort_order: sort_order || 'asc'
  }

  return api.get('properties', {
    params: {
      ...params,
      ...defaultParams
    },
    cancelToken: cancelSource.token,
    headers: {
      responseType: params.csv ? 'blob' : 'application/json'
    }

  })
}

export const fetchProperty = (id, cancelSource) => {
  return api.get(`properties/${id}`, {
    cancelToken: cancelSource.token
  })
}
export const postProperty = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }
    if (data.property_owner.property_owner_image) {
      try {
        const file = await getFileFromURL(data.property_owner.property_owner_image)
        formData.append('property_owner_image', file, data.property_owner.property_owner_image_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }

    delete data.file
    delete data.file_url
    delete data.file_filename
    delete data.property_owner.property_owner_image
    delete data.property_owner.property_owner_image_url
    delete data.property_owner.property_owner_image_filename

    formData.append('data', JSON.stringify(data))
    return api.post('properties/', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}
export const deleteProperty = (id, cancelSource) => {
  return api.delete(`properties/${id}`, {
    cancelToken: cancelSource.token
  })
}
