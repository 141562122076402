import { createSelector } from 'reselect'

const selectRefunds = state => state.refunds

export const selectRefundsLoading = createSelector(
  selectRefunds,
  refunds => refunds.loading
)

export const selectRefundsCount = createSelector(
  selectRefunds,
  refunds => refunds.results_count
)

export const selectRefundsData = createSelector(
  selectRefunds,
  refunds => refunds.results
)
