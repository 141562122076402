import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal } from '@gk-devteam/apmc-core-web'

import { POST_FAQ } from '../../../../types'
import { selectFaqPosting } from '../../../../selectors'

import FaqForm from '../../../../forms/FaqForm/FaqForm'

const FaqModal = ({ visible, onCancel, type, id, editMode }) => {
  const dispatch = useDispatch()
  const posting = useSelector(selectFaqPosting)

  const _handleSubmit = (data) => {
    const onSuccess = () => {
      onCancel()
    }
    dispatch({ type: POST_FAQ, data, config: { [type]: id }, onSuccess })
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={_handleSubmit}
      okText="save"
      title={editMode ? 'faq:edit_faq' : 'faq:add_faq'}
      formID="faq_form"
      posting={posting}
      fullWidth
    >
      <FaqForm handleSubmit={_handleSubmit}/>
    </Modal>
  )
}

export default FaqModal

FaqModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['property', 'room']),
  id: PropTypes.string
}
