import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dot from 'dot-object'
import {
  useTranslation,
  Table,
  TOGGLE_ARRAY_ITEM,
  CheckboxStandAlone,
  UPDATE_FORM_ARRAY
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertiesLoading,
  selectPropertiesData
} from '../../../../../selectors'

import UserCheckmark from './UserCheckmark'

import {
  CheckmarkCellStyle,
  TableContainerStyle
} from '../../../CouponForm.style'

const PropertiesList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const propertiesData = useSelector(selectPropertiesData)
  const selectedProperties = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    return form.recipients_list
  })

  const _handleAllCheck = () => {
    if (propertiesData?.length === selectedProperties?.length) {
      dispatch({
        type: UPDATE_FORM_ARRAY,
        payload: [
          {
            name: 'recipients_id',
            values: null
          },
          {
            name: 'recipients_list',
            values: null
          }
        ]
      })
    } else {
      dispatch({
        type: UPDATE_FORM_ARRAY,
        payload: [
          {
            name: 'recipients_id',
            values: null
          },
          {
            name: 'recipients_list',
            values: null
          }
        ]
      })
      const ids = []
      const properties = []
      for (const property of propertiesData) {
        const { id, name } = property
        ids.push(id)
        properties.push({ id, label: name })
      }
      dispatch({
        type: UPDATE_FORM_ARRAY,
        payload: [
          {
            name: 'recipients_id',
            values: ids
          },
          {
            name: 'recipients_list',
            values: properties
          }
        ]
      })
    }
  }

  const checkmarkTitleCell = () => {
    return (
      <CheckmarkCellStyle>
        <CheckboxStandAlone
          name="allCheck"
          checked={propertiesData?.length === selectedProperties?.length}
          handleChange={_handleAllCheck}
          noLabel
          autoH
          size="fluid"
          mb="0"
        />
      </CheckmarkCellStyle>
    )
  }

  const propertiesColumns = [
    {
      title: checkmarkTitleCell,
      dataIndex: 'allCheck',
      key: 'selected_user',
      RenderComponent: UserCheckmark
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'name',
      key: 'name',
      cellTitle: true
    },
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('manage_id'),
      dataIndex: 'property_manage_id',
      key: 'property_manage_id'
    }
  ]

  const _handleRowClick = (_, row, e) => {
    const { id, name } = row
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [
        {
          key: 'recipients_list',
          value: { id, label: name },
          objectComparatorKey: 'id'
        },
        {
          key: 'recipients_id',
          value: id
        }]
    })
  }

  return (
    <TableContainerStyle>
      <Table
        columns={propertiesColumns}
        rowKey="id"
        loadingSelector={selectPropertiesLoading}
        dataSelector={selectPropertiesData}
        onRowClick={_handleRowClick}
        leftStickyColumnCount={0}
        rightStickyColumnCount={0}
        noHeader
        noFooter
        noLoader
      />
    </TableContainerStyle>
  )
}

export default memo(PropertiesList)

// UsersList.propTypes = {}
