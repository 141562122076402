import { createSelector } from 'reselect'
import { i18n } from '../../locales'

const selectVicinities = state => state.vicinities

const parseDisplay = (data) => {
  const items = []
  for (const item of data) {
    items.push(item.display)
  }

  if (items.every(item => item === 0 || item === false)) return 0 // all hidden
  if (items.every(item => item === 2 || item === true)) return 2 // all displayed
  return 1
}

export const selectVicinitiesLoading = createSelector(
  selectVicinities,
  vicinities => vicinities.loading
)
export const selectVicinitiesPosting = createSelector(
  selectVicinities,
  vicinities => vicinities.posting
)
export const selectVicinitiesCategories = createSelector(
  selectVicinities,
  vicinities => vicinities.categories
)
export const selectVicinitiesCategoriesOptions = createSelector(
  selectVicinities,
  vicinities => vicinities.categories && vicinities.categories.map(category => {
    return {
      label: category.name,
      value: category.id
    }
  })
)
export const selectVicinitiesCategoriesOptionsWithReset = createSelector(
  selectVicinities,
  vicinities => {
    if (vicinities.categories) {
      const options = vicinities.categories.map(category => {
        return {
          label: category.name,
          value: category.id
        }
      })
      return [
        {
          label: i18n.t('all'),
          value: ''
        },
        ...options
      ]
    }
    return null
  }
)
export const selectVicinitiesData = createSelector(
  selectVicinities,
  vicinities => vicinities.data
)
export const selectVicinitiesAllAdditionalDataDisplayed = createSelector(
  selectVicinities,
  vicinities => {
    if (vicinities.data) {
      const data = vicinities.data.filter(vicinity => vicinity.vicinity_type_id !== 0)
      return parseDisplay(data)
    }
    return 0
  }
)
export const selectVicinitiesHiddenBy = createSelector(
  selectVicinities,
  vicinities => vicinities.hiddenBy
)
export const selectVicinitiesHiddenById = createSelector(
  selectVicinities,
  vicinities => vicinities.hiddenById
)
