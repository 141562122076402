import {
  SPACING,
  styled,
  Text,
  Link
} from '@gk-devteam/apmc-core-web'

export const UlStyle = styled.ul`
  list-style: none;
  padding: 0px;
`

export const LiStyle = styled.li`
  padding: ${SPACING.SMALLEST}rem 0px;
`

export const LabelStyle = styled.label`
  width: 40%;
  display: inline-block;
`

export const TextStyle = styled(Text)`
  font-weight: bold;
`
export const LinkStyle = styled(Link)`
  font-weight: bold;
`
