import { createSelector } from 'reselect'

const inspectionSlice = state => state.inspections

export const selectInspectionsLoading = createSelector(
  inspectionSlice,
  inspections => inspections.loading
)
export const selectInspectionsCount = createSelector(
  inspectionSlice,
  inspections => inspections.results_count
)
export const selectInspectionsData = createSelector(
  inspectionSlice,
  inspections => inspections.results
)
