import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchPublicFacilities,
  postPublicFacility,
  togglePublicFacility,
  toggleAdditionalPublicFacilities,
  deletePublicFacility
} from '../../services'
import {
  FETCH_PUBLIC_FACILITIES,
  FETCH_PUBLIC_FACILITIES_SUCCESS,
  FETCH_PUBLIC_FACILITIES_FAIL,
  POST_PUBLIC_FACILITY,
  POST_PUBLIC_FACILITY_SUCCESS,
  POST_PUBLIC_FACILITY_FAIL,
  TOGGLE_PUBLIC_FACILITY,
  TOGGLE_PUBLIC_FACILITY_SUCCESS,
  TOGGLE_PUBLIC_FACILITY_FAIL,
  DELETE_PUBLIC_FACILITY,
  DELETE_PUBLIC_FACILITY_SUCCESS,
  DELETE_PUBLIC_FACILITY_FAIL,
  TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA,
  TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA_SUCCESS,
  TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchPublicFacilitiesSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchPublicFacilities, id, cancelSource)
    if (res && res.data && res.data.list) {
      const { list, categories, hiddenBy, hiddenById } = res.data
      yield put({ type: FETCH_PUBLIC_FACILITIES_SUCCESS, payload: { data: list, categories: categories, hiddenBy, hiddenById } })
    } else {
      yield put({ type: FETCH_PUBLIC_FACILITIES_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_PUBLIC_FACILITIES_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postPublicFacilitySaga ({ data, id, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postPublicFacility, data, id, cancelSource)
    if (res && res.data && res.data.success) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_PUBLIC_FACILITY_SUCCESS })
      yield put({ type: FETCH_PUBLIC_FACILITIES, id })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_PUBLIC_FACILITY_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_PUBLIC_FACILITY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * togglePublicFacilitySaga ({ data, id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(togglePublicFacility, data, id, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: TOGGLE_PUBLIC_FACILITY_SUCCESS })
      yield put({ type: FETCH_PUBLIC_FACILITIES, id })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: TOGGLE_PUBLIC_FACILITY_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: TOGGLE_PUBLIC_FACILITY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * toggleAdditionalPublicFacilitiesSaga ({ data, id, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(toggleAdditionalPublicFacilities, data, id, cancelSource)
    if (res && res.status === 204) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA_SUCCESS })
      yield put({ type: FETCH_PUBLIC_FACILITIES, id })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deletePublicFacilitySaga ({ id, vicinityID }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deletePublicFacility, id, vicinityID, cancelSource)
    if (res) {
      yield put({ type: DELETE_PUBLIC_FACILITY_SUCCESS })
      yield put({ type: FETCH_PUBLIC_FACILITIES, id })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_PUBLIC_FACILITY_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchPublicFacilities () {
  yield takeLatest(FETCH_PUBLIC_FACILITIES, fetchPublicFacilitiesSaga)
  yield takeLatest(POST_PUBLIC_FACILITY, postPublicFacilitySaga)
  yield takeLatest(TOGGLE_PUBLIC_FACILITY, togglePublicFacilitySaga)
  yield takeLatest(TOGGLE_PUBLIC_FACILITIES_ADDITIONAL_DATA, toggleAdditionalPublicFacilitiesSaga)
  yield takeLatest(DELETE_PUBLIC_FACILITY, deletePublicFacilitySaga)
}
