import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  RepeaterRemoveButton,
  TextInput,
  Checkbox
} from '@gk-devteam/apmc-core-web'

const RenewalNoticeRow = ({ index }) => {
  return (
    <Row wrap="wrap" align="baseline">
      <Checkbox
        size='fluid'
        name={`renewal_notice[${index}].enabled`}
        inputLabel='contracts:notice_destination_enabled'
        valueLabel="use"
        repeaterField
        required
      />
      <TextInput
        flex={1}
        type="text"
        name={`renewal_notice[${index}].destination_name`}
        label='contracts:renewal_notice_destination_name'
        placeholder=""
        repeaterField
        required
      />
      <TextInput
        flex={1}
        type="text"
        name={`renewal_notice[${index}].destination_email`}
        label='contracts:renewal_notice_destination_email'
        repeaterField
        placeholder="placeholders.email"
        required
      />
      <RepeaterRemoveButton
        index={index}
        propertyKey="renewal_notice"
        label="delete"
      />
    </Row>
  )
}

export default memo(RenewalNoticeRow)

RenewalNoticeRow.propTypes = {
  index: PropTypes.number.isRequired
}
