import {
  Row,
  SPACING,
  styled
} from '@gk-devteam/apmc-core-web'


export const LoaderContainerStyle = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0%;
  right: 0;
  background-color: rgba(255,255,255,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RowStyle = styled(Row)`
  gap: ${SPACING.LARGEST}rem;
`
export const GroupStyle = styled.div``
export const LabelStyle = styled.div``
export const ValueStyle = styled.div`
  margin-top: 0.25rem;
  min-height: 25.14px;
`
