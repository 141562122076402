import React from 'react'
import dayjs from 'dayjs'
import {
  DatePicker,
  FRONT_DATE_FORMAT,
  RepeaterRemoveButton,
  Row,
  Select,
  TextInput
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { SEX_OPTIONS } from '../../../../../constants'

import { RoomateRowStyle } from '../../ContractForm.style'

function RoommateRow ({ index, relationOptions, loadingRelations }) {
  const disableAfterToday = (current) => {
    if (current) {
      return current.isAfter(dayjs())
    }
    return false
  }
  return (
    <RoomateRowStyle>
      <Row wrap="wrap" align="baseline">
        <TextInput
          flex={1}
          size="M"
          type="text"
          name={`roommates[${index}].last_name`}
          label='contracts:roommates.last_name'
          placeholder=""
          repeaterField
          required
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name={`roommates[${index}].first_name`}
          label='contracts:roommates.first_name'
          placeholder=""
          repeaterField
          required
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name={`roommates[${index}].furigana_last_name`}
          label='contracts:roommates.furigana_last_name'
          placeholder=""
          repeaterField
          required
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name={`roommates[${index}].furigana_first_name`}
          label='contracts:roommates.furigana_first_name'
          placeholder=""
          repeaterField
          required
        />
      </Row>
      <Row wrap="wrap" align="baseline">
        <DatePicker
          flex={1}
          size="XS"
          name={`roommates[${index}].birthday`}
          label='app_users:birthday'
          placeholder={dayjs().subtract(20, 'year').format(FRONT_DATE_FORMAT)}
          disabledDate={disableAfterToday}
          repeaterField
          required
        />
        <Select
          flex={1}
          size="XS"
          name={`roommates[${index}].sex`}
          label='app_users:sex'
          placeholder=""
          options={SEX_OPTIONS}
          repeaterField
          required
        />
        <Select
          flex={1}
          size="XS"
          name={`roommates[${index}].relation_id`}
          label='contracts:invited_users.relation_name'
          placeholder=""
          options={relationOptions}
          loading={loadingRelations}
          repeaterField
          required
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name={`roommates[${index}].phone`}
          label='phone'
          placeholder="placeholders.phone"
          repeaterField
          required
        />
        <TextInput
          flex={1}
          size="M"
          type="text"
          name={`roommates[${index}].work`}
          label='app_users:work.work_school'
          placeholder=""
          repeaterField
          required
        />
        <RepeaterRemoveButton
          index={index}
          propertyKey="roommates"
          label="delete"
        />
      </Row>
    </RoomateRowStyle>
  )
}

RoommateRow.propTypes = {
  index: PropTypes.number.isRequired,
  relationOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  })),
  loadingRelations: PropTypes.bool.isRequired
}

export default RoommateRow
