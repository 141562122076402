import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectPages } from '../selectors'
import {
  FETCH_CONTRACTS_CANCELING,
  FETCH_CONTRACTS_RENEWING,
  RESET_CONTRACTS_RENEWING,
  RESET_CONTRACTS_CANCELING
} from '../types'

export const useContractsProcessing = () => {
  const dispatch = useDispatch()
  const pages = useSelector(selectPages)

  useEffect(() => {
    if (pages?.custom?.cloudsign) {
      dispatch({ type: FETCH_CONTRACTS_RENEWING })
    }
    if (pages?.contracts?.functions?.cancel) {
      dispatch({ type: FETCH_CONTRACTS_CANCELING })
    }

    return () => {
      dispatch({ type: RESET_CONTRACTS_RENEWING })
      dispatch({ type: RESET_CONTRACTS_CANCELING })
    }
  }, [dispatch, pages])
}
