import React from 'react'
import {
  Table
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import {
  selectServiceResults,
  selectServiceLoading,
  selectServiceResultsCount
} from '../../../selectors'

function ServiceByLevelList ({ columns }) {
  return (
    <Table
      columns={columns}
      rowKey=""
      loadingSelector={selectServiceLoading}
      dataSelector={selectServiceResults}
      resultsCountSelector={selectServiceResultsCount}
      leftStickyColumnCount={0}
      rightStickyColumnCount={0}
      fullHeight
      noHeader
    />
  )
}

ServiceByLevelList.propTypes = {
  columns: PropTypes.array.isRequired
}

export default ServiceByLevelList
