import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { UPDATE_FORM } from '@gk-devteam/apmc-core-web'

import {
  ColorBlockContainerStyle,
  ColorBlockStyle,
  ColorBlockLabelStyle
} from './ThemeForm.style'

function ColorBlock ({ theme, label, noClick, active }) {
  const dispatch = useDispatch()
  if (!theme?.accent || !label) return null

  const _handleClick = () => {
    console.log('theme', label, theme)
    if (!noClick) {
      const {
        white,
        inputBorder,
        cardBorder,
        accent,
        info,
        success,
        danger,
        warning,
        text,
        lightText,
        pageTitle
      } = theme
      dispatch({
        type: UPDATE_FORM,
        payload: {
          'colors.white': white,
          'colors.inputBorder': inputBorder,
          'colors.cardBorder': cardBorder,
          'colors.accent': accent,
          'colors.info': info,
          'colors.success': success,
          'colors.danger': danger,
          'colors.warning': warning,
          'colors.text': text,
          'colors.lightText': lightText,
          'colors.pageTitle': pageTitle
        }
      })
    }
  }
  return (
    <ColorBlockContainerStyle color={theme?.accent} noClick={noClick} onClick={_handleClick} active={active}>
      <ColorBlockStyle color={theme?.accent}/>
      <ColorBlockLabelStyle label={label}/>
    </ColorBlockContainerStyle>
  )
}

ColorBlock.propTypes = {
  active: PropTypes.bool,
  theme: PropTypes.shape({
    white: PropTypes.string,
    inputBorder: PropTypes.string,
    cardBorder: PropTypes.string,
    accent: PropTypes.string,
    info: PropTypes.string,
    success: PropTypes.string,
    danger: PropTypes.string,
    warning: PropTypes.string,
    text: PropTypes.string,
    lightText: PropTypes.string,
    pageTitle: PropTypes.string
  }),
  label: PropTypes.string,
  noClick: PropTypes.bool
}

export default ColorBlock
