import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_CONTRACT_OPELO,
  FETCH_CONTRACT_OPELO_SUCCESS,
  FETCH_CONTRACT_OPELO_FAIL,
  RESET_CONTRACT_OPELO,
  POST_CONTRACT_OPELO,
  POST_CONTRACT_OPELO_SUCCESS,
  POST_CONTRACT_OPELO_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: false,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_CONTRACT_OPELO]: (state, action) => {
    state.loading = true
  },
  [FETCH_CONTRACT_OPELO_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_CONTRACT_OPELO_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_CONTRACT_OPELO]: () => INITIAL_STATE,
  [POST_CONTRACT_OPELO]: (state, action) => {
    state.posting = true
  },
  [POST_CONTRACT_OPELO_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CONTRACT_OPELO_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  }
})
