import {
  api,
  MULTIPART_HEADERS,
  getFileFromURL
} from '@gk-devteam/apmc-core-web'

export const fetchAdminProfile = (id, cancelSource) => {
  return api.get(`owners/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const postAdminProfile = async (data, id, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }
    delete data.file
    delete data.file_url
    delete data.file_filename

    formData.append('data', JSON.stringify(data))
    return api.post(`owners/${id}`, formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}
