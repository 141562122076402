export const FETCH_VICINITIES = 'fetch_vicinities'
export const FETCH_VICINITIES_SUCCESS = 'fetch_vicinities_success'
export const FETCH_VICINITIES_FAIL = 'fetch_vicinities_fail'

export const POST_VICINITY = 'post_vicinity'
export const POST_VICINITY_SUCCESS = 'post_vicinity_success'
export const POST_VICINITY_FAIL = 'post_vicinity_fail'

export const DELETE_VICINITY = 'delete_vicinity'
export const DELETE_VICINITY_SUCCESS = 'delete_vicinity_success'
export const DELETE_VICINITY_FAIL = 'delete_vicinity_fail'

export const TOGGLE_VICINITY = 'toggle_vicinity'
export const TOGGLE_VICINITY_SUCCESS = 'toggle_vicinity_success'
export const TOGGLE_VICINITY_FAIL = 'toggle_vicinity_fail'

export const TOGGLE_VICINITIES_ADDITIONAL_DATA = 'toggle_vicinities_additional_data'
export const TOGGLE_VICINITIES_ADDITIONAL_DATA_SUCCESS = 'toggle_vicinities_additional_data_success'
export const TOGGLE_VICINITIES_ADDITIONAL_DATA_FAIL = 'toggle_vicinities_additional_data_fail'

export const RESET_VICINITIES = 'reset_vicinities'
