import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import dot from 'dot-object'
import PropTypes from 'prop-types'
import { ReadOnlyContext } from '@gk-devteam/apmc-core-web'

import SelectedItem from './SelectedItem'
import {
  SelectedContainer
} from './common.style'

const SelectedItems = ({ itemsKey, modal, readOnly }) => {
  const isReadOnly = useContext(ReadOnlyContext)
  const selectedItems = useSelector(state => {
    if (!itemsKey) return null
    const form = state.form && dot.object({ ...state.form })
    return form[`${itemsKey}_list`]
  })

  const _renderSelectedItems = () => {
    if (!selectedItems) return null
    return selectedItems.map(item => <SelectedItem key={item.id} id={item.id} label={item.label} itemKey={itemsKey} readOnly={readOnly} />)
  }

  return (
    <SelectedContainer modalMode={modal} disabled={isReadOnly || readOnly}>
      { _renderSelectedItems() }
    </SelectedContainer>
  )
}

export default SelectedItems

SelectedItems.propTypes = {
  itemsKey: PropTypes.string.isRequired,
  modal: PropTypes.bool,
  readOnly: PropTypes.bool
}
