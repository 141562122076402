import React from 'react'
import { useSelector } from 'react-redux'
import { Empty } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation
} from '@gk-devteam/apmc-core-web'

import {
  selectPublicFacilitiesData
} from '../../../../selectors'

import {
  BlockStyle,
  CollapseStyle,
  PanelStyle
} from '../../panels.style'
import FacilitiesListItemHeader from './FacilitiesListItemHeader'
import FacilitiesListItemContent from './FacilitiesListItemContent'

const FacilitiesList = ({ filter, editModal, copyModal }) => {
  const publicFacilities = useSelector(selectPublicFacilitiesData)
  const { t } = useTranslation()

  const _renderPanel = (facilities) => {
    if (!facilities) return null
    if (facilities.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('no_data')}
        />
      )
    }

    return (
      <CollapseStyle
        bordered={false}
        expandIconPosition="right"
        // disabled={disabled}
        // defaultActiveKey="契約_item1"
      >
        {
          facilities.map((item, i) => {
            return (
              <PanelStyle
                header={(
                  <FacilitiesListItemHeader
                    item={item}
                  />
                )}
                key={`item$_${i}_${item.id}`}
              >
                <FacilitiesListItemContent item={item} editModal={editModal} copyModal={copyModal} />
              </PanelStyle>
            )
          })
        }
      </CollapseStyle>
    )
  }

  const _renderList = () => {
    if (!publicFacilities) return null
    if (!filter) {
      return _renderPanel(publicFacilities)
    } else {
      const filtered = publicFacilities.filter(publicFacility => filter === publicFacility.category)
      if (filtered) {
        return _renderPanel(filtered)
      }
    }
  }
  return (
    <BlockStyle>
      { _renderList() }
    </BlockStyle>
  )
}

export default FacilitiesList

FacilitiesList.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  copyModal: PropTypes.func.isRequired,
  editModal: PropTypes.func.isRequired
}
