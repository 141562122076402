import { put, call, takeLatest, cancelled, select } from 'redux-saga/effects'
import { CancelToken } from 'axios'
import { message } from 'antd'

import {
  fetchGuest,
  postGuest,
  deleteGuest,
  sendGuestEmail
} from '../../services'
import {
  FETCH_GUESTS,
  FETCH_GUEST,
  FETCH_GUEST_SUCCESS,
  FETCH_GUEST_FAIL,
  POST_GUEST,
  POST_GUEST_SUCCESS,
  POST_GUEST_FAIL,
  DELETE_GUEST,
  DELETE_GUEST_SUCCESS,
  DELETE_GUEST_FAIL,
  SEND_GUEST_EMAIL
  // SEND_GUEST_EMAIL_SUCCESS,
  // SEND_GUEST_EMAIL_FAIL,
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchGuestSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchGuest, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_GUEST_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_GUEST_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_GUEST_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postGuestSaga ({ data, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postGuest, data, cancelSource)
    if (res && res.data && res.data.success) {
      if (successRedirect) yield call(successRedirect)
      yield put({ type: POST_GUEST_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_GUEST_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_GUEST_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteGuestSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteGuest, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_GUEST_SUCCESS })
      yield put({ type: FETCH_GUESTS, query })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_GUEST_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * sendGuestEmailSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(sendGuestEmail, id, cancelSource)
    if (res && res.status === 204) {
      yield call(message.success, i18n.t('mail_success'))
    } else {
      yield call(message.error, i18n.t('mail_error'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('mail_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchGuest () {
  yield takeLatest(FETCH_GUEST, fetchGuestSaga)
  yield takeLatest(POST_GUEST, postGuestSaga)
  yield takeLatest(DELETE_GUEST, deleteGuestSaga)
  yield takeLatest(SEND_GUEST_EMAIL, sendGuestEmailSaga)
}
