import {
  styled,
  Image,
  SPACING,
  BORDER_RADIUS
} from '@gk-devteam/apmc-core-web'

export const SeikatsuServiceStyle = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${SPACING.MEDIUM_LARGE}rem;
  margin-bottom: ${SPACING.REGULAR}rem;
`

export const ImageStyle = styled(Image)`
  ${BORDER_RADIUS};
  width: 40px;
  height: 40px;
  margin-right: ${SPACING.SMALL}rem;
`
