import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Empty, message } from 'antd'
import { Link } from '@reach/router'
import {
  useTranslation,
  api,
  Modal,
  Spinner,
  Row,
  LightText,
  Text,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  selectNoticeConditionsModal,
  selectActiveNoticeStatus
} from '../../../selectors'
import { CLOSE_NOTICE_CONDITIONS_MODAL } from '../../../types'

import {
  TextAccentStyle,
  ConditionsContainerStyle,
  ConditionRowStyle,
  ConditionItemStyle,
  RecipientsContainerStyle,
  RecipientItemStyle
} from './NoticesPage.style'

const NoticeRecipientConditionsModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const visible = useSelector(selectNoticeConditionsModal)
  const noticeID = useSelector(selectActiveNoticeStatus)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  const fetchData = useCallback(
    async () => {
      try {
        const res = await api.get(`/notices/${noticeID}/recipients_condition`)
        if (res && res.data) {
          setData(res.data)
          setLoading(false)
        } else {
          message.error(t('fetch_error'))
          setLoading(false)
        }
      } catch (error) {
        console.warning('error', error)
        message.error(t('fetch_error'))
        setLoading(false)
      }
    },
    [noticeID, t]
  )

  useEffect(() => {
    if (visible && noticeID) { fetchData() }
    return () => {
      setLoading(true)
      setData(null)
    }
  }, [fetchData, visible, noticeID])

  const _closeModal = () => {
    dispatch({ type: CLOSE_NOTICE_CONDITIONS_MODAL })
  }

  const _renderConditions = () => {
    if (!data) return null
    if (!data.recipients_condition || data.recipients_condition.length === 0) {
      return (
        <Row mt="XS" px="S"><Text label="none" /></Row>
      )
    }

    return data.recipients_condition.map((condition, i) => {
      const {
        min_income,
        max_income,
        sex,
        min_age,
        max_age
      } = condition
      let sexLabel = ''
      switch (sex) {
        case 0:
          sexLabel = 'app_users:no_sex'
          break
        case 1:
          sexLabel = 'app_users:male'
          break
        case 2:
          sexLabel = 'app_users:female'
          break
        default:
          break
      }
      return (
        <ConditionRowStyle mt="XS" key={i} px="S" py="S">
          { min_income
            ? (
              <ConditionItemStyle>
                <Text label="notices:min_income" />
                :{min_income}
                <Text label="app_users:annual_income_unit" />
              </ConditionItemStyle>
            )
            : null
          }
          { max_income
            ? (
              <ConditionItemStyle>
                <Text label="notices:max_income" />
                :{max_income}
                <Text label="app_users:annual_income_unit" />
              </ConditionItemStyle>
            )
            : null
          }
          { sex
            ? (
              <ConditionItemStyle>
                <Text label="app_users:sex" />
                :
                <Text label={sexLabel} />
              </ConditionItemStyle>
            )
            : null
          }
          { min_age
            ? (
              <ConditionItemStyle>
                <Text label="notices:min_age" />
                :{min_age}
              </ConditionItemStyle>
            )
            : null
          }
          { max_age
            ? (
              <ConditionItemStyle>
                <Text label="notices:max_age" />
                :{max_age}
              </ConditionItemStyle>
            )
            : null
          }
          { sex ? '' : '' }
          { min_age ? '' : '' }
          { max_age ? '' : '' }

        </ConditionRowStyle>
      )
    })
  }

  const _renderRecipients = () => {
    if (!data) return null
    if (!data.recipients || data.recipients.length === 0) {
      return (
        <Row mt="XS" px="S"><Text label="none" /></Row>
      )
    }

    if (data.recipient_type === 0) {
      return (
        <Text label="all" />
      )
    }

    return data.recipients.map((recipient, i) => {
      const { id, name } = recipient
      if (data.recipient_type === 3) {
        return (
          <RecipientItemStyle key={i}>
            <Text text={name}/>
          </RecipientItemStyle>
        )
      }
      let link = ''
      switch (data.recipient_type) {
        case 1:
          link = `/properties/edit/${id}`
          break
        case 2:
          link = `/app_users/edit/${id}`
          break
        default:
          break
      }

      return (
        <RecipientItemStyle key={i} link="true">
          <Link to={link}>{name}</Link>
        </RecipientItemStyle>
      )
    })
  }

  const _renderData = () => {
    if (loading) return <Spinner color="info" />
    if (!data) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <LightText label='no_data'/>
          }
        />
      )
    }
    const {
      recipient_type,
      user_type
    } = data
    let recipientType, userType
    switch (recipient_type) {
      case 0:
        recipientType = 'all'
        break
      case 1:
        recipientType = 'property'
        break
      case 2:
        recipientType = 'user'
        break
      case 3:
        recipientType = 'area'
        break
      default:
        break
    }
    switch (user_type) {
      case 1:
        userType = 'all'
        break
      case 2:
        userType = 'notices:user_contractor'
        break
      case 3:
        userType = 'notices:user_resident'
        break
      case 4:
        userType = 'notices:user_contractor_only'
        break
      case 5:
        userType = 'notices:user_resident_only'
        break
      default:
        break
    }

    return (
      <>
        <Row>
          <Text label="notices:notice_conditions_recipient_type" />
          <TextAccentStyle label={recipientType} />
          <RoleControlled authorized='!custom.house_maker'>
            <Text label="notices:notice_conditions_user_type" />
            <TextAccentStyle label={userType} />
          </RoleControlled>
        </Row>
        <Row mt="R">
          <Text label="notices:notice_conditions" />
        </Row>
        <ConditionsContainerStyle>
          { _renderConditions() }
        </ConditionsContainerStyle>
        <Row mt="R">
          <Text label="notices:notice_recipients" />
        </Row>
        <RecipientsContainerStyle>
          { _renderRecipients() }
        </RecipientsContainerStyle>
      </>
    )
  }

  return (
    <Modal
      visible={visible}
      onCancel={_closeModal}
      cancelText='close'
      title="notices:recipients_condition"
      fullWidth
    >
      { _renderData() }
    </Modal>
  )
}

export default NoticeRecipientConditionsModal

NoticeRecipientConditionsModal.propTypes = {

}
NoticeRecipientConditionsModal.defaultProps = {

}
