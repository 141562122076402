import {
  Card,
  SPACING,
  styled,
  Table,
  ToastTitle
} from '@gk-devteam/apmc-core-web'

export const GridStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${SPACING.LARGE / 2}rem;
`

export const CardStyle = styled(Card)`
  min-width: 400px;
  flex: 1 0 0;
  margin-left: ${SPACING.LARGE / 2}rem;
  margin-right: ${SPACING.LARGE / 2}rem;
`

export const TableStyle = styled(Table)`
  max-height: 400px;
`
export const StatusStyle = styled.div`
  text-align: right;
`
export const ContractorStyle = styled(ToastTitle)`
  white-space: nowrap;
`
