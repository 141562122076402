import React, { memo } from 'react'
import {
  Centered,
  CardTitle,
  Row,
  Column
} from '@gk-devteam/apmc-core-web'

import {
  HeadlineStyle,
  TitleStyle
} from './MaintenancePage.style'

const MaintenancePage = () => {
  return (
    <Centered fullScreen>
      <Column>
        <Row mb={'L'} justify={'center'}>
          <TitleStyle label={'app-me!Cloud'} />
        </Row>
        <HeadlineStyle label={'maintenance:maintenance'} />
        <Row mt={'R'}>
          <Row wrap={'wrap'}>
            <Row justify={'center'}>
              <CardTitle label={'maintenance:detail_1'} />
            </Row>
            <Row justify={'center'}>
              <CardTitle label={'maintenance:detail_2'} />
            </Row>
            <Row justify={'center'} mt={'R'}>
              <CardTitle label={'maintenance:apology'} />
            </Row>
          </Row>
        </Row>
      </Column>
    </Centered>
  )
}
export default memo(MaintenancePage)

MaintenancePage.propTypes = {

}
MaintenancePage.defaultProps = {

}
