import React, { memo, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import {
  Row,
  TextInput,
  Card,
  DangerButton,
  ReadOnlyContext,
  REMOVE_REPEATER_ROW,
  PrimaryButton,
  Checkbox,
  Select,
  UUID,
  UPDATE_FORM,
  useTranslation,
  RepeaterFields,
  FormControlLabel,
  RepeaterAddButton,
  RepeaterRemoveButton,
  Help
} from '@gk-devteam/apmc-core-web'

import { SURVEY_QUESTION_TYPE, SURVEY_QUESTION_TYPE_OPTIONS } from '../../../constants'

import { OptionsStyle, QuestionDragStyle } from '../SurveyForm.style'

const QuestionsRow = ({ item, index = 0, length = 0 }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isReadOnly = useContext(ReadOnlyContext)

  const _handleRemoveRow = () => {
    dispatch({ type: REMOVE_REPEATER_ROW, payload: { index, key: 'questions' } })
  }
  const _handleCopyRow = () => {
    const { label, type, required, max_length, options } = item
    const payload = {
      [`questions[${length}].uuid`]: UUID(),
      [`questions[${length}].label`]: label,
      [`questions[${length}].type`]: type,
      [`questions[${length}].required`]: required,
      [`questions[${length}].max_length`]: max_length,
      [`questions[${length}].options`]: options
    }
    console.log(index, item)
    console.log(payload)
    dispatch({ type: UPDATE_FORM, payload })
  }

  const _renderMaxLength = () => {
    if (item.type === SURVEY_QUESTION_TYPE.TEXT || item.type === SURVEY_QUESTION_TYPE.TEXTAREA) {
      return (
        <TextInput
          size="M"
          type="number"
          name={`questions[${index}].max_length`}
          label='surveys:question.max_length'
          placeholder=""
          repeaterField
        />
      )
    }
    return null
  }
  const _renderOptions = () => {
    if (item.type === SURVEY_QUESTION_TYPE.CHECKBOX || item.type === SURVEY_QUESTION_TYPE.RADIO) {
      return (
        <>
          <FormControlLabel label={t('surveys:question.options')} required/>
          <Row wrap="wrap">
            <RepeaterFields
              propertyKey={`questions[${index}].options`}
              field={subindex => (
                <OptionsStyle key={subindex}>
                  <TextInput
                    size="L"
                    type="text"
                    name={`questions[${index}].options[${subindex}]`}
                    placeholder="surveys:question.options"
                    repeaterField
                    noLabel
                    mr="0"
                  />
                  <RepeaterRemoveButton
                    index={subindex}
                    propertyKey={`questions[${index}].options`}
                  />
                </OptionsStyle>
              )}
            />
          </Row>
          <RepeaterAddButton
            label="surveys:question.add_options"
            propertyKey={`questions[${index}].options`}
          />

        </>
      )
    }
    return null
  }

  return (
    <Draggable key={item.uuid} draggableId={item.uuid} index={index} isDragDisabled={isReadOnly}>
      {(provided, snapshot) => (
        <QuestionDragStyle
          ref={provided.innerRef}
          {...provided.draggableProps}
          // {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
          style={provided.draggableProps.style}
        >
          <Card
            mb="L"
            title={`${t('surveys:questions')} ${index + 1}`}
            dragHandleProps={provided.dragHandleProps}
            rightText={<Help content="surveys:draggable" />}
          >
            <Row>
              <TextInput
                flex={1}
                type="text"
                name={`questions[${index}].label`}
                label='surveys:questions'
                placeholder=""
                repeaterField
                required
              />
            </Row>
            <Row wrap="wrap" align="baseline">
              <Select
                size="XL"
                name={`questions[${index}].type`}
                label='surveys:question.type'
                placeholder=""
                options={SURVEY_QUESTION_TYPE_OPTIONS}
                noClear
              />
              <Checkbox
                name={`questions[${index}].required`}
                inputLabel="required"
              />
              { _renderMaxLength() }
            </Row>
            { _renderOptions() }
            {
              !isReadOnly && (
                <Row justify="end">
                  <PrimaryButton
                    label="copy"
                    handleClick={_handleCopyRow}
                    withMargin
                    type="button"
                  />
                  <DangerButton
                    label="delete"
                    handleClick={_handleRemoveRow}
                    reverse
                    withMargin
                    type="button"
                  />
                </Row>
              )
            }
          </Card>
        </QuestionDragStyle>
      )}
    </Draggable>
  )
}

export default memo(QuestionsRow)

QuestionsRow.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired
}
