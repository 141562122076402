import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import {
  api,
  useTranslation,
  UPDATE_FORM,
  Card
} from '@gk-devteam/apmc-core-web'

import { selectContractID } from '../../../../../selectors'
import { appUserPhoneSearchSchema } from '../../../../../validation'

import AppUserModal from './AppUserModal'
import AppUserNameLink from './AppUserNameLink'
import WaitingUserApproval from './WaitingUserApproval'
import AppUserNameForm from './AppUserNameForm'

export const YasueContractorCard = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState(false)
  const [result, setResult] = useState(null)
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [searchError, setSearchError] = useState('')
  const waitingApproval = useSelector(state => state.form.awaiting_contractor_approval)
  const userID = useSelector(state => state.form.contractor_user_id)
  const contractID = useSelector(selectContractID)

  const handleWaitForUser = useCallback(
    (userID) => {
      dispatch({
        type: UPDATE_FORM,
        payload: {
          contractor_user_id: userID,
          awaiting_contractor_approval: true
        }
      })
    },
    [dispatch]
  )

  const _closeModal = useCallback(
    () => {
      setIsVisible(false)
    },
    []
  )
  const _handleSuccess = useCallback(
    ({ user, userID }) => {
      if (userID) {
        handleWaitForUser(userID)
      } else {
        const {
          app_user_id,
          last_name,
          furigana_last_name,
          phone
        } = user
        dispatch({
          type: UPDATE_FORM,
          payload: {
            contractor_user_id: app_user_id,
            'contractor.last_name': last_name,
            'contractor.furigana_last_name': furigana_last_name,
            'contractor.phone': phone
          }
        })
      }
      _closeModal()
    },
    [_closeModal, dispatch, handleWaitForUser]
  )

  const _handleSearch = useCallback(
    async (search) => {
      if (search) {
        const isValid = await appUserPhoneSearchSchema().isValid({ phone: search })
        if (isValid) {
          setIsLoading(true)
          setSearchError('')
          setResult(null)
          setUser(null)
          try {
            const res = await api.get(`app_users/search?phone=${search}`)
            setIsLoading(false)
            setIsVisible(true)
            if (res.data) {
              setResult(res.data.result)
              setUser(res.data.app_user)
            } else {
              setSearchError(t('fetch_error'))
            }
          } catch (error) {
            console.warning('error', error)
            setIsLoading(false)
            message.error(t('fetch_error'))
            // setSearchError(error)
          }
        } else {
          setSearchError(t('contracts:wrong_phone'))
        }
      } else {
        setSearchError(t('contracts:no_phone'))
      }
    },
    [t]
  )

  if (waitingApproval) return <WaitingUserApproval userType="contractor" />

  const _renderUserName = () => {
    if (contractID && userID) return <AppUserNameLink userType="contractor" />

    return (
      <>
        <AppUserModal
          visible={isVisible}
          onCancel={_closeModal}
          onSuccess={_handleSuccess}
          result={result}
          user={user}
        />
        <AppUserNameForm
          userType="contractor"
          handleSearch={_handleSearch}
          loading={isLoading}
          error={searchError}
        />
      </>
    )
  }

  return (
    <Card mb="L" title="contracts:contractor.contractor_info">
      {_renderUserName()}
    </Card>
  )
}

YasueContractorCard.propTypes = {}
