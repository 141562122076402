import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MainLayout
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import {
  selectContractOpeloData,
  selectContractData
} from '../../../selectors'
import { FETCH_CONTRACT_OPELO, RESET_CONTRACT_OPELO } from '../../../types'

import OpeloForm from '../../../forms/contract/OpeloForm'
import NotFoundPage from '../../NotFoundPage'

function ContractOpelo ({ contractID }) {
  const dispatch = useDispatch()
  const contract = useSelector(selectContractData)
  const data = useSelector(selectContractOpeloData)

  useEffect(() => {
    dispatch({ type: FETCH_CONTRACT_OPELO, id: contractID })
    return () => {
      dispatch({ type: RESET_CONTRACT_OPELO })
    }
  }, [dispatch, contractID])

  const breadcrumbs = [
    {
      label: 'navigation:contracts',
      link: '/contracts'
    },
    {
      label: 'navigation:contracts_details',
      link: `/contracts/edit/${contractID}`
    },
    {
      label: 'navigation:opelo',
      link: `/contracts/edit/${contractID}/opelo`
    }
  ]

  if (!contract?.services?.opelo) return <NotFoundPage withSidebar/>

  return (
    <MainLayout
      withSidebar
      breadcrumbs={breadcrumbs}
      // loading={loading}
    >
      <OpeloForm data={data} contractID={contractID} />
    </MainLayout>
  )
}

ContractOpelo.propTypes = {
  contractID: PropTypes.string.isRequired
}

export default ContractOpelo
