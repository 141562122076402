import { api, MULTIPART_HEADERS, getFileFromURL } from '@gk-devteam/apmc-core-web'

export const fetchAdminUser = (id, cancelSource) => {
  return api.get(`admin_users/${id}`, {
    cancelToken: cancelSource.token
  })
}

export const postAdminUser = async (data, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }
    delete data.file
    delete data.file_url
    delete data.file_filename
    delete data.groups_list
    delete data.properties_list

    formData.append('data', JSON.stringify(data))
    return api.post('admin_users/', formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}
