import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {
  Row,
  Select,
  DatePicker
} from '@gk-devteam/apmc-core-web'

import { NOTICE_TYPE_OPTIONS } from '../../../constants'
import { selectNoticeEditable } from '../../../selectors'

const NoticeType = () => {
  const noticeType = useSelector(state => state.form.notice_type)
  const stopTime = useSelector(state => state.form.stop_time)
  const editable = useSelector(selectNoticeEditable)

  const disableNextDate = (current) => {
    if (current && stopTime) {
      const end = dayjs(stopTime)
      return end && current.isAfter(end)
    }
    return false
  }
  return (
    <Row wrap="wrap" align="baseline">
      <Select
        flex={1}
        size="M"
        type="text"
        name="notice_type"
        label='notices:notice_type'
        placeholder=""
        options={NOTICE_TYPE_OPTIONS}
        disabled={!editable}
        required
        noClear
      />
      {
        noticeType && noticeType === 2
          ? (
            <DatePicker
              flex={1}
              ml="R"
              name="start_time"
              label='notices:start_time_settings'
              placeholder=""
              disabledDate={disableNextDate}
              readonly={!editable}
              showTime
              required
            />
          )
          : null
      }
    </Row>
  )
}

export default memo(NoticeType)

// NoticeType.propTypes = {}
