import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_CONTRACT,
  FETCH_CONTRACT_SUCCESS,
  FETCH_CONTRACT_FAIL,
  RESET_CONTRACT,
  POST_CONTRACT,
  POST_CONTRACT_SUCCESS,
  POST_CONTRACT_FAIL,
  // DELETE_CONTRACT,
  // DELETE_CONTRACT_SUCCESS,
  // DELETE_CONTRACT_FAIL,
  FETCH_RENEW_CONTRACT,
  FETCH_RENEW_CONTRACT_SUCCESS,
  FETCH_RENEW_CONTRACT_FAIL,
  RESET_RENEW_CONTRACT,
  POST_RENEW_CONTRACT,
  POST_RENEW_CONTRACT_SUCCESS,
  POST_RENEW_CONTRACT_FAIL,
  POST_ACCEPT_RENEW_CONTRACT,
  POST_ACCEPT_RENEW_CONTRACT_SUCCESS,
  POST_ACCEPT_RENEW_CONTRACT_FAIL,
  POST_CANCEL_RENEW_CONTRACT,
  POST_CANCEL_RENEW_CONTRACT_SUCCESS,
  POST_CANCEL_RENEW_CONTRACT_FAIL,
  FETCH_CANCEL_CONTRACT,
  FETCH_CANCEL_CONTRACT_SUCCESS,
  FETCH_CANCEL_CONTRACT_FAIL,
  RESET_CANCEL_CONTRACT,
  POST_CANCEL_CONTRACT,
  POST_CANCEL_CONTRACT_SUCCESS,
  POST_CANCEL_CONTRACT_FAIL,
  POST_CANCEL_CONTRACT_CANCELING,
  POST_CANCEL_CONTRACT_CANCELING_SUCCESS,
  POST_CANCEL_CONTRACT_CANCELING_FAIL,
  DELETE_CASA_APPROVAL_NUMBER
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  renew: null,
  cancel: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_CONTRACT]: (state, action) => {
    state.posting = true
  },
  [POST_CONTRACT_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CONTRACT_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_CONTRACT]: (state, action) => {
    if (action && !action.noLoading) {
      state.loading = true
    }
  },
  [FETCH_CONTRACT_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_CONTRACT_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_RENEW_CONTRACT]: (state, action) => {
    state.posting = true
  },
  [POST_RENEW_CONTRACT_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_RENEW_CONTRACT_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [POST_CANCEL_RENEW_CONTRACT]: (state, action) => {
    state.posting = true
  },
  [POST_CANCEL_RENEW_CONTRACT_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CANCEL_RENEW_CONTRACT_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [RESET_CONTRACT]: () => INITIAL_STATE,
  [FETCH_RENEW_CONTRACT]: (state, action) => {
    if (action && !action.noLoading) {
      state.loading = true
    }
  },
  [FETCH_RENEW_CONTRACT_SUCCESS]: (state, action) => {
    state.loading = false
    state.renew = action.payload
  },
  [FETCH_RENEW_CONTRACT_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_RENEW_CONTRACT]: (state, action) => {
    state.renew = null
  },
  [FETCH_CANCEL_CONTRACT]: (state, action) => {
    if (action && !action.noLoading) {
      state.loading = true
    }
  },
  [FETCH_CANCEL_CONTRACT_SUCCESS]: (state, action) => {
    state.loading = false
    state.cancel = action.payload
  },
  [FETCH_CANCEL_CONTRACT_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_CANCEL_CONTRACT]: (state, action) => {
    state.cancel = null
  },
  [POST_ACCEPT_RENEW_CONTRACT]: (state, action) => {
    state.posting = true
  },
  [POST_ACCEPT_RENEW_CONTRACT_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_ACCEPT_RENEW_CONTRACT_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [POST_CANCEL_CONTRACT]: (state, action) => {
    state.posting = true
  },
  [POST_CANCEL_CONTRACT_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CANCEL_CONTRACT_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [POST_CANCEL_CONTRACT_CANCELING]: (state, action) => {
    state.posting = true
  },
  [POST_CANCEL_CONTRACT_CANCELING_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CANCEL_CONTRACT_CANCELING_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [DELETE_CASA_APPROVAL_NUMBER]: (state, action) => {
    state.posting = true
  }
})
