import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const roomUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('common:id')),
    room_number: yup.string().max(255).required().label(i18n.t('properties:room_number')),
    room_manage_id: yup.string().nullable().label(i18n.t('rooms:room_manage_id')),
    room_surface: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('rooms:room_surface')),
    room_plan: yup.string().max(255).nullable().label(i18n.t('rooms:room_plan')),
    memo: yup.string().nullable().max(5000).label(i18n.t('common:memo'))
  })
}
