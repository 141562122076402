import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { MainLayout } from '@gk-devteam/apmc-core-web'

import { selectConstructionWorkData } from '../../../selectors'
import ConstructionWorkForm from '../../../forms/yasue/ConstructionWorkForm/ConstructionWorkForm'

const ConstructionWorkDetailsPage = ({ constructionWorkID }) => {
  const data = useSelector(selectConstructionWorkData)

  const breadcrumbs = [
    {
      label: 'navigation:yasue.construction_work_list',
      link: '/yasue/construction_works?sort_by=contract_date&sort_order=desc'
    },
    {
      text: (data && data.title) || '',
      link: `/yasue/construction_work/edit/${constructionWorkID}`
    }
  ]

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={!data}
      lastEdited={data && data.modified}
      lastEditedBy={data && data.modifiedBy}
    >
      <ConstructionWorkForm data={data} constructionWorkID={constructionWorkID} />
    </MainLayout>
  )
}

export default ConstructionWorkDetailsPage

ConstructionWorkDetailsPage.propTypes = {
  constructionWorkID: PropTypes.string.isRequired
}
