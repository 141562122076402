import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  PageLayout,
  RESET_PARAMS,
  Row,
  selectPages,
  selectQueryParams,
  Tabs,
  Title,
  updateQueryParameters
} from '@gk-devteam/apmc-core-web'
import { Tabs as AntTab } from 'antd'
import PropTypes from 'prop-types'

import { FETCH_CONTRACTS, FETCH_CONTRACTS_PROCESSING_COUNT, RESET_CONTRACTS, RESET_CONTRACTS_PROCESSING_COUNT } from '../../../types'
import { CONTRACT_STATUS } from '../../../constants'
import {
  selectAskingForRenewalContractsCount,
  selectRenewingContractsCount,
  selectRequestingCancelContractsCount,
  selectCancelingContractsCount,
  selectRenewingPeriodContractsCount
} from '../../../selectors'

import ContractsList from './ContractsList'
import TabHeader from './TabHeader'

const { TabPane } = AntTab

function AdminTasksContractsPage ({ location }) {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const pages = useSelector(selectPages)
  const askingForRenewalContractsCount = useSelector(selectAskingForRenewalContractsCount)
  const renewingContractsCount = useSelector(selectRenewingContractsCount)
  const requestingCancelContractsCount = useSelector(selectRequestingCancelContractsCount)
  const cancelingContractsCount = useSelector(selectCancelingContractsCount)
  const renewingPeriodContractsCount = useSelector(selectRenewingPeriodContractsCount)
  const [activeKey, setActiveKey] = useState('0')

  const breadcrumbs = [
    {
      label: 'navigation:admin_tasks',
      link: '/admin_tasks'
    },
    {
      label: 'navigation:admin_tasks_contracts',
      link: '/admin_tasks/contracts'
    }
  ]

  useEffect(() => {
    dispatch({ type: FETCH_CONTRACTS_PROCESSING_COUNT })
  }, [dispatch])

  useEffect(() => {
    if (pages) {
      dispatch({ type: RESET_CONTRACTS })
      let queryStatus = query?.status
      switch (query?.status) {
        case `${CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value},${CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value}`:
          setActiveKey('0')
          break
        case String(CONTRACT_STATUS.RENEWING.value):
          setActiveKey('1')
          break
        case String(CONTRACT_STATUS.REQUESTING_CANCEL.value):
          setActiveKey('2')
          break
        case String(CONTRACT_STATUS.CANCELING.value):
          setActiveKey('3')
          break
        case String(CONTRACT_STATUS.IN_RENEWING_PERIOD.value):
          setActiveKey('4')
          break
        default:
          if (pages?.custom?.cloudsign) {
            queryStatus = [
              CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value,
              CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value
            ].join(',')
            setActiveKey('0')
          } else {
            queryStatus = CONTRACT_STATUS.IN_RENEWING_PERIOD.value
            setActiveKey('4')
          }
          dispatch(updateQueryParameters({
            status: String(queryStatus)
          }))
          break
      }

      dispatch({
        type: FETCH_CONTRACTS,
        query: {
          ...query,
          status: String(queryStatus)
        }
      })
    }
  }, [query, pages, dispatch])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_CONTRACTS })
      dispatch({ type: RESET_CONTRACTS_PROCESSING_COUNT })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const _handleTabChange = (activeKey) => {
    setActiveKey(activeKey)
    let status
    switch (Number(activeKey)) {
      case 0:
        status = [
          CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value,
          CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value
        ].join(',')
        break
      case 1:
        status = CONTRACT_STATUS.RENEWING.value
        break
      case 2:
        status = CONTRACT_STATUS.REQUESTING_CANCEL.value
        break
      case 3:
        status = CONTRACT_STATUS.CANCELING.value
        break
      case 4:
        status = CONTRACT_STATUS.IN_RENEWING_PERIOD.value
        break
      default:
        break
    }
    if (status) {
      dispatch(updateQueryParameters({
        status: String(status)
      }))
    }
  }

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="navigation:admin_tasks_contracts"
    >
      <Row mt="R">
        <Title label="navigation:contracts_list"/>
      </Row>
      <Row mt="ML">
        <Tabs
          activeKey={activeKey}
          onChange={_handleTabChange}
        >
          {
            pages?.custom?.cloudsign
              ? (
                <>
                  <TabPane key="0" tab={<TabHeader header='admin_tasks:contract_statuses.asking_for_renewal' count={askingForRenewalContractsCount}/>}>
                    <ContractsList/>
                  </TabPane>
                  <TabPane key="1" tab={<TabHeader header='admin_tasks:contract_statuses.renewing' count={renewingContractsCount}/>}>
                    <ContractsList/>
                  </TabPane>
                </>
              )
              : null
          }

          <TabPane key="2" tab={<TabHeader header='admin_tasks:contract_statuses.requesting_cancel' count={requestingCancelContractsCount}/>}>
            <ContractsList/>
          </TabPane>
          <TabPane key="3" tab={<TabHeader header='admin_tasks:contract_statuses.canceling' count={cancelingContractsCount}/>}>
            <ContractsList/>
          </TabPane>
          <TabPane key="4" tab={<TabHeader header='admin_tasks:contract_statuses.in_renewing_period' count={renewingPeriodContractsCount}/>}>
            <ContractsList/>
          </TabPane>
        </Tabs>
      </Row>
    </PageLayout>
  )
}

export default AdminTasksContractsPage

AdminTasksContractsPage.propTypes = {
  location: PropTypes.object.isRequired
}
