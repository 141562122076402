import {
  SUBSCRIBE_APP,
  SUBSCRIBE_APP_SUCCESS
// SUBSCRIBE_APP_FAIL,
// UNSUBSCRIBE_APP
} from '../types'
import { checkIfLoggedIn, db } from '../services/firebaseService'
import { APP_COLLECTION_NAME } from '../constants'

let unsubscribeApp

const getAppInfo = (user, dispatch) => {
  if (user && user.uid) {
    // Remove listener if already exists
    if (unsubscribeApp) {
      unsubscribeApp()
    }
    const appRef = db.collection(APP_COLLECTION_NAME).doc('general')
    unsubscribeApp = appRef.onSnapshot(doc => {
      if (!doc.exists) {
        console.log('No app document!')
      } else {
        const appInfo = doc.data()
        if (appInfo.version) {
          // localStorage.setItem('appVersion', appInfo.version)
          // if (getState().app.version && getState().app.version !== appInfo.version) {

          // }
          dispatch({ type: SUBSCRIBE_APP_SUCCESS, payload: appInfo })
        }
      }
    })
  }
}

export const subscribeToAppInfo = () => (dispatch) => {
  dispatch({ type: SUBSCRIBE_APP })
  checkIfLoggedIn((user) => getAppInfo(user, dispatch))
}

export const unSubscribeFromApp = () => {
  try {
    if (unsubscribeApp) unsubscribeApp()
  } catch (error) {
    // Handle Errors here.
    // var errorCode = error.code
    // var errorMessage = error.message
    console.warn(error)
  }
}
