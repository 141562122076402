import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {
  UPDATE_FORM,
  selectQueryParams,
  SERVER_DATE_FORMAT,
  LeftSearchMenu,
  TextInput,
  Select,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import { noticesSearchSchema } from '../../../validation'
import {
  NOTICE_TYPE_OPTIONS,
  SEX_OPTIONS
} from '../../../constants'

import RecipientType from './search/RecipientType'
import NoticeTime from './search/NoticeTime'

const initialValues = {
  recipient_type: '',
  property_name: '',
  app_user_name: '',
  user_type: '',
  notice_type: '',
  start_date_from: null,
  start_date_to: null,
  stop_time_from: null,
  stop_time_to: null,
  prefecture: '',
  city: '',
  min_income: '',
  max_income: '',
  occupation: '',
  sex: '',
  min_age: '',
  max_age: ''
}

const NoticesSearchBar = () => {
  const dispatch = useDispatch()
  const queryParams = useSelector(selectQueryParams)

  useEffect(() => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        recipient_type: queryParams.recipient_type && Number(queryParams.recipient_type),
        property_name: queryParams.property_name,
        app_user_name: queryParams.app_user_name,
        user_type: queryParams.user_type && Number(queryParams.user_type),
        notice_type: queryParams.notice_type && Number(queryParams.notice_type),
        start_date_from: queryParams.start_date_from ? dayjs(queryParams.start_date_from).format(SERVER_DATE_FORMAT) : null,
        start_date_to: queryParams.start_date_to ? dayjs(queryParams.start_date_to).format(SERVER_DATE_FORMAT) : null,
        stop_time_from: queryParams.stop_time_from ? dayjs(queryParams.stop_time_from).format(SERVER_DATE_FORMAT) : null,
        stop_time_to: queryParams.stop_time_to ? dayjs(queryParams.stop_time_to).format(SERVER_DATE_FORMAT) : null,
        prefecture: queryParams.prefecture,
        city: queryParams.city,
        min_income: queryParams.min_income,
        max_income: queryParams.max_income,
        occupation: queryParams.occupation,
        sex: queryParams.sex && Number(queryParams.sex),
        min_age: queryParams.min_age,
        max_age: queryParams.max_age
      }
    })
  }, [queryParams, dispatch])

  return (
    <>
      <LeftSearchMenu
        resetValues={initialValues}
        validationSchema={noticesSearchSchema}
        // debug
      >
        <RecipientType />
        <Select
          name="notice_type"
          label='notices:notice_type'
          placeholder=""
          options={NOTICE_TYPE_OPTIONS}
          mr="0"
        />
        <NoticeTime />

        <RoleControlled>
          <TextInput
            type="number"
            name="min_income"
            label='notices:min_income'
            suffix='app_users:annual_income_unit'
            placeholder=""
            mr="0"
          />
          <TextInput
            type="number"
            name="max_income"
            label='notices:max_income'
            suffix='app_users:annual_income_unit'
            placeholder=""
            mr="0"
          />
          <Select
            name="sex"
            label='app_users:sex'
            placeholder=""
            options={SEX_OPTIONS}
            mr="0"
          />
          <TextInput
            type="number"
            name="min_age"
            label='notices:min_age'
            suffix="notices:age_unit"
            placeholder=""
            mr="0"
          />
          <TextInput
            type="number"
            name="max_age"
            label='notices:max_age'
            suffix="notices:age_unit"
            placeholder=""
            mr="0"
          />
        </RoleControlled>
      </LeftSearchMenu>
    </>
  )
}

export default NoticesSearchBar

// PropertiesSearchBar.propTypes = {}
