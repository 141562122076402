import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  FilePicker,
  Row
} from '@gk-devteam/apmc-core-web'

import {
  selectLoadingAppUser,
  selectPostingAppUser
} from '../../../selectors'
import { contractDocumentUpdateValidation } from '../../../validation'

import ContractDocumentTitle from './ContractDocumentTitle'

const ContractDocumentForm = ({ data, saveDocument }) => {
  const initialValues = {
    ...data
  }

  return (
    <Form
      id="contractDocumentForm"
      handleSubmit={saveDocument}
      validationSchema={contractDocumentUpdateValidation}
      loadingSelector={selectLoadingAppUser}
      postingSelector={selectPostingAppUser}
      initialData={initialValues}
      authorized="contract_documents.functions.add"
      // debug
    >

      <ContractDocumentTitle />
      <Row>
        <FilePicker
          label='select_file'
          name="document"
          delete_name="delete_document"
          types={['pdf', 'image']}
          required
        />
      </Row>
    </Form>
  )
}
export default ContractDocumentForm

ContractDocumentForm.propTypes = {
  data: PropTypes.object,
  saveDocument: PropTypes.func.isRequired
}
ContractDocumentForm.defaultProps = {

}
