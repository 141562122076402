import { createSelector } from 'reselect'

const selectContractDocuments = state => state.contractDocuments
const selectContractDocument = (state, documentID) => {
  return { documentID }
}

export const selectContractDocumentsLoading = createSelector(
  selectContractDocuments,
  documents => documents.loading
)
export const selectContractDocumentsPosting = createSelector(
  selectContractDocuments,
  documents => documents.posting
)

export const selectContractDocumentsHiddenBy = createSelector(
  selectContractDocuments,
  documents => documents.hiddenBy
)
export const selectContractDocumentsHiddenById = createSelector(
  selectContractDocuments,
  documents => documents.hiddenById
)

export const selectContractDocumentsData = createSelector(
  selectContractDocuments,
  documents => documents.items
)

export const selectContractDocumentData = createSelector(
  [selectContractDocuments, selectContractDocument],
  (documents, { documentID }) => {
    if (documentID) {
      return documents.items.find(doc => doc.document_id === documentID)
    }
    return null
  }
)

export const selectDocumentAddModalState = createSelector(
  selectContractDocuments,
  documents => documents.addDocumentModal
)
export const selectDocumentViewModalState = createSelector(
  selectContractDocuments,
  documents => documents.viewDocumentModal
)
export const selectActiveDocumentID = createSelector(
  selectContractDocuments,
  documents => documents.activeDocumentID
)
export const selectCloudSignCertificate = createSelector(
  selectContractDocuments,
  documents => documents.cloudSignCertificate
)
