import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CancelToken } from 'axios'
import {
  useTranslation,
  UPDATE_FORM,
  PrimaryButton,
  RemoveButton,
  Row,
  FormControlLabel,
  FormControlError
} from '@gk-devteam/apmc-core-web'

import { selectContractID } from '../../../../../selectors'
import { fetchProperties } from '../../../../../services'

import ContractPropertyModal from './ContractPropertyModal'

import {
  PropertyInputStyle,
  PropertyLinkStyle,
  ContractReadOnlyLink
} from '../../ContractForm.style'

const ContractProperty = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false)
  const [properties, setProperties] = useState(null)
  const [loading, setLoading] = useState(false)
  const contractID = useSelector(selectContractID)
  const propertyName = useSelector(state => state.form && state.form.property_name)
  const propertyID = useSelector(state => state.form && state.form.property_id)

  const fetchData = useCallback(
    async (propertyName) => {
      try {
        setLoading(true)
        const cancelSource = CancelToken.source()
        const res = await fetchProperties({ property_name: propertyName, limit: 5000 }, cancelSource)
        if (res.data && res.data.results) {
          setProperties(res.data.results)
        } else {
          setProperties(null)
        }
        setLoading(false)
      } catch (error) {
        console.warning('error', error)
        setLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    if (!contractID) {
      fetchData()
    }
  }, [fetchData, contractID])

  const _openModal = () => {
    setModalVisible(true)
  }
  const _closeModal = useCallback(
    () => {
      setModalVisible(false)
    },
    []
  )

  const handleSearchProperties = (propertyName) => {
    fetchData(propertyName)
  }

  const handlePropertyChange = (property_id, property_name) => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        property_id,
        property_name
      }
    })
    _closeModal()
  }

  const handleResetProperty = useCallback(
    () => {
      dispatch({
        type: UPDATE_FORM,
        payload: {
          property_id: null,
          property_name: null,
          room_id: null
        }
      })
    },
    [dispatch]
  )

  if (contractID && propertyName) {
    return (
      <ContractReadOnlyLink
        label="contracts:property"
        text={propertyName}
        to={`/properties/edit/${propertyID}`}
      />
    )
  }

  return (
    <>
      <ContractPropertyModal
        modalVisible={modalVisible}
        closeModal={_closeModal}
        handleSelect={handlePropertyChange}
        searchProperties={handleSearchProperties}
        loading={loading}
        properties={properties}
      />
      <PropertyInputStyle>
        <FormControlLabel label={t('contracts:property')} required/>
        {
          propertyID
            ? (
              <Row align="center">
                <PropertyLinkStyle
                  text={propertyName}
                  to={`/properties/edit/${propertyID}`}
                />
                <RemoveButton
                  label="delete"
                  handleClick={handleResetProperty}
                />
              </Row>
            )
            : (
              <>
                <PrimaryButton
                  label={'contracts:select_property'}
                  type="button"
                  onClick={_openModal}
                />
                <FormControlError name="property_id" />
              </>
            )
        }
      </PropertyInputStyle>
    </>
  )
}

export default ContractProperty

// ContractProperty.propTypes = {}
