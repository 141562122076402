import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  LightText,
  Link,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'
import { ToolOutlined } from '@ant-design/icons'

import {
  selectServiceName,
  selectServiceType
} from '../../../selectors'
import { propertiesSearchSchema } from '../../../validation'
import { SERVICES_ID, SERVICES_LEVEL } from '../../../constants'

import ServiceByLevel from '../common/ServiceByLevel'

import { SeikatsuCellStyle, IconStyle } from './PropertiesServicePage.style'

function PropertiesServicePage ({ serviceID }) {
  const { t } = useTranslation()
  const serviceName = useSelector(selectServiceName)
  const type = useSelector(selectServiceType)
  const breadcrumbs = [
    {
      label: 'navigation:services',
      link: `/settings/services?type=${type}`
    },
    {
      label: serviceName,
      link: `/settings/services/${serviceID}/properties`
    },
    {
      label: 'navigation:service_properties',
      link: `/settings/services/${serviceID}/properties`
    }
  ]

  const seikatsuCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const { property_id, total_categories = 0, enabled_categories = 0 } = data
      return (
        <SeikatsuCellStyle>
          <LightText text={`${enabled_categories}/${total_categories}`}/>
          <Link to={`./${property_id}/seikatsu`}>
            <IconStyle>
              <ToolOutlined />
            </IconStyle>
          </Link>
        </SeikatsuCellStyle>
      )
    },
    []
  )

  const columns = [
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name',
      cellTitle: true,
      sortable: true
    },
    {
      title: t('properties:property_id'),
      dataIndex: 'property_id',
      key: 'property_id',
      sortable: true
    }
  ]

  if (serviceID === String(SERVICES_ID.SEIKATSU)) {
    columns.push({
      title: '',
      dataIndex: '',
      key: 'enabled_categories',
      render: seikatsuCell
    })
  }

  return (
    <ServiceByLevel
      id={serviceID}
      breadcrumbs={breadcrumbs}
      columns={columns}
      validationSchema={propertiesSearchSchema}
      level={SERVICES_LEVEL.PROPERTY}
    />
  )
}

PropertiesServicePage.propTypes = {
  serviceID: PropTypes.string.isRequired
}

export default PropertiesServicePage
