import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  TOGGLE_ARRAY_ITEM
} from '@gk-devteam/apmc-core-web'

import {
  RecipientStyle
} from '../../CouponForm.style'
import { selectCouponEditable } from '../../../../selectors'

const CouponRecipientsItem = ({ id, label }) => {
  const editable = useSelector(selectCouponEditable)
  const dispatch = useDispatch()
  const _handleUserClick = () => {
    dispatch({
      type: TOGGLE_ARRAY_ITEM,
      payload: [
        {
          key: 'recipients_list',
          value: { id, label },
          objectComparatorKey: 'id'
        },
        {
          key: 'recipients_id',
          value: id
        }]
    })
  }
  return (
    <RecipientStyle
      handleClick={_handleUserClick}
      key={id}
      label={label ? label.replace('.', '') : ''}
      disabled={!editable}
    />
  )
}

export default memo(CouponRecipientsItem)

CouponRecipientsItem.propTypes = {
  label: PropTypes.string,
  id: PropTypes.number,
  editable: PropTypes.bool
}
