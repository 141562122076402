import React, { memo, useEffect, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Tabs as AntTab, message } from 'antd'
import { CancelToken } from 'axios'
import {
  Tabs
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import {
  selectWaitingChatRoomsCount,
  selectWaitingChecklistsCount,
  selectWaitingChatRooms,
  selectRealtimeChecklists,
  selectPages,
  selectContractsCancelingCount,
  selectContractsRenewingCount,
  selectSpacesWaitingReservationsCount
} from '../../selectors'
import { SUPPORT_STATUS } from '../../constants'
import { fetchChecklist } from '../../services/checklist/checklistService'

import TabHeader from './TabHeader'
import WaitingRooms from './WaitingRooms'
import SpacesWaitingReservations from './SpacesWaitingReservations'
import {
  RenewingContracts,
  CancelingContracts
} from './Contracts'

import {
  DrawerBodyStyle
} from './Drawer.style.js'

const { TabPane } = AntTab

const Drawer = ({ onClose }) => {
  const pages = useSelector(selectPages)
  const waitingChatCount = useSelector(selectWaitingChatRoomsCount)
  const realtimeChecklists = useSelector(selectRealtimeChecklists)
  const waitingChecklistCount = useSelector(selectWaitingChecklistsCount)
  const waitingChatRooms = useSelector(selectWaitingChatRooms)
  const renewingContractsCount = useSelector(selectContractsRenewingCount)
  const cancelingContractsCount = useSelector(selectContractsCancelingCount)
  const spacesWaitingReservationsCount = useSelector(selectSpacesWaitingReservationsCount)
  const [waitingChecklistRooms, setWaitingChecklistRooms] = useState(null)

  const fetchChecklistRooms = useCallback(
    async () => {
      try {
        const cancelSource = CancelToken.source()
        const res = await fetchChecklist({ status: SUPPORT_STATUS.WAITING }, cancelSource)
        if (res && res.data && res.data.results) {
          const data = res.data.results
          // Sort messages (most recent first, oldest last)
          data.sort((a, b) => {
            return dayjs(b.last_user_message_time).unix() - dayjs(a.last_user_message_time).unix()
          })
          setWaitingChecklistRooms(data)
        } else {
          message.warning('fetch_error')
        }
      } catch (error) {
        message.warning('fetch_error')
      }
    },
    []
  )

  // Handle checklists
  useEffect(() => {
    if (pages && pages.chat.enabled && pages.checklist.enabled) {
      fetchChecklistRooms()
    }
  }, [waitingChecklistCount, realtimeChecklists, fetchChecklistRooms, pages])

  if (!pages || (!pages.chat.enabled && !pages.checklist.enabled && !pages.custom.cloudsign && !pages.contracts.functions.cancel)) return null

  return (

    <DrawerBodyStyle>
      <Tabs defaultActiveKey="waiting_chat">
        {
          pages.chat.enabled
            ? (
              <TabPane key="waiting_chat" tab={<TabHeader header='drawer.waiting_chat' count={waitingChatCount}/>}>
                <WaitingRooms data={waitingChatRooms} type="chat" closeDrawer={onClose} />
              </TabPane>
            )
            : null
        }
        {
          pages.checklist.enabled
            ? (
              <TabPane key="waiting_checklist" tab={<TabHeader header='drawer.waiting_checklist' count={waitingChecklistCount}/>}>
                <WaitingRooms data={waitingChecklistRooms} type="checklist" closeDrawer={onClose} />
              </TabPane>
            )
            : null
        }
        {
          pages.custom.cloudsign
            ? (
              <TabPane key="contracts_renewing" tab={<TabHeader header='drawer.contracts_renewing' count={renewingContractsCount}/>}>
                <RenewingContracts closeDrawer={onClose} />
              </TabPane>
            )
            : null
        }
        {
          pages.contracts.functions.cancel
            ? (
              <TabPane key="contracts_canceling" tab={<TabHeader header='drawer.contracts_canceling' count={cancelingContractsCount}/>}>
                <CancelingContracts closeDrawer={onClose} />
              </TabPane>
            )
            : null
        }
        {
          pages.custom.yahagi
            ? (
              <TabPane key="spaces_reservations" tab={<TabHeader header='drawer.spaces_reservations' count={spacesWaitingReservationsCount}/>}>
                <SpacesWaitingReservations closeDrawer={onClose} />
              </TabPane>
            )
            : null
        }
      </Tabs>
    </DrawerBodyStyle>
  )
}

export default memo(Drawer)

Drawer.propTypes = {
  onClose: PropTypes.func.isRequired
}
Drawer.defaultProps = {

}
