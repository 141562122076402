import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_PROPERTIES_GROUPS,
  RESET_PROPERTIES_GROUPS
} from '../../../types'

import GroupsList from './GroupsList'
import SelectedItems from './SelectedItems'

import {
  ResultsBlockStyle,
  BetweenStyle
} from './common.style'

const LIMIT = 100

const GroupsModal = ({ visible, close }) => {
  const dispatch = useDispatch()

  const fetchData = useCallback(
    () => {
      dispatch({
        type: FETCH_PROPERTIES_GROUPS,
        query: { limit: LIMIT }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    fetchData({})
    return () => {
      dispatch({ type: RESET_PROPERTIES_GROUPS })
    }
  }, [fetchData, dispatch])

  return (
    <Modal
      visible={visible}
      title='user_management:select_groups'
      onCancel={close}
      cancelText='close'
      fullWidth
      fullHeight
      large
    >
      <ResultsBlockStyle>
        <GroupsList />
        <BetweenStyle> {'>>'} </BetweenStyle>
        <SelectedItems itemsKey="groups" modal/>
      </ResultsBlockStyle>
    </Modal>
  )
}

export default GroupsModal

GroupsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}
