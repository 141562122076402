import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { SendOutlined } from '@ant-design/icons'
import {
  useTranslation,
  PrimaryButton
} from '@gk-devteam/apmc-core-web'

import { selectChecklistPosting, selectChecklistIssueHiddenBy } from '../../../selectors'
import { POST_CHECKLIST_ISSUE_COMMENT } from '../../../types'

import {
  MessageStyle,
  TextAreaStyle,
  ButtonsRowStyle
} from '../adminTasks.style'

const CommentMessageForm = ({ checklistID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const posting = useSelector(selectChecklistPosting)
  const hiddenBy = useSelector(selectChecklistIssueHiddenBy)
  const [comment, setComment] = useState('')
  const textareaRef = useRef(null)

  const _focusTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }

  useEffect(() => {
    _focusTextarea()
  }, [])

  const _handleSubmit = (e) => {
    if (e) e.preventDefault()
    if (!posting && comment) {
      dispatch({
        type: POST_CHECKLIST_ISSUE_COMMENT,
        checklistID,
        data: {
          comment
        }
      })
    }
  }

  const _handleChange = (e) => {
    setComment(e.target.value)
  }
  const _handleKeyPress = (e) => {
    const {
      // ctrlKey,
      // metaKey,
      // altKey,
      shiftKey
    } = e
    // console.log('key', e.key)
    // console.log('e.keyCode', e.keyCode)
    // console.log('e.charCode', e.charCode)
    // console.log('ctrlKey', ctrlKey)
    // console.log('metaKey', metaKey)
    // console.log('shiftKey', shiftKey)
    // console.log('altKey', altKey)
    if (e.key === 'Enter' && shiftKey) {
      e.preventDefault()
      _handleSubmit()
    }
  }

  return (
    <form onSubmit={_handleSubmit}>
      <MessageStyle>
        <TextAreaStyle
          ref={textareaRef}
          flex={1}
          name="comment"
          data-qa="checklist_comment"
          data-lpignore="true"
          placeholder={t('message_placeholder')}
          value={comment}
          onChange={_handleChange}
          onKeyPress={_handleKeyPress}
          disabled={posting || hiddenBy}
          required
        />
        <ButtonsRowStyle align="center" justify="end" py="XS" px="XS">
          <PrimaryButton
            label="submit"
            icon={<SendOutlined />}
            type="submit"
            reverse
            loading={posting}
            disabled={hiddenBy}
          />
        </ButtonsRowStyle>
      </MessageStyle>
    </form>
  )
}

export default CommentMessageForm

CommentMessageForm.propTypes = {
  checklistID: PropTypes.string.isRequired
}
