import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_ADMIN_USER,
  FETCH_ADMIN_USER_SUCCESS,
  FETCH_ADMIN_USER_FAIL,
  RESET_ADMIN_USER,
  POST_ADMIN_USER,
  POST_ADMIN_USER_SUCCESS,
  POST_ADMIN_USER_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  posting: null,
  data: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_ADMIN_USER]: (state, action) => {
    state.posting = true
  },
  [POST_ADMIN_USER_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_ADMIN_USER_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_ADMIN_USER]: (state, action) => {
    state.loading = true
  },
  [FETCH_ADMIN_USER_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_ADMIN_USER_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_ADMIN_USER]: () => INITIAL_STATE
})
