import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  INIT_FORM,
  RESET_FORM
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyID,
  selectAdminContactsData,
  selectAdminContactsAllDisplayed
} from '../../../../selectors'
import { TOGGLE_CONTACT } from '../../../../types'

import ContactsList from '../common/ContactsList'

const AdminContactsList = ({ copyModal, editModal }) => {
  const dispatch = useDispatch()

  const _handleCopy = (data, closeMenu, rest) => {
    const { label, name, phone, mail_address, hours } = data
    dispatch({ type: RESET_FORM })
    dispatch({
      type: INIT_FORM,
      payload: {
        label,
        name,
        phone,
        mail_address,
        hours
      }
    })
    copyModal()
    closeMenu()
  }

  const menu = [
    {
      label: 'copy',
      onClick: _handleCopy,
      shouldRender: 'contacts.functions.add'
    }
  ]

  const _handleContactDisplay = useCallback(
    (display, id, item) => {
      if (id) {
        const data = {
          id: item.id,
          display
        }
        const config = {
          type: 'admin_contacts',
          property: id
        }
        dispatch({ type: TOGGLE_CONTACT, data, config })
      } else {
        console.warning('no property id')
      }
    },
    [dispatch]
  )

  return (
    <ContactsList
      dataSelector={selectAdminContactsData}
      displaySelector={selectAdminContactsAllDisplayed}
      idSelector={selectPropertyID}
      title="contacts:admin_contacts"
      type="admin_contacts"
      level="property"
      editModal={editModal}
      handleContactDisplay={_handleContactDisplay}
      sideMenu={menu}
    />
  )
}

export default AdminContactsList

AdminContactsList.propTypes = {
  copyModal: PropTypes.func.isRequired,
  editModal: PropTypes.func.isRequired
}
