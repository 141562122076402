import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row
} from '@gk-devteam/apmc-core-web'

import {
  selectRefuseLoading,
  selectRefusePosting,
  selectCalendars
} from '../../selectors'
import { refuseSelectSchema } from '../../validation'

import CalendarItem from './CalendarItem'

import { FormStyle } from './refuse.style'

const ReportRefuseForm = ({ handleSubmit }) => {
  const calendars = useSelector(selectCalendars)

  const _renderCalendars = () => {
    if (!calendars) return null
    return calendars.map(calendar => {
      return <CalendarItem item={calendar} key={calendar.id} />
    })
  }

  return (
    <FormStyle
      id="refuse_add"
      handleSubmit={handleSubmit}
      validationSchema={refuseSelectSchema}
      loadingSelector={selectRefuseLoading}
      postingSelector={selectRefusePosting}
      initialData={{
        id: null
      }}
      // debug
    >
      <Row wrap="wrap">
        { _renderCalendars() }
      </Row>
    </FormStyle>
  )
}
export default ReportRefuseForm

ReportRefuseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}
ReportRefuseForm.defaultProps = {

}
