/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const userProfileUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    name: yup.string().max(255).required().label(i18n.t('common:name')),
    furigana: yup.string().max(255).required().label(i18n.t('common:furigana')),
    mail_address: yup.string().email().required().label(i18n.t('common:email')),
    phone: yup.string().max(255).nullable().label(i18n.t('common:phone')),
    office: yup.string().max(255).nullable().label(i18n.t('user_management:member.office')),
    department: yup.string().max(255).nullable().label(i18n.t('user_management:member.department')),
    position: yup.string().max(255).nullable().label(i18n.t('user_management:member.position')),
    role: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .min(1).max(4)
      .integer().nullable().label(i18n.t('common:role')),
    password: yup
      .string()
      .isValidPassword()
      .test(
        'conditional_required',
        i18n.t('required', { label: '${label}' }),
        function (value) {
          const { new_password } = this.parent
          if (!value && (new_password)) return false
          return true
        })
      .label(i18n.t('login:password')),
    new_password: yup
      .string()
      .isValidPassword()
      .test(
        'min_length',
        i18n.t('wrong_length', { label: '${label}' }),
        function (value) {
          if (value && value.length < 8) return false
          return true
        })
      .max(64)
      .nullable()
      .label(i18n.t('login:new_password')),
    file: yup.string().nullable().isBlob().label(i18n.t('common:file')),
    memo: yup.string().nullable().max(5000).label(i18n.t('common:memo')),
    delete_file: yup.bool().required().label(i18n.t('common:delete_file_flag'))
  })
}
