import React, { memo, useCallback, useEffect, useState } from 'react'
import { saveAs } from 'file-saver'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Document,
  Page,
  Modal,
  Row,
  Card,
  Text
} from '@gk-devteam/apmc-core-web'

import { PDF_CMAP_URL } from '../../../constants'

import { PreviewStyle } from './PreviewModal.style'

const PreviewModal = ({
  fileURL,
  close,
  visible,
  isPreview = true
}) => {
  const { t } = useTranslation()
  const [height, setHeight] = useState(400)

  const handlePreviewHeight = useCallback(
    () => {
      const windowHeight = window.innerHeight
      setHeight(windowHeight * 0.8)
    },
    []
  )

  useEffect(() => {
    handlePreviewHeight()
    window.addEventListener('resize', handlePreviewHeight)
    return () => {
      window.removeEventListener('resize', handlePreviewHeight)
    }
  }, [handlePreviewHeight])

  if (!fileURL) return null

  const _saveDocument = () => {
    saveAs(fileURL, 'template.pdf')
  }

  const _renderPreview = () => {
    return (
      <PreviewStyle>
        <Document
          file={fileURL}
          loading={t('pdf_load')}
          error={t('pdf_load_fail')}
          // onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          options={{
            cMapUrl: PDF_CMAP_URL,
            cMapPacked: true
          }}
        >
          <Page
            pageNumber={1}
            height={height}
            renderAnnotationLayer={false}
          />
        </Document>
      </PreviewStyle>
    )
  }

  return (
    <Modal
      visible={visible}
      onCancel={close}
      cancelText={ isPreview ? 'close' : 'cancel' }
      okText={ isPreview ? 'download' : 'submit' }
      onOk={ isPreview ? _saveDocument : close }
      formID={ isPreview ? null : 'updateContractRenewalSettings' }
      // title={data && data.document_label}
      fullWidth
      fullHeight
      large
    >
      <div>
        {
          !isPreview
            ? (
              <Row mb="XS">
                <Text label="contracts:contract_preview_save_description"/>
              </Row>
            )
            : null
        }
        <Card>
          { _renderPreview() }
        </Card>
      </div>
    </Modal>
  )
}

export default memo(PreviewModal)

PreviewModal.propTypes = {
  fileURL: PropTypes.string,
  close: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool
}

PreviewModal.defaultProps = {
  isPreview: true
}
