import { createSelector } from 'reselect'

const selectProperties = state => state.properties

export const selectPropertiesLoading = createSelector(
  selectProperties,
  properties => properties.loading
)
export const selectPropertiesCount = createSelector(
  selectProperties,
  properties => properties.results_count
)
export const selectPropertiesData = createSelector(
  selectProperties,
  properties => properties.results
)
