/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const spaceUpdateSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('id')),
    property_id: yup.string().nullable().required().label(i18n.t('properties:property_id')),
    name: yup.string().max(255).required().label(i18n.t('spaces:name')),
    description: yup.string().nullable().max(5000).label(i18n.t('description')),
    type: yup
      .number()
      .oneOf([1, 2, 3, 4, 5, 6, 7])
      .nullable()
      .required()
      .label(i18n.t('spaces:type')),
    reservable_from_days: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .max(365)
      .nullable()
      .required()
      .label(i18n.t('spaces:reservable_from_days')),
    reservable_from_time: yup
      .string()
      .nullable()
      .label(i18n.t('spaces:reservable_from_time')),
    reservable_to_time: yup
      .string()
      .nullable()
      .label(i18n.t('spaces:reservable_to_time')),
    usage_limit: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .max(100)
      .nullable()
      .required()
      .label(i18n.t('spaces:usage_limit')),
    file: yup
      .string()
      .nullable()
      .isBlob()
      .label(i18n.t('common:file')),
    delete_file: yup.bool().required().label(i18n.t('common:delete_file_flag'))
  })
}
