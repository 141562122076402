import React, { memo, useContext } from 'react'
import {
  Row,
  Text
} from '@gk-devteam/apmc-core-web'

import { PanelDisabledContext } from '../PanelDisabled/PanelDisabled'

import {
  PanelLegendContainerStyle,
  PanelLegendInnerContainerStyle,
  LegendItemStyle,
  AllDisplayedStyle,
  SomeDisplayedStyle,
  NoneDisplayedStyle
} from './PanelLegend.style.js'

const PanelLegend = () => {
  const disabled = useContext(PanelDisabledContext)
  return (
    <PanelLegendContainerStyle>
      <PanelLegendInnerContainerStyle>
        <Row>
          <Text label="example"/>
        </Row>
        <Row mt="XS">
          <LegendItemStyle>
            <Text label="legend_all_displayed"/>
            <AllDisplayedStyle disabled={disabled} />
          </LegendItemStyle>
          <LegendItemStyle>
            <Text label="legend_some_displayed"/>
            <SomeDisplayedStyle disabled={disabled} />
          </LegendItemStyle>
          <LegendItemStyle>
            <Text label="legend_none_displayed"/>
            <NoneDisplayedStyle disabled={disabled} />
          </LegendItemStyle>
        </Row>
      </PanelLegendInnerContainerStyle>
    </PanelLegendContainerStyle>
  )
}
export default memo(PanelLegend)

PanelLegend.propTypes = {

}
PanelLegend.defaultProps = {

}
