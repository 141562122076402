import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  PrimaryButton,
  Row
} from '@gk-devteam/apmc-core-web'

import { selectNoticeEditable } from '../../../../../selectors'

import ContractsModal from './ContractsModal'

const ContractsRecipients = () => {
  const editable = useSelector(selectNoticeEditable)
  const [modaleVisible, setModaleVisible] = useState(false)
  const _openModal = () => {
    setModaleVisible(true)
  }
  const _closeModal = () => {
    setModaleVisible(false)
  }

  return (
    <Row mt="XS">
      {
        editable
          ? <PrimaryButton label="notices:search_contracts" handleClick={_openModal} type="button" />
          : null
      }
      <ContractsModal
        visible={modaleVisible}
        close={_closeModal}
      />
    </Row>
  )
}

export default memo(ContractsRecipients)

ContractsRecipients.propTypes = {}
