import React from 'react'
import { Card, Column, Row, Spinner, Text, ToastTitle } from '@gk-devteam/apmc-core-web'

import { ProgressContainerStyle } from './DatasyncConfirmPage.style'

function DataUploading () {
  return (
    <Card mt="XL">
      <ProgressContainerStyle>
        <Row mb="L" justify="center">
          <Spinner size="default" />
        </Row>
        <Column mb="S" justify="between">
          <ToastTitle label="datasync:uploading" />
          <Text label="datasync:uploading_text" />
        </Column>

      </ProgressContainerStyle>
    </Card>
  )
}

export default DataUploading
