import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  INIT_FORM,
  RESET_FORM,
  downloadFileFromURL,
  getFileFromURL
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyManualsData,
  selectPropertyManualsAllDisplayed,
  selectPropertyID
} from '../../../../selectors'
import {
  TOGGLE_MANUAL,
  DELETE_MANUAL
} from '../../../../types'

import ManualsList from '../common/ManualsList'

const PropertyManualsList = ({ copyModal, editModal }) => {
  const dispatch = useDispatch()
  const propertyID = useSelector(selectPropertyID)
  const { t } = useTranslation()

  const _handleDownload = (data, closeMenu) => {
    downloadFileFromURL(data.file_url, 'manual')
    closeMenu()
  }
  const _handleEdit = (data, closeMenu) => {
    dispatch({ type: RESET_FORM })
    const { title, content, manual_id, file_url } = data
    dispatch({
      type: INIT_FORM,
      payload: {
        title,
        content,
        file_url,
        id: manual_id,
        file: null,
        delete_file: false
      }
    })
    editModal()
    closeMenu()
  }
  const _handleCopy = async (data, closeMenu) => {
    dispatch({ type: RESET_FORM })
    const { title, content, file_url } = data
    try {
      let blobURL
      if (file_url) {
        const blob = await getFileFromURL(file_url)
        blobURL = URL.createObjectURL(blob)
      }
      dispatch({
        type: INIT_FORM,
        payload: {
          title,
          content,
          file_url,
          file: blobURL || null,
          file_filename: blobURL ? 'manual.pdf' : null,
          delete_file: false
        }
      })
      copyModal()
    } catch (error) {
      console.warning('error retrieving file blob', error)
      message.warning(t('copy_text_error'))
    }
    closeMenu()
  }
  const _handleDelete = (data, closeMenu) => {
    dispatch({ type: DELETE_MANUAL, id: data.manual_id, config: { property: propertyID } })
    closeMenu()
  }

  const menu = [
    {
      label: 'download',
      onClick: _handleDownload
    },
    {
      label: 'edit',
      onClick: _handleEdit,
      shouldRender: 'manuals.functions.update'
    },
    {
      label: 'copy',
      onClick: _handleCopy,
      shouldRender: 'manuals.functions.add'
    },
    {
      label: 'delete',
      onClick: _handleDelete,
      danger: true,
      shouldRender: 'manuals.functions.delete'
    }
  ]

  const _handleItemDisplay = useCallback(
    (display, id, item) => {
      if (id) {
        const data = {
          id: item.manual_id,
          display
        }
        const config = {
          type: 'property_manuals',
          property: id
        }
        dispatch({ type: TOGGLE_MANUAL, data, config })
      } else {
        console.warning('no property id')
      }
    },
    [dispatch]
  )

  return (
    <ManualsList
      dataSelector={selectPropertyManualsData}
      displaySelector={selectPropertyManualsAllDisplayed}
      idSelector={selectPropertyID}
      title="manuals:property_manuals"
      type="property_manuals"
      level="property"
      editModal={editModal}
      handleItemDisplay={_handleItemDisplay}
      sideMenu={menu}
    />
  )
}

export default PropertyManualsList

PropertyManualsList.propTypes = {
  copyModal: PropTypes.func.isRequired,
  editModal: PropTypes.func.isRequired
}
