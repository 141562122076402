import {
  styled,
  getColor,
  SPACING,
  BORDER_RADIUS,
  Row,
  FormRemovableLabel,
  InfoButton,
  TextArea
} from '@gk-devteam/apmc-core-web'

export const SelectedContainer = styled.ul`
  ${BORDER_RADIUS};
  padding: 0;
  margin: 0;
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  list-style: none;
  flex: 2;
  height: ${props => props.modalMode ? '100%' : 'auto'};
  min-height: 40px;
  overflow: scroll;
  display: flex;
  align-items: ${props => props.modalMode ? 'baseline' : 'center'};
  flex-direction: ${props => props.modalMode ? 'column' : 'row'};
  flex-wrap: wrap;
  background-color: ${props => props.disabled ? getColor(props, 'cardBorder') : getColor(props, 'white')};
`
export const SelectedItemStyle = styled(FormRemovableLabel)`
  margin: ${SPACING.SMALLEST / 2}rem;
`
export const SearchButtonStyle = styled.button`
  ${BORDER_RADIUS};
  appearance: none;
  padding: 0;
  margin: 4px 0 0 0;
  outline: none;
  border: none;
  width: ${SPACING.LARGE}rem;
  height: ${SPACING.LARGE}rem;
  color: ${props => getColor(props, 'white')};
  background-color: ${props => getColor(props, 'accent')};
  cursor: pointer;
  margin-left: ${SPACING.SMALLEST}rem;

  svg {
    width: 50%;
  }
`

export const FormSearchButtonStyle = styled(InfoButton)`
  margin-top: ${SPACING.REGULAR}rem;
  margin-left: ${SPACING.REGULAR}rem;
`

export const TableContainerStyle = styled.div`
  flex: 5;
  height: 100%;
  overflow: scroll;
  position: relative;
`
export const CheckmarkCellStyle = styled.div`
  padding: calc(${SPACING.SMALL}rem - 2px) 0;
  text-align: center;
`

export const ResultsBlockStyle = styled(Row)`
  flex: 1;
  overflow: scroll;
`

export const BetweenStyle = styled.div`
  margin: 0 ${SPACING.SMALLEST}rem;
  user-select: none;
  color: ${props => getColor(props, 'lightText')};
`

export const MemoTextAreaStyle = styled(TextArea)`
  textarea {
    min-height: 125px;
  }
`
