import React from 'react'
import { useSelector } from 'react-redux'
import {
  Card,
  Row,
  Text,
  Link,
  EmptyData
} from '@gk-devteam/apmc-core-web'

import {
  selectRoomContract
} from '../../../selectors'
import { USER_EMPTY_NAME } from '../../../constants'

import {
  StatusStyle,
  StatusValueStyle
} from './RoomDetailsPage.style'

const CurrentContract = () => {
  const contract = useSelector(selectRoomContract)
  if (!contract) return null

  const {
    resident_id,
    resident_name,
    contractor_id,
    contractor_name,
    renewal_period_end
  } = contract
  return (
    <Card
      title="rooms:current_contract_status"
      mt="L"
    >
      {
        resident_id && contractor_id
          ? (
            <Row>
              <StatusStyle>
                <Text label="rooms:current_resident" />
                <StatusValueStyle>
                  <Link to={`/app_users/edit/${resident_id}`}>{resident_name || USER_EMPTY_NAME}</Link>
                </StatusValueStyle>
              </StatusStyle>
              <StatusStyle>
                <Text label="rooms:current_contractor" />
                <StatusValueStyle>
                  <Link to={`/app_users/edit/${contractor_id}`}>{contractor_name || USER_EMPTY_NAME}</Link>
                </StatusValueStyle>
              </StatusStyle>
              <StatusStyle>
                <Text label="rooms:current_contract_renewal_end" />
                <StatusValueStyle>{renewal_period_end}</StatusValueStyle>
              </StatusStyle>
            </Row>
          )
          : <EmptyData description="rooms:no_current_contract_status"/>
      }
    </Card>
  )
}

export default CurrentContract

CurrentContract.propTypes = {}
