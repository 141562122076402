export const FETCH_INQUIRIES = 'fetch_inquiries'
export const FETCH_INQUIRIES_SUCCESS = 'fetch_inquiries_success'
export const FETCH_INQUIRIES_FAIL = 'fetch_inquiries_fail'
export const RESET_INQUIRIES = 'reset_inquiries'

export const TOGGLE_INQUIRY_MODAL = 'toggle_inquiry_modal'
export const SET_ACTIVE_INQUIRY = 'set_active_inquiry'

export const DELETE_INQUIRY = 'delete_inquiry'
export const DELETE_INQUIRY_SUCCESS = 'delete_inquiry_success'
export const DELETE_INQUIRY_FAIL = 'delete_inquiry_fail'
