import React from 'react'
import { useSelector } from 'react-redux'
import { InfoButton } from '@gk-devteam/apmc-core-web'
import { useNavigate } from '@reach/router'

import { selectDatasyncFormContractsFiles, selectDatasyncFormPropertiesFiles } from '../../selectors'

export default function ConfirmButton () {
  const navigate = useNavigate()
  const properties_file = useSelector(selectDatasyncFormPropertiesFiles)
  const contracts_file = useSelector(selectDatasyncFormContractsFiles)
  const propertiesErrors = useSelector(state => state?.formErrors?.properties_file)
  const contractsErrors = useSelector(state => state?.formErrors?.contracts_file)

  if ((!properties_file && !contracts_file) || (propertiesErrors?.length > 0 || contractsErrors?.length > 0)) return null

  const _handleConfirmClick = () => {
    navigate('/datasync/confirm', { state: { properties_file, contracts_file } })
  }

  return (
    <InfoButton
      // form="uploadDatasyncData"
      reverse
      label="import:confirm"
      type="button"
      handleClick={_handleConfirmClick}
    />
  )
}
