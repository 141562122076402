import { api, MULTIPART_HEADERS, getFileFromURL } from '@gk-devteam/apmc-core-web'

export const fetchPublicFacilities = (id, cancelSource) => {
  return api.get(`properties/${id}/public_facilities`, {
    cancelToken: cancelSource.token
  })
}

export const postPublicFacility = async (data, id, cancelSource) => {
  if (data) {
    const formData = new FormData()
    if (data.file) {
      try {
        const file = await getFileFromURL(data.file)
        formData.append('file', file, data.file_filename)
      } catch (error) {
        console.warning('error while processing file')
      }
    }

    delete data.file
    delete data.file_url
    delete data.file_filename
    delete data.modified

    formData.append('data', JSON.stringify(data))
    return api.post(`properties/${id}/public_facilities`, formData, {
      cancelToken: cancelSource.token,
      ...MULTIPART_HEADERS
    })
  }
}

export const togglePublicFacility = (data, id, cancelSource) => {
  return api.post(`properties/${id}/public_facilities/display`, data, {
    cancelToken: cancelSource.token
  })
}

export const toggleAdditionalPublicFacilities = async (data, id, cancelSource) => {
  return api.post(`properties/${id}/public_facilities/enable_additional_data`, data, {
    cancelToken: cancelSource.token
  })
}

export const deletePublicFacility = (propertyID, vicinityID, cancelSource) => {
  return api.delete(`/properties/${propertyID}/public_facilities/${vicinityID}`, {
    cancelToken: cancelSource.token
  })
}
