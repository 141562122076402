import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Modal, useTranslation
} from '@gk-devteam/apmc-core-web'

import { POST_GUIDE } from '../../../../types'
import { selectCategoryName, selectGuidesPosting } from '../../../../selectors'

import GuideForm from '../../../../forms/GuideForm/GuideForm'

const GuideModal = ({ visible, onCancel, type, id, guideCategoryID, guideType, editMode }) => {
  const dispatch = useDispatch()
  const posting = useSelector(selectGuidesPosting)
  const { t } = useTranslation()
  const categoryName = useSelector(selectCategoryName)

  const _handleSubmit = (data) => {
    const onSuccess = () => {
      onCancel()
    }
    dispatch({
      type: POST_GUIDE,
      data,
      config: {
        guideCategoryID,
        [type]: id,
        type: guideType
      },
      onSuccess
    })
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={_handleSubmit}
      okText="save"
      title={editMode ? `${categoryName}${t('guides:edit_guide_detail')}` : `${categoryName}${t('guides:add_guide_detail')}`}
      formID="guide_form"
      posting={posting}
      fullWidth
    >
      <GuideForm handleSubmit={_handleSubmit}/>
    </Modal>
  )
}

export default GuideModal

GuideModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  guideCategoryID: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['property', 'room']),
  guideType: PropTypes.oneOf(['guides', 'admin_guides']).isRequired,
  id: PropTypes.string
}
