import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_VICINITIES,
  FETCH_VICINITIES_SUCCESS,
  FETCH_VICINITIES_FAIL,
  POST_VICINITY,
  POST_VICINITY_SUCCESS,
  POST_VICINITY_FAIL,
  DELETE_VICINITY,
  // DELETE_VICINITY_SUCCESS,
  DELETE_VICINITY_FAIL,
  TOGGLE_VICINITY,
  TOGGLE_VICINITY_FAIL,
  TOGGLE_VICINITIES_ADDITIONAL_DATA,
  RESET_VICINITIES
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  posting: null,
  data: null,
  categories: null,
  error: null,
  hiddenBy: null,
  hiddenById: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_VICINITIES]: (state, action) => {
    state.loading = true
  },
  [FETCH_VICINITIES_SUCCESS]: (state, action) => {
    const { data, categories, hiddenBy, hiddenById } = action.payload
    state.loading = false
    state.data = data
    state.categories = categories
    state.hiddenBy = hiddenBy
    state.hiddenById = hiddenById
  },
  [FETCH_VICINITIES_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [TOGGLE_VICINITIES_ADDITIONAL_DATA]: (state, action) => {
    state.loading = true
  },
  [POST_VICINITY]: (state, action) => {
    state.posting = true
  },
  [POST_VICINITY_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_VICINITY_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [TOGGLE_VICINITY]: (state, action) => {
    state.loading = true
  },
  [TOGGLE_VICINITY_FAIL]: (state, action) => {
    state.loading = false
  },
  [DELETE_VICINITY]: (state, action) => {
    state.loading = true
  },
  [DELETE_VICINITY_FAIL]: (state, action) => {
    state.loading = false
  },
  [RESET_VICINITIES]: () => INITIAL_STATE

})
