import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_SURVEY,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAIL,
  RESET_SURVEY,
  POST_SURVEY,
  POST_SURVEY_SUCCESS,
  POST_SURVEY_FAIL,
  FETCH_SURVEY_ANSWER_USERS,
  FETCH_SURVEY_ANSWER_USERS_SUCCESS,
  FETCH_SURVEY_ANSWER_USERS_FAIL,
  RESET_SURVEY_ANSWER_USERS
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  data: null,
  loading_answer_users: null,
  answer_users: null,
  answer_users_error: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_SURVEY]: (state, action) => {
    state.posting = true
  },
  [POST_SURVEY_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_SURVEY_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [FETCH_SURVEY]: (state, action) => {
    state.loading = true
  },
  [FETCH_SURVEY_SUCCESS]: (state, action) => {
    state.loading = false
    state.data = action.payload
  },
  [FETCH_SURVEY_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_SURVEY]: () => INITIAL_STATE,
  [FETCH_SURVEY_ANSWER_USERS]: (state, action) => {
    state.loading_answer_users = true
  },
  [FETCH_SURVEY_ANSWER_USERS_SUCCESS]: (state, action) => {
    state.loading_answer_users = false
    state.answer_users = action.payload
  },
  [FETCH_SURVEY_ANSWER_USERS_FAIL]: (state, action) => {
    state.loading_answer_users = false
    state.answer_users_error = action.payload
  },
  [RESET_SURVEY_ANSWER_USERS]: (state) => {
    state.loading_answer_users = null
    state.answer_users = null
    state.answer_users_error = null
  }

})
