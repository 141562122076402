import React from 'react'
import { useSelector } from 'react-redux'
import dot from 'dot-object'
import {
  Text,
  Row
} from '@gk-devteam/apmc-core-web'

import CouponRecipientsItem from './CouponRecipientsItem'

const CouponRecipientsList = () => {
  const recipientType = useSelector(state => state.form.recipient_type)
  const recipients = useSelector(state => {
    const form = state.form && dot.object({ ...state.form })
    return form.recipients_list
  })
  if (recipientType && (recipientType === 1)) return null
  if (!recipients) return null

  let label = ''
  switch (recipientType) {
    case 2:
      label = 'coupons:recipients_properties'
      break
    case 3:
      label = 'coupons:recipients_rooms'
      break
    default:
      break
  }

  const _renderRecipients = () => {
    return recipients.map(recipient => <CouponRecipientsItem key={recipient.id} id={recipient.id} label={recipient.label} />)
  }

  return (
    <Row wrap="wrap" align="baseline" mt="S">
      <Row wrap="wrap" align="baseline" mt="S">
        <Text label={label} />
      </Row>
      <Row wrap="wrap" align="baseline" mt="S">
        { _renderRecipients() }
      </Row>
    </Row>
  )
}

export default CouponRecipientsList
