import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { CancelToken } from 'axios'

import {
  fetchAppUsers, fetchAreas, fetchProperties
} from '../../services'
import {
  FETCH_APP_USERS,
  FETCH_APP_USERS_SUCCESS,
  FETCH_APP_USERS_FAIL,
  FETCH_APP_USERS_AVAILABLE_RECIPIENTS,
  FETCH_APP_USERS_AVAILABLE_RECIPIENTS_SUCCESS,
  FETCH_APP_USERS_AVAILABLE_RECIPIENTS_FAIL
} from '../../types'
import { i18n } from '../../locales'
import { APP_USERS_LOGIN_PDF_TYPE } from '../../constants'

export function * fetchAppUsersSaga ({ query }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchAppUsers, query, cancelSource)
    if (res && res.data) {
      const { results, results_count, max_pages } = res.data
      yield put({ type: FETCH_APP_USERS_SUCCESS, payload: { results, results_count, max_pages } })
    } else {
      yield put({ type: FETCH_APP_USERS_FAIL, error: true, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_APP_USERS_FAIL, error: true, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * fetchAppUsersAvailableRecipientsSaga ({ params, recipientType }) {
  const cancelSource = CancelToken.source()
  try {
    let response
    if (recipientType === APP_USERS_LOGIN_PDF_TYPE.PROPERTIES) {
      response = yield call(fetchProperties, params, cancelSource)
    }
    if (recipientType === APP_USERS_LOGIN_PDF_TYPE.AREA) {
      response = yield call(fetchAreas, cancelSource)
    }
    if (recipientType === APP_USERS_LOGIN_PDF_TYPE.RESIDENTS) {
      response = yield call(fetchAppUsers, params, cancelSource)
    }
    if (response?.data) {
      const { results } = response.data
      yield put({ type: FETCH_APP_USERS_AVAILABLE_RECIPIENTS_SUCCESS, payload: results })
    } else {
      yield put({ type: FETCH_APP_USERS_FAIL, payload: { message: i18n.t('fetch_error') } })
    }
  } catch (error) {
    yield put({ type: FETCH_APP_USERS_AVAILABLE_RECIPIENTS_FAIL, payload: error.data || error.message || 'unknow error' })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchAppUsers () {
  yield takeLatest(FETCH_APP_USERS, fetchAppUsersSaga)
  yield takeLatest(FETCH_APP_USERS_AVAILABLE_RECIPIENTS, fetchAppUsersAvailableRecipientsSaga)
}
