import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import {
  dynamicLink,
  MainLayout,
  PrimaryButton,
  RESET_PARAMS,
  Row,
  selectQueryParams,
  Table,
  Text,
  Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_COUPONS,
  RESET_COUPONS,
  DELETE_COUPON
} from '../../../../types'

import {
  selectCouponsLoading,
  selectCouponsData,
  selectCouponsCount
} from '../../../../selectors'

const breadcrumbs = [
  {
    label: 'navigation:inui.coupons',
    link: '/coupons'
  }
]

const CouponsPage = ({ location, navigate }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const { t } = useTranslation(['common', 'coupons'])
  const prevQuery = useRef(null)

  const fetchData = useCallback(() => {
    dispatch({ type: FETCH_COUPONS, query })
  }, [dispatch, query])

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_COUPONS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const _handleCopy = useCallback(
    (id, close) => {
      close()
      navigate('/coupons/add', { state: { id, copy: true } })
    },
    [navigate]
  )

  const valueCell = useCallback(
    (value, cellKey, rowID, className, data) => {
      return <Text text={`${value}${data.value_type}`}/>
    },
    []
  )

  const navigateToAdd = () => {
    navigate('./add/')
  }

  const couponsColumns = [
    {
      title: t('common:title'),
      dataIndex: 'title',
      key: 'title',
      cellTitle: true
    },
    {
      title: t('coupons:value'),
      dataIndex: 'value',
      key: 'value',
      render: valueCell
    },
    {
      title: t('coupons:limit_count'),
      dataIndex: 'limit_count',
      key: 'limit_count'
    },
    {
      title: t('coupons:limit_date'),
      dataIndex: 'limit_date',
      key: 'limit_date'
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('copy'),
          onClick: (id, close) => {
            _handleCopy(id, close)
          }
        },
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            console.log('warning', id)
            dispatch({ type: DELETE_COUPON, id })
            close()
          }
        }
      ]
    }
  ]

  const navigateToDetails = (id, row, e) => {
    const url = `/coupons/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <MainLayout
      withSidebar={false}
      breadcrumbs={breadcrumbs}
      // loading={loading}
      // lastEdited={lastEdited}
      // lastEditedBy={lastEditedBy}
    >
      <Row justify="between" align="center" mt="R" wrap={'wrap'}>
        <Title label="navigation:inui.coupons_list"/>
        <div>
          <PrimaryButton
            label="coupons:add_coupon"
            handleClick={navigateToAdd}
            reverse
            withMargin
          />
        </div>
      </Row>
      <Table
        fullHeight
        columns={couponsColumns}
        rowKey="id"
        loadingSelector={selectCouponsLoading}
        resultsCountSelector={selectCouponsCount}
        dataSelector={selectCouponsData}
        onRowClick={navigateToDetails}
        leftStickyColumnCount={1}
        rightStickyColumnCount={1}
      />
    </MainLayout>
  )
}
export default CouponsPage

CouponsPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}

CouponsPage.defaultProps = {

}
