import { createSelector } from 'reselect'

const selectInuiContacts = state => state.inui

export const selectInuiContactsLoading = createSelector(
  selectInuiContacts,
  inui => inui.loading
)
export const selectInuiContactsCount = createSelector(
  selectInuiContacts,
  inui => inui.results_count
)
export const selectInuiContactsData = createSelector(
  selectInuiContacts,
  inui => inui.results
)
