import {
  BORDER_RADIUS,
  FormRemovableLabel,
  getColor,
  SPACING,
  styled,
  Row
} from '@gk-devteam/apmc-core-web'

export const CouponFormMainBlockStyle = styled.div`
  flex: 1;
  width: 100%;
`

export const RecipientStyle = styled(FormRemovableLabel)`
  margin: ${SPACING.SMALLEST / 2}rem;
`

export const BetweenStyle = styled.div`
  margin: 0 ${SPACING.SMALLEST}rem;
  user-select: none;
  color: ${props => getColor(props, 'lightText')};
`

export const CheckmarkCellStyle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: calc(${SPACING.SMALL}rem - 2px) 0;
  text-align: center;
`

export const ResultsBlockStyle = styled(Row)`
  flex: 1;
  overflow: scroll;
`
export const ResultsContainer = styled.ul`
  ${BORDER_RADIUS};
  padding: ${SPACING.SMALL}rem;
  border: solid 1px ${props => getColor(props, 'cardBorder')};
  margin: 0;
  list-style: none;
  flex: 2;
  height: 100%;
  overflow: scroll;
`

export const TableContainerStyle = styled.div`
  flex: 5;
  height: 100%;
  overflow: scroll;
  position: relative;
`
