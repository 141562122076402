import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  EmptyData,
  MainLayout,
  Row,
  SectionTitle,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'

import { fetchSeikatsu, toggleSeikatsu } from '../../../services/app_services/servicesService'
import { SERVICES_TYPES } from '../../../constants'
import { selectServiceName } from '../../../selectors'

import ServiceHeader from '../common/ServiceHeader'
import SeikatsuCard from './SeikatsuCard'

function Seikatsu ({ propertyID, serviceID }) {
  const { t } = useTranslation()
  const serviceName = useSelector(selectServiceName)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const breadcrumbs = [
    {
      label: 'navigation:services',
      link: `/settings/services?type=${SERVICES_TYPES.EXTERNAL}`
    },
    {
      label: serviceName,
      link: `/settings/services/${serviceID}/properties`
    },
    {
      label: 'navigation:service_properties',
      link: `/settings/services/${serviceID}/properties`
    },
    {
      label: 'navigation:services_seikatsu_settings',
      link: `/settings/services/${serviceID}/properties/seikatsu`
    }
  ]

  const fetchData = useCallback(
    async () => {
      try {
        const cancelSource = CancelToken.source()
        const res = await fetchSeikatsu(propertyID, cancelSource)
        if (res?.data) {
          setData(res.data)
        } else {
          message.error(t('fetch_error'))
        }
      } catch (error) {
        message.error(t('fetch_error'))
      } finally {
        setLoading(false)
      }
    },
    [propertyID, t]
  )

  const _handleToggle = useCallback(
    async (id, enable) => {
      setLoading(true)
      try {
        const cancelSource = CancelToken.source()
        const res = await toggleSeikatsu({
          property_id: propertyID,
          id,
          enable
        }, cancelSource)
        if (res?.status === 204) {
          fetchData()
        } else {
          message.error(t('post_error'))
        }
      } catch (error) {
        message.error(t('post_error'))
      }
    },
    [propertyID, t, fetchData]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const _renderSeikatsuCards = () => {
    if (!data) return <EmptyData />
    return data.map(item => {
      return <SeikatsuCard key={item.id} item={item} handleToggle={_handleToggle} />
    })
  }

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      {/* <Row mb="R">
        <Title label="navigation:settings" />
      </Row> */}
      <ServiceHeader level={t('navigation:services_seikatsu_settings')}/>
      <Row mb="R">
        <SectionTitle label="services:seikatsu.available_genres" />
      </Row>
      {_renderSeikatsuCards()}
    </MainLayout>
  )
}

Seikatsu.propTypes = {
  serviceID: PropTypes.string.isRequired,
  propertyID: PropTypes.string.isRequired
}

export default Seikatsu
