import React, { memo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import {
  selectPropertyName,
  selectGuideCategoriesLoading,
  selectGuideCategoriessHiddenBy,
  selectRoomName,
  selectGuideCategoriessHiddenById
} from '../../../../selectors'
import { FETCH_GUIDE_CATEGORIES, RESET_GUIDE_CATEGORIES } from '../../../../types'

import AdminGuideCategoriesList from './AdminGuideCategoriesList'
import PropertyGuideCategoriesList from './PropertyGuideCategoriesList'
// import RoomGuideCategoriesList from './RoomGuideCategoriesList'
import PanelLayout from '../../../../components/Display/Panels/PanelLayout'

const RoomGuideCategoriesPage = ({ propertyID, roomID }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectGuideCategoriesLoading)
  const propertyName = useSelector(selectPropertyName)
  const roomName = useSelector(selectRoomName)

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      text: roomName,
      link: `/properties/edit/${propertyID}/rooms/edit/${roomID}`
    },
    {
      label: 'navigation:rooms_guides',
      link: `/properties/edit/${propertyID}/rooms/edit/${roomID}/guides`
    }
  ]

  useEffect(() => {
    dispatch({ type: FETCH_GUIDE_CATEGORIES, config: { room: roomID } })
    return () => {
      dispatch({ type: RESET_GUIDE_CATEGORIES })
    }
  }, [dispatch, roomID])

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectGuideCategoriessHiddenBy}
      hiddenByIdSelector={selectGuideCategoriessHiddenById}
      propertyID={propertyID}
      title="navigation:rooms_guide_categories"
      authorized="guides.functions.add"
      info="services:level_message.room"
      infoTitle="services:level_message.roomTitle"
    >
      <AdminGuideCategoriesList />
      <PropertyGuideCategoriesList />
      {/* <RoomGuideCategoriesList editModal={_openAddModal} /> */}
    </PanelLayout>
  )
}
export default memo(RoomGuideCategoriesPage)

RoomGuideCategoriesPage.propTypes = {
  propertyID: PropTypes.string.isRequired,
  roomID: PropTypes.string.isRequired
}
RoomGuideCategoriesPage.defaultProps = {}
