import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useTranslation,
  PageLayout,
  LeftNavigationMenu
} from '@gk-devteam/apmc-core-web'

import { selectPages, selectUserRole } from '../../selectors'
import { ROLES, SERVICES_TYPES } from '../../constants'

import NotFoundPage from '../../pages/NotFoundPage'

const SettingsBlock = ({ enabled, children }) => {
  const { t } = useTranslation(['navigation'])
  const pages = useSelector(selectPages)
  const userRole = useSelector(selectUserRole)

  if (!enabled || !children || !pages) return <NotFoundPage />

  const getMenu = () => {
    const { settings } = pages
    const {
      profile,
      adminProfile,
      adminTasksSettings,
      preferences,
      faq,
      manuals,
      guides,
      contacts,
      services
    } = settings.functions
    const menu = []
    if (profile) {
      menu.push({
        label: t('navigation:settings_user_profile'),
        link: './'
      })
    }
    if (adminProfile) {
      menu.push({
        label: userRole && userRole === ROLES.OWNER ? t('navigation:settings_owner_profile') : t('navigation:settings_admin_profile'),
        link: userRole && userRole === ROLES.OWNER ? './' : './admin_profile'
      })
    }
    if (preferences) {
      menu.push({
        label: t('navigation:settings_preferences'),
        link: './preferences'
      })
    }
    if (adminTasksSettings) {
      menu.push({
        label: t('navigation:settings_admin_tasks_settings'),
        link: './admin_tasks'
      })
    }
    if (faq) {
      menu.push({
        label: t('navigation:settings_faq'),
        link: './faq'
      })
    }
    if (manuals) {
      menu.push({
        label: t('navigation:settings_manuals'),
        link: './manuals'
      })
    }
    if (guides) {
      menu.push({
        label: t('navigation:settings_guide_categories'),
        link: './guides'
      })
    }
    if (contacts) {
      menu.push({
        label: t('navigation:settings_contacts'),
        link: './contacts'
      })
    }
    if (services) {
      menu.push({
        label: t('navigation:services'),
        link: `./services?type=${SERVICES_TYPES.INTERNAL}`
      })
    }

    return menu
  }

  return (
    <PageLayout
      sidebar={() => <LeftNavigationMenu menu={getMenu()}/>}
      noMain
    >
      { children }
    </PageLayout>
  )
}

export default SettingsBlock

SettingsBlock.propTypes = {
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}
