import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Row,
  Column,
  Title,
  SectionTitle,
  Text,
  FormSubmitButton,
  CheckboxStandAlone,
  RadioStandAlone
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_CHAT_STATUS,
  RESET_CHAT_SETTINGS
} from '../../../../types'
import {
  selectOwnerID,
  selectPropertyName,
  selectAdminChatSettings,
  selectPropertyChatSettings,
  selectChatLoading,
  selectChatHiddenBy,
  selectChatHiddenById
} from '../../../../selectors'
import {
  updateChatSettings,
  fetchChatSettings
} from '../../../../actions'

import PanelLayout from '../../../../components/Display/Panels/PanelLayout/PanelLayout'
import ChatSettingsForm from '../../../../forms/chat/ChatSettingsForm'
import TimePreview from '../../../../forms/chat/TimePreview'
import {
  TimeStyle,
  CardStyle
} from '../adminTasks.style'
import { LeftContainerStyle } from './PropertyAdminTasksSettingsPage.style'

const weekdays = [
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
  'Sun'
]

const PropertyAdminTasksSettingsPage = ({ propertyID }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectChatLoading)
  const ownerID = useSelector(selectOwnerID)
  const propertyName = useSelector(selectPropertyName)
  const propertyData = useSelector(selectPropertyChatSettings)
  const adminData = useSelector(selectAdminChatSettings)
  const [settingsLevel, setSettingsLevel] = useState('')
  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_admin_tasks_settings',
      link: `/properties/edit/${propertyID}/contacts`
    }
  ]

  const fetchData = useCallback(
    () => {
      dispatch(fetchChatSettings({
        adminDocument: `owner_${ownerID}`,
        propertyDocument: `property_${propertyID}`
      }))
    },
    [dispatch, ownerID, propertyID]
  )

  useEffect(() => {
    if (ownerID && propertyID) {
      fetchData()
    }
    return () => {
      dispatch({ type: RESET_CHAT_SETTINGS })
    }
  }, [dispatch, fetchData, ownerID, propertyID])

  useEffect(() => {
    if (propertyData && propertyData.autoMessage) {
      setSettingsLevel('property')
    } else {
      setSettingsLevel('admin')
    }
  }, [propertyData, adminData])

  useEffect(() => {
    dispatch({ type: FETCH_CHAT_STATUS, params: { property_id: propertyID } })
  }, [dispatch, propertyID])

  const _handleSubmit = (data) => {
    if (ownerID) {
      dispatch(updateChatSettings({
        documentName: `property_${propertyID}`,
        data,
        onSuccess: fetchData
        // onFail: message.error(t('post_error'))
      }))
    }
  }

  const _handleSettingsLevelChange = (value) => {
    if (value === 'property') {
      _handleSubmit({ autoMessage: true })
    }
    if (value === 'admin') {
      _handleSubmit({ autoMessage: false })
    }
  }

  const _renderAdminSettings = () => {
    const {
      autoMessage,
      startTime,
      endTime,
      message,
      daysOfWeek
    } = adminData
    return (
      <CardStyle
        title="chat.admin_chat_room_settings"
        mt="R"
        disabled={propertyData && propertyData.autoMessage}
      >
        <Row align="start" wrap="wrap">
          <LeftContainerStyle>
            <Row>
              <Text label="chat.auto_reply" />
            </Row>
            <Row mt="R">
              <CheckboxStandAlone
                autoH
                noLabel
                name="admin_autoMessage"
                checked={autoMessage}
                valueLabel="use"
                disabled
              />
            </Row>
            <Row mt="R">
              {
                weekdays.map(weekday => {
                  return (
                    <CheckboxStandAlone
                      key={weekday}
                      autoH
                      noLabel
                      name={`daysOfWeek.${weekday}`}
                      checked={daysOfWeek && daysOfWeek[weekday]}
                      valueLabel={`daysOfWeek.${weekday}`}
                      disabled
                    />
                  )
                })
              }
            </Row>
            <Row mt="R">
              <TimeStyle>
                <Text label="chat.auto_reply_start_time" />
                <Text text={startTime} />
              </TimeStyle>
              <TimeStyle>
                <Text label="chat.auto_reply_end_time" />
                <Text text={endTime} />
              </TimeStyle>
            </Row>
            <Row mt="R">
              <TimeStyle>
                <Text label="chat.auto_reply_message" />
                <Text text={message} />
              </TimeStyle>
            </Row>
          </LeftContainerStyle>
          <TimePreview level="admin" disabled={settingsLevel !== 'admin'} />
        </Row>
      </CardStyle>
    )
  }

  return (
    <PanelLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
      hiddenSelector={selectChatHiddenBy}
      hiddenByIdSelector={selectChatHiddenById}
      noHeader
    >
      <Row justify="between" mb="ML">
        <Title label='navigation:settings_admin_tasks_settings' />
        <FormSubmitButton formID="chat_settings_form" postingSelector={selectChatLoading} />
      </Row>
      <SectionTitle label="chat.chat_room_settings" />

      <Column mt="R" align="start">
        <Text label="chat.select_settings_level" />
        <Row mt="XS" pl="XS">
          <RadioStandAlone
            name="settings_level"
            mb="0"
            handleChange={_handleSettingsLevelChange}
            value={settingsLevel}
            options={[
              {
                label: 'chat.admin_settings',
                value: 'admin'
              },
              {
                label: 'chat.property_settings',
                value: 'property'
              }
            ]}
          />
        </Row>
      </Column>
      {
        adminData
          ? _renderAdminSettings()
          : null
      }
      <CardStyle
        title="chat.property_chat_room_settings"
        mt="R"
        disabled={propertyData && !propertyData.autoMessage}
      >
        <ChatSettingsForm data={propertyData} handleSubmit={_handleSubmit} noCheckbox/>
      </CardStyle>
    </PanelLayout>
  )
}
export default PropertyAdminTasksSettingsPage

PropertyAdminTasksSettingsPage.propTypes = {
  propertyID: PropTypes.string.isRequired
}
PropertyAdminTasksSettingsPage.defaultProps = {}
