import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  dynamicLink,
  Image,
  LightText,
  Link,
  RadioStandAlone,
  Row,
  SmallText,
  SPACING,
  Text,
  ToastTitle,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import { Tooltip } from 'antd'
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'

import {
  SERVICES_LEVEL,
  SERVICES_PLAN,
  SERVICES_TYPES,
  SERVICES_DISPLAY,
  SERVICES_DISPLAY_OPTIONS,
  LAST_LEVEL_SERVICES_DISPLAY_OPTIONS
} from '../../../constants'
import {
  selectContractID,
  selectManageCompanyName,
  selectPropertyID,
  selectRoomID,
  selectContractPropertyID
} from '../../../selectors'
import { FETCH_SERVICES, POST_SERVICE_DISPLAY } from '../../../types'
import { LevelContext } from './ServicesPage'

import {
  HelpIconContainerStyle,
  IconStyle,
  ParentHelpStyle,
  ServiceDisplayContainerStyle,
  ServiceDisplayStyle,
  ServiceItemStyle,
  ServiceItemMainStyle,
  TopRowStyle,
  ServiceContentStyle
} from './services.style'

function ServicesListItem ({ item }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const manageCompanyName = useSelector(selectManageCompanyName)
  const propertyID = useSelector(selectPropertyID)
  const contractPropertyID = useSelector(selectContractPropertyID)
  const roomID = useSelector(selectRoomID)
  const contractID = useSelector(selectContractID)
  const level = useContext(LevelContext)
  const {
    id,
    type,
    name,
    icon,
    description,
    plan,
    parent_display,
    parent_level,
    parent_id,
    last_level,
    display,
    contracts_count
  } = item

  const displayOptions = last_level ? LAST_LEVEL_SERVICES_DISPLAY_OPTIONS : SERVICES_DISPLAY_OPTIONS
  const currentDisplay = parent_display === SERVICES_DISPLAY.CUSTOM_DISPLAY ? display : parent_display

  const _handleChange = (value) => {
    if (
      plan !== SERVICES_PLAN.MANAGED &&
      parent_display === SERVICES_DISPLAY.CUSTOM_DISPLAY
    ) {
      console.log(`updating service ${id} to ${value}`)
      let query
      switch (level) {
        case SERVICES_LEVEL.ADMIN:
          break
        case SERVICES_LEVEL.PROPERTY:
          query = { property_id: propertyID }
          break
        case SERVICES_LEVEL.ROOM:
          query = { room_id: roomID }
          break
        case SERVICES_LEVEL.CONTRACT:
          query = { contract_id: contractID }
          break
        default:
          break
      }
      const data = {
        ...query,
        service_id: id,
        display: value
      }
      const onSuccess = () => {
        dispatch({ type: FETCH_SERVICES, query })
      }
      dispatch({ type: POST_SERVICE_DISPLAY, data, onSuccess })
    }
  }

  const _navigateToDetails = (e) => {
    if (level === SERVICES_LEVEL.ADMIN) {
      dynamicLink({
        url: `/settings/services/${id}/properties`,
        event: e,
        navigate
      })
    }
  }

  const _renderHelp = () => {
    if (plan !== SERVICES_PLAN.MANAGED) return null
    return (
      <Tooltip
        title={t('services:managed_service_help', { manageCompanyName })}
        overlayStyle={{
          fontSize: `${SPACING.SMALL}rem`
        }}
        placement="top"
      >
        <HelpIconContainerStyle>
          <QuestionCircleOutlined />
        </HelpIconContainerStyle>
      </Tooltip>
    )
  }

  const _renderParentHelp = () => {
    if (plan === SERVICES_PLAN.MANAGED || parent_display === SERVICES_DISPLAY.CUSTOM_DISPLAY) return null
    let level, link, additionalText
    switch (parent_level) {
      case SERVICES_LEVEL.MANAGE:
        level = manageCompanyName
        additionalText = t('services:service_levels.contact_manage_company', { manageCompanyName })
        break
      case SERVICES_LEVEL.ADMIN:
        level = t('services:service_levels.admin')
        link = `/settings/services?type=${type}`
        break
      case SERVICES_LEVEL.PROPERTY:
        level = t('services:service_levels.property')
        link = `/properties/edit/${parent_id}/services?type=${type}`
        break
      case SERVICES_LEVEL.ROOM:
        level = t('services:service_levels.room')
        link = `/properties/edit/${propertyID || contractPropertyID}/rooms/edit/${parent_id}/services?type=${type}`
        break
      default:
        break
    }
    return (
      <ParentHelpStyle>
        <ExclamationCircleOutlined/>
        <SmallText text={t('services:service_levels.description', { level })} />
        {
          link ? (
            <>
              <SmallText text={t('services:service_levels.update_here_1', { level })} />
              <Link to={link}>{t('here')}</Link>
              <SmallText text={t('services:service_levels.update_here_2', { level })} />
            </>
          ) : null
        }
        {
          additionalText ? <SmallText text={additionalText} /> : null
        }
      </ParentHelpStyle>
    )
  }

  const _renderDisplay = () => {
    if (plan === SERVICES_PLAN.MANAGED || parent_display !== SERVICES_DISPLAY.CUSTOM_DISPLAY) {
      const label = displayOptions.find(option => option.value === currentDisplay)?.label
      if (!label) return null
      return (
        <ServiceDisplayStyle
          onClick={e => e.stopPropagation()}
        >
          {t(label)}
        </ServiceDisplayStyle>
      )
    }
    return (
      <RadioStandAlone
        name="display"
        handleChange={_handleChange}
        value={currentDisplay}
        options={displayOptions}
        noLabel
        size="fluid"
        autoH
        mr="0"
      />
    )
  }
  return (
    <ServiceItemStyle
      disabled={plan === SERVICES_PLAN.MANAGED || parent_display !== SERVICES_DISPLAY.CUSTOM_DISPLAY}
      onClick={_navigateToDetails}
      link={level === SERVICES_LEVEL.ADMIN}
    >
      <TopRowStyle>
        <ServiceItemMainStyle>
          <IconStyle>
            <Image src={icon} />
          </IconStyle>
          <ServiceContentStyle>
            <Row>
              <ToastTitle text={name} />
              { _renderHelp() }
            </Row>
            <Row>
              <LightText label="services:contracts_count" />
              <LightText text={contracts_count || 0} />
            </Row>
            <Row>
              <Text text={description} />
            </Row>
            { _renderParentHelp() }
          </ServiceContentStyle>
        </ServiceItemMainStyle>
        <ServiceDisplayContainerStyle>
          { _renderDisplay() }

        </ServiceDisplayContainerStyle>
      </TopRowStyle>
    </ServiceItemStyle>
  )
}

ServicesListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.oneOf(Object.values(SERVICES_TYPES)).isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    description: PropTypes.string,
    plan: PropTypes.oneOf(Object.values(SERVICES_PLAN)).isRequired,
    parent_display: PropTypes.number.isRequired,
    parent_level: PropTypes.oneOf(Object.values(SERVICES_LEVEL)).isRequired,
    parent_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    last_level: PropTypes.bool.isRequired,
    display: PropTypes.oneOf(Object.values(SERVICES_DISPLAY)).isRequired,
    contracts_count: PropTypes.number
  }).isRequired
}

export default ServicesListItem
