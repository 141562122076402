import { createSelector } from 'reselect'

const selectRoom = state => state.room

export const selectRoomLoading = createSelector(
  selectRoom,
  room => room.loading
)
export const selectPostingRoom = createSelector(
  selectRoom,
  room => room.posting
)
export const selectRoomData = createSelector(
  selectRoom,
  room => room.data
)
export const selectRoomError = createSelector(
  selectRoom,
  room => room.error
)
export const selectRoomFormData = createSelector(
  selectRoom,
  room => room.data && {
    id: room.data.id,
    room_number: room.data.room_number,
    room_manage_id: room.data.room_manage_id,
    room_surface: room.data.room_surface,
    room_plan: room.data.room_plan
  }
)

export const selectRoomName = createSelector(
  selectRoom,
  room => room.data && room.data.room_number
)
export const selectRoomID = createSelector(
  selectRoom,
  room => room.data && room.data.id
)
export const selectRoomContract = createSelector(
  selectRoom,
  room => room.data && room.data.contract
)
