import { createReducer } from '@reduxjs/toolkit'

import {
  FETCH_CHAT_ROOMS,
  FETCH_CHAT_ROOMS_SUCCESS,
  FETCH_CHAT_ROOMS_FAIL,
  RESET_CHAT_ROOMS,
  SUBSCRIBE_CHAT_WAITING_ROOMS,
  SUBSCRIBE_CHAT_WAITING_ROOMS_SUCCESS,
  SUBSCRIBE_CHAT_WAITING_ROOMS_FAIL,
  UNSUBSCRIBE_CHAT_WAITING_ROOMS,
  // FETCH_CHAT_SETTINGS,
  FETCH_ADMIN_CHAT_SETTINGS_SUCCESS,
  FETCH_PROPERTY_CHAT_SETTINGS_SUCCESS,
  FETCH_ROOM_CHAT_SETTINGS_SUCCESS,
  // FETCH_CHAT_SETTINGS_FAIL,
  UPDATE_CHAT_SETTINGS,
  // UPDATE_CHAT_SETTINGS_SUCCESS,
  // UPDATE_CHAT_SETTINGS_FAIL,
  FETCH_CHAT_STATUS,
  FETCH_CHAT_STATUS_SUCCESS,
  FETCH_CHAT_STATUS_FAIL,
  SUBSCRIBE_CHAT_ROOM,
  SUBSCRIBE_CHAT_ROOM_SUCCESS,
  SUBSCRIBE_CHAT_MESSAGES_SUCCESS,
  SUBSCRIBE_CHAT_ROOM_FAIL,
  UNSUBSCRIBE_CHAT_ROOM,
  POST_CHAT_MESSAGE,
  POST_CHAT_MESSAGE_SUCCESS,
  POST_CHAT_MESSAGE_FAIL,
  DELETE_CHAT_MESSAGE,
  DELETE_CHAT_MESSAGE_SUCCESS,
  DELETE_CHAT_MESSAGE_FAIL,
  UPDATE_CHAT_ROOM_STATUS,
  UPDATE_CHAT_ROOM_STATUS_SUCCESS,
  UPDATE_CHAT_ROOM_STATUS_FAIL,
  RESET_CHAT_STATUS,
  RESET_CHAT_SETTINGS
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: false,
  deleting: false,
  loadingHiddenBy: true,
  error: null,
  rooms: null,
  waitingRooms: null,
  hiddenBy: null,
  hiddenById: null,
  settings: {
    admin: null,
    property: null,
    room: null
  },
  room: null,
  messages: null
}

export default createReducer(INITIAL_STATE, {
  [SUBSCRIBE_CHAT_WAITING_ROOMS]: (state, action) => {
    state.loading = true
    state.waitingRooms = null
  },
  [SUBSCRIBE_CHAT_WAITING_ROOMS_SUCCESS]: (state, action) => {
    state.loading = false
    state.waitingRooms = action.payload
  },
  [SUBSCRIBE_CHAT_WAITING_ROOMS_FAIL]: (state, action) => {
    state.loading = false
    state.waitingRooms = null
  },
  [UNSUBSCRIBE_CHAT_WAITING_ROOMS]: () => INITIAL_STATE,
  [FETCH_CHAT_ROOMS]: (state, action) => {
    state.loading = true
    state.rooms = null
  },
  [FETCH_CHAT_ROOMS_SUCCESS]: (state, action) => {
    state.loading = false
    state.rooms = action.payload
  },
  [FETCH_CHAT_ROOMS_FAIL]: (state, action) => {
    state.loading = false
    state.rooms = null
  },
  [RESET_CHAT_ROOMS]: (state) => {
    state.rooms = null
  },
  [SUBSCRIBE_CHAT_ROOM]: (state, action) => {
    state.loading = true
    state.error = null
    state.room = null
    state.messages = null
  },
  [SUBSCRIBE_CHAT_ROOM_SUCCESS]: (state, action) => {
    state.loading = false
    state.room = action.payload
  },
  [SUBSCRIBE_CHAT_MESSAGES_SUCCESS]: (state, action) => {
    state.loading = false
    state.messages = action.payload
  },
  [SUBSCRIBE_CHAT_ROOM_FAIL]: (state, action) => {
    state.loading = false
    state.room = null
    state.messages = null
    state.error = action.payload
  },
  [UNSUBSCRIBE_CHAT_ROOM]: (state, action) => {
    state.loading = false
    state.error = null
    state.room = null
    state.messages = null
  },
  [POST_CHAT_MESSAGE]: (state, action) => {
    state.posting = true
  },
  [POST_CHAT_MESSAGE_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CHAT_MESSAGE_FAIL]: (state, action) => {
    state.posting = false
  },
  [DELETE_CHAT_MESSAGE]: (state, action) => {
    state.deleting = true
  },
  [DELETE_CHAT_MESSAGE_SUCCESS]: (state, action) => {
    state.deleting = false
  },
  [DELETE_CHAT_MESSAGE_FAIL]: (state, action) => {
    state.deleting = false
  },
  [UPDATE_CHAT_ROOM_STATUS]: (state, action) => {
    state.loading = true
  },
  [UPDATE_CHAT_ROOM_STATUS_SUCCESS]: (state, action) => {
    state.loading = false
  },
  [UPDATE_CHAT_ROOM_STATUS_FAIL]: (state, action) => {
    state.loading = false
  },
  [FETCH_ADMIN_CHAT_SETTINGS_SUCCESS]: (state, action) => {
    state.settings.admin = action.payload
    state.loading = false
  },
  [FETCH_PROPERTY_CHAT_SETTINGS_SUCCESS]: (state, action) => {
    state.settings.property = action.payload
    state.loading = false
  },
  [FETCH_ROOM_CHAT_SETTINGS_SUCCESS]: (state, action) => {
    state.settings.room = action.payload
    state.loading = false
  },
  [UPDATE_CHAT_SETTINGS]: (state, action) => {
    state.loading = true
  },
  [FETCH_CHAT_STATUS]: (state, action) => {
    state.loadingHiddenBy = true
  },
  [FETCH_CHAT_STATUS_SUCCESS]: (state, action) => {
    state.loadingHiddenBy = false
    state.hiddenBy = action.payload.hiddenBy
    state.hiddenById = action.payload.hiddenById
  },
  [FETCH_CHAT_STATUS_FAIL]: (state, action) => {
    state.loadingHiddenBy = false
  },
  [RESET_CHAT_STATUS]: (state, action) => {
    state.hiddenBy = null
    state.hiddenById = null
  },
  [RESET_CHAT_SETTINGS]: (state, action) => {
    state.settings = {
      admin: null,
      property: null,
      room: null
    }
  }
})
