import React, { useContext } from 'react'
import { ReadOnlyContext } from '@gk-devteam/apmc-core-web'
import SearchAddressButton from '../../../components/Misc/SearchAddressButton'

export default function ContractorWorkAddressButton () {
  const isReadOnly = useContext(ReadOnlyContext)
  return (
    <SearchAddressButton
      postcodeKey="contractor.work_postcode"
      prefectureKey="contractor.work_prefecture"
      cityKey="contractor.work_city"
      addressKey="contractor.work_address"
      disabled={isReadOnly}
    />
  )
}
