import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import {
  PageLayout,
  RESET_PARAMS,
  Row,
  selectQueryParams,
  Table,
  Tag,
  Title,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import {
  FETCH_INUI_CONTACTS,
  RESET_INUI_CONTACTS
} from '../../../types'
import {
  selectInuiContactsLoading,
  selectInuiContactsData,
  selectInuiContactsCount
} from '../../../selectors'
import { inuiContactsSearchSchema } from '../../../validation'

import InuiContactsSearchBar from './InuiContactsSearchBar'
import ContactDetailsModal from './ContactDetailsModal'

const breadcrumbs = [
  {
    label: 'navigation:inui.contacts',
    link: '/inui/contacts'
  }
]

function InuiContactsPage ({ location }) {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const { t } = useTranslation()
  const prevQuery = useRef(null)
  const [detailsVisible, setDetailsVisible] = useState(false)
  const [details, setDetails] = useState(null)

  const closeModal = () => {
    setDetailsVisible(false)
    setDetails(null)
  }

  const fetchData = useCallback(
    () => {
      const schema = inuiContactsSearchSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_INUI_CONTACTS, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_INUI_CONTACTS })
          }
        })
    },
    [dispatch, query]
  )
  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_INUI_CONTACTS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const contactType = useCallback(
    (value, cellKey, rowID, className) => {
      let color
      const label = `inui:contact_type.${value}`
      switch (value) {
        case 1:
          color = 'success'
          break
        case 2:
          color = 'info'
          break
        case 3:
          color = 'danger'
          break
        case 4:
          color = 'process'
          break
        case 5:
          color = 'accent'
          break

        default:
          break
      }

      return <Tag label={label} color={color}/>
    },
    []
  )

  const contactsColumns = [
    {
      title: t('inui:name'),
      dataIndex: 'name',
      key: 'name',
      cellTitle: true
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name'
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number'
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: t('inui:contact_types'),
      dataIndex: 'contact_type',
      key: 'contact_type',
      render: contactType
    },
    {
      title: t('notices:sent_time'),
      dataIndex: 'sent_at',
      key: 'sent_at'
    }
  ]

  const showDetails = (id, row) => {
    setDetailsVisible(true)
    setDetails(row)
  }

  return (
    <PageLayout
      sidebar={
        () => <InuiContactsSearchBar />
      }
      breadcrumbs={breadcrumbs}
      // loading={loading}
    >
      <ContactDetailsModal
        visible={detailsVisible}
        close={closeModal}
        data={details}
      />
      <Row justify="between" align="center" mt="R" wrap={'wrap'}>
        <Title label="navigation:inui.contacts"/>
      </Row>
      <Table
        fullHeight
        columns={contactsColumns}
        rowKey="id"
        loadingSelector={selectInuiContactsLoading}
        resultsCountSelector={selectInuiContactsCount}
        dataSelector={selectInuiContactsData}
        onRowClick={showDetails}
      />
    </PageLayout>
  )
}

export default InuiContactsPage

InuiContactsPage.propTypes = {
  location: PropTypes.object.isRequired
}
