import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {
  Radio,
  Column,
  useTranslation,
  UPDATE_FORM_ERROR
} from '@gk-devteam/apmc-core-web'

export default function SelectFinalInspectionDate () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const date1 = useSelector(state => state?.form?.final_inspection_datetime_1)
  const date2 = useSelector(state => state?.form?.final_inspection_datetime_2)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (date1 && date2 && dayjs(date1).isSame(dayjs(date2))) {
      setDisabled(true)
      dispatch({
        type: UPDATE_FORM_ERROR,
        payload: {
          final_inspection_datetime: [
            t('validation:must_be_different', { label: t('contracts:final_inspection_choice_1'), otherField: t('contracts:final_inspection_choice_2') })
          ]
        }
      })
    } else {
      dispatch({
        type: UPDATE_FORM_ERROR,
        payload: { final_inspection_datetime: null }
      })
      setDisabled(false)
    }
  }, [date1, date2, dispatch, t])

  return (
    <Column align="baseline">
      <Radio
        size="fluid"
        name="final_inspection_datetime"
        label="contracts:select_final_inspection_datetime"
        options={[
          {
            label: t('contracts:final_inspection_choice_1'),
            value: date1
          },
          {
            label: t('contracts:final_inspection_choice_2'),
            value: date2
          }
        ]}
        required
        disabled={disabled}
      />
    </Column>
  )
}
