import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CancelToken } from 'axios'
import { message } from 'antd'
import PropTypes from 'prop-types'
import {
  useTranslation,
  getFileFromURL,
  getFileExtension,
  PageLayout
} from '@gk-devteam/apmc-core-web'

import {
  RESET_COUPON,
  FETCH_COUPON_SUCCESS
} from '../../../../types'
import { fetchCoupon } from '../../../../services'
import CouponForm from '../../../../forms/CouponForm/CouponForm'

const breadcrumbs = [
  {
    label: 'navigation:inui.coupons_list',
    link: '/coupons'
  },
  {
    label: 'navigation:inui.coupons_add',
    link: '/coupons/add'
  }
]

const initialData = {
  title: null,
  value: null,
  value_type: 1,
  limit_count: null,
  limit_date: null,
  description: null,
  image: null,
  recipient_type: 1,
  recipients_id: null
}

const CouponsAddPage = ({ location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [formData, setFormData] = useState(null)
  const [loading, setLoading] = useState(null)

  const prepareData = useCallback(
    async (data) => {
      if (data && data.id) {
        const editedData = { ...data }
        editedData.delete_file = false
        delete editedData.id
        delete editedData.modified
        delete editedData.modifiedBy
        delete editedData.history
        delete editedData.results_count
        delete editedData.max_pages
        delete editedData.recipients_list
        if (editedData.file_url) {
          try {
            const blob = await getFileFromURL(data.file_url)
            const extension = getFileExtension(data.file_url)
            const blobURL = URL.createObjectURL(blob)
            editedData.file = blobURL
            editedData.file_filename = `coupon.${extension}`
          } catch (error) {
            console.warning('error retrieving file blob', error)
          }
        }
        setFormData(editedData)
      }
    },
    []
  )

  const fetchData = useCallback(
    async (id) => {
      const cancelSource = CancelToken.source()
      setLoading(true)
      try {
        const res = await fetchCoupon(id, {}, cancelSource)
        setLoading(false)
        if (res && res.data) {
          prepareData(res.data)
          dispatch({ type: FETCH_COUPON_SUCCESS, payload: null })
        } else {
          message.error(t('fetch_error'))
        }
      } catch (error) {
        setLoading(false)
        message.error(t('fetch_error'))
      }
    },
    [dispatch, prepareData, t]
  )

  useEffect(() => {
    if (location && location.state && location.state.copy) {
      fetchData(location.state.id)
    } else if (location && location.state && location.state.userID) {
      setFormData({
        ...initialData,
        recipient_type: 2,
        recipients_id: [location.state.userID],
        recipients_list: [{
          id: location.state.userID,
          label: location.state.userName
        }]
      })
      dispatch({ type: FETCH_COUPON_SUCCESS, payload: null })
    } else {
      setFormData(initialData)
      dispatch({ type: FETCH_COUPON_SUCCESS, payload: null })
    }
    return () => {
      dispatch({ type: RESET_COUPON })
    }
  }, [location, dispatch, fetchData])

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      loading={loading}
    >
      {
        formData
          ? <CouponForm data={formData} editable={true}/>
          : null
      }
    </PageLayout>
  )
}

export default CouponsAddPage

CouponsAddPage.propTypes = {
  location: PropTypes.object
}
CouponsAddPage.defaultProps = {

}
