export const FETCH_REFUNDS = 'fetch_refunds'
export const FETCH_REFUNDS_SUCCESS = 'fetch_refunds_success'
export const FETCH_REFUNDS_FAIL = 'fetch_refunds_fail'
export const RESET_REFUNDS = 'reset_refunds'

export const FETCH_REFUND = 'fetch_refund'
export const FETCH_REFUND_SUCCESS = 'fetch_refund_success'
export const FETCH_REFUND_FAIL = 'fetch_refund_fail'
export const RESET_REFUND = 'reset_refund'

export const UPDATE_REFUND_STATUS = 'update_refund_status'
export const UPDATE_REFUND_STATUS_SUCCESS = 'update_refund_status_success'
export const UPDATE_REFUND_STATUS_FAIL = 'update_refund_status_fail'
