import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  ColorPicker,
  FilePicker,
  Form,
  FormSubmitButton,
  LightText,
  RoleControlled,
  Row,
  TextInput,
  UPDATE_FORM,
  useTranslation
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import { POST_THEME } from '../../../types'
import { userPreferenceThemeUpdateValidation } from '../../../validation'
import {
  selectLoadingUserPreferences,
  selectPostingUserPreferences,
  selectUserPreferencesCurrentColorTheme
} from '../../../selectors'

import ColorBlock from './ColorBlock'
import ColorsBlocks from './ColorsBlocks'
import { ColorPickerContainerStyle, ResetButtonStyle } from './ThemeForm.style'

function ThemeForm ({ data }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const currentTheme = useSelector(selectUserPreferencesCurrentColorTheme)
  const initialValues = {
    ...data,
    header: {
      ...data?.header,
      file: data?.file || null,
      delete_siteLogo: (data?.header?.delete_siteLogo) || false
    }
  }

  const handleFormSubmit = (data) => {
    dispatch({ type: POST_THEME, data })
  }

  const _resetHeaderColor = () => {
    dispatch({
      type: UPDATE_FORM,
      payload: {
        'header.headerTextColor': '#FFFFFF',
        'header.headerBgColor': '#0d1a26'
      }
    })
  }

  return (
    <Form
      id="updateSettingAdminProfileForm"
      handleSubmit={handleFormSubmit}
      validationSchema={userPreferenceThemeUpdateValidation}
      loadingSelector={selectLoadingUserPreferences}
      postingSelector={selectPostingUserPreferences}
      initialData={initialValues}
      authorized="settings.functions.editTheme"
      // debug
    >
      <Row justify="end" mb="ML">
        <RoleControlled authorized="settings.functions.editTheme">
          <FormSubmitButton editMode postingSelector={selectPostingUserPreferences} />
        </RoleControlled>
      </Row>
      <Card title="settings:theme.header.title" mt="L">
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            type="text"
            name="header.siteName"
            label='settings:theme.header.siteName'
            placeholder="app-me!Cloud"
          />
        </Row>
        <Row wrap="wrap" align="baseline" mt="L">
          <FilePicker
            name="header.file"
            delete_name="header.delete_siteLogo"
            label='settings:theme.header.siteLogo'
            types={['image']}
          />
        </Row>
        <Row wrap="wrap" align="baseline" mt="L">
          <ColorPickerContainerStyle>
            <ColorPicker
              name="header.headerTextColor"
              label='settings:theme.header.headerTextColor'
              types={['image']}
              help='settings:theme.header.initiaTextColor'
            />
          </ColorPickerContainerStyle>
          <ColorPickerContainerStyle>
            <ColorPicker
              name="header.headerBgColor"
              label='settings:theme.header.headerBgColor'
              types={['image']}
              help='settings:theme.header.initialBgColor'
            />
          </ColorPickerContainerStyle>
        </Row>
        <Row>
          <ResetButtonStyle
            type="button"
            onClick={_resetHeaderColor}
          >{t('settings:theme.reset_colors')}</ResetButtonStyle>
        </Row>
      </Card>
      <Card title="settings:theme.colors.title" mt="L">
        <Row mb="XS">
          <LightText label="settings:theme.colors.current_settings" />
        </Row>
        <ColorBlock theme={currentTheme?.theme} label={currentTheme?.label} noClick/>
        <Row mt="M" wrap="wrap">
          <ColorsBlocks />
        </Row>
      </Card>
      {/* <Card title="settings:theme.fonts.title" mt="L">
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            type="text"
            name="fonts.font_family_jp"
            label='settings:theme.fonts.font_family_jp'
            placeholder="NotoSansJP"
          />
        </Row>
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            type="url"
            name="fonts.font_url_jp"
            label='settings:theme.fonts.font_url_jp'
            placeholder="https://fonts.gstatic.com/s/notosansjp/v25/-F62fjtqLzI2JPCgQBnw7HFowwII2lcnk-AFfrgQrvWXpdFg3KXxAMsKMbdN.0.woff2"
          />
        </Row>
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            type="text"
            name="fonts.font_family"
            label='settings:theme.fonts.font_family'
            placeholder="RedHatText"
          />
        </Row>
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            type="url"
            name="fonts.font_url"
            label='settings:theme.fonts.font_url'
            placeholder="https://fonts.gstatic.com/s/redhattext/v2/RrQXbohi_ic6B3yVSzGBrMxQZqctMc-JPWCN.woff2"
          />
        </Row>
        <Row wrap="wrap" align="baseline">
          <TextInput
            flex={1}
            type="number"
            name="fonts.font_size"
            label='settings:theme.fonts.font_size'
            placeholder="16"
            min="0"
          />
        </Row>
        <Row>
          <ResetButtonStyle
            type="button"
            onClick={_resetFonts}
          >{t('settings:theme.reset_fonts')}</ResetButtonStyle>
        </Row>
      </Card> */}
    </Form>
  )
}

ThemeForm.propTypes = {
  data: PropTypes.object
}

export default ThemeForm
