import { put, call, takeLatest, select, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { parseCSV } from '@gk-devteam/apmc-core-web'
import { CancelToken } from 'axios'
import { chunk } from 'lodash'
import dot from 'dot-object'

import { i18n } from '../../locales'

import {
  validateData,
  importData,
  fetchFaqCategories
} from '../../services'

import {
  PARSE_CSV,
  PARSE_CSV_SUCCESS,
  PARSE_CSV_FAIL,
  IMPORT_VALIDATION,
  IMPORT_VALIDATION_SUCCESS,
  IMPORT_VALIDATION_FAIL,
  IMPORT_DATA,
  IMPORT_DATA_PARTIAL_SUCCESS,
  IMPORT_DATA_PARTIAL_FAIL,
  IMPORT_DATA_SUCCESS,
  IMPORT_DATA_FAIL,
  UPDATE_STEP
} from '../../types'
import {
  IMPORT_TYPES,
  IMPORT_STEPS,
  IMPORT_CHUNK_SIZE,
  IMPORT_PROPERTIES_HEADERS,
  IMPORT_CONTRACTS_HEADERS,
  IMPORT_FAQ_HEADERS,
  IMPORT_CASA_CONTRACTS_HEADERS,
  IMPORT_YASUE_CONTRACTS_HEADERS,
  IMPORT_YASUE_CONSTRUCTION_WORK_HEADERS
} from '../../constants'

const getOwnerID = (state) => state.auth && state.auth.user && state.auth.user.owner_id
const getCustomPages = (state) => state?.auth?.pages?.custom
const getImportType = (state) => state.dataImport && state.dataImport.importType

const prepareData = (data, importType, ownerID) => {
  let preparedData = data && data.map(row => {
    const rowCopy = { ...row }
    if (rowCopy.originalIndex != null) delete rowCopy.originalIndex
    return rowCopy
  })

  switch (importType) {
    case IMPORT_TYPES.PROPERTIES:
      preparedData = {
        owner_id: ownerID,
        data: [...preparedData]
      }
      break
    case IMPORT_TYPES.CONTRACTS: {
      const converted = preparedData.map(row => {
        const rowCopy = { ...row }
        dot.object(rowCopy)
        return rowCopy
      })

      preparedData = {
        data: converted
      }
      break
    }
    case IMPORT_TYPES.FAQ:
      preparedData = preparedData.map(row => {
        const rowCopy = { ...row }
        if (rowCopy.categoryLabel != null) delete rowCopy.categoryLabel
        return rowCopy
      })
      break
    case IMPORT_TYPES.YASUE_CONTRUCTION_WORK:
      preparedData = {
        data: [...preparedData]
      }
      break
    default:
      break
  }
  return preparedData
}

export function * parseCSVSaga ({ file, onSuccess }) {
  const importType = yield select(getImportType)
  const customPages = yield select(getCustomPages)
  if (importType) {
    let headers
    switch (importType) {
      case IMPORT_TYPES.PROPERTIES:
        headers = IMPORT_PROPERTIES_HEADERS
        break
      case IMPORT_TYPES.CONTRACTS:
        if (customPages.casa) {
          headers = IMPORT_CASA_CONTRACTS_HEADERS
        } else if (customPages.yasue) {
          headers = IMPORT_YASUE_CONTRACTS_HEADERS
        } else {
          headers = IMPORT_CONTRACTS_HEADERS
        }
        break
      case IMPORT_TYPES.FAQ:
        headers = IMPORT_FAQ_HEADERS
        break
      case IMPORT_TYPES.YASUE_CONTRUCTION_WORK:
        if (customPages.yasue) {
          headers = IMPORT_YASUE_CONSTRUCTION_WORK_HEADERS
        }
        break
      default:
        break
    }

    try {
      const res = yield call(parseCSV, {
        file,
        headers,
        skipLines: 3
      })
      if (res) {
        let categories
        if (importType === IMPORT_TYPES.FAQ) {
          const categoriesRes = yield call(fetchFaqCategories)
          categories = categoriesRes && categoriesRes.data && categoriesRes.data.results
        }
        let data
        // Format data for validation
        switch (importType) {
          case IMPORT_TYPES.PROPERTIES:
            data = res.map(row => {
              if (row.rooms) row.rooms = row.rooms.split('$')
              return row
            })
            break
          case IMPORT_TYPES.CONTRACTS:
            if (customPages.yasue) {
              data = res.map(row => ({
                ...row,
                contract_manage_id: row.contract_manage_id?.padStart(8, '0')
              }))
            } else {
              data = res
            }
            break
          case IMPORT_TYPES.FAQ:
            data = res.map(row => {
              const categoryLabel = categories && categories.find(category => String(category.value) === row.category)
              if (row.category) row.categoryLabel = categoryLabel && categoryLabel.label
              return row
            })
            break
          case IMPORT_TYPES.YASUE_CONTRUCTION_WORK:
            data = res.map(row => ({
              ...row,
              contract_manage_id: row.contract_manage_id?.padStart(8, '0')
            }))
            break
          default:
            break
        }

        yield put({ type: PARSE_CSV_SUCCESS, payload: data })
        if (onSuccess && data) yield call(onSuccess)
      } else {
        yield put({ type: PARSE_CSV_FAIL, payload: { message: i18n.t('errors.csv_parse_error') } })
        message.error(i18n.t('errors.csv_parse_error'))
      }
    } catch (error) {
      yield put({ type: PARSE_CSV_FAIL, payload: error.data || error.message || 'unknow error' })
      message.error(error.message)
    }
  } else {
    console.warning('no import type...')
  }
}

export function * validateImportSaga ({ data, importType }) {
  const cancelSource = CancelToken.source()
  const ownerID = yield select(getOwnerID)
  const customPages = yield select(getCustomPages)

  if (data && importType && ownerID) {
    const preparedData = prepareData(data, importType, ownerID)
    try {
      const res = yield call(validateData, preparedData, importType, customPages, cancelSource)
      if (res) {
        yield put({ type: IMPORT_VALIDATION_SUCCESS })
      }
    } catch (error) {
      if (error.data && error.data.code === 400) {
        yield put({
          type: IMPORT_VALIDATION_FAIL,
          payload: {
            errors: error.data && error.data.message,
            message: i18n.t('import:validation_error'),
            data
          }
        })
      } else {
        yield put({
          type: IMPORT_VALIDATION_FAIL,
          payload: {
            errors: null,
            message: error.data || error.message || 'unknow error',
            data
          }
        })
      }
    } finally {
      if (yield cancelled()) {
        console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
        yield call(cancelSource.cancel)
      }
    }
  }
}

export function * importDataSaga ({ data, importType, onValidateSuccess }) {
  const cancelSource = CancelToken.source()
  const ownerID = yield select(getOwnerID)
  const customPages = yield select(getCustomPages)

  if (data && importType && ownerID) {
    const preparedData = prepareData(data, importType, ownerID)
    let validateRes
    // Validate data before import
    try {
      validateRes = yield call(validateData, preparedData, importType, customPages, cancelSource)
    } catch (error) {
      if (error.data && error.data.code === 400) {
        yield put({
          type: IMPORT_VALIDATION_FAIL,
          payload: {
            errors: error.data && error.data.message,
            message: i18n.t('import:validation_error'),
            data
          }
        })
        yield put({ type: IMPORT_DATA_FAIL, payload: { count: null, error: i18n.t('post_error') } })
      } else {
        yield put({
          type: IMPORT_VALIDATION_FAIL,
          payload: {
            errors: null,
            message: error.data || error.message || 'unknow error',
            data
          }
        })
        yield put({ type: IMPORT_DATA_FAIL, payload: { count: null, error: i18n.t('post_error') } })
      }
    }
    try {
      if (validateRes) {
        yield put({ type: UPDATE_STEP, payload: IMPORT_STEPS.UPLOAD })
        yield put({ type: IMPORT_VALIDATION_SUCCESS })
        // if (onValidateSuccess) yield call(onValidateSuccess)
        const chunks = chunk(data, IMPORT_CHUNK_SIZE)
        let count = 0

        for (const chunk of chunks) {
          count += 1
          const formattedChunk = prepareData(chunk, importType, ownerID)
          const importRes = yield call(importData, formattedChunk, importType, customPages, cancelSource)

          if (importRes && importRes.data && importRes.data.success) {
            if (count === chunks.length) {
              yield put({ type: IMPORT_DATA_SUCCESS, payload: { ids: importRes.data.ids, count } })
              yield put({ type: UPDATE_STEP, payload: IMPORT_STEPS.DONE })
            } else {
              yield put({ type: IMPORT_DATA_PARTIAL_SUCCESS, payload: { ids: importRes.data.ids, count, total: chunks.length } })
            }
          } else {
            if (count === chunks.length) {
              yield put({ type: IMPORT_DATA_FAIL, payload: { count, error: i18n.t('post_error') } })
              yield put({ type: UPDATE_STEP, payload: IMPORT_STEPS.DONE })
            } else {
              yield put({ type: IMPORT_DATA_PARTIAL_FAIL, payload: { chunk, count, total: chunks.length } })
            }
          }
        }
      }
    } catch (error) {
      yield put({ type: IMPORT_DATA_FAIL, payload: { count: null, error: i18n.t('post_error') } })
      yield put({ type: UPDATE_STEP, payload: IMPORT_STEPS.DONE })
    } finally {
      if (yield cancelled()) {
        console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
        yield call(cancelSource.cancel)
      }
    }
  }
}

export function * watchImport () {
  yield takeLatest(PARSE_CSV, parseCSVSaga)
  yield takeLatest(IMPORT_VALIDATION, validateImportSaga)
  yield takeLatest(IMPORT_DATA, importDataSaga)
}
