
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Result } from 'antd'
import { Redirect } from '@reach/router'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Card,
  Row
} from '@gk-devteam/apmc-core-web'

import {
  selectCurrentStep,
  selectImportError,
  selectImportedIds,
  selectImportPartialErrors,
  selectImportType
} from '../../../selectors'
import { IMPORT_STEPS, IMPORT_TYPES } from '../../../constants'
import { RESET_IMPORT } from '../../../types'

import {
  ImportAgainButtonStyle,
  ToListButtonStyle,
  WarningBlockStyle,
  FailedTitleStyle,
  ErrorRowStyle,
  ErrorTextStyle
} from './ImportsDonePage.style'

const ImportError = ({ error }) => {
  if (!error) return null
  return Object.values(error).map((value, i) => {
    return <ErrorTextStyle key={i} text={value} />
  })
}

const ImportsDonePage = ({ navigate }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const step = useSelector(selectCurrentStep)
  const error = useSelector(selectImportError)
  const partialErrors = useSelector(selectImportPartialErrors)
  const ids = useSelector(selectImportedIds)
  const importType = useSelector(selectImportType)

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_IMPORT })
    }
  }, [dispatch])

  if (step != null && step !== IMPORT_STEPS.DONE) return <Redirect to="/import" noThrow/>

  const _goToList = async () => {
    let link = './'
    switch (importType) {
      case IMPORT_TYPES.PROPERTIES:
        link = '/properties'
        break
      case IMPORT_TYPES.CONTRACTS:
        link = '/contracts'
        break
      case IMPORT_TYPES.FAQ:
        link = '/settings/faq'
        break

      default:
        break
    }
    await navigate(link)
    dispatch({ type: RESET_IMPORT })
  }

  const _goToImport = () => {
    dispatch({ type: RESET_IMPORT })
    navigate('/import')
  }

  let status, title, subTitle
  if (error) {
    status = 'error'
    title = t('import:import_complete_fail_message')
  } else if (partialErrors && partialErrors.length > 1) {
    status = 'warning'
    title = t('import:import_fail_message')
    subTitle = t('import:import_fail_subtitle', { count: ids && ids.length, errorCount: partialErrors && partialErrors.length })
  } else {
    status = 'success'
    title = t('import:import_success_message')
    subTitle = t('import:import_success_subtitle', { count: ids && ids.length })
  }

  const _renderWarningCard = () => {
    if (partialErrors && partialErrors.length > 1) {
      return (
        <Row mt="L" p="R">
          <WarningBlockStyle>
            <FailedTitleStyle label="import:import_failed_chunks_title" />
            { partialErrors && partialErrors.map((error, i) => <ErrorRowStyle key={i}><ImportError error={error} /></ErrorRowStyle>) }
          </WarningBlockStyle>
        </Row>
      )
    }
    return null
  }

  return (
    <Card mt="XL">
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={[
          <ImportAgainButtonStyle key="continue" label="import:continue_importing" handleClick={_goToImport} />,
          <ToListButtonStyle key="list" label="import:to_list_label" reverse handleClick={_goToList} />
        ]}
      />
      { _renderWarningCard() }
    </Card>
  )
}
export default ImportsDonePage

ImportError.propTypes = {
  error: PropTypes.object.isRequired
}

ImportsDonePage.propTypes = {
  navigate: PropTypes.func.isRequired
}
