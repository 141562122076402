import React, { memo } from 'react'
import {
  Image,
  Row,
  ToastTitle,
  Text
} from '@gk-devteam/apmc-core-web'

const HELP_PROPERTY_ID = require('../../../assets/img/csv_help_property_id.png')

const HelpContract = (props) => {
  return (
    <>
      <Row><ToastTitle label="import:help.about_property_id" /></Row>
      <Row mt="S" mb="ML"><Text label="import:help.about_property_id_text" /></Row>
      <Row>
        <Image src={HELP_PROPERTY_ID} />
      </Row>
    </>
  )
}

export default memo(HelpContract)

HelpContract.propTypes = {}
