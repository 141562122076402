import React, { memo, useCallback, useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  LEFT_INFO,
  useTranslation,
  SPACING,
  Tag,
  Text,
  FRONT_TIMESTAMP_FORMAT
} from '@gk-devteam/apmc-core-web'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import {
  selectSpacesReservationsLoading,
  selectSpacesReservationsCount,
  selectSpacesReservations
} from '../../../selectors'
import { SPACE_RESERVATION_STATUS, SPACE_TYPE } from '../../../constants'

import { TableStyle } from '../adminTasks.style'
import { UPDATE_SPACE_RESERVATION_STATUS } from '../../../types'

const ReservationsList = ({ refetch }) => {
  const dispatch = useDispatch()
  const rowRef = useRef(null)
  const { t } = useTranslation()
  const [tableMaxWidth, setTableMaxWidth] = useState(0)

  useEffect(() => {
    handleTableWidth()
    window.addEventListener('resize', handleTableWidth, false)
    return () => {
      window.removeEventListener('resize', handleTableWidth, false)
    }
  }, [])
  const handleTableWidth = () => {
    const row = rowRef.current
    if (row) {
      const pageWidth = document.documentElement.clientWidth
      const maxWidth = pageWidth - LEFT_INFO - (SPACING.LARGE * 14) - (3.5 * 2 * 14)
      setTableMaxWidth(maxWidth)
    }
  }

  const statusCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      let label, color
      switch (value) {
        case SPACE_RESERVATION_STATUS.UNCONFIRMED:
          label = 'spaces:reservation_statuses.unconfirmed'
          color = 'text'
          break
        case SPACE_RESERVATION_STATUS.CONFIRMED:
          label = 'spaces:reservation_statuses.confirmed'
          color = 'success'
          break
        case SPACE_RESERVATION_STATUS.CANCELED:
          label = 'spaces:reservation_statuses.canceled'
          color = 'warning'
          break
        case SPACE_RESERVATION_STATUS.REJECTED:
          label = 'spaces:reservation_statuses.rejected'
          color = 'danger'
          break
        default:
          break
      }

      return <Tag label={label} color={color} />
    },
    []
  )
  const durationCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const { reservation_from, reservation_to } = data

      return <Text text={`${dayjs(reservation_from).format(FRONT_TIMESTAMP_FORMAT)} - ${dayjs(reservation_to).format(FRONT_TIMESTAMP_FORMAT)}`} />
    },
    []
  )
  const typeCell = useCallback(
    (value, cellKey, rowID, className) => {
      let label, color
      switch (value) {
        case SPACE_TYPE.PARKING:
          label = 'spaces:types.parking'
          color = 'process'
          break
        case SPACE_TYPE.COMMUNITY_ROOM:
          label = 'spaces:types.community_room'
          color = 'danger'
          break
        case SPACE_TYPE.ELECTRIC_VEHICLE_PARKING:
          label = 'spaces:types.electric_vehicle_parking'
          color = 'accent'
          break
        case SPACE_TYPE.CAR_WASH:
          label = 'spaces:types.car_wash'
          color = 'success'
          break
        case SPACE_TYPE.GUEST_ROOM:
          label = 'spaces:types.guest_room'
          color = 'warning'
          break
        case SPACE_TYPE.SKY_DECK:
          label = 'spaces:types.sky_deck'
          color = 'info'
          break
        default:
          break
      }

      return <Tag label={label} color={color}/>
    },
    []
  )

  const _canUpdateStatus = useCallback(
    (data) => {
      return data.status === SPACE_RESERVATION_STATUS.UNCONFIRMED
    },
    []
  )
  const _canResetStatus = useCallback(
    (data) => {
      return [SPACE_RESERVATION_STATUS.CONFIRMED, SPACE_RESERVATION_STATUS.REJECTED].includes(data.status)
    },
    []
  )

  const columns = [
    {
      title: t('spaces:reservation_status'),
      dataIndex: 'status',
      key: 'status',
      render: statusCell
    },
    {
      title: t('app_users:app_user_name'),
      dataIndex: 'app_user_name',
      key: 'app_user_name',
      cellTitle: true
    },
    {
      title: t('properties:property_name'),
      dataIndex: 'property_name',
      key: 'property_name'
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number'
    },
    {
      title: t('spaces:name'),
      dataIndex: 'space_name',
      key: 'space_name'
    },
    {
      title: t('spaces:type'),
      dataIndex: 'space_type',
      key: 'space_type',
      render: typeCell
    },
    {
      title: t('spaces:reserved_duration'),
      dataIndex: 'reservation_from',
      key: 'reservation_from',
      render: durationCell
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('spaces:reservation_statuses.unconfirmed'),
          dangerColor: true,
          onClick: (reservation_id, close, row) => {
            dispatch({ type: UPDATE_SPACE_RESERVATION_STATUS, id: row.space_id, data: { reservation_id: 1, status: SPACE_RESERVATION_STATUS.UNCONFIRMED }, successRedirect: refetch }) // TODO: remove hard coded id
            close()
          },
          shouldRender: _canResetStatus
        },
        {
          label: t('spaces:reservation_statuses.confirmed'),
          onClick: (reservation_id, close, row) => {
            dispatch({ type: UPDATE_SPACE_RESERVATION_STATUS, id: row.space_id, data: { reservation_id: 1, status: SPACE_RESERVATION_STATUS.CONFIRMED }, successRedirect: refetch }) // TODO: remove hard coded id
            close()
          },
          shouldRender: _canUpdateStatus
        },
        {
          label: t('spaces:reservation_statuses.rejected'),
          dangerColor: true,
          onClick: (reservation_id, close, row) => {
            dispatch({ type: UPDATE_SPACE_RESERVATION_STATUS, id: row.space_id, data: { reservation_id: 1, status: SPACE_RESERVATION_STATUS.REJECTED }, successRedirect: refetch }) // TODO: remove hard coded id
            close()
          },
          shouldRender: _canUpdateStatus
        }
      ]
    }
  ]

  const _handleWaitingReservationRow = useCallback(
    (data) => {
      return data?.status === SPACE_RESERVATION_STATUS.UNCONFIRMED
    },
    []
  )

  return (
    <div ref={rowRef}>
      <TableStyle
        fullHeight
        tableMaxWidth={tableMaxWidth}
        columns={columns}
        rowKey="reservation_id"
        loadingSelector={selectSpacesReservationsLoading}
        resultsCountSelector={selectSpacesReservationsCount}
        dataSelector={selectSpacesReservations}
        dangerRow={_handleWaitingReservationRow}
        noHeader
        // leftStickyColumnCount={0}
        // rightStickyColumnCount={0}
      />
    </div>
  )
}

export default memo(ReservationsList)

ReservationsList.propTypes = {
  refetch: PropTypes.func.isRequired
}
