
// import { CancelToken } from 'axios'
// import { message } from 'antd'
// import { i18n } from '../locales'

import {
  SUBSCRIBE_CHECKLISTS,
  SUBSCRIBE_CHECKLISTS_SUCCESS
  // SUBSCRIBE_CHECKLISTS_FAIL,
} from '../types'
import { checkIfLoggedIn, db } from '../services/firebaseService'
import {
  CHECKLIST_COLLECTION_NAME, SUPPORT_STATUS
} from '../constants'

let unsubscribeChecklists

const getChecklists = (user, dispatch) => {
  if (user && user.uid) {
    // Remove listener if already exists
    if (unsubscribeChecklists) {
      unsubscribeChecklists()
    }
    const checklistsRef = db.collection(CHECKLIST_COLLECTION_NAME)
      .where(`members.${user.uid}`, '==', true)
      // .where('enabled', '==', true)
      .where('delete', '==', null)
      // .orderBy('status', 'desc')

    unsubscribeChecklists = checklistsRef.onSnapshot(querySnapshot => {
      const checklists = []
      querySnapshot.forEach(function (checklist) {
        const data = checklist.data()
        data.id = checklist.id
        checklists.push(data)
      })
      // Get all the unresolved checklists
      const count = checklists.filter(checklist => checklist.status === SUPPORT_STATUS.WAITING)
      const statuses = checklists.map(checklist => checklist.status)

      // Sort by status
      checklists.sort((a, b) => {
        return b.status - a.status
      })
      dispatch({ type: SUBSCRIBE_CHECKLISTS_SUCCESS, payload: { checklists, count: count && count.length, statuses } })
    })
  }
}

export const subscribeToChecklists = () => (dispatch) => {
  dispatch({ type: SUBSCRIBE_CHECKLISTS })
  checkIfLoggedIn((user) => getChecklists(user, dispatch))
}

export const unSubscribeFromChecklists = () => {
  try {
    if (unsubscribeChecklists) unsubscribeChecklists()
  } catch (error) {
    // Handle Errors here.
    // var errorCode = error.code
    // var errorMessage = error.message
    console.warn(error)
  }
}
