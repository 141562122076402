import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_INQUIRIES,
  FETCH_INQUIRIES_SUCCESS,
  FETCH_INQUIRIES_FAIL,
  TOGGLE_INQUIRY_MODAL,
  SET_ACTIVE_INQUIRY,
  RESET_INQUIRIES
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  max_pages: null,
  results_count: null,
  inquiryModal: false,
  activeInquiryID: null,
  results: []
}

export default createReducer(INITIAL_STATE, {
  [FETCH_INQUIRIES]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_INQUIRIES_SUCCESS]: (state, action) => {
    const { max_pages, results_count, results } = action.payload
    state.loading = false
    state.max_pages = max_pages
    state.results_count = results_count
    state.results = results
  },
  [FETCH_INQUIRIES_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [TOGGLE_INQUIRY_MODAL]: (state, action) => {
    state.inquiryModal = !state.inquiryModal
  },
  [SET_ACTIVE_INQUIRY]: (state, action) => {
    state.activeInquiryID = action.payload
  },
  [RESET_INQUIRIES]: (state, action) => {
    state.loading = null
    state.error = null
    state.max_pages = null
    state.results_count = null
    state.results = null
  }
})
