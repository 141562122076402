import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const appUserDownloadLoginValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    date: yup.date().nullable().required().label(i18n.t('app_users:download_pdf_files.created_date')),
    type: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .min(1).max(4)
      .integer().nullable().label(i18n.t('app_users:download_pdf_files.type')),
    ids: yup
      .array()
      .of(
        yup.number()
          .transform((cv, ov) => ov === '' ? undefined : cv)
          .integer().nullable().label(i18n.t('app_users:download_pdf_files.ids'))
      )
      .nullable()
      .label('common:id'),
    file: yup.string().nullable().isBlob().required().label(i18n.t('common:file'))
  })
}
