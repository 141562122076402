import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import { parse } from 'query-string'
import PropTypes from 'prop-types'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Redirect } from '@reach/router'
import {
  useTranslation,
  SPACING,
  updateParam,
  Row,
  Card,
  Text,
  SectionTitle,
  EmptyData,
  PrimaryButtonLinkStyle,
  SelectStandAlone
} from '@gk-devteam/apmc-core-web'

import { PARSE_CSV, UPDATE_IMPORT_TYPE } from '../../../types/import/importTypes'
import { selectParsingData, selectImportType, selectCurrentStep, selectPages } from '../../../selectors'
import {
  IMPORT_TYPES_OPTIONS,
  IMPORT_TYPES,
  IMPORT_STEPS
} from '../../../constants'

import csvPropertiesSample from '../../../assets/files/csv_properties_sample.csv'
import csvContractsSample from '../../../assets/files/csv_contracts_sample.csv'
import casaCsvContractsSample from '../../../assets/files/casa_csv_contracts_sample.csv'
import yasueCsvContractsSample from '../../../assets/files/yasue_csv_contracts_sample.csv'
import csvFaqSample from '../../../assets/files/csv_faq_sample.csv'
import yasueConstructionWorkSample from '../../../assets/files/yasue_construction_work_sample.csv'

import HelpModal from './HelpModal'

import {
  ImportTypeLabelStyle,
  HelpIconContainerStyle
} from './ImportsPage.style'

const ImportsPage = ({ navigate, location }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const inputRef = useRef()
  const importType = useSelector(selectImportType)
  const parsing = useSelector(selectParsingData)
  const step = useSelector(selectCurrentStep)
  const pages = useSelector(selectPages)
  const [importTypeOptions, setImportTypeOptions] = useState(null)
  const [sampleLink, setSampleLink] = useState('')
  const [fileTitle, setFileTitle] = useState('')
  const [helpModal, setHelpModal] = useState(false)

  useEffect(() => {
    if (pages) {
      const options = []
      const { imports, custom } = pages
      const { contracts, properties, faq } = imports.functions
      if (properties && !custom.yasue) {
        options.push(IMPORT_TYPES_OPTIONS.find(option => option.value === IMPORT_TYPES.PROPERTIES))
      }
      if (contracts) {
        options.push(IMPORT_TYPES_OPTIONS.find(option => option.value === IMPORT_TYPES.CONTRACTS))
      }
      if (faq) {
        options.push(IMPORT_TYPES_OPTIONS.find(option => option.value === IMPORT_TYPES.FAQ))
      }
      if (pages.custom?.yasue) {
        options.push(IMPORT_TYPES_OPTIONS.find(option => option.value === IMPORT_TYPES.YASUE_CONTRUCTION_WORK))
      }
      setImportTypeOptions(options)
    }
  }, [pages])

  useEffect(() => {
    const qs = parse(location.search)
    if (step === IMPORT_STEPS.SELECT_FILE) {
      if (qs && qs.type && pages) {
        const type = Number(qs.type)
        const isAuthorized = () => {
          const { imports } = pages
          const { contracts, properties, faq } = imports.functions
          const authorizedTypes = []
          if (properties) {
            authorizedTypes.push(IMPORT_TYPES.PROPERTIES)
          }
          if (contracts) {
            authorizedTypes.push(IMPORT_TYPES.CONTRACTS)
          }
          if (faq) {
            authorizedTypes.push(IMPORT_TYPES.FAQ)
          }
          if (pages?.custom?.yasue) {
            authorizedTypes.push(IMPORT_TYPES.YASUE_CONTRUCTION_WORK)
          }
          return authorizedTypes.includes(type)
        }
        if (isAuthorized()) {
          dispatch({ type: UPDATE_IMPORT_TYPE, payload: type })
        }
      } else {
        dispatch({ type: UPDATE_IMPORT_TYPE, payload: null })
      }
    }
  }, [location, dispatch, step, pages])

  useEffect(() => {
    if (importType) {
      switch (importType) {
        case IMPORT_TYPES.PROPERTIES:
          setSampleLink(csvPropertiesSample)
          setFileTitle('import:file_import_title.property')
          break
        case IMPORT_TYPES.CONTRACTS:
          if (pages.custom.casa) {
            setSampleLink(casaCsvContractsSample)
          } else if (pages?.custom?.yasue) {
            setSampleLink(yasueCsvContractsSample)
          } else {
            setSampleLink(csvContractsSample)
          }
          setFileTitle('import:file_import_title.contract')
          break
        case IMPORT_TYPES.FAQ:
          setSampleLink(csvFaqSample)
          setFileTitle('import:file_import_title.faq')
          break
        case IMPORT_TYPES.YASUE_CONTRUCTION_WORK:
          if (pages.custom.yasue) {
            setSampleLink(yasueConstructionWorkSample)
            setFileTitle('import:file_import_title.yasue_construction_work')
          }
          break
        default:
          break
      }
    }
  }, [importType, pages])

  const _handleTypeChange = (value) => {
    dispatch({ type: UPDATE_IMPORT_TYPE, payload: value })
    updateParam('type', value)
  }

  const _resetFile = (e) => {
    e.target.value = null
  }

  const _handleAddFile = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  const _handleFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      dispatch({
        type: PARSE_CSV,
        file: e.target.files[0],
        onSuccess: async () => {
          await navigate('./confirmation')
        }
      })
    }
  }

  const _openModal = () => {
    setHelpModal(true)
  }
  const _closeModal = () => {
    setHelpModal(false)
  }

  const _renderHelpButton = () => {
    if (!importType) return null
    if (importType === IMPORT_TYPES.CONTRACTS && pages?.custom?.yasue) return null
    return (
      <Tooltip
        title={t('import:import_help')}
        overlayStyle={{
          fontSize: `${SPACING.SMALL}rem`
        }}
        placement="rightBottom"
      >
        <HelpIconContainerStyle onClick={_openModal}>
          <QuestionCircleOutlined />
        </HelpIconContainerStyle>
      </Tooltip>
    )
  }

  if (step && step === IMPORT_STEPS.UPLOAD) return <Redirect to="/import/upload" noThrow/>
  if (step && step === IMPORT_STEPS.DONE) return <Redirect to="/import/done" noThrow/>

  return (
    <>
      <HelpModal
        visible={helpModal}
        closeModal={_closeModal}
      />
      <Row mt="L">
        <Text label="import:download_sample_explanation" />
      </Row>
      <Row mt="L">
        <ImportTypeLabelStyle label="import:import_type" />
      </Row>
      <Row mt="XS">
        <SelectStandAlone
          noLabel
          size="XL"
          mb="0"
          mr="0"
          handleChange={_handleTypeChange}
          value={importType}
          name="select_import_type"
          options={importTypeOptions}
          placeholder="import:import_type_placeholder"
          disabled={parsing}
        />
        { _renderHelpButton() }
      </Row>
      <Row mt="ML">
        <PrimaryButtonLinkStyle
          href={sampleLink}
          disabled={!sampleLink}
        >
          {t('import:download_sample_label')}
        </PrimaryButtonLinkStyle>
      </Row>
      {
        importType
          ? (
            <>
              <Row mt="L">
                <SectionTitle label={fileTitle} />
              </Row>
              <Card mt="R">
                <input
                  ref={inputRef}
                  type="file"
                  onChange={_handleFileChange}
                  onClick = {_resetFile}
                  hidden
                />
                <EmptyData
                  handleClick={_handleAddFile}
                  // buttonLabel=""
                  description="import:select_csv"
                  loading={parsing}
                />
              </Card>
            </>
          )
          : null
      }
    </>
  )
}
export default ImportsPage

ImportsPage.propTypes = {
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}
ImportsPage.defaultProps = {

}
