import { createSelector } from 'reselect'

const selectSpaces = state => state.spaces

export const selectSpacesLoading = createSelector(
  selectSpaces,
  spaces => spaces.loading
)
export const selectSpacesCount = createSelector(
  selectSpaces,
  spaces => spaces.results_count
)
export const selectSpacesData = createSelector(
  selectSpaces,
  spaces => spaces.results
)

export const selectSpacesReservationsLoading = createSelector(
  selectSpaces,
  spaces => spaces?.reservations_loading
)
export const selectSpacesReservationsError = createSelector(
  selectSpaces,
  spaces => spaces?.reservations_error
)
export const selectSpacesReservations = createSelector(
  selectSpaces,
  spaces => spaces.reservations
)
export const selectSpacesReservationsCount = createSelector(
  selectSpaces,
  spaces => spaces.reservations_count
)

export const selectSpacesWaitingReservationsLoading = createSelector(
  selectSpaces,
  spaces => spaces.waiting_reservations_loading
)
export const selectSpacesWaitingReservations = createSelector(
  selectSpaces,
  spaces => spaces.waiting_reservations
)
export const selectSpacesWaitingReservationsCount = createSelector(
  selectSpaces,
  spaces => spaces.waiting_reservations_count
)
