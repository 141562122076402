import * as AuthenticationSaga from './auth/authenticationSaga'
import * as AreasSaga from './areasSaga'
import * as DashboardSaga from './dashboard/dashboardsaga'
import * as PropertiesSaga from './properties/propertiesSaga'
import * as PropertySaga from './properties/propertySaga'
import * as PropertyDocumentsSaga from './properties/propertyDocumentsSaga'
import * as PropertyEventsSaga from './properties/propertyEventsSaga'
import * as RoomsSaga from './rooms/roomsSaga'
import * as RoomSaga from './rooms/roomSaga'
import * as ContractsSaga from './contracts/contractsSaga'
import * as ContractSaga from './contracts/contractSaga'
import * as ContractsProcessingSaga from './contracts/contractsProcessingSaga'
import * as ContractDocumentsSaga from './contracts/contractDocumentsSaga'
import * as ContractOpeloSaga from './contracts/contractOpeloSaga'
import * as AppUsersSaga from './app_users/appUsersSaga'
import * as AppUserSaga from './app_users/appUserSaga'
import * as NoticesSaga from './notices/noticesSaga'
import * as NoticeSaga from './notices/noticeSaga'
import * as AdminUserSaga from './settings/adminUserSaga'
import * as SettingAdminProfileSaga from './settings/settingAdminProfileSaga'
import * as UserPreferencesSaga from './settings/userPreferencesSaga'
import * as ServicesSaga from './app_services/servicesSaga'
import * as ServiceSaga from './app_services/serviceSaga'
import * as FaqSaga from './panels/faqSaga'
import * as ManualsSaga from './panels/manualsSaga'
import * as VicinitiesSaga from './panels/vicinitiesSaga'
import * as PublicFacilitiesSaga from './panels/publicFacilitiesSaga'
import * as GuideCategoriesSaga from './panels/guideCategoriesSaga'
import * as GuidesSaga from './panels/guidesSaga'
import * as ContactsSaga from './panels/contactsSaga'
import * as RefuseSaga from './panels/refuseSaga'
import * as ChatSaga from './chat/chatSaga'
import * as ChecklistSaga from './checklist/checklistSaga'
import * as ImportSaga from './import/importSaga'
import * as MembersSaga from './user_management/membersSaga'
import * as MemberSaga from './user_management/memberSaga'
import * as GuestsSaga from './user_management/guestsSaga'
import * as GuestSaga from './user_management/guestSaga'
import * as PropertiesGroupsSaga from './user_management/propertiesGroupsSaga'
import * as PropertiesGroupSaga from './user_management/propertiesGroupSaga'
import * as InuiSaga from './inui/inuiSaga'
import * as CouponsSaga from './coupons/couponsSaga'
import * as CouponSaga from './coupons/couponSaga'
import * as DatasyncSaga from './datasync/datasyncSaga'
import * as RefundsSaga from './refunds/refundsSaga'
import * as RefundSaga from './refunds/refundSaga'
import * as InspectionSaga from './house_maker/inspectionsSaga'
import * as InquiriesSaga from './inquiries/inquiriesSaga'
import * as SurveysSaga from './surveys/surveysSaga'
import * as SurveySaga from './surveys/surveySaga'
import * as SpacesSaga from './spaces/spacesSaga'
import * as SpaceSaga from './spaces/spaceSaga'
import * as SupportSaga from './support/supportSaga'
import * as ChoueiSaga from './chouei/choueiSaga'
import * as ConstructionWorksSaga from './yasue/constructionWorksSaga'
import * as ConstructionWorkSaga from './yasue/constructionWorkSaga'
import * as ConstructionWorkFileSaga from './yasue/constructionWorkFileSaga'
import * as YasueSurveysSaga from './yasue/yasueSurveysSaga'
import * as YasueSurveySaga from './yasue/yasueSurveySaga'
import * as YasueContactsSaga from './yasue/contactsSaga'
import * as YasueMaintenanceSaga from './yasue/maintenanceSaga'

export const sagas = [
  AuthenticationSaga.watchLoginUser(),
  AreasSaga.watchAreas(),
  DashboardSaga.watchDashboard(),
  PropertiesSaga.watchProperties(),
  PropertySaga.watchProperty(),
  PropertyDocumentsSaga.watchPropertyDocuments(),
  PropertyEventsSaga.watchPropertyEvents(),
  RoomsSaga.watchRooms(),
  RoomSaga.watchRoom(),
  ContractsSaga.watchContracts(),
  ContractSaga.watchContract(),
  ContractsProcessingSaga.watchContractsProcessing(),
  ContractDocumentsSaga.watchContractDocuments(),
  ContractOpeloSaga.watchContractOpelo(),
  AppUsersSaga.watchAppUsers(),
  AppUserSaga.watchAppUser(),
  AdminUserSaga.watchAdminUser(),
  NoticesSaga.watchNotices(),
  NoticeSaga.watchNotice(),
  SettingAdminProfileSaga.watchAdminProfile(),
  UserPreferencesSaga.watchUserPreferences(),
  FaqSaga.watchFaq(),
  ManualsSaga.watchManuals(),
  VicinitiesSaga.watchVicinities(),
  PublicFacilitiesSaga.watchPublicFacilities(),
  GuideCategoriesSaga.watchGuideCategories(),
  GuidesSaga.watchGuides(),
  ServicesSaga.watchServices(),
  ServiceSaga.watchService(),
  ContactsSaga.watchContacts(),
  RefuseSaga.watchRefuse(),
  ChatSaga.watchChat(),
  ChecklistSaga.watchChecklist(),
  ImportSaga.watchImport(),
  MembersSaga.watchMembers(),
  MemberSaga.watchMember(),
  GuestsSaga.watchGuests(),
  GuestSaga.watchGuest(),
  PropertiesGroupsSaga.watchPropertiesGroups(),
  PropertiesGroupSaga.watchPropertiesGroup(),
  InuiSaga.watchInui(),
  CouponsSaga.watchCoupons(),
  CouponSaga.watchCoupon(),
  DatasyncSaga.watchDatasync(),
  RefundsSaga.watchRefunds(),
  RefundSaga.watchRefund(),
  InspectionSaga.watchInspections(),
  InquiriesSaga.watchInquiries(),
  SurveysSaga.watchSurveys(),
  SurveySaga.watchSurvey(),
  SpacesSaga.watchSpaces(),
  SpaceSaga.watchSpace(),
  SupportSaga.watchSupport(),
  ChoueiSaga.watchChouei(),
  ConstructionWorksSaga.watchContractioWorks(),
  ConstructionWorkSaga.watchConstructionWork(),
  ConstructionWorkFileSaga.watchConstructionWorkFile(),
  YasueSurveysSaga.watchYasueSurveys(),
  YasueSurveySaga.watchYasueSurvey(),
  YasueContactsSaga.watchYasueContacts(),
  YasueMaintenanceSaga.watchYasueMaintenance()
]

export default sagas
