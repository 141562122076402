/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const checklistSettingsSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    checklist_duration: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .min(7)
      .integer()
      .nullable()
      .label(i18n.t('admin_tasks:checklist_duration'))
  })
}
