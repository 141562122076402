import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const spacesReservationsSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    property_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('properties:property_id')),
    property_name: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('properties:property_id')),
    space_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer()
      .nullable()
      .label(i18n.t('id')),
    space_type: yup
      .number()
      .oneOf([1, 2, 3, 4, 5, 6, 7])
      .nullable()
      .label(i18n.t('spaces:type')),
    from_day: yup
      .string()
      .nullable()
      .label(i18n.t('spaces:reserved_duration')),
    to_day: yup
      .string()
      .nullable()
      .label(i18n.t('spaces:reserved_duration')),
    status: yup
      .number()
      .oneOf([1, 2, 3, 4])
      .nullable()
      .label(i18n.t('spaces:reservation_status')),
    csv: yup
      .bool()
      .nullable()
      .label(i18n.t('csv'))
  })
}
