import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_FCM_STATUS,
  FETCH_FCM_STATUS_SUCCESS,
  FETCH_FCM_STATUS_FAIL,
  // POST_FCM_TOKEN,
  // POST_FCM_TOKEN_SUCCESS,
  // POST_FCM_TOKEN_FAIL,
  // DELETE_FCM_TOKEN,
  // DELETE_FCM_TOKEN_SUCCESS,
  // DELETE_FCM_TOKEN_FAIL,
  RESET_FCM_STATUS,
  FETCH_THEME,
  FETCH_THEME_SUCCESS,
  FETCH_THEME_FAIL,
  RESET_THEME,
  POST_THEME,
  POST_THEME_SUCCESS,
  POST_THEME_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: null,
  posting: null,
  webpush: undefined,
  theme: null,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_FCM_STATUS]: (state, action) => {
    state.loading = true
  },
  [FETCH_FCM_STATUS_SUCCESS]: (state, action) => {
    state.loading = false
    state.webpush = action.payload
  },
  [FETCH_FCM_STATUS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_FCM_STATUS]: (state) => {
    state.webpush = undefined
  },
  [FETCH_THEME]: (state, action) => {
    state.loading = true
  },
  [FETCH_THEME_SUCCESS]: (state, action) => {
    state.loading = false
    state.theme = action.payload
  },
  [FETCH_THEME_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [RESET_THEME]: (state) => {
    state.theme = null
  },
  [POST_THEME]: (state) => {
    state.posting = true
  },
  [POST_THEME_SUCCESS]: (state) => {
    state.posting = false
  },
  [POST_THEME_FAIL]: (state) => {
    state.posting = false
  }
})
