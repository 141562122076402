import { createSelector } from 'reselect'

const selectAppUser = state => state.appUser
const selectChat = state => state.chat

export const selectLoadingAppUser = createSelector(
  selectAppUser,
  appUser => appUser.loading
)
export const selectPostingAppUser = createSelector(
  selectAppUser,
  appUser => appUser.posting
)
export const selectAppUserError = createSelector(
  selectAppUser,
  appUser => appUser.error
)
export const selectAppUserFormData = createSelector(
  selectAppUser,
  appUser => {
    const data = { ...appUser.data }
    delete data.residencies_history
    delete data.contracts_history

    return data
  }
)

export const selectResidenceHistoryCount = createSelector(
  selectAppUser,
  appUser => appUser.data && appUser.data.residencies_history.length
)
export const selectResidenceHistoryData = createSelector(
  selectAppUser,
  appUser => appUser.data && appUser.data.residencies_history
)
export const selectContractHistoryCount = createSelector(
  selectAppUser,
  appUser => appUser.data && appUser.data.contracts_history.length
)
export const selectContractHistoryData = createSelector(
  selectAppUser,
  appUser => appUser.data && appUser.data.contracts_history
)
export const selectConstructionWorkHistoryData = createSelector(
  selectAppUser,
  appUser => appUser.data && appUser.data.construction_works_history
)

export const selectChatHistoryData = createSelector(
  [selectAppUser, selectChat],
  (appUser, chat) => {
    const userID = appUser && appUser.data && appUser.data.id
    const rooms = chat && chat.rooms
    if (userID && rooms) {
      return rooms.filter(room => room.userID === userID)
    }
  }
)
