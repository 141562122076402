import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  useTranslation,
  Link,
  CaretIcon,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  UserRow,
  UserCell,
  DropDownCell,
  UserExtraRow
} from './NoticesPage.style'

const NoticeStatusUserRow = ({ user }) => {
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(true)
  if (!user) return null
  const {
    app_user_id,
    app_user_name,
    contracts,
    read,
    read_time
  } = user

  const _handleRowCollapse = () => {
    if (contracts.length > 1) {
      setCollapsed(!collapsed)
    }
  }

  const _renderExtraRows = () => {
    if (contracts.length > 1 && !collapsed) {
      const extraRows = [...contracts]
      extraRows.shift()
      return extraRows.map((row, i) => {
        return (
          <UserExtraRow key={i} collapsed={collapsed}>
            <UserCell className="cell"></UserCell>
            <UserCell className="cell">
              <div>
                <Link to={`/properties/edit/${row.property_id}`}>
                  { row.property_name}
                </Link>
              </div>
            </UserCell>
            <RoleControlled authorized='!custom.house_maker'>
              <UserCell className="cell">
                <div>
                  <Link to={`/properties/edit/${row.property_id}/rooms/edit/${row.room_id}`}>
                    { row.room_number}
                  </Link>
                </div>
              </UserCell>
            </RoleControlled>
            <UserCell className="cell"></UserCell>
            <UserCell className="cell"></UserCell>
            <UserCell className="cell"></UserCell>
          </UserExtraRow>
        )
      })
    }
    return null
  }

  return (
    <>
      <UserRow>
        <UserCell className="cell">
          <Link to={`/app_users/edit/${app_user_id}`}>{app_user_name}</Link>
        </UserCell>
        <UserCell className="cell">
          <Link to={`/properties/edit/${contracts[0].property_id}`}>
            { contracts && contracts[0] && contracts[0].property_name}
          </Link>
        </UserCell>
        <RoleControlled authorized='!custom.house_maker'>
          <UserCell className="cell">
            <Link to={`/properties/edit/${contracts[0].property_id}/rooms/edit/${contracts[0].room_id}`}>
              { contracts && contracts[0] && contracts[0].room_number}
            </Link>
          </UserCell>
        </RoleControlled>
        <UserCell className="cell">{ read ? t('notices:read') : t('notices:unread')}</UserCell>
        <UserCell className="cell">{ read_time }</UserCell>
        <DropDownCell className="cell" onClick={_handleRowCollapse} collapsed={collapsed}>
          { contracts.length > 1
            ? <CaretIcon />
            : null
          }
        </DropDownCell>
      </UserRow>
      { _renderExtraRows() }
    </>
  )
}

export default memo(NoticeStatusUserRow)

NoticeStatusUserRow.propTypes = {
  user: PropTypes.shape({
    app_user_id: PropTypes.number.isRequired,
    app_user_name: PropTypes.string.isRequired,
    contracts: PropTypes.arrayOf(PropTypes.shape({
      property_id: PropTypes.number.isRequired,
      property_name: PropTypes.string.isRequired,
      room_id: PropTypes.number.isRequired,
      room_number: PropTypes.string.isRequired
    })).isRequired,
    read: PropTypes.bool.isRequired,
    read_time: PropTypes.string.isRequired
  }).isRequired
}
