import { api } from '@gk-devteam/apmc-core-web'

export const fetchYasueMaintenanceContacts = (cancelSource) => {
  return api.get('/yasue/maintenance/contacts', {
    cancelToken: cancelSource.token
  })
}

export const postYasueMaintenanceContacts = (data, cancelSource) => {
  return api.post('/yasue/maintenance/contacts', data, {
    cancelToken: cancelSource.token
  })
}

export const fetchYasueMaintenanceShowrooms = (cancelSource) => {
  return api.get('/yasue/maintenance/showrooms', {
    cancelToken: cancelSource.token
  })
}

export const postYasueMaintenanceShowrooms = (data, cancelSource) => {
  return api.post('/yasue/maintenance/showrooms', data, {
    cancelToken: cancelSource.token
  })
}
