import React, { memo } from 'react'
import {
  DatePicker
} from '@gk-devteam/apmc-core-web'

const CouponLimitDate = () => {
  return (
    <DatePicker
      flex={1}
      size="M"
      name="limit_date"
      label='coupons:limit_date'
      placeholder=""
      showTime
    />
  )
}

export default memo(CouponLimitDate)

// NoticeStopTime.propTypes = {}
