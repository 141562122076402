import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const yasueConstructionWorkFileUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    filename: yup.string().max(255).required().label(i18n.t('yasue:construction.filename')),
    file: yup.string().nullable().isBlob().required().label(i18n.t('common:file'))
  })
}
