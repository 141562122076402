import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const appUserUpdateValidation = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    last_name: yup.string().max(255).required().label(i18n.t('app_users:last_name')),
    first_name: yup.string().max(255).label(i18n.t('app_users:last_name')),
    furigana_last_name: yup.string().max(255).label(i18n.t('app_users:furigana_last_name')),
    furigana_first_name: yup.string().max(255).label(i18n.t('app_users:furigana_first_name')),
    app_user_manage_id: yup.string().max(255).nullable().label(i18n.t('app_users:app_user_manage_id')),
    mail_address: yup.string().email().nullable().label(i18n.t('common:email')),
    phone: yup.string().max(255).nullable().label(i18n.t('common:phone')),
    emergency_phone: yup.string().max(255).nullable().label(i18n.t('app_users:emergency_phone')),
    occupation: yup.string().nullable().max(255).label(i18n.t('app_users:occupation')),
    annual_income: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('app_users:annual_income')),
    birthday: yup.date().nullable().label(i18n.t('app_users:birthday')),
    sex: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .min(0).max(3)
      .integer().nullable().label(i18n.t('app_users:sex')),
    nationality: yup.string().max(255).label(i18n.t('app_users:nationality')),
    residence_card: yup.string().max(255).label(i18n.t('app_users:residence_card')),
    home_country_emergency_contact: yup.string().max(255).label(i18n.t('app_users:home_country_emergency_contact'))
  })
}
