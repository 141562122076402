import React from 'react'
import PropTypes from 'prop-types'
import { InfoCircleOutlined } from '@ant-design/icons'
import {
  Text,
  ToastTitle
} from '@gk-devteam/apmc-core-web'

import { InfoCardStyle } from './PanelInfo.style'

const PanelInfo = ({ info, infoTitle }) => {
  if (!info) return null
  return (
    <InfoCardStyle mt="ML" align="center">
      <InfoCircleOutlined />
      <div>
        <ToastTitle label={infoTitle} />
        <Text label={info} />
      </div>
    </InfoCardStyle>
  )
}

PanelInfo.propTypes = {
  info: PropTypes.string,
  infoTitle: PropTypes.string
}

export default PanelInfo
