import { createSelector } from 'reselect'

const selectInquiries = state => state.inquiries

export const selectInquiriesLoading = createSelector(
  selectInquiries,
  inquiries => inquiries.loading
)
export const selectInquiriesCount = createSelector(
  selectInquiries,
  inquiries => inquiries.results_count
)
export const selectInquiriesData = createSelector(
  selectInquiries,
  inquiries => inquiries.results
)
export const selectInquiryVisible = createSelector(
  selectInquiries,
  inquiries => inquiries.inquiryModal
)
export const selectActiveInquiry = createSelector(
  selectInquiries,
  inquiries => {
    return inquiries?.inquiryModal && inquiries?.activeInquiryID
      ? inquiries?.results.find(result => result.id === inquiries.activeInquiryID)
      : null
  }
)
