import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  CardTitle
} from '@gk-devteam/apmc-core-web'

import PanelDisabled from '../../../components/Display/Panels/PanelDisabled'

import {
  CardStyle,
  TableStyle
} from './AdminTasksPage.style'
import {
  UnresolvedLabelStyle,
  UnresolvedCountStyle,
  UnresolvedCountUnitStyle
} from '../adminTasks.style'

const AdminTaskCard = ({
  title,
  linkTo,
  linkLabel,
  unresolvedCountLabel,
  unresolvedListLabel,
  count,
  columns,
  loadingSelector,
  dataSelector,
  hiddenSelector,
  hiddenByIdSelector,
  handleRowClick,
  rowKey
}) => {
  return (
    <CardStyle
      title={title}
      linkTo={linkTo}
      linkLabel={linkLabel}
      mt="L"
    >
      <PanelDisabled
        hiddenSelector={hiddenSelector}
        hiddenByIdSelector={hiddenByIdSelector}
        noLegend
      >
        <Row>
          <UnresolvedLabelStyle label={unresolvedCountLabel || 'admin_tasks:unresolved_count'} />
        </Row>
        <Row mt="R" align="end" pl="S">
          <UnresolvedCountStyle text={count} />
          <UnresolvedCountUnitStyle label="result_unit" />
        </Row>
        <Row mt="R" mb="S">
          <CardTitle label={unresolvedListLabel || 'admin_tasks:unresolved_list'} />
        </Row>

        <TableStyle
          fullHeight
          columns={columns}
          rowKey={rowKey}
          loadingSelector={loadingSelector}
          dataSelector={dataSelector}
          onRowClick={handleRowClick}
          listMode
        />
      </PanelDisabled>
    </CardStyle>
  )
}

export default AdminTaskCard

AdminTaskCard.propTypes = {
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  unresolvedCountLabel: PropTypes.string,
  unresolvedListLabel: PropTypes.string,
  count: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  loadingSelector: PropTypes.func.isRequired,
  dataSelector: PropTypes.func.isRequired,
  hiddenSelector: PropTypes.func,
  hiddenByIdSelector: PropTypes.func,
  handleRowClick: PropTypes.func.isRequired,
  rowKey: PropTypes.string.isRequired
}
