import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'

import {
  PageLayout,
  Row,
  Title,
  // DownloadCSVBotton,
  Table,
  useTranslation,
  selectQueryParams,
  RESET_PARAMS,
  dynamicLink
} from '@gk-devteam/apmc-core-web'
import YasueSurveysSearchBar from './YasueSurveysSearchBar'
import { selectLoadingYasueSurveys, selectYasueSurveysCount, selectYasueSurveysData } from '../../../selectors'
import { FETCH_YASUE_SURVEYS, RESET_YASUE_SURVEYS } from '../../../types'
// import { fetchYasueSurveys } from '../../../services'
// import { yasueSurveysSearchSchema } from '../../../validation'

const YasueSurveysPage = ({ navigate, location }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'yasue', 'contracts'])
  const query = useSelector(selectQueryParams)
  const prevQuery = useRef(null)

  const fetchData = useCallback(
    () => {
      dispatch({ type: FETCH_YASUE_SURVEYS, query })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_YASUE_SURVEYS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const navigateToDetails = (id, row, e) => {
    const url = `/yasue/survey/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  const breadcrumbs = [
    {
      label: 'navigation:yasue.survey_list',
      link: '/yasue/survey'
    }
  ]

  const columns = [
    {
      title: t('contracts:contractor_name'),
      dataIndex: 'contractor_name',
      key: 'contractor_name',
      cellTitle: true,
      sortable: false
    },
    {
      title: t('yasue:construction.work_number'),
      dataIndex: 'work_number',
      key: 'work_number'
    },
    {
      title: t('yasue:survey.created'),
      dataIndex: 'created',
      key: 'created'
    }
  ]

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      sidebar={
        () => <YasueSurveysSearchBar />
      }
    >
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:yasue.survey_list"/>
        <div>
          {/* <DownloadCSVBotton
            schemaBuilder={yasueSurveysSearchSchema}
            fetchCSV={fetchYasueSurveys}
            filename="surveys.csv"
          /> */}
        </div>
      </Row>
      <Table
        fullHeight
        columns={columns}
        rowKey="id"
        loadingSelector={selectLoadingYasueSurveys}
        resultsCountSelector={selectYasueSurveysCount}
        dataSelector={selectYasueSurveysData}
        onRowClick={navigateToDetails}
        rightStickyColumnCount={0}
      />
    </PageLayout>
  )
}

export default YasueSurveysPage

YasueSurveysPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
