import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  UPDATE_FORM_ARRAY,
  Radio
} from '@gk-devteam/apmc-core-web'

import {
  COUPON_RECIPIENT_TYPE_OPTIONS
} from '../../../../constants'
import { selectCouponEditable } from '../../../../selectors'

const CouponRecipientType = () => {
  const recipientType = useSelector(state => state.form.recipient_type)
  const editable = useSelector(selectCouponEditable)
  const dispatch = useDispatch()
  const prevTypeValue = useRef(null)

  useEffect(() => {
    if (prevTypeValue.current == null) {
      prevTypeValue.current = recipientType
    } else {
      if (prevTypeValue.current !== recipientType) {
        prevTypeValue.current = recipientType
        if (recipientType === 2) {
          dispatch({
            type: UPDATE_FORM_ARRAY,
            payload:
            [
              {
                name: 'recipients_id',
                values: null
              },
              {
                name: 'recipients_list',
                values: null
              }
            ]
          })
        } else {
          dispatch({
            type: UPDATE_FORM_ARRAY,
            payload: [
              {
                name: 'recipients_id',
                values: null
              },
              {
                name: 'recipients_list',
                values: null
              }
            ]
          })
        }
      }
    }
  }, [recipientType, dispatch, prevTypeValue])

  return (
    <Radio
      name="recipient_type"
      options={COUPON_RECIPIENT_TYPE_OPTIONS}
      readonly={!editable}
    />
  )
}

export default CouponRecipientType

CouponRecipientType.propTypes = {
  editable: PropTypes.bool
}
