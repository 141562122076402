import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  MainLayout
} from '@gk-devteam/apmc-core-web'
import PropTypes from 'prop-types'

import {
  selectCancelData,
  selectContractLoading,
  selectContractStatus
} from '../../../selectors'
import { FETCH_CANCEL_CONTRACT, RESET_CANCEL_CONTRACT } from '../../../types'

import ContractCancelForm from '../../../forms/contract/ContractCancelForm'
import { CONTRACT_STATUS } from '../../../constants'

const statuses = [
  CONTRACT_STATUS.MOVING_OUT.value,
  CONTRACT_STATUS.MOVED_OUT.value,
  CONTRACT_STATUS.REQUESTING_CANCEL.value,
  CONTRACT_STATUS.CANCELING.value
]

function ContractCancelPage ({ contractID, navigate }) {
  const dispatch = useDispatch()
  const data = useSelector(selectCancelData)
  const status = useSelector(selectContractStatus)
  const loading = useSelector(selectContractLoading)

  const initialData = {
    template_id: data?.template_id || null,
    final_inspection_datetime: data?.final_inspection_datetime || null,
    cancel_notice: data?.cancel_notice || null,
    ...data
  }

  useEffect(() => {
    if (status != null && statuses.includes(status)) {
      dispatch({ type: FETCH_CANCEL_CONTRACT, id: contractID, noLoading: true })
    } else {
      navigate('../')
    }
    return () => {
      dispatch({ type: RESET_CANCEL_CONTRACT })
    }
  }, [dispatch, contractID, status, navigate])

  const breadcrumbs = [
    {
      label: 'navigation:contracts',
      link: '/contracts'
    },
    {
      label: 'navigation:contracts_details',
      link: `/contracts/edit/${contractID}`
    },
    {
      label: 'navigation:contracts_cancel',
      link: `/contracts/edit/${contractID}/renew`
    }
  ]

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
      loading={loading}
      lastEdited={data?.created}
    >
      <ContractCancelForm
        data={initialData}
        contractID={contractID}
      />
    </MainLayout>
  )
}

ContractCancelPage.propTypes = {
  contractID: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired
}

export default ContractCancelPage
