import { createReducer } from '@reduxjs/toolkit'
import {
  POST_CHOUEI_BILLING,
  POST_CHOUEI_BILLING_SUCCESS,
  POST_CHOUEI_BILLING_FAIL,
  RESET_CHOUEI_BILLING
} from '../../types'

const INITIAL_STATE = {
  posting: false,
  error: null
}

export default createReducer(INITIAL_STATE, {
  [POST_CHOUEI_BILLING]: (state) => {
    state.posting = true
    state.error = null
  },
  [POST_CHOUEI_BILLING_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CHOUEI_BILLING_FAIL]: (state, action) => {
    state.posting = false
    state.error = action.payload
  },
  [RESET_CHOUEI_BILLING]: (state, action) => {
    state.posting = null
    state.error = null
  }
})
