import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_CONTRACT_DOCUMENTS,
  FETCH_CONTRACT_DOCUMENTS_SUCCESS,
  FETCH_CONTRACT_DOCUMENTS_FAIL,
  RESET_CONTRACT_DOCUMENTS,
  POST_CONTRACT_DOCUMENT,
  POST_CONTRACT_DOCUMENT_SUCCESS,
  POST_CONTRACT_DOCUMENT_FAIL,
  DELETE_CONTRACT_DOCUMENT,
  // DELETE_CONTRACT_DOCUMENT_SUCCESS,
  // DELETE_CONTRACT_DOCUMENT_FAIL
  TOGGLE_ADD_DOCUMENT_MODAL,
  TOGGLE_VIEW_DOCUMENT_MODAL,
  SET_ACTIVE_CONTRACT_DOCUMENT
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  posting: null,
  error: null,
  items: [],
  addDocumentModal: false,
  viewDocumentModal: false,
  activeDocumentID: null,
  hiddenBy: null,
  hiddenById: null,
  cloudSignCertificate: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_CONTRACT_DOCUMENTS]: (state, action) => {
    state.loading = true
    state.error = null
  },
  [FETCH_CONTRACT_DOCUMENTS_SUCCESS]: (state, action) => {
    state.loading = false
    state.items = action.payload.items
    state.hiddenBy = action.payload.hiddenBy
    state.hiddenById = action.payload.hiddenById
  },
  [FETCH_CONTRACT_DOCUMENTS_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  },
  [POST_CONTRACT_DOCUMENT]: (state, action) => {
    state.posting = true
  },
  [POST_CONTRACT_DOCUMENT_SUCCESS]: (state, action) => {
    state.posting = false
  },
  [POST_CONTRACT_DOCUMENT_FAIL]: (state, action) => {
    state.posting = false
  },
  [RESET_CONTRACT_DOCUMENTS]: (state, action) => {
    state.loading = true
    state.error = null
    state.items = []
    state.hiddenBy = null
    state.hiddenById = null
  },
  [DELETE_CONTRACT_DOCUMENT]: (state, action) => {
    state.loading = true
  },
  [TOGGLE_ADD_DOCUMENT_MODAL]: (state, action) => {
    state.addDocumentModal = !state.addDocumentModal
  },
  [TOGGLE_VIEW_DOCUMENT_MODAL]: (state, action) => {
    if (state.viewDocumentModal) {
      state.activeDocumentID = null
      state.cloudSignCertificate = null
    }
    state.viewDocumentModal = !state.viewDocumentModal
  },
  [SET_ACTIVE_CONTRACT_DOCUMENT]: (state, action) => {
    const { id, cloudSignCertificate } = action.payload
    state.activeDocumentID = id
    state.cloudSignCertificate = cloudSignCertificate
  }
})
