import { createReducer } from '@reduxjs/toolkit'
import {
  FETCH_REFUND,
  FETCH_REFUND_SUCCESS,
  FETCH_REFUND_FAIL
} from '../../types'

const INITIAL_STATE = {
  loading: true,
  error: null,
  data: null
}

export default createReducer(INITIAL_STATE, {
  [FETCH_REFUND]: (state, action) => {
    if (action && !action.noLoading) {
      state.loading = true
    }
    state.error = null
  },
  [FETCH_REFUND_SUCCESS]: (state, action) => {
    state.loading = false
    const data = action.payload
    if (data.parking) {
      const parking = data.parking.map((item, i) => {
        return {
          index: i,
          ...item
        }
      })
      data.parking = parking
    }
    state.data = data
  },
  [FETCH_REFUND_FAIL]: (state, action) => {
    state.loading = false
    state.error = action.payload
  }
})
