import { put, call, takeLatest, select, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchAppUser,
  postAppUser,
  deleteAppUser,
  sendSMSAppUser,
  sendLoginAppUser,
  resetLogin,
  sendVerifyEmail
} from '../../services'
import {
  FETCH_APP_USER,
  FETCH_APP_USER_SUCCESS,
  FETCH_APP_USER_FAIL,
  POST_APP_USER,
  POST_APP_USER_SUCCESS,
  POST_APP_USER_FAIL,
  DELETE_APP_USER,
  DELETE_APP_USER_SUCCESS,
  DELETE_APP_USER_FAIL,
  FETCH_APP_USERS,
  SEND_APP_USER_SMS,
  SEND_APP_USER_LOGIN,
  RESET_USER_LOGIN,
  SEND_USER_VERIFY_EMAIL
} from '../../types'
import { i18n } from '../../locales'

const getQuery = state => state.query

export function * fetchAppUserSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchAppUser, id, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_APP_USER_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_APP_USER_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_APP_USER_FAIL, payload: error.data || error.message })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postAppUserSaga ({ data, userID, successRedirect }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postAppUser, data, userID, cancelSource)
    if (res && res.data && res.data.success) {
      if (successRedirect) yield call(successRedirect, userID)
      yield put({ type: POST_APP_USER_SUCCESS })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_APP_USER_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_APP_USER_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteAppUserSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteAppUser, id, cancelSource)
    if (res) {
      const query = yield select(getQuery)
      yield put({ type: DELETE_APP_USER_SUCCESS })
      yield put({ type: FETCH_APP_USERS, query })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    yield put({ type: DELETE_APP_USER_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * sendSMSSaga ({ id }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(sendSMSAppUser, id, cancelSource)
    if (res && res.status === 204) {
      yield call(message.success, i18n.t('app_users:send_sms_success'))
    } else {
      yield call(message.error, i18n.t('app_users:send_sms_fail'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('app_users:send_sms_fail'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * sendLoginSaga ({ id, sendType, origin }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(sendLoginAppUser, id, sendType, cancelSource)
    if (res && res.status === 204) {
      if (origin === 'list') {
        const query = yield select(getQuery)
        yield put({ type: FETCH_APP_USERS, query })
      }
      if (origin === 'details') {
        yield put({ type: FETCH_APP_USER, id })
      }
      yield call(message.success, i18n.t('app_users:temp_login.send_success'))
    } else {
      yield call(message.error, i18n.t('app_users:temp_login.send_fail'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('app_users:temp_login.send_fail'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * resetLoginSaga ({ id, origin }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(resetLogin, id, cancelSource)
    if (res && res.status === 204) {
      if (origin === 'list') {
        const query = yield select(getQuery)
        yield put({ type: FETCH_APP_USERS, query })
      }
      if (origin === 'details') {
        yield put({ type: FETCH_APP_USER, id })
      }
      yield call(message.success, i18n.t('app_users:reset_user_login_success'))
    } else {
      yield call(message.error, i18n.t('app_users:reset_user_login_fail'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('app_users:reset_user_login_fail'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * sendUserVerifyEmailSaga ({ id, origin }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(sendVerifyEmail, id, cancelSource)
    if (res && res.status === 204) {
      if (origin === 'list') {
        const query = yield select(getQuery)
        yield put({ type: FETCH_APP_USERS, query })
      }
      if (origin === 'details') {
        yield put({ type: FETCH_APP_USER, id })
      }
      yield call(message.success, i18n.t('app_users:send_verify_token_success'))
    } else {
      yield call(message.error, i18n.t('app_users:send_verify_token_fail'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('app_users:send_verify_token_fail'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchAppUser () {
  yield takeLatest(FETCH_APP_USER, fetchAppUserSaga)
  yield takeLatest(POST_APP_USER, postAppUserSaga)
  yield takeLatest(DELETE_APP_USER, deleteAppUserSaga)
  yield takeLatest(SEND_APP_USER_SMS, sendSMSSaga)
  yield takeLatest(SEND_APP_USER_LOGIN, sendLoginSaga)
  yield takeLatest(RESET_USER_LOGIN, resetLoginSaga)
  yield takeLatest(SEND_USER_VERIFY_EMAIL, sendUserVerifyEmailSaga)
}
