import { put, call, takeLatest, cancelled } from 'redux-saga/effects'
import { message } from 'antd'
import { CancelToken } from 'axios'

import {
  fetchContacts,
  postContact,
  deleteContact,
  toggleContact,
  bulkToggleContacts
} from '../../services'
import {
  FETCH_CONTACTS,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAIL,
  POST_CONTACT,
  POST_CONTACT_SUCCESS,
  POST_CONTACT_FAIL,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
  TOGGLE_CONTACT,
  TOGGLE_CONTACT_SUCCESS,
  TOGGLE_CONTACT_FAIL,
  BULK_TOGGLE_CONTACTS,
  BULK_TOGGLE_CONTACTS_SUCCESS,
  BULK_TOGGLE_CONTACTS_FAIL
} from '../../types'
import { i18n } from '../../locales'

export function * fetchContactsSaga ({ config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(fetchContacts, config, cancelSource)
    if (res && res.data) {
      yield put({ type: FETCH_CONTACTS_SUCCESS, payload: res.data })
    } else {
      yield put({ type: FETCH_CONTACTS_FAIL })
      yield call(message.error, i18n.t('fetch_error'))
    }
  } catch (error) {
    yield put({ type: FETCH_CONTACTS_FAIL })
    yield call(message.error, i18n.t('fetch_error'))
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * postContactSaga ({ data, config, onSuccess }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(postContact, data, config, cancelSource)
    if (res && res.data && res.data.success) {
      if (onSuccess) yield call(onSuccess)
      yield put({ type: POST_CONTACT_SUCCESS })
      yield put({ type: FETCH_CONTACTS, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: POST_CONTACT_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    yield put({ type: POST_CONTACT_FAIL, payload: error.data || error.message })
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * toggleContactSaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(toggleContact, data, config, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: TOGGLE_CONTACT_SUCCESS })
      yield put({ type: FETCH_CONTACTS, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: TOGGLE_CONTACT_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
    yield put({ type: TOGGLE_CONTACT_FAIL, payload: error.data || error.message })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}
export function * bulkToggleContactsSaga ({ data, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(bulkToggleContacts, data, config, cancelSource)
    if (res && res.status === 204) {
      yield put({ type: BULK_TOGGLE_CONTACTS_SUCCESS })
      yield put({ type: FETCH_CONTACTS, config })
      yield call(message.success, i18n.t('post_success'))
    } else {
      yield put({ type: BULK_TOGGLE_CONTACTS_FAIL })
      yield call(message.error, i18n.t('post_error'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('post_error'))
    }
    yield put({ type: BULK_TOGGLE_CONTACTS_FAIL, payload: error.data || error.message })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * deleteContactSaga ({ id, config }) {
  const cancelSource = CancelToken.source()
  try {
    const res = yield call(deleteContact, id, config, cancelSource)
    if (res) {
      yield put({ type: DELETE_CONTACT_SUCCESS })
      yield put({ type: FETCH_CONTACTS, config })
      yield call(message.success, i18n.t('delete_success'))
    }
  } catch (error) {
    if (error && error.data && error.data.message) {
      yield call(message.error, error.data.message)
    } else {
      yield call(message.error, i18n.t('delete_error'))
    }
    yield put({ type: DELETE_CONTACT_FAIL, payload: error.data || error.message })
  } finally {
    if (yield cancelled()) {
      console.warning('>>>>>>>>>>>> cancelled <<<<<<<<<<<<<<')
      yield call(cancelSource.cancel)
    }
  }
}

export function * watchContacts () {
  yield takeLatest(FETCH_CONTACTS, fetchContactsSaga)
  yield takeLatest(POST_CONTACT, postContactSaga)
  yield takeLatest(DELETE_CONTACT, deleteContactSaga)
  yield takeLatest(TOGGLE_CONTACT, toggleContactSaga)
  yield takeLatest(BULK_TOGGLE_CONTACTS, bulkToggleContactsSaga)
}
