import { api } from '@gk-devteam/apmc-core-web'

export const loginUserService = (request, cancelSource) => {
  // return mock(LoginResponse)
  return api.post('/login', request, {
    cancelToken: cancelSource.token
  })
}
export const refreshUserService = (cancelSource) => {
  return api.post('/refresh_user', null, {
    cancelToken: cancelSource.token
  })
}

export const sendForgotPasswordLink = (data, cancelSource) => {
  return api.post('/forgot_password', data, {
    cancelToken: cancelSource.token
  })
}

export const verifyForgotPasswordToken = (data, cancelSource) => {
  return api.post('/forgot_password/verify', data, {
    cancelToken: cancelSource.token
  })
}
export const changeUserPassword = (data, cancelSource) => {
  return api.post('/forgot_password/change', data, {
    cancelToken: cancelSource.token
  })
}

export const verifyCreatePasswordToken = (data, cancelSource) => {
  return api.post('/create_password/verify', data, {
    cancelToken: cancelSource.token
  })
}
export const createUserPassword = (data, cancelSource) => {
  return api.post('/create_password', data, {
    cancelToken: cancelSource.token
  })
}
