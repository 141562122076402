import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from '@reach/router'
import PropTypes from 'prop-types'
import {
  InquiryID,
  Form,
  Card,
  Row,
  Title,
  FilePicker,
  TextInput,
  TextArea,
  FormSubmitButton,
  RoleControlled,
  Select,
  TimeRangePicker
} from '@gk-devteam/apmc-core-web'

import {
  selectSpaceLoading,
  selectSpacePosting
} from '../../selectors'
import { spaceUpdateSchema } from '../../validation'
import { POST_SPACE } from '../../types'
import { INQUIRY_PREFIX, SPACE_TYPE_OPTIONS } from '../../constants'

const PropertySpaceForm = ({ data, propertyID, spaceID }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const initialValues = {
    ...data,
    property_id: data?.property_id || propertyID,
    file: data?.file || null,
    delete_file: data?.delete_file || false
  }

  const handleFormSubmit = (data) => {
    const successRedirect = () => {
      navigate(`/properties/edit/${propertyID}/spaces`)
    }
    dispatch({ type: POST_SPACE, data, successRedirect })
  }

  return (
    <Form
      id="addPropertySpaceForm"
      handleSubmit={handleFormSubmit}
      validationSchema={spaceUpdateSchema}
      loadingSelector={selectSpaceLoading}
      postingSelector={selectSpacePosting}
      initialData={initialValues}
      authorized="properties.functions.update"
      // debug
    >
      <Row justify="between" wrap="wrap">
        {
          spaceID
            ? <Title text={data.name} />
            : <Title label='navigation:properties_spaces_add' />
        }
        <RoleControlled authorized="properties.functions.update">
          <FormSubmitButton editMode={!!spaceID} postingSelector={selectSpacePosting}/>
        </RoleControlled>
      </Row>
      <Row mb="ML">
        <InquiryID id={spaceID} prefix={INQUIRY_PREFIX.SPACE} />
      </Row>
      <Card mb="L">
        <Row wrap="wrap" align="baseline" mb="R">
          <TextInput
            flex={1}
            type="text"
            name="name"
            label='spaces:name'
            placeholder=""
            required
          />
        </Row>
        <Row wrap="wrap" align="baseline" mb="R">
          <TextArea
            flex={1}
            name="description"
            label='description'
            placeholder=""
            maxLength={10000}
          />
        </Row>
        <Row wrap="wrap" align="start" mb="R">
          <Select
            name="type"
            label='spaces:type'
            placeholder=""
            options={SPACE_TYPE_OPTIONS}
            noClear
            required
          />
        </Row>
        <Row wrap="wrap" align="start">
          <TextInput
            type="number"
            name="reservable_from_days"
            label='spaces:reservable_from_days'
            suffix="spaces:days_before_suffix"
            required
          />
          <TimeRangePicker
            size="L"
            name_start="reservable_from_time"
            name_end="reservable_to_time"
            label='spaces:reservable_time'
            // placeholder={['chat.auto_reply_start_time_short', 'chat.auto_reply_end_time_short']}
          />
          <TextInput
            type="number"
            name="usage_limit"
            label='spaces:usage_limit'
            required
          />

        </Row>
      </Card>
      <Card title='image'>
        <FilePicker
          name="file"
          delete_name="delete_file"
          types={['image']}
        />
      </Card>
    </Form>
  )
}
export default PropertySpaceForm

PropertySpaceForm.propTypes = {
  data: PropTypes.object,
  propertyID: PropTypes.string.isRequired,
  spaceID: PropTypes.string
}
PropertySpaceForm.defaultProps = {

}
