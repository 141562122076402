import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const noticesSearchSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    property_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('properties:property_id')),
    property_name: yup.string().label(i18n.t('notices:property_name')),
    app_user_name: yup.string().label(i18n.t('notices:app_user_name')),
    app_user_id: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('app_users:app_user_id')),
    notice_type: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('notices:notice_type')),
    start_date: yup.date().nullable().label(i18n.t('notices:start_date')),
    stop_time: yup.date().nullable().label(i18n.t('notices:stop_time')),
    prefecture: yup.string().label(i18n.t('notices:prefecture')),
    city: yup.string().label(i18n.t('notices:city')),
    min_income: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable()
      .isMore('max_income', i18n.t('notices:max_income'))
      .label(i18n.t('notices:min_income')),
    max_income: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .isLess('min_income', i18n.t('notices:min_income'))
      .integer().nullable().label(i18n.t('notices:max_income')),
    occupation: yup.string().label(i18n.t('app_users:occupation')),
    contract_status: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('notices:contract_status')),
    sex: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .integer().nullable().label(i18n.t('app_users:sex')),
    min_age: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .isMore('max_age', i18n.t('notices:max_age'))
      .integer().nullable().label(i18n.t('notices:min_age')),
    max_age: yup
      .number()
      .transform((cv, ov) => ov === '' ? undefined : cv)
      .isLess('min_age', i18n.t('notices:min_age'))
      .integer().nullable().label(i18n.t('notices:max_age')),
    limit: yup.number().integer().label(i18n.t('limit')),
    page: yup.number().integer().label(i18n.t('page')),
    sort_by: yup.string().label(i18n.t('sort_by')),
    sort_order: yup.string().label(i18n.t('sort_order'))
  })
}
