import React, { useCallback, useEffect, useState } from 'react'
import {
  Row,
  Select,
  useTranslation,
  useIsMountedRef,
  UPDATE_FORM_REQUIRED_FIELDS
} from '@gk-devteam/apmc-core-web'
import { message } from 'antd'
import { CancelToken } from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { fetchTemplates, fetchTemplateDefinition } from '../../../services/templatesService'

import PreviewButton from './PreviewButton'

function SelectContractTemplate ({ type }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const templateID = useSelector(state => state.form.template_id)
  const [templateOptions, setTemplateOptions] = useState()
  const [loadingOptions, setLoadingOptions] = useState(true)
  const isMountedRef = useIsMountedRef()

  const fetchTemplateOptions = useCallback(
    async () => {
      try {
        setLoadingOptions(true)
        const cancelSource = CancelToken.source()
        const res = await fetchTemplates({
          type
        }, cancelSource)
        if (!isMountedRef.current) return
        if (res?.data.results) {
          setLoadingOptions(false)
          const options = res.data.results.map(option => {
            const { id, name } = option
            return {
              label: name,
              value: id
            }
          })
          setTemplateOptions(options)
        } else {
          message.error(t('fetch_error'))
          setLoadingOptions(false)
        }
      } catch (error) {
        message.error(t('fetch_error'))
        setLoadingOptions(false)
      }
    },
    [t, type, isMountedRef]
  )

  const fetchDefinition = useCallback(
    async () => {
      try {
        const cancelSource = CancelToken.source()
        const res = await fetchTemplateDefinition(templateID, type, cancelSource)
        if (!isMountedRef.current) return
        if (res?.data) {
          dispatch({ type: UPDATE_FORM_REQUIRED_FIELDS, payload: res.data })
        }
      } catch (error) {
        message.error(t('fetch_error'))
        setLoadingOptions(false)
      }
    },
    [dispatch, templateID, type, isMountedRef, t]
  )

  useEffect(() => {
    fetchTemplateOptions()
  }, [fetchTemplateOptions])

  useEffect(() => {
    if (templateID) fetchDefinition()
  }, [templateID, fetchDefinition])

  return (
    <Row mb="L" align="start">
      <Select
        flex={1}
        size="L"
        name="template_id"
        label="contracts:template"
        options={templateOptions}
        loading={loadingOptions}
        noClear
        required
      />
      <PreviewButton type={type}/>
    </Row>
  )
}

SelectContractTemplate.propTypes = {
  type: PropTypes.oneOf([1, 2])
}

export default SelectContractTemplate
