import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Row,
  TextInput
} from '@gk-devteam/apmc-core-web'

import { roomUpdateValidation } from '../../../validation'
import {
  selectRoomsLoading,
  selectPostingRoom
} from '../../../selectors'

const initialData = {
  room_number: '',
  room_manage_id: null
}

const AddRoomForm = ({ addRoom }) => {
  return (
    <Form
      id="addRoomForm"
      handleSubmit={addRoom}
      validationSchema={roomUpdateValidation}
      loadingSelector={selectRoomsLoading}
      postingSelector={selectPostingRoom}
      initialData={initialData}
      // debug
    >
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="room_number"
          label='properties:room_number'
          placeholder=""
          required
        />
      </Row>
      <Row>
        <TextInput
          flex={1}
          type="text"
          name="room_manage_id"
          label='rooms:room_manage_id'
          placeholder=""
        />
      </Row>
    </Form>
  )
}

export default memo(AddRoomForm)

AddRoomForm.propTypes = {
  addRoom: PropTypes.func.isRequired
}
